import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Badge from 'design/Badge'
import { Modal, Popconfirm } from 'antd'
import { useLazyQuery, useMutation } from '@apollo/client'
import Spinner from 'design/Spinner'
import { GET_SPLITTED_TRANSACTION_WITH_PARTS, UNSPLIT_TRANSACTION } from 'graphql/transactions'
import TransactionPreview from './TransactionPreview'
import TransactionPartTable from './TransactionPartsTable'
import Button from 'design/Button'
import { useTranslation } from 'react-i18next'

const SplittedTransactionModal = ({ originalTransactionId }) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)

  const [getTransactions, { data }] = useLazyQuery(GET_SPLITTED_TRANSACTION_WITH_PARTS, {
    variables: { originalTransactionId },
    fetchPolicy: 'network-only'
  })

  const [unsplitTransaction, { loading: unsplitting }] = useMutation(UNSPLIT_TRANSACTION, {
    variables: { originalTransactionId },
    onCompleted: () => {
      setVisible(false)
    },
    refetchQueries: ['listAllTransactions']
  })

  const openModal = useCallback(() => {
    getTransactions()
    setVisible(true)
  }, [getTransactions, setVisible])

  const originalTransaction = useMemo(() => {
    return data?.getSplittedTransactionWithParts?.originalTransaction
  }, [data])

  const transactionParts = useMemo(() => {
    return data?.getSplittedTransactionWithParts?.transactionParts || []
  }, [data])

  return (
    <div className='w-full'>
      <button onClick={openModal} className='w-full outline-none'>
        <Badge
          label={t('component.splittedTransactionModal.badge')}
          type='primary'
          className='w-full text-center'
        />
      </button>

      <Modal
        open={visible}
        width={720}
        onClose={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        {(originalTransaction && (
          <div className='flex flex-col'>
            <div className='font-bold text-base mb-2'>{t('component.splittedTransactionModal.originalTransaction')}</div>
            <TransactionPreview transaction={originalTransaction} showDrawers />

            <Popconfirm
              placement='topRight'
              title={(
                <div className='w-96'>
                  {t('component.splittedTransactionModal.cancelSplit')}
                </div>
              )}
              onConfirm={unsplitTransaction}
              okText={t('shared.confirm')}
              okType='danger'
              cancelText={t('shared.cancel')}
              inputContainerClassname='p-2'
            >
              <Button loading={unsplitting} label={t('component.splittedTransactionModal.restoreOriginalTransaction')} className='mt-4 self-end' />
            </Popconfirm>

            <div className='font-bold text-base mt-4 mb-2'>{t('component.splittedTransactionModal.breakdown')}</div>

            <TransactionPartTable
              type={originalTransaction.type}
              dataSource={transactionParts}
            />
          </div>
        )) || (
          <Spinner className='w-6 h-6 text-primary' />
        )}
      </Modal>
    </div>
  )
}

SplittedTransactionModal.propTypes = {
  originalTransactionId: PropTypes.string.isRequired
}

export default SplittedTransactionModal
