import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from 'antd'

import AppBar from 'components/app-bar/AppBar'
import GlobalComponents from './GlobalComponents'
import InnerScreenGlobalComponents from './InnerScreenGlobalComponents'

import RouteRenderer from './RouteRenderer'
import { Route } from 'react-router'

LayoutRoute.defaultProps = {
  appBarType: 'full',
  requireAuth: false,
  requireOnboarding: true,
  requireNotBlocked: true
}

LayoutRoute.propTypes = {
  appBarType: PropTypes.oneOf(['none', 'light', 'full']),
  exact: PropTypes.bool,
  path: PropTypes.any,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  requireAuth: PropTypes.bool,
  requireOnboarding: PropTypes.bool,
  requireNotBlocked: PropTypes.bool
}

const renderAppBar = ({ appBarType }) => {
  switch (appBarType) {
    case 'none':
      break
    case 'light':
      return <AppBar withoutNav />
    default:
      return <AppBar />
  }
}

export default function LayoutRoute ({ appBarType, exact, path, ...rest }) {
  return (
    <Route exact={exact} path={path}>
      <Layout className='rc-layout'>
        {renderAppBar({ appBarType })}

        <GlobalComponents />

        <div className={`rc-screen-container ${appBarType === 'none' ? 'w-full' : ''}`}>
          <InnerScreenGlobalComponents />

          <RouteRenderer {...rest} />
        </div>
      </Layout>
    </Route>
  )
}
