import { get, flatten } from 'lodash'

export const transformResource = (resource) => {
  return { ...resource, id: get(resource, '_id') }
}

export const getResourcesFromResponse = (response, path = 'data') => {
  return flatten([get(response, path, [])]).map(transformResource)
}

export const getPropertiesFromResponse = (response) => {
  return {
    status: get(response, 'status', 0),
    total: get(response, 'data.total', 0),
    errors: undefined
  }
}

export const getErrorsFromResponse = (response) => {
  return {
    status: get(response, 'status', 0),
    errors: get(response, 'data.text', '')
  }
}
