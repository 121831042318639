import InvoiceList from 'components/invoices/InvoiceList'
import ScreenSwitch from 'design/ScreenSwitch.js'
import CashType from 'enums/CashType.js'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function CashOutExpectedDocuments () {
  const { t } = useTranslation()

  return (
    <ScreenSwitch
      screens={[
        {
          label: t('cashOutScreen.invoices'),
          component: <InvoiceList cashType={CashType.CASHOUT} />
        }
      ]}
      className='mb-4'
    />
  )
}
