import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { withTranslation, Trans } from 'react-i18next'

import SignUpForm from './sign-up/SignUpForm'
import Testimonials from './sign-up/Testimonials'
import ConnectWithSellsyButton from './sign-in/ConnectWithSellsyButton'
import Logo from 'components/Logo.js'

class SignUpScreen extends Component {
  render () {
    const { createStatus, isAuthenticated, t } = this.props

    if (isAuthenticated) return <Redirect to='/' />

    return (
      <div className='min-h-full w-full flex flex-col lg:flex-row lg:justify-center'>
        <div className='bg-white w-full lg:w-1/2 flex flex-col justify-start items-center lg:overflow-scroll py-8'>
          <div className='flex flex-col justify-center items-center self-center lg:min-h-full'>
            <div className='mt-8'>
              <Logo />
            </div>

            <div className='max-w-sm p-4 mt-2'>
              <div className='font-bold text-lg lg:text-2xl text-black text-center'>{t('signup.startFreeTrialTitle')}</div>
              <div className='text-center text-gray-500'>{t('signup.freeTrialPeriod')}</div>
            </div>

            <div className='px-0'><ConnectWithSellsyButton isSigningIn={createStatus?.pending} /></div>

            <div className='w-full flex flex-row items-center text-gray-400 my-4'>
              <div className='bg-gray-200 h-px w-auto flex-grow' />
              <div className='mx-2 text-xs'>{t('shared.or')}</div>
              <div className='bg-gray-200 h-px w-auto flex-grow' />
            </div>

            <div className='max-w-sm p-8 py-0'>
              <SignUpForm createStatus={createStatus} createUser={this.createUser} />
            </div>
          </div>

          <div className='max-w-sm text-xs text-gray-400 px-8 lg:px-0 mt-0 lg:mt-24 mb-8 text-justify'>
            {t('signup.disclaimer01')}
            <ul className='list-disc ml-4'>
              <li>{t('signup.disclaimer02')}</li>
              <li>{t('signup.disclaimer03')}</li>
            </ul>

            <Trans i18nKey='signup.disclaimer04' components={{ Link: <a href='https://rocketchart-public.s3.eu-west-3.amazonaws.com/politique-de-confidentialite.pdf' target='_blank' rel='noopener noreferrer' /> }} />

            <br />
            <Trans i18nKey='signup.disclaimer05' components={{ Link: <a href='mailto:privacy@rocketchart.co' target='_blank' rel='noopener noreferrer' /> }} />
          </div>
        </div>

        <div className='bg-primary text-white w-full lg:w-1/2 flex flex-grow items-center'>
          <div className='w-full'>
            <div className='items-center'>
              <Testimonials />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.app.isAuthenticated
  }
}

SignUpScreen.propTypes = {
  isAuthenticated: PropTypes.bool,
  createStatus: PropTypes.object,
  t: PropTypes.func.isRequired
}

export default connect(mapStateToProps)(withTranslation()(SignUpScreen))
