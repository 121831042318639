import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, InputNumber } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import { isNumber } from 'lodash'
import numeral from 'numeral'
import { useTranslation } from 'react-i18next'

const parser = (value) => value.replace(',', '.').replace('%', '')
const formatter = (value) => value ? `${value}%` : ''
const convertPercent = (percent) => numeral(percent).divide(100).value()

const CustomVatRateInput = ({ onSubmit }) => {
  const { t } = useTranslation()
  const [rawValue, setRawValue] = useState('')
  const [customVatRate, setCustomVatRate] = useState()

  const handleOnChange = useCallback((percent) => {
    if (isNumber(percent)) {
      const convertedValue = convertPercent(percent)
      setRawValue(percent)
      setCustomVatRate(convertedValue)
    } else {
      setCustomVatRate(undefined)
      setRawValue(null)
    }
  }, [setRawValue, setCustomVatRate])

  // const onPressEnter = useCallback((e) => {
  //   const rawRawValue = e.currentTarget.value
  //   const rawValue = parser(rawRawValue)
  //   const convertedValue = convertPercent(rawValue)
  //   onSubmit(convertedValue)
  // }, [onSubmit])

  return (
    <div className='ml-2 mr-2 mb-1 flex flex-row items-center'>
      <InputNumber
        placeholder={t('component.customVatRateInput.other')}
        min={0}
        max={99}
        step={1}
        parser={parser}
        formatter={formatter}
        onChange={handleOnChange}
        // onPressEnter={onPressEnter}
        value={rawValue}
      />
      <Button
        htmlType='submit'
        disabled={!isNumber(customVatRate)}
        type='primary'
        icon={<CheckOutlined />}
        className='ml-1'
        onClick={() => onSubmit(customVatRate)}
      />
    </div>
  )
}

CustomVatRateInput.propTypes = {
  onSubmit: PropTypes.func
}

export default CustomVatRateInput
