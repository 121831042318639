export default {
  DEFAULT:
    {
      DRAFT: 'DRAFT',
      SENT: 'SENT',
      READ: 'READ',
      ACCEPTED: 'ACCEPTED',
      PARTIAL_INVOICED: 'PARTIAL_INVOICED',
      DEPOSIT: 'DEPOSIT',
      INVOICED: 'INVOICED',
      EXPIRED: 'EXPIRED',
      REFUSED: 'REFUSED',
      CANCELED: 'CANCELED'
    },
  QUIPU: {
    SENT: 'SENT',
    ACCEPTED: 'ACCEPTED',
    INVOICED: 'INVOICED',
    REFUSED: 'REFUSED'
  }
}
