import { gql } from '@apollo/client'
import { ACCOUNTING_DOCUMENT_NESTED_EXPECTED_TRANSACTIONS_FIELDS } from './expected-transactions.js'
import { getAccountingDocumentListFields, getAddExpectedTransactionToAccountingDocumentFields } from './accounting-documents.js'

const ACCOUNTING_DOCUMENTS_LIST_FIELDS = getAccountingDocumentListFields('Order')
const ADD_EXPECTED_TRANSACTION_TO_ACCOUNTING_DOCUMENT_FIELDS = getAddExpectedTransactionToAccountingDocumentFields('Order')

export const LIST_ORDERS = gql`
  ${ACCOUNTING_DOCUMENTS_LIST_FIELDS}
  ${ACCOUNTING_DOCUMENT_NESTED_EXPECTED_TRANSACTIONS_FIELDS}
  query listOrders(
    $offset: Int
    $limit: Int!
    $search: String
    $stateFilter: OrderStateFilterType
    $withIgnored: Boolean
  ) {
    listOrders(
      offset: $offset
      limit: $limit
      search: $search
      stateFilter: $stateFilter
      withIgnored: $withIgnored
    ) {
      total
      orders {
        ...OrdersListFields
        orderNumber
        documentUrl
        expectedTransactions {
         ...accountingDocumentNestedExpectedTransactionsFields
        }
      }
    }
  }
`

export const IGNORE_ORDERS = gql`
  mutation ignoreOrders($input: IgnoreOrdersInput!) {
    ignoreOrders(input: $input) {
      id
      ignored
    }
  }
`

export const ADD_EXPECTED_TRANSACTION_TO_ORDER = gql`
  ${ADD_EXPECTED_TRANSACTION_TO_ACCOUNTING_DOCUMENT_FIELDS}
  mutation addExpectedTransactionToOrder($input: AddExpectedTransactionToOrderInput!) {
    addExpectedTransactionToOrder(input: $input) {
      ...addExpectedTransactionToOrderFields
    }
  }
`
