import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import { uniq } from 'lodash'

import allColors, { categoryColors } from 'utils/colors'
import ColorOption from './ColorOption'

const { Option } = Select

const ColorSelect = React.forwardRef(({ value, palette, ...rest }, ref) => {
  const colors = uniq(palette || categoryColors)

  if (value) {
    if (!colors.includes(value)) colors.push(value)
  }

  return (
    <Select
      ref={ref}
      optionLabelProp='children'
      value={value}
      className='rc-color-select text-right'
      popupClassName='rc-color-select-dropdown'
      showArrow={false}
      placeholder={(
        <div className='h-full flex items-center'>
          <ColorOption color={allColors.grey} />
        </div>
      )}
      {...rest}
    >
      {colors.map((color) => (
        <Option key={color}>
          <ColorOption color={color} />
        </Option>
      ))}
    </Select>
  )
})

ColorSelect.propTypes = {
  value: PropTypes.string,
  palette: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func
}

export default ColorSelect
