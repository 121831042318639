import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from 'antd'
import ExpectedTransactionDetailTable from 'components/expected-transaction-details/ExpectedTransactionDetailTable'
import { useTranslation } from 'react-i18next'

const AccountingDocumentExpectedTransactionDetails = ({ expectedTransaction }) => {
  const { t } = useTranslation()
  return (
    <div className='w-full max-w-3xl'>
      <Typography.Text strong>{t('shared.details')}</Typography.Text>
      <ExpectedTransactionDetailTable expectedTransaction={expectedTransaction} />
    </div>
  )
}

AccountingDocumentExpectedTransactionDetails.propTypes = {
  expectedTransaction: PropTypes.object
}

export default AccountingDocumentExpectedTransactionDetails
