import { handleActions } from 'redux-actions'
import { set } from 'lodash/fp'

export const appInitialState = {
  isAuthenticated: false,
  country: 'FR',
  pageSize: 20,
  dashboard: {
    realisedVsForecasted: false,
    showScenario: true,
    showPercentage: true,
    hideTotal: false
  },
  lastAccountManagementAt: null,
  nextReferralBannerDisplayAt: null,
  tableExpandedCategories: [],
  invoices: {
    queryFilters: {
      withIgnored: true
    }
  },
  quotes: {
    queryFilters: {
      withIgnored: true
    }
  },
  recurringPayments: {
    queryFilters: {
      withIgnored: true
    }
  },
  orders: {
    queryFilters: {
      withIgnored: true
    }
  }
}

const reducer = handleActions(
  {
    SET_IS_AUTHENTICATED: (state, { payload: { isAuthenticated } }) => ({
      ...state,
      isAuthenticated
    }),
    SET_COUNTRY: (state, { payload: { country } }) => ({
      ...state,
      country
    }),
    SET_REALISED_VS_SCENARIO: (state, { payload: { realisedVsForecasted } }) => set('dashboard.realisedVsForecasted', realisedVsForecasted, state),
    SET_DASHBOARD_HIDE_TOTAL: (state, { payload: { hideTotal } }) => set('dashboard.hideTotal', hideTotal, state),
    SET_DASHBOARD_SHOW_PERCENTAGE: (state, { payload: { showPercentage } }) => set('dashboard.showPercentage', showPercentage, state),
    SET_SHOW_SCENARIO: (state, { payload: { showScenario } }) => set('dashboard.showScenario', showScenario, state),
    SET_PAGE_SIZE: (state, { payload: { pageSize } }) => ({
      ...state,
      pageSize
    }),
    SET_LAST_ACCOUNT_MANAGEMENT_AT: (state, { payload: { accountManagedAt } }) =>
      set('lastAccountManagementAt', accountManagedAt, state),
    SET_LAST_SELLSY_MANAGEMENT_AT: (state, { payload: { sellsyManagedAt } }) =>
      set('lastSellSyManagementAt', sellsyManagedAt, state),
    SET_DASHBOARD_QUERY_FILTERS: (state, { payload: { queryFilters } }) => set('dashboard.queryFilters', queryFilters, state),
    SET_INVOICES_QUERY_FILTERS: (state, { payload: { invoicesQueryFilters } }) => set('invoices.queryFilters', invoicesQueryFilters, state),
    SET_QUOTES_QUERY_FILTERS: (state, { payload: { quotesQueryFilters } }) => set('quotes.queryFilters', quotesQueryFilters, state),
    SET_TABLE_EXPANDED_CATEGORIES: (state, { payload: { tableExpandedCategories } }) => set('tableExpandedCategories', tableExpandedCategories, state),
    SET_RECURRING_PAYMENTS_QUERY_FILTERS: (state, { payload: { recurringPaymentsQueryFilters } }) => set('recurringPayments.queryFilters', recurringPaymentsQueryFilters, state),
    SET_ORDERS_QUERY_FILTERS: (state, { payload: { ordersQueryFilters } }) => set('orders.queryFilters', ordersQueryFilters, state)
  },
  { ...appInitialState }
)

export default reducer
