import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Divider, Modal } from 'antd'

import StandaloneCategoryFormContainer from './StandaloneCategoryFormContainer'
import Button from 'design/Button'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const CategorySelectRenderer = ({ menu, type, onCreateSuccess, withCreateButton, withSettingsButton }) => {
  const { t } = useTranslation()
  const [creationVisible, setCreationVisible] = useState(false)

  const onSuccessCallback = useCallback((id) => {
    if (onCreateSuccess) onCreateSuccess(id)
    setCreationVisible(false)
  }, [onCreateSuccess, setCreationVisible])

  const categoryRoute = useCallback(() => {
    return type === 'cashin' ? '/categories/cash-in' : '/categories/cash-out'
  }, [type])

  if (!withCreateButton) return menu

  return (
    <div>
      {menu}

      <Divider className='mb-3 mt-2' />

      <div className='mb-3 mx-2 flex flex-col 2xl:flex-row-reverse items-center space-y-2 2xl:space-y-0'>

        <Button onClick={() => setCreationVisible(true)} label={t('component.categorySelectRenderer.createACategory')} />
        {withSettingsButton && (<Link to={categoryRoute} className='2xl:mr-4 2xl:ml-2'>{t('component.categorySelectRenderer.manageCategories')}</Link>)}
      </div>

      <Modal
        width={420}
        centered
        title={t('component.categorySelectRenderer.createACategory')}
        open={creationVisible}
        onClose={() => setCreationVisible(false)}
        onCancel={() => setCreationVisible(false)}
        footer={null}
      >
        <StandaloneCategoryFormContainer type={type} onSuccess={onSuccessCallback} />
      </Modal>
    </div>
  )
}

CategorySelectRenderer.defaultProps = {
  withCreateButton: false,
  withSettingsButton: false
}

CategorySelectRenderer.propTypes = {
  type: PropTypes.oneOf(['cashin', 'cashout']),
  menu: PropTypes.node,
  withCreateButton: PropTypes.bool,
  withSettingsButton: PropTypes.bool,
  onCreateSuccess: PropTypes.func
}

export default CategorySelectRenderer
