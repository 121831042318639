
import React from 'react'
// import { Avatar } from 'antd'
// import  from 'components/icons/BellIcon'
import { BellIcon } from '@heroicons/react/24/outline'

const WispButton = () => {
  if (process.env.REACT_APP_TENANT === 'QUIPU') return null

  return (
    <div className='rc-sider-link rc-sider-wisp-container wisp'>
      <BellIcon className='rc-bell-icon w-5 h-5' />
      {/* <BellIcon */}
    </div>
  )
}

export default WispButton
