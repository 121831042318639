import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Typography } from 'antd'
import moment from 'moment'

import InvoiceStatusBadge from './InvoiceStatusBadge'
import AccountingDocumentTableCategorySelectShortcut from 'components/accounting-documents/AccountingDocumentTableCategorySelectShortcut'
import ExpectedTransactionExpectedDatePickerShortcut from 'components/expected-transactions/ExpectedTransactionExpectedDatePickerShortcut'
import InvoiceActions from './InvoiceActions'
import i18next from 'i18next'
import AccountingDocumentTable from 'components/accounting-documents/AccountingDocumentTable.js'
import MultipleInvoiceUpdate from './MultipleInvoiceUpdate.js'
import AccountingDocumentAmountPreview from 'components/accounting-documents/AccoutingDocumentAmountPreview.js'
import UpdateInvoiceManuallyDrawer from './UpdateInvoiceManuallyDrawer'

const { t } = i18next

const { Text } = Typography

const getColumns = ({
  type,
  toggleRowExpand,
  refetchAccountingDocuments: refetchInvoices,
  editAccountingDocument: editInvoice
}) => [
  {
    title: t('component.invoiceTable.column.status'),
    key: 'status',
    width: '1px',
    onCell: (invoice) => ({ onClick: () => { toggleRowExpand(invoice.id) } }),
    className: 'cursor-pointer min-w-max',
    render: invoice => <InvoiceStatusBadge invoice={invoice} />
  },
  {
    title: t('component.invoiceTable.column.invoiceNumber'),
    dataIndex: 'invoiceNumber',
    key: 'invoiceNumber',
    onCell: (invoice) => ({ onClick: () => { toggleRowExpand(invoice.id) } }),
    className: 'cursor-pointer min-w-48',
    render: text => <Text>{text}</Text>
  },
  {
    title: type === 'cashin' ? t('component.invoiceTable.column.client') : t('component.invoiceTable.column.supplier'),
    dataIndex: 'thirdPartyName',
    key: 'thirdPartyName',
    width: '160px',
    onCell: (invoice) => ({ onClick: () => { toggleRowExpand(invoice.id) } }),
    className: 'cursor-pointer',
    render: text => <Text>{text}</Text>
  },
  {
    title: t('component.invoiceTable.column.dueDate'),
    dataIndex: 'dueDate',
    key: 'dueDate',
    width: '120px',
    onCell: (invoice) => ({ onClick: () => { toggleRowExpand(invoice.id) } }),
    className: 'cursor-pointer',
    render: date => date && (<Text>{moment(date).format('DD/MM/YYYY')}</Text>)
  },
  {
    title: t('component.invoiceTable.column.expectedDate'),
    key: 'expectedDate',
    width: '170px',
    editable: true,
    render: invoice => <ExpectedTransactionExpectedDatePickerShortcut accountingDocument={invoice} onExpand={() => { toggleRowExpand(invoice.id) }} />
  },
  {
    title: t('shared.category'),
    key: 'category',
    width: '192px',
    className: 'max-w-[192px]',
    render: invoice => <AccountingDocumentTableCategorySelectShortcut accountingDocument={invoice} onExpand={() => { toggleRowExpand(invoice.id) }} />
  },
  {
    title: t('shared.amountInclTax'),
    key: 'amount',
    width: '180px',
    onCell: (invoice) => ({ onClick: () => { toggleRowExpand(invoice.id) } }),
    className: 'cursor-pointer text-right',
    render: invoice => <AccountingDocumentAmountPreview accountingDocument={invoice} />
  },
  {
    title: '',
    dataIndex: '',
    key: 'more',
    width: '32px',
    className: 'pl-0',
    render: (invoice) => (<InvoiceActions invoice={invoice} refetchInvoices={refetchInvoices} editInvoice={editInvoice} />)
  }
]

InvoiceTable.propTypes = {
  type: PropTypes.oneOf(['cashin', 'cashout']),
  refetchInvoices: PropTypes.func
}

export default function InvoiceTable ({ type, refetchInvoices, ...rest }) {
  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState([])
  const [selectedInvoices, setSelectedInvoices] = useState([])
  const [editedInvoice, setEditedInvoice] = useState(undefined)
  const [editInvoiceDrawerVisible, setEditInvoiceDrawerVisible] = useState(false)

  const handleRowSelect = useCallback((selectedInvoiceIds, selectedInvoices) => {
    setSelectedInvoiceIds(selectedInvoiceIds)
    setSelectedInvoices(selectedInvoices)
  }, [setSelectedInvoiceIds, setSelectedInvoices])

  const onMultipleUpdateSuccess = useCallback(() => {
    setSelectedInvoiceIds([])
    setSelectedInvoices([])
  }, [setSelectedInvoiceIds, setSelectedInvoices])

  const editInvoiceCallback = useCallback(({ invoice }) => {
    setEditedInvoice(invoice)
    setEditInvoiceDrawerVisible(true)
  }, [setEditedInvoice, setEditInvoiceDrawerVisible])

  const onEditDrawerCloseCallback = useCallback(() => {
    setEditedInvoice(undefined)
    setEditInvoiceDrawerVisible(false)
    refetchInvoices()
  }, [setEditedInvoice, setEditInvoiceDrawerVisible, refetchInvoices])

  return (
    <>
      <MultipleInvoiceUpdate
        type={type}
        invoiceIds={selectedInvoiceIds}
        invoices={selectedInvoices}
        onSuccess={onMultipleUpdateSuccess}
        refetchInvoices={refetchInvoices}
      />

      <AccountingDocumentTable
        rowSelection={{
          selectedRowKeys: selectedInvoiceIds,
          onChange: handleRowSelect
        }}
        type={type}
        getColumns={getColumns}
        refetchAccountingDocuments={refetchInvoices}
        editAccountingDocument={editInvoiceCallback}
        {...rest}
      />

      <UpdateInvoiceManuallyDrawer type={type} invoiceId={editedInvoice?.id} visible={editInvoiceDrawerVisible} onClose={onEditDrawerCloseCallback} />
    </>
  )
}
