import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { get } from 'lodash'
import AppActions from 'actions/AppActions'

export default (options = {}) => {
  const dispatch = useDispatch()
  const tableExpandedCategories = useSelector(state => get(state, 'app.tableExpandedCategories', []))

  const handleExpandedRowsChange = useCallback((expandedRows) => {
    dispatch(AppActions.setTableExpandedCategories(expandedRows))
  }, [dispatch])

  const toggleExpandRow = useCallback((rowName) => {
    if (tableExpandedCategories.includes(rowName)) {
      const newExpandedRows = tableExpandedCategories.filter((expandedRowName) => expandedRowName !== rowName)
      dispatch(AppActions.setTableExpandedCategories(newExpandedRows))
    } else {
      dispatch(AppActions.setTableExpandedCategories([rowName, ...tableExpandedCategories]))
    }
  }, [dispatch, tableExpandedCategories])

  const expandable = {
    expandedRowKeys: tableExpandedCategories,
    onExpandedRowsChange: handleExpandedRowsChange,
    defaultExpandAllRows: true,
    toggleExpandRow,
    ...options
  }

  return expandable
}
