import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Card, Typography } from 'antd'

import CategoryRepartition from 'components/CategoryRepartition'
import CategorySelect from 'components/categories/CategorySelect'
import useJsonQueryParams from 'hooks/useJsonQueryParams'
import i18next from 'i18next'

const { t } = i18next

const titles = {
  cashin: t('component.categoryRepartitionCard.cashinBreakdown'),
  cashout: t('component.categoryRepartitionCard.cashoutBreakdown')
}

const CashInCharts = ({ type }) => {
  const [filters, setFilters] = useJsonQueryParams()

  const handleCategorySelectChange = useCallback((categoryId) => {
    const pieChartCategoryId = categoryId || undefined
    setFilters({ ...filters, pieChartCategoryId })
  }, [setFilters, filters])

  return (
    <Card bordered={false} className='rc-margin-top'>
      <Row gutter={80}>
        <Col xs={24} lg={12}>
          <Typography.Title level={4}>{titles[type]}</Typography.Title>
        </Col>

        <Col xs={24} lg={12}>
          <Row gutter={20}>
            <Col xs={24} lg={{ span: 12, offset: 12 /* To look like bottom filters */ }}>
              <CategorySelect
                type={type}
                defaultValue={filters.pieChartCategoryId}
                onChange={handleCategorySelectChange}
                style={{ width: '100%' }}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={80} className='rc-margin-top'>
        <Col xs={24} lg={12}>
          <CategoryRepartition type={type} categoryId={filters.pieChartCategoryId} uniqueKey='left' previous />
        </Col>

        <Col xs={24} lg={12}>
          <CategoryRepartition type={type} categoryId={filters.pieChartCategoryId} uniqueKey='right' />
        </Col>
      </Row>
    </Card>
  )
}

CashInCharts.propTypes = {
  type: PropTypes.oneOf(['cashin', 'cashout'])
}

export default CashInCharts
