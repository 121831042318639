import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import RecurringPaymentTable from './RecurringPaymentTable.js'
import usePagination from 'hooks/pagination/usePagination.js'
import { useQuery, useMutation } from '@apollo/client'
import { LIST_RECURRING_PAYMENTS, CATEGORIZE_RECURRING_PAYMENTS } from 'graphql/recurring-payments.js'
import ExpectedDocumentsConnectorList from 'components/connectors/ExpectedDocumentsConnectorList'
import useConnections from 'hooks/connections/useConnections.js'
import Button from 'design/Button.js'
import useChangeSyncRecurringPayment from 'hooks/useChangeSyncRecurringPayment.js'
import useListAndPollConnections from 'hooks/useListAndPollConnections'
import CashType from 'enums/CashType.js'
import useGetRecurringPaymentsCount from 'hooks/recurringPayments/useGetRecurringPaymentsCount.js'
import RecurringPaymentSearchInput from './RecurringPaymentSearchInput.js'
import useRecurringPaymentsQueryFilters from 'hooks/recurringPayments/useRecurringPaymentsQueryFilters.js'

const RecurringPaymentList = () => {
  const { pagination, page, pageSize, updatePaginationTotal, resetPagination } = usePagination()
  const [search, setSearch] = useState(null)
  const [stateFilter, setStateFilter] = useState('ONGOING')
  const [categorizeRecurringPayments] = useMutation(CATEGORIZE_RECURRING_PAYMENTS)
  const { sellsyConnection } = useConnections()
  const { t } = useTranslation()
  const { refetchRecurringPaymentsCount, recurringPaymentsCount } = useGetRecurringPaymentsCount()
  const { recurringPaymentsQueryFilters, mergeRecurringPaymentsQueryFilters } = useRecurringPaymentsQueryFilters()
  const [withIgnored, setWithIgnored] = useState(recurringPaymentsQueryFilters.withIgnored)

  const onSearchChanged = useCallback((text) => {
    setSearch(text)
    resetPagination()
  }, [setSearch, resetPagination])

  const onWithIgnoredChanged = useCallback((value) => {
    setWithIgnored(value)
    resetPagination()
  }, [resetPagination])

  const onStateFilterChanged = useCallback((value) => {
    setStateFilter(value || 'ALL')
    resetPagination()
  }, [setStateFilter, resetPagination])

  useEffect(() => {
    onWithIgnoredChanged(withIgnored)
    mergeRecurringPaymentsQueryFilters({ withIgnored })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onWithIgnoredChanged, withIgnored]) // add mergeRecurringPaymentsQueryFilters on dependencies create a loop

  const { loading, data, refetch } = useQuery(LIST_RECURRING_PAYMENTS, {
    variables: {
      offset: (page - 1) * pageSize,
      limit: pageSize,
      search,
      stateFilter: stateFilter === 'ALL' ? null : stateFilter,
      withIgnored
    },
    onCompleted: (data) => { updatePaginationTotal(data?.listRecurringPayments?.total) }
  })

  const onCategorySelected = useCallback(({ recurringPaymentId, categoryId }) => {
    categorizeRecurringPayments({
      variables: {
        input: {
          ids: [recurringPaymentId],
          categoryId: categoryId || null
        }
      }
    })
  }, [categorizeRecurringPayments])

  const { loading: loadingConnections, startPolling } = useListAndPollConnections({
    onEndPolling: () => {
      refetch()
      refetchRecurringPaymentsCount()
    }
  })

  const onChangeSyncRecurringPayment = useCallback(() => {
    startPolling(2000)
  }, [startPolling])

  const [changeSyncRecurringPayment] = useChangeSyncRecurringPayment(sellsyConnection, { onCompleted: onChangeSyncRecurringPayment })

  const hasSellsyConnection = sellsyConnection !== undefined
  const recurringPaymentNotSynchronised = sellsyConnection?.config?.hasSyncRecurringPayment !== true

  return (
    <>
      <div>
        <div className='flex flex-row items-center mb-3 flex-wrap justify-between'>
          <RecurringPaymentSearchInput
            onSearchChanged={onSearchChanged}
            onStateFilterChanged={onStateFilterChanged}
            stateFilter={stateFilter}
            loading={loading}
            onWithIgnoredChanged={onWithIgnoredChanged}
            withIgnored={withIgnored}
          />
          {((hasSellsyConnection && recurringPaymentNotSynchronised) || loadingConnections) &&
            <Button
              onClick={() => { changeSyncRecurringPayment(true) }}
              loading={loadingConnections}
              label={t('recurringPayment.SyncRecurringPaymentButton.title')}
            />}
          {!(hasSellsyConnection && recurringPaymentNotSynchronised) &&
            <ExpectedDocumentsConnectorList type={CashType.CASHIN} refetchInvoices={refetch} />}
        </div>
        <RecurringPaymentTable
          loading={loading}
          dataSource={data?.listRecurringPayments?.recurringPayments}
          pagination={pagination}
          onCategorySelected={onCategorySelected}
          recurringPaymentsCount={recurringPaymentsCount}
        />
      </div>
    </>
  )
}

export default RecurringPaymentList
