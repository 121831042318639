import PropTypes from 'prop-types'
import React, { useCallback, useMemo, useState } from 'react'

import { useLazyQuery, useMutation } from '@apollo/client'
import { AGGREGATE_INVOICE_IMPORTS, DELETE_INVOICE_FROM_UPLOAD } from 'graphql/invoices'

import { TrashIcon } from '@heroicons/react/24/outline'
import { Modal, Table } from 'antd'
import confirm from 'antd/lib/modal/confirm'
import Button from 'design/Button'
import ButtonLink from 'design/ButtonLink'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const ManageInvoiceImportsButton = React.forwardRef(({ type, refetchInvoices }, ref) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const [aggregateInvoiceImports, { data, loading }] = useLazyQuery(AGGREGATE_INVOICE_IMPORTS, {
    variables: { type }
  })

  const [deleteInvoicesFromUpload, { loading: deletionLoading }] = useMutation(DELETE_INVOICE_FROM_UPLOAD, {
    onCompleted: () => {
      aggregateInvoiceImports()
      refetchInvoices()
    }
  })

  const openManageModal = useCallback(() => {
    aggregateInvoiceImports()
    setVisible(true)
  }, [setVisible, aggregateInvoiceImports])

  const deleteImport = useCallback((externalImportId) => {
    confirm({
      title: t('component.manageInvoiceImportsButton.deleteImportConfirmation', { importType: type === 'cashin' ? t('shared.cashinPlural').toLowerCase() : t('shared.cashoutPlural').toLowerCase() }),
      okType: 'danger',
      okText: t('shared.delete'),
      cancelText: t('shared.no'),
      maskClosable: true,
      onOk () {
        deleteInvoicesFromUpload({ variables: { externalImportId } })
      }
    })
  }, [deleteInvoicesFromUpload, t, type])

  // const imports = useMemo(() => {
  //   return data?.aggregateInvoiceImports?.map((invoiceImport) => (
  //     {
  //       ...invoiceImport,
  //       formattedDate: moment(invoiceImport.date).format('DD/MM/YYYY HH:mm')
  //     }
  //   ))
  // }, [data])

  const columns = useMemo(() => {
    return [
      {
        title: t('shared.date'),
        dataIndex: 'date',
        render: (date) => <div className='font-bold'>{moment(date).format('DD/MM/YYYY')}</div>
      },
      {
        title: t('shared.time'),
        dataIndex: 'date',
        render: (date) => <div>{moment(date).format('HH[h]mm')}</div>
      },
      {
        title: type === 'cashin' ? t('component.manageInvoiceImportsButton.cashinEntriesCount') : t('component.manageInvoiceImportsButton.cashoutEntriesCount'),
        dataIndex: 'numberOfInvoice'
      },
      {
        title: t('component.manageInvoiceImportsButton.action'),
        dataIndex: 'externalImportId',
        align: 'center',
        render: (externalImportId) => (
          <ButtonLink
            disabled={deletionLoading}
            label={<TrashIcon className='text-error w-12 h-5' />}
            onClick={() => deleteImport(externalImportId)}
          />
        )
      }
    ]
  }, [t, type, deletionLoading, deleteImport])

  return (
    <div ref={ref}>
      <Button
        label={t('component.manageInvoiceImportsButton.manageImports')}
        onClick={openManageModal}
      />

      <Modal
        open={visible}
        onCancel={() => setVisible(false)}
        closable
        footer={null}
      >
        <div>
          <div className='font-bold mb-4'>{t('component.manageInvoiceImportsButton.importsHistory')}</div>

          <Table
            loading={loading || deletionLoading}
            size='small'
            columns={columns}
            dataSource={data?.aggregateInvoiceImports}
            rowKey='externalImportId'
            pagination={false}
          />
        </div>
      </Modal>
    </div>
  )
})

ManageInvoiceImportsButton.propTypes = {
  type: PropTypes.oneOf(['cashin', 'cashout']),
  refetchInvoices: PropTypes.func
}

export default ManageInvoiceImportsButton
