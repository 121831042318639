import React, { useContext, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDrop, useDrag } from 'react-dnd'
import { get, last, indexOf } from 'lodash'

import CategoryDndContext from './CategoryDndContext'
import CategoryLine from './CategoryLine'

const indentSize = 20

const DraggableCategory = ({ catId }) => {
  const dropRef = useRef(null)
  const dragRef = useRef(null)
  const dndContext = useContext(CategoryDndContext)

  const parentIds = useMemo(() => {
    return get(dndContext.parentIdsByCategoryId, catId, [])
  }, [dndContext.parentIdsByCategoryId, catId])

  const currentParentId = useMemo(() => {
    return last(parentIds)
  }, [parentIds])

  const currentIndex = useMemo(() => {
    const parentChildren = get(dndContext.categoriesByParentId, currentParentId, [])
    return indexOf(parentChildren, catId)
  }, [catId, currentParentId, dndContext.categoriesByParentId])

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: `category-${dndContext.type}`,
      id: catId,
      currentParentId,
      initialParentId: currentParentId,
      initialIndex: currentIndex
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    end: (item) => {
      if (item) {
        const parentId = item.currentParentId
        const initialParentId = item.initialParentId

        const index = currentIndex
        const initialIndex = item.initialIndex

        // TODO : Make sure initialParentId is same as backend even if moved before
        // TODO : Make sure initialIndex is same as backend even if moved before

        if (parentId !== initialParentId || index !== initialIndex) {
          dndContext.updateCategoryPosition(catId, parentId, index)
        }
      }
    }
  })

  const [{ isOverCurrent }, drop] = useDrop({
    accept: `category-${dndContext.type}`,
    collect: (monitor) => ({
      isOverCurrent: monitor.isOver({ shallow: true })
    }),
    hover (item, monitor) {
      if (!dropRef.current || !dragRef.current || !isOverCurrent) return

      if (catId === item.id) {
        handleLateralMovement({
          dragRef,
          dndContext,
          item,
          monitor
        })
      } else if (!parentIds.includes(item.id)) {
        handleVerticalMovement({
          catId,
          currentParentId,
          dropRef,
          dndContext,
          item,
          monitor
        })
      }
    }
  })

  const containerStyle = useMemo(() => {
    let marginTop = 0

    if (!currentParentId) {
      const globalIndex = dndContext.globalIndex.indexOf(catId)
      if (globalIndex !== 0) marginTop = 20
    }

    return {
      opacity: isDragging ? 0.2 : 1,
      marginTop,
      marginLeft: parentIds.length * indentSize,
      cursor: 'move'
    }
  }, [isDragging, currentParentId, parentIds, catId, dndContext.globalIndex])

  drag(dragRef)
  drop(dropRef)

  return (
    <div ref={dragRef} style={containerStyle}>
      <div ref={dropRef}>
        <CategoryLine type={dndContext.type} id={catId} />
      </div>
    </div>
  )
}

const handleLateralMovement = ({
  dragRef,
  dndContext,
  item,
  monitor
}) => {
  const sourceClientOffset = monitor.getSourceClientOffset()
  const boundingRect = dragRef.current.getBoundingClientRect()
  const movementX = sourceClientOffset.x - boundingRect.left

  if (movementX >= indentSize * 2) {
    handleSwipeRightMovement({ dndContext, item })
  } else if (movementX <= -indentSize * 2) {
    handleSwipeLeftMovement({ dndContext, item })
  }
}

const handleSwipeRightMovement = ({ dndContext, item }) => {
  const dragGlobalIndex = dndContext.globalIndex.indexOf(item.id)
  const topCatId = dndContext.globalIndex[dragGlobalIndex - 1]

  if (topCatId === item.currentParentId) return

  if (topCatId) {
    const topCatParentId = last(dndContext.parentIdsByCategoryId[topCatId])

    if (topCatParentId === item.currentParentId) {
      dndContext.moveCategory({
        id: item.id,
        currentParentId: item.currentParentId,
        parentId: topCatId,
        index: 0
      })
      item.currentParentId = topCatId
    } else {
      const topParentChildrenIds = dndContext.categoriesByParentId[topCatParentId]

      dndContext.moveCategory({
        id: item.id,
        currentParentId: item.currentParentId,
        parentId: topCatParentId,
        index: topParentChildrenIds.length
      })
      item.currentParentId = topCatParentId
    }
  }
}

const handleSwipeLeftMovement = ({ dndContext, item }) => {
  if (item.currentParentId === undefined) return

  const parentChildren = dndContext.categoriesByParentId[item.currentParentId]
  const isLastChild = parentChildren[parentChildren.length - 1] === item.id

  if (isLastChild) {
    const parentIds = dndContext.parentIdsByCategoryId[item.id]
    const previousParentId = parentIds[parentIds.length - 2]
    const previousParentChildren = dndContext.categoriesByParentId[previousParentId]
    const insertIndex = previousParentChildren.indexOf(item.currentParentId) + 1

    dndContext.moveCategory({
      id: item.id,
      currentParentId: item.currentParentId,
      parentId: previousParentId,
      index: insertIndex
    })
    item.currentParentId = previousParentId
  }
}

const handleVerticalMovement = ({
  catId,
  currentParentId,
  dropRef,
  dndContext,
  item,
  monitor
}) => {
  const dragGlobalIndex = dndContext.globalIndex.indexOf(item.id)
  const hoverGlobalIndex = dndContext.globalIndex.indexOf(catId)

  // Determine rectangle on screen
  const hoverBoundingRect = dropRef.current?.getBoundingClientRect()
  // Get vertical middle
  const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
  // Determine mouse position
  const clientOffset = monitor.getClientOffset()
  // Get pixels to the top
  const hoverClientY = clientOffset.y - hoverBoundingRect.top
  // Only perform the move when the mouse has crossed half of the items height
  // When dragging downwards, only move when the cursor is below 50%
  // When dragging upwards, only move when the cursor is above 50%
  // Dragging downwards
  if (dragGlobalIndex < hoverGlobalIndex && hoverClientY < hoverMiddleY) return
  // Dragging upwards
  if (dragGlobalIndex > hoverGlobalIndex && hoverClientY > hoverMiddleY) return

  const hoverIndex = dndContext.categoriesByParentId[currentParentId].indexOf(catId)

  dndContext.moveCategory({
    id: item.id,
    currentParentId: item.currentParentId,
    parentId: currentParentId,
    index: hoverIndex
  })

  item.currentParentId = currentParentId
}

DraggableCategory.propTypes = {
  catId: PropTypes.string
}

export default DraggableCategory
