import { useMemo, useCallback, useState } from 'react'
import { isNumber, toNumber } from 'lodash'

import OnboardingWelcomeScreen from 'containers/onboarding/OnboardingWelcomeScreen'
import OnboardingBankSetupScreen from 'containers/onboarding/OnboardingBankSetupScreen'
import OnboardingOptionsScreen from 'containers/onboarding/OnboardingOptionsScreen'
import OnboardingCashInCategoriesScreen from 'containers/onboarding/OnboardingCashInCategoriesScreen'
import OnboardingCashOutCategoriesScreen from 'containers/onboarding/OnboardingCashOutCategoriesScreen'
import OnboardingCategorizeFirstTransactions from 'containers/onboarding/OnboardingCategorizeFirstTransactions'
import featureConfig from 'config/features.js'

const steps = getSteps()

const getInitIndex = (initStepIndex) => {
  const numIndex = toNumber(initStepIndex)
  if (isNumber(numIndex) && numIndex > 0 && numIndex < steps.length) return numIndex

  return 0
}

export default ({ initStepIndex, onEndCallback }) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(getInitIndex(initStepIndex))

  const totalNumberOfSteps = steps.length

  const currentStep = useMemo(() => {
    return steps[currentStepIndex]
  }, [currentStepIndex])

  return {
    totalNumberOfSteps,
    currentStepIndex,
    currentStep,
    moveToPrevStep: useCallback(() => {
      if (currentStepIndex > 0 && currentStep.canMoveToPrev) {
        setCurrentStepIndex(currentStepIndex - 1)
      }
    }, [currentStepIndex, currentStep]),
    moveToNextStep: useCallback(() => {
      if (currentStepIndex < totalNumberOfSteps - 1) {
        setCurrentStepIndex(currentStepIndex + 1)
      } else {
        onEndCallback()
      }
    }, [currentStepIndex, totalNumberOfSteps, onEndCallback])
  }
}

function getSteps () {
  const steps = [
    {
      screen: OnboardingWelcomeScreen
    }
  ]

  if (featureConfig.powensConnectionEnabled) {
    steps.push({
      screen: OnboardingBankSetupScreen
    })
  }

  steps.push(...[
    {
      screen: OnboardingOptionsScreen
    },
    {
      screen: OnboardingCashInCategoriesScreen,
      canMoveToPrev: true
    },
    {
      screen: OnboardingCashOutCategoriesScreen,
      canMoveToPrev: true
    },
    {
      screen: OnboardingCategorizeFirstTransactions,
      canMoveToPrev: true
    }
  ])

  return steps
}
