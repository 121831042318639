import React, { useMemo } from 'react'
import { Select } from 'antd'

// import VatDateRuleSelect from 'components/vat/VatDateRuleSelect'
import VatPeriodSelect from 'components/vat/VatPeriodSelect'
import OnboardingHeader from 'components/onboarding/OnboardingHeader'
import OnboardingFooter from 'components/onboarding/OnboardingFooter'
import { businessTypes } from 'utils/enums'
import useAuthenticatedContext from 'hooks/useAuthenticatedContext'
import { UPDATE_COMPANY_INFOS, UPDATE_VAT_SETTINGS } from 'graphql/companies'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

export default () => {
  const { t } = useTranslation()
  const {
    company: {
      // vatDateRule,
      vatPeriod,
      businessType,
      numberOfEmployees
    }
  } = useAuthenticatedContext()

  const [updateVatSettings, { loading: updatingVatSettings }] = useMutation(UPDATE_VAT_SETTINGS)
  const [updateCompanyInfos, { loading: updatingCompanyInfos }] = useMutation(UPDATE_COMPANY_INFOS)

  const nextDisabled = useMemo(() => {
    return !vatPeriod || !businessType || !numberOfEmployees
    // return !vatDateRule || !vatPeriod || !businessType || !numberOfEmployees
  }, [vatPeriod, businessType, numberOfEmployees])
  // }, [vatDateRule, vatPeriod, businessType, numberOfEmployees])

  return (
    <div className='rc-onboarding-screen-container mt-20'>
      <OnboardingHeader title={t('onboarding.onboardingOptionsScreen.configureYourAccount')} />

      <div>
        {/* <div className='text-lg mt-4'>
          Votre TVA est exigible sur les
          <VatDateRuleSelect
            value={vatDateRule}
            placeholder='définir'
            bordered={false}
            dropdownMatchSelectWidth={false}
            onSelect={(value) => updateVatSettings({ variables: { input: { vatDateRule: value } } })}
            loading={updatingVatSettings}
            className='rc-primary-select'
          />
        </div> */}
        <div className='text-lg mt-4'>
          {t('onboarding.onboardingOptionsScreen.yourVatPeriodIs')}
          <VatPeriodSelect
            value={vatPeriod}
            placeholder={t('onboarding.onboardingOptionsScreen.define')}
            bordered={false}
            dropdownMatchSelectWidth={false}
            onSelect={(value) => updateVatSettings({ variables: { input: { vatPeriod: value } } })}
            loading={updatingVatSettings}
            className='rc-primary-select'
          />
        </div>
        <div className='text-lg mt-4'>
          {t('onboarding.onboardingOptionsScreen.yourBusinessTypeIs')}
          <Select
            value={businessType}
            placeholder={t('onboarding.onboardingOptionsScreen.define')}
            bordered={false}
            dropdownMatchSelectWidth={false}
            onSelect={(value) => updateCompanyInfos({ variables: { input: { businessType: value } } })}
            loading={updatingCompanyInfos}
            className='rc-primary-select'
          >
            {businessTypes.map((bt) => (
              <Select.Option key={bt} value={bt}>
                {t(`utils.enums.businessTypesLocales.${bt}`)}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className='text-lg mt-4'>
          {t('onboarding.onboardingOptionsScreen.yourNumberOfEmployeesIs')}
          <Select
            value={numberOfEmployees}
            placeholder={t('onboarding.onboardingOptionsScreen.define')}
            bordered={false}
            dropdownMatchSelectWidth={false}
            onSelect={(value) => updateCompanyInfos({ variables: { input: { numberOfEmployees: value } } })}
            loading={updatingCompanyInfos}
            className='rc-primary-select'
          >
            <Select.Option value={10}>
              {t('onboarding.onboardingOptionsScreen.1to10Employees')}
            </Select.Option>
            <Select.Option value={20}>
              {t('onboarding.onboardingOptionsScreen.11to20Employees')}
            </Select.Option>
            <Select.Option value={30}>
              {t('onboarding.onboardingOptionsScreen.21to30Employees')}
            </Select.Option>
            <Select.Option value={40}>
              {t('onboarding.onboardingOptionsScreen.31to40Employees')}
            </Select.Option>
            <Select.Option value={50}>
              {t('onboarding.onboardingOptionsScreen.41to50Employees')}
            </Select.Option>
            <Select.Option value={100}>
              {t('onboarding.onboardingOptionsScreen.51to100Employees')}
            </Select.Option>
            <Select.Option value={200}>
              {t('onboarding.onboardingOptionsScreen.100to200Employees')}
            </Select.Option>
            <Select.Option value={1000}>
              {t('onboarding.onboardingOptionsScreen.moreThan200Employees')}
            </Select.Option>
          </Select>
        </div>
      </div>

      <OnboardingFooter next nextDisabled={nextDisabled} />
    </div>
  )
}
