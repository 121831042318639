import { gql } from '@apollo/client'

export const TOGGLE_SCENARIO_WITH_VAT_DETAILS = gql`
mutation toggleScenarioWithVatDetails($input: ToggleScenarioWithVatDetailsInput!) {
  toggleScenarioWithVatDetails(input: $input) {
    id
    withVatDetails
  }
}
`

export const LIST_SCENARIOS = gql`
query listScenarios {
    listScenarios {
      id
      name
      withVatDetails
      expectedQuoteStatuses
      includeQuotes
      includeRecurringPayments
      expectedOrderStatuses
      includeOrders
    }
  }
`

export const SET_EXPECTED_QUOTE_STATUSES = gql`
mutation setExpectedQuoteStatuses($input: SetExpectedQuoteStatusesInput!) {
  setExpectedQuoteStatuses(input: $input) {
    id
    name
    withVatDetails
    expectedQuoteStatuses
    includeQuotes
    includeRecurringPayments
    expectedOrderStatuses
    includeOrders
  }
}
`

export const DELETE_SCENARIO = gql`
mutation deleteScenario($scenarioId: ID!) {
  deleteScenario(scenarioId: $scenarioId)
}
`

export const TOOGLE_SCENARIO_INCLUDE_QUOTES = gql`
mutation toggleScenarioIncludeQuotes($input: ToggleScenarioIncludeQuotesInput!) {
  toggleScenarioIncludeQuotes(input: $input) {
    id
    includeQuotes
  }
}
`

export const TOGGLE_SCENARIO_INCLUDE_RECURRING_PAYMENTS = gql`
mutation toggleScenarioIncludeRecurringPayments($input: ToggleScenarioIncludeRecurringPaymentsInput!) {
  toggleScenarioIncludeRecurringPayments(input: $input) {
    id
    includeRecurringPayments
  }
}
`

export const SET_EXPECTED_ORDER_STATUSES = gql`
mutation setExpectedOrderStatuses($input: SetExpectedOrderStatusesInput!) {
  setExpectedOrderStatuses(input: $input) {
    id
    name
    withVatDetails
    expectedQuoteStatuses
    includeQuotes
    includeRecurringPayments
    expectedOrderStatuses
    includeOrders
  }
}
`

export const TOOGLE_SCENARIO_INCLUDE_ORDERS = gql`
mutation toggleScenarioIncludeOrders($input: ToggleScenarioIncludeOrdersInput!) {
  toggleScenarioIncludeOrders(input: $input) {
    id
    includeOrders
  }
}
`
