import React from 'react'
import { get } from 'lodash'
import useAuthenticatedContext from 'hooks/useAuthenticatedContext'
import { SYNC_BANK_CONNECTION } from 'graphql/bank-connections.js'
import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import { Tooltip, message } from 'antd'
import { ArrowPathIcon } from '@heroicons/react/24/solid'
import { useTranslation, Trans } from 'react-i18next'
import ButtonLink from 'design/ButtonLink'
import Badge from 'design/Badge.js'
import { Link } from 'react-router-dom'
import useManageBankConnections from 'hooks/useManageBankConnections.js'
import Spinner from 'design/Spinner.js'

const SyncBankConnectionButton = ({ bankConnection }) => {
  const { t } = useTranslation()
  const { subscription } = useAuthenticatedContext()
  const { redirectToManageAccountsUrl } = useManageBankConnections()
  const hasAccessToManualTriggerSyncAccount = !subscription || subscription.hasAccessToManualTriggerSyncAccount
  const status = get(bankConnection, 'status')
  const bankConnectionStatusError = status !== 'OK'
  const buttonEnable = !bankConnectionStatusError && hasAccessToManualTriggerSyncAccount

  const [syncPowensConnection, { loading: syncPowensConnectionLoading }] = useMutation(SYNC_BANK_CONNECTION, {
    onError: (error) => {
      const errorCode = get(error, 'graphQLErrors.0.extensions.code')
      switch (errorCode) {
        case 'BANK_SYNC_RATE_LIMIT_ERROR':
          message.error(t('component.settingsOverlay.syncPowensRateLimitError'))
          break
      }
    },
    onCompleted: (result) => {
      message.warning(t('component.settingsOverlay.syncPowensLaunched'))
    }
  })

  if (bankConnection?.externalSource !== 'POWENS') {
    return null
  }

  if (syncPowensConnectionLoading) {
    return (<Spinner className='w-4 h-4 mr-1' />)
  }

  let tooltipTitle = t('component.bankConnectionSettings.triggerSyncPowensConnection')
  if (!hasAccessToManualTriggerSyncAccount) {
    tooltipTitle = (
      <div>
        <Trans
          i18nKey='component.bankConnectionSettings.triggerSyncPowensConnection.noAccess'
          components={{
            Badge: <Badge type='info' label={t('dashboard.cashflowSheet.pro')} className='text-xs ml-1' />,
            ReadMore: <Link to='/subscribe?r=sb' />
          }}
        />
      </div>
    )
  } else if (bankConnectionStatusError) {
    tooltipTitle = (
      <div>
        <Trans
          i18nKey='component.bankConnectionSettings.triggerSyncPowensConnection.statusError'
          components={{ Link: <Link onClick={redirectToManageAccountsUrl} /> }}
        />
      </div>
    )
  }

  const buttonColor = buttonEnable ? 'hover:text-black' : ''

  return (
    <Tooltip
      placement='top'
      title={tooltipTitle}
    >
      <ButtonLink
        onClick={(e) => {
          e.stopPropagation()
          if (buttonEnable) {
            syncPowensConnection({ variables: { id: bankConnection.id } })
          }
        }}
        loading={syncPowensConnectionLoading}
        label={<ArrowPathIcon className={'w-4 h-4 mr-1 text-gray-300 ' + buttonColor} />}
      />
    </Tooltip>
  )
}

SyncBankConnectionButton.propTypes = {
  bankConnection: PropTypes.object
}

export default SyncBankConnectionButton
