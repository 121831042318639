import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/solid'
import cx from 'classnames'
import Spinner from 'design/Spinner'
import { useMutation } from '@apollo/client'
import { ANNOTATE_TRANSACTION } from 'graphql/transactions'
import PopoverTextAreaInput from 'components/PopoverTextAreaInput'
import { useTranslation } from 'react-i18next'

TransactionNoteInput.propTypes = {
  transaction: PropTypes.shape({
    id: PropTypes.string.isRequired,
    note: PropTypes.string
  }),
  className: PropTypes.string
}

export default function TransactionNoteInput ({ transaction, className }) {
  const { t } = useTranslation()
  const [annotateTransactionMutation, { loading }] = useMutation(ANNOTATE_TRANSACTION)

  const annotateTransaction = useCallback(({ value, successCallback }) => {
    annotateTransactionMutation({
      variables: {
        id: transaction.id,
        note: value
      }
    }).then(successCallback)
  }, [annotateTransactionMutation, transaction.id])

  return (
    <PopoverTextAreaInput
      placement='rightTop'
      value={transaction?.note}
      placeholder={t('component.transactionNoteInput.notePlaceholder')}
      onSave={annotateTransaction}
      className={cx('rc-show-on-table-row-hover', className)}
      inputContainerClassname='w-80'
    >
      {(loading && (
        <div className='w-6 h-6 flex items-center justify-center'><Spinner className='w-4 h-4 m-0.5 text-primary' /></div>
      )) || (
        <ChatBubbleBottomCenterTextIcon
          onClick={(e) => { e.stopPropagation() }}
          className={cx('w-6 h-6 hover:text-primary cursor-pointer', {
            visible: !!transaction.note,
            'text-gray-400': !transaction.note,
            'text-primary': !!transaction.note
          })}
        />
      )}
    </PopoverTextAreaInput>
  )
}
