import React from 'react'
import PropTypes from 'prop-types'
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts'
import Colors from 'utils/colors'
import { get } from 'lodash'

import VATTooltip from './VATTooltip'
import SimpleBar from './shapes/SimpleBar'

export default function VATOverviewChart ({ data }) {
  if (get(data, 'length', 0) === 0) return null

  return (
    <ResponsiveContainer>
      <BarChart
        layout='vertical'
        data={data}
        barSize={12}
        barGap={9}
      >
        <XAxis type='number' hide />
        <YAxis type='category' hide />

        <Tooltip
          cursor={false}
          content={({ payload }) => (
            <VATTooltip payload={{ title: 'TVA', vatIn: data[0].vatIn, vatOut: data[0].vatOut }} />
          )}
        />

        <Bar
          type='monotone'
          dataKey='vatIn'
          shape={<SimpleBar forceHeight={12} />}
          fill={Colors.revenue}
          minPointSize={6}
        />

        <Bar
          type='monotone'
          dataKey='vatOut'
          shape={<SimpleBar forceHeight={12} />}
          fill={Colors.expense}
          minPointSize={6}
        />
      </BarChart>
    </ResponsiveContainer>
  )
}

VATOverviewChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object)
}
