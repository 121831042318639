import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import Button from 'design/Button'
import { useMutation } from '@apollo/client'
import { flatMap } from 'lodash'
import { MARK_EXPECTED_TRANSACTIONS_AS_INVOICED } from 'graphql/expected-transactions'
import CategorySelect from 'components/categories/CategorySelect'
import { CATEGORIZE_MULTIPLE_EXPECTED_TRANSACTION_DETAILS } from 'graphql/expected-transaction-details'
import CategoryTagFromId from 'components/categories/CategoryTagFromId'
import { Modal } from 'antd'
import MultipleExpectedTransactionExpectedDatePicker from 'components/expected-transactions/MultipleExpectedTransactionExpectedDatePicker'
import { useTranslation, Trans } from 'react-i18next'
import { IGNORE_ORDERS } from 'graphql/orders.js'

MultipleOrderUpdate.propTypes = {
  type: PropTypes.oneOf(['cashin']),
  orderIds: PropTypes.arrayOf(PropTypes.string),
  orders: PropTypes.arrayOf(PropTypes.object),
  onSuccess: PropTypes.func,
  refetchOrders: PropTypes.func
}

function MultipleOrderUpdate ({ type, orderIds, orders, onSuccess, refetchOrders }) {
  const { t } = useTranslation()
  const [selectionHasBeenIgnored, setSelectionHasBeenIgnored] = useState(false)
  const [selectionHasBeenInvoiced, setSelectionHasBeenInvoiced] = useState(false)
  const [categorizeModalVisible, setCategorizeModalVisible] = useState(false)
  const [selectedCategoryId, setSelectedCategoryId] = useState(undefined)

  const expectedTransactions = useMemo(() => {
    const expectedTransactions = flatMap(orders, (order) => order.expectedTransactions)
    return expectedTransactions
  }, [orders])
  const expectedTransactionIds = useMemo(() => expectedTransactions.map((expTxn) => expTxn.id), [expectedTransactions])

  const hasOnlyPaidExpectedTransactions = useMemo(() => {
    // all expected transactions have a payment date
    return expectedTransactions.every((expTxn) => expTxn.paymentDate)
  }, [expectedTransactions])

  const hasNotPaid = useMemo(() => {
    return selectionHasBeenInvoiced ? hasOnlyPaidExpectedTransactions : !hasOnlyPaidExpectedTransactions
  }, [selectionHasBeenInvoiced, hasOnlyPaidExpectedTransactions])

  const hasNotIgnored = useMemo(() => {
    return selectionHasBeenIgnored ? !orders.every((order) => order.ignored) : orders.some((order) => !order.ignored)
  }, [orders, selectionHasBeenIgnored])

  useEffect(() => {
    setSelectionHasBeenIgnored(false)
    setSelectionHasBeenInvoiced(false)
  }, [orderIds, setSelectionHasBeenIgnored])

  const [ignoreOrders, { loading: ignoreLoading }] = useMutation(IGNORE_ORDERS, {
    variables: { input: { orderIds } },
    onCompleted: () => {
      setSelectionHasBeenIgnored(!selectionHasBeenIgnored)
      onSuccess()
    }
  })

  const cancelIgnoreOrders = useCallback(() => {
    ignoreOrders({ variables: { input: { orderIds, cancel: true } } })
  }, [ignoreOrders, orderIds])

  const [markExpectedTransactionAsInvoiced, { loading: markAsInvoicedLoading }] = useMutation(MARK_EXPECTED_TRANSACTIONS_AS_INVOICED, {
    variables: { input: { expectedTransactionIds, cancel: !hasNotPaid } },
    onCompleted: () => {
      setSelectionHasBeenInvoiced(!selectionHasBeenInvoiced)
      onSuccess()
    }
  })

  const [categorizeMultipleExpectedTransactionDetails, { loading: categorizeExpTxnDetailsLoading }] = useMutation(CATEGORIZE_MULTIPLE_EXPECTED_TRANSACTION_DETAILS, {
    onCompleted: () => {
      setCategorizeModalVisible(false)
      setSelectedCategoryId(undefined)
      onSuccess()
    }
  })

  const expectedTransactionDetailIds = useMemo(() => {
    const expectedTransactionDetails = flatMap(expectedTransactions, (expectedTransaction) => expectedTransaction.expectedTransactionDetails)
    const expectedTransactionDetailIds = expectedTransactionDetails.map((expTxnDetail) => expTxnDetail.id)
    return expectedTransactionDetailIds
  }, [expectedTransactions])

  if (orderIds.length === 0) return null

  return (
    <div className='rc-multiple-update flex flex-row items-center space-x-2'>
      <MultipleExpectedTransactionExpectedDatePicker
        expectedTransactions={expectedTransactions}
        onCompleted={onSuccess}
      />

      <CategorySelect
        type={type}
        className='w-64'
        size='large'
        withEmpty
        emptyLabel={t('shared.uncategorize')}
        loading={categorizeExpTxnDetailsLoading}
        disabled={categorizeExpTxnDetailsLoading}
        value={selectedCategoryId}
        onChange={(categoryId) => {
          setSelectedCategoryId(categoryId)
          setCategorizeModalVisible(true)
        }}
      />

      <Modal
        centered
        open={categorizeModalVisible}
        onCancel={() => setCategorizeModalVisible(false)}
        footer={null}
      >
        <div className='mb-4 font-bold'>
          {
            selectedCategoryId !== 'null' ? (
              <Trans
                i18nKey='component.multipleOrderUpdate.warningAssignCategoryToAllSelected'
                components={
                  { CategoryTag: <CategoryTagFromId id={selectedCategoryId} /> }
                }
              />
            ) : (
              <Trans
                i18nKey='component.multipleOrderUpdate.warningUnCategorizeAllSelected'
              />
            )
          }
        </div>
        <div className='w-full flex flex-row justify-end space-x-2'>
          <Button
            label={t('shared.cancel')}
            onClick={() => {
              setSelectedCategoryId(undefined)
              setCategorizeModalVisible(false)
            }}
          />

          <Button
            primary
            label={selectedCategoryId !== 'null' ? t('shared.categorizeSelection') : t('shared.uncategorizeSelection')}
            loading={categorizeExpTxnDetailsLoading}
            onClick={() => {
              categorizeMultipleExpectedTransactionDetails({ variables: { ids: expectedTransactionDetailIds, categoryId: selectedCategoryId === 'null' ? null : selectedCategoryId } })
            }}
          />
        </div>
      </Modal>

      {(hasNotIgnored && (
        <Button
          label={t('shared.ignore')}
          onClick={ignoreOrders}
          loading={ignoreLoading}
        />
      )) || (
        <Button
          label={t('shared.unIgnore')}
          onClick={cancelIgnoreOrders}
          loading={ignoreLoading}
        />
      )}

      {(hasNotPaid && (
        <Button
          label={t('component.multipleOrderUpdate.markAsInvoiced')}
          onClick={markExpectedTransactionAsInvoiced}
          loading={markAsInvoicedLoading}
        />
      )) || (
        <Button
          label={t('component.multipleOrderUpdate.unMarkAsInvoiced')}
          onClick={markExpectedTransactionAsInvoiced}
          loading={markAsInvoicedLoading}
        />
      )}
    </div>
  )
}

export default MultipleOrderUpdate
