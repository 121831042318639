import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Tooltip } from 'antd'
import classNames from 'classnames'
import ExpectedBar from 'components/charts/shapes/ExpectedBar'
import ForecastBar from 'components/charts/shapes/ForecastBar'
import RealisedBar from 'components/charts/shapes/RealisedBar'
import Currency from 'components/Currency'
import ShortcutContainer from 'design/ShortcutContainer.js'
import { max } from 'lodash'
import numeral from 'numeral'
import colors from 'utils/colors'
import ScenarioCommentIndicator from '../../budgeted/ScenarioCommentIndicator.js'

CashflowSheetComparisonCell.propTypes = {
  fill: PropTypes.string,
  realisedAmount: PropTypes.number,
  expectedAmount: PropTypes.number,
  outstandingExpectedAmount: PropTypes.number,
  forecastedAmount: PropTypes.number,
  comment: PropTypes.string,
  noBar: PropTypes.bool,
  sign: PropTypes.bool,
  className: PropTypes.string,
  scenarioCommentNode: PropTypes.node,
  budgetedCellNode: PropTypes.node,
  forecastedValueIsBudgeted: PropTypes.bool,
  onClickLeft: PropTypes.func,
  onClickRight: PropTypes.func,
  forecastShortCutNode: PropTypes.node,
  openDrawerNode: PropTypes.node
}

CashflowSheetComparisonCell.defaultProps = {
  fill: colors.primary
}

export default function CashflowSheetComparisonCell ({
  fill,
  realisedAmount,
  expectedAmount,
  outstandingExpectedAmount,
  forecastedAmount,
  comment,
  noBar,
  sign,
  className,
  scenarioCommentNode,
  budgetedCellNode,
  forecastedValueIsBudgeted,
  onClickLeft,
  onClickRight,
  forecastShortCutNode,
  openDrawerNode
}) {
  const { t } = useTranslation()
  const computedExpectedAmount = useMemo(() => {
    return outstandingExpectedAmount ? numeral(realisedAmount).add(outstandingExpectedAmount).value() : expectedAmount
  }, [realisedAmount, expectedAmount, outstandingExpectedAmount])

  const maxAmount = useMemo(() => {
    return max([realisedAmount, computedExpectedAmount, forecastedAmount])
  }, [realisedAmount, computedExpectedAmount, forecastedAmount])

  const realisedWidth = useMemo(() => {
    const percent = ((realisedAmount || 0) / (maxAmount || 1)) * 100
    return `${percent}%`
  }, [realisedAmount, maxAmount])

  const expectedWidth = useMemo(() => {
    const percent = ((computedExpectedAmount || 0) / (maxAmount || 1)) * 100
    return `${percent}%`
  }, [computedExpectedAmount, maxAmount])

  const forecastedIsRealised = useMemo(() => {
    return (forecastedAmount === realisedAmount)
  }, [realisedAmount, forecastedAmount])

  const budgetForComparison = useMemo(() => {
    return forecastedIsRealised || forecastedValueIsBudgeted
  }, [forecastedIsRealised, forecastedValueIsBudgeted])

  return (
    <div className={classNames('h-full flex flex-col justify-center', className)}>
      <div className='h-full flex flex-row items-stretch justify-between text-xs text-left'>
        <div onClick={onClickLeft} className={classNames('w-1/2 max-w-1/2 flex flex-row items-center pl-2 pr-1', { 'cursor-pointer': !!onClickLeft })}>
          <Tooltip
            title={t('dashboard.cashflowSheetComparisonCell.realized')}
            placement='left'
            // mouseEnterDelay={0.7}
            className='w-full'
          >
            <Currency
              maskZero
              amount={realisedAmount}
              className={classNames({ 'text-gray-400': !realisedAmount })}
              options={{ sign }}
            />
          </Tooltip>
        </div>

        <div
          onClick={budgetForComparison ? null : onClickRight} className={classNames('w-1/2 max-w-1/2 flex flex-row items-center justify-end pl-1 pr-2 group/shortcut', {
            'cursor-pointer': !!onClickRight,
            'opacity-50 group-hover:opacity-100': forecastedIsRealised
          })}
        >
          <Tooltip
            title={budgetForComparison ? t('dashboard.cashflowSheetComparisonCell.budget') : t('dashboard.cashflowSheetComparisonCell.forecasted')}
            placement='right'
            className='w-full flex flex-row items-center justify-end relative'
          >
            <ScenarioCommentIndicator comment={comment} className='mr-1' />

            <div className='flex flex-row items-center justify-end relative'>
              <ShortcutContainer className='absolute right-full mr-1'>
                {openDrawerNode}

                {forecastShortCutNode}

                {scenarioCommentNode}
              </ShortcutContainer>

              {(budgetForComparison && (
                budgetedCellNode
              )) || (
                <Currency
                  maskZero
                  amount={forecastedAmount}
                  className={classNames('min-w-max', { 'text-gray-400': !forecastedAmount })}
                  options={{ sign }}
                />
              )}
            </div>

          </Tooltip>
        </div>
      </div>

      {!noBar && (
        <svg
          width='100%'
          height={6}
          className={classNames('bg-white absolute left-0 right-0 bottom-0 opacity-50 group-hover:opacity-100 transition-all z-10 group-hover:z-20', {
            'group-hover:-bottom-1.5': budgetedCellNode
          })}
        >
          <ForecastBar fill={fill} x={0} y={0} width='100%' height='100%' value={1} noTopBar />
          <ExpectedBar fill={fill} x={0} y={0} width={expectedWidth} height='100%' value={1} />
          <RealisedBar fill={fill} x={0} y={0} width={realisedWidth} height='100%' value={1} />
        </svg>
      )}
    </div>
  )
}
