import { get } from 'lodash'
import { useMemo } from 'react'
import useAuthenticatedContext from './useAuthenticatedContext'

export default function useHiddenAccountIds () {
  const { company } = useAuthenticatedContext()

  const hiddenAccountIds = useMemo(() => get(company, 'hiddenAccountIds', []), [company])
  return hiddenAccountIds
}
