
import PropTypes from 'prop-types'
import InvoiceEditDrawer from './InvoiceEditDrawer'
import React, { useState, useCallback } from 'react'
import { GET_INVOICE, UPDATE_INVOICE_MANUALLY } from 'graphql/invoices'
import { useQuery, useMutation } from '@apollo/client'
import { isUndefined } from 'lodash'

UpdateInvoiceManuallyDrawer.propTypes = {
  invoiceId: PropTypes.string,
  type: PropTypes.string,
  visible: PropTypes.bool,
  onClose: PropTypes.func
}

function UpdateInvoiceManuallyDrawer ({ invoiceId, type, visible, onClose }) {
  const [invoice, setInvoice] = useState(undefined)
  const [updateInvoiceManually, { loading: updateLoading }] = useMutation(UPDATE_INVOICE_MANUALLY, {
    onCompleted: () => {
      onClose()
    }
  })

  useQuery(GET_INVOICE, {
    variables: {
      id: invoiceId
    },
    skip: isUndefined(invoiceId),
    onCompleted: (data) => {
      setInvoice(data.getInvoice)
    }
  }, [setInvoice, invoiceId])

  const onFinishCallback = useCallback(({ invoiceParams }) => {
    updateInvoiceManually({
      variables: {
        input: {
          ...invoiceParams,
          id: invoiceId,
          type
        }
      }
    })
  }, [updateInvoiceManually, invoiceId, type])

  return (<InvoiceEditDrawer mode='update' type={type} invoice={invoice} loading={updateLoading} visible={visible} onFinish={onFinishCallback} onClose={onClose} />)
}

export default UpdateInvoiceManuallyDrawer
