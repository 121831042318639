import { isConnectionSyncing } from 'utils/connections'
import { SYNC_CONNECTION } from 'graphql/connections'
import { useMutation } from '@apollo/client'
import { useMemo } from 'react'

export default ({ connection, startPolling }) => {
  const [syncConnection, { loading }] = useMutation(SYNC_CONNECTION, {
    variables: { connectionId: connection?.id, connectionSource: connection?.externalSource },
    onCompleted: ({ syncConnection }) => {
      if (isConnectionSyncing(syncConnection)) startPolling(2000)
    }
  })

  const syncing = useMemo(() => {
    return isConnectionSyncing(connection) || loading
  }, [connection, loading])

  return {
    syncConnection,
    syncing
  }
}
