import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import CategoryTagFromId from 'components/categories/CategoryTagFromId'
import Title from 'design/Title'
import CashflowSheetDrawerMonthNavigator from './CashflowSheetDrawerMonthNavigator'

CashflowSheetDrawerTitle.propTypes = {
  rowType: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  sheetDate: PropTypes.object,
  sheetDates: PropTypes.arrayOf(PropTypes.object),
  drawerParams: PropTypes.object,
  setDrawerParams: PropTypes.func
}

export default function CashflowSheetDrawerTitle ({ rowType, value, sheetDate, sheetDates, drawerParams, setDrawerParams }) {
  const { t } = useTranslation()
  const label = useMemo(() => {
    switch (rowType) {
      case 'CASH_IN':
        return t('shared.cashinPlural')
      case 'CASH_OUT':
        return t('shared.cashoutPlural')
      default:
        return null
    }
  }, [rowType, t])

  const labelClassname = useMemo(() => {
    switch (rowType) {
      case 'CASH_IN':
        return 'rc-highlight-text-cashin'
      case 'CASH_OUT':
        return 'rc-highlight-text-cashout'
      default:
        return null
    }
  }, [rowType])

  return (
    <div className='w-full flex flex-row items-center space-x-4'>
      <div className='w-full flex flex-row items-center space-x-4'>
        {label && (<Title label={label} className={labelClassname} />)}

        {value.categoryId && (
          <CategoryTagFromId id={value.categoryId} className='max-w-max self-center' />
        )}
      </div>

      <CashflowSheetDrawerMonthNavigator sheetDate={sheetDate} sheetDates={sheetDates} drawerParams={drawerParams} setDrawerParams={setDrawerParams} />
    </div>
  )
}
