import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { Table, Tooltip } from 'antd'
import classNames from 'classnames'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useContext, useMemo, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import AppActions from 'actions/AppActions'
import CategoryTagFromId from 'components/categories/CategoryTagFromId'
import CashflowSheetContext from 'contexts/CashflowSheetContext'
import Badge from 'design/Badge'
import useAuthenticatedContext from 'hooks/useAuthenticatedContext'
import useCategoryExpandable from 'hooks/useCategoryExpandable'
import CashflowSheetDrawer from './CashflowSheetDrawer'
import CashflowSheetCashFlowCell from './cells/CashflowSheetCashFlowCell'
import CashflowSheetCategoryCell from './cells/CashflowSheetCategoryCell'
import CashflowSheetEndingBalanceCell from './cells/CashflowSheetEndingBalanceCell'
import CashflowSheetStartingBalanceCell from './cells/CashflowSheetStartingBalanceCell'
import CashflowSheetTotalCell from './cells/CashflowSheetTotalCell'
import CashflowSheetTypeCell from './cells/CashflowSheetTypeCell'
import CashflowSheetVatPaymentCell from './cells/CashflowSheetVatPaymentCell'
import VatDetailsScenarioSetting from './scenario/VatDetailsScenarioSetting'
import { useTranslation, Trans } from 'react-i18next'
import i18next from 'i18next'

CashflowSheet.propTypes = {
  sheet: PropTypes.array,
  sheetDates: PropTypes.array,
  realisedVsForecasted: PropTypes.bool,
  queryFilters: PropTypes.object,
  mergeQueryFilters: PropTypes.func
}

// const pushWithQueryParams = usePushLocationWithJsonQueryParams()

export default function CashflowSheet ({ sheet, sheetDates, queryFilters, mergeQueryFilters, ...rest }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const memorizedHideTotal = useSelector(state => get(state, 'app.dashboard.hideTotal', false))

  const { subscription } = useAuthenticatedContext()
  const { loadingCashflowSheet } = useContext(CashflowSheetContext)

  const [hideTotal, setHideTotal] = useState(memorizedHideTotal)
  const toggleHideTotal = useCallback(() => setHideTotal(!hideTotal), [hideTotal, setHideTotal])

  const realisedVsForecasted = useSelector(state => get(state, 'app.dashboard.realisedVsForecasted', false))
  const showPercentage = useSelector(state => get(state, 'app.dashboard.showPercentage', false))

  const [drawerParams, setDrawerParams] = useState({
    visible: false,
    rowAbsolutePath: '-1',
    valueIndex: -1,
    tabIndex: null
  })

  const expandable = useCategoryExpandable()

  useEffect(() => {
    if (memorizedHideTotal !== hideTotal) {
      dispatch(AppActions.setDashboardHideTotal(hideTotal))
    }
  }, [dispatch, hideTotal, memorizedHideTotal])

  const columns = useMemo(() => {
    return getColumns({ sheetDates, queryFilters, mergeQueryFilters, setDrawerParams, realisedVsForecasted, toggleExpandRow: expandable.toggleExpandRow, hideTotal, toggleHideTotal, subscription, showPercentage })
  }, [sheetDates, queryFilters, mergeQueryFilters, setDrawerParams, realisedVsForecasted, expandable.toggleExpandRow, hideTotal, toggleHideTotal, subscription, showPercentage])

  const compactSheet = useMemo(() => sheet.filter((row) => {
    const hiddenCashin = (row.type === 'CASHIN_CATEGORY' && queryFilters.cashinCategoriesHidden)
    const hiddenCashout = (row.type === 'CASHOUT_CATEGORY' && queryFilters.cashoutCategoriesHidden)
    return !hiddenCashin && !hiddenCashout
  }), [sheet, queryFilters.cashinCategoriesHidden, queryFilters.cashoutCategoriesHidden])

  return (
    <div className='relative'>
      <Table
        rowKey={(row) => row.categoryId || row.type}
        dataSource={compactSheet}
        loading={loadingCashflowSheet}
        columns={columns}
        bordered
        indentSize={20}
        size='small'
        expandable={expandable}
        pagination={false}
        rowClassName={getRowClassName}
        scroll={{ y: 600, x: 'max-content' }}
        {...rest}
      />

      <CashflowSheetDrawer
        sheet={sheet}
        sheetDates={sheetDates}
        drawerParams={drawerParams}
        setDrawerParams={setDrawerParams}
        scenarioId={queryFilters.scenarioId}
      />

      {hideTotal && (
        <Tooltip title={t('dashboard.cashflowSheet.displayTotalOverPeriod')} placement='left'>
          <div onClick={toggleHideTotal} className='absolute right-1 top-1 h-8 px-0.5 flex flex-row items-center cursor-pointer bg-white rounded border border-r-0 border-gray-100 z-10'>
            <ChevronLeftIcon className={classNames('w-4 h-4 text-gray-400')} />
          </div>
        </Tooltip>
      )}
    </div>

  )
}

const { t } = i18next

const getRowClassName = (record) => {
  if (get(record, '_id')) return

  switch (get(record, 'type')) {
    case 'STARTING_BALANCE':
      return 'rc-cf-st-row rc-cashflow-row'
    case 'CASH_IN':
      return 'rc-cf-st-row rc-cashin-row'
    case 'CASH_OUT':
      return 'rc-cf-st-row rc-cashout-row'
    case 'CASHIN_CATEGORY':
    case 'CASHOUT_CATEGORY':
      return 'rc-cf-st-row'
    case 'CASH_FLOW':
      return 'rc-cf-st-row rc-secondary-row'
    case 'ENDING_BALANCE':
      return 'rc-cf-st-row rc-cashflow-row'
    default:
      return 'rc-cf-st-row'
  }
}

const getColumns = ({ sheetDates, queryFilters, mergeQueryFilters, setDrawerParams, realisedVsForecasted, toggleExpandRow, hideTotal, toggleHideTotal, subscription, showPercentage }) => {
  const columns = []

  columns.push({
    // title: exportButton,
    key: 'name',
    fixed: 'left',
    width: 280,
    ellipsis: true,
    onCell: (row) => ({
      onClick: () => {
        if (['CASHIN_CATEGORY', 'CASHOUT_CATEGORY'].includes(row.type)) {
          toggleExpandRow(row.categoryId)
        } else if (row.type === 'CASH_IN') {
          mergeQueryFilters({ cashinCategoriesHidden: !queryFilters.cashinCategoriesHidden })
        } else if (row.type === 'CASH_OUT') {
          mergeQueryFilters({ cashoutCategoriesHidden: !queryFilters.cashoutCategoriesHidden })
        }
      }
      // colSpan: row.type === 'VAT_PAYMENTS' ? 5 : 1
    }),
    className: 'cursor-pointer z-30',
    render: (row) => renderName({ row, queryFilters, subscription })
  })

  sheetDates.forEach((sheetDate, valueIndex) => {
    columns.push({
      title: (
        <div className='flex flex-col items-center text-sm font-semibold text-base-color text-center p-2.5'>
          <span>
            {sheetDate.momentDate.format('MMM YYYY')}
            {sheetDate.isCurrentMonth && (<span className='text-gray-400 text-xs font-normal ml-2'>{t('dashboard.cashflowSheet.ongoing')}</span>)}
          </span>
        </div>
      ),
      key: sheetDate.date,
      width: (sheetDate.isCurrentMonth || (realisedVsForecasted && sheetDate.isPastMonth)) ? 250 : 140,
      className: classNames('group border-rounded-lg hover:shadow-lg p-0 h-10', {
        'rc-table-primary-column': sheetDate.isCurrentMonth,
        'rc-table-secondary-column': !sheetDate.isCurrentMonth
      }),
      render: (row) => renderMonthValue({ queryFilters, row, valueIndex, sheetDate, realisedVsForecasted, setDrawerParams, showPercentage })
    })
  })

  if (!hideTotal) {
    columns.push({
      title: (
        <div onClick={toggleHideTotal} className='cursor-pointer flex flex-row items-center justify-center'>
          <ChevronRightIcon className='w-4 h-4 text-gray-400 absolute left-2' />
          <span>{t('dashboard.cashflowSheet.total')}</span>
          <span className='text-gray-400 text-xs font-normal ml-2'>{t('dashboard.cashflowSheet.period')}</span>
        </div>
      ),
      key: 'total',
      fixed: 'right',
      width: hideTotal ? 20 : 200,
      ellipsis: true,
      className: 'group text-right p-0 z-20 h-10',
      // onCell: (row) => ({
      //   colSpan: row.type === 'VAT_PAYMENTS' ? 0 : 1
      // }),
      render: (row) => renderTotal({ row, hideTotal, realisedVsForecasted, showPercentage })
    })
  }

  return columns
}

const renderName = ({ row, queryFilters, subscription }) => {
  if (['CASHIN_CATEGORY', 'CASHOUT_CATEGORY'].includes(row.type)) {
    return <CategoryTagFromId id={row.categoryId} />
  }

  if (row.type === 'VAT_PAYMENTS') {
    const noExpanded = queryFilters?.cashinCategoriesHidden && queryFilters?.cashoutCategoriesHidden

    if (subscription && !subscription.hasAccessToVatDetails) {
      return (
        // 206px stands for the space around the CashflowSheet on the dashboard
        <Link to='/subscribe?r=v' className='h-full w-[calc(100vw-206px)] flex-grow flex flex-row items-center absolute left-0 top-0 right-0 bottom-0 z-40 pr-52 group hover:bg-white hover:bg-opacity-60'>
          <div className={classNames('font-semibold text-sm text-base-color px-8 opacity-50 group-hover:opacity-100', {
            'px-8': !noExpanded,
            'px-2': noExpanded
          })}
          >{get(row, 'name')}
          </div>
          <div className='h-full font-semibold text-sm text-base-color flex-grow flex items-center justify-center invisible group-hover:visible'>
            <Trans
              i18nKey='dashboard.cashflowSheet.vatProFeature'
              components={{
                Badge: <Badge type='info' label={t('dashboard.cashflowSheet.pro')} className='text-xs ml-1' />,
                ReadMore: <span className='text-primary hover:underline ml-1' />
              }}
            />
          </div>
        </Link>
      )
    }

    return (
      <div className='group overflow-visible absolute top-0 bottom-0 left-0 right-0 flex items-center text-sm text-base-color font-semibold space-x-2'>
        <span className={classNames({
          'pl-8': !noExpanded,
          'pl-2': noExpanded
        })}
        >
          {t('dashboard.cashflowSheet.vatEstimation')}
        </span>

        <VatDetailsScenarioSetting queryFilters={queryFilters} />
      </div>
    )
  }

  return (
    <div className='font-semibold text-sm text-base-color flex flex-row items-center'>
      {row.type === 'CASH_IN' && (
        (queryFilters.cashinCategoriesHidden && (
          <ChevronRightIcon className={classNames('w-4 h-4 text-gray-400 mr-2', { 'absolute left-2': !queryFilters.cashinCategoriesHidden || !queryFilters.cashoutCategoriesHidden })} />
        )) || (
          <ChevronDownIcon className='w-4 h-4 text-gray-400 absolute left-2' />
        )
      )}
      {row.type === 'CASH_OUT' && (
        (queryFilters.cashoutCategoriesHidden && (
          <ChevronRightIcon className={classNames('w-4 h-4 text-gray-400 mr-2', { 'absolute left-2': !queryFilters.cashinCategoriesHidden || !queryFilters.cashoutCategoriesHidden })} />
        )) || (
          <ChevronDownIcon className='w-4 h-4 text-gray-400 absolute left-2' />
        )
      )}
      {get(row, 'name')}
    </div>)
}

const renderMonthValue = ({ queryFilters, row, valueIndex, sheetDate, realisedVsForecasted, setDrawerParams, showPercentage }) => {
  const { type, sheet } = row

  const value = sheet?.[valueIndex]?.value || {}

  switch (type) {
    case 'STARTING_BALANCE': {
      return (
        <CashflowSheetStartingBalanceCell
          sheetDate={sheetDate}
          value={value}
          realisedVsForecasted={realisedVsForecasted}
        />
      )
    }

    case 'CASH_IN':
    case 'CASH_OUT': {
      return (
        <CashflowSheetTypeCell
          row={row}
          sheetDate={sheetDate}
          value={value}
          valueIndex={valueIndex}
          setDrawerParams={setDrawerParams}
          realisedVsForecasted={realisedVsForecasted}
          showPercentage={showPercentage}
        />
      )
    }

    case 'CASHIN_CATEGORY':
    case 'CASHOUT_CATEGORY': {
      return (
        <CashflowSheetCategoryCell
          row={row}
          sheetDate={sheetDate}
          value={value}
          valueIndex={valueIndex}
          queryFilters={queryFilters}
          setDrawerParams={setDrawerParams}
        />
      )
    }

    case 'VAT_PAYMENTS': {
      return (
        <CashflowSheetVatPaymentCell
          sheetDate={sheetDate}
          value={value}
          realisedVsForecasted={realisedVsForecasted}
        />
      )
    }

    case 'CASH_FLOW': {
      return (
        <CashflowSheetCashFlowCell
          sheetDate={sheetDate}
          value={value}
          realisedVsForecasted={realisedVsForecasted}
          showPercentage={showPercentage}
        />
      )
    }

    case 'ENDING_BALANCE': {
      return (
        <CashflowSheetEndingBalanceCell
          sheetDate={sheetDate}
          value={value}
          realisedVsForecasted={realisedVsForecasted}
        />
      )
    }
  }

  return null
}

function renderTotal ({ row, hideTotal, realisedVsForecasted, showPercentage }) {
  // if (hideTotal || ['VAT_PAYMENTS'].includes(row.type)) return null
  if (hideTotal) return null

  return (
    <CashflowSheetTotalCell row={row} realisedVsForecasted={realisedVsForecasted} showPercentage={showPercentage} />
  )
}
