import { useCallback, useEffect, useMemo, useState } from 'react'
import { LIST_CONNECTIONS } from 'graphql/connections'
import { useQuery } from '@apollo/client'
import { isConnectionSyncing } from 'utils/connections'

export default function useListAndPollConnections ({ onEndPolling } = {}) {
  const [polling, setPolling] = useState(false)

  const onCompleted = useCallback((data) => {
    const connections = data?.connections || []
    const oneIsSyncing = connections.find(isConnectionSyncing)

    if (!oneIsSyncing && polling) {
      setPolling(false)
      if (onEndPolling) onEndPolling()
    } else if (oneIsSyncing && !polling) setPolling(true)
  }, [polling, setPolling, onEndPolling])

  const { loading, data, startPolling, stopPolling, refetch } = useQuery(LIST_CONNECTIONS, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 0,
    onCompleted,
    notifyOnNetworkStatusChange: true
  })

  useEffect(() => {
    if (polling) startPolling(2000)
    else stopPolling()
    return stopPolling
  }, [polling, startPolling, stopPolling])

  const connections = useMemo(() => data?.connections || [], [data])

  return {
    connections,
    loading,
    startPolling: refetch
  }
}
