
import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import useUpdateMultipleTransactions from 'hooks/useUpdateMultipleTransactions'
import { useDebouncedCallback } from 'use-debounce'
import { useTranslation } from 'react-i18next'
import Button from 'design/Button'

ValidateAllButton.defaultProps = {
  lean: false
}

ValidateAllButton.propTypes = {
  transactionIds: PropTypes.arrayOf(PropTypes.string),
  onSuccess: PropTypes.func
}

function ValidateAllButton ({ transactionIds, onSuccess }) {
  const { t } = useTranslation()
  const [success, setSuccess] = useState(false)
  const [batchUpdateTransactionsMutation, { loading }] = useUpdateMultipleTransactions({
    onSuccessCallback: () => {
      setSuccess(false)
      if (onSuccess) onSuccess()
    }
  })

  const validateTransactions = useCallback((validated) => {
    if (validated) {
      batchUpdateTransactionsMutation({
        variables: { ids: transactionIds, properties: { validated: true } }
      })
    }
  }, [batchUpdateTransactionsMutation, transactionIds])

  const [debouncedUpdateTransactions] = useDebouncedCallback(validateTransactions, 750)

  const handleOnClick = useCallback(() => {
    const newSuccess = !success
    setSuccess(newSuccess)
    debouncedUpdateTransactions(newSuccess)
  }, [setSuccess, success, debouncedUpdateTransactions])

  const label = useMemo(() => {
    return transactionIds?.length
      ? t('component.validateAllButton.validateAllCount', { count: transactionIds?.length })
      : t('component.validateAllButton.validateAll')
  }, [t, transactionIds?.length])

  return (
    <Button
      label={label}
      onClick={handleOnClick}
      loading={loading}
      disabled={loading || !transactionIds?.length}
    />
  )
}

export default ValidateAllButton
