import { createActions } from 'redux-actions'

export default createActions({
  SET_IS_AUTHENTICATED: (isAuthenticated) => ({ isAuthenticated }),
  SET_COUNTRY: (country) => ({ country }),
  SET_REALISED_VS_SCENARIO: (realisedVsForecasted) => ({ realisedVsForecasted }),
  SET_DASHBOARD_SHOW_PERCENTAGE: (showPercentage) => ({ showPercentage }),
  SET_DASHBOARD_HIDE_TOTAL: (hideTotal) => ({ hideTotal }),
  SET_SHOW_SCENARIO: (showScenario) => ({ showScenario }),
  SET_PAGE_SIZE: (pageSize) => ({ pageSize }),
  SET_LAST_ACCOUNT_MANAGEMENT_AT: (accountManagedAt) => ({ accountManagedAt }),
  SET_LAST_SELLSY_MANAGEMENT_AT: (sellsyManagedAt) => ({ sellsyManagedAt }),
  SET_DASHBOARD_QUERY_FILTERS: (queryFilters) => ({ queryFilters }),
  SET_INVOICES_QUERY_FILTERS: (invoicesQueryFilters) => ({ invoicesQueryFilters }),
  SET_QUOTES_QUERY_FILTERS: (quotesQueryFilters) => ({ quotesQueryFilters }),
  SET_TABLE_EXPANDED_CATEGORIES: (tableExpandedCategories) => ({ tableExpandedCategories }),
  SET_RECURRING_PAYMENTS_QUERY_FILTERS: (recurringPaymentsQueryFilters) => ({ recurringPaymentsQueryFilters }),
  SET_ORDERS_QUERY_FILTERS: (ordersQueryFilters) => ({ ordersQueryFilters })
})
