import { notification } from 'antd'
import i18next from 'i18next'
import { get } from 'lodash'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { authenticate, authenticateSellsyUserOrRedirect, authenticateSellsyUserOrSignUp, forgotPassword, resetPassword, signUp, authenticateFromTemporaryUserToken, logout } from 'utils/api'
import AggregationsActions from 'actions/AggregationsActions'
import appActions from 'actions/AppActions'
import authActions from 'actions/AuthActions'
import { GET_ACTIONS_LEFT } from 'graphql/aggregations'
import { GET_INTERCOM_USER_HASH, ME } from 'graphql/users'
import apolloClient from 'utils/apollo-client'

export default [
  takeLatest(authActions.requestSignIn, requestSignInSaga),
  takeLatest(authActions.requestSignInSuccess, requestSignInSuccessSaga),
  takeLatest(authActions.requestSignInError, requestSignInErrorSaga),
  takeLatest(authActions.authenticateSellsyUserOrRedirect, authenticateSellsyUserOrRedirectSaga),
  takeLatest(authActions.authenticateSellsyUserOrSignUp, authenticateSellsyUserOrSignUpSaga),
  takeLatest(authActions.authenticateFromTemporaryUserToken, authenticateFromTemporaryUserTokenSaga),
  takeLatest(authActions.requestSignUp, requestSignUpSaga),
  takeLatest(authActions.requestSignUpSuccess, requestSignUpSuccessSaga),
  takeLatest(authActions.requestSignUpError, requestSignUpErrorSaga),
  takeLatest(authActions.sendResetInstructions, sendResetInstructionsSaga),
  takeLatest(authActions.resetPassword, resetPasswordSaga),
  takeLatest(authActions.signOut, signOutSaga)
]

function * requestSignInSaga ({ payload }) {
  const { email, password, firstSignIn } = payload

  try {
    yield call(authenticate, { email, password })
    yield put(authActions.requestSignInSuccess(firstSignIn))
  } catch (error) {
    yield put(authActions.requestSignInError({
      status: get(error, 'response.statusCode', 0),
      message: get(error, 'response.message')
    }))
  }
}

function * requestSignInSuccessSaga ({ payload: { firstSignIn } }) {
  yield put(appActions.setIsAuthenticated(true))
  const { company, user, subscription } = yield loadAuthenticatedContext()

  if (!company || !user) return

  yield put(authActions.hasFetchUser())

  // LOAD MORE
  yield updateActionsLeftAndNotify({ company })

  // TRACKING
  yield trackUserSignIn({ company, user, subscription, firstSignIn })
}

function * requestSignInErrorSaga () {
  yield put(appActions.setIsAuthenticated(false))
}

function * loadAuthenticatedContext () {
  try {
    const meData = yield call(apolloClient.query, {
      query: ME,
      fetchPolicy: 'network-only'
    })

    const company = get(meData, 'data.me.company')
    const user = get(meData, 'data.me')
    const subscription = get(meData, 'data.me.company.subscription')

    yield put(authActions.setCompanyId(company?.id))
    yield put(appActions.setCountry(company?.country))

    return { company, user, subscription }
  } catch {
    yield put(authActions.signOut())
    return {}
  }
}

// SELLSY SSO
function * authenticateSellsyUserOrRedirectSaga ({ payload: { webToken, onError, onComplete } }) {
  try {
    const response = yield call(authenticateSellsyUserOrRedirect, { webToken })

    if (response.data.authenticated) {
      yield put(authActions.requestSignInSuccess())
      if (onComplete) onComplete()
    } else if (response.data.sellsyOauthRedirectUrl) {
      window.location.assign(response.data.sellsyOauthRedirectUrl)
    } else {
      throw Error('Unknown error while connecting with Sellsy account (with webToken).')
    }
  } catch (error) {
    onError(error)
  }
}

function * authenticateSellsyUserOrSignUpSaga ({ payload: { authorizationCode, onError } }) {
  try {
    const response = yield call(authenticateSellsyUserOrSignUp, { authorizationCode })

    if (response.data.authenticated) {
      yield put(authActions.requestSignInSuccess(response.data.signedUp))
    } else {
      throw Error('Unknown error while connecting with Sellsy account.')
    }
  } catch (error) {
    onError(error)
  }
}

// AUTH FROM TEMPORARY TOKEN
function * authenticateFromTemporaryUserTokenSaga ({ payload: { temporaryUserToken, onError, onComplete, ensureLogoutBefore } }) {
  try {
    if (ensureLogoutBefore) {
      const isAuthenticated = yield select((state) => get(state, 'app.isAuthenticated', false))
      if (isAuthenticated) yield signOutSaga()
    }
    yield call(authenticateFromTemporaryUserToken, { temporaryUserToken })
    yield put(authActions.requestSignInSuccess())
    if (onComplete) onComplete()
  } catch (error) {
    onError(error)
  }
}

// SIGN UP
function * requestSignUpSaga ({ payload: { params, onError } }) {
  try {
    yield call(signUp, params)
    yield put(authActions.requestSignUpSuccess({ params }))
  } catch (error) {
    yield put(authActions.requestSignUpError())
    yield call(onError, error)
    // yield put(authActions.requestSignInError({
    //   status: get(error, 'response.statusCode', 0),
    //   message: get(error, 'response.message'),
    //   details: get(error, 'response.data.details')
    // }))
  }
}

function * requestSignUpSuccessSaga ({ payload: { params: { email, password } } }) {
  yield put(authActions.requestSignIn(email, password, true))
}

function * requestSignUpErrorSaga () {
  yield put(appActions.setIsAuthenticated(false))
}

// SIGN OUT
function * signOutSaga () {
  yield put(appActions.setIsAuthenticated(false))
  yield call(logout)
  /**
   * this call throw an error : Unhandled Rejection (TypeError): Cannot read property 'queryManager' of undefined
   * @see https://github.com/apollographql/apollo-client/issues/4970
   * and resetStore is not a good solution beaccuse refetch queries
   */
  try {
    yield call(apolloClient.clearStore)
  } catch (error) {
  }
}

function * sendResetInstructionsSaga ({ payload: { params, onSuccess, onError } }) {
  try {
    yield call(forgotPassword, params)
    yield call(onSuccess)
    yield put(authActions.sendResetInstructionsSuccess())
  } catch (error) {
    yield call(onError, error)
    yield put(authActions.sendResetInstructionsError(error))
  }
}

function * resetPasswordSaga ({ payload: { params, onSuccess, onError } }) {
  try {
    yield call(resetPassword, params)
    yield call(onSuccess)
    yield put(authActions.resetPasswordSuccess())
  } catch (error) {
    yield call(onError, error)
    yield put(authActions.resetPasswordError(get(error, 'response.data.details')))
  }
}

function * trackUserSignIn ({ company, user, subscription, firstSignIn }) {
  if (window.Intercom) {
    const { data } = yield call(apolloClient.query, { query: GET_INTERCOM_USER_HASH })
    const hash = data?.getIntercomUserHash

    const params = { user_hash: hash, email: user.email }

    params.name = `${user.firstName} ${user.lastName}`

    params['RC - Email'] = user.email
    params['RC - Company ID'] = company.id
    params['RC - Company Name'] = company.name
    params['RC - User ID'] = user.id
    params['RC - Sign Up Source'] = company.signUpSource
    params['RC - Subscription Type'] = company.subscriptionType
    params['RC - Subscription Status'] = subscription ? 'Client' : 'Free trial'

    if (user.phone) params['RC - Phone'] = user.phone

    window.Intercom('update', params)
  }

  /* eslint-disable no-undef */
  if (_cio) {
    _cio.identify({ id: company?.id })
    _cio.track('Session Started')
  }

  if (firstSignIn) gtmTrackSignUp({ company })
}

const gtmTrackSignUp = ({ company }) => {
  if (window.dataLayer) window.dataLayer.push({ userId: company.id, event: 'trialStarted' })
}

function * updateActionsLeftAndNotify ({ company }) {
  const { t } = i18next
  const { data: { actionsLeft } } = yield call(apolloClient.query, { query: GET_ACTIONS_LEFT })
  yield put(AggregationsActions.updateActionsLeft(actionsLeft))

  const categorizationLeft = get(actionsLeft, 'category', 0)
  const onboardingEndAt = get(company, 'onboardingEndAt')

  if (categorizationLeft > 0 && onboardingEndAt) {
    const description = t('sagas.authSagas.stillNTransactionsToCategorize', { categorizationLeft })
    notification.info({ message: t('sagas.authSagas.autoCategorization'), description, duration: 7 })
  }
}
