import React from 'react'
import { Select } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import cx from 'classnames'

import SearchInput from 'design/SearchInput.js'
import SearchActions from 'components/search/SearchActions.js'

AccountingDocumentSearchInput.propTypes = {
  states: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      className: PropTypes.string
    }).isRequired
  ),
  currentState: PropTypes.string,
  handleStateChange: PropTypes.func,
  handleSearchChange: PropTypes.func,
  withIgnored: PropTypes.bool,
  handleWithIgnoredChange: PropTypes.func,
  loading: PropTypes.bool
}

function AccountingDocumentSearchInput ({
  states,
  currentState,
  handleStateChange,
  handleSearchChange,
  withIgnored,
  handleWithIgnoredChange,
  loading
}) {
  const StateFilterSelector = (
    <Select
      suffixIcon={<DownOutlined style={{ pointerEvents: 'none' }} />}
      onChange={handleStateChange}
      value={currentState}
      className={cx('w-[105px] mr-2 rc-invoice-state-filter rounded-[6px] transition-none rc-select-no-border', states[currentState]?.className)}
      dropdownStyle={{ padding: 0 }}
    >
      {Object.entries(states).map(([state, stateParams]) => (
        <Select.Option
          key={state}
          value={state}
          className={stateParams.className}
        >
          {stateParams.label}
        </Select.Option>
      ))}
    </Select>
  )

  return (
    <div className='flex flex-row items-center'>
      <SearchInput left={StateFilterSelector} onSearchChanged={handleSearchChange} />

      <SearchActions withIgnored={withIgnored} handleWithIgnoredChange={handleWithIgnoredChange} loading={loading} />
    </div>

  )
}

export default AccountingDocumentSearchInput
