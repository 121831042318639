import { LockOutlined } from '@ant-design/icons'
import { Card, Checkbox, Form, Input, message } from 'antd'
import i18next from 'i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { useMutation } from '@apollo/client'
import authActions from 'actions/AuthActions'
import Button from 'design/Button'
import { SET_PASSWORD } from 'graphql/users'
import useSearchParams from 'hooks/useSearchParams'
import { get } from 'lodash'
import { Redirect } from 'react-router'

const validateCgu = (rule, value, callback) => {
  const { t } = i18next
  if (value !== true) {
    callback(new Error(t('setPassword.error.validateTerms')))
  } else {
    callback()
  }
}

const SetPasswordScreen = () => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { token } = useSearchParams()
  const [values, setValues] = useState({})
  const isSigningIn = useSelector((state) => get(state, 'auth.isSigningIn'))
  const isAuthenticated = useSelector(state => get(state, 'app.isAuthenticated'))

  const [ensureHasLogOut, setEnsureHasLogout] = useState(!isAuthenticated)

  useEffect(() => {
    if (!ensureHasLogOut && isAuthenticated) {
      dispatch(authActions.signOut())
    } else if (!ensureHasLogOut && !isAuthenticated) {
      setEnsureHasLogout(true)
    }
  }, [dispatch, ensureHasLogOut, isAuthenticated, setEnsureHasLogout])

  const [setPassword, { loading: setPasswordLoading }] = useMutation(SET_PASSWORD, {
    variables: {
      inviteToken: token,
      password: values.password
    },
    onError: ({ graphQLErrors }) => {
      const errorCode = get(graphQLErrors, '0.extensions.code')

      switch (errorCode) {
        case 'PASSWORD_ALREADY_SET':
          message.warning({ content: t('setPassword.error.PASSWORD_ALREADY_SET'), duration: 15 })
          break
        default:
          message.error({ content: t('setPassword.error.setPasswordDefaultError'), duration: 15 })
          break
      }
    },
    onCompleted: (data) => {
      const email = data?.setPassword?.email
      dispatch(authActions.requestSignIn(email, values.password))
    }
  })

  const validatePasswordMatch = useCallback((rule, value, callback) => {
    if (value && value !== form.getFieldValue('password')) {
      callback(new Error(t('setPassword.error.notIdentical')))
    } else {
      callback()
    }
  }, [form, t])

  const loading = useMemo(() => {
    return setPasswordLoading || isSigningIn
  }, [setPasswordLoading, isSigningIn])

  const disabled = useMemo(() => !values.password || !values.passwordConfirmation, [values])

  if (ensureHasLogOut && isAuthenticated) {
    return <Redirect to='/' />
  }

  return (
    <div className='rc-full-page-box-container'>
      <a href='/signin' className='rc-signin-logo-container'>
        <img src='/logo_sellsy_tresorerie.svg' alt='Sellsy Trésorerie logo' className='rc-signin-logo' />
      </a>

      <Card bordered={false} className='w-full p-4 max-w-md'>
        <div className='text-black font-bold text-lg mb-4 mt-4'>{t('setPassword.passwordCreation')}</div>

        <Form
          form={form}
          layout='vertical'
          onFinish={setPassword}
          onValuesChange={(_, values) => setValues(values)}
          hideRequiredMark
          validateTrigger='onBlur'
          className='w-full flex flex-col p-0'
        >
          <Form.Item
            label={t('shared.password')}
            name='password'
            rules={[
              { required: true, message: t('setPassword.error.newPasswordRequired') },
              { min: 8, message: t('setPassword.error.minChars') }
            ]}
          >
            <Input.Password
              autoFocus
              size='large'
              prefix={<LockOutlined style={{ color: 'rgba(0, 0, 0, .25)' }} />}
              placeholder={t('shared.password')}
            />
          </Form.Item>

          <Form.Item
            label={t('setPassword.confirmation')}
            name='passwordConfirmation'
            rules={[
              { required: true, message: t('setPassword.enterYourPasswordAgain') },
              { validator: validatePasswordMatch }
            ]}
          >
            <Input.Password
              size='large'
              prefix={<LockOutlined style={{ color: 'rgba(0, 0, 0, .25)' }} />}
              placeholder={t('setPassword.passwordConfirmation')}
            />
          </Form.Item>

          <Form.Item
            validateTrigger='onChange'
            name='cgu'
            valuePropName='checked'
            rules={[
              { validator: validateCgu }
            ]}
          >
            <Checkbox className='text-gray-400 text-justify'>
              <Trans
                i18nKey='setPassword.checkbox.validateTerms'
                components={{
                  Link: <a href={t('setPassword.termsLink')} target='_blank' rel='noopener noreferrer'>{t('setPassword.terms')}</a>
                }}
              />
            </Checkbox>
          </Form.Item>

          <Form.Item className='mb-0 mt-2'>
            <Button primary disabled={disabled} loading={loading} type='submit' label={t('setPassword.button.createPassword')} />
          </Form.Item>
        </Form>
      </Card>
    </div>
  )
}

export default SetPasswordScreen
