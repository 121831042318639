import { takeLatest, put, call } from 'redux-saga/effects'
import { get } from 'lodash'

import { getVatBalance } from 'utils/api'
import { actions as vatActions } from 'reducers/vat-reducer'

export default [
  takeLatest(vatActions.requestCurrentVatBalance, requestCurrentVatBalanceSaga),
  takeLatest(vatActions.requestYearVatBalance, requestYearVatBalanceSaga)
]

function * requestCurrentVatBalanceSaga () {
  try {
    const response = yield call(getVatBalance)
    yield put(vatActions.requestCurrentVatBalanceSuccess(response.data[0]))
  } catch (error) {
    yield put(vatActions.requestCurrentVatBalanceError(get(error, 'response.data.details')))
  }
}

function * requestYearVatBalanceSaga ({ payload: { year } }) {
  try {
    const response = yield call(getVatBalance, { year })
    yield put(vatActions.requestYearVatBalanceSuccess(year, response.data))
  } catch (error) {
    yield put(vatActions.requestYearVatBalanceError(get(error, 'response.data.details')))
  }
}
