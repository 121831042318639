
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button, Input, Row, Col, Form } from 'antd'
import { useSelector } from 'react-redux'
import { get, sample } from 'lodash'
import { generate } from '@ant-design/colors'

import { categoryColors } from 'utils/colors'
import ColorSelect from 'components/colors/ColorSelect'
import VatSelect from 'components/vat/VatSelect'

import { CheckOutlined } from '@ant-design/icons'
import CategorySelect from './CategorySelect'
import { useTranslation } from 'react-i18next'

StandaloneCategoryForm.propTypes = {
  type: PropTypes.oneOf(['cashin', 'cashout']),
  onSubmit: PropTypes.func,
  submitting: PropTypes.bool
}

export default function StandaloneCategoryForm ({ onSubmit, type, submitting, ...rest }) {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const parentId = form.getFieldValue('parentId')
  const parentColor = useSelector(state => get(state, `categories.resources.${parentId}.color`))

  const palette = useMemo(() => {
    if (parentColor) return generate(parentColor)
    return categoryColors
  }, [parentColor])

  const sampleColor = useMemo(() => sample(palette), [palette])

  return (
    <Form form={form} onFinish={(values) => onSubmit(values, form.resetFields)} layout='vertical' {...rest}>
      <div className='w-half absolute'>
        <Form.Item name='color' initialValue={sampleColor} rules={[{ required: true, message: t('component.standaloneCategoryForm.pleaseChooseAColor') }]} className='rc-no-shadow'>
          <ColorSelect palette={palette} />
        </Form.Item>
      </div>

      <Row gutter={20}>
        <Col xs={{ offset: 2, span: 22 }}>
          <Form.Item name='name' rules={[{ required: true, message: t('component.standaloneCategoryForm.pleaseNameCategory') }]} className='rc-no-shadow'>
            <Input type='text' placeholder={t('shared.name')} className='w-full' autoFocus />
          </Form.Item>
        </Col>
      </Row>

      <div className='rc-optional-label mt-1'>{t('shared.optional')}</div>
      <Form.Item name='parentId'>
        <CategorySelect type={type} placeholder={t('component.standaloneCategoryForm.parentCategory')} />
      </Form.Item>

      <div className='rc-optional-label mt-1'>{t('shared.optional')}</div>
      <Form.Item name='vatRate' align='center' className='rc-no-shadow'>
        <VatSelect style={{ width: 120 }} allowClear className='w-full' />
      </Form.Item>

      <Form.Item>
        <Button
          htmlType='submit'
          type='primary'
          loading={submitting}
          disabled={submitting}
          icon={<CheckOutlined />}
        >
          {t('shared.create')}
        </Button>
      </Form.Item>
    </Form>
  )
}
