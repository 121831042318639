
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Spinner from './Spinner'
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid'

ActionsIcon.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string
}

export default function ActionsIcon ({ loading, className, ...rest }) {
  if (loading) return <Spinner className={classNames('m-1 w-4 h-4 text-gray-400 self-center', className)} {...rest} />

  return <EllipsisVerticalIcon className={classNames('w-6 h-6 text-gray-400 hover:opacity-75 cursor-pointer', className)} {...rest} />
}
