import React, { useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { Divider, Row, Col, Empty } from 'antd'
import i18next from 'i18next'

import { get, sum } from 'lodash'
import moment from 'moment'

import aggregationsActions from 'actions/AggregationsActions'
import CategoryPieChart from 'components/charts/CategoryPieChart'
import YearSelect from 'components/YearSelect'
import VatToggle from 'components/vat/VatToggle'
import MonthSelect from 'components/MonthSelect'
import { getCategoriesInTreeSelector } from 'selectors/categories'
import { getTotal } from 'selectors/aggregations'
import colors from 'utils/colors'
import useJsonQueryParams from 'hooks/useJsonQueryParams'

const now = moment()
const { t } = i18next
const todayYear = now.year()
const todayMonth = now.month() + 1
const lastMonthDate = moment(now).subtract(1, 'month')
const lastMonthYear = lastMonthDate.year()
const lastMonth = lastMonthDate.month() + 1

const findMainCategory = (categoryWithChildren, categoryId) => {
  if (!categoryId) return categoryWithChildren

  const children = get(categoryWithChildren, 'children') || []

  const foundCat = children.find((cat) => cat.id === categoryId)
  if (foundCat) return foundCat

  return children.reduce((acc, cat) => acc || findMainCategory(cat, categoryId), undefined)
}

const findCatTotal = (cat, totalPerCategory) =>
  totalPerCategory.find(
    (catTotal) => get(catTotal, 'categoryId') === cat.id
  ) || {}

const mergeTotalInCategories = (mainCategory, totalPerCategory) => {
  const mainCategoryTotal = findCatTotal(mainCategory, totalPerCategory)
  const children = get(mainCategory, 'children') || []

  const newChildren = children.map((cat) => {
    const catTotal = findCatTotal(cat, totalPerCategory)
    return { ...cat, ...catTotal }
  })

  return { ...mainCategory, ...mainCategoryTotal, children: newChildren }
}

const addTotalMain = (type, categoryTree, monthTotal) => {
  return {
    type,
    text: type === 'cashin' ? t('component.categoryRepartition.totalCashin') : t('component.categoryRepartition.totalCashout'),
    color: type === 'cashin' ? colors.revenue : colors.expense,
    total: get(monthTotal, 'totalForAllTransactions'),
    totalWithoutVAT: get(monthTotal, 'totalForAllTransactionsWithoutVAT'),
    children: categoryTree
  }
}

const getTotals = (total, month) => {
  if (month) {
    const monthTotal = get(total, 'totalPerMonth', []).find((monthData) => monthData.month === month)
    const totalPerCategory = get(monthTotal, 'totalPerCategory', [])
    return { periodTotal: monthTotal, totalPerCategory }
  }

  const yearTotal = {
    totalForAllTransactions: get(total, 'totalForTheYear'),
    totalForAllTransactionsWithoutVAT: get(total, 'totalForTheYearWithoutVAT')
  }
  const totalPerCategory = get(total, 'totalPerCategoryForTheYear', [])

  return { periodTotal: yearTotal, totalPerCategory }
}

export const getMainCategoryTreeWithTotal = createSelector(
  getCategoriesInTreeSelector,
  getTotal,
  (state, type, year, categoryId, month, withVat) => [type, categoryId, month],
  (categoryTree, total, [type, categoryId, month]) => {
    const { periodTotal, totalPerCategory } = getTotals(total, month)
    const categoryTreeWithTotalMain = addTotalMain(type, categoryTree, periodTotal)
    const mainCategory = findMainCategory(categoryTreeWithTotalMain, categoryId) || {}

    return mergeTotalInCategories(mainCategory, totalPerCategory)
  }
)

const CategoryRepartition = ({ type, categoryId, previous, uniqueKey }) => {
  const dispatch = useDispatch()
  const [filters, setFilters] = useJsonQueryParams()

  const withVatKey = useMemo(() => (`${uniqueKey}-withVat`), [uniqueKey])
  const handleWithVatChange = useCallback((withVat) => {
    const withVatValue = withVat || undefined
    setFilters({ ...filters, [withVatKey]: withVatValue })
  }, [setFilters, filters, withVatKey])
  const withVat = useMemo(() => {
    return filters[withVatKey] || 'true'
  }, [filters, withVatKey])

  const yearKey = useMemo(() => (`${uniqueKey}-year`), [uniqueKey])
  const handleYearChange = useCallback((year) => {
    const yearValue = year || undefined
    setFilters({ ...filters, [yearKey]: yearValue })
  }, [setFilters, filters, yearKey])
  const year = useMemo(() => {
    const defaultValue = previous ? lastMonthYear : todayYear
    return filters[yearKey] || defaultValue
  }, [filters, yearKey, previous])

  const monthKey = useMemo(() => (`${uniqueKey}-month`), [uniqueKey])
  const handleMonthChange = useCallback((month) => {
    const monthValue = month === 'allYear' ? null : month
    setFilters({ ...filters, [monthKey]: monthValue })
  }, [setFilters, filters, monthKey])
  const month = useMemo(() => {
    const monthValue = filters[monthKey]
    const defaultValue = previous ? lastMonth : todayMonth

    return monthValue === null ? null : (monthValue || defaultValue)
  }, [filters, monthKey, previous])

  const dataKey = withVat === 'true' ? 'total' : 'totalWithoutVAT'
  const mainCategory = useSelector((state) => getMainCategoryTreeWithTotal(state, type, year, categoryId, month, withVat))
  const isFetching = useSelector((state) =>
    get(state, `aggregations.${type === 'cashin' ? 'isFecthingCashinTotal' : 'isFecthingCashoutTotal'}`))
  const isEmpty = useMemo(() => {
    const totals = get(mainCategory, 'children', []).map((cat) => get(cat, dataKey, 0))
    return sum(totals) === 0 && !isFetching
  }, [mainCategory, isFetching, dataKey])
  const noSubCategories = useMemo(() => {
    return get(mainCategory, 'children.length', 0) === 0
  }, [mainCategory])

  useEffect(() => {
    if (year !== todayYear) {
      dispatch(aggregationsActions.requestTransactionTypeTotal(type, year))
    }
  }, [dispatch, type, year])

  return (
    <div>
      <Row gutter={20} align='end'>
        <Col xs={6}>
          <YearSelect onChange={handleYearChange} defaultValue={year} />
        </Col>

        <Col xs={6}>
          <MonthSelect onChange={handleMonthChange} defaultValue={month} />
        </Col>
      </Row>

      <Row style={{ marginTop: 40 }}>
        <Col span={24} style={{ position: 'relative', height: 400 }}>
          {isEmpty && (
            (noSubCategories && (
              <div style={styles.emptyContainer}>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={t('component.categoryRepartition.noSubCategories', { category: get(mainCategory, 'name') })}
                  style={styles.empty}
                />
              </div>
            )) || (
              <div style={styles.emptyContainer}>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={t('component.categoryRepartition.noTransactionAssigned', { category: get(mainCategory, 'name') || get(mainCategory, 'text') })}
                  style={styles.empty}
                />
              </div>
            )
          )}

          <CategoryPieChart isFetching={isFetching} mainCategory={mainCategory} dataKey={dataKey} />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col xs={24} align='end'>
          <VatToggle value={withVat} onChange={(e) => handleWithVatChange(e.target.value)} />
        </Col>
      </Row>

      <Divider />
    </div>
  )
}

const styles = {
  emptyContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  empty: {
    maxWidth: 250
  }
}

CategoryRepartition.propTypes = {
  type: PropTypes.oneOf(['cashin', 'cashout']).isRequired,
  categoryId: PropTypes.string,
  previous: PropTypes.bool,
  uniqueKey: PropTypes.string.isRequired
}

export default CategoryRepartition
