import { gql } from '@apollo/client'

export const LIST_BANK_CONNECTIONS_WITH_ACCOUNTS = gql`
  query listBankConnectionsWithAccounts {
    listBankConnectionsWithAccounts {
      id
      externalId
      externalSource
      externalBankId
      status
      bankName
      lastSyncingAt
      accounts {
        id
        externalId
        customName
        originalName
        accountNumber
        balance
        ignoreBalance
        comingTransactionsAmount
        currency
        archived
      }
      computedBalance
      computedComingTransactionsAmount
    }
  }
`

export const DELETE_BANK_CONNECTION = gql`
  mutation deleteBankConnection($id: ID!) {
    deleteBankConnection(id: $id) 
  }
`

export const SYNC_BANK_CONNECTION = gql`
  mutation triggerSyncBankConnection($id: ID!) {
    triggerSyncBankConnection(id: $id) 
  }
`
