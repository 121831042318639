import React from 'react'

const PictoLogo = () => {
  if (process.env.REACT_APP_TENANT === 'QUIPU') {
    return (
      <div className='fixed w-8'>
        <img src='/picto_logo_quipu.svg' alt='Quipu logo' />
      </div>
    )
  }

  return (
    <div className='fixed w-8'>
      <img src='/picto_logo_sellsy_dark.svg' alt='Sellsy logo' />
    </div>
  )
}

export default PictoLogo
