
import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Toggle from 'design/Toggle.js'
import { TOOGLE_SCENARIO_INCLUDE_QUOTES } from 'graphql/scenarios.js'
import { useMutation } from '@apollo/client'
import classNames from 'classnames'
import { Divider } from 'antd'
import ScenarioExpectedQuoteStatuses from './ScenarioExpectedQuoteStatuses.js'
import i18next from 'i18next'

ScenarioIncludeQuotes.propTypes = {
  scenarioId: PropTypes.string,
  scenarioExpectedQuoteStatuses: PropTypes.array,
  isReadOnly: PropTypes.bool,
  isSettingsOpen: PropTypes.bool,
  onUpdated: PropTypes.func,
  includeQuotes: PropTypes.bool
}

export default function ScenarioIncludeQuotes ({ scenarioId, isReadOnly, scenarioExpectedQuoteStatuses, isSettingsOpen, onUpdated, includeQuotes }) {
  const [scenarioKey, setScenarioKey] = useState(scenarioId)

  const { t } = i18next

  useEffect(() => {
    if (scenarioKey !== scenarioId) {
      setScenarioKey(scenarioId)
    }
  }, [scenarioKey, scenarioId])

  const [toogleScenarioIncludeQuotes] = useMutation(TOOGLE_SCENARIO_INCLUDE_QUOTES,
    { onCompleted: () => onUpdated() })

  const handleOnChange = useCallback((includeQuotes) => {
    toogleScenarioIncludeQuotes({
      variables: {
        input: {
          scenarioId: scenarioId,
          includeQuotes
        }
      }

    })
  }, [scenarioId, toogleScenarioIncludeQuotes])

  return (
    <div>
      <div className='cursor-default text-sm font-semibold text-base-color flex mb-2'>{t('components.scenarioIncludeQuotes.title')}</div>
      <div className='flex flex-row'>
        <Toggle
          size='small' onChange={() => handleOnChange(!includeQuotes)} toggled={includeQuotes} className='inline'
        />
        <span className={classNames('text-base-color')}>
          {t('components.scenarioIncludeQuotes.label')}
        </span>
      </div>
      <Divider className='mb-4 mt-4' />

      <ScenarioExpectedQuoteStatuses
        scenarioId={scenarioId}
        includeQuotes={includeQuotes}
        isReadOnly={isReadOnly}
        scenarioExpectedQuoteStatuses={scenarioExpectedQuoteStatuses}
        isSettingsOpen={isSettingsOpen}
        onUpdated={onUpdated}
      />
    </div>

  )
}
