import React, { useCallback, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import { useSelector } from 'react-redux'
import { useMutation } from '@apollo/client'

import Toggle from 'design/Toggle'
import { TOGGLE_SCENARIO_WITH_VAT_DETAILS } from 'graphql/scenarios'
import { getStatus } from 'redux-resource'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import CashflowSheetContext from 'contexts/CashflowSheetContext'
import { useTranslation } from 'react-i18next'
import { ScenariosContext } from 'contexts/ScenariosContext.js'

VatDetailsScenarioSetting.propTypes = {
  queryFilters: PropTypes.object
}

export default function VatDetailsScenarioSetting ({ queryFilters }) {
  const { t } = useTranslation()
  const { loadingCashflowSheet } = useContext(CashflowSheetContext)
  const { refetch: scenariosRefetch, data: scenariosData } = useContext(ScenariosContext)

  const scenarioId = useMemo(() => queryFilters.scenarioId, [queryFilters.scenarioId])
  const withVatDetails = useMemo(() => scenariosData?.listScenarios.find((scenario) => scenario.id === scenarioId)?.withVatDetails, [scenariosData, scenarioId])
  const gettingScenarioDetails = useSelector(state => getStatus(state, 'scenarios.requests.readScenario.status'))

  const [toggleScenarioWithVatDetails, { loading: mutationLoading }] = useMutation(TOGGLE_SCENARIO_WITH_VAT_DETAILS, {
    onCompleted: (data) => {
      scenariosRefetch()
    }
  }, [scenariosRefetch])

  const toggle = useCallback(() => {
    toggleScenarioWithVatDetails({ variables: { input: { scenarioId, withVatDetails: !withVatDetails } } })
  }, [toggleScenarioWithVatDetails, scenarioId, withVatDetails])

  return (
    <Tooltip
      placement='right'
      title={(
        <div className='flex flex-col space-y-4'>
          <div>
            {t('dashboard.vatDetailsScenarioSetting.tooltip.01')}
          </div>

          <div className='flex flex-row items-center space-x-2'>
            <span>{t('dashboard.vatDetailsScenarioSetting.tooltip.02')}</span>
          </div>
        </div>
      )}
      overlayClassName='w-[450px] max-w-none'
    >
      <div className='flex flex-row items-center space-x-2'>
        <InformationCircleIcon className='w-4 h-4 text-gray-200' />

        <div className='opacity-0 group-hover:opacity-100 transition-opacity'>
          <Toggle size='small' toggled={withVatDetails} onChange={toggle} className='inline' loading={gettingScenarioDetails.pending || mutationLoading || loadingCashflowSheet} />
        </div>
      </div>
    </Tooltip>
  )
}
