import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Collapse, Tooltip } from 'antd'
import numeral from 'numeral'
import { useQuery } from '@apollo/client'
import InfoBox from 'design/InfoBox'

import TransactionPreview from 'components/transactions/TransactionPreview'
import ListTransactionExpectedTransactions from './ListTransactionExpectedTransactions'
import ListReconciliableExpectedTransactions from './ListReconciliableExpectedTransactions'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import useConnections from 'hooks/connections/useConnections.js'

import ExpectedTransactionReconciliationContext from 'contexts/ExpectedTransactionReconciliationContext'
import { LIST_TRANSACTION_EXPECTED_TRANSACTIONS } from 'graphql/expected-transactions'
import { c } from 'utils/currencies'
import { sumBy } from 'lodash'
import { Trans, useTranslation } from 'react-i18next'

const initContext = {
  refetchReconciliated: () => true,
  refetchSearch: () => true
}

const ReconciliationDrawer = ({ transaction }) => {
  const { t } = useTranslation()
  const { sellsyConnection } = useConnections()

  const { data } = useQuery(LIST_TRANSACTION_EXPECTED_TRANSACTIONS, {
    variables: { transactionId: transaction.id },
    networkPolicy: 'cache-only'
  })

  const reconciliatedAmount = useMemo(() => {
    const reconciliatedAmountAcc = numeral(0)

    if (data?.listTransactionExpectedTransactions?.expectedTransactions) {
      data.listTransactionExpectedTransactions.expectedTransactions.forEach((expTxn) => {
        reconciliatedAmountAcc.add(sumBy(expTxn.expectedTransactionDetails, 'amount'))
      })
    }

    return reconciliatedAmountAcc.value()
  }, [data])

  const formattedReconciliatedAmount = useMemo(() => {
    return c(reconciliatedAmount)
  }, [reconciliatedAmount])

  const fullyReconciliated = useMemo(() => {
    return (reconciliatedAmount >= transaction.amount)
  }, [transaction, reconciliatedAmount])

  const findInvoiceHeaderWarning = useMemo(() => {
    return (
      <Tooltip title={t('component.reconciliationDrawer.syncWarning', { context: process.env.REACT_APP_TENANT })}>
        <InformationCircleIcon className='w-5 h-5 text-gray-400 ml-2 inline-block' />
      </Tooltip>
    )
  }, [t])

  const infoBoxWarning = useMemo(() => {
    return (
      <InfoBox type='warning' className='mb-8'>
        <Trans
          i18nKey={t('component.reconciliationDrawer.syncSecondWarning', { context: process.env.REACT_APP_TENANT })}
          components={{ span: <span className='font-bold' /> }}
        />
      </InfoBox>
    )
  }, [t])

  const shouldHaveWarning = process.env.REACT_APP_TENANT === 'QUIPU' || sellsyConnection

  const findInvoiceHeader = (
    <div className='flex flex-row items-center'>
      {t('component.reconciliationDrawer.searchInExpectedTransactions')} {shouldHaveWarning && (findInvoiceHeaderWarning)}
    </div>
  )

  return (
    <div className='h-full min-h-[1000px]'>
      {(shouldHaveWarning && infoBoxWarning)}
      <ExpectedTransactionReconciliationContext.Provider value={initContext}>
        <Collapse ghost defaultActiveKey={['1', '2', '3']} className='h-full flex flex-col overflow-hidden'>
          <Collapse.Panel header={t('shared.transaction')} key='1'>
            <TransactionPreview transaction={transaction} />
          </Collapse.Panel>

          <Collapse.Panel header={t('component.reconciliationDrawer.reconciliatedExpectedTransactions', { formattedReconciliatedAmount })} key='2' className='rc-reconciliation-drawer-collapse flex flex-col max-h-[50%]'>
            <ListTransactionExpectedTransactions transaction={transaction} />
          </Collapse.Panel>

          {(fullyReconciliated && (
            <div className='italic ml-2'>{t('component.reconciliationDrawer.fullyReconciliated')}</div>
          )) || (
            <Collapse.Panel header={findInvoiceHeader} key='3' className='flex-grow overflow-hidden rc-reconciliation-drawer-collapse flex flex-col'>
              <ListReconciliableExpectedTransactions transaction={transaction} reconciliatedAmount={reconciliatedAmount} />
            </Collapse.Panel>
          )}
        </Collapse>
      </ExpectedTransactionReconciliationContext.Provider>
    </div>
  )
}

ReconciliationDrawer.propTypes = {
  transaction: PropTypes.object
}

export default ReconciliationDrawer
