
import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Toggle from 'design/Toggle.js'
import { TOOGLE_SCENARIO_INCLUDE_ORDERS } from 'graphql/scenarios.js'
import { useMutation } from '@apollo/client'
import classNames from 'classnames'
import { Divider } from 'antd'
import ScenarioExpectedOrderStatuses from './ScenarioExpectedOrderStatuses.js'
import i18next from 'i18next'

ScenarioIncludeOrders.propTypes = {
  scenarioId: PropTypes.string,
  scenarioExpectedOrderStatuses: PropTypes.array,
  isReadOnly: PropTypes.bool,
  isSettingsOpen: PropTypes.bool,
  onUpdated: PropTypes.func,
  includeOrders: PropTypes.bool
}

export default function ScenarioIncludeOrders ({ scenarioId, isReadOnly, scenarioExpectedOrderStatuses, isSettingsOpen, onUpdated, includeOrders }) {
  const [scenarioKey, setScenarioKey] = useState(scenarioId)

  const { t } = i18next

  useEffect(() => {
    if (scenarioKey !== scenarioId) {
      setScenarioKey(scenarioId)
    }
  }, [scenarioKey, scenarioId])

  const [toogleScenarioIncludeOrders] = useMutation(TOOGLE_SCENARIO_INCLUDE_ORDERS,
    { onCompleted: () => onUpdated() })

  const handleOnChange = useCallback((includeOrders) => {
    toogleScenarioIncludeOrders({
      variables: {
        input: {
          scenarioId: scenarioId,
          includeOrders
        }
      }

    })
  }, [scenarioId, toogleScenarioIncludeOrders])

  return (
    <div>
      <div className='cursor-default text-sm font-semibold text-base-color flex mb-2'>{t('components.scenarioIncludeOrders.title')}</div>
      <div className='flex flex-row'>
        <Toggle
          size='small' onChange={() => handleOnChange(!includeOrders)} toggled={includeOrders} className='inline'
        />
        <span className={classNames('text-base-color')}>
          {t('components.scenarioIncludeOrders.label')}
        </span>
      </div>
      <Divider className='mb-4 mt-4' />

      <ScenarioExpectedOrderStatuses
        scenarioId={scenarioId}
        includeOrders={includeOrders}
        isReadOnly={isReadOnly}
        scenarioExpectedOrderStatuses={scenarioExpectedOrderStatuses}
        isSettingsOpen={isSettingsOpen}
        onUpdated={onUpdated}
      />
    </div>

  )
}
