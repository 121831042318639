import { useCallback } from 'react'

const getValidationErrosByFieldFromGraphqlErrors = (graphQLErrors) => {
  const validationErrorsByField = {}

  graphQLErrors.forEach((err) => {
    if (err.message === 'Validation') {
      err.extensions.details.forEach((validationError) => {
        const field = validationError.path.join('.')
        if (!validationErrorsByField[field]) validationErrorsByField[field] = []
        validationErrorsByField[field].push(validationError.message)
      })
    }
  })

  return validationErrorsByField
}

export default ({ form }) => {
  return useCallback(({ graphQLErrors }) => {
    const errorsByField = getValidationErrosByFieldFromGraphqlErrors(graphQLErrors)

    const fields = form.getFieldsValue()

    const allFieldsWithErrors = Object.keys(fields).map((fieldName) => {
      const fieldValue = fields[fieldName]
      return { name: fieldName, value: fieldValue, errors: errorsByField[fieldName] }
    })

    form.setFields(allFieldsWithErrors)
  }, [form])
}
