import PropTypes from 'prop-types'
import React from 'react'

import ExpectedTransactionActions from './ExpectedTransactionActions.js'

ExpectedTransactionPreviewActions.propTypes = {
  expectedTransaction: PropTypes.object,
  onCompleted: PropTypes.func
}

export default function ExpectedTransactionPreviewActions ({ expectedTransaction, onCompleted }) {
  return (
    <ExpectedTransactionActions
      accountingDocument={expectedTransaction.accountingDocument}
      expectedTransaction={expectedTransaction} hiddenDeleteAction
      onCompleted={onCompleted}
    />
  )
}
