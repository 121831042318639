import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import CashflowSheet from './CashflowSheet'
import CashflowSheetHeader from '../CashflowSheetHeader'
import CashflowSheetContext from 'contexts/CashflowSheetContext'

CashflowSheetContainer.propTypes = {
  queryFilters: PropTypes.object,
  mergeQueryFilters: PropTypes.func
}

export default function CashflowSheetContainer ({ queryFilters, mergeQueryFilters }) {
  const { sheet, sheetDates, preparedSheet } = useContext(CashflowSheetContext)

  if (!sheet) return null

  return (
    <div className='flex flex-col space-y-4'>
      <CashflowSheetHeader
        sheet={preparedSheet}
        sheetDates={sheetDates}
        queryFilters={queryFilters}
        mergeQueryFilters={mergeQueryFilters}
      />

      <CashflowSheet
        sheet={preparedSheet}
        sheetDates={sheetDates}
        queryFilters={queryFilters}
        mergeQueryFilters={mergeQueryFilters}
      />
    </div>
  )
}
