import { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'

import { GET_QUOTES_COUNT } from 'graphql/transactions'

export default () => {
  const [quotesCount, setQuotesCount] = useState(undefined)
  const [refetchQuotesCount] = useLazyQuery(GET_QUOTES_COUNT, {
    fetchPolicy: 'no-cache',
    onCompleted: (result) => {
      setQuotesCount(result.getQuotesCount)
    }
  }, [setQuotesCount])

  useEffect(() => {
    refetchQuotesCount()
  }, [refetchQuotesCount])

  return {
    refetchQuotesCount,
    quotesCount
  }
}
