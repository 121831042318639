import React from 'react'
import { Redirect } from 'react-router'
import useSearchParams from 'hooks/useSearchParams'

const NewConnectionScreen = () => {
  const { error } = useSearchParams()

  if (error) return <Redirect to='/dashboard' />

  return <Redirect to='/dashboard?newConnection=1' />
}

export default NewConnectionScreen
