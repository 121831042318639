import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ApolloProvider } from '@apollo/client'
import { PersistGate } from 'redux-persist/integration/react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import './config'
import AppRouter from './routes'
import { configureStore, onStoreReady } from './store'
import { setUnauthorizedInterceptor } from './utils/api'
import apolloClient from './utils/apollo-client'
import AntdConfigProvider from 'config/AntdConfigProvider.js'
import ScenariosContextProvider from 'contexts/ScenariosContext.js'

const { store, persistor } = configureStore()

const onBeforeLift = () => onStoreReady(store)
setUnauthorizedInterceptor(store)

const onAppBootSuccess = () => {
  if (window.Intercom) {
    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'p6qpddj1',
      alignment: 'right',
      horizontal_padding: 20,
      vertical_padding: 20
    })
  }
}

ReactDOM.render((
  <Provider store={store}>
    <ApolloProvider client={apolloClient}>
      <PersistGate loading={null} persistor={persistor} onBeforeLift={onBeforeLift}>
        <AntdConfigProvider>
          <DndProvider backend={HTML5Backend}>
            <ScenariosContextProvider>
              <AppRouter />
            </ScenariosContextProvider>
          </DndProvider>
        </AntdConfigProvider>
      </PersistGate>
    </ApolloProvider>
  </Provider>
), document.getElementById('root'), onAppBootSuccess)
