import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import moment from 'moment-timezone'
import { times } from 'lodash'
import { endEdgeDate } from 'utils/dates'

const YearSelect = React.forwardRef(({ until, withoutBorder, ...rest }, ref) => {
  const years = useMemo(() => {
    const untilDate = until || endEdgeDate
    const numberOfYears = moment(untilDate).diff('2018-01-01', 'years') + 1
    return times(numberOfYears, (y) => moment(untilDate).subtract(y, 'years').year())
  }, [until])

  return (
    <Select
      className={`w-full ${withoutBorder ? 'rc-select-without-border' : null}`}
      {...rest}
    >
      {years.map((year) => (
        <Select.Option key={year} value={year}>{year}</Select.Option>
      ))}
    </Select>
  )
})

YearSelect.propTypes = {
  until: PropTypes.object,
  withoutBorder: PropTypes.bool
}

export default YearSelect
