import { useQuery } from '@apollo/client'
import { Button, Input, List } from 'antd'
import PropTypes from 'prop-types'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'

import { EllipsisOutlined } from '@ant-design/icons'
import ReconciliableExpectedTransactionPreview from 'components/expected-transactions/ReconciliableExpectedTransactionPreview'
import ExpectedTransactionReconciliationContext from 'contexts/ExpectedTransactionReconciliationContext'
import { SEARCH_RECONCILIABLE_EXPECTED_TRANSACTIONS } from 'graphql/expected-transactions'
import numeral from 'numeral'
import { useTranslation } from 'react-i18next'

const { Search } = Input

const ListReconciliableExpectedTransactions = ({ transaction, reconciliatedAmount }) => {
  const { t } = useTranslation()
  const [total, setTotal] = useState(0)
  const [data, setData] = useState([])
  const [searchText, setSearchText] = useState()
  const [search] = useDebounce(searchText, 250)
  const reconciliationContext = useContext(ExpectedTransactionReconciliationContext)

  const reconciliableAmount = useMemo(() => {
    return numeral(transaction.amount).subtract(reconciliatedAmount).value()
  }, [transaction, reconciliatedAmount])

  const onCompleted = useCallback((result) => {
    const data = result?.searchReconciliableExpectedTransactions?.expectedTransactions
    const total = result?.searchReconciliableExpectedTransactions?.total || 0
    setData(data)
    setTotal(total)
  }, [setData, setTotal])

  const { loading, refetch } = useQuery(SEARCH_RECONCILIABLE_EXPECTED_TRANSACTIONS, {
    fetchPolicy: 'cache-and-network',
    variables: { type: transaction.type, search, limit: 5 },
    // From https://github.com/apollographql/react-apollo/issues/3709#issuecomment-592786578
    notifyOnNetworkStatusChange: true,
    onCompleted
  })

  const handleSearchChange = useCallback((event) => {
    setSearchText(event.target.value)
  }, [setSearchText])

  const searchLoading = useMemo(() => {
    return loading || search !== searchText
  }, [loading, search, searchText])

  useEffect(() => { reconciliationContext.refetchSearch = refetch }, [refetch, reconciliationContext])

  const loadMoreButton = useMemo(() => {
    if (data.length >= total) return null

    return (
      <div className='w-full p-2 flex justify-center'>
        <Button
          loading={loading}
          disabled={searchLoading}
          onClick={() => refetch({ limit: data.length + 5 })}
          size='small'
          type='link'
          icon={<EllipsisOutlined />}
        >
          {t('shared.loadMore', { dataLength: data.length, total })}
        </Button>
      </div>
    )
  }, [data, total, loading, searchLoading, t, refetch])

  return (
    <div className='h-full flex flex-col'>
      <Search
        placeholder={t('component.listReconciliableExpectedTransactions.searchPlaceholder')}
        onChange={handleSearchChange}
        loading={searchLoading}
        allowClear
        className='mb-4'
      />

      <div className='flex-grow overflow-scroll pr-1 -mr-3 rc-show-scrollbar'>
        <List
          loadMore={loadMoreButton}
          loading={loading}
          dataSource={data}
          renderItem={expectedTransaction => (
            <List.Item key={expectedTransaction.id} className='pt-0'>
              <ReconciliableExpectedTransactionPreview type={transaction.type} expectedTransaction={expectedTransaction} reconciliationTransactionId={transaction.id} reconciliableAmount={reconciliableAmount} />
            </List.Item>
          )}
          split={false}
        />
      </div>
    </div>
  )
}

ListReconciliableExpectedTransactions.propTypes = {
  transaction: PropTypes.object,
  reconciliatedAmount: PropTypes.number
}

export default ListReconciliableExpectedTransactions
