import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Divider, Select } from 'antd'
import { isNumber } from 'lodash'
import { getVatRates } from 'selectors/users'
import CustomVatRateInput from './CustomVatRateInput'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import numeral from 'numeral'

const { Option } = Select

const getPercent = (vatRate) => numeral(vatRate || 0).multiply(100).value()

const getLabel = (vatRate) => {
  const { t } = i18next
  const percent = getPercent(vatRate)
  return vatRate === 0 ? t('component.vatSelect.noVAT') : `${percent}%`
}

const VatSelect = React.forwardRef(({ value, onChange, ...rest }, ref) => {
  const { t } = useTranslation()
  const vatRates = useSelector(getVatRates)

  return (
    <Select
      ref={ref}
      placeholder={t('component.vatSelect.vatPercentage')}
      style={{ width: '100%' }}
      value={value}
      onChange={onChange}
      {...rest}
      dropdownMatchSelectWidth={false}
      dropdownRender={(menu) => (
        <div>
          {menu}

          <Divider className='mt-1 mb-2' />

          <Option />
          <CustomVatRateInput onSubmit={onChange} />

        </div>
      )}
    >
      {vatRates.map((vatRate) => <Option key={vatRate} value={vatRate}>{getLabel(vatRate)}</Option>)}
      {isNumber(value) && !vatRates.includes(value) && (
        <Option value={value}>{getLabel(value)}</Option>
      )}
    </Select>
  )
})

VatSelect.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func
}

export default VatSelect
