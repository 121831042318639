import { concat, findKey, isFunction, isObject, max, round } from 'lodash'
import moment from 'moment'
import numeral from 'numeral'

const growthTypes = ['AMOUNT', 'PERCENTAGE']
const growthOperations = ['ADD', 'SUBTRACT']
const frequencies = ['MONTHLY', 'QUARTERLY', 'HALF-YEARLY', 'YEARLY']

class ForecastAssistant {
  constructor ({ sheetDate, amount }) {
    if (!isObject(sheetDate)) throw new Error('sheetDate must be an object')
    if (isNaN(amount)) throw new Error('amount must be a number')
    // if (month < 1 || month > 12) throw new Error('month must be between 1 and 12')

    this.amount = amount
    this.sheetDate = sheetDate
  }

  computeGrowth ({
    growthType,
    growthOperation,
    growthValue,
    toDate,
    frequency
  }) {
    if (!growthTypes.includes(growthType)) throw new Error('growthType is not valid')
    if (!growthOperations.includes(growthOperation)) throw new Error('growthOperation is not valid')
    if (isNaN(growthValue) || growthValue < 0) throw new Error('growthValue is not valid')
    if (!frequencies.includes(frequency)) throw new Error('frequency is not valid')
    if (!isFunction(toDate?.isValid) || !toDate.isValid()) throw new Error('toDate is not valid')

    const followingMonths = this.getMonthsToCompute({ frequency, toDate })
    let lastMonthValue = this.amount

    const results = [{
      date: moment(this.sheetDate.momentDate).startOf('month').format('YYYY-MM-DD'),
      amount: this.amount
    }]

    followingMonths.forEach((month) => {
      const computedAmount = this.computeAmount(({ lastMonthValue, growthType, growthOperation, growthValue }))
      lastMonthValue = computedAmount
      results.push({
        date: month,
        amount: computedAmount
      })
    })

    return results
  }

  // PRIVATE
  getMonthsToCompute ({ frequency, toDate }) {
    const monthsToAdd = this.getMonthsToAdd(frequency)

    const dateEnd = moment(toDate).startOf('month')
    const dateMonths = []
    let nextDate = moment(this.sheetDate.momentDate).add(monthsToAdd, 'month').startOf('month')

    while (nextDate.isSameOrBefore(dateEnd)) {
      dateMonths.push(nextDate.format('YYYY-MM-DD'))
      nextDate = moment(nextDate).add(monthsToAdd, 'month')
    }

    return dateMonths
  }

  getMonthsToAdd (frequency) {
    switch (frequency) {
      case 'MONTHLY':
        return 1
      case 'QUARTERLY':
        return 3
      case 'HALF-YEARLY':
        return 6
      case 'YEARLY':
        return 12
    }
  }

  computeAmount ({ lastMonthValue, growthType, growthOperation, growthValue }) {
    if (growthValue <= 0) return lastMonthValue

    if (growthType === 'AMOUNT') {
      const operation = growthOperation === 'ADD' ? 'add' : 'subtract'
      const result = numeral(lastMonthValue)[operation](growthValue).value().toFixed(2)
      const roundedResult = round(result, 2)
      return max([roundedResult, 0])
    }

    if (growthType === 'PERCENTAGE') {
      const rate = (growthValue / 100)
      const factor = growthOperation === 'ADD' ? 1 + rate : 1 - rate
      const result = numeral(lastMonthValue).multiply(factor).value().toFixed(2)
      const roundedResult = round(result, 2)
      return max([roundedResult, 0])
    }
  }

  hasAlreadyBudgetedOnPeriod ({
    categoryId, toDate, sheet
  }) {
    let result = false
    const months = concat(
      moment(this.sheetDate.momentDate).format('YYYY-MM-DD'),
      this.getMonthsToCompute({ frequency: 'MONTHLY', toDate })
    )

    for (const month of months) {
      const idxDate = findKey(sheet, { date: month.substring(0, 7) })

      if (!idxDate) { break }

      // search category in cashin categories
      const idxCategoryCashin = findKey(sheet[idxDate].value.cashinDetailsByCategory, { categoryId: categoryId })

      // search category in cashout categories
      if (idxCategoryCashin) {
        result = sheet[idxDate].value.cashinDetailsByCategory[idxCategoryCashin].budgeted > 0
      } else {
        const idxCategoryCashout = findKey(sheet[idxDate].value.cashoutDetailsByCategory, { categoryId: categoryId })

        result = sheet[idxDate].value.cashoutDetailsByCategory[idxCategoryCashout].budgeted > 0
      }

      if (result) { break }
    }

    return result
  }

  assembleForecastFromRealisedInput ({
    scenarioId,
    categoryId,
    params,
    sheetDate
  }) {
    return {
      scenarioId,
      categoryId,
      forecastBehaviour: params.behaviour,
      forecastVariation: {
        growthType: params.growthType,
        growthOperation: params.growthOperation,
        growthValue: params.growthValue
      },
      period: {
        startDate: moment(sheetDate.date).format('YYYY-MM-DD'),
        endDate: moment(params.toDate).startOf('month').format('YYYY-MM-DD')
      }
    }
  }
}

export default ForecastAssistant
