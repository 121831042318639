import React, { useMemo, useCallback } from 'react'
import { get, flatMap } from 'lodash'
import { useHistory } from 'react-router-dom'

import BankConnectionSettings from './BankConnectionSettings'
import Button from 'design/Button'
import useHiddenAccountIds from 'hooks/useHiddenAccountIds'
import { UPDATE_HIDDEN_ACCOUNT_IDS } from 'graphql/companies'
import { LIST_BANK_CONNECTIONS_WITH_ACCOUNTS } from 'graphql/bank-connections.js'
import { useMutation, useQuery } from '@apollo/client'
import useAuthenticatedContext from 'hooks/useAuthenticatedContext'
import useManageBankConnections from 'hooks/useManageBankConnections.js'
import { useTranslation } from 'react-i18next'
import useInitTotalToValidate from 'hooks/useInitTotalToValidate'

const BankAccountSettings = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const { data } = useQuery(LIST_BANK_CONNECTIONS_WITH_ACCOUNTS)

  // const accounts = useSelector(state => get(state, 'accounts.data') || [])
  const { user, subscription } = useAuthenticatedContext()
  const hiddenAccountIds = useHiddenAccountIds()
  const { refetch: refetchTotalToValidateCashin } = useInitTotalToValidate({ type: 'cashin' })
  const { refetch: refetchTotalToValidateCashout } = useInitTotalToValidate({ type: 'cashout' })

  const [updateHiddenAccountIds, { loading: updatingHiddenAccountIds }] = useMutation(UPDATE_HIDDEN_ACCOUNT_IDS, {
    onCompleted: () => {
      // RELOAD APP TRICK
      history.push('/reload')
      history.goBack()
      refetchTotalToValidateCashin()
      refetchTotalToValidateCashout()
    }
  })

  const bankConnections = useMemo(() => data?.listBankConnectionsWithAccounts || [], [data])

  const activeBanksCount = useMemo(() => {
    const withoutDeleted = bankConnections.filter((a) => a.status !== 'DELETED')
    return withoutDeleted.length
  }, [bankConnections])

  const showedAccountsById = useMemo(() => {
    const allAccounts = flatMap(bankConnections, (bankConnections) => get(bankConnections, 'accounts', []))

    return allAccounts.reduce((acc, account) => {
      const accountId = account.id
      if (!accountId) return acc
      const showed = !hiddenAccountIds.includes(accountId)
      return { ...acc, [accountId]: showed }
    }, {})
  }, [bankConnections, hiddenAccountIds])

  const updateHiddenAccounts = useCallback((showedAccountsById) => {
    const hiddenAccountIds = Object.entries(showedAccountsById)
      .filter(([_, showed]) => !showed).map(([id, _]) => id)

    updateHiddenAccountIds({ variables: { hiddenAccountIds } })
  }, [updateHiddenAccountIds])

  const {
    redirectToConnectAccountsUrl,
    redirectToManageAccountsUrl,
    redirectingToConnectAccountsUrl,
    redirectingToManageAccountsUrl
  } = useManageBankConnections()

  const handleAddBank = useCallback(() => {
    if (subscription && activeBanksCount >= subscription?.includedBanksQuantity) {
      history.push('/subscribe?r=b')
    } else {
      redirectToConnectAccountsUrl()
    }
  }, [subscription, history, activeBanksCount, redirectToConnectAccountsUrl])

  return (
    <div className='mb-2'>
      <div className='overflow-y-auto rc-show-scrollbar max-h-64 mr-1 mt-1'>
        {bankConnections.map((bankConnections, index) => (
          <BankConnectionSettings
            defaultOpen={index === 0}
            key={`bank-connection-${index}`}
            loading={updatingHiddenAccountIds}
            bankConnection={bankConnections}
            showedAccountsById={showedAccountsById}
            updateHiddenAccounts={updateHiddenAccounts}
          />
        ))}
      </div>

      {user?.role === 'admin' && (
        <div className='flex flex-row ml-4 mt-4 justify-end'>
          <Button
            loading={redirectingToManageAccountsUrl}
            label={t('component.BankAccountSettings.manageBankAccounts')}
            onClick={redirectToManageAccountsUrl}
            className='overflow-visible'
          />

          <Button
            primary
            loading={redirectingToConnectAccountsUrl}
            label={t('component.BankAccountSettings.addABank')}
            onClick={handleAddBank}
            className='overflow-visible mx-4'
          />
        </div>
      )}
    </div>

  )
}

export default BankAccountSettings
