import { handleActions } from 'redux-actions'

const initialState = {
  trees: {
    cashin: [],
    cashout: []
  }
}

const reducer = handleActions(
  {
    UPDATE_CATEGORY_TREE: (state, { payload: { type, tree } }) => {
      const trees = state.trees
      trees[type] = tree

      return {
        ...state,
        trees
      }
    }
  },
  { ...initialState }
)

export default reducer
