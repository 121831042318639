import React from 'react'
import PropTypes from 'prop-types'
import Currency from 'components/Currency'
import CashflowSheetComparisonCell from './components/CashflowSheetComparisonCell'
import CashflowSheetDefaultCell from './components/CashflowSheetCurrencyCell'
import CashType from 'enums/CashType.js'
import CashflowSheetForecastedCompletionRateOverlay from '../CashflowSheetForecastedCompletionRateOverlay.js'

CashflowSheetCashFlowCell.propTypes = {
  sheetDate: PropTypes.object,
  value: PropTypes.object,
  realisedVsForecasted: PropTypes.bool,
  showPercentage: PropTypes.bool
}

export default function CashflowSheetCashFlowCell ({ sheetDate, value, realisedVsForecasted, showPercentage }) {
  if ((sheetDate.isCurrentMonth || realisedVsForecasted) && value.forecastedCashflow) {
    return (
      <CashflowSheetForecastedCompletionRateOverlay
        type={CashType.CASHFLOW}
        visible={realisedVsForecasted}
        realisedAmount={value.realisedCashflow}
        forecastedAmount={value.forecastedCashflow}
        outlineBadge
        showPercentage={showPercentage}
      >
        <CashflowSheetComparisonCell
          realisedAmount={value.realisedCashflow}
          forecastedAmount={value.forecastedCashflow}
          noBar
          strong
        />
      </CashflowSheetForecastedCompletionRateOverlay>
    )
  }

  if (!sheetDate.isFutureMonth) {
    return (
      <CashflowSheetForecastedCompletionRateOverlay
        type={CashType.CASHFLOW}
        visible={realisedVsForecasted}
        realisedAmount={value.realisedCashflow}
        forecastedAmount={value.forecastedCashflow}
        outlineBadge
        showPercentage={showPercentage}
      >
        <CashflowSheetDefaultCell justify={realisedVsForecasted ? 'start' : 'end'}>
          <Currency amount={value.realisedCashflow} />
        </CashflowSheetDefaultCell>
      </CashflowSheetForecastedCompletionRateOverlay>
    )
  }

  if (value.forecastedCashflow) {
    return (
      <CashflowSheetDefaultCell justify='end'>
        <Currency amount={value.forecastedCashflow} />
      </CashflowSheetDefaultCell>
    )
  }

  return null
}
