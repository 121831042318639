import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Switch, Tooltip } from 'antd'
import { get } from 'lodash'

import Currency from 'components/Currency'
import AccountSettings from './AccountSettings'
import SyncBankConnectionButton from './SyncBankConnectionButton'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon, ChevronUpIcon, InformationCircleIcon, BuildingLibraryIcon, TrashIcon } from '@heroicons/react/24/solid'
import Badge from 'design/Badge'
import moment from 'moment'
import { useTranslation, Trans } from 'react-i18next'
import { getBankConnectionFaviconUrl } from 'utils/banks-infos.js'
import confirm from 'antd/lib/modal/confirm'
import { useMutation } from '@apollo/client'
import { DELETE_BANK_CONNECTION } from 'graphql/bank-connections.js'
import ButtonLink from 'design/ButtonLink.js'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.js'

const BankConnectionSettings = ({ defaultOpen, loading, bankConnection, showedAccountsById, updateHiddenAccounts }) => {
  const { t } = useTranslation()
  const accounts = get(bankConnection, 'accounts', [])
  const status = get(bankConnection, 'status')
  const history = useHistory()

  const hasChildrenShowed = useMemo(() => {
    const showedLinkedAccounts = accounts.filter((account) => {
      const id = get(account, 'id')
      const showed = get(showedAccountsById, id, false)
      return showed
    })

    return showedLinkedAccounts.length > 0
  }, [showedAccountsById, accounts])

  const [deleteBankConnection] = useMutation(DELETE_BANK_CONNECTION, {
    onCompleted: () => {
      // RELOAD APP TRICK
      history.push('/reload')
      history.goBack()
    }
  })

  const switchLinkedAccounts = useCallback((checked) => {
    const newShowedAccountsById = accounts.reduce((acc, account) => {
      const id = get(account, 'id')
      return { ...acc, [id]: checked }
    }, showedAccountsById)

    updateHiddenAccounts(newShowedAccountsById)
  }, [accounts, showedAccountsById, updateHiddenAccounts])

  const handleOnClickDeleteBankConnection = useCallback(async (bankConnection) => {
    confirm({
      title:
  <Trans
    i18nKey='component.bankConnectionSettings.confirmDeleteBankConnection'
    components={{
      BankName: <span className='font-bold' />,
      Br: <br />
    }}
    values={{ bankName: bankConnection.bankName }}
  />,
      okType: 'danger',
      okText: t('shared.delete'),
      cancelText: t('shared.cancel'),
      maskClosable: true,
      width: 550,
      onOk () {
        deleteBankConnection({ variables: { id: bankConnection.id } })
      }
    })
  }, [deleteBankConnection, t])

  const labelStyle = useMemo(() => {
    switch (status) {
      case 'OK':
        return 'success'
      case 'DELETED':
        return 'disabled'
      default:
        return 'warning'
    }
  }, [status])

  const labelText = useMemo(() => {
    switch (status) {
      case 'OK':
        return t('component.bankConnectionSettings.connected')
      case 'DELETED':
        return t('component.settingsOverlay.connectionDeleted')
      default:
        return t('component.settingsOverlay.error')
    }
  }, [status, t])

  const moreInfoLabel = useMemo(() => {
    const lastSyncingAt = moment(bankConnection?.lastSyncingAt)

    return (
      <div>
        <div>
          {t('component.settingsOverlay.lastSyncing')}
          <br />
          {(bankConnection?.lastSyncingAt && (
            <span>
              <Trans
                i18nKey='component.bankConnectionSettings.lastSyncingAt'
                components={{
                  Date: <span className='font-bold' />,
                  Time: <span className='font-bold' />
                }}
                values={{
                  date: lastSyncingAt.format('LL'),
                  time: lastSyncingAt.format('HH[h]mm')
                }}
              />
            </span>
          )) || '-'}
        </div>

        <br />

        <div>
          {t('component.bankConnectionSettings.expected')}
          <br />
          <Currency amount={bankConnection?.computedComingTransactionsAmount} className='text-white font-bold' />
        </div>
      </div>
    )
  }, [bankConnection?.computedComingTransactionsAmount, bankConnection?.lastSyncingAt, t])

  const faviconUrl = useMemo(() => {
    return getBankConnectionFaviconUrl(bankConnection)
  }, [bankConnection])

  return (
    <div className={`p-4 pr-3 pb-2 ${status === 'DELETED' ? 'opacity-75' : ''}`}>
      <Disclosure defaultOpen={defaultOpen}>
        {({ open }) => (
          <div className='flex flex-col group'>
            <div className='flex flex-row items-center'>
              <Disclosure.Button className='focus:outline-none'>
                {(open && (
                  <ChevronUpIcon className='w-5 h-5 mr-1 text-gray-400' />
                )) || (
                  <ChevronDownIcon className='w-5 h-5 mr-1 text-gray-400' />
                )}
              </Disclosure.Button>

              <Switch
                checked={hasChildrenShowed}
                onChange={switchLinkedAccounts}
                onClick={(_, e) => { e.stopPropagation() }}
                disabled={loading}
                loading={loading}
              />

              <Tooltip title={moreInfoLabel} placement='right'>
                <Disclosure.Button className='w-full flex flex-row items-center focus:outline-none' as='div'>
                  <div className='flex flex-grow flex-row items-center'>
                    <div className='min-w-4 mx-2'>
                      {(faviconUrl && (
                        <img alt='bank-logo' src={faviconUrl} className='w-4 h-4 flex-grow object-cover' />
                      )) || (
                        <BuildingLibraryIcon className='w-4 h-4 text-light-primary' />
                      )}
                    </div>

                    <div className='flex flex-grow items-center font-bold text-left'>
                      <span className='mr-2'>{get(bankConnection, 'bankName', '...')}</span>

                      <Badge type={labelStyle} label={labelText} />
                    </div>
                  </div>

                  <div className='flex flex-row items-center min-w-min ml-2'>
                    <div className='opacity-0 group-hover:opacity-100 hidden'>
                      <Tooltip
                        placement='top'
                        title={t('component.bankConnectionSettings.deleteBankConnection')}
                      >
                        <ButtonLink
                          onClick={(e) => {
                            e.stopPropagation()
                            handleOnClickDeleteBankConnection(bankConnection)
                          }}
                          label={<TrashIcon className='w-4 h-4 mr-1 text-error' />}
                        />
                      </Tooltip>
                    </div>
                    <SyncBankConnectionButton bankConnection={bankConnection} />
                    <InformationCircleIcon className='w-4 h-4 text-gray-300 mr-1' />
                    <Currency amount={get(bankConnection, 'computedBalance')} className='whitespace-nowrap font-bold' />
                  </div>
                </Disclosure.Button>
              </Tooltip>
            </div>

            <Disclosure.Panel>
              {accounts.map((account, index) => (
                <AccountSettings
                  key={`linked-account-${index}`}
                  loading={loading}
                  account={account}
                  showedAccountsById={showedAccountsById}
                  updateHiddenAccounts={updateHiddenAccounts}
                />
              ))}
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
    </div>
  )
}

BankConnectionSettings.propTypes = {
  loading: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  bankConnection: PropTypes.object,
  showedAccountsById: PropTypes.object,
  updateHiddenAccounts: PropTypes.func
}

export default BankConnectionSettings
