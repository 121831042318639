import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'

import { useSelector } from 'react-redux'
import { Modal, Typography } from 'antd'
import { get } from 'lodash'
import moment from 'moment-timezone'

import Button from 'design/Button'
import { useTranslation } from 'react-i18next'
import useConnections from 'hooks/connections/useConnections.js'

export default function FailedSellsyConnectionPopup () {
  const { t } = useTranslation()
  const history = useHistory()

  const [visible, setVisible] = useState(false)
  const { sellsyConnection } = useConnections()
  const hasFailedConnections = useMemo(() => {
    return (sellsyConnection && sellsyConnection.status === 'DISCONNECTED')
  }, [sellsyConnection])

  const lastSellSyManagementAt = useSelector((state) => get(state, 'app.lastSellSyManagementAt'))
  const isAuthenticated = useSelector(state => get(state, 'app.isAuthenticated'))

  useEffect(() => {
    if (!hasFailedConnections) return

    if (lastSellSyManagementAt) {
      const anHourAgo = moment().subtract(1, 'hour')
      const isMoreThanHourAgo = moment(lastSellSyManagementAt).isBefore(anHourAgo)
      if (isMoreThanHourAgo) setVisible(true)
    } else {
      setVisible(true)
    }
  }, [hasFailedConnections, lastSellSyManagementAt, setVisible])

  if (!isAuthenticated) return null

  return (
    <Modal
      open={visible}
      onCancel={() => setVisible(false)}
      maskClosable
      centered
      footer={null}
    >
      <Typography.Title level={4}>
        {t('component.failedSellsyConnectionPopup.title')}
      </Typography.Title>

      <Typography.Paragraph>
        {t('component.failedSellsyConnectionPopup.paragraph')}
      </Typography.Paragraph>

      <div className='w-full flex flex-col items-center'>
        <Button
          onClick={() => {
            setVisible(false)
            history.push(`/integrations/${sellsyConnection.externalSource.toLowerCase()}`)
          }}
          primary
          label={t('component.failedSellsyConnectionPopup.buttonLabel')}
        />
      </div>
    </Modal>
  )
}
