import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import resourcesActions from 'actions/ResourcesActions'
import { Modal } from 'antd'
import CategorySelect from 'components/categories/CategorySelect'
import CategoryTag from 'components/categories/CategoryTag'
import Button from 'design/Button'
import { flatMap } from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getStatus } from 'redux-resource'
import { getCategoriesInTreeSelector } from 'selectors/categories'

export default ({ category }) => {
  const { t } = useTranslation()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const openMoveCategoryPopup = useCallback(() => {
    setIsModalVisible(true)
  }, [setIsModalVisible])

  const closeMoveCategoryPopup = useCallback(() => {
    setIsModalVisible(false)
  }, [setIsModalVisible])

  const MoveCategoryModal = useCallback(() => {
    return (
      <Modal
        width={620}
        destroyOnClose
        title={t('hook.useMoveCategory.moveCategory')}
        open={isModalVisible}
        onCancel={closeMoveCategoryPopup}
        onClose={closeMoveCategoryPopup}
        footer={null}
      >
        <MoveCategoryModalContent category={category} closeMoveCategoryPopup={closeMoveCategoryPopup} />
      </Modal>
    )
  }, [t, isModalVisible, closeMoveCategoryPopup, category])

  return {
    openMoveCategoryPopup,
    MoveCategoryModal
  }
}

const MoveCategoryModalContent = ({ category, closeMoveCategoryPopup }) => {
  const { t } = useTranslation()
  const moveStatus = useSelector(state => getStatus(state, 'categories.requests.moveCategory.status'))

  const [parentId, setParentId] = useState()

  const dispatch = useDispatch()

  const moveCategory = useCallback((parentId) => {
    const index = 0
    const newParentId = parentId === 'null' ? undefined : parentId
    dispatch(resourcesActions.MOVE_CATEGORY(category.type, category.id, newParentId, index, closeMoveCategoryPopup))
  }, [dispatch, category.type, category.id, closeMoveCategoryPopup])

  const categoryTree = useSelector(state => getCategoriesInTreeSelector(state, category.type))
  const alreadyUnderParentId = useMemo(() => {
    return (parentId === 'null' ? category.parentId === null : category.parentId === parentId)
  }, [parentId, category.parentId])

  const parentIdIsUnderSameTree = useMemo(() => {
    const treePart = findTreePart(categoryTree, category.id)
    const included = isIncludedIntoTreePart(treePart, parentId)
    return included
  }, [categoryTree, category.id, parentId])

  const moveCategoryDisabled = useMemo(() => {
    return parentId === category.id || alreadyUnderParentId || parentIdIsUnderSameTree || parentId === undefined
  }, [parentId, category.id, alreadyUnderParentId, parentIdIsUnderSameTree])

  return (
    <div className='flex flex-col'>
      <span className='font-bold mb-2'>
        <Trans
          i18nKey='hook.useMoveCategory.whereDoYouWantToMoveCategory'
          components={{ CategoryTag: <CategoryTag category={category} className='mx-1' /> }}
        />
      </span>

      <CategorySelect
        value={parentId}
        type={category.type}
        onChange={setParentId}
        placeholder={t('hook.useMoveCategory.parentCategory')}
        withEmpty
        emptyLabel={category.type === 'cashin' ? t('shared.cashinPlural') : t('shared.cashoutPlural')}
        className='w-1/2'
      />

      {parentIdIsUnderSameTree && (
        <span className='text-yellow-600 flex flex-row items-center mt-4'>
          <ExclamationTriangleIcon className='w-4 h-4 mr-4' />
          <span>{t('hook.useMoveCategory.warning.categoryCannotBeUnderItself')}</span>
        </span>
      )}

      {alreadyUnderParentId && (
        <span className='text-yellow-600 flex flex-row items-center mt-4'>
          <ExclamationTriangleIcon className='w-4 h-4 mr-4' />
          <span>{t('hook.useMoveCategory.warning.categoryAlreadyUnderSelectedCategory')}</span>
        </span>
      )}

      <div className='w-full flex flex-row items-center justify-end space-x-2 mt-4'>
        <Button onClick={closeMoveCategoryPopup} label={t('hook.useMoveCategory.cancel')} />
        <Button
          loading={moveStatus.pending}
          disabled={moveCategoryDisabled}
          onClick={() => moveCategory(parentId)}
          primary
          label={t('hook.useMoveCategory.move')}
        />
      </div>
    </div>
  )
}

MoveCategoryModalContent.propTypes = {
  category: PropTypes.object,
  closeMoveCategoryPopup: PropTypes.func
}

function findTreePart (categoryTree, categoryId) {
  const tree = categoryTree.find((category) => (category.id === categoryId))

  if (tree) return tree

  const allChildren = flatMap(categoryTree, (cat) => cat.children || [])

  return findTreePart(allChildren, categoryId)
}

function isIncludedIntoTreePart (treePart, parentId) {
  if (!parentId || parentId === 'null' || !treePart) return false
  if (treePart.id === parentId) return true

  const found = treePart.children?.find((cat) => {
    if (cat.id === parentId) return true
    return isIncludedIntoTreePart(cat.children, parentId)
  })

  return !!found
}
