import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { Input, List } from 'antd'
import { useDebounce } from 'use-debounce'

import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid'
import TransactionPreview from './TransactionPreview'
import ButtonLink from 'design/ButtonLink'
import { useTranslation } from 'react-i18next'

const { Search } = Input

TransactionSelect.propTypes = {
  type: PropTypes.oneOf(['cashin', 'cashout']),
  onSelect: PropTypes.func,
  selectedId: PropTypes.string,
  gqlQuery: PropTypes.object,
  exactAmountToMatchFirst: PropTypes.number
}

export default function TransactionSelect ({ type, onSelect, selectedId, gqlQuery, exactAmountToMatchFirst }) {
  const { t } = useTranslation()
  const [total, setTotal] = useState(0)
  const [data, setData] = useState([])
  const [searchText, setSearchText] = useState()
  const [search] = useDebounce(searchText, 250)

  const resetSelection = useCallback(() => { onSelect(undefined) }, [onSelect])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(resetSelection, [search]) // not specified resetSelection dependency otherwise break feature of selecting a transaction

  const onCompleted = useCallback((result) => {
    const data = result?.allTransactions?.transactions
    const total = result?.allTransactions?.total || 0
    setData(data)
    setTotal(total)
  }, [setData, setTotal])

  const { loading, refetch } = useQuery(gqlQuery, {
    fetchPolicy: 'cache-and-network',
    variables: { type, search, limit: 5, offset: 0, exactAmountToMatchFirst },
    // From https://github.com/apollographql/react-apollo/issues/3709#issuecomment-592786578
    notifyOnNetworkStatusChange: true,
    onCompleted
  })

  const handleSearchChange = useCallback((event) => {
    setSearchText(event.target.value)
  }, [setSearchText])

  const searchLoading = useMemo(() => {
    return loading || search !== searchText
  }, [loading, search, searchText])

  const loadMoreButton = useMemo(() => {
    if (data.length >= total) return null

    return (
      <div className='w-full p-2 flex justify-center'>
        <ButtonLink
          loading={loading}
          disabled={searchLoading}
          label={(
            <div className='flex flex-row items-center'>
              <EllipsisHorizontalIcon className='w-4 h-4 mr-2' />
              {t('shared.loadMore', { dataLength: data.length, total })}
            </div>
          )}
          onClick={() => refetch({ limit: data.length + 5 })}
        />
      </div>
    )
  }, [data.length, total, loading, searchLoading, t, refetch])

  return (
    <div className='flex flex-col grow overflow-hidden'>
      <Search
        placeholder={t('component.transactionSelect.transactionSearch')}
        onChange={handleSearchChange}
        loading={searchLoading}
        allowClear
        className='mb-4'
      />

      <div className='grow rc-transaction-select-list-container pr-1 rc-show-scrollbar'>
        <List
          loadMore={loadMoreButton}
          loading={loading}
          dataSource={data}
          renderItem={transaction => (
            <List.Item key={transaction.id} className='pt-0'>
              <TransactionPreview
                transaction={transaction}
                showCategory
                selectable
                selected={selectedId === transaction.id}
                onClick={() => onSelect(transaction)}
              />
            </List.Item>
          )}
          split={false}
        />
      </div>
    </div>
  )
}
