import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import colors from 'utils/colors'
import Currency from 'components/Currency'
import CashflowSheetComparisonCell from './components/CashflowSheetComparisonCell'
import CashflowSheetDefaultCell from './components/CashflowSheetCurrencyCell'
import CashflowSheetForecastedCompletionRateOverlay from '../CashflowSheetForecastedCompletionRateOverlay.js'
import CashType from 'enums/CashType.js'

CashflowSheetTypeCell.propTypes = {
  row: PropTypes.object,
  sheetDate: PropTypes.object,
  value: PropTypes.object,
  valueIndex: PropTypes.number,
  setDrawerParams: PropTypes.func,
  realisedVsForecasted: PropTypes.bool,
  showPercentage: PropTypes.bool
}

export default function CashflowSheetTypeCell ({ row, sheetDate, value, valueIndex, setDrawerParams, realisedVsForecasted, showPercentage }) {
  const onClickLeft = useCallback(() => {
    setDrawerParams({ visible: true, rowAbsolutePath: row.absolutePath, valueIndex, tabIndex: 0 })
  }, [setDrawerParams, row.absolutePath, valueIndex])

  const onClickRight = useCallback(() => {
    setDrawerParams({ visible: true, rowAbsolutePath: row.absolutePath, valueIndex, tabIndex: 1 })
  }, [setDrawerParams, row.absolutePath, valueIndex])

  const realised = row.type === 'CASH_IN' ? value.realisedCashin : value.realisedCashout
  const expected = row.type === 'CASH_IN' ? value.expectedCashin : value.expectedCashout
  const outstandingExpected = row.type === 'CASH_IN' ? value.outstandingExpectedCashin : value.outstandingExpectedCashout
  const forecasted = row.type === 'CASH_IN' ? value.forecastedCashin : value.forecastedCashout
  const fillColor = row.type === 'CASH_IN' ? colors.success : colors.error
  const cashType = row.type === 'CASH_IN' ? CashType.CASHIN : CashType.CASHOUT

  if (!sheetDate.isFutureMonth && (sheetDate.isCurrentMonth || realisedVsForecasted)) {
    return (
      <CashflowSheetForecastedCompletionRateOverlay
        type={cashType}
        outlineBadge
        visible={realisedVsForecasted}
        realisedAmount={realised}
        forecastedAmount={forecasted}
        showPercentage={showPercentage}
      >
        <CashflowSheetComparisonCell
          onClickLeft={onClickLeft}
          onClickRight={onClickRight}
          fill={fillColor}
          realisedAmount={realised}
          expectedAmount={expected}
          outstandingExpectedAmount={outstandingExpected}
          forecastedAmount={forecasted}
          strong
        />
      </CashflowSheetForecastedCompletionRateOverlay>
    )
  }

  if (!sheetDate.isFutureMonth) {
    return (
      <CashflowSheetForecastedCompletionRateOverlay
        type={cashType}
        outlineBadge
        visible={realisedVsForecasted}
        realisedAmount={realised}
        forecastedAmount={forecasted}
        showPercentage={showPercentage}
      >
        <CashflowSheetDefaultCell
          onClick={onClickLeft}
          justify={realisedVsForecasted ? 'start' : 'end'}
        >
          <Currency
            amount={realised}
            maskZero
            className={classNames('text-xs', { 'text-right': !realisedVsForecasted, 'text-left': realisedVsForecasted })}
          />
        </CashflowSheetDefaultCell>
      </CashflowSheetForecastedCompletionRateOverlay>
    )
  }

  if (forecasted) {
    return (
      <CashflowSheetDefaultCell
        onClick={onClickRight}
        justify='end'
      >
        <Currency amount={forecasted} />
      </CashflowSheetDefaultCell>
    )
  }

  return null
}
