import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { CHANGE_USER_ROLE } from 'graphql/users'
import UserRoleSelect from './UserRoleSelect'
import UserRoleBadge from './UserRoleBadge'
import { message } from 'antd'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

ChangeUserRole.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    role: PropTypes.string
  }),
  ownerId: PropTypes.string
}

function ChangeUserRole ({ user, ownerId, ...rest }) {
  const { t } = useTranslation()
  const [changeUserRole, { loading }] = useMutation(CHANGE_USER_ROLE, {
    onError: (e) => {
      const errorCode = get(e, 'graphQLErrors.0.extensions.code')

      switch (errorCode) {
        case 'ONLY_ADMIN_CAN_CHANGE_ROLE':
          message.warning(t('users.changeUserRole.onlyAdminCanChangeUserRole'))
          break
        case 'USER_CANNOT_UPDATE_OWN_ROLE':
          message.warning(t('users.changeUserRole.cannotChangeYourOwnRole'))
          break
        default:
          message.error(t('shared.anErrorHasOccured'))
          break
      }
    }
  })

  const onChange = useCallback((newRole) => {
    changeUserRole({ variables: { userIdToUpdate: user.id, newRole } })
  }, [changeUserRole, user.id])

  if (user.id === ownerId) return <UserRoleBadge role='owner' />

  return (
    <UserRoleSelect
      onChange={onChange}
      loading={loading}
      value={user.role}
      bordered={false}
      className='w-full -ml-3'
    />
  )
}

export default ChangeUserRole
