import React from 'react'
import Spinner from 'design/Spinner'

const LoadingScreen = () => {
  return (
    <div className='flex w-full h-full items-center justify-center bg-gray-100'>
      <Spinner className='w-6 h-6 text-primary' />
    </div>
  )
}

export default LoadingScreen
