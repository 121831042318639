import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import WcrForm from './WcrForm'

const UpdateWcrPopover = ({ onSave }) => {
  const { t } = useTranslation()
  return (
    <div>
      <div className='font-bold'>{t('component.wcrPopover.title')}</div>
      <div className='mb-2'>{t('component.wcrPopover.description')}</div>

      <WcrForm onCompleted={onSave} />
    </div>
  )
}

UpdateWcrPopover.propTypes = {
  onSave: PropTypes.func
}

export default UpdateWcrPopover
