import React from 'react'
import PropTypes from 'prop-types'
import { Popover } from '@headlessui/react'
import ChangeLocaleSelect from 'components/locale/ChangeLocaleSelect.js'
import { useTranslation } from 'react-i18next'
import { GlobeEuropeAfricaIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import useAuthenticatedContext from 'hooks/useAuthenticatedContext.js'

SettingsEmail.propTypes = {
  className: PropTypes.string
}

function SettingsEmail ({ className }) {
  const { user } = useAuthenticatedContext()
  const { t } = useTranslation()

  return (
    <Popover className='relative flex flex-row items-center'>
      <span className='text-xs whitespace-nowrap text-gray-500'>
        {user?.email}
      </span>

      <Popover.Button as='div' className={classNames('cursor-pointer group flex flex-row items-center hover:text-light-primary text-primary', className)}>
        <GlobeEuropeAfricaIcon className='w-5 h-5 ml-2' />
      </Popover.Button>

      <Popover.Panel unmount={false} className='absolute z-10 bottom-8 right-0 text-base border rounded-md' onClick={e => e.stopPropagation()}>
        <div className='flex flex-col shadow-md min-w-max p-2 bg-white rounded'>
          <span className='text-xs mr-2 mb-1'>
            {t('userSettings.displayLanguage')}
          </span>

          <ChangeLocaleSelect />
        </div>
      </Popover.Panel>
    </Popover>
  )
}

export default SettingsEmail
