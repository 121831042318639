import { createContext } from 'react'

const CashflowSheetContext = createContext({
  refetchCashflowsheet: () => true,
  loadingCashflowSheet: false,
  sheet: [],
  sheetDates: [],
  preparedSheet: [],
  currentMonthRealised: {}
})

export default CashflowSheetContext
