import { Button, Col, Input, Row, Form } from 'antd'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import ScenarioSelect from './ScenarioSelect'

const Scenarioform = ({ edit, duplicate, submit, initialValues, submitting, errors, ...rest }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const [changes, setChanges] = useState(false)

  const disabled = !changes || submitting

  useEffect(() => {
    if (errors && errors.length > 0) {
      form.setFields([{ name: 'name', value: form.getFieldValue('name'), errors }])
    }
  })

  // Handlers
  const handleChange = useCallback(() => {
    setChanges(true)
  }, [])

  const handleReset = useCallback(() => {
    form.resetFields()
    setChanges(false)
  }, [form])

  const handleFinish = useCallback((values) => {
    submit(values, handleReset)
  }, [handleReset, submit])

  const submitLabel = useMemo(() => {
    if (edit) return t('shared.save')
    return duplicate ? t('component.scenarioForm.duplicate') : t('component.scenarioForm.create')
  }, [edit, t, duplicate])

  return (
    <Form form={form} onFinish={handleFinish} onChange={handleChange} initialValues={initialValues} {...rest}>
      <Row>
        <Col xs={12}>
          <Form.Item label={edit ? '' : t('component.scenarioForm.nameOfNewScenario')} name='name'>
            <Input style={{ width: '200px' }} />
          </Form.Item>
        </Col>
        <Col xs={12}>
          {duplicate && (
            <Form.Item label={t('component.scenarioForm.scenarioToDuplicate')} name='duplicateScenarioId'>
              <ScenarioSelect />
            </Form.Item>
          )}
        </Col>
      </Row>

      <Form.Item>
        <Button
          htmlType='submit'
          type='primary'
          loading={submitting}
          disabled={disabled}
        >
          {submitLabel}
        </Button>
      </Form.Item>
    </Form>
  )
}

Scenarioform.defaultProps = {
  edit: false,
  duplicate: false
}

Scenarioform.propTypes = {
  edit: PropTypes.bool,
  submitting: PropTypes.bool,
  duplicate: PropTypes.bool,
  initialValues: PropTypes.object,
  submit: PropTypes.func,
  errors: PropTypes.array
}

export default Scenarioform
