import React, { useState, useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import useInvoicesQueryFilters from 'hooks/invoices/useInvoicesQueryFilters.js'
import { backgroundTextColors } from 'utils/colors.js'
import featureConfig from 'config/features.js'
import AccountingDocumentSearchInput from 'components/accounting-documents/AccountingDocumentSearchInput.js'

const badgeColorsMap = {
  ONGOING: 'primary',
  LATE: 'error',
  PAID: 'success',
  DRAFT: 'disabled'
}

const InvoiceSearchInput = ({ onSearchChanged, onStateFilterChanged, onWithIgnoredChanged, loading }) => {
  const { t } = useTranslation()
  const { invoicesQueryFilters, mergeInvoicesQueryFilters } = useInvoicesQueryFilters()
  const [state, setState] = useState('ONGOING')
  const [withIgnored, setWithIgnored] = useState(invoicesQueryFilters.withIgnored)

  useEffect(() => {
    onWithIgnoredChanged(withIgnored)
    mergeInvoicesQueryFilters({ withIgnored })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onWithIgnoredChanged, withIgnored]) // add mergeInvoicesQueryFilters on dependencies create a loop

  const handleSearchChange = useCallback((search) => {
    onSearchChanged(search)
  }, [onSearchChanged])

  const handleStateChange = useCallback((value) => {
    onStateFilterChanged(value !== 'ALL' ? value : null)
    setState(value || 'ALL')
  }, [onStateFilterChanged])

  const states = useMemo(() => {
    const states = {
      ALL: { label: t('component.invoiceSearchInput.all') },
      ONGOING: { label: t('component.invoiceSearchInput.ongoing'), className: backgroundTextColors[badgeColorsMap.ONGOING] },
      LATE: { label: t('component.invoiceSearchInput.late'), className: backgroundTextColors[badgeColorsMap.LATE] },
      PAID: { label: t('component.invoiceSearchInput.paid'), className: backgroundTextColors[badgeColorsMap.PAID] }
    }

    featureConfig.draftInvoicesEnabled && (
      states.DRAFT = { label: t('component.invoiceSearchInput.draft'), className: backgroundTextColors[badgeColorsMap.DRAFT] }
    )

    return states
  }, [t])

  return (
    <AccountingDocumentSearchInput
      states={states}
      currentState={state}
      handleStateChange={handleStateChange}
      handleSearchChange={handleSearchChange}
      withIgnored={withIgnored}
      handleWithIgnoredChange={setWithIgnored}
      loading={loading}
    />
  )
}

InvoiceSearchInput.propTypes = {
  onSearchChanged: PropTypes.func,
  onStateFilterChanged: PropTypes.func,
  onWithIgnoredChanged: PropTypes.func,
  loading: PropTypes.bool
}

export default InvoiceSearchInput
