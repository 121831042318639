import AppActions from 'actions/AppActions'
import { get } from 'lodash'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export default (defaultPageSize = 20) => {
  const dispatch = useDispatch()
  const pageSize = useSelector(state => get(state, 'app.pageSize', defaultPageSize))

  const setPageSize = useCallback((newPageSize) => {
    if (newPageSize !== pageSize) dispatch(AppActions.setPageSize(newPageSize))
  }, [dispatch, pageSize])

  return [pageSize, setPageSize]
}
