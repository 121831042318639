import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import { InformationCircleIcon } from '@heroicons/react/24/solid'
import Badge from 'design/Badge'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

ExpectedTransactionPaymentBadge.propTypes = {
  expectedTransaction: PropTypes.object
}

function ExpectedTransactionPaymentBadge ({ expectedTransaction }) {
  const { t } = useTranslation()

  const formattedPaymentDate = useMemo(() => {
    if (!expectedTransaction.paymentDate) return ''
    return moment(expectedTransaction.paymentDate).format('DD/MM/YYYY')
  }, [expectedTransaction.paymentDate])

  const tooltipLabel = useMemo(() => {
    return t('component.expectedTransactionPaymentBadge.paymentRecordedOn', { formattedPaymentDate })
  }, [formattedPaymentDate, t])

  if (!expectedTransaction.paymentDate) return null

  return (
    <Badge
      type='success'
      label={(
        <div className='flex flex-row items-center space-x-1 max-w-max'>
          <InformationCircleIcon className='h-3 w-3' />
          <span>{t('component.expectedTransactionPaymentBadge.paid')}</span>
        </div>
      )}
      tooltipLabel={tooltipLabel}
      placement='topLeft'
    />
  )
}

export default ExpectedTransactionPaymentBadge
