import { CheckOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import colors from 'utils/colors'

const ValidationButton = ({ validated, withLabel, withValidationProgress, label, block, ...rest }) => {
  const { t } = useTranslation()
  const buttonStyles = useMemo(() => {
    if (validated) return styles.buttonSuccess

    return undefined
  }, [validated])

  const buttonLabel = useMemo(() => {
    if (withLabel) {
      return label || t('component.validationButton.validate')
    }
  }, [withLabel, label, t])

  const className = useMemo(() => {
    const classes = []

    if (validated) {
      if (withValidationProgress) {
        classes.push('rc-validation-button')
      } else {
        classes.push('rc-validation-button-validated')
      }
    }

    if (block) classes.push('w-full')

    return classes.join(' ')
  }, [validated, withValidationProgress, block])

  return (
    <Button
      style={buttonStyles}
      shape='round'
      icon={<CheckOutlined />}
      // size='large'
      className={className}
      {...rest}
    >
      {buttonLabel}
    </Button>
  )
}

const styles = {
  buttonSuccess: {
    backgroundColor: colors.transparentSuccess,
    color: colors.white,
    borderColor: colors.success
  }
}

ValidationButton.propTypes = {
  validated: PropTypes.bool,
  disabled: PropTypes.bool,
  withLabel: PropTypes.bool,
  withValidationProgress: PropTypes.bool,
  label: PropTypes.string,
  block: PropTypes.bool
}

export default ValidationButton
