import { useMutation } from '@apollo/client'
import { Tooltip } from 'antd'
import { sumBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useContext, useMemo } from 'react'

import confirm from 'antd/lib/modal/confirm'
import classNames from 'classnames'
import ExpectedTransactionReconciliationContext from 'contexts/ExpectedTransactionReconciliationContext'
import Spinner from 'design/Spinner'
import { CANCEL_EXPECTED_TRANSACTION_RECONCILIATION, RECONCILIATE_EXPECTED_TRANSACTION } from 'graphql/expected-transactions'
import { REFRESH_TRANSACTION_RECONCILIATION_STATE } from 'graphql/transactions'
import { useTranslation } from 'react-i18next'
import ExpectedTransactionPreview from 'components/expected-transactions/ExpectedTransactionPreview'

ReconciliableExpectedTransactionPreview.propTypes = {
  type: PropTypes.string.isRequired,
  expectedTransaction: PropTypes.shape({
    id: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    expectedDate: PropTypes.string.isRequired,
    transactionId: PropTypes.string,
    expectedTransactionDetails: PropTypes.arrayOf(PropTypes.shape({
      amount: PropTypes.number.isRequired
    })),
    invoice: PropTypes.shape({
      thirdPartyName: PropTypes.string,
      invoiceNumber: PropTypes.string.isRequired
    })
  }).isRequired,
  reconciliationTransactionId: PropTypes.string.isRequired,
  reconciliableAmount: PropTypes.number
}

export default function ReconciliableExpectedTransactionPreview ({ type, expectedTransaction, reconciliationTransactionId, reconciliableAmount }) {
  const { t } = useTranslation()
  const reconciliationContext = useContext(ExpectedTransactionReconciliationContext)
  const isReconciliated = useMemo(() => !!expectedTransaction.transactionId, [expectedTransaction.transactionId])

  const totalAmount = useMemo(() => sumBy(expectedTransaction.expectedTransactionDetails, 'amount'), [expectedTransaction.expectedTransactionDetails])

  const [reconciliateExpectedTransaction, { loading: reconciliating }] = useMutation(RECONCILIATE_EXPECTED_TRANSACTION, {
    variables: { id: expectedTransaction.id, transactionId: reconciliationTransactionId },
    onCompleted: reconciliationContext.refetchReconciliated,
    refetchQueries: [{ query: REFRESH_TRANSACTION_RECONCILIATION_STATE, variables: { id: reconciliationTransactionId } }]
  })

  const [cancelReconciliation, { loading: canceling }] = useMutation(CANCEL_EXPECTED_TRANSACTION_RECONCILIATION, {
    variables: { id: expectedTransaction.id },
    onCompleted: () => {
      reconciliationContext.refetchSearch()
      reconciliationContext.refetchReconciliated()
    },
    refetchQueries: [{ query: REFRESH_TRANSACTION_RECONCILIATION_STATE, variables: { id: reconciliationTransactionId } }]
  })

  const loading = useMemo(() => (reconciliating || canceling), [reconciliating, canceling])

  const handleReconciliate = useCallback(() => {
    if (reconciliableAmount < totalAmount) {
      return confirm({
        title: t('component.reconciliableExpectedTransactionPreview.handleReconciliateConfirmMsg'),
        okText: t('shared.confirm'),
        cancelText: t('shared.cancel'),
        maskClosable: true,
        onOk () {
          reconciliateExpectedTransaction()
        }
      })
    } else {
      reconciliateExpectedTransaction()
    }
  }, [reconciliableAmount, totalAmount, t, reconciliateExpectedTransaction])

  const handleOnClick = useCallback(() => {
    if (!loading) {
      if (isReconciliated) cancelReconciliation()
      else if (reconciliationTransactionId) handleReconciliate()
    }
  }, [handleReconciliate, loading, reconciliationTransactionId, cancelReconciliation, isReconciliated])

  return (
    <Tooltip mouseEnterDelay={0.4} title={isReconciliated ? t('component.reconciliableExpectedTransactionPreview.undoReconciliation') : t('component.reconciliableExpectedTransactionPreview.reconciliateDueDateAndTransaction')}>
      <div className='relative w-full'>
        <ExpectedTransactionPreview
          type={type}
          expectedTransaction={expectedTransaction}
          onClick={handleOnClick}
          className={classNames('cursor-pointer hover:border-primary hover:opacity-75', {
            'border border-gray-400': !isReconciliated,
            'border-2 border-primary': isReconciliated
          })}
        />

        {loading && (
          <div className='absolute top-0 bottom-0 left-0 right-0 flex flex-row items-center justify-center w-full h-full bg-ultra-light-primary bg-opacity-25'>
            <Spinner className=' w-6 h-6 text-primary' />
          </div>
        )}
      </div>
    </Tooltip>
  )
}
