import React from 'react'

import FailedBankConnectionPopup from 'components/global/FailedBankConnectionPopup'
import NewConnectionPopup from 'components/global/NewConnectionPopup'
import SubscriptionGlobalComponents from 'components/subscription/SubscriptionGlobalComponents'
import FailedSellsyConnectionPopup from 'components/global/FailedSellsyConnectionPopup.js'

export default function GlobalComponents () {
  return (
    <>
      <FailedBankConnectionPopup />
      <NewConnectionPopup />
      <SubscriptionGlobalComponents />
      <FailedSellsyConnectionPopup />
    </>
  )
}
