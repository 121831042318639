import { Button } from 'antd'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ExpectedTransactionExpectedDatePicker from './ExpectedTransactionExpectedDatePicker'
// import { DownCircleOutlined } from '@ant-design/icons'

const ExpectedTransactionExpectedDatePickerShortcut = ({ accountingDocument, onExpand }) => {
  const { t } = useTranslation()
  const singleExpectedTransaction = useMemo(() => {
    if (get(accountingDocument, 'expectedTransactions.length', 0) > 1) return null

    return get(accountingDocument, 'expectedTransactions.0')
  }, [accountingDocument])

  if (singleExpectedTransaction) {
    return (
      <ExpectedTransactionExpectedDatePicker expectedTransaction={singleExpectedTransaction} />
    )
  }

  return (
    <Button
      type='link'
      onClick={onExpand}
      block
      className='text-xs'
      size='small'
    >
      {t('component.expectedTransactionExpectedDatePickerShortcut.multiplePayments')}
    </Button>
  )
}

ExpectedTransactionExpectedDatePickerShortcut.propTypes = {
  accountingDocument: PropTypes.object,
  onExpand: PropTypes.func
}

export default ExpectedTransactionExpectedDatePickerShortcut
