import { useLazyQuery } from '@apollo/client'
import { GET_POWENS_CONNECT_URL, GET_POWENS_MANAGE_URL } from 'graphql/powens.js'
import { useCallback } from 'react'
import useQuipuAppRootUrl from './quipu/useQuipuAppRootUrl.js'

export default function useManageBankConnections () {
  const quipuAppRootUrl = useQuipuAppRootUrl()

  const [getPowensConnectUrl, { loading: gettingPowensConnectUrl }] = useLazyQuery(GET_POWENS_CONNECT_URL, {
    onCompleted: (data) => {
      if (data.getPowensConnectUrl) window.location.href = data.getPowensConnectUrl
    }
  })

  const redirectToConnectAccountsUrl = useCallback(() => {
    if (process.env.REACT_APP_TENANT === 'QUIPU') {
      window.location.href = `${quipuAppRootUrl}/banks`
    } else {
      getPowensConnectUrl()
    }
  }, [getPowensConnectUrl, quipuAppRootUrl])

  const [getPowensManageUrl, { loading: gettingPowensManageUrl }] = useLazyQuery(GET_POWENS_MANAGE_URL, {
    onCompleted: (data) => {
      if (data.getPowensManageUrl) window.location.href = data.getPowensManageUrl
    }
  })

  const redirectToManageAccountsUrl = useCallback(() => {
    if (process.env.REACT_APP_TENANT === 'QUIPU') {
      window.location.href = `${quipuAppRootUrl}/banks`
    } else {
      getPowensManageUrl()
    }
  }, [getPowensManageUrl, quipuAppRootUrl])

  // In QUIPU case, no redirecting status is needed, the redirect will be instant.
  const redirectingToConnectAccountsUrl = gettingPowensConnectUrl
  const redirectingToManageAccountsUrl = gettingPowensManageUrl

  return {
    redirectToConnectAccountsUrl,
    redirectToManageAccountsUrl,
    redirectingToConnectAccountsUrl,
    redirectingToManageAccountsUrl
  }
}
