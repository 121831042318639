import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Typography, Progress, Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'

import OnboardingContext from './OnboardingContext'
import theme from 'theme'

const { Title, Text } = Typography

const OnboardingHeader = ({ title }) => {
  const { t } = useTranslation()
  const context = useContext(OnboardingContext)
  const maxIndex = context.totalNumberOfSteps - 1
  const percentStep = useMemo(() => {
    return (context.currentStepIndex / maxIndex) * 100
  }, [context.currentStepIndex, maxIndex])

  return (
    <Row>
      <Col xs={20}>
        <Title level={3}>{title}</Title>
      </Col>

      <Col xs={4}>
        <div className='w-full flex justify-center'>
          <Text>{t('component.onboardingHeader.stepXOverX', { currentStepIndex: context.currentStepIndex, maxIndex })}</Text>
        </div>
        <div className='-mt-2'>
          <Progress status='active' percent={percentStep} showInfo={false} strokeColor={theme.colors.primary} />
        </div>
      </Col>
    </Row>
  )
}

OnboardingHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
}

export default OnboardingHeader
