import { gql } from '@apollo/client'
import { ACCOUNTING_DOCUMENT_NESTED_EXPECTED_TRANSACTIONS_FIELDS } from './expected-transactions.js'
import { getAccountingDocumentFields, getAccountingDocumentListFields, getAddExpectedTransactionToAccountingDocumentFields } from './accounting-documents.js'

const ACCOUNTING_DOCUMENTS_FIELDS = getAccountingDocumentFields('Invoice')
const ACCOUNTING_DOCUMENTS_LIST_FIELDS = getAccountingDocumentListFields('Invoice')
const ADD_EXPECTED_TRANSACTION_TO_ACCOUNTING_DOCUMENT_FIELDS = getAddExpectedTransactionToAccountingDocumentFields('Invoice')

export const GET_INVOICE = gql`
  ${ACCOUNTING_DOCUMENTS_FIELDS}
  ${ACCOUNTING_DOCUMENT_NESTED_EXPECTED_TRANSACTIONS_FIELDS}
  query getInvoice(
    $id: ID!
  ) {
    getInvoice(
      id: $id
    ) {
      ...InvoicesFields
      invoiceNumber
      cashType
      documentUrl
      expectedTransactions {
        ...accountingDocumentNestedExpectedTransactionsFields
      }
    }
  }
`

export const LIST_INVOICES = gql`
  ${ACCOUNTING_DOCUMENTS_LIST_FIELDS}
  ${ACCOUNTING_DOCUMENT_NESTED_EXPECTED_TRANSACTIONS_FIELDS}
  query allInvoices(
    $offset: Int
    $limit: Int!
    $type: String!
    $search: String
    $stateFilter: StateFilterType
    $withIgnored: Boolean
  ) {
    allInvoices(
      offset: $offset
      limit: $limit
      type: $type
      search: $search
      stateFilter: $stateFilter
      withIgnored: $withIgnored
    ) {
      total
      invoices {
        ...InvoicesListFields
        invoiceNumber
        cashType
        documentUrl
        expectedTransactions {
         ...accountingDocumentNestedExpectedTransactionsFields
        }
      }
    }
  }
`

export const AMOUNTS_INVOICES = gql`
query amountsInvoices(
  $type: String!
  $search: String
  $stateFilter: StateFilterType
) {
  amountsInvoices(
    type: $type
    search: $search
    stateFilter: $stateFilter
  ) {
    totalAmountsRemaining
    totalAmountsRemainingExcludingVAT
  }
}
`

export const AGGREGATE_INVOICE_IMPORTS = gql`
  query aggregateInvoiceImports($type: CashType!) {
    aggregateInvoiceImports(type: $type) {
      externalImportId
      date
      numberOfInvoice
    }
  }
`

export const CREATE_INVOICE_MANUALLY = gql`
  mutation createInvoiceManually($input: CreateInvoiceManuallyInput!) {
    createInvoiceManually(input: $input) {
      id
    }
  }
`

export const UPDATE_INVOICE_MANUALLY = gql`
  mutation updateInvoiceManually($input: UpdateInvoiceManuallyInput!) {
    updateInvoiceManually(input: $input) {
      id
    }
  }
`

export const IMPORT_INVOICES_FROM_UPLOAD = gql`
  mutation importInvoicesFromUpload($invoicesParams: [InvoiceParamInput]!) {
    importInvoicesFromUpload(invoicesParams: $invoicesParams) {
      insertedInvoices {
        id
      }
    }
  }
`

export const IGNORE_INVOICES = gql`
  mutation ignoreInvoices($input: IgnoreInvoicesInput!) {
    ignoreInvoices(input: $input) {
      id
      ignored
    }
  }
`

export const ADD_EXPECTED_TRANSACTION_TO_INVOICE = gql`
  ${ADD_EXPECTED_TRANSACTION_TO_ACCOUNTING_DOCUMENT_FIELDS}
  mutation addExpectedTransactionToInvoice($input: AddExpectedTransactionToInvoiceInput!) {
    addExpectedTransactionToInvoice(input: $input) {
      ...addExpectedTransactionToInvoiceFields
    }
  }
`

export const DELETE_INVOICE_FROM_UPLOAD = gql`
  mutation deleteInvoicesFromUpload($externalImportId: String!) {
    deleteInvoicesFromUpload(externalImportId: $externalImportId)
  }
`

export const DELETE_INVOICE_FROM_IDS = gql`
  mutation deleteInvoicesFromIds($invoiceIds: [ID!]!) {
    deleteInvoicesFromIds(invoiceIds: $invoiceIds) {
      deletedCount
    }
  }
`

export const LIST_INVOICE_EXISTING_EXTERNAL_IDS = gql`
  query listInvoiceExistingExternalIds($type: String, $externalIds: [String]!) {
    listInvoiceExistingExternalIds(type: $type, externalIds: $externalIds) {
      existingsExternalIds
    }
  }
`
