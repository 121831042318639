import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { MinusCircleIcon } from '@heroicons/react/24/outline'
import { round } from 'lodash'

import PercentInput from 'components/PercentInput'
import CurrencyInput from 'components/CurrencyInput'
import CategorySelect from 'components/categories/CategorySelect'
import VatSelect from 'components/vat/VatSelect'

const SplitTransactionForm = ({ type, transactionAmount, part, onUpdate, onRemove, canRemove }) => {
  const updateProperties = useCallback((updatedProperties) => {
    const updatedPart = { ...part, ...updatedProperties }
    onUpdate(updatedPart)
  }, [part, onUpdate])

  const percentage = useMemo(() => {
    return round((part.amount / transactionAmount * 100), 2)
  }, [part.amount, transactionAmount])

  const vatAmount = useMemo(() => {
    const amount = part.amount
    const vatRate = part.vatRate
    if (!part.vatRate) return
    return round(amount - (amount / (1 + vatRate)), 2)
  }, [part.amount, part.vatRate])

  const updateAmount = useCallback((amount) => {
    const newAmount = round(amount, 2)
    updateProperties({ amount: newAmount || 0.01 })
  }, [updateProperties])

  const updatePercentage = useCallback((percentage) => {
    const newAmount = round((transactionAmount * percentage) / 100, 2)
    updateAmount(newAmount)
  }, [transactionAmount, updateAmount])

  const updateVatAmount = useCallback((vatAmount) => {
    if (!vatAmount) return
    const vatRate = part.vatRate
    const newAmount = round(vatAmount + (vatAmount / vatRate), 2)
    updateAmount(newAmount)
  }, [updateAmount, part.vatRate])

  return (
    <div className='w-full flex flex-row items-center space-x-8 mt-4'>
      <div className='w-4/12 flex flex-row items-center'>
        <div className='w-4/12'>
          <PercentInput
            min={0.01}
            max={100}
            value={percentage}
            onChange={updatePercentage}
          />
        </div>

        <div className='w-2/12 flex flex-row justify-center'>
          <span className='font-bold text-gray-500 mx-2'>/</span>
        </div>

        <div className='w-6/12'>
          <CurrencyInput
            onChange={updateAmount}
            value={part.amount}
            min={0.01}
            max={transactionAmount}
            step={10}
          />
        </div>
      </div>

      <div className='w-4/12 flex flex-row items-center'>
        <VatSelect
          onChange={(vatRate) => updateProperties({ vatRate })}
          value={part.vatRate}
          className='w-4/12'
        />

        <div className='w-2/12 flex flex-row justify-center'>
          <span className='font-bold text-gray-500 mx-2'>/</span>
        </div>

        <div className='w-6/12'>
          <CurrencyInput
            disabled={!part.vatRate}
            onChange={updateVatAmount}
            value={vatAmount}
            min={0.01}
            max={transactionAmount}
            step={10}
          />
        </div>
      </div>

      <div className='w-4/12 flex flex-row items-center'>
        <CategorySelect
          withCreateButton
          onChange={(categoryId) => updateProperties({ categoryId })}
          type={type}
          value={part.categoryId}
          style={{ width: '100%' }}
          className='w-full'
        />

        {canRemove && (
          <button onClick={onRemove}>
            <MinusCircleIcon className='ml-2 w-4 h-4 text-gray-500 hover:text-gray-400 cursor-pointer' />
          </button>
        )}
      </div>
    </div>
  )
}

SplitTransactionForm.propTypes = {
  type: PropTypes.oneOf(['cashin', 'cashout']).isRequired,
  transactionAmount: PropTypes.number.isRequired,
  part: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    vatRate: PropTypes.number,
    categoryId: PropTypes.string
  }).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  canRemove: PropTypes.bool
}

export default SplitTransactionForm
