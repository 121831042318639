import { gql } from '@apollo/client'

export const COMPUTE_CASHFLOW_SHEET = gql`
  query computeCashflowSheet(
    $fromDate: Date!
    $toDate: Date!
    $scenarioId: ID!
    $withExpected: Boolean
    $withVatDetails: Boolean
  ) {
    computeCashflowSheet(fromDate: $fromDate, toDate: $toDate, scenarioId: $scenarioId, withExpected: $withExpected, withVatDetails: $withVatDetails) {
      currentMonthRealised {
        date
        value {
          realisedCashin
          realisedCashout
          realisedEndingBalance
        }
      }
      sheet {
        date
        value {
          realisedStartingBalance
          forecastedStartingBalance

          realisedCashin
          expectedCashin
          paidExpectedCashin
          outstandingExpectedCashin
          budgetedCashin
          forecastedCashin

          cashinDetailsByCategory {
            categoryId
            realised
            expected
            paidExpected
            outstandingExpected
            budgeted
            budgetedEditable
            forecasted
            comment
          }

          realisedCashout
          expectedCashout
          paidExpectedCashout
          outstandingExpectedCashout
          budgetedCashout
          forecastedCashout

          cashoutDetailsByCategory {
            categoryId
            realised
            expected
            paidExpected
            outstandingExpected
            budgeted
            budgetedEditable
            forecasted
            comment
          }

          realisedVatPayment
          forecastedVatPayment

          realisedCashflow
          budgetedCashflow
          forecastedCashflow

          realisedEndingBalance
          forecastedEndingBalance
        }
      }
    }
  }
`

export const GET_ACTIONS_LEFT = gql`
  query getActionsLeft {
    actionsLeft {
      category
    }
  }
`
