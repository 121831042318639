import React, { useCallback, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { getStatus } from 'redux-resource'
import { useSelector, useDispatch } from 'react-redux'
import { Modal } from 'antd'
import { get } from 'lodash'

import resourcesActions from 'actions/ResourcesActions'
import ScenarioForm from './ScenarioForm'
import { useTranslation } from 'react-i18next'
import { ScenariosContext } from 'contexts/ScenariosContext.js'

const EditScenarioModal = ({ scenarioId, year, visible, setVisible, onComplete }) => {
  const dispatch = useDispatch()
  const { data: scenariosData } = useContext(ScenariosContext)

  const scenario = useMemo(() => (
    scenariosData?.listScenarios.find((scenario) => scenario.id === scenarioId)
  ), [scenarioId, scenariosData])
  const editScenarioStatus = useSelector(state => getStatus(state, `scenarios.requests.updateScenario-${scenarioId}.status`))
  const editScenarioErrors = useSelector(state => get(state, `scenarios.requests.updateScenario-${scenarioId}.errors`))
  const { t } = useTranslation()

  const adaptedErrors = useMemo(() => {
    if (editScenarioErrors) {
      return [editScenarioErrors]
    }
  }, [editScenarioErrors])

  const editScenario = useCallback((params) => {
    dispatch(resourcesActions.UPDATE_SCENARIO(scenario, year, params, () => onComplete()))
  }, [dispatch, scenario, year, onComplete])

  return (
    <Modal
      title={t('component.editScenarioModal.title')}
      open={visible}
      onClose={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      centered
      footer={null}
    >
      <ScenarioForm
        edit
        errors={adaptedErrors}
        initialValues={{ ...scenario }}
        submitting={editScenarioStatus.pending}
        submit={editScenario}
        style={{ marginLeft: 10 }}
      />
    </Modal>
  )
}

EditScenarioModal.propTypes = {
  scenarioId: PropTypes.string,
  year: PropTypes.number,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  onComplete: PropTypes.func
}

export default EditScenarioModal
