
import { get } from 'lodash'
import { useQuery } from '@apollo/client'

import { COMPUTE_CASHFLOW_SHEET } from 'graphql/aggregations'
import useCashflowSheetDates from 'hooks/useCashflowSheetDates'
import usePreparedSheet from 'hooks/usePreparedCashflowSheet'
import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import useAuthenticatedContext from './useAuthenticatedContext'
import featureConfig from 'config/features.js'
import { ScenariosContext } from 'contexts/ScenariosContext.js'

export function useCashflowSheetContext ({ fromDate, toDate, scenarioId }) {
  const { subscription } = useAuthenticatedContext()

  const { data: scenariosData } = useContext(ScenariosContext)

  const scenarioWithVatDetails = useMemo(() =>
    scenariosData?.listScenarios.find((scenario) => scenario.id === scenarioId)?.withVatDetails, [scenariosData, scenarioId])

  const realisedVsForecasted = useSelector(state => get(state, 'app.dashboard.realisedVsForecasted', false))

  const withVatDetails = useMemo(() => {
    if (!featureConfig.vatDetailsEnabled) return false

    return get(subscription, 'hasAccessToVatDetails', true) && scenarioWithVatDetails
  }, [subscription, scenarioWithVatDetails])

  const { previousData, data, loading, refetch } = useQuery(COMPUTE_CASHFLOW_SHEET, {
    fetchPolicy: 'cache-and-network',
    variables: {
      fromDate,
      toDate,
      scenarioId,
      withExpected: !realisedVsForecasted,
      withVatDetails
    }
  })

  const computeCashflowSheet = useMemo(() => get((data || previousData), 'computeCashflowSheet'), [data, previousData])
  const sheet = useMemo(() => computeCashflowSheet?.sheet, [computeCashflowSheet])
  const currentMonthRealised = useMemo(() => computeCashflowSheet?.currentMonthRealised, [computeCashflowSheet])
  const sheetDates = useCashflowSheetDates({ sheet })
  const preparedSheet = usePreparedSheet({ sheet, sheetDates, realisedVsForecasted })

  const cashflowSheetContextValue = useMemo(() => {
    return {
      refetchCashflowSheet: refetch,
      loadingCashflowSheet: loading,
      sheet,
      sheetDates,
      preparedSheet,
      currentMonthRealised
    }
  }, [refetch, loading, sheet, sheetDates, preparedSheet, currentMonthRealised])

  return cashflowSheetContextValue
}
