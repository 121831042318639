import { useMutation } from '@apollo/client'
import { loadStripe } from '@stripe/stripe-js'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { message } from 'antd'
import UpgradePopup from 'components/subscription/UpgradePopup'
import { CREATE_CHECKOUT_SESSION, UPGRADE_SUBSCRIPTION } from 'graphql/subscriptions'
import { get } from 'lodash'
import useAuthenticatedContext from './useAuthenticatedContext'

export default () => {
  const { t } = useTranslation()
  const { subscription } = useAuthenticatedContext()
  const [loading, setLoading] = useState(false)
  const [upgradePopupParams, setUpgradePopupParams] = useState({
    visible: false,
    loading: false,
    plan: null,
    recurrence: null
  })
  const history = useHistory()

  const [createCheckoutSession, { loading: createCheckoutSessionLoading }] = useMutation(CREATE_CHECKOUT_SESSION, {
    onCompleted: async (data) => {
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)
      await stripe.redirectToCheckout({ sessionId: data.createCheckoutSession })
      setLoading(false)
    }
  })

  const [upgradeSubscription, { loading: upgradeSubscriptionLoading }] = useMutation(UPGRADE_SUBSCRIPTION, {
    onCompleted: async () => {
      setUpgradePopupParams({ ...upgradePopupParams, visible: false, loading: false })
      history.replace('/dashboard?upgSub=1')
    },
    onError: ({ graphQLErrors }) => {
      setUpgradePopupParams({ ...upgradePopupParams, loading: false })

      const errorCode = get(graphQLErrors, '0.extensions.code')

      switch (errorCode) {
        case 'INVALID_PROMOTION_CODE':
          message.warning({ content: t('hook.useSubscribeToPlan.warning.invalidPromoCode'), duration: 15 })
          break
        default:
          message.error(t('hook.useSubscribeToPlan.error.planUpgradeFailed'))
          break
      }
    }
  })

  const createCheckout = useCallback(({ plan, recurrence }) => {
    setLoading(true)
    createCheckoutSession({ variables: { plan, recurrence } })
  }, [setLoading, createCheckoutSession])

  const upgradeToPlan = useCallback(({ plan, recurrence }) => {
    setUpgradePopupParams({
      loading: false,
      visible: true,
      plan,
      recurrence
    })
  }, [setUpgradePopupParams])

  const onUpgradeConfirm = useCallback(({ plan, recurrence, promotionCode }) => {
    setUpgradePopupParams({ ...upgradePopupParams, loading: true })

    const variables = { newPlan: plan, recurrence }
    if (promotionCode) variables.promotionCode = promotionCode
    upgradeSubscription({ variables })
  }, [upgradeSubscription, upgradePopupParams])

  const subscribeToPlan = subscription?.id ? upgradeToPlan : createCheckout

  return {
    subscribeToPlan,
    upgradePopup: subscription?.id ? (
      <UpgradePopup
        params={upgradePopupParams}
        onConfirm={onUpgradeConfirm}
        setVisible={(visible) => setUpgradePopupParams({ ...upgradePopupParams, visible })}
      />
    ) : null,
    loading: createCheckoutSessionLoading || upgradeSubscriptionLoading || loading
  }
}
