import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Tooltip } from 'antd'
import { backgroundTextColors, outlineBackgroundTextColors } from 'utils/colors.js'

Badge.propTypes = {
  label: PropTypes.node,
  className: PropTypes.string,
  outline: PropTypes.bool,
  type: PropTypes.oneOf(['success', 'error', 'warning', 'primary', 'info', 'disabled']),
  tooltipLabel: PropTypes.string
}

export default function Badge ({ label, className, type, outline, tooltipLabel, ...rest }) {
  const colorClassnames = useMemo(() => {
    if (outline) {
      return cx(outlineBackgroundTextColors[(type || 'success')], 'bg-white border')
    }

    return backgroundTextColors[(type || 'success')]
  }, [outline, type])

  return (
    <Tooltip
      title={tooltipLabel}
      className={cx(
        'flex-shrink-0 inline-block px-2 py-0.5 text-xs font-medium rounded',
        className,
        colorClassnames
      )}
      {...rest}
    >
      {label}
    </Tooltip>
  )
}
