import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

const sentryDsn = 'https://7907a280af994e65b6b94e3fa0880605@o462678.ingest.sentry.io/5466543'

Sentry.init({
  dsn: sentryDsn,
  environment: process.env.REACT_APP_MODE,
  enabled: process.env.NODE_ENV === 'production',
  integrations: [
    new Integrations.BrowserTracing()
  ],

  // Adjusting this value carrefully, can change sentry plan
  tracesSampleRate: 0.01
})
