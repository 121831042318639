import { InputNumber } from 'antd'
import cx from 'classnames'
import Spinner from 'design/Spinner.js'
import PropTypes from 'prop-types'
import React from 'react'
// import { parse } from 'accounting'

import { currencyToSymbol } from 'utils/currencies'

// const ensurePrecision = (strValue, precision) => {
//   if (strValue.includes(',') || !precision) return strValue

//   // FROM https://stackoverflow.com/a/7441540/4245210
//   const lastDigitIndex = strValue.match(/\d(?!.*\d)/)?.index || 0

//   const splitted = strValue.split('')
//   splitted.splice(lastDigitIndex - (precision - 1), 0, ',')

//   return splitted.join('')
// }

const defaultCurrency = 'EUR'
const symbol = currencyToSymbol[defaultCurrency]

const CurrencyInput = React.forwardRef(({ loading, grow, className, ...rest }, ref) => {
  return (
    <div className={cx('rc-unit-input relative flex flex-row items-center', { 'flex-grow': grow, 'w-max': !grow }, className)}>
      <InputNumber
        ref={ref}
        min={0}
        step={100}
        decimalSeparator=','
        className={cx({ 'flex-grow': grow }, className)}
        controls={false}
        {...rest}
      />

      {loading && (<Spinner className='absolute left-2 w-4 h-4 text-primary' />)}

      <div className='absolute right-2 text-xs'>{symbol}</div>
    </div>
  )
})

CurrencyInput.defaultProps = {
  loading: false
}

CurrencyInput.propTypes = {
  loading: PropTypes.bool,
  grow: PropTypes.bool,
  className: PropTypes.string
}

export default CurrencyInput
