import React from 'react'
import PropTypes from 'prop-types'

// FROM http://recharts.org/en-US/examples/PieChartWithCustomizedLabel
const RADIAN = Math.PI / 180

const PercentLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  if (percent === 0) return null

  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text x={x} y={y} fill='white' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

PercentLabel.propTypes = {
  cx: PropTypes.number,
  cy: PropTypes.number,
  midAngle: PropTypes.number,
  innerRadius: PropTypes.number,
  outerRadius: PropTypes.number,
  percent: PropTypes.number,
  index: PropTypes.number
}

export default PercentLabel
