import React from 'react'
import { Typography } from 'antd'

const { Title } = Typography

export default function NotFoundScreen () {
  return (
    <div className='rc-full-page-box-container'>
      <img
        width='800px'
        src='https://media.giphy.com/media/mNRSjGX753w9W/giphy.gif'
        alt='Not Found'
        style={{ borderRadius: '8px' }}
      />
      <Title style={{ color: 'white', position: 'relative', top: '-100px' }}>404 Not Found</Title>
    </div>
  )
}
