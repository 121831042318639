import React, { useCallback } from 'react'
import VatSelect from 'components/vat/VatSelect'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { SET_EXPECTED_TRANSACTION_DETAIL_VAT_RATE } from 'graphql/expected-transaction-details'
import classNames from 'classnames'

const ExpectedTransactionDetailVatRateSelect = ({ expectedTransactionDetail, onSelect, className, ...rest }) => {
  const type = expectedTransactionDetail.type
  const vatRate = expectedTransactionDetail.vatRate
  const [setExpTxnDetailVatRAte, { loading }] = useMutation(SET_EXPECTED_TRANSACTION_DETAIL_VAT_RATE)

  const handleOnChange = useCallback((vatRate) => {
    setExpTxnDetailVatRAte({
      variables: {
        id: expectedTransactionDetail.id,
        vatRate
      }
    })
    document.activeElement.blur()

    if (onSelect) onSelect()
  }, [setExpTxnDetailVatRAte, expectedTransactionDetail.id, onSelect])

  return (
    <VatSelect
      disabled={loading}
      loading={loading}
      type={type}
      value={vatRate}
      onChange={handleOnChange}
      className={classNames('w-full', className)}
      {...rest}
    />
  )
}

ExpectedTransactionDetailVatRateSelect.propTypes = {
  expectedTransactionDetail: PropTypes.object,
  onSelect: PropTypes.func,
  className: PropTypes.string
}

export default ExpectedTransactionDetailVatRateSelect
