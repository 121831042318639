import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import CategoryTag from './CategoryTag'
import { useTranslation } from 'react-i18next'
import { isUncategorized } from 'utils/categories.js'

CategoryTagFromId.propTypes = {
  id: PropTypes.string
}

function CategoryTagFromId ({ id, ...rest }) {
  const { t } = useTranslation()
  const category = useSelector(state => get(state, `categories.resources.${id}`))

  if (isUncategorized(id)) {
    return <CategoryTag category={{ name: t('components.CategoryTagFromId.notCategorized'), color: '#999999' }} {...rest} />
  }
  if (!category) return null

  return <CategoryTag category={category} {...rest} />
}

export default CategoryTagFromId
