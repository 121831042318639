import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Input, Popover } from 'antd'
import { trim } from 'lodash'
import Button from 'design/Button'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

PopoverTextAreaInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  children: PropTypes.node,
  onSave: PropTypes.func,
  className: PropTypes.string,
  inputContainerClassname: PropTypes.string,
  onVisibleChange: PropTypes.func
}

export default function PopoverTextAreaInput ({ placeholder, value, children, onSave, className, inputContainerClassname, onVisibleChange, ...rest }) {
  const [updating, setUpdating] = useState(false)
  const [visible, setVisible] = useState(false)
  const [updatedValue, setUpdatedValue] = useState(value)

  const { t } = useTranslation()

  // const hasChange = useMemo(() => { return value !== updatedValue }, [value, updatedValue])
  const handleCancel = useCallback(() => {
    setUpdatedValue(value)
    setVisible(false)
  }, [setVisible, setUpdatedValue, value])

  const handleOnVisibleChange = useCallback((visible) => {
    if (visible) setVisible(visible)
    if (value !== updatedValue) return

    setVisible(visible)
    if (onVisibleChange) onVisibleChange(visible)
  }, [setVisible, value, updatedValue, onVisibleChange])

  useEffect(() => {
    setUpdatedValue(value)
  }, [setUpdatedValue, value])

  const updateForecastComment = useCallback((debouncedComment) => {
    setUpdating(true)

    const newComment = trim(debouncedComment || updatedValue, ' \t\n\r')

    if (newComment === value) {
      setUpdating(false)
      return
    }

    if (onSave) {
      onSave({
        value: newComment,
        successCallback: () => {
          setUpdating(false)
          setVisible(false)
        }
      })
    } else {
      setUpdating(false)
      setVisible(false)
    }
  }, [updatedValue, value, setUpdating, setVisible, onSave])

  // const [debouncedUpdateTransaction] = useDebouncedCallback(updateForecastComment, 1000)

  const handleOnChange = useCallback((e) => {
    const newValue = e.target.value
    setUpdatedValue(newValue === '' ? undefined : newValue)
  }, [setUpdatedValue])

  return (
    <Popover
      open={visible}
      onOpenChange={handleOnVisibleChange}
      // trigger={value ? 'hover' : 'click'}
      // mouseLeaveDelay={0.3}
      content={
        <div
          onClick={(e) => e.stopPropagation()}
          className={cx('p-2', 'flex flex-col items-end', inputContainerClassname)}
        >
          <Input.TextArea
            value={updatedValue}
            onChange={handleOnChange}
            autoFocus
            autoSize={{ minRows: 3 }}
            placeholder={placeholder}
            allowClear
          />

          <div className='flex flex-row mt-2 space-x-2'>
            <Button disabled={updating} label={t('component.popoverTextAreaInput.cancel')} onClick={handleCancel} />
            <Button loading={updating} primary label={t('component.popoverTextAreaInput.save')} onClick={updateForecastComment} />
          </div>
        </div>
      }
      overlayClassName='rc-popover-no-padding'
      {...rest}
    >
      <div
        className={cx({
          visible: !!value || visible,
          'text-primary': !!updatedValue,
          'text-gray-400': !updatedValue
        }, className)}
      >
        {children}
      </div>
    </Popover>
  )
}
