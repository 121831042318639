import { useMutation } from '@apollo/client'
import { Dropdown, Tooltip } from 'antd'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'
import ActionsIcon from 'design/ActionsIcon'
import { useTranslation } from 'react-i18next'

import { IGNORE_RECURRING_PAYMENTS } from 'graphql/recurring-payments.js'

RecurringPaymentActions.propTypes = {
  recurringPayment: PropTypes.object
}

export default function RecurringPaymentActions ({ recurringPayment }) {
  const { t } = useTranslation()

  const [ignoreRecurringPayments, { loading: ignoreLoading }] = useMutation(IGNORE_RECURRING_PAYMENTS, {
    variables: { input: { ids: [recurringPayment.id], cancel: recurringPayment.ignored } }
  })

  const handleMenuClick = useCallback(({ key }) => {
    switch (key) {
      case 'ignore':
        ignoreRecurringPayments()
        break
      default:
        console.warn(`${key} button not handled`)
    }
  }, [ignoreRecurringPayments])

  const loading = useMemo(() => (ignoreLoading), [ignoreLoading])

  return (
    <div className='flex flex-row items-center justify-end w-full space-x-1'>
      <Dropdown
        disabled={loading}
        placement='bottomRight'
        menu={{
          onClick: handleMenuClick,
          items: [
            {
              key: 'ignore',
              label: (
                <Tooltip
                  title={t('component.recurringPaymentActions.tooltip.ignored')}
                  placement='left'
                  className='flex items-center'
                >
                  {(recurringPayment.ignored && <EyeIcon className='w-4 h-4 mr-2 inline' />) || <EyeSlashIcon className='w-4 h-4 mr-2 inline' />}
                  {recurringPayment.ignored ? t('shared.unIgnore') : t('shared.ignore')}
                </Tooltip>
              )
            }
          ]
        }}
      >
        <ActionsIcon loading={loading} />
      </Dropdown>
    </div>
  )
}
