import quipuBanksInfos from 'utils/quipu-banks-infos.json'
import powensBanksInfos from 'utils/powens-banks-infos.json'

export function getBankConnectionFaviconUrl (bankConnection) {
  if (bankConnection.externalSource === 'QUIPU') {
    const bankName = bankConnection?.bankName
    return quipuBanksInfos[bankName]?.faviconUrl
  } else if (bankConnection.externalSource === 'POWENS') {
    const externalBankId = bankConnection?.externalBankId
    return powensBanksInfos[externalBankId]?.faviconUrl
  }

  return null
}
