import React from 'react'
import PropTypes from 'prop-types'
import { ResponsiveContainer, AreaChart, Area, ReferenceLine, Tooltip } from 'recharts'
import { get } from 'lodash'

import theme from 'theme'
import SimpleChartTooltip from './SimpleChartTooltip'

export default function SimpleChart ({ data, dataKey, color, limit, limitLabel }) {
  if (get(data, 'length', 0) === 0) return null

  return (
    <ResponsiveContainer>
      <AreaChart data={data}>
        <Tooltip
          content={({ payload }) => (
            <SimpleChartTooltip
              dataKey={dataKey}
              params={get(payload, '0')}
              limitLabel={limitLabel}
              limit={limit}
            />
          )}
          wrapperStyle={{ zIndex: 10 }}
        />

        <defs>
          <linearGradient id={`${color}-gradient`} x1='0' y1='0' x2='0' y2='1'>
            <stop offset='0%' stopColor={theme.colors[color]} stopOpacity={0.2} />
            <stop offset='100%' stopColor={theme.colors[color]} stopOpacity={0} />
          </linearGradient>
        </defs>

        <Area type='monotone' dataKey={dataKey} stroke={theme.colors[color]} strokeWidth={2} fillOpacity={1} fill={`url(#${color}-gradient)`} />

        {!!limit && (
          <ReferenceLine y={limit} stroke={theme.colors.warning} strokeWidth={2} />
        )}
      </AreaChart>
    </ResponsiveContainer>
  )
}

SimpleChart.defaultProps = {
  color: 'cashflow',
  dataKey: 'value'
}

SimpleChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  dataKey: PropTypes.string,
  color: PropTypes.oneOf(['cashflow', 'cashout', 'cashin']),
  limitLabel: PropTypes.string,
  limit: PropTypes.number
}
