
import PropTypes from 'prop-types'
import React from 'react'

import { ArrowPathIcon, CheckIcon, ExclamationTriangleIcon, LinkIcon } from '@heroicons/react/24/solid'
import ConnectionEvent from 'components/connectors/ConnectionEvent'
import { Trans, useTranslation } from 'react-i18next'

const ConnectionEventTimeline = ({ connection }) => {
  const { t } = useTranslation()

  if (!connection || (!connection.lastSyncingAt && !connection.firstSyncingStartAt && !connection.firstSyncingEndAt)) return null

  return (
    <div className='flow-root'>
      <ul className='-mb-8'>
        {connection.status === 'DISCONNECTED' && (
          <ConnectionEvent
            icon={ExclamationTriangleIcon}
            iconType='warning'
          >
            {t('component.connectionEventTimeline.interruptedConnection')}
          </ConnectionEvent>
        )}

        {connection.lastSyncingAt && (
          <ConnectionEvent
            icon={ArrowPathIcon}
            iconType='info'
            eventAt={connection.lastSyncingAt}
          >
            <Trans
              i18nKey='component.connectionEventTimeline.lastSyncing'
              components={{
                Span: <span className='font-medium text-base-color' />
              }}
            />

          </ConnectionEvent>
        )}

        {!connection.firstSyncingEndAt && (
          <ConnectionEvent
            icon={CheckIcon}
            iconType='success'
            eventAt={connection?.firstSyncingEndAt}
          >
            <Trans
              i18nKey='component.connectionEventTimeline.endOfFirstSync'
              components={{
                Span: <span className='font-medium text-base-color' />
              }}
            />
          </ConnectionEvent>
        )}

        {connection.firstSyncingStartAt && (
          <ConnectionEvent
            icon={LinkIcon}
            eventAt={connection?.firstSyncingStartAt}
          >
            <Trans
              i18nKey='component.connectionEventTimeline.startOfFirstSync'
              components={{
                Span: <span className='font-medium text-base-color' />
              }}
            />
          </ConnectionEvent>
        )}
      </ul>
    </div>
  )
}

ConnectionEventTimeline.propTypes = {
  connection: PropTypes.object
}

export default ConnectionEventTimeline
