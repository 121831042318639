import { useLazyQuery } from '@apollo/client'
import InvoiceExternalSource from 'enums/InvoiceExternalSource.js'
import { GET_SELLSY_INVOICE_DOCUMENT_URL, GET_SELLSY_ORDER_DOCUMENT_URL, GET_SELLSY_QUOTE_DOCUMENT_URL } from 'graphql/sellsy.js'
import { isEmpty } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { message } from 'antd'

import { useTranslation } from 'react-i18next'
import { GET_SECURED_QUIPU_INVOICE_DOCUMENT_URL, GET_SECURED_QUIPU_QUOTE_DOCUMENT_URL } from 'graphql/quipu.js'

export default ({ accountingDocument, onShowDocument }) => {
  const { t } = useTranslation()
  const hasDocument = useMemo(() => (accountingDocument.externalSource === InvoiceExternalSource.SELLSY || accountingDocument.documentUrl), [accountingDocument])

  const isSellsyQuote = useMemo(() => (accountingDocument.externalSource === InvoiceExternalSource.SELLSY && accountingDocument.type === 'QUOTE'), [accountingDocument])
  const isSellsyInvoice = useMemo(() => (accountingDocument.externalSource === InvoiceExternalSource.SELLSY && accountingDocument.type === 'INVOICE'), [accountingDocument])
  const isSellsyOrder = useMemo(() => (accountingDocument.externalSource === InvoiceExternalSource.SELLSY && accountingDocument.type === 'ORDER'), [accountingDocument])

  const isQuipuInvoice = useMemo(() => (accountingDocument.externalSource === InvoiceExternalSource.QUIPU && accountingDocument.type === 'INVOICE'), [accountingDocument])
  const isQuipuQuote = useMemo(() => (accountingDocument.externalSource === InvoiceExternalSource.QUIPU && accountingDocument.type === 'QUOTE'), [accountingDocument])

  const [documentUrl, setDocumentUrl] = useState(accountingDocument.documentUrl)
  const [fetchSellsyQuoteDocumentUrl, { loading: sellsyQuoteUrlLoading }] = useLazyQuery(GET_SELLSY_QUOTE_DOCUMENT_URL, {
    variables: { quoteId: accountingDocument.id },
    onCompleted: (result) => {
      if (!isEmpty(result.getSellsyQuoteDocumentUrl)) {
        setDocumentUrl(result.getSellsyQuoteDocumentUrl)
        onShowDocument()
      } else {
        message.warning(t('component.quoteDocumentPreview.notFound'), 10)
      }
    }
  })

  const [fetchSellsyInvoiceDocumentUrl, { loading: sellsyInvoiceUrlLoading }] = useLazyQuery(GET_SELLSY_INVOICE_DOCUMENT_URL, {
    variables: { invoiceId: accountingDocument.id },
    onCompleted: (result) => {
      if (!isEmpty(result.getSellsyInvoiceDocumentUrl)) {
        setDocumentUrl(result.getSellsyInvoiceDocumentUrl)
        onShowDocument()
      } else {
        message.warning(t('component.invoiceDocumentPreview.notFound'), 10)
      }
    }
  })

  const [fetchSellsyOrderDocumentUrl, { loading: sellsyOrderUrlLoading }] = useLazyQuery(GET_SELLSY_ORDER_DOCUMENT_URL, {
    variables: { orderId: accountingDocument.id },
    onCompleted: (result) => {
      if (!isEmpty(result.getSellsyOrderDocumentUrl)) {
        setDocumentUrl(result.getSellsyOrderDocumentUrl)
        onShowDocument()
      } else {
        message.warning(t('component.orderDocumentPreview.notFound'), 10)
      }
    }
  })

  const [fetchSecuredQuipuInvoiceDocumentUrl, { loading: quipuUrlLoading }] = useLazyQuery(GET_SECURED_QUIPU_INVOICE_DOCUMENT_URL, {
    variables: { invoiceId: accountingDocument.id },
    onCompleted: (result) => {
      if (!isEmpty(result.getSecuredQuipuInvoiceDocumentUrl)) {
        setDocumentUrl(result.getSecuredQuipuInvoiceDocumentUrl)
        onShowDocument()
      } else {
        message.warning(t('component.invoiceDocumentPreview.notFound'), 10)
      }
    }
  })

  const [fetchSecuredQuipuQuoteDocumentUrl] = useLazyQuery(GET_SECURED_QUIPU_QUOTE_DOCUMENT_URL, {
    variables: { quoteId: accountingDocument.id },
    onCompleted: (result) => {
      if (!isEmpty(result.getSecuredQuipuQuoteDocumentUrl)) {
        setDocumentUrl(result.getSecuredQuipuQuoteDocumentUrl)
        onShowDocument()
      } else {
        message.warning(t('component.quoteDocumentPreview.notFound'), 10)
      }
    }
  })

  const showDocument = useCallback(() => {
    if (isSellsyQuote) {
      fetchSellsyQuoteDocumentUrl()
    } else if (isSellsyInvoice) {
      fetchSellsyInvoiceDocumentUrl()
    } else if (isSellsyOrder) {
      fetchSellsyOrderDocumentUrl()
    } else if (isQuipuInvoice) {
      fetchSecuredQuipuInvoiceDocumentUrl()
    } else if (isQuipuQuote) {
      fetchSecuredQuipuQuoteDocumentUrl()
    } else {
      onShowDocument()
    }
  }, [fetchSellsyQuoteDocumentUrl, fetchSellsyInvoiceDocumentUrl,
    onShowDocument, fetchSecuredQuipuInvoiceDocumentUrl, fetchSecuredQuipuQuoteDocumentUrl,
    fetchSellsyOrderDocumentUrl, isSellsyQuote, isQuipuInvoice, isSellsyInvoice, isQuipuQuote, isSellsyOrder])

  const loading = sellsyQuoteUrlLoading || sellsyInvoiceUrlLoading || quipuUrlLoading || sellsyOrderUrlLoading

  return {
    hasDocument,
    documentUrl,
    showDocument,
    loading,
    title: accountingDocument.invoiceNumber ?? accountingDocument.quoteNumber ?? accountingDocument.orderNumber ?? accountingDocument.documentNumber
  }
}
