import { Button } from 'antd'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ExpectedTransactionDetailCategorySelect from 'components/expected-transaction-details/ExpectedTransactionDetailCategorySelect'

const ExpectedTransactionTableCategorySelectShortcut = ({ expectedTransaction, onExpand }) => {
  const { t } = useTranslation()
  const singleExpectedTransactionDetail = useMemo(() => {
    if (get(expectedTransaction, 'expectedTransactionDetails.length', 0) > 1) return null

    return get(expectedTransaction, 'expectedTransactionDetails.0')
  }, [expectedTransaction])

  if (singleExpectedTransactionDetail) {
    return (
      <ExpectedTransactionDetailCategorySelect expectedTransactionDetail={singleExpectedTransactionDetail} />
    )
  }

  return (
    <Button
      type='link'
      onClick={onExpand}
      block
      className='text-xs'
      size='small'
    >
      {t('component.expectedTransactionTableCategorySelectShortcut.multipleCategories')}
    </Button>
  )
}

ExpectedTransactionTableCategorySelectShortcut.propTypes = {
  expectedTransaction: PropTypes.object,
  onExpand: PropTypes.func
}

export default ExpectedTransactionTableCategorySelectShortcut
