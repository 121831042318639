import React, { useMemo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { getStatus } from 'redux-resource'
import { PlusOutlined } from '@ant-design/icons'
import { Row, Col, Button } from 'antd'
import { get } from 'lodash'
import { generate } from '@ant-design/colors'
import resourcesActions from 'actions/ResourcesActions'

import { useTranslation } from 'react-i18next'

import CategoryTag from './CategoryTag'
import RowCategoryBorder from './RowCategoryBorder'
import NewCategoryLine from './NewCategoryLine'
import CategoryForm from './CategoryForm'
import CategoryLineActions from './CategoryLineActions'
import { adaptError } from 'utils/legacy-forms.js'

const CategoryLine = ({ type, id }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const category = useSelector(state => get(state, `categories.resources.${id}`))
  const parentId = get(category, 'parentId')
  const parentCategory = useSelector(state => parentId ? get(state, `categories.resources.${parentId}`) : null)
  const updateCategoryStatus = useSelector(state => getStatus(state, `categories.requests.updateCategory-${id}.status`))
  const updateCategoryErrors = useSelector(state => get(state, `categories.requests.updateCategory-${id}.errors`))

  const adaptedErrors = adaptError({ errors: updateCategoryErrors })

  const colorPalette = useMemo(() => {
    const parentCategoryColor = get(parentCategory, 'color')
    return parentCategoryColor ? generate(parentCategoryColor) : null
  }, [parentCategory])

  const [showSubCatForm, setShowSubCatForm] = useState(false)

  const addSubCategory = useCallback(() => setShowSubCatForm(true), [setShowSubCatForm])
  const hideSubCategoryForm = useCallback(() => setShowSubCatForm(false), [setShowSubCatForm])

  const updateCategory = useCallback((values, resetChanges) => {
    dispatch(resourcesActions.UPDATE_CATEGORY(id, values, resetChanges))
  }, [dispatch, id])

  const initialValues = { name: category.name, color: category.color, vatRate: category.vatRate }

  if (!category) return null

  return (
    <div>
      <Row gutter={20} className='rc-category-line'>
        <RowCategoryBorder categoryId={category?.id} style={{ bottom: 0 }} />

        <Col xs={6}>
          <CategoryTag category={category} />
        </Col>

        <Col xs={18} className='flex justify-between items-center'>
          <CategoryForm
            edit
            submit={updateCategory}
            submitting={updateCategoryStatus.pending}
            initialValues={initialValues}
            colorPalette={colorPalette}
            errors={adaptedErrors}
          />

          <div className='flex flex-row'>
            <Button
              type='dashed'
              icon={<PlusOutlined />}
              style={{ marginRight: 10 }}
              onClick={addSubCategory}
            >
              {t('component.categoryLine.subCategory')}
            </Button>

            <CategoryLineActions type={type} category={category} />
          </div>
        </Col>
      </Row>

      {showSubCatForm && (
        <div style={{ marginLeft: 20 }}>
          <NewCategoryLine
            type={type}
            parentCategory={category}
            hideSubCategoryForm={hideSubCategoryForm}
          />
        </div>
      )}
    </div>
  )
}

CategoryLine.propTypes = {
  type: PropTypes.oneOf(['cashin', 'cashout']),
  id: PropTypes.string
}

export default CategoryLine
