import { gql } from '@apollo/client'

export const UPDATE_VAT_SETTINGS = gql`
  mutation updateVatSettings($input: UpdateVatSettingsInput!) {
    updateVatSettings(input: $input) {
      id
      vatDateRule
      vatPeriod
    }
  }
`

export const UPDATE_COMPANY_INFOS = gql`
  mutation updateCompanyInfos($input: UpdateCompanyInfosInput!) {
    updateCompanyInfos(input: $input) {
      id
      businessType
      numberOfEmployees
    }
  }
`

export const END_ONBOARDING = gql`
  mutation endOnboarding {
    endOnboarding {
      id
      onboardingEndAt
    }
  }
`

export const SET_WCR = gql`
  mutation setWcr($wcr: Float) {
    setWcr(wcr: $wcr) {
      id
      wcr
    }
  }
`

export const UPDATE_HIDDEN_ACCOUNT_IDS = gql`
  mutation updateHiddenAccountIds($hiddenAccountIds: [String]!) {
    updateHiddenAccountIds(hiddenAccountIds: $hiddenAccountIds) {
      id
      hiddenAccountIds
    }
  }
`
