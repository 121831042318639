import React, { useState, useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
// import { get } from 'lodash'

import DraggableCategory from './DraggableCategory'
import CategorySortService from 'services/CategorySortService'
import CategoryDndContext from './CategoryDndContext'
import NewCategoryLine from './NewCategoryLine'

const DropableCategoryListContainer = ({ type, categoryTree, updateCategoryPosition, ...rest }) => {
  const sorter = useMemo(() => {
    return new CategorySortService({ categoryTree })
  }, [categoryTree])

  const contextFromSorter = useCallback(() => {
    return {
      moveCategory: (params) => {
        sorter.moveCategory(params)
        setDndContext(contextFromSorter())
      },
      updateCategoryPosition,
      categoriesByParentId: sorter.categoriesByParentId,
      globalIndex: sorter.globalIndex,
      parentIdsByCategoryId: sorter.parentIdsByCategoryId,
      type
    }
  }, [sorter, type, updateCategoryPosition])

  const [dndContext, setDndContext] = useState(contextFromSorter())

  useEffect(() => {
    setDndContext(contextFromSorter())
  }, [sorter, contextFromSorter])

  return (
    <CategoryDndContext.Provider value={dndContext}>
      <div className='flex flex-col'>
        <NewCategoryLine type={type} />

        <div className='rc-show-scrollbar'>
          {dndContext.globalIndex.map((catId) => (
            <DraggableCategory key={catId} catId={catId} />
          ))}
        </div>
      </div>
    </CategoryDndContext.Provider>
  )
}

DropableCategoryListContainer.propTypes = {
  type: PropTypes.oneOf(['cashin', 'cashout']),
  categoryTree: PropTypes.arrayOf(PropTypes.object),
  updateCategoryPosition: PropTypes.func
}

export default DropableCategoryListContainer
