import { useQuery } from '@apollo/client'
import { LIST_CONNECTIONS } from 'graphql/connections.js'
import { get, keyBy } from 'lodash'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

export default function useConnections () {
  const isAuthenticated = useSelector(state => get(state, 'app.isAuthenticated'))

  const { data, loading } = useQuery(LIST_CONNECTIONS, {
    fetchPolicy: 'cache-first',
    skip: !isAuthenticated
  })

  const connections = useMemo(() => {
    return get(data, 'connections', [])
  }, [data])

  const connectionBySource = useMemo(() => {
    return keyBy(connections, (c) => `${c.externalSource.toLowerCase()}Connection`)
  }, [connections])

  const powensConnection = connectionBySource.powensConnection

  useEffect(() => {
    if (window.Intercom && powensConnection?.config?.userId) {
      try {
        window.Intercom('update', { 'RC - Budget Insight User Id': powensConnection?.config?.userId })
      } catch (error) {
        console.error(error)
      }
    }
  }, [powensConnection])

  return { connections, ...connectionBySource, loading }
}
