import { Modal } from 'antd'
import { get } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { LIST_SIMILAR_UNCATEGORIZED_TRANSACTIONS } from 'graphql/transactions'

import { useLazyQuery } from '@apollo/client'
import CategoryTag from 'components/categories/CategoryTag'
import useUpdateMultipleTransactions from 'hooks/useUpdateMultipleTransactions'

const getVatRateInPercent = (vatRate) => {
  const value = get(vatRate, 'value', 0) * 100
  return `${value} %`
}

const getTypeFilterParams = (type) => {
  switch (type) {
    case 'category':
      return { categoryIds: ['null'] }
    case 'vatRate':
      return { vatRates: [null] }
    default:
      throw new Error('Type is not valid.')
  }
}

const getUpdateProperty = (type, typeValue) => {
  switch (type) {
    case 'category':
      return { categoryId: typeValue }
    case 'vatRate':
      return { vatRate: typeValue }
    default:
      throw new Error('Type is not valid.')
  }
}

export default ({ type }) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [categorizationType, setCategorizationType] = useState(null)
  const [categorizationTypeId, setCategorizationTypeId] = useState(null)
  const [filteredSearch, setFilteredSearch] = useState('')
  const [transactionIds, setTransactionIds] = useState([])
  const category = useSelector(state => get(state, `categories.resources.${categorizationTypeId}`))
  const vatRate = useSelector(state => get(state, `vatRates.resources.${categorizationTypeId}`))

  const onListComplete = useCallback((data) => {
    const transactionIds = data.allTransactions.transactions.map(({ id }) => id)
    const filteredSearch = data.allTransactions.filteredSearch

    setTransactionIds(transactionIds)
    setFilteredSearch(filteredSearch)

    if (get(transactionIds, 'length', 0) > 0 && filteredSearch) setVisible(true)
  }, [setVisible, setTransactionIds, setFilteredSearch])

  const [listSimilarUncategorizedTransactions] = useLazyQuery(LIST_SIMILAR_UNCATEGORIZED_TRANSACTIONS, { onCompleted: onListComplete })

  const triggerHelper = useCallback(({ categorizationType, categorizationTypeId, description }) => {
    setCategorizationType(categorizationType)
    setCategorizationTypeId(categorizationTypeId)
    listSimilarUncategorizedTransactions({ variables: { type, search: description, ...getTypeFilterParams(categorizationType) } })
  }, [type, setCategorizationType, setCategorizationTypeId, listSimilarUncategorizedTransactions])

  const onUpdateMultipleComplete = useCallback(() => {
    setLoading(false)
    setVisible(false)
  }, [setVisible, setLoading])

  const [batchUpdateTransactionsMutation] = useUpdateMultipleTransactions({ onSuccessCallback: onUpdateMultipleComplete })

  const onOk = useCallback(() => {
    setLoading(true)
    batchUpdateTransactionsMutation({
      variables: {
        ids: transactionIds,
        properties: getUpdateProperty(categorizationType, categorizationTypeId)
      }
    })
  }, [batchUpdateTransactionsMutation, transactionIds, categorizationType, categorizationTypeId, setLoading])

  const modal = useMemo(() => {
    return (
      <Modal
        open={visible}
        closable={false}
        onClose={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        onOk={onOk}
        cancelText={t('shared.no')}
        okText={t('shared.yes')}
        confirmLoading={loading}
        centered
      >
        <div className='text-lg'>
          <Trans
            i18nKey='hook.useCategorizationHelper.transactionsWithSimilarLabel'
            components={{ Span: <span className='text-lg font-bold text-success' /> }}
            values={{ transactionIdsLength: transactionIds.length }}
          />

        </div>

        <div className='text-lg text-black text-opacity-50 mt-4'>
          {filteredSearch.toUpperCase()}
        </div>

        {category && (
          <div className='text-lg mt-4 flex items-center'>
            <Trans
              i18nKey='hook.useCategorizationHelper.doYouWantToAssignCategoryX'
              components={{ CategoryTag: <CategoryTag category={category} className='ml-2' /> }}
            />

          </div>
        )}

        {vatRate && (
          <div className='text-lg mt-4'>
            {t('hook.useCategorizationHelper.doYouWantToAssignVatRate', { vatRateInPercent: getVatRateInPercent(vatRate) })}
          </div>
        )}
      </Modal>
    )
  }, [visible, onOk, t, loading, transactionIds.length, filteredSearch, category, vatRate])

  return {
    modal,
    loading,
    triggerHelper
  }
}
