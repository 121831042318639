import React, { useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'
import Badge from 'design/Badge'

import { get, isNumber } from 'lodash'
import useAuthenticatedContext from 'hooks/useAuthenticatedContext.js'

import { Trans, useTranslation } from 'react-i18next'
import { ScenariosContext } from 'contexts/ScenariosContext.js'

export default function useSubscriptionScenarioLimit () {
  const { data: scenariosData } = useContext(ScenariosContext)

  const scenariosCount = useMemo(() => {
    return get(scenariosData, 'listScenarios', []).length
  }, [scenariosData])

  const { subscription } = useAuthenticatedContext()
  const { t } = useTranslation()

  const hasScenarioLimitAttempted = useMemo(() => {
    return (
      isNumber(scenariosCount) &&
      isNumber(subscription?.includedScenariosQuantity) &&
      scenariosCount >= subscription?.includedScenariosQuantity
    )
  }, [subscription, scenariosCount])

  const tooltipTitle = (
    <div>
      {hasScenarioLimitAttempted && (
        <Link to='/subscribe?r=sc'>
          <div>
            <span className='text-white'>
              <Trans
                i18nKey='hook.useSubscriptionScenarioLimit.toolTip.title'
                components={{
                  Badge:
  <Badge type='info' label={t(`utils.plans.planLocales.${subscription?.plan}`)} className='text-xs' />
                }}
              />
            </span>
            <span className='text-primary hover:underline ml-1'>{t('hook.useSubscriptionScenarioLimit.toolTip.seePackages')}</span>
          </div>
        </Link>
      )}
    </div>
  )

  return { hasScenarioLimitAttempted, tooltipTitle }
}
