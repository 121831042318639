import React, { useEffect, useContext, useMemo } from 'react'

import OnboardingContext from 'components/onboarding/OnboardingContext'
import OnboardingHeader from 'components/onboarding/OnboardingHeader'
import OnboardingFooter from 'components/onboarding/OnboardingFooter'
import useAuthenticatedContext from 'hooks/useAuthenticatedContext'
import useIsConnectingOrConnected from 'hooks/useIsConnectingOrConnected'
import { useTranslation, Trans } from 'react-i18next'
import useLocale from 'hooks/useLocale.js'
import Locale from 'enums/Locale.js'
import useProductName from 'hooks/useProductName.js'
import useFreeTrialEnd from 'hooks/subscriptions/useFreeTrialEnd.js'

export default () => {
  const { t } = useTranslation()
  const { company: { signUpSource }, user: { firstName }, subscription } = useAuthenticatedContext()
  const isConnectingOrConnected = useIsConnectingOrConnected()
  const context = useContext(OnboardingContext)

  const { formattedFreeTrialEndAt } = useFreeTrialEnd()

  useEffect(() => {
    if (process.env.REACT_APP_TENANT !== 'QUIPU') {
      if (isConnectingOrConnected) context.moveToNextStep()
    }
  }, [isConnectingOrConnected, context])

  const locale = useLocale()
  const productName = useProductName()

  const dashboardImageUrl = useMemo(() => {
    if (locale === Locale.fr_FR) return '/image-step-0-onboarding-fr.png'
    if (locale === Locale.es_ES) return '/image-step-0-onboarding-es.png'
    return '/image-step-0-onboarding-en.png'
  }, [locale])

  return (
    <div className='rc-onboarding-screen-container mt-20'>
      <OnboardingHeader title={t('onboarding.onboardingWelcomeScreen.welcomeFirstName', { firstName, productName })} />

      {(signUpSource === 'SELLSY' && !subscription && (
        <div className='flex flex-row items-start space-x-8 mt-8 mb-4'>
          <img src={dashboardImageUrl} alt='Sellsy Trésorerie dashboard' className='w-1/2 -ml-40' />

          <div className='text-lg flex flex-col space-y-4'>
            <div>
              <Trans
                i18nKey='onboarding.onboardingWelcomeScreen.rocketChartIsASellsyModule'
                components={{ Span: <span className='font-bold' /> }}
                values={{ formattedFreeTrialEndAt }}
              />

            </div>

            <div><span className='font-bold'>{t('onboarding.onboardingWelcomeScreen.youWontBeChargedAutomatically')}</span> {t('onboarding.onboardingWelcomeScreen.afterTrialSubscription')}</div>

            <div>{t('onboarding.onboardingWelcomeScreen.rocketChartPitch', { productName })}</div>
          </div>
        </div>
      )) || (
        <>
          <div className='text-lg'>{t('onboarding.onboardingWelcomeScreen.rocketChartPitch', { productName })}</div>

          <img src={dashboardImageUrl} alt='Dashboard' className='w-full px-16 mt-4' />
        </>
      )}

      <OnboardingFooter next nextLabel={t('onboarding.onboardingWelcomeScreen.start')} />
    </div>
  )
}
