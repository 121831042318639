import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import cx from 'classnames'

import authActions from 'actions/AuthActions'
import BankAccountSettings from './BankAccountSettings'
import { Popover } from '@headlessui/react'
import { TagIcon, LifebuoyIcon, LinkIcon, ArrowRightOnRectangleIcon, BookOpenIcon, CalculatorIcon, UserGroupIcon } from '@heroicons/react/24/outline'
import useAuthenticatedContext from 'hooks/useAuthenticatedContext'
import { useTranslation } from 'react-i18next'
import SettingsEmail from './SettingsEmail.js'
import Badge from 'design/Badge.js'
import featureConfig from 'config/features.js'
import { useLazyQuery } from '@apollo/client'
import { GET_STRIPE_BILLING_PORTAL_URL } from 'graphql/billing.js'

const buttonClassNames = 'w-full flex flex-row items-center px-4 py-2 hover:text-primary text-base-color'
const iconClassNames = 'h-4 w-4 mr-2'

const SettingsOverlay = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const { company, user, subscription } = useAuthenticatedContext()

  const [getStripeBillingPortalUrl] = useLazyQuery(GET_STRIPE_BILLING_PORTAL_URL, {
    onCompleted: (data) => {
      window.location.replace(data.getStripeBillingPortalUrl)
    }
  })
  const planBadge = subscription?.plan
    ? <Badge type='info' label={t(`utils.plans.planLocales.${subscription?.plan}`)} className='text-xs ml-2' />
    : <Badge type='disabled' label={t('component.settingsOverlay.trial')} className='text-xs ml-2' />

  return (
    <div className='rc-settings border rounded-md'>
      <BankAccountSettings />

      <div className='relative mt-5 mb-1'>
        <div className='absolute inset-0 flex items-center' aria-hidden='true'>
          <div className='w-full border-t border-gray-200' />
        </div>
        <div className='relative flex justify-center'>
          <div className='px-2 bg-white text-sm text-gray-500'>
            {t('component.settingsOverlay.settingsCompany', { name: company?.name })}
            {featureConfig.displayPlanEnabled && subscription?.plan && (
              planBadge
            )}
          </div>
        </div>
      </div>

      <div className='flex flex-col'>
        {featureConfig.manageIntegrationsEnabled && (
          <Popover.Button className='outline-none'>
            <div onClick={() => { history.push('/integrations') }} className={buttonClassNames}>
              <LinkIcon className={iconClassNames} /> {t('component.settingsOverlay.manageIntegrations')}
            </div>
          </Popover.Button>
        )}

        <Popover.Button className='outline-none'>
          <div onClick={() => { history.push('/categories') }} className={buttonClassNames}>
            <TagIcon className={iconClassNames} /> {t('component.settingsOverlay.manageCategories')}
          </div>
        </Popover.Button>

        {user?.role === 'admin' && (
          <Popover.Button className='outline-none'>
            <div onClick={() => { history.push('/users') }} className={buttonClassNames}>
              <UserGroupIcon className={iconClassNames} /> {t('component.settingsOverlay.manageUsers')}
            </div>
          </Popover.Button>
        )}

        {user?.role === 'admin' && company?.subscriptionType === 'DIRECT' && subscription?.id && (
          <Popover.Button className='outline-none'>
            <div onClick={getStripeBillingPortalUrl} className={buttonClassNames}>
              <CalculatorIcon className={iconClassNames} /> {t('component.settingsOverlay.billing')}
            </div>
          </Popover.Button>
        )}

        {(process.env.REACT_APP_TENANT === 'DEFAULT') && (
          <Popover.Button className='outline-none'>
            <a href={process.env.REACT_APP_PRODUCT_TOUR_URL} target='_blank' className={buttonClassNames} rel='noopener noreferrer'>
              <BookOpenIcon className={iconClassNames} /> {t('component.settingsOverlay.seeProductTourAgain')}
            </a>
          </Popover.Button>
        )}

        {featureConfig.helpCenterEnabled && (
          <Popover.Button className='outline-none'>
            <a href={process.env.REACT_APP_HELP_CENTER_URL} target='_blank' className={buttonClassNames} rel='noopener noreferrer'>
              <LifebuoyIcon className={iconClassNames} /> {t('component.settingsOverlay.contactSupport')}
            </a>
          </Popover.Button>
        )}

        <Popover.Button as='div' className='flex flex-row justify-between items-center outline-none'>
          {(featureConfig.logoutEnabled && (
            <div onClick={() => { dispatch(authActions.signOut()) }} className={cx(buttonClassNames, 'cursor-pointer justify-between mb-1')}>
              <div className='flex flex-row items-center'><ArrowRightOnRectangleIcon className={iconClassNames} /> {t('component.settingsOverlay.logOut')}</div>
            </div>
          )) || (
            <div />
          )}

          <div className={cx('pr-3', { 'mb-3': !featureConfig.logoutEnabled })}>
            <SettingsEmail />
          </div>
        </Popover.Button>
      </div>
    </div>
  )
}

export default SettingsOverlay
