import { Alert } from 'antd'
import { get } from 'lodash'
import React from 'react'

import { XMarkIcon } from '@heroicons/react/24/solid'
import Button from 'design/Button'
import useAuthenticatedContext from 'hooks/useAuthenticatedContext'
import { useTranslation } from 'react-i18next'

export default function DemoBanner () {
  const { t } = useTranslation()
  const { user } = useAuthenticatedContext()
  const email = get(user, 'email')

  if (email !== 'demo@rocketchart.co') return null

  return (
    <Alert
      type='info'
      message={null}
      description={(
        <div className='font-bold text-primary text-center'>
          {t('component.demoBanner.youAreOnADemoAccount')}
          <Button
            onClick={() => {
              window.open('https://app.rocketchart.co/dashboard', '_self')
            }}
            label={t('component.demoBanner.goBackToMyAccount')} className='ml-8'
          />
        </div>
      )}
      banner
      closable
      showIcon={false}
      closeText={<XMarkIcon className='w-6 h-6 self-center mt-2' />}
    />
  )
}
