import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import Button from 'design/Button'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'

CashflowSheetDrawerMonthNavigator.propTypes = {
  sheetDate: PropTypes.object,
  sheetDates: PropTypes.arrayOf(PropTypes.object),
  drawerParams: PropTypes.shape({
    visible: PropTypes.bool,
    rowAbsolutePath: PropTypes.string,
    valueIndex: PropTypes.number
  }),
  setDrawerParams: PropTypes.func
}

export default function CashflowSheetDrawerMonthNavigator ({ sheetDate, sheetDates, drawerParams, setDrawerParams }) {
  const maxIndex = useMemo(() => sheetDates.length - 1, [sheetDates])

  const canNavPrev = useMemo(() => drawerParams.valueIndex > 0, [drawerParams.valueIndex])
  const canNavNext = useMemo(() => drawerParams.valueIndex < maxIndex, [maxIndex, drawerParams.valueIndex])

  const navPrev = useCallback(() => { setDrawerParams({ ...drawerParams, valueIndex: drawerParams.valueIndex - 1 }) }, [setDrawerParams, drawerParams])
  const navNext = useCallback(() => { setDrawerParams({ ...drawerParams, valueIndex: drawerParams.valueIndex + 1 }) }, [setDrawerParams, drawerParams])

  return (
    <div className='flex flex-row items-center justify-center space-x-2'>
      <Button onClick={navPrev} disabled={!canNavPrev} label={<ChevronLeftIcon className='w-4 h-4' />} />
      <div className='text-sm font-bold w-32 text-center'>{sheetDate.momentDate.format('MMMM YYYY')}</div>
      <Button onClick={navNext} disabled={!canNavNext} label={<ChevronRightIcon className='w-4 h-4' />} />
    </div>
  )
}
