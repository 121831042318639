import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

InfoBox.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.string
}

export default function InfoBox ({ children, className, type, ...rest }) {
  return (
    <div
      className={cx(
        'rounded-md bg-white bg-opacity-25 border overflow-hidden sm:rounded-lg sm:shadow',
        className,
        {
          'border-green-800 bg-green-100': (!type || type === 'success'),
          'border-error bg-red-100': (type === 'error'),
          'border-yellow-800 bg-yellow-100': (type === 'warning'),
          'border-primary bg-ultra-light-primary': (type === 'primary'),
          'border-blue-800 bg-blue-100': (type === 'info'),
          'border-gray-500 bg-gray-100': (type === 'disabled')
        }
      )}
    >
      {children && (
        <div className='p-4 sm:p-6'>
          {children}
        </div>
      )}
    </div>
  )
}
