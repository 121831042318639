import { Card, Table, Typography } from 'antd'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import CashIndicator from 'components/CashIndicator'
import Currency from 'components/Currency'
import CategoryTag from 'components/categories/CategoryTag'
import i18next from 'i18next'
import featureConfig from 'config/features.js'

const { Paragraph, Text } = Typography

const TableHeader = ({ title, children, scenarioEnabled }) => {
  if (scenarioEnabled) {
    return (
      <div>
        {title}
        <br />
        {children}
      </div>
    )
  }

  return children
}

TableHeader.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  scenarioEnabled: PropTypes.bool
}

const generateColumns = (type, month, withVat, scenarioEnabled, data) => {
  const { t } = i18next

  const columns = [
    {
      title: <TableHeader scenarioEnabled={scenarioEnabled}><CashIndicator type={type} /></TableHeader>,
      key: 'name',
      render: (category) => (
        <CategoryTag category={category} style={{ width: '100%' }} />
      )
    },
    {
      title: <TableHeader title={t('component.transactionTypeTooltip.realized')} scenarioEnabled={scenarioEnabled}><Currency amount={get(data, 'total')} /></TableHeader>,
      key: 'real',
      dataIndex: ['forecast', month, withVat === 'true' ? 'real' : 'realWithoutVAT'],
      render: (real, category) => (
        <Currency
          amount={real}
          color={get(category, 'color', '#aaa')}
        />
      )
    }
  ]

  if (scenarioEnabled) {
    columns.push({
      title: <TableHeader title={get(data, 'scenarioName')} scenarioEnabled={scenarioEnabled}><Currency amount={get(data, 'totalPlanned')} /></TableHeader>,
      key: 'plannedCumulated',
      dataIndex: ['forecast', month, 'plannedCumulated'],
      render: (plannedCumulated, category) => (
        <Currency
          amount={plannedCumulated}
          color={get(category, 'color', '#aaa')}
        />
      )
    })
  } else {
    columns.push({
      title: '100%',
      key: 'percentage',
      dataIndex: ['forecast', month, withVat === 'true' ? 'percentage' : 'percentageWithoutVAT'],
      render: (percentage, category) => (
        <Text style={{ color: get(category, 'color', '#aaa') }}>
          {percentage}%
        </Text>
      )
    })
  }

  return columns
}

// const getMonthTotalPath = (withVat) => (
//   withVat === 'true' ? 'totalForAllTransactions' : 'totalForAllTransactionsWithoutVAT'
// )

const TransactionTypeTooltip = ({ type, datetime, data, categories, withVat, scenarioEnabled }) => {
  const title = useMemo(() => {
    const dateText = datetime.format('MMMM YYYY')
    const taxesLabel = !featureConfig.vatDetailsEnabled ? '' : withVat === 'true' ? 'TTC' : 'HT'
    return `${dateText} - ${taxesLabel}`
  }, [datetime, withVat])

  const columns = useMemo(() => {
    const month = datetime.month() + 1
    return generateColumns(type, month, withVat, scenarioEnabled, data)
  }, [type, datetime, withVat, scenarioEnabled, data])

  return (
    <Card>
      <Paragraph strong>{title}</Paragraph>
      <Table
        rowKey='_id'
        size='small'
        dataSource={categories}
        columns={columns}
        pagination={false}
        className='rc-min-table'
      />
    </Card>
  )
}

TransactionTypeTooltip.propTypes = {
  type: PropTypes.oneOf(['cashin', 'cashout']),
  datetime: PropTypes.object,
  data: PropTypes.object,
  categories: PropTypes.arrayOf(PropTypes.object),
  withVat: PropTypes.string,
  scenarioEnabled: PropTypes.bool
}

export default TransactionTypeTooltip
