import { LeftOutlined } from '@ant-design/icons'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Layout, message } from 'antd'
import { CONNECT_OR_RECONNECT_SELLSY, GET_SELLSY_OAUTH_REDIRECTION_URL } from 'graphql/sellsy'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { get } from 'lodash'

import ConnectionEventTimeline from 'components/connectors/ConnectionEventTimeline'
import Button from 'design/Button'
import Card from 'design/Card'
import CardHeader from 'design/CardHeader'
import useSearchParams from 'hooks/useSearchParams'
import useSyncConnection from 'hooks/useSyncConnection'
import { sourceLogos, sourceNames } from 'utils/connections'
import ConnectionBadge from './ConnectionBadge'
import IntegrationSettings from './IntegrationSettings.js'
import AppActions from 'actions/AppActions.js'

const ManageIntegration = ({ source, connection, startPolling }) => {
  const { t } = useTranslation()
  const { onConnect, connecting, saveData, saving, redirecting } = useConnectionFlow({ source, startPolling })
  const actualConnection = useMemo(() => (connection || saveData?.saveConnection), [connection, saveData])
  const { syncConnection, syncing } = useSyncConnection({ connection: actualConnection, startPolling })
  const [connectionDidExists, setConnectionDidExists] = useState(!!connection)
  const dispatch = useDispatch()

  useEffect(() => {
    if (source === 'SELLSY') {
      dispatch(AppActions.setLastSellsyManagementAt(moment().toISOString()))
    }

    if (connectionDidExists && !connection) {
      message.error(t('integrations.manageIntegration.anErrorOccuredPleaseContactSupport'))
      setConnectionDidExists(false)
    }
    if (!connectionDidExists && !!connection) {
      setConnectionDidExists(true)
    }
  }, [connectionDidExists, setConnectionDidExists, connection, dispatch, source, t])

  const headerButtons = useMemo(() => {
    if (!actualConnection) {
      return [
        <Button
          key='connect'
          label={saving ? t('integrations.manageIntegration.connecting') : t('integrations.manageIntegration.connect')}
          loading={redirecting || connecting || saving}
          primary
          onClick={onConnect}
        />
      ]
    }

    if (actualConnection.status === 'DISCONNECTED') {
      return [
        <Button
          key='reconnect'
          label={saving ? t('integrations.manageIntegration.reconnecting') : t('integrations.manageIntegration.reconnect')}
          loading={redirecting || connecting || saving}
          primary
          onClick={onConnect}
        />
      ]
    }

    return [
      <Button
        key='sync'
        label={syncing ? t('integrations.manageIntegration.syncing') : t('integrations.manageIntegration.sync')}
        primary
        loading={syncing}
        onClick={syncConnection}
      />
    ]
  }, [actualConnection, syncing, t, syncConnection, saving, redirecting, connecting, onConnect])

  return (
    <Layout className='rc-content flex flex-col items-center pt-48'>
      <div className='w-full xl:w-2/3 2xl:w-1/2'>
        <div className='mb-2'>
          <Link to='/integrations'>
            <LeftOutlined /> {t('integrations.manageIntegration.backToIntegrations')}
          </Link>
        </div>

        {/* {tokenConnectionModal}
        {customConnectionModal} */}

        <Card
          header={(
            <CardHeader
              imgSrc={sourceLogos[source]}
              title={sourceNames[source]}
              buttons={headerButtons}
              badge={<ConnectionBadge connection={actualConnection} className='ml-2' />}
            />
          )}
        >
          {actualConnection && <ConnectionEventTimeline connection={actualConnection} />}
        </Card>
        <div className='mt-7' />
        {source === 'SELLSY' && actualConnection &&
          <IntegrationSettings className='mt-7' connection={actualConnection} />}
      </div>
    </Layout>
  )
}

const useConnectionFlow = ({ source, startPolling }) => {
  const { t } = useTranslation()
  const [redirecting, setRedirecting] = useState(false)
  // const [modalVisibility, setModalVisibility] = useState(false)
  // const [customModalVisibility, setCustomModalVisibility] = useState(false)
  // const [token, setToken] = useState()
  // const [customParams, setCustomParams] = useState()
  const connectionParams = useConnectionParams({ source })

  const { query, queryOptions } = useMemo(() => {
    if (source === 'SELLSY') {
      return {
        query: GET_SELLSY_OAUTH_REDIRECTION_URL,
        queryOptions: {
          onCompleted: (data) => {
            setRedirecting(true)
            const authUri = data.getSellsyOauthRedirectionUrl
            window.location.assign(authUri)
          }
        }
      }
    }
  }, [source, setRedirecting])

  const [getConnectionAuthUri, { loading: connecting }] = useLazyQuery(query, queryOptions)

  const { mutation } = useMemo(() => {
    if (source === 'SELLSY') {
      return { mutation: CONNECT_OR_RECONNECT_SELLSY }
    }
  }, [source])

  const [saveConnection, { data: saveData, loading: saving }] = useMutation(mutation, {
    onCompleted: startPolling,
    onError: ({ graphQLErrors }) => {
      const errorCode = get(graphQLErrors, '0.extensions.code')

      switch (errorCode) {
        case 'SELLSY_CONNECTION_NOT_PERMITTED':
          message.warning({ content: t('integrations.manageIntegration.sellsyConnectionNotPermitted'), duration: 15 })
          break
        default:
          message.error(t('integrations.manageIntegration.connectionFailed'))
          break
      }
    }
  })

  useEffect(() => {
    if (connectionParams) { saveConnection({ variables: connectionParams }) }
  }, [saveConnection, connectionParams])

  return {
    onConnect: getConnectionAuthUri,
    connecting,
    redirecting,
    saveData,
    saving
    // tokenConnectionModal: useMemo(() => {
    //   if (sourceTypes[source] !== 'token') return null

    //   return (
    //     <TokenConnectionModalContainer
    //       visible={modalVisibility}
    //       source={source}
    //       onCancel={() => {
    //         setRedirecting(false)
    //         setModalVisibility(false)
    //       }}
    //       onConnect={(token) => {
    //         setToken(token)
    //         setRedirecting(false)
    //         setModalVisibility(false)
    //       }}
    //     />
    //   )
    // }, [source, setRedirecting, modalVisibility, setModalVisibility, setToken]),
    // customConnectionModal: useMemo(() => {
    //   if (sourceTypes[source] !== 'custom') return null

    //   return (
    //     <CustomConnectionModalContainer
    //       visible={customModalVisibility}
    //       source={source}
    //       onCancel={() => {
    //         setRedirecting(false)
    //         setCustomModalVisibility(false)
    //       }}
    //       onConnect={(customParams) => {
    //         setCustomParams(customParams)
    //         setRedirecting(false)
    //         setCustomModalVisibility(false)
    //       }}
    //     />
    //   )
    // }, [source, setRedirecting, customModalVisibility, setCustomModalVisibility, setCustomParams])
  }
}

// const useConnectionParams = ({ source, token, customParams }) => {
const useConnectionParams = ({ source }) => {
  const searchParams = useSearchParams()

  return useMemo(() => {
    switch (source) {
      case 'SELLSY': {
        const authorizationCode = searchParams.code
        if (authorizationCode) return { authorizationCode }
        return null
      }
      default:
        throw new Error('Invalid source')
    }
  }, [source, searchParams])
}

ManageIntegration.propTypes = {
  source: PropTypes.string,
  connection: PropTypes.object,
  startPolling: PropTypes.func
}

export default ManageIntegration
