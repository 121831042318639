import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { LIST_EXPECTED_TRANSACTIONS } from 'graphql/expected-transactions'
import moment from 'moment'
import Currency from 'components/Currency'
import CashflowSheetDrawerExpectedTransactionList from './CashflowSheetDrawerExpectedTransactionList'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import AccountingDocumentType from 'enums/AccountingDocumentType.js'

CashflowSheetDrawerPaidExpectedTransactionsPanel.propTypes = {
  paidExpected: PropTypes.number,
  rowType: PropTypes.string,
  sheetDate: PropTypes.object,
  categoryIds: PropTypes.arrayOf(PropTypes.string)
}

export default function CashflowSheetDrawerPaidExpectedTransactionsPanel ({
  paidExpected,
  rowType,
  sheetDate,
  categoryIds
}) {
  const { t } = useTranslation()
  const type = useMemo(() => {
    if (['CASH_IN', 'CASHIN_CATEGORY'].includes(rowType)) return 'cashin'

    return 'cashout'
  }, [rowType])

  const { fromDate, toDate } = useMemo(() => {
    const fromDate = moment(sheetDate.momentDate).startOf('month').format('YYYY-MM-DD')
    const toDate = moment(sheetDate.momentDate).endOf('month').format('YYYY-MM-DD')

    return { fromDate, toDate }
  }, [sheetDate])

  const { data, loading, refetch } = useQuery(LIST_EXPECTED_TRANSACTIONS, {
    variables: {
      type,
      fromDate,
      toDate,
      categoryIds,
      paid: true,
      offset: 0,
      limit: 10,
      orderBy: 'expectedDate',
      parentType: AccountingDocumentType.INVOICE
    }
  })

  const expectedTransactions = useMemo(() => data?.listExpectedTransactions?.expectedTransactions || [], [data])
  const total = useMemo(() => (data?.listExpectedTransactions?.total || 0), [data])

  if (sheetDate?.isFutureMonth) return null

  return (
    <>
      <Disclosure defaultOpen>
        <Disclosure.Button>
          {({ open }) => (
            <div className='w-full flex flex-row items-center space-x-2'>
              {(open && (
                <ChevronDownIcon className='w-6 h-6 text-gray-400' />
              )) || (
                <ChevronRightIcon className='w-6 h-6 text-gray-400' />
              )}
              <span>{t('dashboard.cashflowSheetDrawerTransactionList.paidExpectedTransactions', { total })}</span>
              <span className='text-gray-400'>/</span>
              <Currency amount={paidExpected} className='font-bold' />
            </div>
          )}

        </Disclosure.Button>

        <Disclosure.Panel className='overflow-y-scroll rc-show-scrollbar mt-2 mb-4 pl-8 pr-4 min-h-[380px]'>
          <div className='pr-1 -mr-3'>
            <CashflowSheetDrawerExpectedTransactionList
              type={type}
              expectedTransactions={expectedTransactions}
              total={total}
              loading={loading}
              refetch={refetch}
              withExpectedDatePicker={false}
            />
          </div>
        </Disclosure.Panel>
      </Disclosure>
    </>
  )
}
