import { useMutation } from '@apollo/client'
import { DatePicker, Form, Input } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'

import Button from 'design/Button'

import CategorySelect from 'components/categories/CategorySelect'
import CurrencyInput from 'components/CurrencyInput'
import VatSelect from 'components/vat/VatSelect'
import { ADD_EXPECTED_TRANSACTION_TO_INVOICE } from 'graphql/invoices'
import useHandleFormValidationErrors from 'hooks/useHandleFormValidationErrors'
import { useTranslation } from 'react-i18next'
import AccountingDocumentType from 'enums/AccountingDocumentType.js'
import { ADD_EXPECTED_TRANSACTION_TO_QUOTE } from 'graphql/quotes.js'
import { ADD_EXPECTED_TRANSACTION_TO_ORDER } from 'graphql/orders.js'

const accountingDocumentIdNameByType = {
  [AccountingDocumentType.INVOICE]: 'invoiceId',
  [AccountingDocumentType.QUOTE]: 'quoteId',
  [AccountingDocumentType.ORDER]: 'orderId'
}

const mutationByType = {
  [AccountingDocumentType.INVOICE]: ADD_EXPECTED_TRANSACTION_TO_INVOICE,
  [AccountingDocumentType.QUOTE]: ADD_EXPECTED_TRANSACTION_TO_QUOTE,
  [AccountingDocumentType.ORDER]: ADD_EXPECTED_TRANSACTION_TO_ORDER
}

const NewExpectedTransactionForm = ({ accountingDocument, onCompleted }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const handleValidationErrors = useHandleFormValidationErrors({ form })

  const [addExpectedTransactionToInvoice, { loading }] = useMutation(mutationByType[accountingDocument.type], {
    onError: handleValidationErrors,
    onCompleted
  })

  const onFinish = useCallback((formValues) => {
    addExpectedTransactionToInvoice({
      variables: {
        input: {
          [accountingDocumentIdNameByType[accountingDocument.type]]: accountingDocument.id,
          newExpectedTransactionWithDetailParams: {
            ...formValues,
            expectedDate: formValues.expectedDate.format('YYYY-MM-DD')
          }
        }
      }
    })
  }, [addExpectedTransactionToInvoice, accountingDocument.type, accountingDocument.id])

  const initialExpectedDate = useMemo(() => accountingDocument.dueDate ? moment(accountingDocument.dueDate) : moment(accountingDocument.issueDate).add(30, 'days'), [accountingDocument.dueDate, accountingDocument.issueDate])
  const initialDescription = useMemo(() => {
    const length = accountingDocument.expectedTransactions.length
    return t('shared.installment', { index: length + 1 })
  }, [accountingDocument.expectedTransactions.length, t])

  const allCategoryIds = [...new Set(accountingDocument.expectedTransactions.flatMap(expectedTransaction => expectedTransaction.expectedTransactionDetails.map(detail => detail.categoryId)))]
  const defaultCategoryId = allCategoryIds.length === 1 ? allCategoryIds[0] : null

  return (
    <Form form={form} layout='vertical' onFinish={onFinish} className='p-2' requiredMark='optional'>
      <Form.Item>
        <span className='font-bold'>{t('component.newExpectedTransactionForm.newExpectedTransaction')}</span>
      </Form.Item>

      <Form.Item name='description' placeholder='Description' initialValue={initialDescription} label={t('shared.description')} rules={[{ required: true, message: t('component.newExpectedTransactionForm.descriptionRequired') }]}>
        <Input className='w-60' />
      </Form.Item>

      <Form.Item name='expectedDate' label={t('shared.expectedPayment')} initialValue={initialExpectedDate} rules={[{ required: true }]}>
        <DatePicker
          loading={loading}
          disabled={loading}
          format='DD/MM/YYYY'
          allowClear={false}
          className='w-60'
        />
      </Form.Item>

      <Form.Item name='categoryId' label={t('shared.category')} initialValue={defaultCategoryId}>
        <CategorySelect type={[AccountingDocumentType.QUOTE, AccountingDocumentType.ORDER].includes(accountingDocument.type) ? 'cashin' : accountingDocument.cashType} className='w-60' />
      </Form.Item>

      <Form.Item name='vatRate' label={t('shared.vatRate')} initialValue={0.2} rules={[{ required: true }]}>
        <VatSelect className='w-60' />
      </Form.Item>

      <Form.Item name='amount' label={t('shared.amountInclTax')} initialValue={0} rules={[{ required: true }]} className='w-60'>
        <CurrencyInput options={{ precision: 2 }} grow />
      </Form.Item>

      <Form.Item>
        <Button primary disabled={loading} loading={loading} type='submit' label={t('component.newExpectedTransactionForm.addExpectedTransaction')} />
      </Form.Item>
    </Form>
  )
}

NewExpectedTransactionForm.propTypes = {
  accountingDocument: PropTypes.object,
  onCompleted: PropTypes.func
}

export default NewExpectedTransactionForm
