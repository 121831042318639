
import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Toggle from 'design/Toggle.js'
import i18next from 'i18next'
import { TOGGLE_SCENARIO_INCLUDE_RECURRING_PAYMENTS } from 'graphql/scenarios.js'
import { useMutation } from '@apollo/client'
import classNames from 'classnames'

ScenarioIncludeRecurringPayments.propTypes = {
  scenarioId: PropTypes.string,
  includeRecurringPayments: PropTypes.bool,
  onUpdated: PropTypes.func
}

export default function ScenarioIncludeRecurringPayments ({ scenarioId, includeRecurringPayments, onUpdated }) {
  const { t } = i18next

  const [scenarioKey, setScenarioKey] = useState(scenarioId)

  useEffect(() => {
    // Reset if scenario changes
    if (scenarioKey !== scenarioId) {
      setScenarioKey(scenarioId)
    }
  }, [scenarioKey, scenarioId])

  const [toggleScenarioIncludeRecurringPayments] = useMutation(TOGGLE_SCENARIO_INCLUDE_RECURRING_PAYMENTS,
    {
      onCompleted: () => onUpdated()
    })

  const handleOnChange = useCallback((includeRecurringPayments) => {
    toggleScenarioIncludeRecurringPayments({
      variables: {
        input: {
          scenarioId: scenarioId,
          includeRecurringPayments
        }
      }
    })
  }, [scenarioId, toggleScenarioIncludeRecurringPayments])

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <div className='cursor-default text-sm font-semibold text-base-color flex'>{t('component.scenarioInclideRecurringPayments.title')}</div>
      <div className='flex flex-row'>
        <Toggle
          size='small' onChange={() => handleOnChange(!includeRecurringPayments)} toggled={includeRecurringPayments} className='inline'
        />
        <span className={classNames('text-base-color')}>
          {t('component.scenarioInclideRecurringPayments.toggleLabel')}
        </span>
      </div>
    </div>

  )
}
