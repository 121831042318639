import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

ScenarioCommentIndicator.propTypes = {
  comment: PropTypes.string,
  className: PropTypes.string
}

export default function ScenarioCommentIndicator ({ comment, className }) {
  if (!comment) return null

  return (
    <ChatBubbleBottomCenterTextIcon className={classNames('min-w-max w-4 h-4 text-light-primary', className)} />
  )
}
