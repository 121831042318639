import { useMemo } from 'react'
import { get, remove, zip } from 'lodash'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { getCategoriesInTreeSelector } from 'selectors/categories'
import numeral from 'numeral'
import featureConfig from 'config/features.js'
import { isUncategorized } from 'utils/categories.js'

export default function usePreparedSheet ({ sheet, sheetDates, realisedVsForecasted }) {
  const cashinCategoryTree = useSelector(state => getCategoriesInTreeSelector(state, 'cashin'))
  cashinCategoryTree.push({
    id: 'UNCATEGORIZED_CASHIN',
    color: '#999999',
    parentId: null,
    type: 'cashin',
    vatRate: null,
    children: null
  })

  const cashoutCategoryTree = useSelector(state => getCategoriesInTreeSelector(state, 'cashout'))

  cashoutCategoryTree.push({
    id: 'UNCATEGORIZED_CASHOUT',
    color: '#999999',
    parentId: null,
    type: 'cashout',
    vatRate: null,
    children: null
  })

  const { t } = useTranslation()

  return useMemo(() => {
    if (!sheet) return []

    const lines = []

    lines.push({ type: 'STARTING_BALANCE', name: t('hook.usePreparedCashflowSheet.startOfMonthCash'), sheet, absolutePath: `${lines.length}` })

    lines.push({ type: 'CASH_IN', name: t('shared.cashinPlural'), sheet, total: getPeriodTotal({ sheet, sheetDates, keys: { realised: 'realisedCashin', budgeted: 'budgetedCashin', forecasted: 'forecastedCashin' }, alwaysTakeRealisedForPast: !realisedVsForecasted }), absolutePath: `${lines.length}` })
    const cashinCategoryRows = getCategoryRows({ type: 'CASHIN_CATEGORY', sheet, sheetDates, categoryTree: cashinCategoryTree, baseIndex: lines.length, realisedVsForecasted })
    lines.push(...removeUncategorizedRowsWhenEmpty(cashinCategoryRows))

    lines.push({ type: 'CASH_OUT', name: t('shared.cashoutPlural'), sheet, total: getPeriodTotal({ sheet, sheetDates, keys: { realised: 'realisedCashout', budgeted: 'budgetedCashout', forecasted: 'forecastedCashout' }, alwaysTakeRealisedForPast: !realisedVsForecasted }), absolutePath: `${lines.length}` })
    const cashoutCategoryRows = getCategoryRows({ type: 'CASHOUT_CATEGORY', sheet, sheetDates, categoryTree: cashoutCategoryTree, baseIndex: lines.length, realisedVsForecasted })
    lines.push(...removeUncategorizedRowsWhenEmpty(cashoutCategoryRows))

    if (featureConfig.vatDetailsEnabled) {
      lines.push({ type: 'VAT_PAYMENTS', name: t('dashboard.cashflowSheet.vatEstimation'), sheet, total: getPeriodTotal({ sheet, sheetDates, keys: { realised: 'realisedVatPayment', budgeted: 'forecastedVatPayment', forecasted: 'forecastedVatPayment' }, alwaysTakeRealisedForPast: true, invertSign: true }), absolutePath: `${lines.length}` })
    }

    lines.push({ type: 'CASH_FLOW', name: t('hook.usePreparedCashflowSheet.variation'), sheet, total: getPeriodTotal({ sheet, sheetDates, keys: { realised: 'realisedCashflow', budgeted: 'budgetedCashflow', forecasted: 'forecastedCashflow' }, alwaysTakeRealisedForPast: !realisedVsForecasted }), absolutePath: `${lines.length}` })
    lines.push({ type: 'ENDING_BALANCE', name: t('hook.usePreparedCashflowSheet.endOfMonthCash'), sheet, absolutePath: `${lines.length}` })

    return lines
  }, [sheet, t, sheetDates, realisedVsForecasted, cashinCategoryTree, cashoutCategoryTree])
}

function getCategoryRows ({ type, sheet, sheetDates, categoryTree, baseIndex, realisedVsForecasted }) {
  const allDetails = sheet.map(({ value }) => {
    const categoryValue = type === 'CASHIN_CATEGORY' ? value.cashinDetailsByCategory : value.cashoutDetailsByCategory
    return categoryValue
  })

  const categoryValues = zip(...allDetails)
  const categorySheets = categoryValues.map((values) => {
    return sheet.map(({ date }, index) => ({ date, value: values[index] }))
  })

  const rowsByCategoryId = categorySheets.reduce((acc, categorySheet) => {
    const categoryId = categorySheet[0].value.categoryId
    return { ...acc, [categoryId]: { type, categoryId, sheet: categorySheet, total: getPeriodTotal({ sheet: categorySheet, sheetDates, keys: { realised: 'realised', budgeted: 'budgeted', forecasted: 'forecasted' }, alwaysTakeRealisedForPast: !realisedVsForecasted }) } }
  }, {})

  return mapRowsInTree({ categoryTree, rowsByCategoryId, baseIndex })
}

const removeUncategorizedRowsWhenEmpty = (categoryRows) => {
  remove(categoryRows, (row) => isUncategorized(row.categoryId) && row.total.realised === 0 && row.total.forecasted === 0)
  return categoryRows
}

export const mapRowsInTree = ({ categoryTree, rowsByCategoryId, parentCategoryId, baseIndex, parentPath }) => {
  return categoryTree.map((category, index) => {
    const categoryId = category.id
    const categoryName = category.name
    const row = rowsByCategoryId[categoryId]
    const absolutePath = parentPath ? `${parentPath}.children.${index}` : `${baseIndex + index}`

    const newRow = { ...row, children: null, parentCategoryId: parentCategoryId || null, absolutePath, name: categoryName }

    const children = [...(get(category, 'children') || [])]
    if (children.length > 0) newRow.children = mapRowsInTree({ categoryTree: children, rowsByCategoryId, parentCategoryId: categoryId, parentPath: absolutePath })

    return newRow
  })
}

function getPeriodTotal ({ sheet, sheetDates, keys: { realised, budgeted, forecasted }, alwaysTakeRealisedForPast = false, invertSign = false }) {
  const realisedAcc = numeral(0)
  const forecastedAcc = numeral(0)

  sheet.forEach(({ value }, index) => {
    const realisedValue = get(value, realised) || 0
    const budgetedValue = get(value, budgeted) || 0
    const forecastedValue = get(value, forecasted) || 0

    if (sheetDates[index].isPastMonth) {
      realisedAcc.add(realisedValue)
      if (alwaysTakeRealisedForPast) {
        forecastedAcc.add(realisedValue)
      } else {
        forecastedAcc.add(budgetedValue)
      }
    } else if (sheetDates[index].isCurrentMonth) {
      realisedAcc.add(realisedValue)
      forecastedAcc.add(forecastedValue)
    } else if (sheetDates[index].isFutureMonth) {
      forecastedAcc.add(forecastedValue)
    }
  })

  if (invertSign) {
    realisedAcc.multiply(-1)
    forecastedAcc.multiply(-1)
  }

  return {
    realised: realisedAcc.value(),
    forecasted: forecastedAcc.value()
  }
}
