import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import NewExpectedTransactionForm from 'components/expected-transactions/NewExpectedTransactionForm'
import { useTranslation } from 'react-i18next'

const AddExpectedTransactionToAccountingDocumentButton = ({ accountingDocument }) => {
  const { t } = useTranslation()
  const [displayForm, setDisplayForm] = useState(false)

  if (displayForm === false) {
    return (
      <div className='w-full flex flex-row justify-center'>
        <Button type='dashed' icon={<PlusOutlined />} onClick={() => setDisplayForm(true)} className='rc-no-shadow'>
          {t('component.addExpectedTransactionToAccountingDocumentButton.addAnExpectedTransaction')}
        </Button>
      </div>
    )
  }

  return <NewExpectedTransactionForm accountingDocument={accountingDocument} onCompleted={() => setDisplayForm(false)} />
}

AddExpectedTransactionToAccountingDocumentButton.propTypes = {
  accountingDocument: PropTypes.object
}

export default AddExpectedTransactionToAccountingDocumentButton
