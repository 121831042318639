import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import useQuotesQueryFilters from 'hooks/quotes/useQuotesQueryFilters.js'
import AccountingDocumentSearchInput from 'components/accounting-documents/AccountingDocumentSearchInput.js'
import { backgroundTextColors } from 'utils/colors.js'

const badgeColorsMap = {
  OPEN: 'primary',
  INVOICED: 'success',
  CANCELED: 'error'
}

const QuoteSearchInput = ({ onSearchChanged, onStateFilterChanged, onWithIgnoredChanged, loading }) => {
  const { t } = useTranslation()
  const { quotesQueryFilters, mergeQuotesQueryFilters } = useQuotesQueryFilters()
  const [state, setState] = useState('OPEN')
  const [withIgnored, setWithIgnored] = useState(quotesQueryFilters.withIgnored)

  useEffect(() => {
    onWithIgnoredChanged(withIgnored)
    mergeQuotesQueryFilters({ withIgnored })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onWithIgnoredChanged, withIgnored]) // add mergeQuotesQueryFilters on dependencies create a loop

  const handleSearchChange = useCallback((search) => {
    onSearchChanged(search)
  }, [onSearchChanged])

  const handleStateChange = useCallback((value) => {
    onStateFilterChanged(value !== 'ALL' ? value : null)
    setState(value || 'ALL')
  }, [onStateFilterChanged])

  return (
    <AccountingDocumentSearchInput
      states={{
        ALL: { label: t('component.quoteSearchInput.all') },
        OPEN: { label: t('component.quoteSearchInput.open'), className: backgroundTextColors[badgeColorsMap.OPEN] },
        INVOICED: { label: t('component.quoteSearchInput.invoiced'), className: backgroundTextColors[badgeColorsMap.INVOICED] },
        CANCELED: { label: t('component.quoteSearchInput.canceled'), className: backgroundTextColors[badgeColorsMap.CANCELED] }
      }}
      currentState={state}
      handleStateChange={handleStateChange}
      handleSearchChange={handleSearchChange}
      withIgnored={withIgnored}
      handleWithIgnoredChange={setWithIgnored}
      loading={loading}
    />
  )
}

QuoteSearchInput.propTypes = {
  onSearchChanged: PropTypes.func,
  onStateFilterChanged: PropTypes.func,
  onWithIgnoredChanged: PropTypes.func,
  loading: PropTypes.bool
}

export default QuoteSearchInput
