import { gql } from '@apollo/client'

export const ME = gql`
  query me {
    me {
      id
      role
      email
      firstName
      lastName
      phone
      locale
      company {
        id
        externalId
        blocked
        name
        vatDateRule
        vatPeriod
        businessType
        numberOfEmployees
        country
        freeTrialEndAt
        firstSyncingStartAt
        firstSyncingEndAt
        onboardingEndAt
        wcr
        biId
        signUpSource
        subscriptionType
        ownerId
        hiddenAccountIds
        subscription {
          id
          plan
          includedBanksQuantity
          includedUsersQuantity
          includedScenariosQuantity
          hasAccessToVatDetails
          hasAccessToTransactionsExport
          hasAccessToBatchReceiptsDownload
          hasAccessToScenarioConfiguration
          hasAccessToManualTriggerSyncAccount
          blocked
        }
      }
    }
  }
`

export const LIST_USERS = gql`
  query listUsers {
    listUsers {
      id
      firstName
      lastName
      email
      role
      sellsyUserId
    }
  }
`

export const INVITE_USER = gql`
  mutation inviteUser($input: InviteUserInput!) {
    inviteUser(input: $input) {
      id
      firstName
      lastName
      email
      role
      sellsyUserId
    }
  }
`

export const SET_PASSWORD = gql`
  mutation setPassword($inviteToken: String!, $password: String!) {
    setPassword(inviteToken: $inviteToken, password: $password) {
      email
    }
  }
`

export const CHANGE_USER_ROLE = gql`
  mutation changeUserRole($userIdToUpdate: ID!, $newRole: Role!) {
    changeUserRole(userIdToUpdate: $userIdToUpdate, newRole: $newRole) {
      id
      role
    }
  }
`

export const CHANGE_LOCALE = gql`
  mutation changeLocale($locale: String!) {
    changeLocale(locale: $locale) {
      id
      locale
    }
  }
`

export const DELETE_USER = gql`
  mutation deleteUser($userIdToDelete: ID!) {
    deleteUser(userIdToDelete: $userIdToDelete)
  }
`

export const GET_INTERCOM_USER_HASH = gql`
  query getIntercomUserHash {
    getIntercomUserHash
  }
`
