import { useMemo } from 'react'

import useAuthenticatedContext from 'hooks/useAuthenticatedContext'

export default () => {
  const { company } = useAuthenticatedContext()
  const companySlug = company?.externalId

  const quipuAppRootUrl = useMemo(() => {
    if (process.env.REACT_APP_TENANT !== 'QUIPU' || !companySlug) return null

    if (process.env.REACT_APP_MODE === 'production') {
      return `https://getquipu.com/d/${companySlug}`
    }

    return `https://quiputest.net/d/${companySlug}`
  }, [companySlug])

  return quipuAppRootUrl
}
