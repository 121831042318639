import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useSelector } from 'react-redux'
import useRenderExpectedDateFooterPicker from 'hooks/useRenderExpectedDateFooterPicker'
import { DatePicker, Form, Input, Tooltip } from 'antd'
import ButtonLink from 'design/ButtonLink'
import CategorySelect from 'components/categories/CategorySelect'
import VatSelect from 'components/vat/VatSelect'
import VatToggle from 'components/vat/VatToggle'
import CurrencyInput from 'components/CurrencyInput'
import Button from 'design/Button'
import { XMarkIcon, ListBulletIcon } from '@heroicons/react/20/solid'
import { ClockIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { last } from 'lodash'
import { getVatRates } from 'selectors/users'

AccountingDocumentEditExpectedTransaction.propTypes = {
  form: PropTypes.object,
  expectedTransactions: PropTypes.arrayOf(PropTypes.object),
  defaultCategoryId: PropTypes.string,
  type: PropTypes.string,
  defaultDueDate: PropTypes.object
}

function AccountingDocumentEditExpectedTransaction ({ form, expectedTransactions, defaultCategoryId, defaultDueDate, type }) {
  const { t } = useTranslation()
  const vatRates = useSelector(getVatRates)

  const renderDatePickerFooter = useRenderExpectedDateFooterPicker()

  const handleExpectedDatePickerChange = useCallback((index, date) => {
    const expectedDatePath = ['expectedTransactions', index, 'expectedDate']
    const firstExpectedDate = form.getFieldValue(expectedDatePath)
    if (!firstExpectedDate) form.setFieldValue(expectedDatePath, date)
  }, [form])

  return (
    <>
      <div className='flex flex-row items-center font-bold mb-4'>
        <ClockIcon className='w-4 h-4 text-base mr-1' /> {t('component.invoiceEditDrawer.expectedTransactions')}
      </div>

      <Form.List name='expectedTransactions' initialValue={expectedTransactions}>
        {(fields, { add, remove }) => (
          <div className='flex flex-col items-end'>
            {fields.map(({ key, name }) => (
              <div key={key} className='w-full rounded border border-gray-200 p-4 mb-4 bg-ultra-light-primary bg-opacity-20'>
                <div className='w-full flex flex-row items-center justify-between space-x-4'>
                  <Form.Item name={[name, 'description']} initialValue={getInitialExpectedTransactionDescription({ index: name })} label={t('shared.description')} rules={[{ required: true, message: t('component.invoiceEditDrawer.descriptionRequired') }, { max: 1024 }]} className='w-1/2'>
                    <Input size='large' placeholder={t('component.invoiceEditDrawer.expectedTransactionDescription')} onKeyDown={e => e.stopPropagation()} />
                  </Form.Item>

                  <Form.Item name={[name, 'expectedDate']} getValueProps={(i) => ({ value: i ? moment(i) : undefined })} initialValue={getInitialExpectedTransactionExpectedDate({ firstExpectedDate: form.getFieldValue(['expectedTransactions', 0, 'expectedDate']), defaultDueDate, index: name })} label={t('component.invoiceEditDrawer.expectedDate')} rules={[{ required: true }]} className='w-1/2'>
                    <DatePicker
                      format='DD/MM/YYYY'
                      allowClear={false}
                      size='large'
                      className='w-full'
                      renderExtraFooter={() => renderDatePickerFooter((date) => handleExpectedDatePickerChange(name, date))}
                      disabledDate={disabledDate}
                      showToday={false}
                    />
                  </Form.Item>

                  <Tooltip title={fields.length <= 1 ? t('component.invoiceEditDrawer.warning.atLeastDueDate', { transactionType: (type === 'cashin' ? t('shared.cashin') : t('shared.cashout')).toLowerCase() }) : t('component.invoiceEditDrawer.deleteExpectedDate')} placement='left'>
                    <ButtonLink
                      label={(<XMarkIcon className='w-6 h-6' />)}
                      onClick={() => remove(name)}
                      disabled={fields.length <= 1}
                    />
                  </Tooltip>
                </div>

                <div className='flex flex-row items-center font-bold mb-4'>
                  <ListBulletIcon className='w-4 h-4 text-gray-400 mr-1' /> {t('shared.details')}
                </div>

                <Form.List name={[name, 'expectedTransactionDetails']} initialValue={getInitialExpectedTransactionDetails(expectedTransactions, name)}>
                  {(fields, { add, remove }) => (
                    <div className='flex flex-col items-end'>
                      {fields.map(({ key, name }) => (
                        <div key={key} className='w-full flex flex-row items-center space-x-4 rounded border border-gray-200 p-4 pb-0 mb-4 bg-white'>
                          <Form.Item name={[name, 'categoryId']} initialValue={defaultCategoryId} label={t('shared.category')} className='w-4/12'>
                            <CategorySelect type={type} />
                          </Form.Item>

                          <Form.Item name={[name, 'vatRate']} label={t('shared.vatRate')} initialValue={last(vatRates)} rules={[{ required: true }]} className='w-3/12'>
                            <VatSelect />
                          </Form.Item>

                          <Form.Item name={[name, 'amount']} label={t('component.invoiceEditDrawer.amount')} initialValue={1000} rules={[{ required: true, message: t('component.invoiceEditDrawer.amountRequired') }]} className='w-3/12'>
                            <CurrencyInput options={{ precision: 2 }} min={0.01} />
                          </Form.Item>

                          <Form.Item name={[name, 'withVat']} label=' ' initialValue='true' rules={[{ required: true }]} className='w-2/12'>
                            <VatToggle />
                          </Form.Item>

                          <Tooltip placement='left' title={fields.length <= 1 ? t('component.invoiceEditDrawer.expectedTransactionMustHaveAtLeastOneDetail') : t('component.invoiceEditDrawer.deleteDetail')}>
                            <ButtonLink
                              label={(<XMarkIcon className='w-4 h-4' />)}
                              onClick={() => remove(name)}
                              disabled={fields.length <= 1}
                            />
                          </Tooltip>
                        </div>
                      ))}

                      <Button label={t('component.invoiceEditDrawer.addDetail')} onClick={add} />
                    </div>
                  )}
                </Form.List>
              </div>
            ))}

            <Button label={t('component.invoiceEditDrawer.addExpectedTransaction')} onClick={add} />
          </div>
        )}
      </Form.List>
    </>
  )
}

function getInitialExpectedTransactionDetails ({ expectedTransactions, index }) {
  if (expectedTransactions === undefined || expectedTransactions.length <= index) {
    return [{}]
  }
  return expectedTransactions[index].expectedTransactionDatails
}

function getInitialExpectedTransactionDescription ({ index }) {
  const { t } = i18next
  return t('shared.installment', { index: `${index + 1}` })
}

function getInitialExpectedTransactionExpectedDate ({ firstExpectedDate, defaultDueDate, index }) {
  if (firstExpectedDate) {
    return moment(firstExpectedDate, 'YYYY-MM-DD').add(index, 'months')
  }

  if (defaultDueDate?.isAfter()) {
    return defaultDueDate
  }

  return undefined
}

function disabledDate (current) {
  return moment().startOf('day').isAfter(current)
}

export default AccountingDocumentEditExpectedTransaction
