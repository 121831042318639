import PropTypes from 'prop-types'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { useMutation } from '@apollo/client'
import CurrencyInput from 'components/CurrencyInput'
import CashflowSheetContext from 'contexts/CashflowSheetContext'
import { FORECAST_AMOUNT } from 'graphql/scenario-forecasts'
import ForecastAssistant from './ForecastAssistant'

UpdateForecastCell.defaultProps = {
  onBlur: () => true,
  withAssistant: false
}

UpdateForecastCell.propTypes = {
  budgeted: PropTypes.number,
  scenarioId: PropTypes.string,
  categoryId: PropTypes.string,
  sheetDate: PropTypes.object,
  onBlur: PropTypes.func,
  withAssistant: PropTypes.bool,
  className: PropTypes.string,
  toDate: PropTypes.string
}

function UpdateForecastCell ({ budgeted, scenarioId, categoryId, sheetDate, onBlur, withAssistant, className, toDate }) {
  const { refetchCashflowSheet } = useContext(CashflowSheetContext)

  const [assistantVisible, setAssistantVisible] = useState(withAssistant)
  const [inputFocused, setInputFocused] = useState(true)
  const [amount, setAmount] = useState(budgeted)

  const [forecastAmount, { loading }] = useMutation(FORECAST_AMOUNT)

  const onChangeAmount = useCallback((newAmount) => {
    setAmount(newAmount || 0)
  }, [setAmount])

  useEffect(() => {
    setAmount(budgeted)
  }, [budgeted, setAmount])

  const updateForecasts = useCallback((forecasts, successCallback = onBlur) => {
    forecastAmount({
      variables: {
        input: {
          date: sheetDate.momentDate.format('YYYY-MM-DD'),
          scenarioId,
          categoryId,
          amount: forecasts[0].amount
        }
      },
      onCompleted: () => {
        refetchCashflowSheet()
        return successCallback()
      }
    })
  }, [onBlur, forecastAmount, sheetDate.momentDate, scenarioId, categoryId, refetchCashflowSheet])

  const onBlurIfAssistantNotVisible = useCallback(() => {
    if (!assistantVisible) onBlur()
  }, [assistantVisible, onBlur])

  const onBlurIfInputNotFocused = useCallback(() => {
    if (!inputFocused) onBlur()
  }, [inputFocused, onBlur])

  const onVisibleChange = useCallback((visible) => {
    setAssistantVisible(visible)
    if (!visible) onBlurIfInputNotFocused()
  }, [setAssistantVisible, onBlurIfInputNotFocused])

  const handleOnBlur = useCallback((enterPressed) => {
    setInputFocused(false)

    if (budgeted === amount) {
      enterPressed ? onBlur() : onBlurIfAssistantNotVisible()
      return
    }

    const forecasts = [{ amount }]

    updateForecasts(forecasts, enterPressed ? onBlur : onBlurIfAssistantNotVisible)
  }, [setInputFocused, updateForecasts, amount, budgeted, onBlurIfAssistantNotVisible, onBlur])

  const input = useMemo(() => {
    return (
      <CurrencyInput
        autoFocus
        disabled={loading}
        loading={loading}
        // min={budgetedDiff}
        value={amount || null}
        onChange={onChangeAmount}
        onBlur={() => handleOnBlur(false)}
        onPressEnter={() => handleOnBlur(true)}
        onFocus={() => setInputFocused(true)}
        align='right'
        grow
        // step={1000}
        className={className}
      />
    )
  }, [loading, amount, onChangeAmount, handleOnBlur, setInputFocused, className])

  if (withAssistant) {
    return (
      <ForecastAssistant
        scenarioId={scenarioId}
        categoryId={categoryId}
        defaultToDate={toDate}
        sheetDate={sheetDate}
        amount={amount}
        open={assistantVisible}
        onOpenChange={onVisibleChange}
        hideAssistant={() => setAssistantVisible(false)}
        onCompleted={onBlur}
      >
        {input}
      </ForecastAssistant>
    )
  }

  return input
}

export default UpdateForecastCell
