import { handleActions } from 'redux-actions'
import { set, pipe } from 'lodash/fp'

const initialState = {
  transactionsTotal: null,
  isFecthingTransactionsTotal: false,
  isFecthingCashoutTotal: false,
  isFecthingCashinTotal: false,
  total: {
    cashout: {},
    cashin: {}
  },
  totalToValidate: {
    cashout: 0,
    cashin: 0
  },
  previousActionsLeft: {
    category: 0,
    vatRate: 0
  },
  actionsLeft: {
    category: 0,
    vatRate: 0
  }
}

const reducer = handleActions(
  {
    // TRANSACTIONS
    REQUEST_TRANSACTIONS_TOTAL: (state) => set('isFecthingTransactionsTotal', true, state),
    REQUEST_TRANSACTIONS_TOTAL_SUCCESS: (state, { payload: { data } }) =>
      pipe(
        set('isFecthingTransactionsTotal', false),
        set('transactionsTotal', data)
      )(state),
    REQUEST_TRANSACTIONS_TOTAL_ERROR: (state) => set('isFecthingTransactionsTotal', false, state),

    // CASHOUT
    REQUEST_CASHOUT_TOTAL: (state) => set('isFecthingCashoutTotal', true, state),
    REQUEST_CASHOUT_TOTAL_SUCCESS: (state, { payload: { year, data } }) =>
      pipe(
        set('isFecthingCashoutTotal', false),
        set(`total.cashout.${year}`, data)
      )(state),
    REQUEST_CASHOUT_TOTAL_ERROR: (state) => set('isFecthingCashoutTotal', false, state),

    // CASHIN
    REQUEST_CASHIN_TOTAL: (state) => set('isFecthingCashinTotal', true, state),
    REQUEST_CASHIN_TOTAL_SUCCESS: (state, { payload: { year, data } }) =>
      pipe(
        set('isFecthingCashinTotal', false),
        set(`total.cashin.${year}`, data)
      )(state),
    REQUEST_CASHIN_TOTAL_ERROR: (state) => set('isFecthingCashinTotal', false, state),

    // TO_VALIDATE
    REQUEST_TO_VALIDATE_TOTAL_SUCCESS: (state, { payload: { type, total } }) =>
      set(`totalToValidate.${type}`, total, state),

    // ACTIONS LEFT
    UPDATE_ACTIONS_LEFT: (state, { payload: { actionsLeft } }) =>
      pipe(
        set('previousActionsLeft', state.actionsLeft),
        set('actionsLeft', { ...state.actionsLeft, ...actionsLeft })
      )(state)
  },
  { ...initialState }
)

export default reducer
