
import { createSelector } from 'reselect'
import { getBankConnectionFaviconUrl } from 'utils/banks-infos.js'

const getBanksConnections = (data) => data?.listBankConnectionsWithAccounts || []

export const getBankInfosByAccountId = createSelector(
  getBanksConnections,
  (bankConnections) => {
    const getBankInfosByAccountId = {}

    bankConnections.forEach((bankConnection) => {
      const accounts = bankConnection?.accounts || []

      accounts.forEach((account) => {
        const faviconUrl = getBankConnectionFaviconUrl(bankConnection)
        const bankName = bankConnection?.bankName
        const accountName = account.customName || account.originalName
        const accountNumber = account.accountNumber

        getBankInfosByAccountId[account.id] = {
          bankName,
          faviconUrl,
          accountName,
          accountNumber
        }
      })
    })

    return getBankInfosByAccountId
  }
)
