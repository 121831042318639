
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Spinner from './Spinner.js'

ButtonLink.propTypes = {
  label: PropTypes.node,
  // secondary: PropTypes.bool,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  displaySpinner: PropTypes.bool
}

export default function ButtonLink ({ label, onClick, disabled, loading, className, displaySpinner, ...rest }) {
  const handleOnClick = useCallback((e) => {
    if (disabled || loading) return
    if (onClick) onClick(e)
  }, [onClick, disabled, loading])

  return (
    <button
      type='button'
      onClick={handleOnClick}
      className={classNames('group outline-none', className)}
      {...rest}
    >
      <div
        className={classNames({
          'flex items-center': true,
          'text-gray-400': disabled || loading,
          'cursor-wait': loading,
          'text-primary group-hover:text-light-primary': !disabled && !loading
          // 'text-gray-500 group-hover:text-ultra-light-primary': !disabled && !loading && secondary
        })}
      >
        {(loading && displaySpinner) ? (<Spinner className='w-4 mr-2' />) : label}
      </div>
    </button>
  )
}
