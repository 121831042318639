import { actionTypes } from 'redux-resource'

export default ({
  READ_CATEGORIES: (type) => ({
    type: actionTypes.READ_RESOURCES_PENDING,
    resourceType: 'categories',
    requestKey: `readCategories/${type}`,
    requestProperties: { type },
    list: type
  }),

  CREATE_SCENARIO: (params, successCallback) => ({
    type: actionTypes.CREATE_RESOURCES_PENDING,
    resourceType: 'scenarios',
    requestKey: 'createScenario',
    requestProperties: { params, successCallback }
  }),

  GENERATE_CATEGORY_TREE: (type, successCallback) => ({
    type: actionTypes.CREATE_RESOURCES_PENDING,
    resourceType: 'categories',
    requestKey: `generateCategoryTree-${type}`,
    requestProperties: { params: { type }, successCallback }
  }),

  CREATE_CATEGORY: (params, successCallback, requestId = undefined) => ({
    type: actionTypes.CREATE_RESOURCES_PENDING,
    resourceType: 'categories',
    requestKey: `createCategory-${requestId || params?.parentId}`,
    requestProperties: { params, successCallback }
  }),

  CREATE_RECEIPT: ({ params, documentFile, successCallback }) => ({
    type: actionTypes.CREATE_RESOURCES_PENDING,
    resourceType: 'receipts',
    requestKey: 'createReceipt',
    requestProperties: { params, documentFile, successCallback }
  }),

  UPDATE_SCENARIO: (scenario, year, params, successCallback) => {
    const id = scenario.id

    return {
      type: actionTypes.UPDATE_RESOURCES_PENDING,
      resourceType: 'scenarios',
      requestKey: `updateScenario-${id}`,
      resources: [id],
      requestProperties: { params: { _id: id, year, ...params }, successCallback }
    }
  },

  UPDATE_CATEGORY: (id, params, successCallback) => {
    return {
      type: actionTypes.UPDATE_RESOURCES_PENDING,
      resourceType: 'categories',
      requestKey: `updateCategory-${id}`,
      resources: [id],
      requestProperties: { params: { id, ...params }, successCallback }
    }
  },

  MOVE_CATEGORY: (type, id, parentId, index, successCallback) => {
    return {
      type: actionTypes.UPDATE_RESOURCES_PENDING,
      resources: [id],
      resourceType: 'categories',
      requestKey: 'moveCategory',
      requestProperties: {
        type,
        params: { type, id, parentId, index },
        successCallback
      }
    }
  },

  DELETE_CATEGORY: (type, id, successCallback) => ({
    type: actionTypes.DELETE_RESOURCES_PENDING,
    resourceType: 'categories',
    requestKey: `deleteCategory-${id}`,
    resources: [id],
    requestProperties: { type, id, successCallback }
  }),

  // FORECASTS
  UPDATE_FORECASTS: (requestKey, scenarioId, year, forecasts, successCallback) => ({
    type: actionTypes.UPDATE_RESOURCES_PENDING,
    resourceType: 'scenarios',
    requestKey,
    resources: [scenarioId],
    requestProperties: {
      params: {
        _id: scenarioId,
        year,
        forecasts
      },
      successCallback
    }
  })
})
