import React from 'react'
import PropTypes from 'prop-types'

const ColorOption = ({ color, style, ...rest }) => {
  return (
    <div className='rc-color-option' style={{ backgroundColor: color }} {...rest} />
  )
}

ColorOption.defaultProps = {
  color: '#ccc',
  style: {}
}

ColorOption.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object
}

export default ColorOption
