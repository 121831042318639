import { Tooltip } from 'antd'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Currency from 'components/Currency'
import UpdateBudgeted from './UpdateBudgeted'
import { isUncategorized } from 'utils/categories.js'

BudgetedCell.propTypes = {
  budgeted: PropTypes.number,
  budgetedEditable: PropTypes.bool,
  scenarioId: PropTypes.string,
  categoryId: PropTypes.string,
  sheetDate: PropTypes.object,
  className: PropTypes.string,
  withAssistant: PropTypes.bool,
  maskZero: PropTypes.bool,
  toDate: PropTypes.string
}

export default function BudgetedCell ({ budgeted, budgetedEditable, scenarioId, categoryId, sheetDate, className, withAssistant, maskZero, toDate }) {
  const { t } = useTranslation()
  const [editMode, setEditMode] = useState(false)

  const onClickAmount = useCallback(() => {
    if (budgetedEditable) setEditMode(true)
  }, [budgetedEditable, setEditMode])

  const hasNoCateogry = isUncategorized(categoryId)

  if (editMode) {
    return (
      <UpdateBudgeted
        editable={budgetedEditable}
        budgeted={budgeted}
        scenarioId={scenarioId}
        categoryId={categoryId}
        toDate={toDate}
        sheetDate={sheetDate}
        onBlur={() => setEditMode(false)}
        className={className}
        withAssistant={withAssistant}
      />
    )
  }

  return (
    <div className={classNames('flex flex-row items-center w-full max-w-full overflow-visible', className)}>
      <Tooltip
        title={budgetedEditable ? null : hasNoCateogry ? t('dashboard.budgetedCell.cannotEditBudget.onUnCategorized') : t('dashboard.budgetedCell.cannotEditBudget')}
      >
        <div
          onClick={onClickAmount}
          className={classNames('border-primary rounded min-w-max flex justify-end items-center w-full cursor-text group-hover:border-2 group-hover:py-1.5 group-hover:px-1', {
            'cursor-not-allowed grayscale': !budgetedEditable
          })}
        >
          <Currency
            amount={budgeted}
            options={{ precision: 2 }}
            maskZero={maskZero}
            maskZeroLabel=' '
            className='min-h-max min-w-20'
          />
        </div>
      </Tooltip>
    </div>
  )
}
