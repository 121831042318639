import React, { useMemo } from 'react'
import { ConfigProvider } from 'antd'
import frFR from 'antd/lib/locale/fr_FR'
import enUS from 'antd/lib/locale/en_US'
import esES from 'antd/lib/locale/es_ES'

import useLocale from 'hooks/useLocale.js'
import Locale from 'enums/Locale.js'

export default function AntdConfigProvider (props) {
  const antdLocale = useAntdLocale()
  return <ConfigProvider {...props} locale={antdLocale} />
}

function useAntdLocale () {
  const locale = useLocale()

  return useMemo(() => {
    switch (locale) {
      case Locale.fr_FR:
        return frFR
      case Locale.es_ES:
        return esES
      case Locale.ca_ES:
        return esES // caES exists on antd, but we don't officially support caES yet
      default:
        return enUS
    }
  }, [locale])
}
