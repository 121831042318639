import { createActions, handleActions } from 'redux-actions'

export const actions = createActions({
  REQUEST_CURRENT_VAT_BALANCE: undefined,
  REQUEST_CURRENT_VAT_BALANCE_SUCCESS: (vatBalance) => ({ vatBalance }),
  REQUEST_CURRENT_VAT_BALANCE_ERROR: undefined,

  REQUEST_YEAR_VAT_BALANCE: (year) => ({ year }),
  REQUEST_YEAR_VAT_BALANCE_SUCCESS: (year, vatBalance) => ({ year, vatBalance }),
  REQUEST_YEAR_VAT_BALANCE_ERROR: undefined
})

const initialState = {
  isGettingCurrentVatBalance: false,
  isGettingYearVatBalance: false,
  currentVatBalance: {},
  vatBalanceByYear: {}
}

const reducer = handleActions(
  {
    REQUEST_CURRENT_VAT_BALANCE: (state) => ({
      ...state,
      isGettingCurrentVatBalance: true
    }),

    REQUEST_CURRENT_VAT_BALANCE_SUCCESS: (state, { payload: { vatBalance } }) => ({
      ...state,
      isGettingCurrentVatBalance: false,
      currentVatBalance: vatBalance
    }),

    REQUEST_CURRENT_VAT_BALANCE_ERROR: (state) => ({
      ...state,
      isGettingCurrentVatBalance: false
    }),

    REQUEST_YEAR_VAT_BALANCE: (state) => ({
      ...state,
      isGettingYearVatBalance: true
    }),

    REQUEST_YEAR_VAT_BALANCE_SUCCESS: (state, { payload: { year, vatBalance } }) => ({
      ...state,
      isGettingYearVatBalance: false,
      vatBalanceByYear: {
        ...state.vatBalanceByYear,
        [year]: vatBalance
      }
    }),

    REQUEST_YEAR_VAT_BALANCE_ERROR: (state) => ({
      ...state,
      isGettingYearVatBalance: false
    })
  },
  { ...initialState }
)

export default reducer
