import { gql } from '@apollo/client'

export const FORECAST_AMOUNT = gql`
  mutation forecastAmount($input: ForecastAmountInput!) {
    forecastAmount(input: $input) {
      id
      amount
    }
  }
`

export const BATCH_FORECAST_AMOUNTS = gql`
  mutation batchForecastAmounts($input: BatchForecastAmountsInput!) {
    batchForecastAmounts(input: $input) {
      id
      amount
    }
  }
`

export const COMMENT_SCENARIO_FORECAST = gql`
  mutation commentScenarioForecast($input: CommentScenarioForecastInput!) {
    commentScenarioForecast(input: $input) {
      id
      comment
    }
  }
`

export const FORECAST_FROM_REALISED = gql`
  mutation forecastFromRealised($input: ForecastFromRealisedInput!) {
    forecastFromRealised(input: $input) {   
      id 
      amount
    }
  }
`
export const CHECK_EXISTS_SCENARIO_FORECAST = gql`
  query checkExistsScenarioForecast($request: CheckExistsScenarioForecastRequest!) {
    checkExistsScenarioForecast(request: $request)
  }
`

export const INITIALIZE_FORECAST_FROM_REALISED = gql`
  mutation initializeForecastFromRealised($input: InitializeForecastFromRealisedInput!) {
    initializeForecastFromRealised(input: $input) {   
      id 
      amount
    }
  }
`
