import React from 'react'
import DemoBanner from 'components/global/DemoBanner'

export default function InnerScreenGlobalComponents () {
  return (
    <>
      <DemoBanner />
    </>
  )
}
