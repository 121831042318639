import ButtonLink from 'design/ButtonLink'
import { isFunction } from 'lodash'
import moment from 'moment'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

const startOfCurrentMonth = moment().startOf('month')

export default function useRenderExpectedDateFooterPicker ({ handleOnChange } = {}) {
  const { t } = useTranslation()

  return useCallback((onChange) => {
    const usedOnChange = isFunction(onChange) ? onChange : handleOnChange
    const startOfToday = moment().startOf('day')
    const currentMidMonth = moment(startOfCurrentMonth).add(14, 'days')
    const currentMidMonthIsFuture = moment(currentMidMonth).isSameOrAfter(startOfToday)
    const currentEndMonth = moment(startOfCurrentMonth).endOf('month').startOf('day')
    const nextMidMonth = moment(startOfCurrentMonth).add(1, 'month').add(14, 'days')
    const nextEndMonth = moment(startOfCurrentMonth).add(1, 'month').endOf('month').startOf('day')

    return (
      <div className='flex flex-col divide-y divide-dashed divide-gray-200'>
        {currentMidMonthIsFuture && (
          <ButtonLink
            onClick={() => usedOnChange(currentMidMonth)}
            label={t('hook.useRenderExpectedDateFooterPicker.15thOfCurrentMonth')}
            className='text-xs py-3'
          />
        )}
        <ButtonLink
          onClick={() => usedOnChange(currentEndMonth)}
          label={t('hook.useRenderExpectedDateFooterPicker.xOfCurrentMonth', { dayNumber: currentEndMonth.format('D') })}
          className='text-xs py-3'
        />

        <ButtonLink
          onClick={() => usedOnChange(nextMidMonth)}
          label={t('hook.useRenderExpectedDateFooterPicker.15thOfNextMonth')}
          className='text-xs py-3'
        />

        <ButtonLink
          onClick={() => usedOnChange(nextEndMonth)}
          label={t('hook.useRenderExpectedDateFooterPicker.xOfNextMonth', { dayNumber: nextEndMonth.format('D') })}
          className='text-xs py-3'
        />
      </div>
    )
  }, [handleOnChange, t])
}
