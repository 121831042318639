import React from 'react'
import { Badge, Tooltip } from 'antd'
import { get } from 'lodash'
import { NavLink } from 'react-router-dom'

import { PresentationChartLineIcon, ArrowTrendingDownIcon, ArrowTrendingUpIcon } from '@heroicons/react/24/solid'
import { useSelector } from 'react-redux'
import useInitTotalToValidate from 'hooks/useInitTotalToValidate'
import colors from 'utils/colors'
import i18next from 'i18next'

const { t } = i18next

const availableRoutes = [
  {
    translationKey: 'component.navBar.dashboard',
    path: '/dashboard',
    icon: (
      <div className='rc-navbar-link-icon-container'>
        <PresentationChartLineIcon className='rc-navbar-link-icon h-5 w-5' />
      </div>
    )
  },
  {
    translationKey: 'shared.cashinPlural',
    path: '/cash-in',
    icon: (
      <div className='rc-navbar-link-icon-container rc-cashin'>
        <ArrowTrendingUpIcon className='rc-navbar-link-icon h-5 w-5' />
      </div>
    )
  },
  {
    translationKey: 'shared.cashoutPlural',
    path: '/cash-out',
    icon: (
      <div className='rc-navbar-link-icon-container rc-cashout'>
        <ArrowTrendingDownIcon className='rc-navbar-link-icon h-5 w-5' />
      </div>
    )
  }
]

export default function NavBar () {
  useInitTotalToValidate({ type: 'cashin' })
  useInitTotalToValidate({ type: 'cashout' })

  const totalCashinToValidate = useSelector(state => get(state, 'aggregations.totalToValidate.cashin', 0))
  const totalCashoutToValidate = useSelector(state => get(state, 'aggregations.totalToValidate.cashout', 0))

  return (
    <div className='rc-navbar'>
      {availableRoutes.map((route) => (
        <Tooltip key={route.path} title={t(route.translationKey)} placement='right' className='relative group'>
          <NavLink
            activeClassName='selected'
            to={route.path}
            className='rc-navbar-link'
          >
            {route.icon}
          </NavLink>

          {!!totalCashinToValidate && route.path === '/cash-in' && (
            <div className='absolute right-0 top-1 opacity-75 group-hover:opacity-100'>
              <Badge size='small' count={totalCashinToValidate} style={{ backgroundColor: colors.success }} className='min-w-8' />
            </div>
          )}

          {!!totalCashoutToValidate && route.path === '/cash-out' && (
            <div className='absolute right-0 top-1 opacity-75 group-hover:opacity-100'>
              <Badge size='small' count={totalCashoutToValidate} style={{ backgroundColor: colors.error }} className='min-w-8' />
            </div>
          )}
        </Tooltip>
      ))}
    </div>
  )
}
