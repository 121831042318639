import { Select } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

const { Option } = Select

const VatPeriodSelect = React.forwardRef((props, ref) => {
  const { t } = useTranslation()

  return (
    <Select
      ref={ref}
      placeholder={t('component.vatPeriodSelect.vatScheme')}
      {...props}
    >
      <Option value='MONTHLY'>
        {t('shared.monthly')}
      </Option>
      <Option value='QUARTERLY'>
        {t('shared.quarterly')}
      </Option>
      <Option value='HALF-YEARLY'>
        {t('shared.halfYearly')}
      </Option>
      <Option value='YEARLY'>
        {t('shared.yearly')}
      </Option>
    </Select>
  )
})

export default VatPeriodSelect
