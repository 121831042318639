import React, { useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { getStatus } from 'redux-resource'
import { Spin } from 'antd'
import { get } from 'lodash'

import { getCategoryTree } from 'selectors/categories'
import resourcesActions from 'actions/ResourcesActions'
import DroppableCategoryList from './DroppableCategoryList'
import Spinner from 'design/Spinner'

export default function ManageCategories ({ type }) {
  const dispatch = useDispatch()
  const categoryTree = useSelector(state => getCategoryTree(state, type))

  const readPending = useSelector(state => getStatus(state, `categories.requests.readCategories/${type}.status`).pending)
  const movePending = useSelector(state => getStatus(state, 'categories.requests.moveCategory.status').pending)

  useEffect(() => {
    dispatch(resourcesActions.READ_CATEGORIES(type))
  }, [dispatch, type])

  const updateCategoryPosition = useCallback((catId, parentId, index) => {
    dispatch(resourcesActions.MOVE_CATEGORY(type, catId, parentId, index))
  }, [dispatch, type])

  const isLoading = useMemo(() => {
    return readPending && (get(categoryTree, 'length', 0) === 0)
  }, [readPending, categoryTree])

  if (isLoading) return <Spin />

  return (
    <div className='px-2'>
      <DroppableCategoryList
        type={type}
        categoryTree={categoryTree}
        updateCategoryPosition={updateCategoryPosition}
      />

      {movePending && (
        <div className='h-full w-full fixed top-0 bottom-0 left-0 right-0 bg-gray-300 opacity-50 flex items-center justify-center rounded'>
          <Spinner className='w-6 h-6 text-primary' />
        </div>
      )}
    </div>
  )
}

ManageCategories.propTypes = {
  type: PropTypes.oneOf(['cashin', 'cashout'])
}
