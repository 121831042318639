import { gql } from '@apollo/client'

export const GET_CONNECTION_AUTH_URI = gql`
  query initiateConnection($connectionSource: ConnectionSource!) {
    initiateConnection(connectionSource: $connectionSource) {
      authUri
    }
  }
`

export const LIST_CONNECTIONS = gql`
  query connections {
    connections {
      id
      externalSource
      status
      firstSyncingStartAt
      firstSyncingEndAt
      lastSyncingAt
      hasSyncedQuoteOnce
      hasSyncedOrderOnce
      config {
        userId
        hasSyncDraftInvoice
        hasSyncQuote
        hasSyncRecurringPayment
        hasSyncOrder
      }
    }
  }
`

export const SYNC_CONNECTION = gql`
  mutation syncConnection($connectionId: ID!, $connectionSource: ConnectionSource!) {
    syncConnection(connectionId: $connectionId, connectionSource: $connectionSource) {
      id
      externalSource
      status
      firstSyncingStartAt
      firstSyncingEndAt
      lastSyncingAt
    }
  }
`

export const UPDATE_CONNECTION_CONFIG = gql`
  mutation updateConnectionConfig($connectionId: ID!, $hasSyncDraftInvoice: Boolean!) {
    updateConnectionConfig(input:{id: $connectionId, config: { hasSyncDraftInvoice: $hasSyncDraftInvoice }}) {
      id
      externalSource
      status
      firstSyncingStartAt
      firstSyncingEndAt
      lastSyncingAt
      hasSyncedQuoteOnce
      config {
        userId
        hasSyncDraftInvoice
      }
    }
  }
`
