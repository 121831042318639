
import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import Button from 'design/Button'
import { useLazyQuery } from '@apollo/client'
import { GET_SELLSY_OAUTH_REDIRECTION_URL } from 'graphql/sellsy'
import { Trans } from 'react-i18next'

function ConnectWithSellsyButton ({ isSigningIn }) {
  const [redirecting, setRedirecting] = useState(false)

  const onCompleted = useCallback((data) => {
    setRedirecting(true)
    const authUri = data.getSellsyOauthRedirectionUrl
    window.location.assign(authUri)
  }, [setRedirecting])

  const [getSellsyOauthRedirectionUrl, { loading }] = useLazyQuery(GET_SELLSY_OAUTH_REDIRECTION_URL, {
    onCompleted
  })

  return (
    <Button
      primary
      type='submit'
      label={(
        <div className='flex flex-row items-center'>
          <img src='/picto_logo_sellsy_light.svg' alt='Sellsy Trésorerie' className='w-8 -my-2 mr-4 text-white fill-current' />
          <div className='min-w-max'>
            <Trans
              i18nKey='signin.connectWithSellsyButton'
              components={{
                Bold: <span className='font-bold' />
              }}
            />

          </div>
        </div>
      )}
      onClick={getSellsyOauthRedirectionUrl}
      className='w-full shadow-lg hover:animate-pulse'
      // primary
      disabled={isSigningIn}
      loading={loading || redirecting}
    />
  )
}

ConnectWithSellsyButton.propTypes = {
  isSigningIn: PropTypes.bool
  // label: PropTypes.string.isRequired()
}

export default ConnectWithSellsyButton
