
import React from 'react'
import { Carousel } from 'antd'
import i18next from 'i18next'

import Testimonial from './Testimonial'

export default () => {
  const { t } = i18next

  return (
    <Carousel autoplay>
      <Testimonial
        logoSrc={t('testimonial.01.logoSrc')}
        avatarSrc={t('testimonial.01.avatarSrc')}
        name={t('testimonial.01.name')}
        position={t('testimonial.01.position')}
        text={t('testimonial.01.text')}
      />
      <Testimonial
        logoSrc={t('testimonial.02.logoSrc')}
        avatarSrc={t('testimonial.02.avatarSrc')}
        name={t('testimonial.02.name')}
        position={t('testimonial.02.position')}
        text={t('testimonial.02.text')}
      />
      <Testimonial
        logoSrc={t('testimonial.03.logoSrc')}
        avatarSrc={t('testimonial.03.avatarSrc')}
        name={t('testimonial.03.name')}
        position={t('testimonial.03.position')}
        text={t('testimonial.03.text')}
      />
    </Carousel>
  )
}
