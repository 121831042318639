import { useMemo } from 'react'
import useConnections from 'hooks/connections/useConnections.js'

export default function useIsConnectingOrConnected () {
  const { powensConnection } = useConnections()

  const isConnectingOrConnected = useMemo(() => {
    return !!powensConnection?.firstSyncingStartAt || !!powensConnection?.firstSyncingEndAt
  }, [powensConnection])

  return isConnectingOrConnected
}
