
import React from 'react'
import PropTypes from 'prop-types'

export default function CardHeader ({ title, badge, imgSrc, buttons }) {
  return (
    <div className='p-4 sm:p-6 border-b border-gray-200'>
      <div className='flex items-center justify-between flex-wrap sm:flex-nowrap'>
        <div className='flex flex-grow items-center'>
          {imgSrc && (<img className='h-auto w-10 rounded-sm mr-4' src={imgSrc} alt='Connector logo' />)}

          <span className='text-lg leading-6 font-medium text-gray-900'>
            {title}
          </span>

          {badge}
        </div>

        {buttons && (<div className='grid gap-4 grid-flow-col'>{buttons}</div>)}
      </div>
    </div>
  )
}

CardHeader.propTypes = {
  title: PropTypes.string.isRequired,
  badge: PropTypes.node,
  imgSrc: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.node)
}
