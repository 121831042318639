import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { useMutation } from '@apollo/client'
import { BATCH_UPDATE_TRANSACTIONS } from 'graphql/transactions'
import AggregationsActions from 'actions/AggregationsActions'

export default ({ onSuccessCallback } = {}) => {
  const dispatch = useDispatch()

  const onCompleted = useCallback((data) => {
    if (onSuccessCallback) onSuccessCallback()

    const actionsLeft = data?.batchUpdateTransactions?.actionsLeft
    dispatch(AggregationsActions.updateActionsLeft(actionsLeft))
  }, [dispatch, onSuccessCallback])

  return useMutation(BATCH_UPDATE_TRANSACTIONS, { onCompleted })
}
