import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from 'antd'
import RowCategoryBorder from 'components/categories/RowCategoryBorder'
import i18next from 'i18next'
import Badge from 'design/Badge'

import TransactionNoteInput from './TransactionNoteInput'
import TransactionIgnoredReason from 'enums/TransactionIgnoredReason.js'

const { t } = i18next
const { Paragraph } = Typography

const TransactionDescription = ({ transaction }) => {
  const textLabel = transaction.type === 'cashin' ? t('component.transactionDescription.defferedCardResetLabel') : t('component.transactionDescription.monthlyCardDebitLabel')
  const textTooltipLabel = transaction.type === 'cashin' ? t('component.transactionDescription.defferedCardResetTooltipLabel') : t('component.transactionDescription.monthlyCardDebitLabelTooltipLabel')

  if (transaction.ignoredReason === TransactionIgnoredReason.DEFERRED_CARD_RESET) {
    return (<Badge type='info' label={textLabel} tooltipLabel={textTooltipLabel} className='ml-2 filter-none opacity-100' />)
  }

  return (
    <div className='flex flex-row items-center justify-between'>
      <RowCategoryBorder categoryId={transaction.categoryId} />

      <Paragraph ellipsis={{ rows: 2, tooltip: true }} className='mb-0'>{transaction.description}</Paragraph>

      <TransactionNoteInput transaction={transaction} className='min-w-min ml-2 -mr-4' />
    </div>
  )
}

TransactionDescription.propTypes = {
  transaction: PropTypes.shape({
    description: PropTypes.string.isRequired,
    note: PropTypes.string,
    categoryId: PropTypes.string,
    ignoredReason: PropTypes.string,
    type: PropTypes.string
  })
}

export default TransactionDescription
