import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import ButtonLink from 'design/ButtonLink.js'
import { DocumentTextIcon } from '@heroicons/react/24/solid'
import DocumentViewerModal from '../DocumentViewerModal'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import Spinner from 'design/Spinner.js'
import useAccoutingDocumentUrl from 'hooks/useAccoutingDocumentUrl.js'

AccountingDocumentPreviewButton.propTypes = {
  accountingDocument: PropTypes.object
}

export default function AccountingDocumentPreviewButton ({ accountingDocument }) {
  const { t } = useTranslation()
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const openModal = useCallback(() => { setModalIsOpen(true) }, [setModalIsOpen])
  const hideModal = useCallback(() => { setModalIsOpen(false) }, [setModalIsOpen])

  const {
    hasDocument,
    documentUrl,
    showDocument,
    loading,
    title
  } = useAccoutingDocumentUrl({ accountingDocument, onShowDocument: openModal })

  if (!accountingDocument) return null
  if (!hasDocument) return null

  return (
    <>
      <Tooltip
        title={t('component.invoicePreviewButton.tooltip')}
        placement='left'
        className='flex items-center'
        zIndex={10} // Modal below is set to zIndex 20
        trigger={['hover', 'click']}
      >
        {(loading && (
          <Spinner className='w-5 h-5 text-primary' />
        )) || (
          <ButtonLink
            onClick={showDocument}
            className='ml-1'
            label={<DocumentTextIcon className={classNames('w-5 h-5 inline text-light-primary')} />}
          />
        )}
      </Tooltip>

      <DocumentViewerModal
        open={modalIsOpen}
        url={documentUrl}
        title={title}
        onClose={hideModal}
      />
    </>
  )
}
