import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Currency from 'components/Currency'
import CashflowSheetDefaultCell from './components/CashflowSheetCurrencyCell'
import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

CashflowSheetVatPaymentCell.propTypes = {
  sheetDate: PropTypes.object,
  value: PropTypes.object,
  realisedVsForecasted: PropTypes.bool
}

export default function CashflowSheetVatPaymentCell ({ sheetDate, value, realisedVsForecasted }) {
  const { t } = useTranslation()
  const { vatPayment, isRealised } = useMemo(() => {
    if (sheetDate.isPastMonth) {
      return {
        vatPayment: (value.realisedVatPayment || 0) * -1,
        isRealised: true
      }
    }

    return {
      vatPayment: (value.forecastedVatPayment || 0) * -1,
      isRealised: value.forecastedVatPayment === value.realisedVatPayment
    }
  }, [sheetDate, value.realisedVatPayment, value.forecastedVatPayment])

  const justify = useMemo(() => {
    if (sheetDate.isPastMonth && realisedVsForecasted) return 'center'
    if (sheetDate.isPastMonth || sheetDate.isFutureMonth) return 'end'
    return isRealised ? 'center' : 'end'
  }, [isRealised, realisedVsForecasted, sheetDate])

  const tooltipTitle = useMemo(() => {
    return isRealised ? t('dashboard.cashflowSheetVatPayment.realizedVatAmountTooltip') : null
  }, [isRealised, t])

  if (vatPayment) {
    return (
      <CashflowSheetDefaultCell justify={justify}>
        <Tooltip title={tooltipTitle}>
          <Currency amount={vatPayment} options={{ sign: true }} />
        </Tooltip>
      </CashflowSheetDefaultCell>
    )
  }

  return null
}
