const featureConfig = {
  emailPasswordSignInEnabled: ['DEFAULT'].includes(process.env.REACT_APP_TENANT) || process.env.NODE_ENV !== 'production',
  signUpEnabled: ['DEFAULT'].includes(process.env.REACT_APP_TENANT),
  resetPasswordEnabled: ['DEFAULT'].includes(process.env.REACT_APP_TENANT),
  sellsySsoEnabled: ['DEFAULT'].includes(process.env.REACT_APP_TENANT),
  quipuSsoEnabled: ['QUIPU'].includes(process.env.REACT_APP_TENANT),
  demoLinkEnabled: ['DEFAULT', 'QUIPU'].includes(process.env.REACT_APP_TENANT),
  powensConnectionEnabled: ['DEFAULT'].includes(process.env.REACT_APP_TENANT),
  inviteUsersEnabled: ['DEFAULT'].includes(process.env.REACT_APP_TENANT),
  manageIntegrationsEnabled: ['DEFAULT'].includes(process.env.REACT_APP_TENANT),
  vatBalanceEnabled: ['DEFAULT'].includes(process.env.REACT_APP_TENANT),
  vatDetailsEnabled: ['DEFAULT'].includes(process.env.REACT_APP_TENANT),
  invoicesEnabled: ['DEFAULT', 'QUIPU'].includes(process.env.REACT_APP_TENANT),
  importInvoicesEnabled: ['DEFAULT'].includes(process.env.REACT_APP_TENANT),
  helpCenterEnabled: ['DEFAULT', 'QUIPU'].includes(process.env.REACT_APP_TENANT),
  displayPlanEnabled: ['DEFAULT'].includes(process.env.REACT_APP_TENANT),
  logoutEnabled: ['DEFAULT'].includes(process.env.REACT_APP_TENANT),
  accountEditNameEnabled: ['DEFAULT'].includes(process.env.REACT_APP_TENANT),
  draftInvoicesEnabled: ['DEFAULT'].includes(process.env.REACT_APP_TENANT),
  batchReceiptsDownloadEnabled: ['DEFAULT', 'QUIPU'].includes(process.env.REACT_APP_TENANT),
  onboardingEnabled: ['DEFAULT'].includes(process.env.REACT_APP_TENANT),
  importTransactionsEnabled: ['DEFAULT'].includes(process.env.REACT_APP_TENANT)
}

export default featureConfig
