import { useCallback } from 'react'
import { useHistory } from 'react-router'

export default function usePushLocationWithJsonQueryParams (paramsKey = 'f') {
  const history = useHistory()

  return useCallback((pathname, newParams) => {
    const strParams = JSON.stringify(newParams || {})
    const hasParams = strParams !== '{}'

    history.push({
      pathname,
      search: hasParams ? `?${paramsKey}=${encodeURIComponent(utf8ToB64(strParams))}` : ''
    })
  }, [history, paramsKey])
}

// from https://developer.mozilla.org/fr/docs/Glossary/Base64
function utf8ToB64 (str) {
  return window.btoa(unescape(encodeURIComponent(str)))
}
