import { Tooltip } from 'antd'
import React from 'react'

import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline'
import AuthActions from 'actions/AuthActions'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

const LogoutButton = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  return (
    <div onClick={() => dispatch(AuthActions.signOut())} className='rc-sider-link cursor-pointer hover:opacity-50'>
      <Tooltip placement='right' title={t('component.logoutButton.logOut')}>
        <ArrowRightOnRectangleIcon className='rc-fire-icon h-5 w-5' />
      </Tooltip>
    </div>
  )
}

export default LogoutButton
