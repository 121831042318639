import { List } from 'antd'
import PropTypes from 'prop-types'
import React, { useContext, useMemo } from 'react'

import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline'
import ExpectedTransactionPreview from 'components/expected-transactions/ExpectedTransactionPreview'
import CashflowSheetContext from 'contexts/CashflowSheetContext'
import ButtonLink from 'design/ButtonLink'
import { useTranslation } from 'react-i18next'

CashflowSheetDrawerExpectedTransactionList.propTypes = {
  expectedTransactions: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.string,
  total: PropTypes.number,
  drawerParams: PropTypes.shape({
    visible: PropTypes.bool,
    rowAbsolutePath: PropTypes.string
  }),
  loading: PropTypes.bool,
  refetch: PropTypes.func,
  withExpectedDatePicker: PropTypes.bool
}

function CashflowSheetDrawerExpectedTransactionList ({
  type,
  expectedTransactions,
  total,
  loading,
  refetch,
  withExpectedDatePicker
}) {
  const { t } = useTranslation()
  const { refetchCashflowSheet } = useContext(CashflowSheetContext)

  const dataLength = useMemo(() => expectedTransactions?.length || 0, [expectedTransactions])

  const loadMoreButton = useMemo(() => {
    if (dataLength >= total) return null

    return (
      <div className='w-full p-2 flex justify-center'>
        <ButtonLink
          loading={loading}
          onClick={() => refetch({ limit: dataLength + 10 })}
          label={(
            <div className='flex flex-row items-center'>
              <EllipsisHorizontalIcon className='w-4 h-4 text-primary mr-2' />
              {t('shared.loadMore', { dataLength, total })}
            </div>
          )}
        />
      </div>
    )
  }, [dataLength, total, loading, t, refetch])

  return (
    <List
      loadMore={loadMoreButton}
      loading={loading}
      dataSource={expectedTransactions}
      locale={{ emptyText: t('dashboard.cashflowSheetDrawerExpectedTransactionList.noExpectedTransaction') }}
      renderItem={expectedTransaction => (
        <List.Item key={expectedTransaction.id} className='pt-0'>
          <ExpectedTransactionPreview
            type={type}
            expectedTransaction={expectedTransaction}
            withDetails
            withActions
            withExpectedDatePicker={withExpectedDatePicker}
            onCategorize={refetchCashflowSheet}
            onVatSelect={refetchCashflowSheet}
            onChangeExpectedDate={refetchCashflowSheet}
            onAction={refetchCashflowSheet}
          />
        </List.Item>
      )}
      split={false}
    />
  )
}

export default CashflowSheetDrawerExpectedTransactionList
