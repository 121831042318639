import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'

CashflowSheetDefaultCell.propTypes = {
  onClick: PropTypes.func,
  justify: PropTypes.oneOf(['start', 'center', 'between', 'end']),
  className: PropTypes.string,
  children: PropTypes.node
}

CashflowSheetDefaultCell.defaultProps = {
  justify: 'start'
}

export default function CashflowSheetDefaultCell ({
  onClick,
  justify,
  className,
  children,
  ...rest
}) {
  return (
    <div
      onClick={onClick}
      className={classNames(
        'text-xs w-full h-full flex flex-row items-center px-2',
        `justify-${justify}`,
        {
          // 'cursor-pointer hover:underline': !!onClick
          'cursor-pointer': !!onClick
        },
        className
      )}
      {...rest}
    >
      {children}
    </div>
  )
}

// className='transition-height h-1.5 hover:h-4'
