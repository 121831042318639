import { Badge, Layout, Menu, Typography } from 'antd'
import { get } from 'lodash'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router'

import ActionsLeftInfo from 'components/ActionsLeftInfo'
import CashInCharts from './cash-in/CashInCharts'
import CashInExpectedDocuments from './cash-in/CashInExpectedDocuments'
import CashInTransactions from './cash-in/CashInTransactions'
import CashInTransactionsToValidate from './cash-in/CashInTransactionsToValidate'
import CategorizationHelperContext from 'contexts/CategorizationHelperContext'
import useCategorizationHelper from 'hooks/useCategorizationHelper'
import useGetActionsLeft from 'hooks/useGetActionsLeft'
import { useTranslation } from 'react-i18next'
import colors from 'utils/colors'
import featureConfig from 'config/features.js'

const type = 'cashin'

export default function CashInScreen () {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const totalToValidate = useSelector(state => get(state, `aggregations.totalToValidate.${type}`, 0))

  const menuItems = useMemo(() => {
    const items = [{ key: '/cash-in/transactions', label: t('cashInScreen.transactions') }]

    if (featureConfig.invoicesEnabled) {
      items.push({ key: '/cash-in/invoices', label: t('cashInScreen.expectedDocuments') })
    }

    items.push({
      key: '/cash-in/validate',
      label: (
        <div>
          {t('cashInScreen.validationPending')} <Badge count={totalToValidate} offset={[0, -5]} className='ml-1' style={{ backgroundColor: colors.success }} />
        </div>
      )
    })

    items.push({ key: '/cash-in/charts', label: t('cashInScreen.analytics') })

    return items
  }, [t, totalToValidate])

  useGetActionsLeft()

  const categorizationHelper = useCategorizationHelper({ type })

  return (
    <Layout>
      <Layout.Header className='rc-header'>
        <Typography.Title level={4} className='rc-header-title'><span className='rc-highlight-text-cashin'>{t('shared.cashinPlural')}</span></Typography.Title>

        <Menu
          onSelect={({ key }) => { history.push(key) }}
          selectedKeys={[location.pathname]}
          mode='horizontal' className='rc-header-menu'
          items={menuItems}
        />
      </Layout.Header>

      <Layout.Content className='rc-content'>
        <Switch>
          <Route exact path='/cash-in/transactions'>
            <CategorizationHelperContext.Provider value={categorizationHelper}>
              <ActionsLeftInfo />
              <CashInTransactions />
            </CategorizationHelperContext.Provider>
          </Route>

          {featureConfig.invoicesEnabled && (
            <Route exact path='/cash-in/invoices'>
              <CashInExpectedDocuments />
            </Route>
          )}

          <Route exact path='/cash-in/validate'>
            <CategorizationHelperContext.Provider value={categorizationHelper}>
              <ActionsLeftInfo />
              <CashInTransactionsToValidate />
            </CategorizationHelperContext.Provider>
          </Route>
          <Route exact path='/cash-in/charts'>
            <CashInCharts />
          </Route>
          <Redirect to='/cash-in/transactions' />
        </Switch>
      </Layout.Content>

      {categorizationHelper.modal}
    </Layout>
  )
}
