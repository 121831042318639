import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import UserRoleBadge from './UserRoleBadge'
import { useTranslation } from 'react-i18next'

const roles = [
  'admin',
  'collaborator',
  'cfo',
  'accountant',
  'investor',
  'banker'
]

const UserRoleSelect = React.forwardRef(({ value, onChange, ...rest }, ref) => {
  const { t } = useTranslation()
  return (
    <Select
      ref={ref}
      placeholder={t('users.userRoleSelect.role')}
      value={value}
      onChange={onChange}
      {...rest}
    >
      {roles.map((role) => <Select.Option key={role} value={role}><UserRoleBadge role={role} /></Select.Option>)}
    </Select>
  )
})

UserRoleSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  user: PropTypes.shape({
    id: PropTypes.string,
    role: PropTypes.string
  })
}

export default UserRoleSelect
