
import React, { useCallback } from 'react'
import { Dropdown } from 'antd'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

SearchActions.propTypes = {
  withIgnored: PropTypes.bool,
  handleWithIgnoredChange: PropTypes.func,
  loading: PropTypes.bool,
  itemListIsMasculine: PropTypes.bool
}

function SearchActions ({
  withIgnored,
  handleWithIgnoredChange,
  loading,
  itemListIsMasculine
}) {
  const { t } = useTranslation()
  const context = itemListIsMasculine ? 'm' : 'f'

  const handleMenuClick = useCallback(({ key }) => {
    switch (key) {
      case 'withIgnored':
        handleWithIgnoredChange(!withIgnored)
        break
      default:
        console.warn(`${key} button not handled`)
    }
  }, [handleWithIgnoredChange, withIgnored])

  return (
    <Dropdown
      disabled={loading}
      placement='bottomLeft'
      menu={{
        onClick: handleMenuClick,
        items: [
          {
            key: 'withIgnored',
            label: (
              <div className='flex flex-row items-center'>
                {(!withIgnored && <EyeIcon className='w-4 h-4 mr-2 inline' />) || <EyeSlashIcon className='w-4 h-4 mr-2 inline' />}
                {!withIgnored ? t('component.searchActions.showIgnored', { context }) : t('component.searchActions.hideIgnored', { context })}
              </div>
            )
          }
        ]
      }}
    >
      <EllipsisVerticalIcon className='w-6 h-6 text-gray-400 hover:opacity-75 cursor-pointer mr-2' />
    </Dropdown>
  )
}

export default SearchActions
