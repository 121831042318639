import { useMutation } from '@apollo/client'
import { Button, Form } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { UPDATE_VAT_SETTINGS } from 'graphql/companies'
import useAuthenticatedContext from 'hooks/useAuthenticatedContext'
import useHandleFormValidationErrors from 'hooks/useHandleFormValidationErrors'
import VatPeriodSelect from './VatPeriodSelect'
// import VatDateRuleSelect from './VatDateRuleSelect'

const VatSettingsForm = () => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { company } = useAuthenticatedContext()
  const [values, setValues] = useState(company)

  useEffect(() => {
    setValues(company)
  }, [company])

  const handleValidationErrors = useHandleFormValidationErrors({ form })

  const [updateVatSettings, { loading }] = useMutation(UPDATE_VAT_SETTINGS, {
    onError: handleValidationErrors
  })

  const onFinish = useCallback((formValues) => {
    updateVatSettings({
      variables: { input: formValues }
    })
  }, [updateVatSettings])

  const disabled = useMemo(() => {
    return loading || (company.vatPeriod === values.vatPeriod)
    // return loading || ((company.vatDateRule === values.vatDateRule) && (company.vatPeriod === values.vatPeriod))
  }, [loading, company, values])

  return (
    <Form form={form} layout='horizontal' onFinish={onFinish} initialValues={values} onValuesChange={(_, values) => setValues(values)}>
      {/* <Form.Item name='vatDateRule' label='Votre TVA est exigible sur les' className='flex flex-row items-center' colon={false}>
        <VatDateRuleSelect className='rc-primary-select w-auto mb-2 -ml-1' bordered={false} dropdownMatchSelectWidth={false} />
      </Form.Item> */}

      <Form.Item name='vatPeriod' label={t('component.vatSettingsForm.yourVatPeriodIs')} className='flex flex-row items-center' colon={false}>
        <VatPeriodSelect className='rc-primary-select w-auto -ml-2' bordered={false} dropdownMatchSelectWidth={false} />
      </Form.Item>

      <Button
        htmlType='submit'
        type='primary'
        loading={loading}
        disabled={disabled}
      >
        {t('shared.save')}
      </Button>
    </Form>
  )
}

export default VatSettingsForm
