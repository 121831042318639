import { gql } from '@apollo/client'

export const LIST_RECURRING_PAYMENTS = gql`
query listRecurringPayments(
  $offset: Int
  $limit: Int!
  $search: String
  $stateFilter: RecurringPaymentStateFilterType
  $withIgnored: Boolean
) {
  listRecurringPayments(
    offset: $offset
    limit: $limit
    search: $search
    stateFilter: $stateFilter
    withIgnored: $withIgnored
  ) {
    total
    recurringPayments {
      id
      description
      thirdPartyName
      nextExpectedDate
      status
      categoryId
      cashType
      unitAmountExcludingVat
      unitAmount
      frequency
      ignored
      expectedTransactions {
        id
        description
        paymentDate
        expectedDate
        type
        expectedTransactionDetails {
          id
          type
          amount
          vatRate
        }
      }
    }
  }
}
`

export const CATEGORIZE_RECURRING_PAYMENTS = gql`
  mutation categorizeRecurringPayments($input: CategorizeRecurringPaymentsInput!) {
    categorizeRecurringPayments(input: $input) {
      recurringPayments {
        id,
        categoryId
      }
    }
  }
`
export const GET_RECURRING_PAYMENTS_COUNT = gql`
  query getRecurringPaymentsCount {
    getRecurringPaymentsCount
  }
`

export const IGNORE_RECURRING_PAYMENTS = gql`
  mutation ignoreRecurringPayments($input: IgnoreRecurringPaymentsInput!) {
    ignoreRecurringPayments(input: $input) {
      id
      ignored
    }
  }
`
