import React from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'

// order is important here, tailwind.css should be loaded before app.less
import 'styles/tailwind.css'
import 'styles/app.less'

import featureConfig from 'config/features.js'
import AutoSignInScreen from 'containers/AutoSignInScreen'
import CashInScreen from 'containers/CashInScreen'
import CashOutScreen from 'containers/CashOutScreen'
import CategoriesScreen from 'containers/CategoriesScreen'
import DashboardScreen from 'containers/DashboardScreen'
import NotFoundScreen from 'containers/errors/NotFoundScreen'
import ForbiddenScreen from 'containers/ForbiddenScreen'
import ForgotPasswordScreen from 'containers/ForgotPasswordScreen'
import HomeScreen from 'containers/HomeScreen'
import IntegrationsScreen from 'containers/IntegrationsScreen'
import NewConnectionScreen from 'containers/NewConnectionScreen'
import OnboardingScreen from 'containers/OnboardingScreen'
import ReloadScreen from 'containers/ReloadScreen'
import ResetPasswordScreen from 'containers/ResetPasswordScreen'
import SellsyOAuthScreen from 'containers/SellsyOAuthScreen'
import SellsySSOScreen from 'containers/SellsySSOScreen'
import SetPasswordScreen from 'containers/SetPasswordScreen'
import SignInScreen from 'containers/SignInScreen'
import SignUpScreen from 'containers/SignUpScreen'
import SubscribeScreen from 'containers/SubscribeScreen'
import TestScreen from 'containers/TestScreen'
import UsersScreen from 'containers/UsersScreen'
import VatScreen from 'containers/VatScreen'
import LayoutRoute from './LayoutRoute'
import QuipuSSOScreen from 'containers/QuipuSSOScreen.js'
import StripeBillingRedirectionScreen from 'containers/StripeBillingRedirectionScreen.js'

export default function () {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Switch>
        <LayoutRoute exact path='/' component={HomeScreen} />
        <LayoutRoute exact path='/reload' component={ReloadScreen} />
        <LayoutRoute exact path='/auto-signin' appBarType='none' component={AutoSignInScreen} />
        <LayoutRoute exact path='/signin' appBarType='none' component={SignInScreen} />

        {featureConfig.signUpEnabled && (
          <LayoutRoute exact path='/signup' appBarType='none' component={SignUpScreen} />
        )}

        {featureConfig.resetPasswordEnabled && (
          <LayoutRoute exact path='/set-password' appBarType='none' component={SetPasswordScreen} />
        )}
        {featureConfig.resetPasswordEnabled && (
          <LayoutRoute exact path='/forgot-password' appBarType='none' component={ForgotPasswordScreen} />
        )}
        {featureConfig.resetPasswordEnabled && (
          <LayoutRoute exact path='/reset-password' appBarType='none' component={ResetPasswordScreen} />
        )}

        {featureConfig.quipuSsoEnabled && (
          <LayoutRoute exact path='/quipu/sso' appBarType='none' component={QuipuSSOScreen} />
        )}

        {featureConfig.sellsySsoEnabled && (
          <LayoutRoute exact path='/sellsy/sso' appBarType='none' component={SellsySSOScreen} />
        )}
        {featureConfig.sellsySsoEnabled && (
          <LayoutRoute exact path='/sellsy/oauth' appBarType='none' component={SellsyOAuthScreen} />
        )}

        <LayoutRoute requireAuth requireOnboarding={false} exact path={['/onboarding/', '/onboarding/:step']} appBarType='light' component={OnboardingScreen} />

        {featureConfig.vatBalanceEnabled && (
          <LayoutRoute requireAuth path='/dashboard/vat' component={VatScreen} />
        )}

        <LayoutRoute requireAuth path='/dashboard' component={DashboardScreen} />
        <LayoutRoute requireAuth path='/cash-in' component={CashInScreen} />
        <LayoutRoute requireAuth path='/cash-out' component={CashOutScreen} />

        {featureConfig.manageIntegrationsEnabled && (
          <LayoutRoute requireAuth path='/integrations' component={IntegrationsScreen} />
        )}

        <LayoutRoute requireAuth path='/categories' component={CategoriesScreen} />
        <LayoutRoute requireAuth path='/users' component={UsersScreen} />
        <LayoutRoute requireAuth path='/new-connection' component={NewConnectionScreen} />
        <LayoutRoute requireAuth requireOnboarding={false} exact path='/subscribe' appBarType='light' component={SubscribeScreen} />
        <LayoutRoute requireAuth requireOnboarding={false} exact path='/billing/stripe-portal-redirect' appBarType='light' component={StripeBillingRedirectionScreen} />
        <LayoutRoute requireAuth requireOnboarding={false} requireNotBlocked={false} exact path='/forbidden' appBarType='light' component={ForbiddenScreen} />
        <LayoutRoute exact path='/test' appBarType='none' component={TestScreen} />
        <LayoutRoute appBarType='none' component={NotFoundScreen} />
      </Switch>
    </Router>
  )
}
