import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import { InfoCircleFilled } from '@ant-design/icons'
import Currency from 'components/Currency'
import { c } from 'utils/currencies'
import { sum, sumBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import featureConfig from 'config/features.js'
import AccountingDocumentType from 'enums/AccountingDocumentType.js'

const AccountingDocumentAmountPreview = ({ accountingDocument }) => {
  const { t } = useTranslation()

  const detailSum = useMemo(() => {
    const expTxnSums = accountingDocument.expectedTransactions.map((expTxn) => {
      return sumBy(expTxn.expectedTransactionDetails, 'amount')
    })

    return parseFloat(sum(expTxnSums)?.toFixed(2))
  }, [accountingDocument])

  const sumIsNotAmount = useMemo(() => {
    return detailSum !== accountingDocument.amount
  }, [accountingDocument.amount, detailSum])

  return (
    <div className='flex flex-row justify-end items-center'>
      {sumIsNotAmount && (
        <Tooltip title={getTooltipText({ accountingDocument, t })}>
          <InfoCircleFilled className='mr-1' />
        </Tooltip>
      )}
      {featureConfig.vatDetailsEnabled
        ? (
          <Tooltip title={`${c(accountingDocument.amountExcludingVat)} HT`}>
            <Currency amount={accountingDocument.amount} strong />
          </Tooltip>
        ) : (
          <Currency amount={accountingDocument.amount} strong />
        )}
    </div>
  )
}

function getTooltipText ({ accountingDocument, t }) {
  const labels = {
    [AccountingDocumentType.INVOICE]: t('component.invoiceAmountPreview.tooltip'),
    [AccountingDocumentType.QUOTE]: t('component.quoteAmountPreview.quote'),
    [AccountingDocumentType.ORDER]: t('component.AccountingDocumentAmountPreview.order')
  }

  return labels[accountingDocument.type]
}

AccountingDocumentAmountPreview.propTypes = {
  accountingDocument: PropTypes.object
}

export default AccountingDocumentAmountPreview
