import React from 'react'
import PropTypes from 'prop-types'
import { DownOutlined } from '@ant-design/icons'
import { Select } from 'antd'

import SearchInput from 'design/SearchInput.js'
import { backgroundTextColors } from 'utils/colors.js'
import cx from 'classnames'
import SearchActions from 'components/search/SearchActions.js'

const RecurringPaymentSearchInput = ({
  onSearchChanged,
  onStateFilterChanged,
  stateFilter,
  onWithIgnoredChanged,
  loading,
  withIgnored
}) => {
  const states = {
    ALL: { label: 'Tous' },
    ONGOING: { label: 'En cours', className: backgroundTextColors.primary },
    FINISHED: { label: 'Terminé', className: backgroundTextColors.success }
  }

  const StateFilterSelector = (
    <Select
      suffixIcon={<DownOutlined style={{ pointerEvents: 'none' }} />}
      onChange={onStateFilterChanged}
      value={stateFilter}
      className={cx('w-[105px] mr-2 rc-invoice-state-filter rounded-[6px] transition-none rc-select-no-border',
        states[stateFilter]?.className)}
      dropdownStyle={{ padding: 0 }}
    >
      {Object.entries(states).map(([state, stateParams]) => (
        <Select.Option
          key={state}
          value={state}
          className={stateParams.className}
        >
          {stateParams.label}
        </Select.Option>
      ))}
    </Select>
  )

  return (
    <div className='flex flex-row items-center'>
      <SearchInput left={StateFilterSelector} onSearchChanged={onSearchChanged} />
      <SearchActions withIgnored={withIgnored} handleWithIgnoredChange={onWithIgnoredChanged} loading={loading} itemListIsMasculine />
    </div>
  )
}

RecurringPaymentSearchInput.propTypes = {
  onSearchChanged: PropTypes.func,
  onStateFilterChanged: PropTypes.func,
  stateFilter: PropTypes.string,
  onWithIgnoredChanged: PropTypes.func,
  loading: PropTypes.bool,
  withIgnored: PropTypes.bool
}

export default RecurringPaymentSearchInput
