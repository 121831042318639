import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Button } from 'antd'

import CurrencyInput from 'components/CurrencyInput'
import useHandleFormValidationErrors from 'hooks/useHandleFormValidationErrors'
import { useMutation } from '@apollo/client'
import { SET_WCR } from 'graphql/companies'
import useAuthenticatedContext from 'hooks/useAuthenticatedContext'
import { useTranslation } from 'react-i18next'

const WcrForm = ({ onCompleted }) => {
  const [form] = Form.useForm()
  const { company } = useAuthenticatedContext()
  const [values, setValues] = useState(company)
  const { t } = useTranslation()

  useEffect(() => {
    setValues(company)
  }, [company])

  const handleValidationErrors = useHandleFormValidationErrors({ form })

  const [setWcr, { loading }] = useMutation(SET_WCR, {
    onError: handleValidationErrors,
    onCompleted
  })

  const onFinish = useCallback((formValues) => {
    setWcr({
      variables: { wcr: formValues.wcr }
    })
  }, [setWcr])

  const disabled = useMemo(() => {
    return loading || company.wcr === values.wcr
  }, [loading, company, values])

  return (
    <Form form={form} layout='inline' onFinish={onFinish} requiredMark='optional' initialValues={values} onValuesChange={(_, values) => setValues(values)}>
      <Form.Item name='wcr'>
        <CurrencyInput min={undefined} className='w-36' />
      </Form.Item>

      <Form.Item>
        <Button
          htmlType='submit'
          type='primary'
          loading={loading}
          disabled={disabled}
        >
          {t('shared.save')}
        </Button>
      </Form.Item>
    </Form>
  )
}

WcrForm.propTypes = {
  onCompleted: PropTypes.func
}

export default WcrForm
