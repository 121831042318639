import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import useConnections from 'hooks/connections/useConnections.js'
import { Link } from 'react-router-dom'

const RecurringPaymentNoSyncPanel = () => {
  const { t } = useTranslation()
  const { sellsyConnection } = useConnections()

  return (

    <div className='overflow-x-visible flex flex-row justify-center items-start'>
      <div className='bg-white rounded-lg w-full p-12 h-auto content-center flex items-center justify-center'>
        <div className='max-w-2xl'>
          <h3 className='text-xl leading-6 font-medium text-gray-900'>
            {t('recurringPayment.RecurringPaymentNoSyncScreen.title')}
          </h3>
          <div className='mt-2 max-w-4xl text-gray-500'>
            <Trans
              i18nKey='recurringPayment.RecurringPaymentNoSyncScreen.message'
              components={{
                Link: <Link to={`/integrations/${sellsyConnection?.externalSource.toLowerCase()}`} />
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

RecurringPaymentNoSyncPanel.propTypes = {

}

export default RecurringPaymentNoSyncPanel
