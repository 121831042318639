import resourcesActions from 'actions/ResourcesActions'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import QuoteSearchInput from './QuoteSearchInput.js'
import { useQuery } from '@apollo/client'
import usePagination from 'hooks/pagination/usePagination.js'
import { LIST_QUOTES } from 'graphql/quotes.js'
import QuoteTable from './QuoteTable.js'
import Button from 'design/Button.js'
import useChangeSyncQuote from 'hooks/useChangeSyncQuote.js'
import useConnections from 'hooks/connections/useConnections.js'
import useListAndPollConnections from 'hooks/useListAndPollConnections'
import OpenCreateQuoteManuallyDrawerButton from './OpenCreateQuoteManuallyDrawerButton'
import useGetQuotesCount from 'hooks/quotes/useGetQuotesCount.js'
import ExpectedDocumentsConnectorList from 'components/connectors/ExpectedDocumentsConnectorList.js'

const QuoteList = ({ cashType }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [search, setSearch] = useState(null)
  const [stateFilter, setStateFilter] = useState('OPEN')
  const [withIgnored, setWithIgnored] = useState(true)
  const { pagination, page, pageSize, updatePaginationTotal, resetPagination } = usePagination()
  const { sellsyConnection } = useConnections()
  const { refetchQuotesCount, quotesCount } = useGetQuotesCount()

  useEffect(() => {
    dispatch(resourcesActions.READ_CATEGORIES(cashType))
  }, [dispatch, cashType])

  const onSearchChanged = useCallback((text) => {
    setSearch(text)
    resetPagination()
  }, [setSearch, resetPagination])

  const onStateFilterChanged = useCallback((value) => {
    setStateFilter(value)
    resetPagination()
  }, [setStateFilter, resetPagination])

  const onWithIgnoredChanged = useCallback((value) => {
    setWithIgnored(value)
    resetPagination()
  }, [setWithIgnored, resetPagination])

  const { loading, data, refetch } = useQuery(LIST_QUOTES, {
    variables: {
      offset: (page - 1) * pageSize,
      limit: pageSize,
      search,
      stateFilter,
      withIgnored
    },
    onCompleted: (data) => { updatePaginationTotal(data?.allQuotes?.total) }
  })

  const { loading: loadingConnections, startPolling } = useListAndPollConnections({
    onEndPolling: () => {
      refetch()
    }
  })

  const onChangeSyncQuote = useCallback(() => {
    startPolling(2000)
  }, [startPolling])

  const refetchQuotes = useCallback(() => {
    refetch()
    refetchQuotesCount()
  }, [refetchQuotesCount, refetch])

  const [changeSyncQuote] = useChangeSyncQuote(sellsyConnection, { onCompleted: onChangeSyncQuote })

  return (
    <div>
      <div className='flex flex-row items-center mb-3 flex-wrap justify-end'>
        <QuoteSearchInput
          loading={false}
          onSearchChanged={onSearchChanged}
          onStateFilterChanged={onStateFilterChanged}
          onWithIgnoredChanged={onWithIgnoredChanged}
        />
        <div className='grow flex flex-row items-center mb-3 flex-wrap justify-end'>
          {((sellsyConnection && !sellsyConnection.config.hasSyncQuote) || loadingConnections) &&
          (
            <Button
              className='mr-2'
              onClick={() => { changeSyncQuote(true) }}
              loading={loadingConnections}
              label={t('quote.SyncQuoteButton.title')}
            />
          )}
          {(sellsyConnection && sellsyConnection.config.hasSyncQuote) &&
            <ExpectedDocumentsConnectorList type={cashType} refetchInvoices={refetchQuotes} />}
          <OpenCreateQuoteManuallyDrawerButton type={cashType} onSuccess={refetchQuotes} />
        </div>
      </div>

      <QuoteTable
        loading={loading}
        dataSource={data?.allQuotes?.quotes}
        pagination={pagination}
        refetchQuotes={refetchQuotes}
        quotesCount={quotesCount}
      />
    </div>
  )
}

QuoteList.propTypes = {
  cashType: PropTypes.oneOf(['cashin'])
}

export default QuoteList
