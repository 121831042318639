import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'

import ExpectedDocumentsConnectorList from 'components/connectors/ExpectedDocumentsConnectorList'
import Button from 'design/Button.js'
import useConnections from 'hooks/connections/useConnections.js'
import useListAndPollConnections from 'hooks/useListAndPollConnections'
import OrderTable from './OrderTable.js'
import useChangeSyncOrder from 'hooks/useChangeSyncOrder.js'
import { LIST_ORDERS } from 'graphql/orders.js'
import usePagination from 'hooks/pagination/usePagination.js'
import OrderSearchInput from './OrderSearchInput.js'

const OrderList = ({ cashType }) => {
  const { t } = useTranslation()

  const { sellsyConnection } = useConnections()
  const { pagination, page, pageSize, updatePaginationTotal, resetPagination } = usePagination()
  const [search, setSearch] = useState(null)
  const [stateFilter, setStateFilter] = useState('OPEN')
  const [withIgnored, setWithIgnored] = useState(true)

  const onSearchChanged = useCallback((text) => {
    setSearch(text)
    resetPagination()
  }, [setSearch, resetPagination])

  const onStateFilterChanged = useCallback((value) => {
    setStateFilter(value)
    resetPagination()
  }, [setStateFilter, resetPagination])

  const onWithIgnoredChanged = useCallback((value) => {
    setWithIgnored(value)
    resetPagination()
  }, [setWithIgnored, resetPagination])

  const { loading, data, refetch } = useQuery(LIST_ORDERS, {
    variables: {
      offset: (page - 1) * pageSize,
      limit: pageSize,
      search,
      stateFilter,
      withIgnored
    },
    onCompleted: (data) => { updatePaginationTotal(data?.listOrders?.total) }
  })

  const { loading: loadingConnections, startPolling } = useListAndPollConnections({
    onEndPolling: () => {
      refetch()
    }
  })

  const onChangeSyncOrder = useCallback(() => {
    startPolling(2000)
  }, [startPolling])

  const refetchOrders = useCallback(() => {
    refetch()
  }, [refetch])

  const [changeSyncOrder] = useChangeSyncOrder(sellsyConnection, { onCompleted: onChangeSyncOrder })

  return (
    <div>
      <div className='flex flex-row items-center mb-3 flex-wrap justify-end'>
        <OrderSearchInput
          loading={false}
          onSearchChanged={onSearchChanged}
          onStateFilterChanged={onStateFilterChanged}
          onWithIgnoredChanged={onWithIgnoredChanged}
        />
        <div className='grow flex flex-row items-center mb-3 flex-wrap justify-end'>
          {(!sellsyConnection.config?.hasSyncOrder || loadingConnections) &&
            <Button
              className='mr-2'
              onClick={() => { changeSyncOrder(true) }}
              loading={loadingConnections}
              label={t('order.OrderList.syncButtton.label')}
            />}
          {sellsyConnection.config?.hasSyncOrder && <ExpectedDocumentsConnectorList type={cashType} refetchInvoices={refetch} />}
        </div>
      </div>
      <OrderTable
        loading={loading}
        dataSource={data?.listOrders?.orders}
        pagination={pagination}
        refetchOrders={refetchOrders}
        hasSyncedOrderOnce={sellsyConnection?.hasSyncedOrderOnce}
      />
    </div>
  )
}

OrderList.propTypes = {
  cashType: PropTypes.oneOf(['cashin'])
}

export default OrderList
