import React, { useCallback, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { get } from 'lodash'

import { LIST_USERS } from 'graphql/users'
import PageLoader from 'design/PageLoader'
import Button from 'design/Button'
import UserActions from './UserActions'

import { sourceLogos } from 'utils/connections'
import { EnvelopeIcon } from '@heroicons/react/24/outline'
import { message, Tooltip } from 'antd'
import InviteUserForm from './InviteUserForm'
import ChangeUserRole from './ChangeUserRole'
import useAuthenticatedContext from 'hooks/useAuthenticatedContext'
import { useTranslation } from 'react-i18next'
import featureConfig from 'config/features.js'

const ListUsers = () => {
  const { t } = useTranslation()
  const [inviteUserFormVisible, setInviteUserFormVisible] = useState(false)
  const { company: { ownerId } } = useAuthenticatedContext()

  const { loading, data, refetch } = useQuery(LIST_USERS, {
    // Will set loading to true while refetching
    notifyOnNetworkStatusChange: true
  })
  const users = useMemo(() => {
    return get(data, 'listUsers', [])
  }, [data])

  const onInviteCompleted = useCallback(() => {
    message.success({ content: t('users.listUsers.userReceivedAnInvitationMail'), duration: 15 })
    setInviteUserFormVisible(false)
    refetch()
  }, [t, refetch])

  const onDelete = useCallback(() => {
    refetch()
  }, [refetch])

  return (
    <div className='w-full'>
      <div className='w-full flex flex-col space-y-4'>
        {users.map(user => (
          <div key={user.id} className='w-full flex flex-row items-center space-x-4'>
            <div className='w-4/12  font-bold flex flex-row items-center'>
              <div className='min-w-max w-5 mr-2'>
                {(user.sellsyUserId && (
                  <Tooltip
                    placement='left'
                    title={t('users.listUsers.userConnectedWithSellsyAccount')}
                  >
                    <img className='w-5' src={sourceLogos.SELLSY} alt='Sellsy logo' />
                  </Tooltip>
                )) || (
                  <Tooltip
                    placement='left'
                    title={t('users.listUsers.userInvitedByEmail')}
                  >
                    <EnvelopeIcon className='w-5 h-5 text-primary' />
                  </Tooltip>
                )}
              </div>
              <div className='flex-grow truncate'>
                {user.firstName} {user.lastName}
              </div>
            </div>

            <div className='w-5/12 truncate flex flex-row items-center'>
              {user.email}
            </div>
            <div className='w-3/12 flex flex-row justify-between items-center'>
              <ChangeUserRole user={user} ownerId={ownerId} />

              <UserActions user={user} ownerId={ownerId} onDelete={onDelete} />
            </div>
          </div>
        ))}
      </div>

      {(loading && (
        <div className='w-full flex justify-center pt-4'>
          <PageLoader />
        </div>
      ))}

      {inviteUserFormVisible && (
        <div className='w-full mt-4'>
          <InviteUserForm
            onCompleted={onInviteCompleted}
            onCancel={() => setInviteUserFormVisible(false)}
          />
        </div>
      )}

      {featureConfig.inviteUsersEnabled && !inviteUserFormVisible && (
        <div className='w-full mt-8 flex flex-row justify-end'>
          <Button
            label={t('users.listUsers.inviteAUser')}
            primary
            onClick={() => setInviteUserFormVisible(true)}
          />
        </div>
      )}
    </div>
  )
}

export default ListUsers
