import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Tooltip, Dropdown } from 'antd'
import { useMutation } from '@apollo/client'

import { MARK_EXPECTED_TRANSACTIONS_AS_INVOICED, MARK_EXPECTED_TRANSACTIONS_AS_PAID } from 'graphql/expected-transactions'
import { BanknotesIcon, TrashIcon } from '@heroicons/react/24/solid'
import { BanknotesIcon as BanknotesIconOutline } from '@heroicons/react/24/outline'
import useDeleteExpectedTransaction from 'hooks/expected-transactions/useDeleteExpectedTransaction'
import ActionsIcon from 'design/ActionsIcon'
import { useTranslation } from 'react-i18next'

ExpectedTransactionActions.propTypes = {
  accountingDocument: PropTypes.object,
  expectedTransaction: PropTypes.object,
  hiddenDeleteAction: PropTypes.bool,
  onCompleted: PropTypes.func
}

export default function ExpectedTransactionActions ({ accountingDocument, expectedTransaction, onCompleted = () => {}, hiddenDeleteAction = false }) {
  const { t } = useTranslation()

  const { deleteExpectedTransaction, loading: deleteLoading, disabled: deleteDisabled } = useDeleteExpectedTransaction({ accountingDocument, expectedTransaction })

  const [markExpectedTransactionAsPaid, { loading: markAsPaidLoading }] = useMutation(MARK_EXPECTED_TRANSACTIONS_AS_PAID, {
    variables: { input: { expectedTransactionIds: [expectedTransaction.id], cancel: !!expectedTransaction.paymentDate } },
    onCompleted: () => onCompleted()
  })

  const [markExpectedTransactionAsInvoiced, { loading: markAsInvoicedLoading }] = useMutation(MARK_EXPECTED_TRANSACTIONS_AS_INVOICED, {
    variables: { input: { expectedTransactionIds: [expectedTransaction.id], cancel: !!expectedTransaction.paymentDate } },
    onCompleted: () => onCompleted()
  })

  const handleMenuClick = useCallback(({ key }) => {
    switch (key) {
      case 'delete':
        deleteExpectedTransaction()
        break
      case 'markAsPaid':
        markExpectedTransactionAsPaid()
        break
      case 'markAsInvoiced':
        markExpectedTransactionAsInvoiced()
        break
      default:
        console.warn(`${key} button not handled`)
    }
  }, [deleteExpectedTransaction, markExpectedTransactionAsPaid, markExpectedTransactionAsInvoiced])

  const loading = useMemo(() => (deleteLoading || markAsPaidLoading || markAsInvoicedLoading), [deleteLoading, markAsPaidLoading, markAsInvoicedLoading])

  const markAsPaidAction =
    {
      key: 'markAsPaid',
      label: (
        <Tooltip
          title={t('component.expectedTransactionActions.markAsPaidTooltip')}
          placement='left'
          className='flex items-center'
        >
          {expectedTransaction.paymentDate ? <BanknotesIconOutline className='w-4 h-4 mr-2 inline' /> : <BanknotesIcon className='w-4 h-4 mr-2 inline' />}
          {expectedTransaction.paymentDate ? t('component.expectedTransactionActions.unmarkAsPaid') : t('component.expectedTransactionActions.markAsPaid')}
        </Tooltip>
      )
    }

  const markAsInvoicedAction =
  {
    key: 'markAsInvoiced',
    label: (
      <Tooltip
        title={t('component.quoteActions.invoicedExpectedTransactionNotTakenIntoAccountAnymore')}
        placement='left'
        className='flex items-center'
      >
        {expectedTransaction.paymentDate ? <BanknotesIconOutline className='w-4 h-4 mr-2 inline' /> : <BanknotesIcon className='w-4 h-4 mr-2 inline' />}
        {expectedTransaction.paymentDate ? t('component.expectedTransactionActions.unmarkAsInvoiced') : t('component.expectedTransactionActions.markAsInvoiced')}
      </Tooltip>
    )
  }

  return (
    <div className='flex flex-row items-center justify-between'>
      <Dropdown
        disabled={loading}
        placement='bottomRight'
        menu={{
          onClick: handleMenuClick,
          items: [
            expectedTransaction.parentType === 'INVOICE' ? markAsPaidAction : markAsInvoicedAction,
            hiddenDeleteAction ? null : {
              key: 'delete',
              disabled: deleteDisabled,
              label: (
                <div className='flex items-center'>
                  <TrashIcon className='w-4 h-4 mr-2 inline' />
                  {t('component.expectedTransactionActions.deleteExpectedTransaction')}
                </div>
              )
            }
          ]
        }}
      >
        <ActionsIcon loading={loading} />
      </Dropdown>
    </div>
  )
}
