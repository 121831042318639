import { sumBy } from 'lodash'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import classNames from 'classnames'
import Currency from 'components/Currency'
import ExpectedTransactionDetailCategorySelect from 'components/expected-transaction-details/ExpectedTransactionDetailCategorySelect'
import ExpectedTransactionDetailVatRateSelect from 'components/expected-transaction-details/ExpectedTransactionDetailVatRateSelect'
import ExpectedTransactionExpectedDatePicker from 'components/expected-transactions/ExpectedTransactionExpectedDatePicker'
import Spinner from 'design/Spinner'
import { useTranslation } from 'react-i18next'
import ExpectedTransactionPaymentBadge from './ExpectedTransactionPaymentBadge'
import ExpectedTransactionPreviewActions from 'components/expected-transactions/ExpectedTransactionPreviewActions'
import AccountingDocumentPreviewButton from 'components/accounting-documents/AccountingDocumentPreviewButton.js'
import InvoiceStatusBadge from 'components/invoices/InvoiceStatusBadge.js'
import QuoteStatusBadge from 'components/quotes/QuoteStatusBadge.js'
import RecurringPaymentStatusBadge from 'components/recurring-payments/RecurringPaymentStatusBadge.js'
import CategoryTagFromId from 'components/categories/CategoryTagFromId.js'
import VatLabel from 'components/vat/VatLabel.js'
import OrderStatusBadge from 'components/orders/OrderStatusBadge.js'

ExpectedTransactionPreview.propTypes = {
  type: PropTypes.string.isRequired,
  expectedTransaction: PropTypes.shape({
    id: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    paymentDate: PropTypes.string,
    expectedDate: PropTypes.string.isRequired,
    expectedTransactionDetails: PropTypes.arrayOf(PropTypes.shape({
      amount: PropTypes.number.isRequired
    })),
    accountingDocument: PropTypes.shape({
      thirdPartyName: PropTypes.string,
      documentNumber: PropTypes.string.isRequired,
      status: PropTypes.string,
      type: PropTypes.string
    })
  }).isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
  withDetails: PropTypes.bool,
  withExpectedDatePicker: PropTypes.bool,
  withActions: PropTypes.bool,
  onCategorize: PropTypes.func,
  onVatSelect: PropTypes.func,
  onChangeExpectedDate: PropTypes.func,
  onAction: PropTypes.func
}

export default function ExpectedTransactionPreview ({ type, expectedTransaction, className, loading, withDetails, withExpectedDatePicker, withActions, onCategorize, onVatSelect, onChangeExpectedDate, onAction, ...rest }) {
  const { t } = useTranslation()
  const formattedExpectedDate = useMemo(() => {
    if (!expectedTransaction.expectedDate) return ''

    return moment(expectedTransaction.expectedDate).format('DD/MM/YYYY')
  }, [expectedTransaction.expectedDate])

  const details = useMemo(() => {
    return expectedTransaction.expectedTransactionDetails || []
  }, [expectedTransaction.expectedTransactionDetails])

  const totalAmount = useMemo(() => sumBy(expectedTransaction.expectedTransactionDetails, 'amount'), [expectedTransaction.expectedTransactionDetails])
  const statusBadge = useMemo(() => {
    if (!expectedTransaction.accountingDocument?.status) return null

    switch (expectedTransaction?.accountingDocument?.type) {
      case 'INVOICE':
        return <InvoiceStatusBadge className='inline-flex text-center ml-2 mb-1' invoice={expectedTransaction?.accountingDocument} />
      case 'QUOTE':
        return <QuoteStatusBadge className='inline-flex text-center ml-2 mb-1' quote={expectedTransaction?.accountingDocument} />
      case 'RECURRING_PAYMENT':
        return <RecurringPaymentStatusBadge className='inline-flex text-center ml-2 mb-1' recurringPayment={expectedTransaction?.accountingDocument} />
      case 'ORDER':
        return <OrderStatusBadge className='inline-flex text-center ml-2 mb-1' order={expectedTransaction?.accountingDocument} />
      default:
        return null
    }
  }, [expectedTransaction?.accountingDocument])

  const isNotRecurringPayment = expectedTransaction?.accountingDocument?.type !== 'RECURRING_PAYMENT'
  const isEditable = isNotRecurringPayment
  const showExpectedDatePicker = withExpectedDatePicker && isNotRecurringPayment

  return (
    <div
      className={
        classNames('relative w-full border-2 border-gray-400 rounded-md flex flex-row items-center', className)
      }
      {...rest}
    >
      <div className='flex-grow flex flex-col justify-between p-2 text-xs space-y-2'>
        <div className='flex flex-row justify-between items-center'>
          <div className='flex flex-col'>

            <div className='font-bold text-sm'>
              {t('component.expectedTransactionPreview.label', { context: expectedTransaction?.accountingDocument?.type })}
              {statusBadge}
            </div>

            <div className='w-full flex flex-row items-baseline justify-between space-x-4'>
              <div className='space-x-2 mt-1'>
                <span>{expectedTransaction?.accountingDocument?.documentNumber}</span>
                <span className='text-gray-400'>/</span>
                <span>{expectedTransaction?.description}</span>
              </div>
            </div>
          </div>
          {isEditable && (
            <div className='flex flex-row'>
              {expectedTransaction?.accountingDocument && <AccountingDocumentPreviewButton accountingDocument={expectedTransaction?.accountingDocument} />}
              {withActions && (<ExpectedTransactionPreviewActions expectedTransaction={expectedTransaction} onCompleted={onAction} />)}
            </div>
          )}
        </div>

        <div className='flex flex-row justify-between space-x-4'>
          <div className='flex flex-col w-1/3'>
            <div className='font-semibold text-xs'>{type === 'cashin' ? t('component.expectedTransactionPreview.client') : t('component.expectedTransactionPreview.supplier')}</div>

            {(!!expectedTransaction?.accountingDocument?.thirdPartyName && (
              <div className='mt-1'>{expectedTransaction?.accountingDocument?.thirdPartyName}</div>
            )) || (
              <div className='text-gray-400 mt-1'>-</div>
            )}
          </div>

          <div className='w-1/3 flex flex-row justify-center'>
            <div className='flex flex-col'>
              <div className='font-semibold'>{t('shared.expectedPayment')}</div>

              {
                (!!expectedTransaction.paymentDate && (
                  <ExpectedTransactionPaymentBadge expectedTransaction={expectedTransaction} />
                )) ||
                (showExpectedDatePicker && (
                  <ExpectedTransactionExpectedDatePicker expectedTransaction={expectedTransaction} onCompleted={onChangeExpectedDate} className='rc-input-text-xs' />
                )) ||
                (
                  <div className='mt-1'>{formattedExpectedDate}</div>
                )
              }
            </div>
          </div>

          <div className='flex flex-col w-1/3 items-end'>
            <div className='font-semibold'>{t('component.expectedTransactionPreview.amountInclTax')}</div>

            <div className='mt-1'><Currency amount={totalAmount} /></div>
          </div>
        </div>

        {withDetails && (
          <div className='w-full flex flex-col items-end'>
            <div className='font-semibold'>{t('component.expectedTransactionPreview.details')}</div>

            <div className='flex flex-col justify-end divide-y divide-dashed'>
              {details.map((detail, index) => (
                <div key={index} className='w-full flex flex-row items-center justify-end'>
                  {isEditable ? (
                    <ExpectedTransactionDetailVatRateSelect
                      expectedTransactionDetail={detail}
                      bordered={false}
                      onSelect={onVatSelect}
                      className='max-w-min'
                    />
                  ) : (
                    <VatLabel vatRate={detail.vatRate} className='max-w-min mr-4' />
                  )}

                  {isEditable ? (
                    <ExpectedTransactionDetailCategorySelect
                      expectedTransactionDetail={detail}
                      bordered={false}
                      onCategorize={onCategorize}
                      className='max-w-min'
                    />
                  ) : (
                    <CategoryTagFromId id={detail.categoryId} className='max-w-min mr-4' />
                  )}

                  <Currency amount={detail.amount} />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {loading && (
        <div className='absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-gray-100 opacity-50'>
          <Spinner className='w-4 text-primary' />
        </div>
      )}
    </div>
  )
}
