import { NoSymbolIcon } from '@heroicons/react/24/solid'
import { Layout } from 'antd'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'

import ButtonLink from 'design/ButtonLink'
import { openLiveChat } from 'utils/contact'

import useAuthenticatedContext from 'hooks/useAuthenticatedContext'

const ForbiddenScreen = () => {
  const { t } = useTranslation()
  const { company, subscription } = useAuthenticatedContext()
  const notBlocked = !company?.blocked && !subscription?.blocked

  if (notBlocked) {
    return <Redirect to='/dashboard' />
  }

  return (
    <Layout>
      <Layout.Content className='rc-content bg-white flex flex-col items-center justify-center'>
        <NoSymbolIcon className='absolute -translate-y-24 h-24 w-24 text-error animate-pulse mb-8' />

        <div className='text-2xl font-bold'>{t('forbiddenScreen.oops')}</div>
        <div className='text-lg'>
          <Trans
            i18nKey='forbiddenScreen.noPanic'
            components={{ ButtonLink: <ButtonLink onClick={openLiveChat} label={t('forbiddenScreen.contactUsOnTheChat')} /> }}
          />
        </div>
      </Layout.Content>
    </Layout>
  )
}

export default ForbiddenScreen
