import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'
import i18next from 'i18next'

import AccountingDocumentExpectedTransactionDetails from 'components/accounting-documents/AccountingDocumentExpectedTransactionDetails'
import ExpectedTransactionTableCategorySelectShortcut from 'components/expected-transactions/ExpectedTransactionTableCategorySelectShortcut'
import ExpectedTransactionTableAmountInputShortcut from 'components/expected-transactions/ExpectedTransactionTableAmountInputShortcut'
import ExpectedTransactionExpectedDatePicker from './ExpectedTransactionExpectedDatePicker'
import AddExpectedTransactionToAccountingDocumentButton from 'components/accounting-documents/AddExpectedTransactionToAccountingDocumentButton'
import ExpectedTransactionActions from './ExpectedTransactionActions'
import ExpectedTransactionDescription from './ExpectedTransactionDescription'

const { t } = i18next

const getColumns = ({
  toggleRowExpand,
  accountingDocument
}) => [
  {
    title: t('shared.description'),
    key: 'description',
    onCell: (expTxn) => ({ onClick: () => { toggleRowExpand(expTxn.id) } }),
    className: 'cursor-pointer max-w-0',
    render: (expTxn) => (
      <ExpectedTransactionDescription expectedTransaction={expTxn} />
    )
  },
  {
    title: t('shared.expectedPayment'),
    key: 'expectedDate',
    width: '162px',
    className: 'pr-4',
    render: expectedTransaction => (
      <ExpectedTransactionExpectedDatePicker expectedTransaction={expectedTransaction} />
    )
  },
  {
    title: t('shared.category'),
    key: 'category',
    width: '200px',
    className: 'pr-6 pl-4',
    render: expTxn => <ExpectedTransactionTableCategorySelectShortcut expectedTransaction={expTxn} onExpand={() => { toggleRowExpand(expTxn.id) }} />
  },
  {
    title: t('shared.amountInclTax'),
    key: 'totalAmount',
    width: '128px',
    render: expTxn => <ExpectedTransactionTableAmountInputShortcut expectedTransaction={expTxn} onExpand={() => { toggleRowExpand(expTxn.id) }} />
  },
  {
    key: 'deleteButton',
    width: '32px',
    className: 'pl-0 pr-0',
    render: (expTxn) => <ExpectedTransactionActions accountingDocument={accountingDocument} expectedTransaction={expTxn} />
  }
]

const ExpectedTransactionTable = ({ accountingDocument, ...rest }) => {
  const expectedTransactions = accountingDocument.expectedTransactions

  const defaultExpandedKeys = useMemo(() => {
    return expectedTransactions.map(({ id, expectedTransactionDetails }) => expectedTransactionDetails?.length > 1 && id)
  }, [expectedTransactions])
  const [expandedRowKeys, setExpandedRowKeys] = useState(defaultExpandedKeys)

  const toggleRowExpand = useCallback((rowKeyToToggle) => {
    if (expandedRowKeys.includes(rowKeyToToggle)) {
      const newKeys = expandedRowKeys.filter((rowKey) => rowKey !== rowKeyToToggle)
      setExpandedRowKeys(newKeys)
    } else {
      setExpandedRowKeys([rowKeyToToggle, ...expandedRowKeys])
    }
  }, [setExpandedRowKeys, expandedRowKeys])

  const columns = useMemo(() => getColumns({ toggleRowExpand, accountingDocument }), [toggleRowExpand, accountingDocument])

  return (
    <Table
      size='small'
      columns={columns}
      rowKey={expTxn => expTxn.id}
      pagination={false}
      dataSource={expectedTransactions}
      expandable={{
        expandedRowKeys,
        expandedRowClassName: () => 'rc-invoice-expected-transaction-table-expanded-row',
        expandedRowRender: (expTxn) => <div className='flex flex-col items-center'><AccountingDocumentExpectedTransactionDetails expectedTransaction={expTxn} /></div>,
        onExpand: (expanded, expTxn) => toggleRowExpand(expTxn.id)
      }}
      footer={() => (
        <AddExpectedTransactionToAccountingDocumentButton accountingDocument={accountingDocument} />
      )}
      {...rest}
    />
  )
}

ExpectedTransactionTable.propTypes = {
  accountingDocument: PropTypes.object
}

export default ExpectedTransactionTable
