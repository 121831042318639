import React from 'react'
import { Layout } from 'antd'

import ListUsers from './users/ListUsers'
import useAuthenticatedContext from 'hooks/useAuthenticatedContext'
import { useTranslation } from 'react-i18next'

export default () => {
  const { t } = useTranslation()
  const { company: { name } } = useAuthenticatedContext()

  return (
    <Layout>
      <Layout.Content className='rc-content overflow-x-visible flex flex-row justify-center items-start'>
        <div className='bg-white rounded-lg w-full max-w-3xl 2xl:max-w-4xl p-4 mt-20 h-auto'>
          <div className='pb-5 border-b border-gray-200 mb-5'>
            <h3 className='text-lg leading-6 font-medium text-gray-900'>
              {t('users.manageUsers')}
            </h3>
            <div className='mt-2 max-w-4xl text-sm text-gray-500'>{t('users.addDeleteUsers', { name })}</div>
          </div>

          <ListUsers />
        </div>
      </Layout.Content>
    </Layout>
  )
}
