import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { useHistory } from 'react-router'

import { get } from 'lodash'
import Spinner from 'design/Spinner'
import AuthActions from 'actions/AuthActions'
import useSearchParams from 'hooks/useSearchParams'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'

export default () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { temporary_token: temporaryUserToken } = useSearchParams()
  const history = useHistory()
  const [temporaryUserTokenIsSent, setTemporaryUserTokenIsSent] = useState(false)

  const isAuthenticated = useSelector(state => get(state, 'app.isAuthenticated'))

  useEffect(() => {
    if (temporaryUserToken && !temporaryUserTokenIsSent) {
      setTemporaryUserTokenIsSent(true)
      dispatch(AuthActions.authenticateFromTemporaryUserToken({
        temporaryUserToken,
        onError: (e) => {
          message.error({ content: t('signin.errorWhileSigningIn'), duration: 15 })
        },
        onComplete: () => {
          // We need to wait for the state to be updated before redirecting
          setTimeout(() => {
            history.push('/')
          }, 1)
        },
        ensureLogoutBefore: true
      }))
    }
  }, [dispatch, history, temporaryUserToken, isAuthenticated, t, temporaryUserTokenIsSent])

  if (isAuthenticated && !temporaryUserToken) {
    return <Redirect to='/' />
  }

  return (
    <div className='flex flex-col w-full h-full items-center justify-center'>
      <div className='mb-8 -mt-14 w-full flex flex-row justify-center animate-fade-in'>
        <img src='/logo_quipu.svg' alt='Quipu logo' className='rc-signin-logo' />
      </div>

      <Spinner className='w-6 h-6 text-primary' />
    </div>
  )
}
