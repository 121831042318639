import React from 'react'
import TotalYearOverview from 'components/transactions/TotalYearOverview'
import CategoryRepartitionCard from 'components/CategoryRepartitionCard'

export default function CashOutCharts () {
  return (
    <div>
      <TotalYearOverview type='cashout' />

      <CategoryRepartitionCard type='cashout' />
    </div>
  )
}
