import { useMutation } from '@apollo/client'
import confirm from 'antd/lib/modal/confirm'
import { FORECAST_FROM_REALISED } from 'graphql/scenario-forecasts'
import ForecastAssistantService from 'services/ForecastAssistantService.js'

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export default ({ scenarioId, categoryId, sheetDate, onCompleted, params, amount, sheet }) => {
  const { t } = useTranslation()
  const [forecastFromRealised, { loading }] = useMutation(FORECAST_FROM_REALISED, {
    onCompleted: onCompleted
  })

  const saveForecastFromRealised = useCallback((assistantService) => {
    forecastFromRealised({
      variables: {
        input: assistantService.assembleForecastFromRealisedInput({
          scenarioId,
          categoryId,
          params,
          sheetDate
        })
      }
    })
  }, [forecastFromRealised, scenarioId, categoryId, params, sheetDate])

  const onSubmitRealised = useCallback(() => {
    const assistantService = new ForecastAssistantService({ sheetDate, amount })

    if (assistantService.hasAlreadyBudgetedOnPeriod({ categoryId, toDate: params.toDate, sheet })) {
      confirm({
        title: t('hook.useSaveForecastFromRealised.warning.youAreAboutToOverwriteFutureBudgets'),
        okType: 'danger',
        okText: t('shared.confirm'),
        cancelText: t('shared.cancel'),
        maskClosable: true,
        zIndex: 1500,
        onOk () {
          saveForecastFromRealised(assistantService)
        }
      })
    } else {
      saveForecastFromRealised(assistantService)
    }
  }, [sheetDate, params, sheet, categoryId, amount, t, saveForecastFromRealised])

  return {
    onSubmitRealised,
    loading
  }
}
