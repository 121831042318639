import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'
import signinRoutes from 'config/signinRoutes.js'

import rootReducer from 'reducers'
import rootSaga from 'sagas'
import authActions from 'actions/AuthActions'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['app']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware()
  const store = createStore(persistedReducer, composeWithDevTools(
    applyMiddleware(sagaMiddleware)
  ))
  const persistor = persistStore(store)

  return {
    store: {
      ...store,
      runSaga: sagaMiddleware.run(rootSaga)
    },
    persistor
  }
}

// May move this in a saga sometime
const onStoreReady = (store) => {
  const { isAuthenticated } = store.getState().app
  const isOnSigninScreen = signinRoutes.some(signinRoute => location.href.includes(signinRoute))

  if (isAuthenticated && !isOnSigninScreen) {
    const signInSuccessAction = authActions.requestSignInSuccess()

    store.dispatch(signInSuccessAction)
  }
}

export { configureStore, onStoreReady }
