import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getResources } from 'redux-resource'
import PropTypes from 'prop-types'
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts'
import { get } from 'lodash'
import Colors from 'utils/colors'

const typeConfig = {
  cashout: {
    name: 'cash out',
    color: Colors.expense
  },
  cashin: {
    name: 'cash in',
    color: Colors.revenue
  }
}

export default function TransactionTypeChart ({ type, data, filterCategories, scenarioEnabled }) {
  const categories = useSelector(state => getResources(state.categories, type, { byId: true }))

  const hasData = useMemo(() => {
    return get(data, 'length', 0) !== 0
  }, [data])

  const hasFilterCategories = useMemo(() => {
    return get(filterCategories, 'length', 0) !== 0
  }, [filterCategories])

  if (!hasData) return null

  return (
    <ResponsiveContainer>
      <AreaChart data={data}>
        <XAxis dataKey='name' minTickGap={0} axisLine={false} tickLine={false} />
        <YAxis />

        <Tooltip
          content={({ payload }) => get(payload, '0.payload.renderTooltip', () => true)()}
        />

        {!hasFilterCategories && (
          <defs>
            <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='0%' stopColor={typeConfig[type].color} stopOpacity={0.1} />
              <stop offset='100%' stopColor={typeConfig[type].color} stopOpacity={0} />
            </linearGradient>
          </defs>
        )}

        {!hasFilterCategories && (
          <Area
            fill='url(#colorUv)'
            type='monotone'
            dataKey='total'
            stroke={typeConfig[type].color}
            strokeWidth={2}
            dot={{ strokeWidth: 2, r: 5 }}
          />
        )}

        {!hasFilterCategories && scenarioEnabled && (
          <Area
            fill='url(#colorUv)'
            type='monotone'
            dataKey='totalPlanned'
            strokeDasharray='5 5'
            stroke={typeConfig[type].color + 'AA'}
            strokeWidth={2}
            dot={{ strokeWidth: 2, r: 5 }}
          />
        )}

        {hasFilterCategories && (
          filterCategories.map((categoryId, index) => (
            <defs key={index}>
              <linearGradient id={`colorUv-${categoryId}`} x1='0' y1='0' x2='0' y2='1'>
                <stop offset='0%' stopColor={get(categories, `${categoryId}.color`)} stopOpacity={0.1} />
                <stop offset='100%' stopColor={get(categories, `${categoryId}.color`)} stopOpacity={0} />
              </linearGradient>
            </defs>
          ))
        )}

        {hasFilterCategories && (
          filterCategories.map((categoryId, index) => (
            <Area
              fill={`url(#colorUv-${categoryId})`}
              key={index}
              type='monotone'
              dataKey={`${categoryId}.real`}
              stroke={get(categories, `${categoryId}.color`)}
              strokeWidth={2}
              dot={{ strokeWidth: 2, r: 5 }}
            />
          ))
        )}

        {hasFilterCategories && scenarioEnabled && (
          filterCategories.map((categoryId, index) => (
            <Area
              fill={`url(#colorUv-${categoryId})`}
              key={`planned-${index}`}
              type='monotone'
              dataKey={`${categoryId}.plannedCumulated`}
              strokeDasharray='5 5'
              stroke={get(categories, `${categoryId}.color`) + 'AA'}
              strokeWidth={2}
              dot={{ strokeWidth: 2, r: 5 }}
            />
          ))
        )}
      </AreaChart>
    </ResponsiveContainer>
  )
}

TransactionTypeChart.propTypes = {
  type: PropTypes.oneOf(['cashin', 'cashout']),
  data: PropTypes.arrayOf(PropTypes.object),
  filterCategories: PropTypes.arrayOf(PropTypes.string),
  scenarioEnabled: PropTypes.bool
}
