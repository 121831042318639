import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button, Tooltip } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'

import { DELETE_EXPECTED_TRANSACTION_DETAIL } from 'graphql/expected-transaction-details'
import { useTranslation } from 'react-i18next'

const DeleteExpectedTransactionDetail = ({ expectedTransaction, expectedTransactionDetail }) => {
  const { t } = useTranslation()
  const updateCache = (cache) => {
    cache.modify({
      id: cache.identify(expectedTransaction),
      fields: {
        expectedTransactionDetails (existingDetailRefs, { readField }) {
          return existingDetailRefs.filter(
            detailRef => expectedTransactionDetail.id !== readField('id', detailRef)
          )
        }
      }
    })
  }

  const [deleteExpectedTransactionDetail, { loading }] = useMutation(DELETE_EXPECTED_TRANSACTION_DETAIL, {
    variables: { id: expectedTransactionDetail.id },
    update: updateCache
  })

  const detailCount = expectedTransaction.expectedTransactionDetails.length
  const disabled = useMemo(() => detailCount > 1 ? loading : true, [detailCount, loading])

  return (
    <Tooltip title={t('component.deleteExpectedTransactionDetail.deleteDetail')}>
      <Button disabled={disabled} loading={loading} type='link' onClick={deleteExpectedTransactionDetail} icon={<DeleteOutlined />} />
    </Tooltip>
  )
}

DeleteExpectedTransactionDetail.propTypes = {
  expectedTransaction: PropTypes.object,
  expectedTransactionDetail: PropTypes.object
}

export default DeleteExpectedTransactionDetail
