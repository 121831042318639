import PropTypes from 'prop-types'
import React from 'react'

import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { Tooltip } from 'antd'
import Currency from 'components/Currency'
import Badge from 'design/Badge'
import { useTranslation } from 'react-i18next'

const TransactionAmount = ({ transaction }) => {
  const { t } = useTranslation()

  if (transaction.refundedTransactionId) {
    return (
      <Tooltip
        title={(
          <div>
            {t('component.transactionAmount.originalAmount')} <Currency amount={transaction.originalAmount} className='text-white font-bold' />
          </div>
        )}
        className='flex flex-row items-center justify-end cursor-help'
      >
        <div><Badge label={t('component.transactionAmount.refund')} /></div>
      </Tooltip>
    )
  }

  if (transaction.refundTransactionId) {
    return (
      <Tooltip
        title={(
          <div>
            {t('component.transactionAmount.transactionRefunded')}
            <br /> {t('component.transactionAmount.originalAmount')} <Currency amount={transaction.originalAmount} className='text-white font-bold' />
          </div>
        )}
        className='flex flex-row items-center justify-end cursor-help'
      >
        <InformationCircleIcon className='w-4 h-4 text-gray-300 mr-1' />
        <Currency amount={transaction.amount} />
      </Tooltip>
    )
  }

  if (transaction.ignored) {
    return (
      <Tooltip
        title={(
          <div>
            {t('component.transactionAmount.amount')} <Currency amount={transaction.originalAmount} className='text-white font-bold' />
          </div>
        )}
        className='flex flex-row items-center justify-end cursor-help'
      >
        <div><Badge label={t('component.transactionAmount.ignored')} /></div>
      </Tooltip>
    )
  }

  return (
    <div className='float-right'>
      <Currency amount={transaction.amount} />
    </div>
  )
}

TransactionAmount.propTypes = {
  transaction: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    originalAmount: PropTypes.number,
    refundedTransactionId: PropTypes.string,
    refundTransactionId: PropTypes.string,
    ignored: PropTypes.bool
  })
}

export default TransactionAmount
