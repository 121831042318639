import Locale from 'enums/Locale.js'
import i18next from 'i18next'

export const openLiveChat = (message = null) => {
  // if (message) {
  //   window.Intercom('showNewMessage', message)
  // } else {
  if (window.Intercom) {
    window.Intercom('showNewMessage')
  } else {
    console.warn('Intercom is not defined in development mode.')
  }
  // }
}

export const getBookDemoLink = () => {
  if (process.env.REACT_APP_TENANT === 'QUIPU') {
    switch (i18next.language) {
      case Locale.es_ES:
      case Locale.ca_ES:
        return 'https://asesorias.typeform.com/tesoreria-es?utm_source=app&utm_medium=demo&utm_campaign=tesoreria'
      default:
        return 'https://asesorias.typeform.com/cashflow-en?utm_source=app&utm_medium=demo&utm_campaign=tesoreria'
    }
  }

  return 'https://rocketchart.co/reserver-une-demo/'
}

export const autoSignInDemoAccountLink = 'https://demo.rocketchart.co/auto-signin?e=demo@rocketchart.co&p=89asXH7Gh'
