import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Table, Typography } from 'antd'
import i18next from 'i18next'

import moment from 'moment'
import Currency from 'components/Currency.js'
import ExpectedTransactionBillingBadge from 'components/expected-transactions/ExpectedTransactionBillingBadge.js'

const { t } = i18next
const { Text } = Typography

const getColumns = ({
  toggleRowExpand,
  recurringPayment
}) => [
  {
    title: '',
    dataIndex: 'description',
    key: 'description',
    align: 'center',
    width: '150px',
    render: text => <Text>{text}</Text>
  },
  {
    title: t('shared.date'),
    key: 'expectedDate',
    dataIndex: 'expectedDate',
    width: '162px',
    className: 'pr-4',
    render: date => date && (<Text>{moment(date).format('DD/MM/YYYY')}</Text>)

  },
  {
    title: t('shared.status'),
    key: 'status',
    render: expTxn => <ExpectedTransactionBillingBadge expectedTransaction={expTxn} />
  },
  {
    title: t('shared.amountInclTax'),
    key: 'totalAmount',
    width: '128px',
    align: 'right',
    render: expTxn => <Currency amount={recurringPayment.unitAmount} strong />
  },
  {
    title: '',
    align: 'center',
    width: '50px'
  }

]

const RecurringPaymentExpectedTransactionsTable = ({ recurringPayment, ...rest }) => {
  const expectedTransactions = recurringPayment.expectedTransactions

  const columns = useMemo(() => getColumns({ recurringPayment }), [recurringPayment])

  return (
    <Table
      size='small'
      className='max-w-2xl'
      columns={columns}
      rowKey={expTxn => expTxn.id}
      pagination={false}
      dataSource={expectedTransactions}
      scroll={{
        y: 273
      }}
      {...rest}
    />
  )
}

RecurringPaymentExpectedTransactionsTable.propTypes = {
  recurringPayment: PropTypes.object
}

export default RecurringPaymentExpectedTransactionsTable
