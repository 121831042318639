import { useMutation } from '@apollo/client'
import { DatePicker, Tooltip } from 'antd'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { CalendarIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import ExpectedTransactionPaymentBadge from 'components/expected-transactions/ExpectedTransactionPaymentBadge'
import { SET_EXPECTED_TRANSACTION_EXPECTED_DATE } from 'graphql/expected-transactions'
import useRenderExpectedDateFooterPicker from 'hooks/useRenderExpectedDateFooterPicker'
import moment from 'moment'
import ExpectedTransactionBillingBadge from './ExpectedTransactionBillingBadge.js'

ExpectedTransactionExpectedDatePicker.propTypes = {
  expectedTransaction: PropTypes.object,
  onCompleted: PropTypes.func,
  className: PropTypes.string
}

function ExpectedTransactionExpectedDatePicker ({ expectedTransaction, onCompleted, className }) {
  const { t } = useTranslation()
  const now = moment().startOf('day')
  const expectedDateMoment = moment(expectedTransaction.expectedDate)
  const expectedDateShifted = expectedDateMoment < now
  const expectedDateFormated = expectedDateMoment.format('DD/MM/YYYY')
  const expectedDateToShow = expectedDateShifted ? now : expectedDateMoment
  const [setExpDate, { loading }] = useMutation(SET_EXPECTED_TRANSACTION_EXPECTED_DATE, { onCompleted })

  const handleOnChange = useCallback((newExpectedDate) => {
    // Antd's DatePicker trigger OnChange callback inside blur() function, so we have recursive calls.
    // This code block prevents a second call to the API.
    if (document.activeElement.tagName.toLowerCase() !== 'input') {
      document.activeElement.blur()
      return
    }

    setExpDate({
      variables: {
        id: expectedTransaction.id,
        expectedDate: newExpectedDate.format('YYYY-MM-DD')
      }
    })
    document.activeElement.blur()
  }, [setExpDate, expectedTransaction.id])

  const renderDatePickerFooter = useRenderExpectedDateFooterPicker({ handleOnChange })

  if (expectedTransaction.paymentDate) {
    return expectedTransaction.parentType === 'INVOICE' ? <ExpectedTransactionPaymentBadge expectedTransaction={expectedTransaction} />
      : <ExpectedTransactionBillingBadge expectedTransaction={expectedTransaction} />
  }

  return (
    <Tooltip
      title={expectedDateShifted ? t('component.expectedTransactionExpectedDatePicker.initiallyExpectedOn', { formattedOriginalExpectedDate: expectedDateFormated }) : null}
      placement='right'
      className={classNames('flex flex-row items-center', className)}
    >
      <DatePicker
        value={expectedDateToShow}
        loading={loading}
        disabled={loading}
        disabledDate={disabledDate}
        format='DD/MM/YYYY'
        suffixIcon={expectedDateShifted ? (<ExclamationTriangleIcon className='w-4 h-4 text-gray-800' />) : (<CalendarIcon className='w-4 h-4 text-gray-200' />)}
        onChange={handleOnChange}
        allowClear={false}
        renderExtraFooter={renderDatePickerFooter}
        showToday={false}
      />
    </Tooltip>
  )
}

function disabledDate (current) {
  return moment().startOf('day').isAfter(current)
}

export default ExpectedTransactionExpectedDatePicker
