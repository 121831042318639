import React, { useCallback, useMemo } from 'react'
import CategorySelect from 'components/categories/CategorySelect'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { CATEGORIZE_MULTIPLE_EXPECTED_TRANSACTION_DETAILS } from 'graphql/expected-transaction-details'
import classNames from 'classnames'
import { isArray, uniq } from 'lodash'

const ExpectedTransactionDetailCategorySelect = ({ expectedTransactionDetail, expectedTransactionDetails, onCategorize, className, ...rest }) => {
  const multipleExpectedTransactionDetails = isArray(expectedTransactionDetails)

  const type = multipleExpectedTransactionDetails ? expectedTransactionDetails[0].type : expectedTransactionDetail.type
  const categoryId = multipleExpectedTransactionDetails ? expectedTransactionDetails[0].categoryId : expectedTransactionDetail.categoryId
  const expectedTransactionDetailIds = useMemo(() => multipleExpectedTransactionDetails ? expectedTransactionDetails.map(expTrDtl => expTrDtl.id) : [expectedTransactionDetail.id],
    [expectedTransactionDetail, expectedTransactionDetails, multipleExpectedTransactionDetails])

  const [categorizeMultipleExpTxnDetails, { loading }] = useMutation(CATEGORIZE_MULTIPLE_EXPECTED_TRANSACTION_DETAILS)

  const handleOnChange = useCallback((categoryId) => {
    categorizeMultipleExpTxnDetails({
      variables: {
        ids: expectedTransactionDetailIds,
        categoryId: categoryId || null
      }
    })

    if (onCategorize) onCategorize()

    document.activeElement.blur()
  }, [categorizeMultipleExpTxnDetails, expectedTransactionDetailIds, onCategorize])

  if (multipleExpectedTransactionDetails && uniq(expectedTransactionDetails.map(expectedTransactionDetail => expectedTransactionDetail.categoryId)).length > 1) {
    // this case should not happen, all expectedTransactionDetails must have the same category
    console.warn('all expectedTransactionDetails in CategorySelector must have the same category')
    return null
  }

  return (
    <CategorySelect
      disabled={loading}
      loading={loading}
      type={type}
      value={categoryId}
      onChange={handleOnChange}
      className={classNames('w-full', className)}
      {...rest}
      // className='w-full rc-expected-transaction-detail-category-select'
    />
  )
}

ExpectedTransactionDetailCategorySelect.propTypes = {
  expectedTransactionDetail: PropTypes.object,
  expectedTransactionDetails: PropTypes.array,
  onCategorize: PropTypes.func,
  className: PropTypes.string
}

export default ExpectedTransactionDetailCategorySelect
