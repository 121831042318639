import { gql } from '@apollo/client'

export const ACCOUNTING_DOCUMENT_NESTED_EXPECTED_TRANSACTIONS_FIELDS = gql`
  fragment accountingDocumentNestedExpectedTransactionsFields on ExpectedTransaction {
    id
    description
    paymentDate
    transactionId
    expectedDate
    type
    parentType
    expectedTransactionDetails {
      id
      type
      amount
      vatRate
      categoryId
    }
  }
`

export const SET_EXPECTED_TRANSACTION_EXPECTED_DATE = gql`
  mutation setExpectedTransactionExpectedDate(
    $id: ID!,
    $expectedDate: Date!
  ) {
    setExpectedTransactionExpectedDate(
      id: $id
      expectedDate: $expectedDate
    ) {
      id
      expectedDate
    }
  }
`

export const SET_EXPECTED_TRANSACTION_DESCRIPTION = gql`
  mutation setExpectedTransactionDescription($id: ID!, $description: String!) {
    setExpectedTransactionDescription(id: $id, description: $description) {
      id
      description
    }
  }
`

export const ADD_DETAIL_TO_EXPECTED_TRANSACTION = gql`
  mutation addDetailToExpectedTransaction($input: AddDetailToExpectedTransactionInput!) {
    addDetailToExpectedTransaction(input: $input) {
      id
      expectedTransactionDetails {
        id
        categoryId
        type
        amount
        vatAmount
      }
    }
  }
`

export const LIST_EXPECTED_TRANSACTIONS = gql`
  query listExpectedTransactions(
      $fromDate: Date!, 
      $toDate: Date!,
      $type: String!,
      $categoryIds: [String],
      $paid: Boolean,
      $offset: Int!,
      $limit: Int!,
      $orderBy: String!,
      $parentType: ParentType,
      $scenarioId: ID
    ) {
    listExpectedTransactions(
      fromDate: $fromDate,
      toDate: $toDate, 
      type: $type,
      categoryIds: $categoryIds,
      paid: $paid,
      offset: $offset,
      limit: $limit,
      orderBy: $orderBy,
      parentType: $parentType,
      scenarioId: $scenarioId
    ) {
      total
      expectedTransactions {
        id
        description
        expectedDate
        paymentDate
        transactionId
        expectedTransactionDetails {
          id
          amount
          vatRate
          type
          categoryId
        }
        accountingDocument {
          ... on Invoice {
            id
            thirdPartyName
            documentNumber: invoiceNumber
            externalSource
            type
            documentUrl
            status
          }
          ... on Quote {
            id
            thirdPartyName
            documentNumber: quoteNumber
            type
            externalSource
            status
          }
          ... on RecurringPayment {
            id
            thirdPartyName
            documentNumber: description
            externalSource
            type
            status
            ignored
          }
          ... on Order {
            id
            thirdPartyName
            documentNumber: orderNumber
            externalSource
            type
            status
          }
        }
      }
    }
  }
`

export const LIST_TRANSACTION_EXPECTED_TRANSACTIONS = gql`
  query listTransactionExpectedTransactions($transactionId: ID!) {
    listTransactionExpectedTransactions(transactionId: $transactionId) {
      expectedTransactions {
        id
        description
        expectedDate
        transactionId
        expectedTransactionDetails {
          amount
        }
        accountingDocument {
          ... on Invoice {
            id
            thirdPartyName
            documentNumber: invoiceNumber
            externalSource
            type
            documentUrl
            status
          }
          ... on Quote {
            id
            thirdPartyName
            documentNumber: quoteNumber
            type
            externalSource
            status
          }
          ... on RecurringPayment {
            id
            thirdPartyName
            documentNumber: description
            externalSource
            status
          }
          ... on Order {
            id
            thirdPartyName
            documentNumber: orderNumber
            type
            externalSource
            status
          }
        }
      }
    }
  }
`

export const SEARCH_RECONCILIABLE_EXPECTED_TRANSACTIONS = gql`
  query searchReconciliableExpectedTransactions($type: String!, $search: String, $limit: Int!) {
    searchReconciliableExpectedTransactions(type: $type, search: $search, limit: $limit) {
      total
      expectedTransactions {
        id
        description
        expectedDate
        transactionId
        expectedTransactionDetails {
          amount
        }
        accountingDocument {
          ... on Invoice {
            id
            thirdPartyName
            documentNumber: invoiceNumber
            externalSource
            type
            documentUrl
            status
          }
        }
      }
    }
  }
`

export const RECONCILIATE_EXPECTED_TRANSACTION = gql`
  mutation reconciliateExpectedTransaction($id: ID!, $transactionId: ID!) {
    reconciliateExpectedTransaction(id: $id, transactionId: $transactionId) {
      id
      transactionId
    }
  }
`

export const CANCEL_EXPECTED_TRANSACTION_RECONCILIATION = gql`
  mutation cancelExpectedTransactionReconciliation($id: ID!) {
    cancelExpectedTransactionReconciliation(id: $id) {
      id
      transactionId
    }
  }
`

export const DELETE_EXPECTED_TRANSACTION = gql`
  mutation deleteExpectedTransaction(
    $id: ID!
  ) {
    deleteExpectedTransaction(id: $id) {
      id
      accountingDocument {
          ... on Invoice {
          id
          status
        }
      }
    }
  }
`

export const MARK_EXPECTED_TRANSACTIONS_AS_PAID = gql`
  mutation markExpectedTransactionsAsPaid($input: MarkExpectedTransactionsAsPaidInput!) {
    markExpectedTransactionsAsPaid(input: $input) {
      id
      paymentDate
      # when cancel payment, transactionId is unset
      transactionId
      accountingDocument {
        ... on Invoice {
          id
          status
        }
      }
    }
  }
  `

export const MARK_EXPECTED_TRANSACTIONS_AS_INVOICED = gql`
  mutation markExpectedTransactionsAsPaid($input: MarkExpectedTransactionsAsPaidInput!) {
    markExpectedTransactionsAsPaid(input: $input) {
      id
      paymentDate
      # when cancel payment, transactionId is unset
      transactionId
      accountingDocument {
        ... on Quote {
          id
          status
        }
      }
    }
  }
`
