import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import { get, sumBy } from 'lodash'
import { c } from 'utils/currencies'

import ExpectedTransactionDetailAmountInput from 'components/expected-transaction-details/ExpectedTransactionDetailAmountInput'

const ExpectedTransactionTableAmountInputShortcut = ({ expectedTransaction, onExpand }) => {
  const singleExpectedTransactionDetail = useMemo(() => {
    if (get(expectedTransaction, 'expectedTransactionDetails.length', 0) > 1) return null

    return get(expectedTransaction, 'expectedTransactionDetails.0')
  }, [expectedTransaction])

  const totalAmount = useMemo(() => sumBy(expectedTransaction.expectedTransactionDetails, 'amount'), [expectedTransaction.expectedTransactionDetails])
  const amountLabel = useMemo(() => {
    return c(totalAmount)
  }, [totalAmount])

  if (singleExpectedTransactionDetail) {
    return (
      <ExpectedTransactionDetailAmountInput expectedTransactionDetail={singleExpectedTransactionDetail} />
    )
  }

  return (
    <Button
      type='link'
      onClick={onExpand}
      size='small'
      className='pl-0 pr-0 border-none'
    >
      {amountLabel}
    </Button>
  )
}

ExpectedTransactionTableAmountInputShortcut.propTypes = {
  expectedTransaction: PropTypes.object,
  onExpand: PropTypes.func
}

export default ExpectedTransactionTableAmountInputShortcut
