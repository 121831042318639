export function parseNumber (stringValue) {
  if (typeof stringValue !== 'string') {
    return Number.NaN
  }
  const cleanedValue = stringValue.replace(',', '.')
  if (isNaN(cleanedValue)) {
    return Number.NaN
  }
  return parseFloat(cleanedValue)
}
