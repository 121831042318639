import { gql } from '@apollo/client'

export const GET_POWENS_CONNECT_URL = gql`
  query getPowensConnectUrl {
    getPowensConnectUrl
  }
`

export const GET_POWENS_MANAGE_URL = gql`
  query getPowensManageUrl {
    getPowensManageUrl
  }
`

export const REGISTER_POWENS_TOKEN = gql`
  mutation registerPowensToken($temporaryCode: String!) {
    registerPowensToken(temporaryCode: $temporaryCode) {
      id
      firstSyncingStartAt
    }
  }
`
