import React, { useCallback, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { ArrowRightIcon, CalendarIcon } from '@heroicons/react/24/outline'

import NewScenarioModal from 'components/scenarios/NewScenarioModal'
import ScenarioSelect from 'components/scenarios/ScenarioSelect'
import ScenarioActions from 'components/scenarios/ScenarioActions'

import AppActions from 'actions/AppActions'

import Toggle from 'design/Toggle'
import ButtonLink from 'design/ButtonLink'
import { DatePicker, Radio } from 'antd'
import { get } from 'lodash'
import useExportTable from 'hooks/useExportTable'
import CashflowSheetExportService from 'services/CashflowSheetExportService'
import { startEdgeDate } from 'utils/dates'
import { useTranslation } from 'react-i18next'
import InitializeScenarioForecastButton from './InitializeScenarioForecastButton.js'
import classNames from 'classnames'
import { ScenariosContext } from 'contexts/ScenariosContext.js'

CashflowSheetHeader.propTypes = {
  sheet: PropTypes.array,
  sheetDates: PropTypes.array,
  queryFilters: PropTypes.object,
  mergeQueryFilters: PropTypes.func
}

export default function CashflowSheetHeader ({
  sheet,
  sheetDates,
  queryFilters,
  mergeQueryFilters
}) {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const { refetch: scenariosRefetch } = useContext(ScenariosContext)
  const [newScenarioModalVisible, setNewScenarioModalVisible] = useState(false)
  const realisedVsForecasted = useSelector(state => get(state, 'app.dashboard.realisedVsForecasted', false))
  const showPercentage = useSelector(state => get(state, 'app.dashboard.showPercentage', true))
  const scenarioId = useMemo(() => queryFilters.scenarioId, [queryFilters.scenarioId])

  const handleScenarioSelect = useCallback(
    async (scenarioId) => {
      await scenariosRefetch()
      mergeQueryFilters({ scenarioId })
    },
    [scenariosRefetch, mergeQueryFilters])

  const onCreateSuccess = useCallback((scenarioId) => {
    handleScenarioSelect(scenarioId)
  }, [handleScenarioSelect])

  const onDelete = useCallback(() => {
    handleScenarioSelect(undefined)
  }, [handleScenarioSelect])

  const handleChangeRealisedVsScenario = useCallback((realisedVsForecasted) => {
    dispatch(AppActions.setRealisedVsScenario(realisedVsForecasted))
  }, [dispatch])

  const handleChangeShowPercent = useCallback(() => {
    dispatch(AppActions.setDashboardShowPercentage(!showPercentage))
  }, [dispatch, showPercentage])

  const fromDateValue = useMemo(() => moment(queryFilters.fromDate), [queryFilters.fromDate])
  const toDateValue = useMemo(() => moment(queryFilters.toDate), [queryFilters.toDate])

  const handleFromDateChange = useCallback((fromDateMoment) => {
    const fromDate = fromDateMoment.format('YYYY-MM-DD')
    const newQueryFilters = { fromDate }

    if (moment(fromDateMoment).endOf('month').isSameOrAfter(toDateValue)) {
      newQueryFilters.toDate = moment(fromDateMoment).add(1, 'month').endOf('month').format('YYYY-MM-DD')
    }

    mergeQueryFilters(newQueryFilters)
  }, [mergeQueryFilters, toDateValue])

  const handleToDateChange = useCallback((toDateMoment) => {
    const toDate = moment(toDateMoment).endOf('month').format('YYYY-MM-DD')
    mergeQueryFilters({ toDate })
  }, [mergeQueryFilters])

  const renderFromDatePickerFooter = useCallback(() => {
    return (
      <div className='flex flex-row justify-around'>
        <ButtonLink
          onClick={() => handleFromDateChange(threeMonthAgo)}
          label={t('dashboard.cashflowSheetHeader.threeLastMonths')}
        />

        <ButtonLink
          onClick={() => handleFromDateChange(startOfCurrentMonth)}
          label={t('dashboard.cashflowSheetHeader.currentMonth')}
        />
      </div>
    )
  }, [handleFromDateChange, t])

  const renderToDatePickerFooter = useCallback(() => {
    return (
      <div className='flex flex-row justify-around'>
        <ButtonLink
          onClick={() => handleToDateChange(threeMonthFromNow)}
          label={t('dashboard.cashflowSheetHeader.threeNextMonths')}
        />

        <ButtonLink
          type='link'
          onClick={() => handleToDateChange(sixMonthFromNow)}
          label={t('dashboard.cashflowSheetHeader.sixNextMonths')}
        />
      </div>
    )
  }, [handleToDateChange, t])

  const { exportButton } = useExportTable({ sheet, sheetDates, realisedVsForecasted, ExportService: CashflowSheetExportService })

  return (
    <div className='w-full flex flex-row justify-between items-center'>
      <div className='flex items-center justify-between'>
        <div className='flex flex-row items-center justify-between'>
          <DatePicker
            value={fromDateValue}
            disabledDate={disabledFromDate}
            picker='month'
            format='MMM YYYY'
            allowClear={false}
            onChange={handleFromDateChange}
            className='w-32'
            renderExtraFooter={renderFromDatePickerFooter}
            suffixIcon={<CalendarIcon className='w-4 h-4 text-gray-200' />}
          />

          <ArrowRightIcon className='mx-2 w-4 h-4 text-gray-200' />

          <DatePicker
            value={toDateValue}
            disabledDate={(current) => disabledToDate(current, fromDateValue)}
            picker='month'
            format='MMM YYYY'
            allowClear={false}
            onChange={handleToDateChange}
            className='w-32'
            renderExtraFooter={renderToDatePickerFooter}
            suffixIcon={<CalendarIcon className='w-4 h-4 text-gray-200' />}
          />
        </div>

        {exportButton}
      </div>

      <div className='flex flex-row justify-between items-center'>

        <Toggle
          onChange={handleChangeRealisedVsScenario}
          toggled={realisedVsForecasted}
          label={t('dashboard.cashflowSheetHeader.toggleRealisedVsScenario.label')}
        />

        <Radio.Group
          buttonStyle='solid'
          className={classNames('rc-custom-radio min-w-max ml-5', realisedVsForecasted ? '' : 'opacity-0')}
          size='small'
          value={showPercentage ? 'PERCENTAGE' : 'AMOUNT'}
          onChange={handleChangeShowPercent}
        >
          <Radio.Button value='PERCENTAGE'>%</Radio.Button>
          <Radio.Button value='AMOUNT'>€</Radio.Button>
        </Radio.Group>
      </div>

      <div
        className='flex flex-end items-center w-64 justify-end'
        id='rc-kompassify-scenario-select'
      >

        <InitializeScenarioForecastButton
          hasForecast='true'
          scenarioId={scenarioId}
        />

        <ScenarioSelect
          onChange={handleScenarioSelect}
          onCreate={() => setNewScenarioModalVisible(true)}
          value={queryFilters.scenarioId}
          align='start'
          className='w-48 mr-2'
        />

        <ScenarioActions
          scenarioId={scenarioId}
          onDelete={onDelete}
          onDuplicateSuccess={onCreateSuccess}
        />

        <NewScenarioModal
          visible={newScenarioModalVisible}
          setVisible={setNewScenarioModalVisible}
          onSuccess={onCreateSuccess}
        />
      </div>

    </div>
  )
}

function disabledFromDate (current) {
  return moment(current).isBefore(startEdgeDate)
}

function disabledToDate (current, fromDateValue) {
  return moment(fromDateValue).endOf('month').isAfter(current)
}

const startOfCurrentMonth = moment().startOf('month')
const threeMonthAgo = moment(startOfCurrentMonth).subtract(3, 'month')
const threeMonthFromNow = moment(startOfCurrentMonth).add(3, 'month')
const sixMonthFromNow = moment(startOfCurrentMonth).add(6, 'month')
