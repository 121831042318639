import i18next from 'i18next'
import { first, flatten, get, isArray, isBoolean, isNumber, last, times } from 'lodash'
import moment from 'moment'

import { c } from 'utils/currencies'

const { t } = i18next
class CashflowSheetExportService {
  constructor ({ sheet, sheetDates, realisedVsForecasted }) {
    if (!isArray(sheet)) throw new Error('sheet must be an array')
    if (!isArray(sheetDates)) throw new Error('sheetDates must be an array')
    if (!isBoolean(realisedVsForecasted)) throw new Error('realisedVsForecasted must be a boolean')

    this.sheet = this.addEmptyRows(sheet)
    this.sheetDates = sheetDates
    this.columns = this.getColumnsConfig({ sheetDates, realisedVsForecasted })
    this.fromDate = first(sheetDates).momentDate
    this.toDate = last(sheetDates).momentDate
  }

  getFilename () {
    const fromDateLabel = moment(this.fromDate).format('DD/MM/YYYY')
    const toDateLabel = moment(this.toDate).format('DD/MM/YYYY')
    return `tresorerie_${fromDateLabel}_${toDateLabel}.csv`
  }

  getCSVHeader () {
    const monthsLabels = this.columns.map(({ valueType, sheetDate }) => {
      const formattedDate = sheetDate.momentDate.format('MMMM YYYY')

      switch (valueType) {
        case 'realised':
          return t('service.cashflowSheetExportService.dateRealized', { formattedDate })
        case 'budgeted':
          return t('service.cashflowSheetExportService.dateBudgeted', { formattedDate })
        case 'forecasted':
          return t('service.cashflowSheetExportService.dateForecasted', { formattedDate })
        default:
          break
      }
    })
    return ['', ...monthsLabels, t('service.cashflowSheetExportService.totalRealized'), t('service.cashflowSheetExportService.totalForecasted')]
  }

  getCSVRowFromSheetRow (row, indentation = 0, indentationText = '↪ ') {
    const renderedMonths = this.columns.map(({ valueType, sheetIndex }) => {
      // const monthKey = sheetDate.momentDate.format('YYYY-MM')
      const monthValue = get(row.sheet, `${sheetIndex}.value`, {})
      return this.renderMonthValue({ rowType: row.type, valueType, monthValue })
    }).flat()

    const spaces = times(indentation, () => indentationText).join('')
    const renderedName = row.name ? `${spaces}${row.name}` : ''
    const renderedRealisedTotal = row.total ? this.renderMonthValue({ rowType: row.type, valueType: 'total', monthValue: row.total.realised }) : ''
    const renderedForecastedTotal = row.total ? this.renderMonthValue({ rowType: row.type, valueType: 'total', monthValue: row.total.forecasted }) : ''

    return [renderedName, ...renderedMonths, renderedRealisedTotal, renderedForecastedTotal]
  }

  renderMonthValue ({ rowType, valueType, monthValue }) {
    const value = this.getCurrencyValue({ rowType, valueType, monthValue })

    if (value === null) return ''

    return c(value, '', { precision: 2, thousand: '' }).trim()
  }

  renderRows (rows, indentation = 0) {
    return flatten(rows.map((row) => {
      return [
        this.getCSVRowFromSheetRow(row, indentation),
        ...this.renderRows(row.children || [], indentation + 1)
      ]
    }))
  }

  getCSVData () {
    return this.renderRows(this.sheet)
  }

  getCurrencyValue ({ rowType, valueType, monthValue }) {
    switch (rowType) {
      case 'STARTING_BALANCE':
        if (valueType === 'realised') {
          const realised = monthValue.realisedStartingBalance
          if (isNumber(realised)) return realised
        } else if (['forecasted', 'budgeted'].includes(valueType)) {
          const forecasted = monthValue.forecastedStartingBalance
          if (isNumber(forecasted)) return forecasted
        }
        break
      case 'CASH_IN':
      case 'CASH_OUT': {
        if (valueType === 'realised') {
          const realised = rowType === 'CASH_IN' ? monthValue.realisedCashin : monthValue.realisedCashout
          if (isNumber(realised)) return realised
        } else if (['forecasted', 'budgeted'].includes(valueType)) {
          const forecasted = rowType === 'CASH_IN' ? monthValue.forecastedCashin : monthValue.forecastedCashout
          if (isNumber(forecasted)) return forecasted
        }
        break
      }
      case 'CASHIN_CATEGORY':
      case 'CASHOUT_CATEGORY': {
        if (valueType === 'realised') {
          const realised = monthValue.realised
          if (isNumber(realised)) return realised
        } else if (['forecasted', 'budgeted'].includes(valueType)) {
          const forecasted = monthValue.forecasted
          if (isNumber(forecasted)) return forecasted
        }
        break
      }
      case 'VAT_PAYMENTS': {
        if (valueType === 'realised') {
          const realised = monthValue.realisedVatPayment
          if (isNumber(realised)) return realised * -1
        } else if (['forecasted', 'budgeted'].includes(valueType)) {
          const forecasted = monthValue.forecastedVatPayment
          if (isNumber(forecasted)) return forecasted * -1
        }
        break
      }
      case 'CASH_FLOW': {
        if (valueType === 'realised') {
          const realised = monthValue.realisedCashflow
          if (isNumber(realised)) return realised
        } else if (['forecasted', 'budgeted'].includes(valueType)) {
          const forecasted = monthValue.forecastedCashflow
          if (isNumber(forecasted)) return forecasted
        }
        break
      }
      case 'ENDING_BALANCE':
        if (valueType === 'realised') {
          const realised = monthValue.realisedEndingBalance
          if (isNumber(realised)) return realised
        } else if (['forecasted', 'budgeted'].includes(valueType)) {
          const forecasted = monthValue.forecastedEndingBalance
          if (isNumber(forecasted)) return forecasted
        }
        break
      default:
        break
    }

    return isNumber(monthValue) ? monthValue : null
  }

  getColumnsConfig ({ sheetDates, realisedVsForecasted }) {
    return flatten(
      sheetDates.map((sheetDate, sheetIndex) => {
        const columns = []

        if (sheetDate.isPastMonth) {
          columns.push({ valueType: 'realised', sheetDate, sheetIndex })
        }

        if (sheetDate.isPastMonth && realisedVsForecasted) {
          columns.push({ valueType: 'budgeted', sheetDate, sheetIndex })
        }

        if (sheetDate.isCurrentMonth) {
          columns.push({ valueType: 'realised', sheetDate, sheetIndex })
          columns.push({ valueType: 'forecasted', sheetDate, sheetIndex })
        }

        if (sheetDate.isFutureMonth) {
          columns.push({ valueType: 'forecasted', sheetDate, sheetIndex })
        }

        return columns
      })
    )
  }

  addEmptyRows (sheet) {
    return sheet.reduce((acc, row) => {
      const newAcc = [...acc]

      if (['CASH_IN', 'CASH_OUT', 'CASH_FLOW', 'ENDING_BALANCE'].includes(row.type)) newAcc.push({ type: 'EMPTY' })

      newAcc.push(row)

      return newAcc
    }, [])
  }
}

export default CashflowSheetExportService
