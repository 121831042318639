import useAuthenticatedContext from 'hooks/useAuthenticatedContext.js'
import { get } from 'lodash'
import moment, { duration } from 'moment'
import { useMemo } from 'react'

export default () => {
  const { company } = useAuthenticatedContext()
  const freeTrialEndAt = get(company, 'freeTrialEndAt')

  const graceDelay = duration(15, 'days')

  const freeTrialEndDate = useMemo(() => {
    if (freeTrialEndAt) {
      return moment(freeTrialEndAt)
    }
  }, [freeTrialEndAt])

  const isOverFreeTrial = useMemo(() => {
    if (freeTrialEndDate) {
      return moment(freeTrialEndDate).isBefore()
    }
  }, [freeTrialEndDate])

  const endGracePeriodDate = useMemo(() => {
    if (freeTrialEndDate) {
      return moment(freeTrialEndDate).add(graceDelay).format('DD/MM/YYYY')
    }
  }, [freeTrialEndDate, graceDelay])

  const isOverGracePeriod = useMemo(() => {
    if (freeTrialEndDate) {
      return moment(freeTrialEndDate).add(graceDelay).isBefore()
    }
  }, [freeTrialEndDate, graceDelay])

  const daysToGo = useMemo(() => {
    if (freeTrialEndAt) {
      return (moment().diff(freeTrialEndAt, 'days') * -1)
    }
  }, [freeTrialEndAt])

  const formattedFreeTrialEndAt = useMemo(() => {
    return moment(freeTrialEndAt).format('LL')
  }, [freeTrialEndAt])

  return { freeTrialEndDate, isOverFreeTrial, isOverGracePeriod, endGracePeriodDate, daysToGo, formattedFreeTrialEndAt }
}
