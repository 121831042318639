import React from 'react'
import PropTypes from 'prop-types'
import ExpectedPattern from './ExpectedPattern'

const ExpectedBar = ({ fill, x, y, width, height }) => {
  // const dotLineHeight = (height || 0) - 2
  const id = `dashed-${fill}`

  return (
    <g fill={fill}>
      <rect x={x || 0} y={y || 0} width={width || 0} height={height || 0} fillOpacity='0.1' />

      <ExpectedPattern id={id} stroke={fill} />

      <rect x={x || 0} y={y || 0} width={width || 0} height={height || 0} fill={`url(#${id})`} />
      {/* <rect x={x} y={(y || 0) + 1} width={width - 2} height={dotLineHeight >= 0 ? dotLineHeight : 0} rx='0' fill='none' /> */}
    </g>
  )
}

ExpectedBar.defaultProps = {
  fill: '#000000',
  x: 0,
  y: 0,
  width: 0,
  height: 0
}

ExpectedBar.propTypes = {
  fill: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default ExpectedBar
