export const sourceNames = {
  SELLSY: 'Sellsy'
}

export const sourceLogos = {
  SELLSY: '/picto_logo_sellsy_dark.svg'
}

export const sourceTypes = {
  SELLSY: 'oauth'
}

export const isConnectionSyncing = (connection) => {
  return ['SYNCING'].includes(connection?.status)
}
