import { useMemo } from 'react'
import { useMutation } from '@apollo/client'

import { DELETE_EXPECTED_TRANSACTION } from 'graphql/expected-transactions'

export default ({ accountingDocument, expectedTransaction }) => {
  const updateCache = (cache) => {
    cache.modify({
      id: cache.identify(accountingDocument),
      fields: {
        expectedTransactions (existingExpTxnRefs, { readField }) {
          return existingExpTxnRefs.filter(
            detailRef => expectedTransaction.id !== readField('id', detailRef)
          )
        }
      }
    })
  }

  const [deleteExpectedTransaction, { loading }] = useMutation(DELETE_EXPECTED_TRANSACTION, {
    variables: { id: expectedTransaction.id },
    update: updateCache
  })

  const detailCount = accountingDocument?.expectedTransactions?.length ?? 0
  const disabled = useMemo(() => detailCount > 1 ? loading : true, [detailCount, loading])

  return { loading, disabled, deleteExpectedTransaction }
}
