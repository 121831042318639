import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { Typography } from 'antd'

import Currency from 'components/Currency'
import ReconciliationDrawerContainer from './ReconciliationDrawerContainer'
import DocumentDrawerContainer from './DocumentDrawerContainer'
import CategoryTagFromId from 'components/categories/CategoryTagFromId'
import cx from 'classnames'
import CategorizeTransaction from './CategorizeTransaction'
import TransactionVatSelect from './TransactionVatSelect'

const TransactionPreview = ({ transaction, showCategory, showCategorySelect, showVatSelect, showDrawers, selectable, selected, onCategorize, onCategorizeVat, ...rest }) => {
  return (
    <div
      className={cx('relative w-full border-2 rounded-md p-4', {
        'cursor-pointer hover:border-primary hover:opacity-75': selectable,
        'border-gray-400': !selected,
        'border-primary': selected
      })}
      {...rest}
    >
      <div className='flex flex-row items-start justify-center'>
        <Typography.Paragraph ellipsis={{ rows: 2 }} className='flex-grow'>
          {transaction?.description}
        </Typography.Paragraph>

        {showCategory && (<CategoryTagFromId id={transaction.categoryId} className='ml-2' />)}
        {showCategorySelect && (
          <CategorizeTransaction
            type={transaction.type}
            transaction={transaction}
            className='-mt-1 -mr-3'
            onCategorize={onCategorize}
          />
        )}

        {showDrawers && (
          <div className='flex flex-row items-center justify-start ml-4 space-x-2'>
            <ReconciliationDrawerContainer transaction={transaction} />

            <DocumentDrawerContainer transaction={transaction} tooltipPlacement='topRight' />
          </div>
        )}
      </div>

      <div className='flex flex-row justify-between items-center mt-4'>
        <Typography.Text strong>{moment(transaction.date).format('DD/MM/YYYY')}</Typography.Text>

        <div className='flex flex-row items-center justify-end space-x-2'>
          {showVatSelect && (
            <TransactionVatSelect transaction={transaction} onSuccess={onCategorizeVat} />
          )}
          <Currency amount={transaction?.amount} strong className='min-w-max' />
        </div>
      </div>
    </div>
  )
}

TransactionPreview.propTypes = {
  transaction: PropTypes.shape({
    description: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    categoryId: PropTypes.string
  }),
  showCategory: PropTypes.bool,
  showCategorySelect: PropTypes.bool,
  showVatSelect: PropTypes.bool,
  showDrawers: PropTypes.bool,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  onCategorize: PropTypes.func,
  onCategorizeVat: PropTypes.func
}

export default TransactionPreview
