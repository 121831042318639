import React, { useCallback, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import { ChartBarIcon, PresentationChartLineIcon, BarsArrowUpIcon } from '@heroicons/react/24/solid'
import { useTranslation } from 'react-i18next'

import Button from 'design/Button'
import Spinner from 'design/Spinner'
import DashboardMainChart from 'components/charts/DashboardMainChart'
import CashflowSheetContext from 'contexts/CashflowSheetContext'
import classNames from 'classnames'
import { ScenariosContext } from 'contexts/ScenariosContext.js'

DashboardMainChartContainer.propTypes = {
  // currentMonthRealised: PropTypes.object,
  queryFilters: PropTypes.object,
  mergeQueryFilters: PropTypes.func
}

export default function DashboardMainChartContainer ({
  queryFilters,
  mergeQueryFilters
}) {
  const { sheet } = useContext(CashflowSheetContext)
  const { data: scenariosData } = useContext(ScenariosContext)

  const scenarioName = useMemo(
    () => scenariosData?.listScenarios.find((scenario) => scenario.id === queryFilters.scenarioId)?.name,
    [scenariosData, queryFilters.scenarioId])

  const reduced = useMemo(() => !!queryFilters.reduced, [queryFilters.reduced])

  const toggleLines = useCallback((e) => {
    mergeQueryFilters({ hideChartLines: !queryFilters.hideChartLines })
  }, [mergeQueryFilters, queryFilters.hideChartLines])

  const toggleBars = useCallback((e) => {
    mergeQueryFilters({ hideChartBars: !queryFilters.hideChartBars })
  }, [mergeQueryFilters, queryFilters.hideChartBars])

  const toggleScale = useCallback((e) => {
    if (queryFilters.chartScale === 'sqrt') {
      mergeQueryFilters({ chartScale: null })
    } else {
      mergeQueryFilters({ chartScale: 'sqrt' })
    }
  }, [mergeQueryFilters, queryFilters.chartScale])

  const { t } = useTranslation()

  return (
    <div className={classNames('group relative flex items-center justify-center rc-main-chart pt-8 h-[480px]', { 'max-h-[200px]': reduced })} id='rc-kompassify-main-chart'>
      {(sheet && (
        <DashboardMainChart
          data={sheet}
          // showScenario={showScenario}
          scenarioName={scenarioName}
          hideChartLines={queryFilters.hideChartLines && !queryFilters.hideChartBars}
          hideChartBars={queryFilters.hideChartBars && !queryFilters.hideChartLines}
          chartScale={queryFilters.chartScale}
        />
      )) || (
        <Spinner className='w-6 h-6 text-primary' />
      )}

      <div className='rounded-lg bg-white bg-opacity-80 p-2 border-b border-gray-200 absolute flex flex-row items-center -top-2 m-auto rc-show-on-main-chart-hover divide-x divide-gray-200 space-x-4 opacity-75 group-hover:opacity-100'>
        <div className='flex flex-row space-x-2'>
          <Tooltip mouseEnterDelay={0.4} title={t('dashboard.mainChart.tooltip.toggleLines')}>
            <Button primary={!queryFilters.hideChartLines} onClick={toggleLines} label={<PresentationChartLineIcon className='w-4 h-4' />} rounded className='' />
          </Tooltip>

          <Tooltip mouseEnterDelay={0.4} title={t('dashboard.mainChart.tooltip.toggleBars')}>
            <Button primary={!queryFilters.hideChartBars} onClick={toggleBars} label={<ChartBarIcon className='w-4 h-4' />} rounded />
          </Tooltip>
        </div>

        <div className='pl-4'>
          <Tooltip mouseEnterDelay={0.4} title={t('dashboard.mainChart.tooltip.toggleScale')}>
            <Button primary={queryFilters.chartScale === 'sqrt'} onClick={toggleScale} label={<BarsArrowUpIcon className='w-4 h-4' />} rounded />
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

// const useMergedDataWithPreMonth = ({ mergedData, realisedVsForecasted }) => {
//   return useMemo(() => {
//     const firstMonth = get(mergedData, 0)
//     if (!firstMonth) return mergedData

//     const firstMonthStart = get(mergedData, '0.unixStartOfMonth')
//     const firstMonthIsFuture = moment.unix(firstMonthStart).isAfter(startOfCurrentMonth)
//     const firstMonthIsCurrentMonth = moment.unix(firstMonthStart).isSame(startOfCurrentMonth)
//     const unixStartOfPreMonth = moment.unix(firstMonthStart).subtract(1, 'month').unix()
//     const globalEndingBalance = firstMonth.global.endingBalance - firstMonth.global.cashflow

//     const preMonth = {
//       ...getMonthDates(unixStartOfPreMonth),
//       global: { endingBalance: globalEndingBalance },
//       cashBudget: {},
//       scenario: {}
//     }

//     if (firstMonthIsCurrentMonth && !realisedVsForecasted) {
//       preMonth.scenario.endingBalance = globalEndingBalance
//     } else if ((firstMonth.scenario && (realisedVsForecasted || firstMonthIsFuture))) {
//       preMonth.scenario.endingBalance = firstMonth.scenario.endingBalance - firstMonth.scenario.cashflow
//     }

//     if (firstMonthIsCurrentMonth) {
//       preMonth.cashBudget.expectedEndingBalance = firstMonth.global.endingBalance
//     }

//     return [preMonth, ...mergedData]
//   }, [mergedData, realisedVsForecasted])
// }

// const getGlobalData = ({ globalData, i }) => {
//   const monthData = get(globalData, i)
//   const isCurrentOrPast = moment(monthData?.date).isSameOrBefore()

//   const result = {
//     cashflow: null,
//     endingBalance: null,
//     runway: monthData?.value?.runway
//   }

//   if (isCurrentOrPast) {
//     result.cashflow = monthData?.value?.cashflow
//     result.endingBalance = monthData?.value?.endingBalance
//   }

//   return result
// }

// const getCashBudgetData = ({ cashBudgetData, unixDate, i }) => {
//   const startOfMonth = moment.unix(unixDate)
//   const isPast = startOfMonth.isBefore(startOfCurrentMonth)
//   const isPrevMonth = startOfMonth.isSame(startOfPrevMonth)

//   if (isPrevMonth) {
//     const currentMonthStartingBalance = get(cashBudgetData, `${i + 1}.value.expectedStartingBalance`)
//     if (isNumber(currentMonthStartingBalance)) {
//       return { expectedEndingBalance: currentMonthStartingBalance }
//     }
//     return null
//   }
//   if (isPast) return null

//   const monthData = get(cashBudgetData, i)

//   return {
//     expectedCashflow: monthData?.value?.expectedCashflow,
//     expectedCashin: monthData?.value?.expectedCashin,
//     expectedCashout: monthData?.value?.expectedCashout,
//     expectedEndingBalance: monthData?.value?.expectedEndingBalance
//   }
// }

// const getShiftedScenarioEndingBalance = ({ isCurrentMonth, monthCashflow, globalMonth, prevMonthScenario }) => {
//   if (isCurrentMonth) {
//     const globalEndingBalance = globalMonth?.endingBalance || 0
//     const globalCashflow = globalMonth?.cashflow || 0
//     const startingBalance = globalEndingBalance - globalCashflow
//     return startingBalance + monthCashflow
//   }

//   const prevMonthEndingBalance = prevMonthScenario?.endingBalance || 0
//   return prevMonthEndingBalance + monthCashflow
// }

// const getScenarioData = ({ scenarioData, globalMonth, prevMonthScenario, realisedVsForecasted, unixDate, i }) => {
//   const monthData = get(scenarioData, i)

//   if (realisedVsForecasted) {
//     return {
//       cashin: monthData?.value?.cashin || null,
//       cashout: monthData?.value?.cashout || null,
//       cashflow: monthData?.value?.cashflow || null,
//       endingBalance: monthData?.value?.endingBalance || null,
//       runway: monthData?.value?.runway
//     }
//   }

//   const startOfMonth = moment.unix(unixDate)
//   const isPast = startOfMonth.isBefore(startOfCurrentMonth)
//   const isPrevMonth = startOfMonth.isSame(startOfPrevMonth)

//   if (isPrevMonth) return globalMonth
//   if (isPast) return null

//   const isCurrentMonth = startOfMonth.isSame(startOfCurrentMonth)
//   const monthCashflow = monthData?.value?.cashflow || 0

//   return {
//     cashin: monthData?.value?.cashin || null,
//     cashout: monthData?.value?.cashout || null,
//     cashflow: monthCashflow,
//     endingBalance: getShiftedScenarioEndingBalance({ isCurrentMonth, monthCashflow, globalMonth, prevMonthScenario }),
//     runway: monthData?.value?.runway
//   }
// }
