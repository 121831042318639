import { XMarkIcon } from '@heroicons/react/20/solid'
import { Drawer } from 'antd'
import RefundTransactionDrawerContent from 'components/transactions/RefundTransactionDrawerContent'
import Title from 'design/Title'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

export default ({ transaction }) => {
  const { t } = useTranslation()
  const [drawerVisible, setDrawerVisible] = useState(false)

  const openRefundTransactionDrawer = useCallback(() => {
    setDrawerVisible(true)
  }, [setDrawerVisible])

  const closeRefundTransactionDrawer = useCallback(() => {
    setDrawerVisible(false)
  }, [setDrawerVisible])

  const refundTransactionDrawer = (
    <Drawer
      title={<Title label={t('hook.useRefundTransaction.indicateRefund')} />}
      closeIcon={<XMarkIcon className='w-5 h-5' />}
      width={580}
      placement='right'
      onClose={closeRefundTransactionDrawer}
      open={drawerVisible}
    >
      <RefundTransactionDrawerContent
        transaction={transaction}
        closeRefundTransactionDrawer={closeRefundTransactionDrawer}
      />
    </Drawer>
  )

  return {
    openRefundTransactionDrawer,
    refundTransactionDrawer
  }
}
