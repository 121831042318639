import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { useQuery } from '@apollo/client'
import { GET_ACTIONS_LEFT } from 'graphql/aggregations'
import AggregationsActions from 'actions/AggregationsActions'

export default () => {
  const dispatch = useDispatch()

  const onCompleted = useCallback(({ actionsLeft }) => {
    dispatch(AggregationsActions.updateActionsLeft(actionsLeft))
  }, [dispatch])

  return useQuery(GET_ACTIONS_LEFT, { onCompleted, fetchPolicy: 'network-only' })
}
