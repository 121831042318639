import moment from 'moment'
import { useMemo } from 'react'

import { startOfThisMonth } from 'utils/dates'

const currentDateString = moment(startOfThisMonth).format('YYYY-MM')

export default function useCashflowSheetDates ({ sheet }) {
  return useMemo(() => {
    return (sheet || []).map(({ date }) => {
      const momentDate = moment(date, 'YYYY-MM')

      const isCurrentMonth = date === currentDateString
      const isFutureMonth = momentDate.isAfter(startOfThisMonth)
      const isPastMonth = !isCurrentMonth && !isFutureMonth

      return {
        date,
        momentDate,
        isPastMonth,
        isCurrentMonth,
        isFutureMonth
      }
    })
  }, [sheet])
}
