import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import NewExpectedTransactionDetailForm from 'components/expected-transaction-details/NewExpectedTransactionDetailForm'
import { useTranslation } from 'react-i18next'

const AddDetailToExpectedTransactionButton = ({ expectedTransaction }) => {
  const { t } = useTranslation()
  const [displayForm, setDisplayForm] = useState(false)

  if (displayForm === false) {
    return (
      <div className='w-full flex flex-row justify-center'>
        <Button type='dashed' icon={<PlusOutlined />} onClick={() => setDisplayForm(true)} className='rc-no-shadow'>
          {t('component.addDetailToExpectedTransactionButton.addDetail')}
        </Button>
      </div>
    )
  }

  return <NewExpectedTransactionDetailForm expectedTransaction={expectedTransaction} onCompleted={() => setDisplayForm(false)} />
}

AddDetailToExpectedTransactionButton.propTypes = {
  expectedTransaction: PropTypes.object
}

export default AddDetailToExpectedTransactionButton
