import { gql } from '@apollo/client'
import { ACCOUNTING_DOCUMENT_NESTED_EXPECTED_TRANSACTIONS_FIELDS } from './expected-transactions.js'
import { getAccountingDocumentFields, getAccountingDocumentListFields, getAddExpectedTransactionToAccountingDocumentFields } from './accounting-documents.js'

const ACCOUNTING_DOCUMENTS_FIELDS = getAccountingDocumentFields('Quote')
const ACCOUNTING_DOCUMENTS_LIST_FIELDS = getAccountingDocumentListFields('Quote')
const ADD_EXPECTED_TRANSACTION_TO_ACCOUNTING_DOCUMENT_FIELDS = getAddExpectedTransactionToAccountingDocumentFields('Quote')

export const GET_QUOTE = gql`
  ${ACCOUNTING_DOCUMENTS_FIELDS}
  ${ACCOUNTING_DOCUMENT_NESTED_EXPECTED_TRANSACTIONS_FIELDS}
  query getQuote(
    $id: ID!
  ) {
    getQuote(
      id: $id
    ) {
      ...QuotesFields
      quoteNumber
      expectedTransactions {
        ...accountingDocumentNestedExpectedTransactionsFields
      }
    }
  }
`

export const LIST_QUOTES = gql`
  ${ACCOUNTING_DOCUMENTS_LIST_FIELDS}
  ${ACCOUNTING_DOCUMENT_NESTED_EXPECTED_TRANSACTIONS_FIELDS}
  query allQuotes(
    $offset: Int
    $limit: Int!
    $search: String
    $stateFilter: QuoteStateFilterType
    $withIgnored: Boolean
  ) {
    allQuotes(
      offset: $offset
      limit: $limit
      search: $search
      stateFilter: $stateFilter
      withIgnored: $withIgnored
    ) {
      total
      quotes {
        ...QuotesListFields
        quoteNumber
        documentUrl
        expectedTransactions {
         ...accountingDocumentNestedExpectedTransactionsFields
        }
      }
    }
  }
`

export const IGNORE_QUOTES = gql`
  mutation ignoreQuotes($input: IgnoreQuotesInput!) {
    ignoreQuotes(input: $input) {
      id
      ignored
    }
  }
`

export const ADD_EXPECTED_TRANSACTION_TO_QUOTE = gql`
  ${ADD_EXPECTED_TRANSACTION_TO_ACCOUNTING_DOCUMENT_FIELDS}
  mutation addExpectedTransactionToQuote($input: AddExpectedTransactionToQuoteInput!) {
    addExpectedTransactionToQuote(input: $input) {
      ...addExpectedTransactionToQuoteFields
    }
  }
`

export const CREATE_QUOTE_MANUALLY = gql`
  mutation createQuoteManually($input: CreateQuoteManuallyInput!) {
    createQuoteManually(input: $input) {
      id
    }
  }
`

export const UPDATE_QUOTE_MANUALLY = gql`
  mutation updateQuoteManually($input: UpdateQuoteManuallyInput!) {
    updateQuoteManually(input: $input) {
      id
    }
  }
`

export const DELETE_QUOTE_FROM_IDS = gql`
  mutation deleteQuotesFromIds($quoteIds: [ID!]!) {
    deleteQuotesFromIds(quoteIds: $quoteIds) {
      deletedCount
    }
  }
`
