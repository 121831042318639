import React, { useEffect, useContext, useMemo } from 'react'
import { get } from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { useTranslation, Trans } from 'react-i18next'

import OnboardingHeader from 'components/onboarding/OnboardingHeader'
import OnboardingFooter from 'components/onboarding/OnboardingFooter'
import OnboardingContext from 'components/onboarding/OnboardingContext'
import TransactionTable from 'components/transactions/TransactionTable'
import SynchronizingInfo from 'components/onboarding/SynchronizingInfo'
import { LIST_ALL_TRANSACTION } from 'graphql/transactions'
import useBankConnectionReady from 'hooks/useBankConnectionReady.js'

export default () => {
  const { t } = useTranslation()
  const context = useContext(OnboardingContext)
  const bankConnectionReady = useBankConnectionReady()

  const [listLastTransactions, { loading, data }] = useLazyQuery(LIST_ALL_TRANSACTION, {
    variables: {
      type: 'cashout',
      offset: 0,
      limit: 10
    }
  })
  const lastTransactions = useMemo(() => data?.allTransactions?.transactions || [], [data])

  const numberOfCategorizedTransactions = useMemo((state) => {
    return lastTransactions.filter((transaction) => {
      return !!get(transaction, 'categoryId')
    }).length
  }, [lastTransactions])

  useEffect(() => {
    if (bankConnectionReady) listLastTransactions()
  }, [bankConnectionReady, listLastTransactions])

  useEffect(() => {
    if (numberOfCategorizedTransactions >= 10) {
      context.moveToNextStep()
    }
  }, [numberOfCategorizedTransactions, context])

  return (
    <div className='mt-20 flex flex-col items-center w-full'>
      {(!bankConnectionReady && (
        <div className='rc-onboarding-screen-container'>
          <SynchronizingInfo />
        </div>
      )) || (
        <>
          <div className='rc-onboarding-screen-container'>
            <OnboardingHeader
              title={(
                <div>
                  <Trans
                    i18nKey='onboarding.onboardingCategorizeFirstTransactions.title'
                    components={{
                      Span: <span className='rc-highlight-text-cashout' />
                    }}
                  />

                </div>
              )}
            />
            <div className='text-lg'>
              {t('onboarding.onboardingCategorizeFirstTransactions.description')}
            </div>
          </div>

          <div className='w-full mt-8 px-2'>
            <TransactionTable
              type='cashout'
              dataSource={data?.allTransactions?.transactions}
              loading={loading}
              lean
              pagination={false}
            />
          </div>

          <div className='rc-onboarding-screen-container mt-4'>
            <OnboardingFooter
              prev
              next
              nextLabel={t('onboarding.onboardingCategorizeFirstTransactions.finish')}
              // nextDisabled={numberOfCategorizedTransactions === 0 || data?.allTransactions?.transactions?.length === 0}
            />
          </div>
        </>
      )}
    </div>
  )
}
