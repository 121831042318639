import { useQuery } from '@apollo/client'
import resourcesActions from 'actions/ResourcesActions'
import { LIST_INVOICES } from 'graphql/invoices'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import ExpectedDocumentsConnectorList from 'components/connectors/ExpectedDocumentsConnectorList'
import InvoiceAmounts from './InvoiceAmounts'
import InvoiceSearchInput from './InvoiceSearchInput'
import InvoiceTable from './InvoiceTable'
import usePagination from 'hooks/pagination/usePagination.js'

const InvoiceList = ({ cashType }) => {
  const dispatch = useDispatch()
  const [search, setSearch] = useState(null)
  const [stateFilter, setStateFilter] = useState('ONGOING')
  const [withIgnored, setWithIgnored] = useState(true)

  const { pagination, page, pageSize, updatePaginationTotal, resetPagination } = usePagination()

  const { loading, data, refetch } = useQuery(LIST_INVOICES, {
    variables: {
      type: cashType,
      offset: (page - 1) * pageSize,
      limit: pageSize,
      search,
      stateFilter,
      withIgnored
    },
    onCompleted: (data) => { updatePaginationTotal(data?.allInvoices?.total) }
  })

  useEffect(() => {
    resetPagination()
  }, [resetPagination, search])

  useEffect(() => {
    dispatch(resourcesActions.READ_CATEGORIES(cashType))
  }, [dispatch, cashType])

  const onSearchChanged = useCallback((text) => {
    setSearch(text)
    resetPagination()
  }, [resetPagination])

  const onStateFilterChanged = useCallback((value) => {
    setStateFilter(value)
    resetPagination()
  }, [resetPagination])

  const onWithIgnoredChanged = useCallback((value) => {
    setWithIgnored(value)
    resetPagination()
  }, [resetPagination])

  return (
    <div>
      <div className='flex flex-row items-center mb-3 flex-wrap justify-end'>
        <InvoiceSearchInput
          loading={loading}
          onSearchChanged={onSearchChanged}
          onStateFilterChanged={onStateFilterChanged}
          onWithIgnoredChanged={onWithIgnoredChanged}
        />
        <InvoiceAmounts className='grow' type={cashType} search={search} stateFilter={stateFilter} />
        <ExpectedDocumentsConnectorList type={cashType} refetchInvoices={refetch} enableManualImports />
      </div>

      <InvoiceTable
        type={cashType}
        loading={loading}
        dataSource={data?.allInvoices?.invoices}
        pagination={pagination}
        refetchInvoices={refetch}
      />
    </div>
  )
}

InvoiceList.propTypes = {
  cashType: PropTypes.oneOf(['cashin', 'cashout'])
}

export default InvoiceList
