import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Modal, Tooltip } from 'antd'
import { ArrowUpTrayIcon } from '@heroicons/react/24/solid'
import ImportTransactionsContent from './ImportTransactionsContent.js'
import cx from 'classnames'
import ButtonLink from 'design/ButtonLink'
import featureConfig from 'config/features.js'

ImportModal.propTypes = {
  refetchTransactions: PropTypes.func
}

export default function ImportModal ({ refetchTransactions }) {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)

  if (!featureConfig.importTransactionsEnabled) {
    return null
  }

  return (
    <>
      <Modal
        title={t('component.importTransactions.modalTitle')}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width={700}
        destroyOnClose
      >
        <ImportTransactionsContent refetchTransactions={refetchTransactions} closePanel={() => { setVisible(false) }} />
      </Modal>

      <Tooltip placement='top' mouseEnterDelay={0.4} title={t('component.importTransactions.buttonTitle')} className={cx('ml-2')}>
        <ButtonLink
          onClick={() => setVisible(true)}
          label={<ArrowUpTrayIcon className={cx('w-5 h-5')} />}
        />
      </Tooltip>
    </>
  )
}
