import { createActions } from 'redux-actions'

const actions = createActions({
  REQUEST_TRANSACTIONS_TOTAL: (year) => ({ year }),
  REQUEST_TRANSACTIONS_TOTAL_SUCCESS: (data) => ({ data }),
  REQUEST_TRANSACTIONS_TOTAL_ERROR: undefined,

  REQUEST_CASHOUT_TOTAL: (year, categories) => ({ year, categories }),
  REQUEST_CASHOUT_TOTAL_SUCCESS: (year, data) => ({ year, data }),
  REQUEST_CASHOUT_TOTAL_ERROR: undefined,

  REQUEST_CASHIN_TOTAL: (year, categories) => ({ year, categories }),
  REQUEST_CASHIN_TOTAL_SUCCESS: (year, data) => ({ year, data }),
  REQUEST_CASHIN_TOTAL_ERROR: undefined,

  REQUEST_TO_VALIDATE_TOTAL: (type) => ({ type }),
  REQUEST_TO_VALIDATE_TOTAL_SUCCESS: (type, total) => ({ type, total }),
  REQUEST_TO_VALIDATE_TOTAL_ERROR: undefined,

  UPDATE_ACTIONS_LEFT: (actionsLeft) => ({ actionsLeft })
})

const extendedActions = {
  ...actions,
  requestTransactionTypeTotal: (type, year) => {
    switch (type) {
      case 'cashin':
        return actions.requestCashinTotal(year)
      case 'cashout':
        return actions.requestCashoutTotal(year)
      default:
        console.warn(`Wrong type: ${type}`)
        break
    }
  }
}

export default extendedActions
