import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Badge from 'design/Badge'
import i18next from 'i18next'

const badgeTypes = {
  ONGOING: 'primary',
  PARTIAL: 'warning',
  PAID: 'success',
  LATE: 'error',
  IGNORED: 'disabled',
  DRAFT: 'disabled',
  CANCELED: 'disabled'
}

const InvoiceStatusBadge = ({ invoice, style, ...rest }) => {
  const computedStatus = useMemo(() => {
    return invoice.ignored ? 'IGNORED' : invoice.status
  }, [invoice])

  const badgeType = badgeTypes[computedStatus]
  const { t } = i18next

  return (
    <Badge
      type={badgeType}
      className='text-center min-w-max'
      label={t('component.invoiceStatus.label', { context: computedStatus })}
      {...rest}
    />
  )
}

InvoiceStatusBadge.propTypes = {
  invoice: PropTypes.object,
  checkable: PropTypes.bool,
  style: PropTypes.object
}

export default InvoiceStatusBadge
