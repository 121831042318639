import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid'
import { useTranslation } from 'react-i18next'

ReduceDashboardButton.propTypes = {
  queryFilters: PropTypes.object,
  mergeQueryFilters: PropTypes.func
}

export default function ReduceDashboardButton ({ queryFilters, mergeQueryFilters }) {
  const { t } = useTranslation()
  const toggleReduced = useCallback(() => {
    mergeQueryFilters({ reduced: !queryFilters.reduced })
  }, [queryFilters.reduced, mergeQueryFilters])

  return (
    <Tooltip title={queryFilters.reduced ? t('dashboard.reduceDashboard.increaseDisplay') : t('dashboard.reduceDashboard.reduceDisplay')} placement='right' mouseLeaveDelay={0.001} trigger={['hover', 'click']}>
      <button onClick={toggleReduced} className='absolute m-auto right-0 -top-3 px-3 bg-white rounded-t-lg outline-none'>
        {(queryFilters.reduced && (
          <ChevronDownIcon className='w-4 h-4' />
        )) || (
          <ChevronUpIcon className='w-4 h-4' />
        )}
      </button>
    </Tooltip>
  )
}
