import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import InvoiceList from 'components/invoices/InvoiceList.js'
import QuoteList from 'components/quotes/QuoteList.js'
import ScreenSwitch from 'design/ScreenSwitch.js'
import CashType from 'enums/CashType.js'
import RecurringPaymentList from 'components/recurring-payments/RecurringPaymentList.js'
import useConnections from 'hooks/connections/useConnections.js'
import OrderList from 'components/orders/OrderList.js'

export default function CashInExpectedDocuments () {
  const { t } = useTranslation()
  const { sellsyConnection } = useConnections()

  const screens = useMemo(() => {
    const screens = []

    screens.push({
      label: t('cashInScreen.quotes'),
      component: <QuoteList cashType={CashType.CASHIN} />
    })

    if (sellsyConnection) {
      screens.push({
        label: t('cashInScreen.orders'),
        component: <OrderList />
      })
    }

    screens.push({
      label: t('cashInScreen.invoices'),
      component: <InvoiceList cashType={CashType.CASHIN} />
    })

    if (sellsyConnection) {
      screens.push({
        label: t('cashInScreen.recurringPayments'),
        component: <RecurringPaymentList />
      })
    }

    return screens
  }, [t, sellsyConnection])

  return (
    <ScreenSwitch screens={screens} className='mb-4' />
  )
}
