import { gql } from '@apollo/client'

export const LIST_TRANSACTION_RECEIPTS = gql`
  query listTransactionReceipts(
    $transactionId: ID!
  ) {
    receipts(transactionId: $transactionId) {
      id
      document {
        id
        filename
        url
      }
    }
  }
`

export const CREATE_RECEIPT = gql`
  mutation createReceipt(
    $transactionId: ID!
    $documentFile: Upload!
  ) {
    createReceipt(
      transactionId: $transactionId
      documentFile: $documentFile
    ) {
      id
      transaction {
        id
        receiptCount
      }
      document {
        id
        filename
        url
      }
    }
  }
`

export const DELETE_RECEIPT = gql`
  mutation deleteReceipt($id: ID!) {
    deleteReceipt(id: $id) {
      id
      transaction {
        id
        receiptCount
      }
    }
  }
`
