import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import { get } from 'lodash'

const HomeScreen = () => {
  const isAuthenticated = useSelector(state => get(state, 'app.isAuthenticated'))

  if (isAuthenticated) return <Redirect to='/dashboard' />

  return <Redirect to='/signin' />
}

export default HomeScreen
