import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useDebouncedCallback } from 'use-debounce'
import { useMutation } from '@apollo/client'

import ValidationButton from 'components/ValidationButton'
import { VALIDATE_TRANSACTION } from 'graphql/transactions'

const TransactionValidate = ({ transaction, onValidate }) => {
  const [validateTransactionMutation] = useMutation(VALIDATE_TRANSACTION, { onCompleted: onValidate })
  const [success, setSuccess] = useState(false)

  const validateTransaction = useCallback((validated) => {
    if (validated) validateTransactionMutation({ variables: { id: transaction.id } })
  }, [transaction, validateTransactionMutation])

  const [debouncedUpdateTransaction] = useDebouncedCallback(validateTransaction, 750)

  const handleOnClick = useCallback(() => {
    const newSuccess = !success
    setSuccess(newSuccess)
    debouncedUpdateTransaction(newSuccess)
  }, [setSuccess, success, debouncedUpdateTransaction])

  return (
    <ValidationButton onClick={handleOnClick} validated={success} withValidationProgress block />
  )
}

TransactionValidate.propTypes = {
  transaction: PropTypes.object,
  onValidate: PropTypes.func
}

export default TransactionValidate
