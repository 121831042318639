import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import { useTranslation } from 'react-i18next'

const MonthSelect = React.forwardRef(({ withoutBorder, allYear, ...rest }, ref) => {
  const { t } = useTranslation()
  return (
    <Select
      ref={ref}
      className={`w-full ${withoutBorder ? 'rc-select-without-border' : null}`}
      {...rest}
    >
      {allYear && (
        <Select.OptGroup label={t('shared.Year')}>
          <Select.Option value='allYear'>{t('shared.FullYear')}</Select.Option>
        </Select.OptGroup>
      )}
      <Select.OptGroup label='Mois'>
        <Select.Option value={1}>{t('shared.month.January')}</Select.Option>
        <Select.Option value={2}>{t('shared.month.February')}</Select.Option>
        <Select.Option value={3}>{t('shared.month.March')}</Select.Option>
        <Select.Option value={4}>{t('shared.month.April')}</Select.Option>
        <Select.Option value={5}>{t('shared.month.May')}</Select.Option>
        <Select.Option value={6}>{t('shared.month.June')}</Select.Option>
        <Select.Option value={7}>{t('shared.month.July')}</Select.Option>
        <Select.Option value={8}>{t('shared.month.August')}</Select.Option>
        <Select.Option value={9}>{t('shared.month.September')}</Select.Option>
        <Select.Option value={10}>{t('shared.month.October')}</Select.Option>
        <Select.Option value={11}>{t('shared.month.November')}</Select.Option>
        <Select.Option value={12}>{t('shared.month.December')}</Select.Option>
      </Select.OptGroup>
    </Select>
  )
})

MonthSelect.defaultProps = {
  allYear: true
}

MonthSelect.propTypes = {
  withoutBorder: PropTypes.bool,
  allYear: PropTypes.bool
}

export default MonthSelect
