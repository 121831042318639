import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import Currency from 'components/Currency'
import { c } from 'utils/currencies'
import featureConfig from 'config/features.js'

const RecurringPaymentAmountPreview = ({ recurringPayment }) => {
  return (
    <div className='flex flex-row justify-end items-center'>

      {featureConfig.vatDetailsEnabled
        ? (
          <Tooltip title={`${c(recurringPayment.unitAmountExcludingVat)} HT`}>
            <Currency amount={recurringPayment.unitAmount} strong />
          </Tooltip>
        ) : (
          <Currency amount={recurringPayment.unitAmount} strong />
        )}
    </div>
  )
}

RecurringPaymentAmountPreview.propTypes = {
  recurringPayment: PropTypes.object
}

export default RecurringPaymentAmountPreview
