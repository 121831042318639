import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Input, message } from 'antd'
import { useMutation } from '@apollo/client'

import Button from 'design/Button'

import useHandleFormValidationErrors from 'hooks/useHandleFormValidationErrors'
import UserRoleSelect from './UserRoleSelect'
import { INVITE_USER } from 'graphql/users'
import { get } from 'lodash'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

InviteUserForm.propTypes = {
  onCompleted: PropTypes.func,
  onCancel: PropTypes.func
}

function InviteUserForm ({ onCompleted, onCancel }) {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const handleValidationErrors = useHandleFormValidationErrors({ form })
  const [customErr, setCustomErr] = useState(null)
  const history = useHistory()

  const [inviteUser, { loading }] = useMutation(INVITE_USER, {
    onError: (e) => {
      const errorCode = get(e, 'graphQLErrors.0.extensions.code')

      switch (errorCode) {
        case 'ONLY_ADMIN_CAN_INVITE_USER':
          setCustomErr(t('users.inviteUserForm.onlyAdminCanInviteUser'))
          break
        case 'EMAIL_ALREADY_USED':
          setCustomErr(t('users.inviteUserForm.emailAlreadyInUse'))
          break
        case 'INCLUDED_USERS_QUANTITY_REACHED':
          history.push('/subscribe?r=u')
          break
        default: {
          const isValidation = !!e.graphQLErrors.find((e) => e.message === 'Validation')
          if (isValidation) handleValidationErrors(e)
          else message.error(t('users.inviteUserForm.anErrorHasOccuredWhileInvitingUser'))
          break
        }
      }
    },
    onCompleted
  })

  const onFinish = useCallback((formValues) => {
    setCustomErr(null)
    inviteUser({
      variables: {
        input: formValues
      }
    })
  }, [inviteUser, setCustomErr])

  return (
    <Form form={form} layout='horizontal' onFinish={onFinish} className='w-full flex flex-col' requiredMark='optional'>
      <div className='w-full flex flex-row items-start space-x-4'>
        <div className='w-4/12 flex flex-row items-start'>
          <Form.Item
            name='firstName'
            rules={[{ required: true, message: t('users.inviteUserForm.firstNameRequired') }]}
            className='w-1/2 pr-2 mb-0'
          >
            <Input placeholder={t('shared.firstName')} autoFocus />
          </Form.Item>

          <Form.Item
            name='lastName'
            rules={[{ required: true, message: t('users.inviteUserForm.lastNameRequired') }]}
            className='w-1/2 pl-2 mb-0'
          >
            <Input placeholder={t('shared.lastName')} />
          </Form.Item>

        </div>

        <Form.Item
          name='email'
          rules={[{ type: 'email', message: t('users.inviteUserForm.pleaseTypeAValidEmail') }, { required: true, message: t('users.inviteUserForm.emailRequired') }]}
          className='w-5/12 mb-0'
        >
          <Input placeholder={t('shared.emailAddress')} />
        </Form.Item>

        <Form.Item
          initialValue='collaborator'
          name='role'
          rules={[{ required: true, message: t('users.inviteUserForm.roleRequired') }]}
          className='w-3/12 mb-0'
        >
          <UserRoleSelect />
        </Form.Item>
      </div>

      {customErr && (
        <div className='w-full text-error mt-2'>{customErr}</div>
      )}

      <div className='mb-0 mt-4 w-full flex flex-row items-center justify-end space-x-4'>
        <Button onClick={onCancel} disabled={loading} label={t('shared.cancel')} />
        <Form.Item className='mb-0'>
          <Button primary disabled={loading} loading={loading} type='submit' label={t('users.inviteUserForm.sendInvitation')} />
        </Form.Item>
      </div>
      <div className='text-xs text-gray-400 mt-2 text-right'>{t('users.inviteUserForm.invitationWillBeSent')}</div>
    </Form>
  )
}

export default InviteUserForm
