import { combineReducers } from 'redux'
import { resourceReducer } from 'redux-resource'
import { reset } from 'redux-resource-plugins'
import app, { appInitialState } from './app-reducer'
import auth from './auth-reducer'
import aggregations from './aggregations-reducer'
import categoryTrees from './category-trees-reducer'
// import company from './company-reducer'
import vat from './vat-reducer'

const rootReducer = (state, action) => {
  if (action.type === 'SIGN_OUT') {
    // Keeps some settings, not account specific
    state = { app: { ...appInitialState, dashboard: state?.app?.dashboard, pageSize: state?.app?.pageSize } }
  }

  return combineReducers({
    aggregations,
    app,
    auth,
    categoryTrees,
    vat,

    // Resources
    categories: resourceReducer('categories', { plugins: [reset] }),
    receipts: resourceReducer('receipts'),
    scenarios: resourceReducer('scenarios'),
    vatRates: resourceReducer('vatRates')
  })(state, action)
}

export default rootReducer
