
import PropTypes from 'prop-types'
import React, { useState, useCallback } from 'react'
import { GET_QUOTE, UPDATE_QUOTE_MANUALLY } from 'graphql/quotes'
import { useQuery, useMutation } from '@apollo/client'
import { isUndefined } from 'lodash'
import QuoteEditDrawer from './QuoteEditDrawer.js'

UpdateQuoteManuallyDrawer.propTypes = {
  quoteId: PropTypes.string,
  type: PropTypes.string,
  visible: PropTypes.bool,
  onClose: PropTypes.func
}

function UpdateQuoteManuallyDrawer ({ quoteId, visible, onClose, type }) {
  const [quote, setQuote] = useState(undefined)
  const [updateQuoteManually, { loading: updateLoading }] = useMutation(UPDATE_QUOTE_MANUALLY, {
    onCompleted: () => {
      onClose()
    }
  })

  useQuery(GET_QUOTE, {
    variables: {
      id: quoteId
    },
    skip: isUndefined(quoteId),
    onCompleted: (data) => {
      setQuote(data.getQuote)
    }
  }, [setQuote, quoteId])

  const onFinishCallback = useCallback(({ quoteParams }) => {
    updateQuoteManually({
      variables: {
        input: {
          ...quoteParams,
          id: quoteId
        }
      }
    })
  }, [updateQuoteManually, quoteId])

  return (<QuoteEditDrawer mode='update' quote={quote} type={type} loading={updateLoading} visible={visible} onFinish={onFinishCallback} onClose={onClose} />)
}

export default UpdateQuoteManuallyDrawer
