import { Card, Col, Layout, Row } from 'antd'
import moment from 'moment-timezone'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import YearSelect from 'components/YearSelect'
import VatSettingsForm from 'components/vat/VatSettingsForm'
import VatYearTable from 'components/vat/VatYearTable'
import Title from 'design/Title'

const now = moment()

const VatScreen = () => {
  const { t } = useTranslation()
  const [year, setYear] = useState(now.year())

  return (
    <Layout>
      <Layout.Content className='rc-content'>
        <Card className='flex flex-row items-start'>
          <Title label={t('vat.vatSettings')} className='mb-4' />

          <VatSettingsForm />
        </Card>

        <Card className='rc-margin-top'>
          <Title label={t('vat.vatHistory')} className='mb-4' />

          <Row>
            <Col xs={12} lg={4}>
              <YearSelect onChange={(year) => setYear(year)} defaultValue={year} />
            </Col>
          </Row>

          <Row className='rc-margin-top'>
            <Col xs={24}>
              <VatYearTable year={year} />
            </Col>
          </Row>
        </Card>
      </Layout.Content>
    </Layout>
  )
}

export default VatScreen
