
import AppActions from 'actions/AppActions'
import { get } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const queryFilters = { withIgnored: true }

export default function useOrdersQueryFilters () {
  const dispatch = useDispatch()

  const memorizedQueryFilters = useSelector(state => get(state, 'app.orders.queryFilters', queryFilters))
  const [ordersQueryFilters, setOrdersQueryFilters] = useState(memorizedQueryFilters)

  const mergeOrdersQueryFilters = useCallback((filters) => {
    setOrdersQueryFilters({ ...ordersQueryFilters, ...filters })
  }, [ordersQueryFilters, setOrdersQueryFilters])

  // REHYDRATE QUERY FILTERS
  useEffect(() => {
    if (memorizedQueryFilters !== ordersQueryFilters) {
      dispatch(AppActions.setOrdersQueryFilters(ordersQueryFilters))
    }
  }, [dispatch, ordersQueryFilters, memorizedQueryFilters])

  return {
    ordersQueryFilters,
    mergeOrdersQueryFilters
  }
}
