import { Typography } from 'antd'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Dot from 'components/Dot'
import colors from 'utils/colors'

const CashIndicator = ({ type, text, style }) => {
  const { t } = useTranslation()
  const name = useMemo(() => {
    if (text) return text

    switch (type) {
      case 'cashin':
        return t('shared.cashinPlural')
      case 'cashout':
        return t('shared.cashoutPlural')
      default:
        return t('component.cashIndicator.cashflow')
    }
  }, [type, text, t])

  const color = useMemo(() => {
    switch (type) {
      case 'cashin':
        return colors.lightSuccess
      case 'cashout':
        return colors.lightError
      default:
        return colors.lightPrimary
    }
  }, [type])

  return (
    <div className='flex items-center' style={style}>
      <Dot color={color} className='mr-2' />
      <Typography.Text>{name}</Typography.Text>
    </div>
  )
}

CashIndicator.propTypes = {
  type: PropTypes.oneOf(['cashin', 'cashout']),
  text: PropTypes.string,
  style: PropTypes.object
}

export default CashIndicator
