import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'

import AccountingDocumentExpectedTransactions from 'components/accounting-documents/AccountingDocumentExpectedTransactions.js'

AccountingDocumentTable.propTypes = {
  type: PropTypes.oneOf(['cashin', 'cashout']),
  refetchAccountingDocuments: PropTypes.func,
  editAccountingDocument: PropTypes.func,
  getColumns: PropTypes.func
}

export default function AccountingDocumentTable ({ type, refetchAccountingDocuments, editAccountingDocument, getColumns, ...rest }) {
  const [expandedRowKeys, setExpandedRowKeys] = useState([])

  const toggleRowExpand = useCallback((rowKeyToToggle) => {
    if (expandedRowKeys.includes(rowKeyToToggle)) {
      setExpandedRowKeys([])
    } else {
      setExpandedRowKeys([rowKeyToToggle])
    }
  }, [setExpandedRowKeys, expandedRowKeys])

  const getRowClassName = useCallback((accountingDocument) => {
    const classNames = ['rc-accounting-document-table-row', 'rc-absolute-row']

    if (expandedRowKeys.includes(accountingDocument.id)) classNames.push('expanded')

    if (accountingDocument.ignored) classNames.push('rc-table-row-ignored')

    return classNames.join(' ')
  }, [expandedRowKeys])

  const columns = useMemo(() => getColumns({ type, toggleRowExpand, refetchAccountingDocuments, editAccountingDocument }), [getColumns, type, toggleRowExpand, refetchAccountingDocuments, editAccountingDocument])

  return (
    <Table
      columns={columns}
      expandable={{
        expandedRowClassName: () => 'rc-accounting-document-table-expanded-row',
        expandedRowRender: (accountingDocument) => <div className='flex flex-col items-center'><AccountingDocumentExpectedTransactions accountingDocument={accountingDocument} /></div>,
        expandedRowKeys,
        onExpand: (expanded, accountingDocument) => setExpandedRowKeys(expanded ? [accountingDocument.id] : [])
      }}
      rowKey={accountingDocument => accountingDocument.id}
      rowClassName={getRowClassName}
      className='rc-accounting-document-table'
      {...rest}
    />
  )
}
