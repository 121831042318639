import React, { useCallback } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { Input } from 'antd'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import cx from 'classnames'

const SearchInput = ({ onSearchChanged, left, className }) => {
  const { t } = useTranslation()
  const [searchDebounced] = useDebouncedCallback(search => {
    onSearchChanged(search)
  }, 250)

  const handleSearchChanged = useCallback((event) => {
    const search = event.target.value
    searchDebounced(search === '' ? null : search)
  }, [searchDebounced])

  return (
    <div className={cx(className, 'flex flex-row items-center bg-white p-1.5 rounded-md mr-1')}>

      {left}

      <Input
        placeholder={t('component.invoiceSearchInput.search')}
        onChange={handleSearchChanged}
        bordered={false}
        allowClear
        className='w-56'
      />

      <MagnifyingGlassIcon className='w-5 h-5 text-gray-400 mr-1' />
    </div>

  )
}

SearchInput.propTypes = {
  onSearchChanged: PropTypes.func,
  left: PropTypes.node,
  className: PropTypes.string
}

export default SearchInput
