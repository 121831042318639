
import React from 'react'

import SettingsOverlay from './SettingsOverlay'
import { Popover } from '@headlessui/react'
import { Cog6ToothIcon } from '@heroicons/react/24/outline'

const SettingsButton = () => {
  return (
    <Popover placement='bottomRight'>
      <Popover.Button className='outline-none'>
        <div className='rc-sider-link' id='rc-kompassify-settings-button'>
          <Cog6ToothIcon className='rc-settings-icon w-5 h-5' />
        </div>
      </Popover.Button>

      <Popover.Panel unmount={false} className='absolute z-10 bottom-6 left-20'>
        <SettingsOverlay />
      </Popover.Panel>
    </Popover>
  )
}

export default SettingsButton
