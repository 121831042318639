import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Form, Button } from 'antd'
import { useMutation } from '@apollo/client'

import CategorySelect from 'components/categories/CategorySelect'
import VatSelect from 'components/vat/VatSelect'
import CurrencyInput from 'components/CurrencyInput'
import { ADD_DETAIL_TO_EXPECTED_TRANSACTION } from 'graphql/expected-transactions'
import useHandleFormValidationErrors from 'hooks/useHandleFormValidationErrors'
import { useTranslation } from 'react-i18next'

const NewExpectedTransactionDetailForm = ({ expectedTransaction, onCompleted }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const handleValidationErrors = useHandleFormValidationErrors({ form })

  const [addDetailToExpectedTransaction, { loading }] = useMutation(ADD_DETAIL_TO_EXPECTED_TRANSACTION, {
    onError: handleValidationErrors,
    onCompleted
  })

  const onFinish = useCallback((formValues) => {
    addDetailToExpectedTransaction({
      variables: {
        input: {
          expectedTransactionId: expectedTransaction.id,
          newExpectedTransactionDetail: formValues
        }
      }
    })
  }, [addDetailToExpectedTransaction, expectedTransaction.id])

  return (
    <Form form={form} layout='inline' onFinish={onFinish} requiredMark='optional'>
      <Form.Item name='categoryId' label={t('shared.category')}>
        <CategorySelect type={expectedTransaction.type} />
      </Form.Item>

      <Form.Item name='vatRate' label={t('shared.vatRate')} initialValue={0.2} rules={[{ required: true }]}>
        <VatSelect />
      </Form.Item>

      <Form.Item name='amount' label={t('shared.amountInclTax')} initialValue={0} rules={[{ required: true }]}>
        <CurrencyInput options={{ precision: 2 }} />
      </Form.Item>

      <Form.Item>
        <Button type='primary' htmlType='submit' disabled={loading} loading={loading}>
          {t('shared.add')}
        </Button>
      </Form.Item>
    </Form>
  )
}

NewExpectedTransactionDetailForm.propTypes = {
  expectedTransaction: PropTypes.object,
  onCompleted: PropTypes.func
}

export default NewExpectedTransactionDetailForm
