import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { get } from 'lodash'

const RowCategoryBorder = ({ categoryId, style }) => {
  const categoryColor = useSelector(state => get(state, `categories.resources.${categoryId}.color`, '#fafafa'))

  return (
    <div
      className='rc-light-left-shadow rc-category-border absolute top-0 rounded-l-md'
      style={{
        background: categoryColor,
        bottom: 1,
        width: 6,
        left: -6,
        ...style
      }}
    />
  )
}

RowCategoryBorder.propTypes = {
  categoryId: PropTypes.string,
  style: PropTypes.object
}

export default RowCategoryBorder
