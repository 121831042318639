import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation, useQuery } from '@apollo/client'

import useSearchParams from 'hooks/useSearchParams'

import { REGISTER_POWENS_TOKEN } from 'graphql/powens'
import { LIST_CONNECTIONS } from 'graphql/connections.js'
import useBankConnectionReady from './useBankConnectionReady.js'

export default ({ shouldStart }) => {
  const dispatch = useDispatch()
  const { code } = useSearchParams()
  const [registered, setRegistered] = useState(false)

  const [registerPowensToken] = useMutation(REGISTER_POWENS_TOKEN, {
    variables: { temporaryCode: code }
  })

  const bankConnectionReady = useBankConnectionReady()

  const { startPolling, stopPolling } = useQuery(LIST_CONNECTIONS, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 5000
  })

  useEffect(() => {
    if (code && !registered) {
      setRegistered(true)
      registerPowensToken()
      // stopPolling()
    } else if (shouldStart) {
      if (!bankConnectionReady) {
        startPolling(5000)
      } else {
        stopPolling()
      }

      return stopPolling
    } else {
      stopPolling()
    }
  }, [registered, setRegistered, registerPowensToken, code, dispatch, shouldStart, startPolling, stopPolling, bankConnectionReady])
}
