import { useMutation } from '@apollo/client'
import { Dropdown, Tooltip } from 'antd'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'

import { ArrowUturnLeftIcon, ChartBarIcon, CheckIcon, EyeIcon, EyeSlashIcon, ReceiptPercentIcon, ArrowPathIcon } from '@heroicons/react/24/solid'
import ActionsIcon from 'design/ActionsIcon'
import { CANCEL_TRANSACTION_REFUND, MARK_TRANSACTION_AS_VAT_PAYMENT, UNMARK_TRANSACTION_AS_VAT_PAYMENT, IGNORE_TRANSACTION, UNIGNORE_TRANSACTION } from 'graphql/transactions'
import useRefundTransaction from 'hooks/useRefundTransaction'
import useSplitTransaction from 'hooks/useSplitTransaction'
import { useTranslation } from 'react-i18next'
import DocumentDrawerContainer from './DocumentDrawerContainer'
import ReconciliationDrawerContainer from './ReconciliationDrawerContainer'
import TransactionValidate from './TransactionValidate'
import TransactionIgnoredReason from 'enums/TransactionIgnoredReason.js'
import featureConfig from 'config/features.js'

TransactionActions.propTypes = {
  transaction: PropTypes.object,
  withValidation: PropTypes.bool,
  onValidate: PropTypes.func
}

export default function TransactionActions ({ transaction, withValidation, onValidate }) {
  const { t } = useTranslation()
  const [ignoreTransactionMutation, { loading: ignoreTransactionLoading }] = useMutation(IGNORE_TRANSACTION)
  const [unignoreTransactionMutation, { loading: unignoreTransactionLoading }] = useMutation(UNIGNORE_TRANSACTION)
  const [cancelTransactionRefundMutation, { loading: cancelLoading }] = useMutation(CANCEL_TRANSACTION_REFUND)
  const [markTransactionAsVatPayment, { loading: markAsVatPaymentLoading }] = useMutation(MARK_TRANSACTION_AS_VAT_PAYMENT, { variables: { id: transaction.id } })
  const [unmarkTransactionAsVatPayment, { loading: unmarkAsVatPaymentLoading }] = useMutation(UNMARK_TRANSACTION_AS_VAT_PAYMENT, { variables: { id: transaction.id } })

  const updateLoading = useMemo(() => {
    return unignoreTransactionLoading || ignoreTransactionLoading
  }, [ignoreTransactionLoading, unignoreTransactionLoading])

  const cancelTransactionRefund = useCallback(() =>
    cancelTransactionRefundMutation({ variables: { refundTransactionId: transaction.refundTransactionId || transaction.id } }),
  [cancelTransactionRefundMutation, transaction.refundTransactionId, transaction.id])

  const ignoreTransaction = useCallback(() => {
    ignoreTransactionMutation({ variables: { id: transaction.id, ignoredReason: null } })
  }, [ignoreTransactionMutation, transaction])

  const unignoreTransaction = useCallback(() => {
    unignoreTransactionMutation({ variables: { id: transaction.id } })
  }, [unignoreTransactionMutation, transaction])

  const swapIgnoreTransaction = useCallback(() => {
    if (transaction.ignored) {
      unignoreTransaction()
    } else {
      ignoreTransaction()
    }
  }, [ignoreTransaction, transaction.ignored, unignoreTransaction])

  const markAsDeferredCardReset = useCallback(() => {
    if (!transaction.ignored) {
      ignoreTransactionMutation({ variables: { id: transaction.id, ignoredReason: TransactionIgnoredReason.DEFERRED_CARD_RESET } })
    }
  }, [ignoreTransactionMutation, transaction])

  const unmarkAsDeferredCardReset = useCallback(() => {
    if (transaction.ignored && transaction.ignoredReason === TransactionIgnoredReason.DEFERRED_CARD_RESET) {
      unignoreTransactionMutation({ variables: { id: transaction.id } })
    }
  }, [transaction.ignored, transaction.ignoredReason, transaction.id, unignoreTransactionMutation])

  const { openSplitTransactionPopup, SplitTransactionModal } = useSplitTransaction({ transaction })
  const { openRefundTransactionDrawer, refundTransactionDrawer } = useRefundTransaction({ transaction })

  const handleMenuClick = useCallback(({ key }) => {
    switch (key) {
      case 'ignore':
        swapIgnoreTransaction()
        break
      case 'split':
        openSplitTransactionPopup()
        break
      case 'refund':
        openRefundTransactionDrawer()
        break
      case 'cancelRefund':
        cancelTransactionRefund()
        break
      case 'markAsVatPayment':
        markTransactionAsVatPayment()
        break
      case 'unmarkAsVatPayment':
        unmarkTransactionAsVatPayment()
        break
      case 'markAsDeferredCardReset':
        markAsDeferredCardReset()
        break
      case 'unmarkAsDeferredCardReset':
        unmarkAsDeferredCardReset()
        break
      default:
        console.warn(`${key} button not handled`)
    }
  }, [swapIgnoreTransaction, openSplitTransactionPopup, openRefundTransactionDrawer, cancelTransactionRefund, markTransactionAsVatPayment, unmarkTransactionAsVatPayment, markAsDeferredCardReset, unmarkAsDeferredCardReset])

  const loading = useMemo(() => (
    updateLoading || cancelLoading || markAsVatPaymentLoading || unmarkAsVatPaymentLoading
  ), [updateLoading, cancelLoading, markAsVatPaymentLoading, unmarkAsVatPaymentLoading])

  const isPartOfRefund = useMemo(() => (!!transaction.refundedTransactionId || !!transaction.refundTransactionId), [transaction.refundTransactionId, transaction.refundedTransactionId])
  // const isPartOfSplit = useMemo(() => (!isPartOfRefund), [isPartOfRefund])
  // const isStandard = useMemo(() => (!isPartOfRefund), [isPartOfRefund])

  const canIgnore = useMemo(() => !isPartOfRefund, [isPartOfRefund])
  const canRefund = useMemo(() => transaction.type === 'cashin' && !isPartOfRefund, [transaction.type, isPartOfRefund])
  const canSplit = useMemo(() => !isPartOfRefund, [isPartOfRefund])
  const canCancelRefund = useMemo(() => isPartOfRefund, [isPartOfRefund])
  const canMarkAsVatPayment = useMemo(() => featureConfig.vatDetailsEnabled && !transaction.isVatPayment && !isPartOfRefund, [transaction.isVatPayment, isPartOfRefund])
  const canUnmarkAsVatPayment = useMemo(() => featureConfig.vatDetailsEnabled && !!transaction.isVatPayment, [transaction.isVatPayment])

  const menuItems = useMemo(() => {
    const items = []

    if (canIgnore && transaction.ignoredReason !== TransactionIgnoredReason.DEFERRED_CARD_RESET) {
      items.push({
        key: 'ignore',
        label: (
          <Tooltip
            title={t('component.transactionActions.ignoreTransactionTooltip')}
            placement='left'
            className='flex items-center'
          >
            {(transaction.ignored && <EyeIcon className='w-4 h-4 mr-2 inline' />) || <EyeSlashIcon className='w-4 h-4 mr-2 inline' />}
            {transaction.ignored ? t('shared.unIgnore') : t('shared.ignore')}
          </Tooltip>
        )
      })
    }

    if (canIgnore && (!transaction.ignored || transaction.ignoredReason === TransactionIgnoredReason.DEFERRED_CARD_RESET)) {
      const key = transaction.ignored ? 'unmarkAsDeferredCardReset' : 'markAsDeferredCardReset'
      const cashinText = transaction.ignored ? t('component.transactionActions.unmarkAsDeferredCardReset') : t('component.transactionActions.markAsDeferredCardReset')
      const cashoutText = transaction.ignored ? t('component.transactionActions.unmarkAsMonthlyCardDebit') : t('component.transactionActions.markAsMonthlyCardDebit')
      const text = transaction.type === 'cashin' ? cashinText : cashoutText
      const tooltipTitle = transaction.type === 'cashin' ? t('component.transactionActions.markAsDeferredCardResetTooltip') : t('component.transactionActions.markAsMonthlyCardDebitTooltip')
      items.push({
        key,
        label: (
          <Tooltip
            title={tooltipTitle}
            placement='left'
            className='flex items-center'
          >
            <ArrowPathIcon className='w-4 h-4 mr-2 inline transform rotate-180' />
            {text}
          </Tooltip>
        )
      })
    }

    if (canSplit) {
      items.push({
        key: 'split',
        label: (
          <Tooltip
            title={t('component.transactionActions.splitTooltip')}
            placement='left'
            className='flex items-center'
          >
            <ChartBarIcon className='w-4 h-4 mr-2 inline transform rotate-90' />
            {t('component.transactionActions.split')}
          </Tooltip>
        )
      })
    }

    if (canRefund) {
      items.push({
        key: 'refund',
        label: (
          <Tooltip
            title={t('component.transactionActions.refundTooltip')}
            placement='left'
            className='flex items-center'
          >
            <ArrowUturnLeftIcon className='w-4 h-4 mr-2 inline' />
            {t('component.transactionActions.refund')}
          </Tooltip>
        )
      })
    }

    if (canCancelRefund) {
      items.push({
        key: 'cancelRefund',
        label: (
          <Tooltip
            title={t('component.transactionActions.cancelRefundTooltip')}
            placement='left'
            className='flex items-center'
          >
            <ArrowUturnLeftIcon className='w-4 h-4 mr-2 inline transform rotate-180' />
            {t('component.transactionActions.cancelRefund')}
          </Tooltip>
        )
      })
    }

    if (canMarkAsVatPayment) {
      items.push({
        key: 'markAsVatPayment',
        label: (
          <Tooltip
            title={t('component.transactionActions.markAsVatPaymentTooltip')}
            placement='left'
            className='flex items-center'
          >
            <ReceiptPercentIcon className='w-4 h-4 mr-2 inline' />
            {t('component.transactionActions.markAsVatPayment')}
          </Tooltip>
        )
      })
    }

    if (canUnmarkAsVatPayment) {
      items.push({
        key: 'unmarkAsVatPayment',
        label: (
          <Tooltip
            title={t('component.transactionActions.unmarkAsVatPaymentTooltip')}
            placement='left'
            className='flex items-center'
          >
            <ReceiptPercentIcon className='w-4 h-4 mr-2 inline transform rotate-180' />
            {t('component.transactionActions.unmarkAsVatPayment')}
          </Tooltip>
        )
      })
    }

    return items
  }, [canIgnore, transaction.ignoredReason, transaction.ignored, transaction.type, canSplit, canRefund, canCancelRefund, canMarkAsVatPayment, canUnmarkAsVatPayment, t])

  return (
    <div className='flex flex-row items-center justify-between'>
      <ReconciliationDrawerContainer transaction={transaction} />
      <DocumentDrawerContainer transaction={transaction} />
      <SplitTransactionModal />
      {refundTransactionDrawer}

      {(withValidation && (
        <div className='w-20'>
          <TransactionValidate transaction={transaction} onValidate={onValidate} />
        </div>
      )) || (
        <Tooltip
          mouseEnterDelay={0.4}
          title={t('component.transactionActions.transactionValidated')}
          placement='right'
          className={transaction?.validated ? 'visible' : 'invisible'}
        >
          <CheckIcon className='w-4 h-4 text-gray-400' />
        </Tooltip>
      )}

      <Dropdown
        disabled={loading}
        placement='bottomRight'
        menu={{ onClick: handleMenuClick, items: menuItems }}
      >
        <ActionsIcon loading={loading} />
      </Dropdown>
    </div>
  )
}
