import { ArrowDownIcon, ExclamationTriangleIcon, PlusIcon } from '@heroicons/react/24/solid'
import Currency from 'components/Currency'
import Button from 'design/Button'
import { floor, round, sumBy } from 'lodash'
import numeral from 'numeral'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import SplitTransactionPartForm from './SplitTransactionPartForm'
import TransactionPreview from './TransactionPreview'

const SplitTransactionForm = ({ transaction, onSubmit, loading }) => {
  const { t } = useTranslation()
  const initialParts = useMemo(() => {
    return [
      {
        amount: transaction.amount / 2,
        vatRate: transaction.vatRate,
        categoryId: transaction.categoryId
      },
      {
        amount: transaction.amount / 2,
        vatRate: transaction.vatRate
      }
    ]
  }, [transaction])

  const [parts, setParts] = useState(initialParts)

  const defaultAmount = useMemo(() => {
    return computeNewDefaultAmount(transaction.amount, parts.length)
  }, [transaction, parts])

  const totalPartsAmount = useMemo(() => {
    const totalPartsAmount = sumBy(parts, 'amount')

    return round(totalPartsAmount, 2)
  }, [parts])

  const doesNotHaveRightAmount = useMemo(() => {
    return totalPartsAmount !== transaction.amount
  }, [transaction, totalPartsAmount])

  const addPart = useCallback(() => {
    const newDefaultAmount = computeNewDefaultAmount(transaction.amount, parts.length + 1)
    const lastPartDefaultAmount = computeNewDefaultAmount(transaction.amount, parts.length, true)

    const updatedParts = recomputeParts(parts, defaultAmount, lastPartDefaultAmount, newDefaultAmount)

    setParts([
      ...updatedParts,
      {
        amount: computeNewDefaultAmount(transaction.amount, parts.length + 1, true),
        vatRate: transaction.vatRate
      }
    ])
  }, [parts, setParts, defaultAmount, transaction.amount, transaction.vatRate])

  const removePart = useCallback((index) => {
    const removedPartIsNotLast = (parts.length - 1) !== index
    const newDefaultAmount = computeNewDefaultAmount(transaction.amount, parts.length - 1)
    const lastPartDefaultAmount = computeNewDefaultAmount(transaction.amount, parts.length, removedPartIsNotLast)

    const newParts = [...parts]
    newParts.splice(index, 1)

    const updatedParts = recomputeParts(newParts, defaultAmount, lastPartDefaultAmount, newDefaultAmount)

    setParts(updatedParts)
  }, [transaction, parts, setParts, defaultAmount])

  const updatePart = useCallback((newPart, index) => {
    const newParts = [...parts]
    newParts.splice(index, 1, newPart)
    setParts(newParts)
  }, [parts, setParts])

  const submit = useCallback(() => {
    onSubmit(parts)
  }, [onSubmit, parts])

  return (
    <div className='w-full flex flex-col items-center'>
      <TransactionPreview transaction={transaction} />

      <ArrowDownIcon className='w-6 h-6 text-center my-4' />

      <div className='w-full flex flex-row space-x-8'>
        <div className='w-4/12 font-bold'>
          {t('shared.amountInclTax')}
        </div>

        <div className='w-4/12 flex flex-row'>
          <div className='w-5/12 font-bold'>
            {t('shared.vatRate')}
          </div>
          <div className='w-1/12 font-bold' />

          <div className='w-6/12 font-bold'>
            {t('component.splitTransactionForm.vatAmount')}
          </div>
        </div>

        <div className='w-4/12 font-bold'>
          {t('shared.category')}
        </div>
      </div>

      {parts.map((part, index) => (
        <SplitTransactionPartForm
          key={index}
          type={transaction.type}
          transactionAmount={transaction.amount}
          part={part}
          onUpdate={(newPart) => updatePart(newPart, index)}
          onRemove={() => removePart(index)}
          canRemove={parts.length > 2}
        />
      ))}

      <Button
        label={(<PlusIcon className='w-6 h-6' />)}
        rounded
        className='my-4'
        onClick={addPart}
      />

      {doesNotHaveRightAmount && (
        <span className='text-yellow-600 flex flex-row items-center'>
          <ExclamationTriangleIcon className='w-4 h-4 mr-4' />
          <Trans
            i18nKey='component.splitTransactionForm.doesNotHaveRightAmount'
            components={{ Currency: <Currency amount={totalPartsAmount} className='inline' /> }}
          />

        </span>
      )}

      <Button
        disabled={doesNotHaveRightAmount}
        loading={loading}
        primary
        label={t('component.splitTransactionForm.splitTransaction')}
        className='mt-4 self-end'
        onClick={submit}
      />
    </div>
  )
}

SplitTransactionForm.propTypes = {
  transaction: PropTypes.object,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool
}

function computeNewDefaultAmount (transactionAmount, newPartsLength, lastPart = false) {
  const roundedTransactionAmount = round(transactionAmount, 2)
  const newAmount = numeral(roundedTransactionAmount).divide(newPartsLength).value()
  const flooredAmount = floor(newAmount, 2)

  if (lastPart) {
    const totalAmount = numeral(flooredAmount).multiply(newPartsLength).value()
    const difference = numeral(roundedTransactionAmount).subtract(totalAmount).value()
    const withDifference = numeral(newAmount).add(difference).value()

    return floor(withDifference, 2)
  }

  return flooredAmount
}

function recomputeParts (parts, defaultAmount, lastPartDefaultAmount, newDefaultAmount) {
  return parts.map((part, index) => {
    const shouldUpdateAmount = (index === parts.length - 1) ? (part.amount === lastPartDefaultAmount) : (part.amount === defaultAmount)

    return {
      ...part,
      amount: shouldUpdateAmount ? newDefaultAmount : part.amount
    }
  })
}

export default SplitTransactionForm
