import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Input, Typography, Form } from 'antd'
import i18next from 'i18next'
import { get } from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'

import authActions from 'actions/AuthActions'
import classNames from 'classnames'
import Logo from 'components/Logo.js'
import featureConfig from 'config/features.js'
import Button from 'design/Button'
import ConnectWithSellsyButton from './sign-in/ConnectWithSellsyButton'
import { quipuSignInRedirectUrl } from 'utils/quipu.js'

const { Text } = Typography

const SignInScreen = () => {
  const isAuthenticated = useSelector(state => get(state, 'app.isAuthenticated'))
  const isSigningIn = useSelector(state => get(state, 'auth.isSigningIn'))
  const signInError = useSelector(state => get(state, 'auth.signInError'))

  const { t } = i18next
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const handleSubmit = (values) => {
    const { email, password } = values
    dispatch(authActions.requestSignIn(email, password))
  }

  useEffect(() => {
    if (!isAuthenticated && process.env.REACT_APP_TENANT === 'QUIPU' && process.env.NODE_ENV !== 'development') {
      window.location.href = quipuSignInRedirectUrl
    }
  }, [isAuthenticated])

  useEffect(() => {
    const signInEnded = !(isSigningIn)

    if (signInEnded && (signInError)) {
      form.setFields([{ name: 'email', value: form.getFieldValue('email'), errors: [t('signin.invalidEmailOrPassword')] }])
    }
  }, [isSigningIn, signInError, form, t])

  if (isAuthenticated) {
    return <Redirect to='/' />
  }

  return (
    <div className='rc-full-page-box-container bg-white'>
      {featureConfig.signUpEnabled && (
        <div className='absolute top-3 right-4'>
          <Text>{t('signin.noAccount')}</Text>
          <br />
          <Link to='/signup'>{t('signin.tryForFree')}</Link>
        </div>
      )}

      <div className='max-w-md p-4 grid grid-cols-1 animate-fade-in'>
        <div className='relative left-0 right-0 -mt-24 w-full flex flex-row justify-center'>
          <Logo className='rc-signin-logo' />
        </div>

        {featureConfig.sellsySsoEnabled && (
          <>
            <ConnectWithSellsyButton isSigningIn={isSigningIn} />

            <div className='w-full flex flex-row items-center text-gray-400 my-4'>
              <div className='bg-gray-200 h-px w-auto flex-grow' />
              <div className='mx-2 text-xs'>{t('shared.or')}</div>
              <div className='bg-gray-200 h-px w-auto flex-grow' />
            </div>
          </>
        )}

        {featureConfig.emailPasswordSignInEnabled && (
          <Form form={form} layout='vertical' onFinish={handleSubmit} className='p-0' requiredMark={false}>
            <Form.Item
              name='email'
              className='mb-2 '
              label={(
                <div className='text-xs text-gray-500'>{t('shared.emailAddress')}</div>
              )}
              rules={[
                { type: 'email', message: t('signin.invalidEmail') },
                { required: true, message: t('signin.pleaseEnterEmail') }
              ]}
            >
              <Input
                prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder='email@example.net'
              />
            </Form.Item>

            <Form.Item
              name='password'
              className='mb-3'
              label={(
                <div className='text-xs text-gray-500'>{t('shared.password')}</div>
              )}
              rules={[{ required: true, message: t('signin.passwordPrompt') }]}
            >
              <Input.Password
                prefix={<LockOutlined style={{ color: 'rgba(0, 0, 0, .25)' }} />}
                placeholder={t('shared.password')}
              />
            </Form.Item>

            <Form.Item className='mb-0 pb-0'>
              <div className={classNames(
                'flex flex-row  items-start', {
                  'justify-between': featureConfig.resetPasswordEnabled,
                  'justify-end': !featureConfig.resetPasswordEnabled
                })}
              >
                {featureConfig.resetPasswordEnabled && (
                  <Link to='/forgot-password' className='text-xs'>{t('signin.forgotPassword')}</Link>
                )}
                <span className='w-10' />
                <Button
                  type='submit'
                  label={t('signin.connect')}
                  loading={isSigningIn}
                />
              </div>
            </Form.Item>
          </Form>
        )}

      </div>
    </div>
  )
}

export default SignInScreen
