import React, { useEffect, useMemo, useState } from 'react'
import useSearchParams from 'hooks/useSearchParams'
import FreeTrialEndPopup from './FreeTrialEndPopup'
import { message } from 'antd'
import confetti from 'canvas-confetti'
import useAuthenticatedContext from 'hooks/useAuthenticatedContext'
import { get, isNumber, upperFirst } from 'lodash'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import useFreeTrialEnd from 'hooks/subscriptions/useFreeTrialEnd.js'
import useSubscriptionRedirect from 'hooks/subscriptions/useSubscriptionRedirect.js'

const gtmTrackSubscription = ({ companyId }) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      userId: companyId,
      // value: 49 || 490,
      // subscriptionType: 'monthly',
      event: 'subscriptionStarted'
    })
  }
}

const welcomeNewClient = (msg) => {
  message.success(msg, 5)

  confetti({
    startVelocity: 35,
    angle: -90,
    spread: 240,
    particleCount: 80,
    gravity: 0.7,
    decay: 0.92,
    origin: {
      y: 0
    }
  })
}

export default () => {
  const { company, user, subscription } = useAuthenticatedContext()
  const { newSub, upgSub } = useSearchParams()
  const history = useHistory()
  const { isOverGracePeriod } = useFreeTrialEnd()
  const { redirectToSubscriptionPage } = useSubscriptionRedirect()

  const [hasFired, setHasFired] = useState(false)

  const activeBanksCount = useSelector((state) => {
    const data = get(state, 'accounts.data')
    if (!data) return -1

    const withoutDeleted = data.filter((a) => a.status !== 'DELETED')

    return withoutDeleted.length
  })

  const companyId = company?.id
  const subscribed = !!subscription?.id

  useEffect(() => {
    if (isOverGracePeriod && !subscribed) {
      redirectToSubscriptionPage({ subscriptionType: company?.subscriptionType })
      if (!company?.subscriptionType !== 'QUIPU') {
        history.replace('/subscribe')
      }
    }
  }, [history, isOverGracePeriod, redirectToSubscriptionPage, company?.subscriptionType, subscribed])

  useEffect(() => {
    if (newSub === '1' && !hasFired) {
      setHasFired(true)
      setTimeout(() => welcomeNewClient('Bienvenue sur RocketChart ! 🎉'), 300)
      gtmTrackSubscription({ companyId })
    }
  }, [newSub, companyId, hasFired, setHasFired])

  useEffect(() => {
    if (upgSub === '1' && subscription?.plan && !hasFired) {
      setHasFired(true)
      const planName = upperFirst(subscription?.plan?.toLowerCase())
      setTimeout(() => welcomeNewClient(`Vous êtes maintenant sur le forfait ${planName} ! 🎉`), 300)
    }
  }, [upgSub, subscription, hasFired])

  const shouldRedirect = useMemo(() => {
    return (
      user?.role === 'admin' &&
      isNumber(activeBanksCount) &&
      isNumber(subscription?.includedBanksQuantity) &&
      activeBanksCount > subscription?.includedBanksQuantity
    )
  }, [user, activeBanksCount, subscription])

  useEffect(() => {
    if (shouldRedirect) history.replace('/subscribe?r=ob')
  }, [shouldRedirect, history])

  if (!company) return null

  if (subscribed === false && !isOverGracePeriod) {
    return <FreeTrialEndPopup />
  }

  return null
}
