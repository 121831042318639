import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Typography } from 'antd'
import { get } from 'lodash'

import Currency from 'components/Currency'
import colors from 'utils/colors'
import Dot from 'components/Dot'
import moment from 'moment'
// import cx from 'classnames'

const { Text } = Typography

const SimpleChartTooltip = ({ params, limit, limitLabel, dataKey }) => {
  const title = useMemo(() => {
    return moment(get(params, 'payload.date')).format('MMMM YYYY')
  }, [params])

  const amount = get(params, `payload.${dataKey}`)
  const color = get(params, 'color')

  if (!params) return null

  return (
    <div className='rounded-md border border-gray-200 px-2 py-1' style={{ backgroundColor: color }}>
      <Text strong style={{ color: colors.white }}>{title}</Text>
      <br />
      <Dot
        bordered
        color={color}
        size='small'
        style={{ marginRight: 5 }}
      />
      <Currency amount={amount} color={colors.white} />
      {!!limit && (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: colors.white }}>
          <Dot
            bordered
            color={colors.warning}
            size='small'
            style={{ marginRight: 5 }}
          />
          <Currency amount={limit} color={colors.white} />
          <Text strong style={{ color: 'white', marginLeft: 5 }}>- {limitLabel}</Text>
        </div>
      )}
    </div>
  )
}

SimpleChartTooltip.propTypes = {
  params: PropTypes.object,
  limit: PropTypes.number,
  limitLabel: PropTypes.string,
  dataKey: PropTypes.string
}

export default SimpleChartTooltip
