import { Modal } from 'antd'
import useSearchParams from 'hooks/useSearchParams'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export default () => {
  const { t } = useTranslation()
  const { newConnection } = useSearchParams()

  useEffect(() => {
    if (newConnection === '1') {
      Modal.info({
        centered: true,
        title: t('component.newConnectionPopup.newBankConnection'),
        content: (
          <div>
            {t('component.newConnectionPopup.newBankAccountSyncing01')}
            {t('component.newConnectionPopup.newBankAccountSyncing02')}
          </div>
        )
      })
    }
  }, [newConnection, t])

  return null
}
