import React from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { Typography, Card } from 'antd'
import { Trans } from 'react-i18next'

const ActionsLeftInfo = () => {
  const categorizationLeft = useSelector(state => get(state, 'aggregations.actionsLeft.category', 0))

  if (categorizationLeft === 0) return null

  return (
    <Card style={{ marginBottom: 20 }} className='rc-info-card'>
      <Typography.Text>
        <Trans
          i18nKey='components.actionsLeftInfo.categorizationLeft'
          components={{ Typography: <Typography.Text strong /> }}
          values={{ count: categorizationLeft }}
        />

      </Typography.Text>
    </Card>
  )
}

export default ActionsLeftInfo
