import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { get } from 'lodash'
import { useLocation } from 'react-router'

import useAuthenticatedContext from 'hooks/useAuthenticatedContext'
import LoadingScreen from 'containers/LoadingScreen'
import useLocale from 'hooks/useLocale.js'
import i18n from 'config/i18n.js'
import moment from 'moment'
import featureConfig from 'config/features.js'

RouteRenderer.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  requireAuth: PropTypes.bool,
  requireOnboarding: PropTypes.bool,
  requireNotBlocked: PropTypes.bool
}

export default function RouteRenderer ({
  component: RouteComponent,
  requireAuth,
  requireOnboarding,
  requireNotBlocked,
  ...rest
}) {
  const location = useLocation()
  const { company, subscription } = useAuthenticatedContext()
  const locale = useLocale()

  const isAuthenticated = useSelector(state => get(state, 'app.isAuthenticated'))
  const hasFetchUser = useSelector(state => get(state, 'auth.hasFetchUser'))

  const blocked = !!company?.blocked || !!subscription?.blocked
  const onboardingEndAt = company?.onboardingEndAt

  useEffect(() => {
    i18n.changeLanguage(locale)
    moment.locale(locale.split('-')[0])
  }, [locale])

  useEffect(() => {
    if (window.Intercom) {
      // Intercom asks to update every time the route change, from the doc.
      window.Intercom('update')
    }
  }, [location])

  if (requireAuth && !isAuthenticated) {
    window.location.replace('/signin')
  } else if (requireAuth && !hasFetchUser) {
    return (
      <LoadingScreen />
    )
  } else if (requireAuth && requireOnboarding && !onboardingEndAt && featureConfig.onboardingEnabled) {
    return (
      <Redirect
        to={{
          pathname: '/onboarding',
          state: { from: location }
        }}
      />
    )
  } else if (blocked && requireNotBlocked) {
    return (
      <Redirect
        to={{
          pathname: '/forbidden',
          state: { from: location }
        }}
      />
    )
  }

  return (
    <RouteComponent
      {...rest}
    />
  )
}
