import React, { useCallback } from 'react'
import Card from 'design/Card.js'
import PropTypes from 'prop-types'

import Toggle from 'design/Toggle'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { Tooltip } from 'antd'
import { UPDATE_CONNECTION_CONFIG } from 'graphql/connections.js'
import { useMutation } from '@apollo/client'
import confirm from 'antd/lib/modal/confirm.js'
import { useTranslation } from 'react-i18next'
import useChangeSyncQuote from 'hooks/useChangeSyncQuote.js'
import useChangeSyncRecurringPayment from 'hooks/useChangeSyncRecurringPayment.js'
import useChangeSyncOrder from 'hooks/useChangeSyncOrder.js'

IntegrationSettings.propTypes = {
  connection: PropTypes.object
}

export default function IntegrationSettings ({ connection, ...rest }) {
  const { t } = useTranslation()
  const [updateConnectionConfig, { loadingUpdateConnectionConfig }] = useMutation(UPDATE_CONNECTION_CONFIG)
  const [changeSyncQuote, loadingSyncQuote] = useChangeSyncQuote(connection)
  const [changeSyncRecurringPayment, loadingSyncRecurringPayment] = useChangeSyncRecurringPayment(connection)
  const [changeSyncOrder, loadingSyncOrder] = useChangeSyncOrder(connection)
  const syncDraftInvoiceToggled = connection?.config.hasSyncDraftInvoice
  const syncQuoteToggled = connection?.config.hasSyncQuote
  const syncRecurringPaymentToggled = connection?.config.hasSyncRecurringPayment
  const syncOrderToggled = connection?.config.hasSyncOrder

  const handleOnChangeDraftInvoice = useCallback((toggled) => {
    if (toggled) {
      confirm({
        title: t('integrations.integrationSettings.activateSyncDraft.confirm.title'),
        okText: t('shared.confirm'),
        okType: 'danger',
        cancelText: t('shared.cancel'),
        maskClosable: true,
        onOk () {
          updateConnectionConfig({
            variables: {
              connectionId: connection.id,
              hasSyncDraftInvoice: toggled
            }
          })
        }
      })
    } else {
      confirm({
        title: t('integrations.integrationSettings.disableSyncDraft.confirm.title'),
        okText: t('shared.confirm'),
        okType: 'danger',
        width: 550,
        cancelText: t('shared.cancel'),
        maskClosable: true,
        content: t('integrations.integrationSettings.disableSyncDraft.confirm.content'),
        onOk () {
          updateConnectionConfig({
            variables: {
              connectionId: connection.id,
              hasSyncDraftInvoice: toggled,
              hasSyncQuote: connection?.config.hasSyncQuote
            }
          })
        }
      })
    }

    // refresh
  }, [updateConnectionConfig, connection?.id, connection?.config.hasSyncQuote, t])

  const handleOnChangeSyncQuote = (toggle) => {
    changeSyncQuote(toggle)
  }

  const handleOnChangeSyncRecurringPayment = (toggle) => {
    changeSyncRecurringPayment(toggle)
  }

  const handleOnChangeSyncOrder = (toggle) => {
    changeSyncOrder(toggle)
  }

  // if (connection.externalSource !== 'SELLSY') return null

  return (
    <Card>
      <span className='text-lg leading-6 font-medium text-gray-900'>
        {t('integrations.integrationSettings.title')}
      </span>
      <div className='flex flex-row'>
        <div className='flex-1'>
          <div className='my-5'>
            <span className='text-sm leading-3 font-medium text-gray-900'>{t('integrations.integrationSettings.invoiceTitle')}</span>
          </div>

          <div className='flex flex-row items-center'>
            <Tooltip
              placement='right'
              title={(
                <div className='flex flex-col space-y-4'>
                  {t('integrations.integrationSettings.invoiceSyncToggle.toolTip')}
                </div>
              )}
            >
              <div className='flex flex-row'>

                <Toggle size='small' toggled readonly loading={false} className='inline cursor-none' />

                <span>{t('integrations.integrationSettings.invoiceSyncToggle.label')}</span>
              </div>
            </Tooltip>
          </div>
          <div className='mt-2' />
          <div className='flex flex-row items-center '>

            <Tooltip
              placement='right'
              title={(
                <div className='flex flex-col space-y-4'>
                  <div>
                    {t('integrations.integrationSettings.invoiceSyncDragToggle.toolTip')}
                  </div>
                </div>
              )}
              overlayClassName='w-[450px] max-w-none'
            >
              <div className='flex flex-row'>

                <Toggle size='small' toggled={syncDraftInvoiceToggled} onChange={handleOnChangeDraftInvoice} loading={loadingUpdateConnectionConfig} className='inline' />
                <span>{t('integrations.integrationSettings.invoiceSyncDragToggle.label')}</span>

                <InformationCircleIcon className='w-5 h-5 text-gray-200 ml-2' />
              </div>
            </Tooltip>
          </div>
        </div>
        <div className='flex-1'>
          <div className='my-5'>
            <span className='text-sm leading-3 font-medium text-gray-900'>{t('integrations.integrationSettings.otherDocumentTitle')}</span>
          </div>
          <div className='flex flex-row items-center'>
            <Tooltip
              placement='right'
              title={(
                <div className='flex flex-col space-y-4'>
                  {t('integrations.integrationSettings.quoteSyncToggle.toolTip')}
                </div>
              )}
            >
              <div className='flex flex-row'>
                <Toggle onChange={handleOnChangeSyncQuote} size='small' toggled={syncQuoteToggled} loading={!!loadingSyncQuote} className='inline cursor-none' />
                <span>{t('integrations.integrationSettings.quoteSyncToggle.label')}</span>
                <InformationCircleIcon className='w-5 h-5 text-gray-200 ml-2' />
              </div>
            </Tooltip>
          </div>
          <div className='mt-2' />
          <div className='flex flex-row items-center'>
            <Tooltip
              placement='right'
              title={(
                <div className='flex flex-col space-y-4'>
                  {t('integrations.integrationSettings.orderSyncToggle.toolTip')}
                </div>
              )}
            >
              <div className='flex flex-row'>
                <Toggle onChange={handleOnChangeSyncOrder} size='small' toggled={syncOrderToggled} loading={!!loadingSyncOrder} className='inline cursor-none' />
                <span>{t('integrations.integrationSettings.orderSyncToggle.label')}</span>
                <InformationCircleIcon className='w-5 h-5 text-gray-200 ml-2' />
              </div>
            </Tooltip>
          </div>
          <div className='mt-2' />
          <div className='flex flex-row items-center'>
            <Tooltip
              placement='right'
              title={(
                <div className='flex flex-col space-y-4'>
                  {t('integrations.integrationSettings.recurringPaymentSyncToggle.toolTip')}
                </div>
              )}
            >
              <div className='flex flex-row'>
                <Toggle onChange={handleOnChangeSyncRecurringPayment} size='small' toggled={syncRecurringPaymentToggled} loading={!!loadingSyncRecurringPayment} className='inline cursor-none' />
                <span>{t('integrations.integrationSettings.recurringPaymentSyncToggle.label')}</span>
                <InformationCircleIcon className='w-5 h-5 text-gray-200 ml-2' />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </Card>
  )
}
