import confirm from 'antd/lib/modal/confirm.js'
import { useTranslation } from 'react-i18next'
import { ACTIVATE_SELLSY_ORDERS } from 'graphql/sellsy.js'
import { useMutation } from '@apollo/client'
import { useCallback } from 'react'
import { message } from 'antd'

const useChangeSyncOrder = (connection, { onCompleted } = {}) => {
  const { t } = useTranslation()

  const [activateSellsyOrder, { loadingActivateSellsyOrders }] = useMutation(ACTIVATE_SELLSY_ORDERS, {
    onError: () => {
      message.error(t('integrations.manageIntegration.connectionFailed'))
    },
    onCompleted: () => {
      if (onCompleted) {
        onCompleted()
      }
    }
  })

  const setSyncOrder = useCallback((state) => {
    const title = state ? t('integrations.integrationSettings.activateSyncOrder.confirm.title') : t('integrations.integrationSettings.disableSyncOrder.confirm.title')
    const content = state ? null : t('integrations.integrationSettings.disableSyncOrder.confirm.content')
    const width = state ? null : 550

    confirm({
      title,
      okText: t('shared.confirm'),
      okType: 'danger',
      cancelText: t('shared.cancel'),
      maskClosable: true,
      content,
      width,
      onOk () {
        activateSellsyOrder({
          variables: {
            connectionId: connection.id,
            state
          }
        })
      }
    })
  }, [activateSellsyOrder, connection, t])

  const loading = loadingActivateSellsyOrders

  return [setSyncOrder, loading]
}

export default useChangeSyncOrder
