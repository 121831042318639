import React from 'react'

import TotalYearOverview from 'components/transactions/TotalYearOverview'
import CategoryRepartitionCard from 'components/CategoryRepartitionCard'

export default function CashInCharts () {
  return (
    <div>
      <TotalYearOverview type='cashin' />

      <CategoryRepartitionCard type='cashin' />
    </div>
  )
}
