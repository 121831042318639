import confirm from 'antd/lib/modal/confirm.js'
import { useTranslation } from 'react-i18next'
import { SYNC_CONNECTION } from 'graphql/connections.js'
import { ACTIVATE_SELLSY_RECURRING_PAYMENTS } from 'graphql/sellsy.js'
import { useMutation } from '@apollo/client'
import { useCallback } from 'react'
import { message } from 'antd'

const useChangeSyncRecurringPayment = (connection, { onCompleted } = {}) => {
  const { t } = useTranslation()

  const [syncConnection, loadingSyncConnection] = useMutation(SYNC_CONNECTION, {
    variables: { connectionId: connection?.id, connectionSource: connection?.externalSource }
  })

  const [activateSellsyRecurringPayments, { loadingActivateSellsyRecurringPayments }] = useMutation(ACTIVATE_SELLSY_RECURRING_PAYMENTS, {
    onError: (error) => {
      message.error(t('integrations.manageIntegration.connectionFailed'))
      message.error(error)
      syncConnection()
    },
    onCompleted: () => {
      if (onCompleted) {
        onCompleted()
      }
    }
  })

  const setSyncRecurringPayment = useCallback((state) => {
    const title = state ? t('integrations.integrationSettings.activateSyncRecurringPayment.confirm.title') : t('integrations.integrationSettings.disableSyncRecurringPayment.confirm.title')
    const content = state ? null : t('integrations.integrationSettings.disableSyncRecurringPayment.confirm.content')
    const width = state ? null : 550

    confirm({
      title,
      okText: t('shared.confirm'),
      okType: 'danger',
      cancelText: t('shared.cancel'),
      maskClosable: true,
      content,
      width,
      onOk () {
        activateSellsyRecurringPayments({
          variables: {
            connectionId: connection.id,
            state
          }
        })
      }
    })
  }, [activateSellsyRecurringPayments, connection, t])

  const loading = loadingSyncConnection | loadingActivateSellsyRecurringPayments

  return [setSyncRecurringPayment, loading]
}

export default useChangeSyncRecurringPayment
