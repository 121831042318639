import usePageSize from 'hooks/usePageSize.js'
import { useCallback, useMemo, useState } from 'react'

export default function usePagination () {
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = usePageSize()
  const [total, setTotal] = useState(0)

  const pagination = useMemo(() => {
    return {
      onChange: (page) => {
        setPage(page)
      },
      onShowSizeChange: (page, pageSize) => {
        setPage(page)
        setPageSize(pageSize)
      },
      current: page,
      pageSize,
      showSizeChanger: true,
      total
    }
  }, [page, pageSize, setPageSize, total])

  const resetPagination = useCallback(() => {
    setPage(1)
  }, [setPage])

  return {
    pagination,
    page,
    pageSize,
    resetPagination,
    updatePaginationTotal: setTotal
  }
}
