import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { getStatus } from 'redux-resource'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, Tabs } from 'antd'
import { get } from 'lodash'

import resourcesActions from 'actions/ResourcesActions'
import ScenarioForm from './ScenarioForm'
import { useTranslation } from 'react-i18next'

const NewScenarioModal = ({ visible, setVisible, onSuccess, duplicateScenarioId }) => {
  const dispatch = useDispatch()
  const createScenarioStatus = useSelector(state => getStatus(state, 'scenarios.requests.createScenario.status'))
  const createScenarioErrors = useSelector(state => get(state, 'scenarios.requests.createScenario.errors'))
  const { t } = useTranslation()

  const adaptedErrors = useMemo(() => {
    if (createScenarioErrors) {
      return [createScenarioErrors]
    }
  }, [createScenarioErrors])

  const successCallback = useCallback((scenarioId, resetChanges) => {
    onSuccess(scenarioId)
    setVisible(false)
    resetChanges()
  }, [onSuccess, setVisible])

  const createScenario = useCallback((values, resetChanges) => {
    dispatch(resourcesActions.CREATE_SCENARIO(values, (id) => successCallback(id, resetChanges)))
  }, [dispatch, successCallback])

  return (
    <Modal
      open={visible}
      onClose={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      centered
      footer={null}
    >
      <Tabs
        defaultActiveKey={duplicateScenarioId ? 'duplicate' : 'new'}
        animated={false}
        style={{ marginTop: -20, overflow: 'visible' }}
      >
        <Tabs.TabPane tab={t('component.newScenarioModal.createNewScenario')} key='new'>
          <div style={{ margin: 10 }}>
            <ScenarioForm
              submitting={createScenarioStatus.pending}
              submit={createScenario}
              errors={adaptedErrors}
              layout='vertical'
            />
          </div>
        </Tabs.TabPane>

        <Tabs.TabPane tab={t('component.newScenarioModal.duplicateScenario')} key='duplicate'>
          <div style={{ margin: 10 }}>
            <ScenarioForm
              initialValues={{ duplicateScenarioId }}
              submit={createScenario}
              submitting={createScenarioStatus.pending}
              errors={adaptedErrors}
              duplicate
              layout='vertical'
            />
          </div>
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  )
}

NewScenarioModal.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  onSuccess: PropTypes.func,
  duplicateScenarioId: PropTypes.string
}

export default NewScenarioModal
