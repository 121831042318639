import { Table, Typography } from 'antd'
import i18next from 'i18next'
import { get, max, min } from 'lodash'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Currency from 'components/Currency'
import Dot from 'components/Dot'
import useAuthenticatedContext from 'hooks/useAuthenticatedContext'
import { actions as vatActions } from 'reducers/vat-reducer'
import colors from 'utils/colors'

const { t } = i18next

const columns = [
  {
    dataIndex: 'period',
    render: (period) => {
      const minMonth = min(period) - 1
      const maxMonth = max(period) - 1
      const minMonthLabel = moment().month(minMonth).format('MMMM')
      const maxMonthLabel = moment().month(maxMonth).format('MMMM')

      if (minMonth === maxMonth) {
        return <Typography.Text strong>{minMonthLabel}</Typography.Text>
      }

      return (
        <Typography.Text strong>
          {minMonthLabel} - {maxMonthLabel}
        </Typography.Text>
      )
    }
  },
  {
    title: () => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Dot color={colors.cashflow} className='mr-4' />
        <Typography.Text>{t('component.vatYearTable.vatAmount')}</Typography.Text>
      </div>
    ),
    dataIndex: 'vatBalance',
    width: '25%',
    render: amount => <Currency amount={amount} />
  },
  {
    title: () => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Dot color={colors.revenue} className='mr-4' />
        <Typography.Text>{t('component.vatYearTable.collectedVat')}</Typography.Text>
      </div>
    ),
    dataIndex: 'totalVatCollectedForPeriod',
    width: '25%',
    render: amount => <Currency amount={amount} />
  },
  {
    title: () => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Dot color={colors.expense} className='mr-4' />
        <Typography.Text>{t('component.vatYearTable.deductibleVat')}</Typography.Text>
      </div>
    ),
    dataIndex: 'totalVatDeductibleForPeriod',
    width: '25%',
    render: amount => <Currency amount={amount} />
  }
]

const VatYearTable = ({ year }) => {
  const dispatch = useDispatch()
  const yearVatBalance = useSelector(state => get(state, `vat.vatBalanceByYear.${year}`))
  const { company: { vatPeriod } } = useAuthenticatedContext()

  useEffect(() => {
    if (vatPeriod) {
      dispatch(vatActions.requestYearVatBalance(year))
    }
  }, [dispatch, year, vatPeriod])

  return (
    <Table
      columns={columns}
      dataSource={yearVatBalance}
      bordered
      rowKey={(vatBalance) => vatBalance.period.join('-')}
      pagination={false}
    />
  )
}

VatYearTable.propTypes = {
  year: PropTypes.number
}

export default VatYearTable
