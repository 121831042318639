import { LeftOutlined } from '@ant-design/icons'
import { Layout } from 'antd'
import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Badge from 'design/Badge'
import ButtonLink from 'design/ButtonLink'
import InfoBox from 'design/InfoBox'
import useAuthenticatedContext from 'hooks/useAuthenticatedContext'
import useManageBankConnections from 'hooks/useManageBankConnections.js'
import useSearchParams from 'hooks/useSearchParams'
import PlanSelection from './subscribe/PlanSelection'
import SellsySubscriptionMessage from './subscribe/SellsySubscriptionMessage'
import SubscriptionType from 'enums/SubscriptionType.js'
import Spinner from 'design/Spinner.js'
import useFreeTrialEnd from 'hooks/subscriptions/useFreeTrialEnd.js'
import useSubscriptionRedirect from 'hooks/subscriptions/useSubscriptionRedirect.js'

export default () => {
  const { t } = useTranslation()
  const { company: { subscriptionType }, subscription } = useAuthenticatedContext()
  const { r: reason } = useSearchParams()
  const { redirectToManageAccountsUrl, redirectingToManageAccountsUrl } = useManageBankConnections()
  const { redirectToSubscriptionPage } = useSubscriptionRedirect()
  const { isOverGracePeriod } = useFreeTrialEnd()
  const subscribed = !!subscription?.id

  useEffect(() => {
    redirectToSubscriptionPage({ subscriptionType })
  }, [subscriptionType, redirectToSubscriptionPage])

  const displayDashboardRedirect = reason !== 'ob' && (!isOverGracePeriod || subscribed)

  if (subscriptionType === SubscriptionType.QUIPU) {
    return (
      <div className='h-full w-full flex items-center justify-center'>
        <Spinner className='w-6 h-6 text-primary' />
      </div>
    )
  }

  return (
    <Layout>
      <Layout.Content className='rc-content p-8'>
        <div className='w-full min-h-full bg-white p-8 rounded-lg flex flex-col items-center justify-start relative'>
          <div className='mt-12'>
            {displayDashboardRedirect && (
              <Link to='/dashboard' className='text-gray-400 absolute left-6 top-6'>
                <LeftOutlined /> {t('subscribe.backToDashboard')}
              </Link>
            )}

            {reason === 'u' && (
              <InfoBox type='warning' className='mb-8'>
                {t('subscribe.warning.notPossibleToInviteAnotherUser')} <Link to='/users'>{t('subscribe.link.manageUsers')}</Link>
              </InfoBox>
            )}
            {reason === 'b' && (
              <InfoBox type='warning' className='mb-8'>
                {t('subscribe.warning.notPossibleToAddAnotherBank')}
              </InfoBox>
            )}
            {reason === 'ob' && (
              <InfoBox type='warning' className='mb-8 max-w-lg'>
                <Trans
                  i18nKey='subscribe.warning.youHaveReachedTheBankAccountQuota'
                  components={{ ButtonLink: <ButtonLink loading={redirectingToManageAccountsUrl} onClick={redirectToManageAccountsUrl} label={t('subscribe.removeConnectionToAdditionalBank')} /> }}
                />

              </InfoBox>
            )}

            {reason === 'v' && (
              <InfoBox type='warning' className='mb-8'>
                <Trans
                  i18nKey='subscribe.warning.vatNotAvailable'
                  components={{ Badge: <Badge type='info' label={t('utils.plans.planLocales.PRO')} className='text-xs ml-1' /> }}
                />

              </InfoBox>
            )}

            {reason === 'sb' && (
              <InfoBox type='warning' className='mb-8'>
                <Trans
                  i18nKey='subscribe.warning.triggerBankAccountSyncNotAvaible'
                  components={{ Badge: <Badge type='info' label={t('utils.plans.planLocales.PRO')} className='text-xs ml-1' /> }}
                />

              </InfoBox>
            )}

            {reason === 'ex' && (
              <InfoBox type='warning' className='mb-8'>
                {t('subscribe.warning.exportNotAvailable')}
              </InfoBox>
            )}

            {reason === 'sc' && (
              <InfoBox type='warning' className='mb-8'>
                {t('subscribe.warning.scenarioQuotaExceeded')}
              </InfoBox>
            )}

            {reason === 'cs' && (
              <InfoBox type='warning' className='mb-8'>
                {t('subscribe.warning.configureScenarioNotAvailable')}
              </InfoBox>
            )}
          </div>

          {(subscriptionType === 'SELLSY' && (
            <SellsySubscriptionMessage />
          )) || (
            <PlanSelection />
          )}
        </div>
      </Layout.Content>
    </Layout>
  )
}
