import React from 'react'
import PropTypes from 'prop-types'

const SimpleBar = ({ fill, width, height, forceHeight, x, y, offsetX, offsetY }) => {
  const xProp = (x || 0) + offsetX
  const yProp = (y || 0) + offsetY

  return (
    <g fill={fill} stroke={fill} fillOpacity={1}>
      <rect x={xProp} y={yProp} width={width || 0} height={forceHeight || height || 0} />
    </g>
  )
}

// function computeRightRoundedRectD (x, y, width, height, radius) {
//   return 'M' + x + ',' + y +
//        'h' + (width - radius) +
//        'a' + radius + ',' + radius + ' 0 0 1 ' + radius + ',' + radius +
//        'v' + (height - 2 * radius) +
//        'a' + radius + ',' + radius + ' 0 0 1 ' + -radius + ',' + radius +
//        'h' + (radius - width) +
//        'z'
// }

SimpleBar.defaultProps = {
  fill: '#000000',
  width: 0,
  height: 0,
  forceHeight: 0,
  x: 0,
  y: 0,
  offsetX: 0,
  offsetY: 0
}

SimpleBar.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  forceHeight: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
  offsetX: PropTypes.number,
  offsetY: PropTypes.number
}

export default SimpleBar
