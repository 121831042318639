import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Typography } from 'antd'

import QuoteStatusBadge from './QuoteStatusBadge'
import i18next from 'i18next'
import AccountingDocumentTable from 'components/accounting-documents/AccountingDocumentTable.js'
import CashType from 'enums/CashType.js'
import moment from 'moment'
import ExpectedTransactionExpectedDatePickerShortcut from 'components/expected-transactions/ExpectedTransactionExpectedDatePickerShortcut.js'
import AccountingDocumentTableCategorySelectShortcut from 'components/accounting-documents/AccountingDocumentTableCategorySelectShortcut.js'
import AccountingDocumentAmountPreview from 'components/accounting-documents/AccoutingDocumentAmountPreview.js'
import QuoteActions from './QuoteActions.js'
import MultipleQuoteUpdate from './MultipleQuoteUpdate.js'
import UpdateQuoteManuallyDrawer from './UpdateQuoteManuallyDrawer'
import QuoteNoSyncPanel from './QuoteNoSyncPanel.js'
import useHasQuoteSyncOnce from 'hooks/connections/useHasQuoteSyncOnce.js'
import useConnections from 'hooks/connections/useConnections.js'

const { t } = i18next

const { Text } = Typography

const getColumns = ({
  toggleRowExpand,
  refetchAccountingDocuments: refetchQuotes,
  editAccountingDocument: editQuote
}) => [
  {
    title: t('component.quoteTable.column.status'),
    key: 'status',
    width: '1px',
    onCell: (quote) => ({ onClick: () => { toggleRowExpand(quote.id) } }),
    className: 'cursor-pointer min-w-max',
    render: quote => <QuoteStatusBadge quote={quote} />
  },
  {
    title: t('component.quoteTable.column.quoteNumber'),
    dataIndex: 'quoteNumber',
    key: 'quoteNumber',
    onCell: (quote) => ({ onClick: () => { toggleRowExpand(quote.id) } }),
    className: 'cursor-pointer min-w-48',
    render: text => <Text>{text}</Text>
  },
  {
    title: t('component.quoteTable.column.client'),
    dataIndex: 'thirdPartyName',
    key: 'thirdPartyName',
    width: '160px',
    onCell: (quote) => ({ onClick: () => { toggleRowExpand(quote.id) } }),
    className: 'cursor-pointer',
    render: text => <Text>{text}</Text>
  },
  {
    title: t('component.quoteTable.column.dueDate'),
    dataIndex: 'dueDate',
    key: 'dueDate',
    width: '120px',
    onCell: (quote) => ({ onClick: () => { toggleRowExpand(quote.id) } }),
    className: 'cursor-pointer',
    render: date => date && (<Text>{moment(date).format('DD/MM/YYYY')}</Text>)
  },
  {
    title: t('component.quoteTable.column.expectedDate'),
    key: 'expectedDate',
    width: '170px',
    editable: true,
    render: quote => <ExpectedTransactionExpectedDatePickerShortcut accountingDocument={quote} onExpand={() => { toggleRowExpand(quote.id) }} />
  },
  {
    title: t('shared.category'),
    key: 'category',
    width: '192px',
    className: 'max-w-[192px]',
    render: quote => <AccountingDocumentTableCategorySelectShortcut accountingDocument={quote} onExpand={() => { toggleRowExpand(quote.id) }} />
  },
  {
    title: t('shared.amountInclTax'),
    key: 'amount',
    width: '180px',
    onCell: (quote) => ({ onClick: () => { toggleRowExpand(quote.id) } }),
    className: 'cursor-pointer text-right',
    render: quote => <AccountingDocumentAmountPreview accountingDocument={quote} />
  },
  {
    title: '',
    dataIndex: '',
    key: 'more',
    width: '32px',
    className: 'pl-0',
    render: (quote) => (<QuoteActions quote={quote} refetchQuotes={refetchQuotes} editQuote={editQuote} />)
  }
]

QuoteTable.propTypes = {
  refetchQuotes: PropTypes.func,
  quotesCount: PropTypes.number
}

export default function QuoteTable ({ refetchQuotes, quotesCount, ...rest }) {
  const [selectedQuoteIds, setSelectedQuoteIds] = useState([])
  const [selectedQuotes, setSelectedQuotes] = useState([])
  const [editedQuote, setEditedQuote] = useState(undefined)
  const [editQuoteDrawerVisible, setEditQuoteDrawerVisible] = useState(false)
  const hasQuoteSyncOnce = useHasQuoteSyncOnce()
  const { sellsyConnection } = useConnections()

  const handleRowSelect = useCallback((selectedQuoteIds, selectedQuotes) => {
    setSelectedQuoteIds(selectedQuoteIds)
    setSelectedQuotes(selectedQuotes)
  }, [setSelectedQuoteIds, setSelectedQuotes])

  const onMultipleUpdateSuccess = useCallback(() => {
    setSelectedQuoteIds([])
    setSelectedQuotes([])
  }, [setSelectedQuoteIds, setSelectedQuotes])

  const editQuoteCallback = useCallback(({ quote }) => {
    setEditedQuote(quote)
    setEditQuoteDrawerVisible(true)
  }, [setEditedQuote, setEditQuoteDrawerVisible])

  const onEditDrawerCloseCallback = useCallback(() => {
    setEditedQuote(undefined)
    setEditQuoteDrawerVisible(false)
    refetchQuotes()
  }, [setEditedQuote, setEditQuoteDrawerVisible, refetchQuotes])

  const hasNoQuote = quotesCount === 0
  const isSellsyUser = sellsyConnection !== undefined
  const hasNeverSyncQuote = !hasQuoteSyncOnce

  if (isSellsyUser && hasNeverSyncQuote && hasNoQuote) {
    return (<QuoteNoSyncPanel />)
  }

  return (
    <>
      <MultipleQuoteUpdate
        type={CashType.CASHIN}
        quoteIds={selectedQuoteIds}
        quotes={selectedQuotes}
        onSuccess={onMultipleUpdateSuccess}
        refetchQuotes={refetchQuotes}
      />

      <AccountingDocumentTable
        rowSelection={{
          selectedRowKeys: selectedQuoteIds,
          onChange: handleRowSelect
        }}
        type={CashType.CASHIN}
        getColumns={getColumns}
        refetchAccountingDocuments={refetchQuotes}
        editAccountingDocument={editQuoteCallback}
        {...rest}
      />

      <UpdateQuoteManuallyDrawer quoteId={editedQuote?.id} visible={editQuoteDrawerVisible} onClose={onEditDrawerCloseCallback} type='cashin' />
    </>
  )
}
