import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Layout } from 'antd'

import useAuthenticatedContext from 'hooks/useAuthenticatedContext'
import useDashboardQueryFilters from 'hooks/useQueryFilters'
import ResourcesActions from 'actions/ResourcesActions'
import FreeTrialBanner from 'components/subscription/FreeTrialBanner'
import DashboardCashflowCard from './dashboard/cards/DashboardCashflowCard'
import DashboardCashinCard from './dashboard/cards/DashboardCashinCard'
import DashboardCashoutCard from './dashboard/cards/DashboardCashoutCard'
import DashboardVatCard from './dashboard/cards/DashboardVatCard'
import ReduceDashboardButton from './dashboard/ReduceDashboardButton'
import DashboardMainChartContainer from './dashboard/DashboardMainChartContainer'
import CashflowSheetContainer from './dashboard/cashflow-sheet/CashflowSheetContainer'
import CashflowSheetContext from 'contexts/CashflowSheetContext'
import { useCashflowSheetContext } from 'hooks/useCashflowSheetContext'
import featureConfig from 'config/features.js'

export default function DashboardScreen () {
  const dispatch = useDispatch()

  const { subscription } = useAuthenticatedContext()
  const subscribed = !!subscription?.id
  const { queryFilters, mergeQueryFilters } = useDashboardQueryFilters()
  const cashflowSheetContextValue = useCashflowSheetContext(queryFilters)

  useEffect(() => {
    dispatch(ResourcesActions.READ_CATEGORIES('cashin'))
    dispatch(ResourcesActions.READ_CATEGORIES('cashout'))
  }, [dispatch])

  return (
    <CashflowSheetContext.Provider value={cashflowSheetContextValue}>
      <Layout>
        <Layout.Content className='rc-content'>
          {subscribed === false && (<FreeTrialBanner />)}

          <div className='rc-dashboard-charts-container'>
            {(!queryFilters.reduced && (
              <div className='w-full flex flex-row items-center space-x-5 rc-dashboard-head-charts-container'>
                <div className='flex-grow w-1/4 rc-dashboard-head-chart'>
                  <DashboardCashflowCard />
                </div>

                <div className='flex-grow w-1/4 rc-dashboard-head-chart'>
                  <DashboardCashinCard />
                </div>

                <div className='flex-grow w-1/4 rc-dashboard-head-chart'>
                  <DashboardCashoutCard />
                </div>

                {featureConfig.vatBalanceEnabled && (
                  <div className='flex-grow w-1/4 rc-dashboard-head-chart'>
                    <DashboardVatCard />
                  </div>
                )}
              </div>
            ))}

            <div className='relative flex flex-col space-y-4 bg-white rounded-md pb-4 z-0'>
              <ReduceDashboardButton queryFilters={queryFilters} mergeQueryFilters={mergeQueryFilters} />

              <DashboardMainChartContainer queryFilters={queryFilters} mergeQueryFilters={mergeQueryFilters} />

              <div className='px-8'>
                <CashflowSheetContainer queryFilters={queryFilters} mergeQueryFilters={mergeQueryFilters} />
              </div>
            </div>

            <div className='rc-dashboard-bottom-spacing' />
          </div>
        </Layout.Content>
      </Layout>
    </CashflowSheetContext.Provider>
  )
}
