import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import Currency from 'components/Currency'
import OpenCreateInvoiceManuallyDrawerButton from 'components/invoices/OpenCreateInvoiceManuallyDrawerButton'
import CashflowSheetContext from 'contexts/CashflowSheetContext'
import { LIST_EXPECTED_TRANSACTIONS } from 'graphql/expected-transactions'
import moment from 'moment'
import CashflowSheetDrawerExpectedTransactionList from './CashflowSheetDrawerExpectedTransactionList'
import useDashboardQueryFilters from 'hooks/useQueryFilters.js'

CashflowSheetDrawerOutstandingExpectedTransactionsPanel.propTypes = {
  outstandingExpected: PropTypes.number,
  rowType: PropTypes.string,
  rowCategoryId: PropTypes.string,
  sheetDate: PropTypes.object,
  categoryIds: PropTypes.arrayOf(PropTypes.string)
}

export default function CashflowSheetDrawerOutstandingExpectedTransactionsPanel ({
  outstandingExpected,
  rowType,
  rowCategoryId,
  sheetDate,
  categoryIds
}) {
  const { t } = useTranslation()
  const { queryFilters } = useDashboardQueryFilters()
  const scenarioId = useMemo(() => queryFilters.scenarioId, [queryFilters.scenarioId])

  const type = useMemo(() => {
    if (['CASH_IN', 'CASHIN_CATEGORY'].includes(rowType)) return 'cashin'

    return 'cashout'
  }, [rowType])

  const { fromDate, toDate } = useMemo(() => {
    const fromDate = moment(sheetDate.momentDate).startOf('month').format('YYYY-MM-DD')
    const toDate = moment(sheetDate.momentDate).endOf('month').format('YYYY-MM-DD')

    return { fromDate, toDate }
  }, [sheetDate])

  const { refetchCashflowSheet } = useContext(CashflowSheetContext)

  const { data, loading, refetch } = useQuery(LIST_EXPECTED_TRANSACTIONS, {
    variables: {
      type,
      fromDate,
      toDate,
      categoryIds,
      paid: false,
      offset: 0,
      limit: 10,
      orderBy: 'expectedDate',
      scenarioId: scenarioId
    }
  })

  const expectedTransactions = useMemo(() => data?.listExpectedTransactions?.expectedTransactions || [], [data])
  const total = useMemo(() => (data?.listExpectedTransactions?.total || 0), [data])

  const defaultDueDate = useMemo(() => moment(sheetDate.momentDate).endOf('month'), [sheetDate])

  const onCreateInvoiceSuccess = useCallback(() => {
    refetch()
    refetchCashflowSheet()
  }, [refetch, refetchCashflowSheet])

  if (sheetDate?.isPastMonth) return null

  return (
    <>
      <Disclosure defaultOpen>
        <div className='group w-full flex flex-row justify-between items-center space-x-2'>
          <Disclosure.Button>
            {({ open }) => (
              <div className='flex flex-row items-center space-x-2'>
                {(open && (
                  <ChevronDownIcon className='w-6 h-6 text-gray-400' />
                )) || (
                  <ChevronRightIcon className='w-6 h-6 text-gray-400' />
                )}
                <span>{t('dashboard.cashflowSheetDrawerOutstandingExpectedTransactionsPanel.expectedTransactionsAwaiting', { total })}</span>
                <span className='text-gray-400'>/</span>
                <Currency amount={outstandingExpected} className='font-bold' />
              </div>
            )}
          </Disclosure.Button>

          <div className='transition-opacity opacity-0 group-hover:opacity-100'>
            <OpenCreateInvoiceManuallyDrawerButton type={type} defaultCategoryId={rowCategoryId} defaultDueDate={defaultDueDate} onSuccess={onCreateInvoiceSuccess} />
          </div>
        </div>

        <Disclosure.Panel className='overflow-y-scroll rc-show-scrollbar mt-2 mb-4 pl-8 pr-4 min-h-[380px]'>
          <div className='pr-1 -mr-3'>
            <CashflowSheetDrawerExpectedTransactionList
              type={type}
              expectedTransactions={expectedTransactions}
              total={total}
              loading={loading}
              refetch={refetch}
              withExpectedDatePicker
            />
          </div>
        </Disclosure.Panel>
      </Disclosure>
    </>

  )
}
