import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { CloseOutlined } from '@ant-design/icons'
import { Row, Col, Button } from 'antd'
import { getStatus } from 'redux-resource'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { generate } from '@ant-design/colors'

import { categoryColors } from 'utils/colors'
import CategoryForm from './CategoryForm'
import RowCategoryBorder from './RowCategoryBorder'
import resourcesActions from 'actions/ResourcesActions'
import { adaptError } from 'utils/legacy-forms.js'

const NewCategoryLine = ({
  type,
  parentCategory,
  hideSubCategoryForm
}) => {
  const dispatch = useDispatch()
  const parentId = get(parentCategory, 'id')
  const parentColor = get(parentCategory, 'color')
  const createCategoryStatus = useSelector(state => getStatus(state, `categories.requests.createCategory-${parentId}.status`))
  const createCategoryErrors = useSelector(state => get(state, `categories.requests.createCategory-${parentId}.errors`))

  const adaptedErrors = adaptError({ errors: createCategoryErrors })

  const palette = useMemo(() => {
    if (parentColor) return generate(parentColor)
    return categoryColors
  }, [parentColor])

  const initialColor = useMemo(() => {
    if (parentColor) return palette[4]
    return undefined
  }, [parentColor, palette])

  const successCallback = useCallback((resetChanges) => {
    resetChanges()
    if (hideSubCategoryForm) hideSubCategoryForm()
  }, [hideSubCategoryForm])

  const createCategory = useCallback((values, resetChanges) => {
    dispatch(resourcesActions.CREATE_CATEGORY(
      { type, parentId, ...values },
      () => successCallback(resetChanges)
    ))
  }, [dispatch, successCallback, parentId, type])

  return (
    <Row gutter={20} style={{ marginBottom: parentId ? 0 : 20 }} className='rc-category-line'>
      <RowCategoryBorder category={{ color: initialColor }} style={{ bottom: 0 }} />

      <Col xs={{ col: 18, offset: 6 }} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <CategoryForm
          submit={createCategory}
          submitting={createCategoryStatus.pending}
          initialValues={{ color: initialColor }}
          colorPalette={palette}
          errors={adaptedErrors}
        />

        {parentId && (
          <Button type='dashed' icon={<CloseOutlined />} onClick={hideSubCategoryForm} />
        )}
      </Col>
    </Row>
  )
}

NewCategoryLine.propTypes = {
  type: PropTypes.oneOf(['cashin', 'cashout']),
  parentCategory: PropTypes.object,
  hideSubCategoryForm: PropTypes.func
}

export default NewCategoryLine
