import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import TransactionSelect from 'components/transactions/TransactionSelect'
import { useDispatch } from 'react-redux'
import ResourcesActions from 'actions/ResourcesActions'
import TransactionPreview from 'components/transactions/TransactionPreview'
import Button from 'design/Button'
import { LIST_TRANSACTIONS_FOR_REFUND, MARK_TRANSACTION_AS_REFUND } from 'graphql/transactions'
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import Currency from 'components/Currency'
import { useMutation } from '@apollo/client'
import { useTranslation, Trans } from 'react-i18next'

RefundTransactionDrawerContent.propTypes = {
  transaction: PropTypes.object,
  closeRefundTransactionDrawer: PropTypes.func
}

export default function RefundTransactionDrawerContent ({ transaction, closeRefundTransactionDrawer }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [selectedTransaction, setSelectedTransaction] = useState()

  const antiType = useMemo(() => (transaction.type === 'cashin' ? 'cashout' : 'cashin'), [transaction.type])

  useEffect(() => {
    dispatch(ResourcesActions.READ_CATEGORIES(antiType))
  }, [dispatch, antiType])

  const isAlreadyRefunded = useMemo(() => {
    return !!selectedTransaction?.refundTransactionId || !!selectedTransaction?.refundedTransactionId
  }, [selectedTransaction])

  const isSplitPart = useMemo(() => {
    return !!selectedTransaction?.originalTransactionId
  }, [selectedTransaction])

  const hasGreaterAmount = useMemo(() => {
    if (!selectedTransaction) return false

    const selectedTxnAmount = selectedTransaction.amount
    return transaction.amount > selectedTxnAmount
  }, [selectedTransaction, transaction])

  const disabled = useMemo(() => {
    return !selectedTransaction || isAlreadyRefunded || isSplitPart || hasGreaterAmount
  }, [selectedTransaction, isAlreadyRefunded, isSplitPart, hasGreaterAmount])

  const handleTransactionSelect = useCallback((newSelectedTransaction) => {
    if (newSelectedTransaction?.id === selectedTransaction?.id) setSelectedTransaction(undefined)
    else setSelectedTransaction(newSelectedTransaction)
  }, [selectedTransaction, setSelectedTransaction])

  const [markTransactionAsRefund, { loading }] = useMutation(MARK_TRANSACTION_AS_REFUND, {
    variables: { refundTransactionId: transaction.id, refundedTransactionId: selectedTransaction?.id },
    onCompleted: closeRefundTransactionDrawer
  })

  return (
    <div className='h-full flex flex-col'>
      <span className='mb-2'>{t('shared.refund')}</span>
      <TransactionPreview transaction={transaction} showCategory />

      <span className='mb-2 mt-4'>{t('component.refundTransactionDrawerContent.selectCashoutToRefund')}</span>
      <TransactionSelect
        gqlQuery={LIST_TRANSACTIONS_FOR_REFUND}
        type={antiType}
        onSelect={handleTransactionSelect}
        selectedId={selectedTransaction?.id}
        exactAmountToMatchFirst={transaction.amount}
      />

      <div className='flex-grow flex flex-col space-y-4 mt-4 pb-4 items-center justify-end'>
        {isAlreadyRefunded && (
          <span className='text-yellow-600 flex flex-row items-center'>
            <ExclamationTriangleIcon className='w-4 h-4 mr-4' />
            <span>{t('component.refundTransactionDrawerContent.selectedCashoutAlreadyLinkedToARefund')}</span>
          </span>
        )}

        {isSplitPart && (
          <span className='text-yellow-600 flex flex-row items-center'>
            <ExclamationTriangleIcon className='w-4 h-4 mr-4' />
            <span>{t('component.refundTransactionDrawerContent.splitPartCannotBeRefunded')}</span>
          </span>
        )}

        {hasGreaterAmount && (
          <span className='text-yellow-600 flex flex-row items-center'>
            <ExclamationTriangleIcon className='w-6 h-6 mr-2' />
            <span>
              <Trans
                i18nKey='component.refundTransactionDrawerContent.refundCannotBeGreaterThanCashout'
                components={{
                  RefundAmount: <Currency amount={transaction.amount} className='inline' />,
                  TransactionAmount: <Currency amount={selectedTransaction?.amount} className='inline' />
                }}
              />
            </span>

          </span>
        )}

        <Button
          primary
          label={t('component.refundTransactionDrawerContent.validateRefund')}
          loading={loading}
          disabled={disabled}
          onClick={markTransactionAsRefund}
        />
      </div>
    </div>
  )
}
