import React, { useContext, useMemo, useState } from 'react'
import { Modal } from 'antd'
import { get } from 'lodash'
import { Cog6ToothIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { c } from 'utils/currencies'
import SimpleChart from 'components/charts/SimpleChart'
import CashInfo from 'components/CashInfo'
import UpdateWcrPopover from 'components/wcr/UpdateWcrPopover'
import useAuthenticatedContext from 'hooks/useAuthenticatedContext'
import ButtonLink from 'design/ButtonLink'
import CashflowSheetContext from 'contexts/CashflowSheetContext'

function DashboardCashflowCard () {
  const { t } = useTranslation()
  const { currentMonthRealised, sheet } = useContext(CashflowSheetContext)
  const { company } = useAuthenticatedContext()
  const [modalVisible, setModalVisible] = useState(false)

  const dataUntilNow = useMemo(() => {
    return (sheet || []).filter(({ value }) => value?.realisedEndingBalance !== null)
  }, [sheet])

  const endingBalance = useMemo(() => {
    return get(currentMonthRealised, 'value.realisedEndingBalance')
  }, [currentMonthRealised])

  return (
    <div className='relative h-full bg-white rounded-md p-4'>
      <div className='flex flex-row items-start justify-between'>
        <CashInfo
          title={t('dashboard.cashflowCard.cashInfo.title')}
          infoLabel={t('dashboard.cashflowCard.cashInfo.infoLabel')}
          text={c(endingBalance)}
          color='cashflow'
        />

        <ButtonLink label={<Cog6ToothIcon className='w-5 h-5' />} onClick={() => setModalVisible(true)} className='p-0' />

        <Modal open={modalVisible} onCancel={() => setModalVisible(false)} footer={null}>
          <UpdateWcrPopover onSave={() => setModalVisible(false)} />
        </Modal>
      </div>

      <div className='rc-full-card-graph'>
        <SimpleChart
          data={dataUntilNow}
          limitLabel={t('dashboard.cashflowCard.chart.limitLabel')}
          limit={company?.wcr}
          dataKey='value.realisedEndingBalance'
          color='cashflow'
        />
      </div>
    </div>
  )
}

export default DashboardCashflowCard
