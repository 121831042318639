import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'

import Spinner from 'design/Spinner'
import { GET_STRIPE_BILLING_PORTAL_URL } from 'graphql/billing.js'
import { useQuery } from '@apollo/client'

export default () => {
  const [hasError, setHasError] = useState(false)

  const { data } = useQuery(GET_STRIPE_BILLING_PORTAL_URL, {
    onCompleted: () => {
      window.location.replace(data.getStripeBillingPortalUrl)
    },
    onError: () => {
      setHasError(true)
    }
  })

  if (hasError) return <Redirect to='/' />

  return (
    <div className='flex flex-col w-full h-full items-center justify-center'>
      <Spinner className='w-6 h-6 text-primary' />
    </div>
  )
}
