import React from 'react'
import PropTypes from 'prop-types'

import colors from 'utils/colors'
import CashflowSheetComparisonCell from './components/CashflowSheetComparisonCell'
import CashflowSheetForecastedCompletionRateOverlay from '../CashflowSheetForecastedCompletionRateOverlay.js'
import CashType from 'enums/CashType.js'

CashflowSheetTotalCell.propTypes = {
  row: PropTypes.object,
  realisedVsForecasted: PropTypes.bool,
  showPercentage: PropTypes.bool
}

export default function CashflowSheetTotalCell ({ row, realisedVsForecasted, showPercentage }) {
  if (['STARTING_BALANCE', 'ENDING_BALANCE'].includes(row.type)) return null

  const cashType = getTotalCashType(row.type)
  const fill = getTotalFillColor(cashType)
  const realised = row.total?.realised
  const forecasted = row.total?.forecasted

  return (
    <CashflowSheetForecastedCompletionRateOverlay
      type={cashType}
      outlineBadge={getOutline(row.type)}
      visible={realisedVsForecasted}
      realisedAmount={realised}
      forecastedAmount={forecasted}
      showPercentage={showPercentage}
    >
      <CashflowSheetComparisonCell
        fill={fill}
        realisedAmount={realised}
        forecastedAmount={forecasted}
        strong
        barOnTop
        noBar={['VAT_PAYMENTS'].includes(row.type)}
        sign={row.type === 'VAT_PAYMENTS'}
      />
    </CashflowSheetForecastedCompletionRateOverlay>
  )
}

function getTotalCashType (rowType) {
  switch (rowType) {
    case 'CASH_IN':
    case 'CASHIN_CATEGORY':
      return CashType.CASHIN
    case 'CASH_OUT':
    case 'CASHOUT_CATEGORY':
      return CashType.CASHOUT
    default:
      return CashType.CASHFLOW
  }
}

function getOutline (rowType) {
  switch (rowType) {
    case 'CASH_IN':
    case 'CASH_OUT':
    case 'CASH_FLOW':
      return true
    default:
      return false
  }
}

function getTotalFillColor (cashType) {
  switch (cashType) {
    case CashType.CASHIN:
      return colors.success
    case CashType.CASHOUT:
      return colors.error
    default:
      return colors.primary
  }
}
