import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Drawer, Tabs, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid'
import cx from 'classnames'

import useAuthenticatedContext from 'hooks/useAuthenticatedContext'
import Title from 'design/Title'
import ButtonLink from 'design/ButtonLink'
import Badge from 'design/Badge'
import ExportForm from './ExportForm.js'
import { useTranslation, Trans } from 'react-i18next'
import featureConfig from 'config/features.js'

ExportDrawerContainer.propTypes = {
  year: PropTypes.number,
  month: PropTypes.number,
  type: PropTypes.string,
  categoryIds: PropTypes.arrayOf(PropTypes.string)
}

export default function ExportDrawerContainer ({ year, month, categoryIds, type }) {
  const { t } = useTranslation()
  const [exportDrawerVisible, setExportDrawerVisible] = useState(false)
  const { subscription } = useAuthenticatedContext()
  const hasAccessToTransactionsExport = !subscription || subscription.hasAccessToTransactionsExport
  const hasAccessToBatchReceiptsDownload = !subscription || subscription.hasAccessToBatchReceiptsDownload
  const hasAccessToExportData = hasAccessToTransactionsExport || hasAccessToBatchReceiptsDownload

  const onTooltipClick = () => {
    if (hasAccessToExportData) {
      setExportDrawerVisible(true)
    }
  }

  const modes = { transaction: 'transaction', document: 'document' }

  const tooltipTitle = (
    <div>
      {hasAccessToExportData && (<div>{t('component.exportDrawerContainer.exportTransactions')}</div>)}
      {!hasAccessToExportData && (
        <Link to='/subscribe?r=ex'>
          <div className='text-center'>
            <span className='text-white'>
              <Trans
                i18nKey='component.exportDrawerContainer.exportIsOnlyAvailableFromProPlan'
                components={{ Badge: <Badge type='info' label={t('utils.plans.planLocales.PRO')} className='text-xs ml-1' /> }}
              />
            </span>
            <span className='text-primary hover:underline ml-1'> {t('component.exportDrawerContainer.learnMore')}</span>
          </div>
        </Link>
      )}
    </div>
  )

  return (
    <>
      <Drawer
        title={<Title label={t('component.exportDrawerContainer.exportTransactions')} />}
        closeIcon={<XMarkIcon className='w-5 h-5' />}
        width={540}
        placement='right'
        onClose={() => setExportDrawerVisible(false)}
        open={exportDrawerVisible}
      >
        <Tabs
          defaultActiveKey={modes.transaction}
          items={[
            hasAccessToTransactionsExport && {
              label: <Trans i18nKey='component.exportDrawerContainer.exportTransactions.tabName' />,
              key: modes.transaction,
              children: <ExportForm year={year} month={month} categoryIds={categoryIds} type={type} mode={modes.transaction} />
            },
            hasAccessToBatchReceiptsDownload && featureConfig.batchReceiptsDownloadEnabled && {
              label: <div><span className='mr-1'> <Trans i18nKey='component.exportDrawerContainer.exportDocuments.tabName' /></span></div>,
              key: modes.document,
              children: <ExportForm year={year} month={month} categoryIds={categoryIds} type={type} mode={modes.document} />
            }
          ]}
        />

      </Drawer>

      <Tooltip placement='top' mouseEnterDelay={0.4} title={tooltipTitle}>
        <ButtonLink
          onClick={onTooltipClick}
          label={<ArrowDownTrayIcon className={cx('w-5 h-5', { 'text-base-color': !hasAccessToExportData })} />}
        />
      </Tooltip>
    </>
  )
}
