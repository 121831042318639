import React from 'react'
import { Radio } from 'antd'
import PropTypes from 'prop-types'

import CurrencyInput from 'components/CurrencyInput'
import PercentInput from 'components/PercentInput'

GrowthVariation.propTypes = {
  growthType: PropTypes.string,
  growthOperation: PropTypes.string,
  growthValue: PropTypes.number,
  onChange: PropTypes.func

}

function GrowthVariation ({
  growthType,
  growthOperation,
  growthValue,
  onChange
}
) {
  return (
    <>
      <Radio.Group
        buttonStyle='solid'
        className='rc-custom-radio min-w-max'
        size='small'
        value={growthType}
        onChange={(e) => onChange({ growthType: e.target.value })}
      >
        <Radio.Button value='PERCENTAGE'>%</Radio.Button>
        <Radio.Button value='AMOUNT'>€</Radio.Button>
      </Radio.Group>

      <Radio.Group
        defaultValue='ADD'
        buttonStyle='solid'
        className='rc-custom-radio min-w-max'
        size='small'
        value={growthOperation}
        onChange={(e) => onChange({ growthOperation: e.target.value })}
      >
        <Radio.Button value='SUBTRACT'>-</Radio.Button>
        <Radio.Button value='ADD'>+</Radio.Button>
      </Radio.Group>

      {(growthType === 'PERCENTAGE' && (
        <PercentInput
          min={0}
          value={growthValue}
          onChange={(growthValue) => onChange({ growthValue })}
          className='max-w-18'
          grow
        />
      )) || (
        <CurrencyInput
          min={0}
          value={growthValue}
          onChange={(growthValue) => onChange({ growthValue })}
          className='max-w-18'
          grow
        />
      )}
    </>
  )
}

export default GrowthVariation
