import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Badge from 'design/Badge'
import i18next from 'i18next'

const badgeTypes = {
  SENT: 'primary',
  READ: 'primary',
  ACCEPTED: 'success',
  DRAFT: 'disabled',
  CANCELED: 'disabled',
  EXPIRED: 'disabled',
  DEPOSIT: 'warning',
  PARTIAL_INVOICED: 'warning',
  INVOICED: 'success',
  IGNORED: 'disabled'
}

const OrderStatusBadge = ({ order, style, ...rest }) => {
  const { t } = i18next

  const computedStatus = useMemo(() => {
    return order.ignored ? 'IGNORED' : order.status
  }, [order])

  const badgeType = badgeTypes[computedStatus]

  const context = useMemo(() => {
    return computedStatus
  }, [computedStatus])

  return (
    <Badge
      type={badgeType}
      className='text-center min-w-max'
      label={t('component.orderStatus.label', { context })}
      {...rest}
    />
  )
}

OrderStatusBadge.propTypes = {
  order: PropTypes.object,
  checkable: PropTypes.bool,
  style: PropTypes.object
}

export default OrderStatusBadge
