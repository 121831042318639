import { all } from 'redux-saga/effects'

import resourcesSagas from './resources-sagas'
import authSagas from './auth-sagas'
import aggregationsSagas from './aggregations-sagas'
import vatSagas from './vat-sagas'

export default function * rootSaga () {
  yield all([
    ...resourcesSagas,
    ...authSagas,
    ...aggregationsSagas,
    ...vatSagas
  ])
}
