import { getTenantDefaultLocale } from 'config/i18n.js'
import useAuthenticatedContext from './useAuthenticatedContext.js'

export default function useLocale () {
  const { user } = useAuthenticatedContext()

  const userLocale = user?.locale

  return userLocale || getTenantDefaultLocale()
}
