import React, { useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Card, Row, Col, Typography } from 'antd'
import { createSelector } from 'reselect'
import { getResources } from 'redux-resource'
import { useSelector, useDispatch } from 'react-redux'
import { get, set, first } from 'lodash'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

import { c } from 'utils/currencies'
import aggregationsActions from 'actions/AggregationsActions'
import resourcesActions from 'actions/ResourcesActions'
import YearSelect from 'components/YearSelect'
import TransactionTypeChart from 'components/charts/TransactionTypeChart'
import TransactionTypeTooltip from 'components/charts/TransactionTypeTooltip'
import CashInfo from 'components/CashInfo'
import CategoryTreeCheck from 'components/categories/CategoryTreeCheck'
import VatToggle from 'components/vat/VatToggle'
import { getCategoriesWithForecastSelector } from 'selectors/categories'
import { getScenarioTotalTypeByMonth, getScenarioName } from 'selectors/scenarios'
import useJsonQueryParams from 'hooks/useJsonQueryParams'
import featureConfig from 'config/features.js'

const now = moment()
const { t } = i18next

const getBeginningOfMonth = (year, monthTotal) => (
  moment().year(year).month(get(monthTotal, 'month') - 1).startOf('month')
)

const getMonthTotalPath = (withVat) => (
  withVat === 'true' ? 'totalForAllTransactions' : 'totalForAllTransactionsWithoutVAT'
)

const getMonthPercentagePath = (withVat) => (
  withVat === 'true' ? 'percentage' : 'percentageWithoutVAT'
)

const getCategoryTotalPath = (withVat) => (
  withVat === 'true' ? 'real' : 'realWithoutVAT'
)

const getTotalTransactionTypeDataSelector = createSelector(
  (state, type, year) => get(state, `aggregations.total.${type}.${year}.totalPerMonth`, []),
  (state, type, __, scenarioId) => getScenarioTotalTypeByMonth(state, scenarioId, type),
  (state, _, __, scenarioId) => getScenarioName(state, scenarioId),
  (_, type, year, __, filteredCategoriesWithForecast, withVat, scenarioEnabled) => [type, year, filteredCategoriesWithForecast, withVat, scenarioEnabled],
  (totalPerMonth, scenarioTotalByMonth, scenarioName, [type, year, filteredCategoriesWithForecast, withVat, scenarioEnabled]) => {
    return totalPerMonth.map((monthTotal) => {
      const datetime = getBeginningOfMonth(year, monthTotal)
      const monthNumber = datetime.month() + 1

      const data = {
        name: moment(datetime).endOf('month').format('MMM DD')
      }

      if (datetime < now) {
        data.total = get(monthTotal, getMonthTotalPath(withVat))
        data.percentage = get(monthTotal, getMonthPercentagePath(withVat))
      }

      if (scenarioEnabled) {
        data.scenarioName = scenarioName
        data.totalPlanned = get(scenarioTotalByMonth, monthNumber)
      }

      const realPath = getCategoryTotalPath(withVat)

      filteredCategoriesWithForecast.forEach((category) => {
        if (datetime < now) {
          const real = get(category, `forecast.${monthNumber}.${realPath}`, 0)
          set(data, `${category.id}.real`, real)
        }

        if (scenarioEnabled) {
          const plannedCumulated = get(category, `forecast.${monthNumber}.plannedCumulated`, 0)
          set(data, `${category.id}.plannedCumulated`, plannedCumulated)
        }
      })

      data.renderTooltip = () => (
        <TransactionTypeTooltip
          type={type}
          datetime={datetime}
          data={data}
          categories={filteredCategoriesWithForecast}
          withVat={withVat}
          scenarioEnabled={scenarioEnabled}
        />
      )

      return data
    })
  }
)

const typeConfig = {
  cashout: {
    name: t('shared.cashoutPlural')?.toLowerCase(),
    colorName: 'cashout'
  },
  cashin: {
    name: t('shared.cashinPlural')?.toLowerCase(),
    colorName: 'cashin'
  }
}

const TotalYearOverview = ({ type }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [filters, setFilters] = useJsonQueryParams()
  const year = useMemo(() => (filters.year || now.year()), [filters.year])
  const scenarioEnabled = false
  const firstScenarioId = useSelector(state => get(first(getResources(state.scenarios)), '_id'))
  const selectedScenario = useMemo(() => (filters.scenarioId || firstScenarioId), [filters.scenarioId, firstScenarioId])
  const withVat = useMemo(() => {
    if (scenarioEnabled) return 'true'
    return filters.withVat || 'true'
  }, [filters.withVat, scenarioEnabled])
  const filterCategories = useMemo(() => (filters.categoryIds || []), [filters.categoryIds])

  const totalYearPath = withVat === 'true' ? 'totalForTheYear' : 'totalForTheYearWithoutVAT'
  const totalForTheYear = useSelector(state => get(state, `aggregations.total.${type}.${year}.${totalYearPath}`))
  const categoriesWithForecast = useSelector(state => getCategoriesWithForecastSelector(state, type, year, selectedScenario))
  const filteredCategoriesWithForecast = useMemo(() => {
    return categoriesWithForecast.filter((cat) => filterCategories.includes(cat.id))
  }, [categoriesWithForecast, filterCategories])
  const yearData = useSelector(state => getTotalTransactionTypeDataSelector(state, type, year, selectedScenario, filteredCategoriesWithForecast, withVat, scenarioEnabled))

  useEffect(() => {
    dispatch(aggregationsActions.requestTransactionTypeTotal(type, year))
  }, [dispatch, type, year])

  useEffect(() => {
    dispatch(resourcesActions.READ_CATEGORIES(type))
  }, [dispatch, type])

  const handleCategoryIdsChange = useCallback((categoryIds) => {
    const length = get(categoryIds, 'length', 0)
    const value = length > 0 ? categoryIds : undefined

    setFilters({ ...filters, categoryIds: value || undefined })
  }, [setFilters, filters])

  const handleYearChange = useCallback((year) => {
    setFilters({ ...filters, year: year || undefined })
  }, [setFilters, filters])

  const handleWithVatChange = useCallback((withVat) => {
    setFilters({ ...filters, withVat })
  }, [setFilters, filters])

  return (
    <Card bordered={false}>
      <Row gutter={[20, 20]} style={{ display: 'flex', alignItems: 'center' }}>
        <Col xs={12} lg={3}>
          <YearSelect onChange={handleYearChange} defaultValue={year} />
        </Col>

        <Col xs={12} lg={8}>
          <VatToggle disabled={scenarioEnabled} value={withVat} onChange={(e) => handleWithVatChange(e.target.value)} />
        </Col>
      </Row>

      <Row className='rc-margin-top'>
        <Col span={24}>
          <CashInfo
            loading={false}
            title={t('component.totalYearOverview.totalCashtypeYear', { context: type, year })}
            text={c(totalForTheYear)}
            subtext={!featureConfig.vatDetailsEnabled ? '' : withVat === 'true' ? 'TTC' : 'HT'}
            style={{ height: '50%' }}
            color={typeConfig[type].colorName}
          />
        </Col>
      </Row>

      <Row className='rc-margin-top'>
        <Col span={24} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
          <div style={styles.chartContainer}>
            <TransactionTypeChart
              type={type}
              data={yearData}
              filterCategories={filterCategories}
              scenarioEnabled={scenarioEnabled}
            />
          </div>

          <div style={styles.selectContainer}>
            <div style={styles.totalContainer}>
              <Checkbox
                onChange={handleCategoryIdsChange}
                checked={filterCategories.length === 0}
              >
                <Typography.Text strong>{t('component.totalYearOverview.totalCashtype', { context: type })}</Typography.Text>
              </Checkbox>
            </div>

            <div style={styles.categorySelectContainer} className='rc-show-scrollbar'>
              <CategoryTreeCheck
                type={type}
                checkedKeys={filterCategories}
                onCheck={(checkedKeys) => handleCategoryIdsChange(checkedKeys.checked)}
              />
            </div>
          </div>
        </Col>
      </Row>
    </Card>
  )
}

const styles = {
  chartContainer: {
    display: 'flex',
    height: '400px',
    flexGrow: 1,
    width: 'calc(100% - 300px)'
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'column',
    float: 'right',
    flexGrow: 1,
    maxWidth: 300,
    marginLeft: 20,
    height: '400px'
  },
  totalContainer: {
    height: '40px'
  },
  categorySelectContainer: {
    display: 'flex',
    overflowY: 'scroll',
    maxHeight: '360px',
    maxWidth: 240
  }
}

TotalYearOverview.propTypes = {
  type: PropTypes.oneOf(['cashin', 'cashout'])
}

export default TotalYearOverview
