import React, { createContext } from 'react'
import { useQuery } from '@apollo/client'
import { LIST_SCENARIOS } from 'graphql/scenarios.js'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useSelector } from 'react-redux'

ScenariosContextProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const ScenariosContext = createContext()

export default function ScenariosContextProvider ({ children }) {
  const isAuthenticated = useSelector(state => get(state, 'app.isAuthenticated'))
  const { loading, data, refetch } = useQuery(LIST_SCENARIOS, {
    fetchPolicy: 'cache-and-network',
    skip: !isAuthenticated
  })

  return (
    <ScenariosContext.Provider value={{ loading, data, refetch }}>
      {children}
    </ScenariosContext.Provider>
  )
}
