import React from 'react'
import { Link } from 'react-router-dom'
import { getBookDemoLink } from 'utils/contact'
import useAuthenticatedContext from 'hooks/useAuthenticatedContext'
import { useTranslation, Trans } from 'react-i18next'
import featureConfig from 'config/features.js'
import useFreeTrialEnd from 'hooks/subscriptions/useFreeTrialEnd.js'

export default () => {
  const { t } = useTranslation()
  const { user } = useAuthenticatedContext()
  const { daysToGo } = useFreeTrialEnd()

  if (isNaN(daysToGo)) return null

  return (
    <div className='rc-free-trial-banner'>
      <div className='text-black text-opacity-50'>
        {
          (daysToGo <= 0 && (
            t('component.freeTrialBanner.trialPeriodIsOver')
          )) || (
            t('component.freeTrialBanner.trialPeriodEndsInXDays', { count: daysToGo }) // this translation works but is not detected by i18n ally as it doesn't support plural keys yet
          )
        }
        {user?.role === 'admin' && (
          <>
            {' - '}
            <Link to='/subscribe'>
              {t('component.freeTrialBanner.chooseAPlan')}
            </Link>
          </>
        )}
      </div>

      {featureConfig.demoLinkEnabled && (
        <div className='text-black text-opacity-50'>
          <Trans
            i18nKey='component.freeTrialBanner.bookADemo'
            components={{ Link: <a href={getBookDemoLink()} target='_blank' rel='noopener noreferrer' /> }}
          />
        </div>
      )}
    </div>
  )
}
