import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { ca, en, es, fr } from '../locales'
import Locale from 'enums/Locale.js'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  ca: { translation: ca },
  en: { translation: en },
  es: { translation: es },
  fr: { translation: fr }
}

export const getTenantDefaultLocale = () => {
  switch (process.env.REACT_APP_TENANT) {
    case 'DEFAULT':
      return Locale.fr_FR
    case 'QUIPU':
      return Locale.es_ES
    default:
      return Locale.en_US
  }
}

const defaultLocale = getTenantDefaultLocale()

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    returnEmptyString: false, // fallbacks well if translation is empty
    lng: defaultLocale, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    fallbackLng: {
      'ca-ES': ['es', 'en', 'fr'],
      'es-ES': ['en', 'fr'],
      'en-US': [defaultLocale, 'fr'],
      default: [defaultLocale, 'fr']
    },
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

export default i18n
