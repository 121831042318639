import React from 'react'
import PropTypes from 'prop-types'

export default function Card ({ children, header, ...rest }) {
  return (
    <div className='rounded-md bg-white overflow-hidden sm:rounded-lg sm:shadow'>
      {header}

      {children && (
        <div className='p-4 sm:p-6'>
          {children}
        </div>
      )}
    </div>
  )
}

Card.propTypes = {
  header: PropTypes.node,
  children: PropTypes.node
}
