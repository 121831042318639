import React, { useCallback, useEffect, useState } from 'react'
import { Typography } from 'antd'
import Spinner from 'design/Spinner'
import { useTranslation, Trans } from 'react-i18next'

const { Title } = Typography

const twoMinutes = 2 * 60 * 1000

export default function SynchronizingScreen () {
  const { t } = useTranslation()
  const [tooLong, setTooLong] = useState(false)

  const displayPopup = useCallback(() => setTooLong(true), [setTooLong])

  useEffect(() => {
    const displayPopupTimeout = setTimeout(displayPopup, twoMinutes)
    return () => clearTimeout(displayPopupTimeout)
  }, [displayPopup])

  return (
    <div className='flex flex-row justify-center'>
      {(tooLong && (
        <div className='rc-onboarding-screen-container'>
          <div className='text-lg'>
            {t('component.synchronizingInfo.takingTooLong')}
          </div>

          <div className='text-lg mt-4'>
            <Trans
              i18nKey='component.synchronizingInfo.youWillReceiveAMail'
              components={{ Span: <span className='text-primary font-bold' /> }}
            />

          </div>

          <div className='text-lg mt-4'>
            {t('component.synchronizingInfo.youCanCloseThisPage')}
          </div>
        </div>
      )) || (
        <>
          <img
            width='360'
            src='https://media.giphy.com/media/qjj4xrA1STjfa/giphy.gif'
            style={styles.gif}
            alt='Man doing stuff in apollo 9 launch control.'
          />

          <div className='flex flex-col ml-8 mt-4'>
            <Title level={3}>{t('component.synchronizingInfo.yourTransactionsAreSyncing')}</Title>
            <div className='text-lg flex flex-row items-center'><span>{t('component.synchronizingInfo.upTo2Minutes')}</span> <Spinner className='ml-2 w-4 h-4 text-primary inline' /></div>
          </div>
        </>
      )}
    </div>
  )
}

const styles = {
  // gifContainer: {
  //   width: '100%',
  //   display: 'flex',
  //   justifyContent: 'center'
  // },
  container: {
    marginTop: 40,
    maxWidth: 520,
    width: '100%'
  },
  gif: {
    marginTop: 20,
    borderRadius: 10,
    textAlign: 'center'
  }
}
