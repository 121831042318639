import React from 'react'
import { useTranslation } from 'react-i18next'
import numeral from 'numeral'
import PropTypes from 'prop-types'

const getPercent = (vatRate) => numeral(vatRate || 0).multiply(100).value()

const VatLabel = ({ vatRate, ...rest }) => {
  const { t } = useTranslation()
  const percent = getPercent(vatRate)
  return (
    <div {...rest}>
      {vatRate === 0 ? t('component.vatSelect.noVAT') : `${percent}%`}
    </div>
  )
}

VatLabel.propTypes = {
  vatRate: PropTypes.number
}

export default VatLabel
