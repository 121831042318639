import { Badge, Layout, Menu, Typography } from 'antd'
import { get } from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router'

import ActionsLeftInfo from 'components/ActionsLeftInfo'
import CategorizationHelperContext from 'contexts/CategorizationHelperContext'
import useCategorizationHelper from 'hooks/useCategorizationHelper'
import useGetActionsLeft from 'hooks/useGetActionsLeft'
import colors from 'utils/colors'
import CashOutCharts from './cash-out/CashOutCharts'
import CashOutExpectedDocuments from './cash-out/CashOutExpectedDocuments'
import CashOutTransactions from './cash-out/CashOutTransactions'
import CashOutTransactionsToValidate from './cash-out/CashOutTransactionsToValidate'
import featureConfig from 'config/features.js'

const type = 'cashout'

export default function CashOutScreen () {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const totalToValidate = useSelector(state => get(state, `aggregations.totalToValidate.${type}`, 0))

  const menuItems = useMemo(() => {
    const items = [{ key: '/cash-out/transactions', label: t('cashOutScreen.transactions') }]

    if (featureConfig.invoicesEnabled) {
      items.push({ key: '/cash-out/invoices', label: t('cashOutScreen.expectedDocuments') })
    }

    items.push({
      key: '/cash-out/validate',
      label: (
        <div>
          {t('cashOutScreen.validationPending')} <Badge count={totalToValidate} offset={[0, -5]} className='ml-1' style={{ backgroundColor: colors.error }} />
        </div>
      )
    })

    items.push({ key: '/cash-out/charts', label: t('cashOutScreen.analytics') })

    return items
  }, [t, totalToValidate])

  // useInitTotalToValidate({ type })
  useGetActionsLeft()
  const categorizationHelper = useCategorizationHelper({ type })

  return (
    <Layout>
      <Layout.Header className='rc-header'>
        <Typography.Title level={4} className='rc-header-title'><span className='rc-highlight-text-cashout'>{t('shared.cashoutPlural')}</span></Typography.Title>

        <Menu
          onSelect={({ key }) => { history.push(key) }}
          selectedKeys={[location.pathname]}
          mode='horizontal' className='rc-header-menu'
          items={menuItems}
        />
      </Layout.Header>

      <Layout.Content className='rc-content'>
        <Switch>
          <Route exact path='/cash-out/charts'>
            <CashOutCharts />
          </Route>
          <Route exact path='/cash-out/transactions'>
            <CategorizationHelperContext.Provider value={categorizationHelper}>
              <ActionsLeftInfo />
              <CashOutTransactions />
            </CategorizationHelperContext.Provider>
          </Route>
          <Route exact path='/cash-out/validate'>
            <CategorizationHelperContext.Provider value={categorizationHelper}>
              <ActionsLeftInfo />
              <CashOutTransactionsToValidate />
            </CategorizationHelperContext.Provider>
          </Route>
          <Route exact path='/cash-out/invoices'>
            <CashOutExpectedDocuments />
          </Route>
          <Redirect to='/cash-out/transactions' />
        </Switch>
      </Layout.Content>

      {categorizationHelper.modal}
    </Layout>
  )
}
