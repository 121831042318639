import React, { useCallback, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline'
import { List } from 'antd'
import { useTranslation } from 'react-i18next'

import ButtonLink from 'design/ButtonLink'
import TransactionPreview from 'components/transactions/TransactionPreview'
import CashflowSheetContext from 'contexts/CashflowSheetContext'
import { actions as vatActions } from 'reducers/vat-reducer'

CashflowSheetDrawerTransactionList.propTypes = {
  transactions: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
  loading: PropTypes.bool,
  refetch: PropTypes.func
}

export default function CashflowSheetDrawerTransactionList ({
  transactions,
  total,
  loading,
  refetch
}) {
  const { t } = useTranslation()
  const { refetchCashflowSheet } = useContext(CashflowSheetContext)
  const dispatch = useDispatch()

  const reloadVat = useCallback(() => {
    dispatch(vatActions.requestCurrentVatBalance())
  }, [dispatch])

  const dataLength = useMemo(() => transactions?.length || 0, [transactions])

  const loadMoreButton = useMemo(() => {
    if (dataLength >= total) return null

    return (
      <div className='w-full p-2 flex justify-center'>
        <ButtonLink
          loading={loading}
          onClick={() => refetch({ limit: dataLength + 10 })}
          label={(
            <div className='flex flex-row items-center'>
              <EllipsisHorizontalIcon className='w-4 h-4 text-primary mr-2' />
              {t('shared.loadMore', { dataLength, total })}
            </div>
          )}
        />
      </div>
    )
  }, [dataLength, total, loading, t, refetch])

  return (
    <div className='w-full flex flex-col items-start space-y-4'>
      {/* {loading && (<Spinner className='w-4 h-4 text-primary' />)} */}

      {/* {total > 0 && ( */}
      <List
        loadMore={loadMoreButton}
        loading={loading}
        dataSource={transactions}
        locale={{ emptyText: t('dashboard.cashflowSheetDrawerTransactionList.noTransaction') }}
        renderItem={transaction => (
          <List.Item key={transaction.id} className='pt-0'>
            <TransactionPreview
              transaction={transaction}
              showCategorySelect
              showDrawers
              showVatSelect
              onCategorize={() => {
                refetchCashflowSheet()
                reloadVat()
              }}
              onCategorizeVat={() => {
                refetchCashflowSheet()
                reloadVat()
              }}
            />
          </List.Item>
        )}
        split={false}
        className='w-full'
      />
      {/* )} */}
    </div>
  )
}
