import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Switch } from '@headlessui/react'
import classNames from 'classnames'
import Spinner from './Spinner'

Toggle.defaultProps = {
  size: 'medium'
}

Toggle.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.node,
  toggled: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'small']),
  loading: PropTypes.bool,
  readonly: PropTypes.bool,
  className: PropTypes.string
}

export default function Toggle ({ onChange, label, toggled, size, loading, readonly, className }) {
  const toggledDotClassName = useMemo(() => {
    if (toggled) {
      if (size === 'medium') return 'translate-x-5'
      return 'translate-x-4'
    }
    return 'translate-x-0'
  }, [toggled, size])

  if (loading) return <Spinner className={classNames('w-4 h-4 text-gray-300 self-center', className)} />

  return (
    <Switch.Group as='div' className='flex items-center'>
      <Switch
        checked={toggled}
        onChange={onChange}
        className={classNames(
          readonly ? 'opacity-40' : 'opacity-100',
          toggled ? 'bg-primary' : 'bg-gray-200',
          readonly ? 'cursor-not-allowed' : 'cursor-pointer',
          size === 'medium' ? 'h-6 w-11' : 'h-4 w-8',
          'relative inline-flex flex-shrink-0 border-2 border-transparent rounded-full  transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary'
        )}
      >
        <span className='sr-only'>Use setting</span>
        <span
          aria-hidden='true'
          className={classNames(
            toggledDotClassName,
            size === 'medium' ? 'h-5 w-5' : 'h-3 w-3',
            'pointer-events-none inline-block rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
          )}
        />
      </Switch>
      <Switch.Label as='span' className='ml-3'>
        <span className='text-base-color'>{label}</span>
      </Switch.Label>
    </Switch.Group>
  )
}
