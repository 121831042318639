import { Checkbox, Col, Input, Row, Select, Form } from 'antd'
import i18next from 'i18next'
import { get } from 'lodash'
import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import authActions from 'actions/AuthActions'
import Button from 'design/Button'
import countries from 'utils/countries'
import { setFieldsError } from 'utils/legacy-forms'

const { Option } = Select

const validateCgu = async (_rule, value) => {
  if (value !== true) {
    return Promise.reject(new Error(i18next.t('signupForm.error.acceptTOS')))
  } else {
    return Promise.resolve()
  }
}

const SignUpForm = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const loading = useSelector(state => get(state, 'auth.isSigningUp') || get(state, 'auth.isSigningIn'))

  const onError = useCallback((createError) => {
    const { setFields, getFieldValue } = form
    const errorCode = createError?.response?.data?.code
    const fields = form.getFieldsValue()

    if (errorCode === 'EMAIL_IS_ALREADY_USED') {
      form.setFields([{ name: 'email', value: fields.email, errors: [t('signupForm.error.emailAlreadyInUse')] }])
    } else if (errorCode === 'EMAIL_DOMAIN_NOT_ALLOWED') {
      form.setFields([{ name: 'email', value: fields.email, errors: [t('signupForm.error.emailDomainNotAllowed')] }])
    } else {
      const errorDetails = get(createError, 'response.data.details')
      setFieldsError(errorDetails, setFields, getFieldValue)
    }
  }, [form, t])

  const handleSubmit = (values) => {
    dispatch(authActions.requestSignUp({ params: { ...values, cgu: undefined }, onError }))
  }

  return (
    <Form form={form} onFinish={handleSubmit} layout='vertical' colon={false}>
      <Row gutter={20}>
        <Col span={12}>
          <Form.Item
            name='firstName'
            rules={[
              { required: true, message: t('signupForm.enterYourFirstName') }
            ]}
          >
            <Input placeholder={t('signupForm.firstName')} className='rc-no-shadow' />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name='lastName' rules={[
              {
                required: true,
                message: t('signupForm.enterYourLastName')
              }
            ]}
          >
            <Input placeholder={t('signupForm.lastName')} className='rc-no-shadow' />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name='email'

      ><Input placeholder={t('signupForm.professionalEmail')} className='rc-no-shadow' />
      </Form.Item>

      <Form.Item
        name='password' rules={[
          {
            required: true,
            message: t('signupForm.error.enterPassword')
          },
          {
            min: 8,
            message: t('signupForm.error.invalidPasswordFormat')
          }
        ]}
      >
        <Input.Password placeholder={t('signupForm.password')} className='rc-no-shadow' />

      </Form.Item>

      <Form.Item
        name='phone'
        rules={[
          {
            required: true,
            message: t('signupForm.error.enterYourPhoneNumber')
          },
          {
            pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-  s./0-9]*$/,
            message: t('signupForm.error.onlyNumbersInPhoneNumber')
          },
          {
            min: 10,
            message: t('signupForm.error.invalidPhoneNumber')
          },
          {
            max: 20,
            message: t('signupForm.error.invalidPhoneNumber')
          }
        ]}
      >
        <Input placeholder={t('signupForm.phone')} className='rc-no-shadow' />

      </Form.Item>

      <Row gutter={20}>
        <Col span={12}>
          <Form.Item
            name='companyName' rules={[
              {
                required: true,
                message: t('signupForm.enterCompanyName')
              }
            ]}
          ><Input placeholder={t('signupForm.company')} className='rc-no-shadow' />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name='country'
            rules={[
              {
                required: true,
                message: t('signupForm.error.countryNeeded')
              }
            ]}
            initialValue='FR'
          >
            <Select showSearch placeholder='France' className='rc-no-shadow'>
              {countries.map(({ name, flag, value }) => (
                <Option key={value} value={value}><span className='mr-2 align-middle'>{flag}</span> {name}</Option>
              ))}
            </Select>

          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col span={24}>
          <Form.Item
            name='cgu' valuePropName='checked'
            rules={[
              { validator: validateCgu }
            ]}
          >
            <Checkbox className='text-gray-400 text-justify'>
              <Trans
                i18nKey='signupForm.checkboxTOS'
                components={{
                  Link1: <a href={t('signupForm.link.TOS')} target='_blank' rel='noopener noreferrer' />,
                  Link2: <a href={t('signupForm.link.TOS2')} target='_blank' rel='noopener noreferrer' />
                }}
              />
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item className='mb-0 pb-0'>
        <Button
          type='submit'
          label={t('signupForm.startTrialPeriod')}
          loading={loading}
          className='w-full text-center flex flex-row justify-center'
        />

        <div className='w-full text-center text-gray-400 text-sm mt-2 mb-12 lg:mb-0'>
          <Trans
            i18nKey='signupForm.alreadyHaveAnAccount'
            components={{ Link: <Link to='/signin' /> }}
          />
        </div>
      </Form.Item>
    </Form>
  )
}

export default SignUpForm
