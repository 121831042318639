import React from 'react'
import PropTypes from 'prop-types'
import { Card } from 'antd'
import { get } from 'lodash'

import Currency from 'components/Currency'
import CategoryTag from 'components/categories/CategoryTag'
import CashIndicator from 'components/CashIndicator'

const CategoryPieChartTooltip = ({ params, dataKey }) => {
  if (!params) return null

  const category = get(params, 'payload')
  const isCashType = !get(category, 'id')
  const amount = get(params, `payload.${dataKey}`)

  if (isCashType) {
    return (
      <Card size='small' bodyStyle={{ display: 'flex', alignItems: 'center' }}>
        <CashIndicator
          type={get(category, 'type')}
          text={get(category, 'text')}
          style={{ marginRight: 10, fontWeight: 'bold' }}
        />
        <Currency amount={amount} />
      </Card>
    )
  }

  return (
    <Card size='small' bodyStyle={{ display: 'flex', alignItems: 'center' }}>
      <CategoryTag category={category} />
      <Currency amount={amount} />
    </Card>
  )
}

CategoryPieChartTooltip.propTypes = {
  params: PropTypes.object,
  dataKey: PropTypes.string
}

export default CategoryPieChartTooltip
