import React, { useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button, Row, Col, Select } from 'antd'
import { isNumber } from 'lodash'

import CategorySelect from 'components/categories/CategorySelect'
import VatSelect from 'components/vat/VatSelect'
import ValidationButton from 'components/ValidationButton'
import useUpdateMultipleTransactions from 'hooks/useUpdateMultipleTransactions'
import { useTranslation } from 'react-i18next'

const MultipleTransactionUpdate = ({ type, transactionIds, withValidation, onSuccess }) => {
  const { t } = useTranslation()
  const [categoryId, setCategoryId] = useState()
  const [vatRate, setVatRate] = useState()
  const [validated, setValidated] = useState(false)
  const [ignored, setIgnored] = useState()

  const onSuccessCallback = useCallback(() => {
    if (onSuccess) onSuccess()
    setCategoryId(undefined)
    setVatRate(undefined)
    setValidated(false)
    setIgnored(undefined)
  }, [onSuccess, setCategoryId, setVatRate])

  const [batchUpdateTransactionsMutation, { loading }] = useUpdateMultipleTransactions({ onSuccessCallback })

  const handleSubmit = useCallback(() => {
    const properties = {}
    if (categoryId) properties.categoryId = categoryId
    if (isNumber(vatRate)) properties.vatRate = vatRate
    if (validated) properties.validated = validated
    if (ignored) properties.ignored = (ignored === 'true')

    batchUpdateTransactionsMutation({ variables: { ids: transactionIds, properties } })
  }, [batchUpdateTransactionsMutation, transactionIds, categoryId, vatRate, validated, ignored])

  const submitDisabled = useMemo(() => {
    const hasNoValues = !categoryId && !isNumber(vatRate) && !validated && !ignored
    return loading || hasNoValues
  }, [loading, categoryId, vatRate, validated, ignored])

  if (transactionIds.length === 0) return null

  return (
    <div className='rc-multiple-update'>
      <Row gutter={[20, 10]}>
        <Col xs={12} lg={5}>
          <CategorySelect
            type={type}
            withEmpty
            emptyLabel={t('shared.uncategorize')}
            value={categoryId}
            onChange={(catId) => setCategoryId(catId)}
            loading={loading}
            disabled={loading}
            style={{ width: '100%' }}
          />
        </Col>

        <Col xs={12} lg={4}>
          <VatSelect
            value={vatRate}
            onChange={(vatRate) => setVatRate(vatRate)}
            loading={loading}
            disabled={loading}
            allowClear
          />
        </Col>

        <Col xs={12} lg={5}>
          <Select
            className='w-full'
            placeholder={t('component.multipleTransactionUpdate.ignoreUnignore')}
            allowClear
            onChange={(ignored) => setIgnored(ignored)}
            value={ignored}
          >
            <Select.Option value='true'>{t('component.multipleTransactionUpdate.ignoreTransactions')}</Select.Option>
            <Select.Option value='false'>{t('component.multipleTransactionUpdate.unignoreTransactions')}</Select.Option>
          </Select>
        </Col>

        {withValidation && (
          <Col xs={12} lg={5}>
            <ValidationButton
              withLabel
              onClick={() => setValidated(!validated)}
              validated={validated}
              loading={loading}
              disabled={loading}
              size='normal'
              block
            />
          </Col>
        )}

        <Col xs={12} lg={5}>
          <Button
            type='primary'
            onClick={handleSubmit}
            loading={loading}
            disabled={submitDisabled}
            block
          >
            {t('component.multipleTransactionUpdate.applyToSuggestion')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

MultipleTransactionUpdate.propTypes = {
  type: PropTypes.oneOf(['cashin', 'cashout']),
  transactionIds: PropTypes.arrayOf(PropTypes.string),
  withValidation: PropTypes.bool,
  onSuccess: PropTypes.func
}

export default MultipleTransactionUpdate
