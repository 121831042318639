import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { List } from 'antd'

import { LIST_TRANSACTION_EXPECTED_TRANSACTIONS } from 'graphql/expected-transactions'
import ExpectedTransactionReconciliationContext from 'contexts/ExpectedTransactionReconciliationContext'
import ReconciliableExpectedTransactionPreview from 'components/expected-transactions/ReconciliableExpectedTransactionPreview'

const ListTransactionExpectedTransactions = ({ transaction }) => {
  const reconciliationContext = useContext(ExpectedTransactionReconciliationContext)

  const { data, loading, refetch } = useQuery(LIST_TRANSACTION_EXPECTED_TRANSACTIONS, {
    variables: { transactionId: transaction.id }
  })

  useEffect(() => { reconciliationContext.refetchReconciliated = refetch }, [refetch, reconciliationContext])

  return (
    <div className='h-full overflow-scroll rc-transaction-expected-transactions-list-container pr-1 -mr-3 rc-show-scrollbar'>
      <List
        loading={loading}
        dataSource={data?.listTransactionExpectedTransactions?.expectedTransactions}
        renderItem={expectedTransaction => (
          <List.Item key={expectedTransaction.id} className='pt-0'>
            <ReconciliableExpectedTransactionPreview type={transaction.type} expectedTransaction={expectedTransaction} reconciliationTransactionId={transaction.id} />
          </List.Item>
        )}
        split={false}
      />
    </div>
  )
}

ListTransactionExpectedTransactions.propTypes = {
  transaction: PropTypes.object
}

export default ListTransactionExpectedTransactions
