import { gql } from '@apollo/client'

export const getAccountingDocumentFields = (docType) => gql`
  fragment ${docType}sFields on ${docType}  {
    id
    externalSource
    thirdPartyName
    amount
    amountExcludingVat
    issueDate
    dueDate
    type
    ignored
    status
  }
`

export const getAccountingDocumentListFields = (docType) => gql`
  fragment ${docType}sListFields on ${docType}  {
    id
    externalSource
    thirdPartyName
    amount
    amountExcludingVat
    dueDate
    type
    ignored
    status
  }
`

export const getAddExpectedTransactionToAccountingDocumentFields = (docType) => gql`
  fragment addExpectedTransactionTo${docType}Fields on ${docType}  {
    id
    status
    expectedTransactions {
      id
      description
      paymentDate
      expectedDate
      type
      expectedTransactionDetails {
        id
        type
        amount
        vatRate
        categoryId
      }
    }
  }
`
