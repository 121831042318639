import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'antd'

import { ArrowsPointingOutIcon, TrashIcon } from '@heroicons/react/24/solid'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'

import useDeleteCategory from 'hooks/categories/useDeleteCategory'
import useMoveCategory from 'hooks/categories/useMoveCategory'
import { useTranslation } from 'react-i18next'

CategoryLineActions.propTypes = {
  category: PropTypes.object
}

export default function CategoryLineActions ({ category }) {
  const { t } = useTranslation()
  const { openMoveCategoryPopup, MoveCategoryModal } = useMoveCategory({ category })
  const { openDeleteCategoryPopup, deleteCategoryModal } = useDeleteCategory({ category })

  const handleMenuClick = useCallback(({ key }) => {
    switch (key) {
      case 'move':
        openMoveCategoryPopup()
        break
      case 'delete':
        openDeleteCategoryPopup()
        break
      default:
        console.warn(`${key} button not handled`)
    }
  }, [openMoveCategoryPopup, openDeleteCategoryPopup])

  return (
    <div className='flex flex-row items-center justify-between'>
      {deleteCategoryModal}

      <Dropdown
        placement='bottomRight'
        menu={{
          onClick: handleMenuClick,
          items: [
            {
              key: 'move',
              label: (
                <div className='flex items-center justify-center'>
                  <ArrowsPointingOutIcon className='w-4 h-4 transform rotate-45 mr-2' />
                  {t('component.categoryLineActions.move')}
                </div>
              )
            }, {
              key: 'delete',
              label: (
                <div className='flex items-center justify-center'>
                  <TrashIcon className='w-4 h-4 mr-2' />
                  {t('component.categoryLineActions.delete')}
                </div>
              )
            }
          ]
        }}
      >
        <EllipsisVerticalIcon className='w-6 h-6 text-gray-400 hover:opacity-75 cursor-pointer' />
      </Dropdown>

      <MoveCategoryModal />
    </div>
  )
}
