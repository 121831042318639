import i18next from 'i18next'
import { capitalize, flatten, get, range } from 'lodash'
import moment from 'moment-timezone'
import { createSelector } from 'reselect'

import { getTotal, getTransactionsTotal } from './aggregations'
import { getCategories, getCategoryTree, mergeCategoriesInTree } from './categories'
import { getTotalMonthInfo } from './sheets'
import { useContext } from 'react'
import { ScenariosContext } from 'contexts/ScenariosContext.js'

const { t } = i18next

export const useScenario = (scenarioId) => {
  const { data } = useContext(ScenariosContext)
  return data?.listScenarios.find((scenario) => scenario.id === scenarioId)
}

export const getScenarioTotalTypeByMonth = createSelector(
  useScenario,
  (_, __, type) => type,
  (scenario, type) => {
    return range(1, 13).reduce((acc, month) => {
      const monthForecast = get(scenario, 'details.months', []).find((scenarioMonth) => {
        return scenarioMonth.month === month
      })

      return {
        ...acc,
        [month]: get(monthForecast, `total.${type}`, 0)
      }
    }, {})
  }
)

export const getScenarioName = createSelector(
  useScenario, (scenario) => {
    return get(scenario, 'name')
  }
)

export const getScenarioSheetSelector = createSelector(
  (state) => getCategories(state, 'cashin'),
  (state) => getCategories(state, 'cashout'),
  (state) => getCategoryTree(state, 'cashin'),
  (state) => getCategoryTree(state, 'cashout'),
  (state, _, year) => getTotal(state, 'cashin', year),
  (state, _, year) => getTotal(state, 'cashout', year),
  getTransactionsTotal,
  useScenario,
  (_, __, year) => year,
  (
    cashinCats,
    cashoutCats,
    cashinTree,
    cashoutTree,
    cashinTotal,
    cashoutTotal,
    transactionsTotal,
    scenario,
    year
  ) => {
    const cashinSheet = mergeForecastInCats(scenario, cashinTotal, cashinCats, year)
    const cashoutSheet = mergeForecastInCats(scenario, cashoutTotal, cashoutCats, year)

    return [
      getStartingBalance(scenario, transactionsTotal),
      { type: 'EMPTY' },
      getTotalCashIn(scenario, cashinTotal),
      ...mergeCategoriesInTree(cashinTree, cashinSheet),
      { type: 'EMPTY' },
      getTotalCashOut(scenario, cashoutTotal),
      ...mergeCategoriesInTree(cashoutTree, cashoutSheet),
      { type: 'EMPTY' },
      getNetCashFlow(scenario, transactionsTotal),
      { type: 'EMPTY' },
      getEndingBalance(scenario, transactionsTotal),
      { type: 'EMPTY' },
      getRunway(scenario, transactionsTotal)
    ]
  }
)

export const mergeForecastInCats = (scenario, total, categories, year) => {
  const now = moment()

  return categories.reduce((catsById, category) => {
    const months = range(1, 13)
    const forecast = months.reduce((acc, month) => {
      const monthForecast = get(scenario, 'details.months', []).find((scenarioMonth) => {
        return scenarioMonth.month === month
      })

      const forecasts = flatten(get(monthForecast, 'types', []).map((type) => type.forecasts))
      const catForecast = forecasts.find((forecast) => {
        return forecast.category === category.id
      })

      const monthTotal = get(total, 'totalPerMonth', []).find((monthTotal) => monthTotal.month === month)
      const catTotal = get(monthTotal, 'totalPerCategory', []).find((cat) => get(cat, 'categoryId') === category.id)

      const isPassed = year <= now.year() && month <= now.month() + 1

      return {
        ...acc,
        [month]: {
          planned: get(catForecast, 'amount', 0),
          plannedCumulated: get(catForecast, 'amountCumulated', 0),
          comment: get(catForecast, 'comment'),
          real: get(catTotal, 'total', isPassed ? 0 : undefined),
          realWithoutVAT: get(catTotal, 'totalWithoutVAT', isPassed ? 0 : undefined),
          percentage: get(catTotal, 'percentage', isPassed ? 0 : undefined),
          percentageWithoutVAT: get(catTotal, 'percentageWithoutVAT', isPassed ? 0 : undefined)
        }
      }
    }, {})

    const categoryWithForecast = { ...category, forecast }
    return { ...catsById, [category.id]: categoryWithForecast }
  }, {})
}

export const getStartingBalance = (scenario, transactionsTotal) => {
  const months = range(1, 13)
  const forecast = months.reduce((acc, month) => {
    const monthForecast = get(scenario, 'details.months', []).find((scenarioMonth) => {
      return scenarioMonth.month === month
    })

    const startingBalance = getTotalMonthInfo(month, 'startingBalance', transactionsTotal)

    return {
      ...acc,
      [month]: {
        planned: get(monthForecast, 'total.startingBalance'),
        real: startingBalance
      }
    }
  }, {})

  return {
    type: 'STARTING_BALANCE',
    name: t('selector.scenarios.startingBalance'),
    forecast
  }
}

export const getTotalCashIn = (scenario, total) => {
  const months = range(1, 13)
  const forecast = months.reduce((acc, month) => {
    const monthForecast = get(scenario, 'details.months', []).find((scenarioMonth) => {
      return scenarioMonth.month === month
    })

    const monthTotal = get(total, 'totalPerMonth', []).find((monthTotal) => monthTotal.month === month)

    return {
      ...acc,
      [month]: {
        planned: get(monthForecast, 'total.cashin', 0),
        real: get(monthTotal, 'totalForAllTransactions')
      }
    }
  }, {})

  return {
    type: 'CASH_IN',
    name: t('shared.cashinPlural'),
    forecast
  }
}

export const getTotalCashOut = (scenario, total) => {
  const months = range(1, 13)
  const forecast = months.reduce((acc, month) => {
    const monthForecast = get(scenario, 'details.months', []).find((scenarioMonth) => {
      return scenarioMonth.month === month
    })

    const monthTotal = get(total, 'totalPerMonth', []).find((monthTotal) => monthTotal.month === month)

    return {
      ...acc,
      [month]: {
        planned: get(monthForecast, 'total.cashout', 0),
        real: get(monthTotal, 'totalForAllTransactions')
      }
    }
  }, {})

  return {
    type: 'CASH_OUT',
    name: t('shared.cashoutPlural'),
    forecast
  }
}

export const getNetCashFlow = (scenario, transactionsTotal) => {
  const months = range(1, 13)
  const forecast = months.reduce((acc, month) => {
    const monthForecast = get(scenario, 'details.months', []).find((scenarioMonth) => {
      return scenarioMonth.month === month
    })
    const monthTotal = get(transactionsTotal, 'monthlyTotal', []).find((monthTotal) => {
      return monthTotal.month === month
    })

    return {
      ...acc,
      [month]: {
        planned: get(monthForecast, 'total.netCashflow'),
        real: get(monthTotal, 'netCashflow')
      }
    }
  }, {})

  return {
    type: 'CASH_FLOW',
    name: t('selector.scenarios.variation'),
    forecast
  }
}

export const getEndingBalance = (scenario, transactionsTotal) => {
  const months = range(1, 13)
  const forecast = months.reduce((acc, month) => {
    const monthForecast = get(scenario, 'details.months', []).find((scenarioMonth) => {
      return scenarioMonth.month === month
    })
    const monthTotal = get(transactionsTotal, 'monthlyTotal', []).find((monthTotal) => {
      return monthTotal.month === month
    })

    return {
      ...acc,
      [month]: {
        planned: get(monthForecast, 'total.endingBalance'),
        real: get(monthTotal, 'endOfMonthCashInBank')
      }
    }
  }, {})

  return {
    type: 'ENDING_BALANCE',
    name: t('selector.scenarios.endingBalance'),
    forecast
  }
}

export const getRunway = (scenario, transactionsTotal) => {
  const months = range(1, 13)
  const forecast = months.reduce((acc, month) => {
    const monthForecast = get(scenario, 'details.months', []).find((scenarioMonth) => {
      return scenarioMonth.month === month
    })
    const monthTotal = get(transactionsTotal, 'monthlyTotal', []).find((monthTotal) => {
      return monthTotal.month === month
    })

    return {
      ...acc,
      [month]: {
        planned: capitalize(get(monthForecast, 'total.runway')),
        real: capitalize(get(monthTotal, 'runway'))
      }
    }
  }, {})

  return {
    type: 'RUNWAY',
    name: t('selector.scenarios.runway'),
    forecast
  }
}
