import { compact, get } from 'lodash'
import { createSelector } from 'reselect'
import { getResources } from 'redux-resource'

import { mergeForecastInCats, useScenario } from './scenarios'
import { getTotal } from './aggregations'

export const getCategories = (state, type) => getResources(state.categories, type)
export const getCategoriesById = (state, type) => getResources(state.categories, type, { byId: true })
export const getCategoryTree = (state, type) => get(state, `categoryTrees.trees.${type}`, [])

export const getCategoriesInTreeSelector = createSelector(
  getCategoriesById,
  getCategoryTree,
  (categories, categoryTree) => {
    return mergeCategoriesInTree(categoryTree, categories)
  }
)

export const getCategoriesWithForecastSelector = createSelector(
  getCategories,
  getTotal,
  (state, _, __, scenarioId) => useScenario(state, scenarioId),
  (_, __, year) => [year],
  (categories, total, scenario, [year]) => {
    const catsById = mergeForecastInCats(scenario, total, categories, year)
    return Object.values(catsById)
  }
)

export const mergeCategoriesInTree = (categoryTree, categories) => {
  return compact(categoryTree.map((category) => {
    const fullCategory = get(categories, category.id)
    if (!fullCategory) return null

    const newCategory = { ...category, ...fullCategory }
    const children = [...get(category, 'children', [])]

    if (children.length > 0) {
      newCategory.children = mergeCategoriesInTree(children, categories)
    } else {
      newCategory.children = null
    }

    return newCategory
  }))
}
