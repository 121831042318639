
import AppActions from 'actions/AppActions'
import { ScenariosContext } from 'contexts/ScenariosContext.js'
import { first, get } from 'lodash'
import moment from 'moment'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const now = moment()

const fullYearDefaultFilter = {
  fromDate: moment(now).subtract(3, 'months').startOf('month').format('YYYY-MM-DD'),
  toDate: moment(now).add(9, 'months').endOf('month').format('YYYY-MM-DD')
}

export default function useDashboardQueryFilters () {
  const dispatch = useDispatch()
  const { data: scenariosData, loading: scenariosLoading } = useContext(ScenariosContext)

  const memorizedQueryFilters = useSelector(state => get(state, 'app.dashboard.queryFilters', fullYearDefaultFilter))
  const [queryFilters, setQueryFilters] = useState(memorizedQueryFilters)

  const mergeQueryFilters = useCallback((filters) => {
    setQueryFilters({ ...queryFilters, ...filters })
  }, [queryFilters, setQueryFilters])

  // REHYDRATE QUERY FILTERS
  useEffect(() => {
    if (memorizedQueryFilters !== queryFilters) {
      dispatch(AppActions.setDashboardQueryFilters(queryFilters))
    }
  }, [dispatch, queryFilters, memorizedQueryFilters])

  // SETS FIRST SCENARIO ID AS DEFAULT
  const defaultScenarioId = useMemo(() => {
    if (scenariosLoading) return null
    const firstScenarioId = get(first(scenariosData?.listScenarios), 'id')
    if (!queryFilters.scenarioId) {
      return firstScenarioId
    }

    if (queryFilters.scenarioId && scenariosData?.listScenarios.length > 0 &&
      !scenariosData?.listScenarios.some((scenario) => scenario.id === queryFilters.scenarioId)) {
      return firstScenarioId
    }

    return queryFilters.scenarioId
  }, [queryFilters.scenarioId, scenariosData?.listScenarios, scenariosLoading])

  useEffect(() => {
    if (defaultScenarioId !== queryFilters.scenarioId && defaultScenarioId) { mergeQueryFilters({ scenarioId: defaultScenarioId }) }
  }, [defaultScenarioId, queryFilters.scenarioId, mergeQueryFilters])

  return {
    queryFilters,
    setQueryFilters,
    mergeQueryFilters
  }
}
