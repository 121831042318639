import { Button } from 'antd'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { WarningOutlined } from '@ant-design/icons'

const OnboardingScreenWidthWarning = ({ currentStep }) => {
  const { t } = useTranslation()
  const [gotIt, setGotIt] = useState(false)

  return (
    <div
      className={
        cx('bg-white absolute top-0 left-0 right-0 bottom-0 z-50 flex flex-col items-center justify-center p-8',
          { hidden: gotIt || currentStep !== 0, 'md:hidden': !gotIt })
      }
    >
      <WarningOutlined className='text-yellow-500 text-6xl mr-4 mb-4' />

      <div className='flex flex-row items-center'>
        {t('onboarding.onboardingScreenWidthWarning.screenNotLargeEnough')}
        {t('onboarding.onboardingScreenWidthWarning.youCanConnectFromADesktopComputer')}
      </div>
      <Button onClick={() => setGotIt(true)} type='primary' className='self-end mt-4'>{t('onboarding.onboardingScreenWidthWarning.understood')}</Button>
    </div>
  )
}

OnboardingScreenWidthWarning.propTypes = {
  currentStep: PropTypes.number
}

export default OnboardingScreenWidthWarning
