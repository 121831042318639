import React from 'react'
import PropTypes from 'prop-types'
import { Tab } from '@headlessui/react'
import cx from 'classnames'

ScreenSwitch.propTypes = {
  screens: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      component: PropTypes.node.isRequired
    })
  ).isRequired,
  className: PropTypes.string
}

export default function ScreenSwitch ({ screens, className, ...rest }) {
  return (
    <Tab.Group as='div' {...rest}>
      <Tab.List className={cx('space-x-2', className)}>
        {screens.map(({ label }) => (
          <Tab key={label} className='outline-none drop-shadow-sm'>
            {({ selected }) => (
              <div className={cx('text-primary font-medium py-2 px-4', { 'bg-ultra-light-primary rounded-md': selected })}>{label}</div>
            )}
          </Tab>
        ))}
      </Tab.List>

      <Tab.Panels>
        {screens.map(({ component }, index) => (
          <Tab.Panel key={`tab-switch-${index}`}>
            {component}
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  )
}
