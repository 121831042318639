import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import { flatMap, uniq } from 'lodash'
import { useTranslation } from 'react-i18next'
import ExpectedTransactionDetailCategorySelect from 'components/expected-transaction-details/ExpectedTransactionDetailCategorySelect'

const AccountingDocumentTableCategorySelectShortcut = ({ accountingDocument, onExpand }) => {
  const { t } = useTranslation()

  const expectedTransactionDetails = flatMap(accountingDocument.expectedTransactions, (expectedTransaction) => expectedTransaction.expectedTransactionDetails)
  const singleCategory = uniq(expectedTransactionDetails.map(expectedTransactionDetail => expectedTransactionDetail.categoryId)).length === 1

  if (singleCategory) {
    return (
      <ExpectedTransactionDetailCategorySelect expectedTransactionDetails={expectedTransactionDetails} />
    )
  }

  return (
    <Button
      type='link'
      onClick={onExpand}
      block
      className='text-xs'
      size='small'
    >
      {t('component.accountingDocumentTableCategorySelectShortcut.multipleCategories')}
    </Button>
  )
}

AccountingDocumentTableCategorySelectShortcut.propTypes = {
  accountingDocument: PropTypes.shape({
    expectedTransactions: PropTypes.arrayOf(PropTypes.shape({
      expectedTransactionDetails: PropTypes.arrayOf(PropTypes.shape({
        categoryId: PropTypes.string
      }))
    }))
  }),
  onExpand: PropTypes.func
}

export default AccountingDocumentTableCategorySelectShortcut
