import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import QuoteStatus from 'enums/QuoteStatus.js'
import Toggle from 'design/Toggle.js'
import { Tooltip } from 'antd'
import { fromPairs, without } from 'lodash'
import i18next from 'i18next'
import { SET_EXPECTED_QUOTE_STATUSES } from 'graphql/scenarios.js'
import { useMutation } from '@apollo/client'
import Badge from 'design/Badge.js'
import { Link } from 'react-router-dom'
import { Trans } from 'react-i18next'
import classNames from 'classnames'

ScenarioExpectedQuoteStatuses.propTypes = {
  scenarioId: PropTypes.string,
  scenarioExpectedQuoteStatuses: PropTypes.array,
  isReadOnly: PropTypes.bool,
  isSettingsOpen: PropTypes.bool,
  onUpdated: PropTypes.func,
  includeQuotes: PropTypes.bool
}

export default function ScenarioExpectedQuoteStatuses ({ scenarioId, isReadOnly, scenarioExpectedQuoteStatuses, isSettingsOpen, onUpdated, includeQuotes }) {
  const { t } = i18next

  // init list with statuts
  const initState = fromPairs(
    Object.keys(QuoteStatus[process.env.REACT_APP_TENANT]).map(
      (status) => { return [status, scenarioExpectedQuoteStatuses?.includes(status)] }))

  const [listStatuses, setListStatuses] = useState(initState)
  const [scenarioKey, setScenarioKey] = useState(scenarioId)

  useEffect(() => {
    // Reset list if scenario changes
    if (scenarioKey !== scenarioId) {
      setScenarioKey(scenarioId)
      setListStatuses(initState)
    }
  }, [scenarioKey, scenarioId, initState])

  const [updateScenarioSettings] = useMutation(SET_EXPECTED_QUOTE_STATUSES,
    { onCompleted: () => onUpdated() })

  const handleOnChange = useCallback((key, toggled) => {
    listStatuses[key] = toggled
    setListStatuses({ ...listStatuses })

    // transform list of status to array and remove undefined
    const statuses = without(Object.entries(listStatuses).map(([key, value]) => { if (value) { return key } }), undefined)

    updateScenarioSettings({
      variables: {
        input: {
          scenarioId: scenarioId,
          expectedQuoteStatuses: statuses
        }
      }
    })
  }, [listStatuses, scenarioId, updateScenarioSettings])

  const getContextStatusLabel = (key) => {
    return process.env.REACT_APP_TENANT === 'QUIPU' && key === 'SENT' ? `${key}_QUIPU` : key
  }

  const tooltipTitle = (
    <div>
      {isReadOnly && (
        <Link to='/subscribe?r=cs'>
          <div className='text-center'>
            <span className='text-white'>
              <Trans
                i18nKey='component.scenarioSettings.configureScenariosIsOnlyAvailableSincePlan'
                components={{ Badge: <Badge type='info' label={t('utils.plans.planLocales.PRO')} className='text-xs ml-1' /> }}
              />
            </span>
            <span className='text-primary hover:underline ml-1'> {t('component.exportDrawerContainer.learnMore')} </span>
          </div>
        </Link>
      )}
    </div>
  )

  const toogleList = (
    <div className='w-[200px]'>
      <div className='mb-4'>
        {t('component.scenarioSettings.label')}
      </div>
      {
        Object.entries(listStatuses).map(([key, value]) =>

          <div className='flex flex-row' key={key}>
            <Toggle
              readonly={isReadOnly} size='small' onChange={
                isReadOnly || !includeQuotes ? () => {} : () => handleOnChange(key, !value)
              } toggled={includeQuotes ? value : false} className='inline'
            />
            <span className={
              classNames(isReadOnly ? 'text-gray-300' : 'text-base-color')
            }
            > {t('component.quoteStatus.label', { context: getContextStatusLabel(key) })}
            </span>
          </div>)
      }

    </div>
  )
  if (!isReadOnly) {
    return toogleList
  }

  return (
    <Tooltip
      open={!isSettingsOpen} // Hide Tooltip when onClose prop is provided
      placement='left'
      title={tooltipTitle}
      overlayClassName='w-[450px] max-w-none'
      children={toogleList}
    />

  )
}
