import React, { useCallback, useMemo, useState } from 'react'
import { Typography, Table } from 'antd'

import i18next from 'i18next'
import moment from 'moment'
import PropTypes from 'prop-types'
import RecurringPaymentStatusBadge from './RecurringPaymentStatusBadge.js'
import CategorySelect from 'components/categories/CategorySelect.js'
import RecurringPaymentAmountPreview from './RecurringPaymentAmountPreview.js'
import RecurringPaymentExpectedTransactionsList from './RecurringPaymentExpectedTransactionsList.js'
import RecurringPaymentNoSyncPanel from './RecurringPaymentNoSyncPanel.js'
import RecurringPaymentActions from './RecurringPaymentActions.js'
import MultipleRecurringPaymentUpdate from './MultipleRecurringPaymentUpdate.js'

const { t } = i18next

const { Text } = Typography

const getColumns = ({ toggleRowExpand, onCategorySelected }) => [
  {
    title: t('shared.status'),
    key: 'status',
    onCell: (recurringPayment) => ({ onClick: () => { toggleRowExpand(recurringPayment.id) } }),
    className: 'cursor-pointer min-w-max',
    render: recurringPayment => <RecurringPaymentStatusBadge recurringPayment={recurringPayment} />
  },
  {
    title: t('shared.description'),
    dataIndex: 'description',
    key: 'description',
    width: '320',

    onCell: (recurringPayment) => ({ onClick: () => { toggleRowExpand(recurringPayment.id) } }),
    className: 'cursor-pointer min-w-48',
    render: text => <Text>{text}</Text>
  },
  {
    title: t('component.recurringPaymentTable.column.client'),
    dataIndex: 'thirdPartyName',
    key: 'thirdPartyName',
    width: '160px',
    onCell: (recurringPayment) => ({ onClick: () => { toggleRowExpand(recurringPayment.id) } }),
    className: 'cursor-pointer',
    render: text => <Text>{text}</Text>
  },
  {
    title: t('component.recurringPaymentTable.column.frequency'),
    dataIndex: 'frequency',
    key: 'frequency',
    width: '160px',
    onCell: (recurringPayment) => ({ onClick: () => { toggleRowExpand(recurringPayment.id) } }),
    className: 'cursor-pointer',
    render: text => <Text>{t('component.recurringPaymentTable.frequency', { context: text })}</Text>
  },
  {
    title: t('component.recurringPaymentTable.column.nextInvoiceDate'),
    dataIndex: 'nextExpectedDate',
    key: 'nextExpectedDate',
    width: '180px',
    onCell: (recurringPayment) => ({ onClick: () => { toggleRowExpand(recurringPayment.id) } }),
    className: 'cursor-pointer',
    render: date => date && (<Text>{moment(date).format('DD/MM/YYYY')}</Text>)
  },
  {
    title: t('shared.category'),
    key: 'category',
    width: '192px',
    className: 'max-w-[192px]',
    render: recurringPayment =>
      <CategorySelect
        value={recurringPayment.categoryId}
        type='cashin'
        placeholder='Catégorie'
        className='w-full'
        onChange={(categoryId) => { onCategorySelected({ recurringPaymentId: recurringPayment.id, categoryId }) }}
      />
  },
  {
    title: t('component.recurringPaymentTable.column.periodicAmount'),
    key: 'unitAmount',
    width: '200px',
    onCell: (recurringPayment) => ({ onClick: () => { toggleRowExpand(recurringPayment.id) } }),
    className: 'cursor-pointer text-right',
    render: recurringPayment => <RecurringPaymentAmountPreview recurringPayment={recurringPayment} />
  },
  {
    title: '',
    dataIndex: '',
    key: 'more',
    width: '32px',
    className: 'pl-0',
    render: (recurringPayment) => (<RecurringPaymentActions recurringPayment={recurringPayment} />)
  }
]

RecurringPaymentTable.propTypes = {
  onCategorySelected: PropTypes.func,
  recurringPaymentsCount: PropTypes.number
}

export default function RecurringPaymentTable ({ recurringPaymentsCount, onCategorySelected, ...rest }) {
  const [selectedRecurringPaymentIds, setSelectedRecurringPaymentIds] = useState([])
  const [selectedRecurringPayments, setSelectedRecurringPayment] = useState([])
  const [expandedRowKeys, setExpandedRowKeys] = useState([])

  const handleRowSelect = useCallback((selectedRecurringPaymentIds, selectedRecurringPayments) => {
    setSelectedRecurringPaymentIds(selectedRecurringPaymentIds)
    setSelectedRecurringPayment(selectedRecurringPayments)
  }, [setSelectedRecurringPaymentIds, setSelectedRecurringPayment])

  const onMultipleUpdateSuccess = useCallback(() => {
    setSelectedRecurringPaymentIds([])
    setSelectedRecurringPayment([])
  }, [setSelectedRecurringPaymentIds, setSelectedRecurringPayment])

  const toggleRowExpand = useCallback((rowKeyToToggle) => {
    if (expandedRowKeys.includes(rowKeyToToggle)) {
      setExpandedRowKeys([])
    } else {
      setExpandedRowKeys([rowKeyToToggle])
    }
  }, [setExpandedRowKeys, expandedRowKeys])

  const getRowClassName = useCallback((recurringPayment) => {
    const classNames = ['rc-recurring-payment-table-row', 'rc-absolute-row']

    if (expandedRowKeys.includes(recurringPayment.id)) classNames.push('expanded')

    if (recurringPayment.ignored) classNames.push('rc-table-row-ignored')

    return classNames.join(' ')
  }, [expandedRowKeys])

  const columns = useMemo(() => getColumns({ toggleRowExpand, onCategorySelected }), [toggleRowExpand, onCategorySelected])

  const hasNoRecurringPayments = recurringPaymentsCount === 0
  if (hasNoRecurringPayments) {
    return (<RecurringPaymentNoSyncPanel />)
  }

  return (
    <>
      <MultipleRecurringPaymentUpdate
        recurringPaymentIds={selectedRecurringPaymentIds}
        recurringPayments={selectedRecurringPayments}
        onSuccess={onMultipleUpdateSuccess}
      />

      <Table
        rowSelection={{
          selectedRowKeys: selectedRecurringPaymentIds,
          onChange: handleRowSelect
        }}
        columns={columns}
        expandable={{
          expandedRowClassName: () => 'rc-recurring-payment-table-expanded-row',
          expandedRowRender: (recurringPayment) => <div className='flex flex-col items-center'><RecurringPaymentExpectedTransactionsList recurringPayment={recurringPayment} /></div>,
          expandedRowKeys,
          onExpand: (expanded, recurringPayment) => setExpandedRowKeys(expanded ? [recurringPayment.id] : [])
        }}
        rowKey={recurringPayment => recurringPayment.id}
        rowClassName={getRowClassName}
        className='rc-recurring-payment-table'
        {...rest}
      />
    </>
  )
}
