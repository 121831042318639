import moment from 'moment-timezone'

export function getUnixMiddleOfMonth (unixDate) {
  const monthStart = moment.unix(unixDate).startOf('month')
  const monthEnd = moment.unix(unixDate).endOf('month')
  const halfMonthDuration = ((monthEnd - monthStart) / 2)
  const midOfMonth = moment(monthStart).add(halfMonthDuration)

  return midOfMonth.unix()
}

// PRESENT STAY PRESENT
const today = moment().startOf('day')
export const startOfThisMonth = moment(today).startOf('month')
const unixEndOfThisMonth = moment(startOfThisMonth).endOf('month').unix()

export function getUnixEndOfMonth (unixDate, { keepPresentForCurrentMonth, keepPresentForPastMonths } = {}) {
  const unixEndOfMonth = moment.unix(unixDate).endOf('month').unix()

  if (keepPresentForCurrentMonth) {
    const isSameMonth = unixEndOfMonth === unixEndOfThisMonth
    if (isSameMonth) {
      const unixToday = moment(today).endOf('day').unix()
      return unixToday
    }
  }
  if (keepPresentForPastMonths) {
    const isPast = unixEndOfMonth < unixEndOfThisMonth
    if (isPast) {
      const unixToday = moment(today).endOf('day').unix()
      return unixToday
    }
  }

  return unixEndOfMonth
}

export function getAllMonthsBetweenDates (fromDate, toDate) {
  let dateCursor = moment(fromDate).startOf('month')
  const dateEnd = moment(toDate).startOf('month')

  const months = [moment(dateCursor)]

  while (dateEnd.isAfter(dateCursor)) {
    const newMonth = moment(dateCursor).add(1, 'month')
    months.push(newMonth)
    dateCursor = newMonth
  }

  return months
}

export const startEdgeDate = moment('2018-01-01', 'YYYY-MM-DD')
export const endEdgeDate = moment().add(1, 'year').endOf('year')

export function convertStringDateFrToIso (strDate) {
  if (!strDate) return undefined

  return moment.utc(strDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
}
