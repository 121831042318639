import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import CashflowSheetDrawerTransactionList from './CashflowSheetDrawerTransactionList'
import { LIST_ALL_TRANSACTION } from 'graphql/transactions'
import Currency from 'components/Currency'

CashflowSheetDrawerTransactionsPanel.propTypes = {
  rowType: PropTypes.string,
  sheetDate: PropTypes.object,
  categoryIds: PropTypes.arrayOf(PropTypes.string),
  drawerParams: PropTypes.shape({
    visible: PropTypes.bool,
    rowAbsolutePath: PropTypes.string
  })
}

export default function CashflowSheetDrawerTransactionsPanel ({
  rowType,
  sheetDate,
  categoryIds,
  drawerParams
}) {
  const { t } = useTranslation()
  const { year, month } = useMemo(() => {
    return {
      year: sheetDate.momentDate.year(),
      month: sheetDate.momentDate.month() + 1
    }
  }, [sheetDate])

  const type = useMemo(() => {
    return ['CASH_IN', 'CASHIN_CATEGORY'].includes(rowType) ? 'cashin' : 'cashout'
  }, [rowType])

  const { loading, data, refetch } = useQuery(LIST_ALL_TRANSACTION, {
    variables: {
      type: type,
      year,
      month,
      categoryIds,
      offset: 0,
      limit: 10,
      ignored: false
    }
  })

  const transactions = useMemo(() => data?.allTransactions?.transactions || [], [data])
  const total = useMemo(() => data?.allTransactions?.total || 0, [data])
  const totalAmount = useMemo(() => data?.allTransactions?.totalAmount, [data])

  useEffect(() => {
    if (drawerParams.visible) refetch()
  }, [refetch, drawerParams.visible])

  return (
    <Disclosure defaultOpen>
      <Disclosure.Button>
        {({ open }) => (
          <div className='w-full flex flex-row items-center space-x-2'>
            {(open && (
              <ChevronDownIcon className='w-6 h-6 text-gray-400' />
            )) || (
              <ChevronRightIcon className='w-6 h-6 text-gray-400' />
            )}
            <span>{t('dashboard.cashflowSheetDrawerTransactionsPanel.transactionsTotal', { total })}</span>
            <span className='text-gray-400'>/</span>
            <Currency amount={totalAmount} className='font-bold' />
          </div>
        )}

      </Disclosure.Button>

      <Disclosure.Panel className='flex-grow basis-0 overflow-scroll rc-show-scrollbar pt-2 pl-8 pr-4 pb-4'>
        <div className='pr-1 -mr-3'>
          <CashflowSheetDrawerTransactionList
            transactions={transactions}
            total={total}
            loading={loading}
            refetch={refetch}
          />
        </div>
      </Disclosure.Panel>
    </Disclosure>
  )
}
