import SubscriptionType from 'enums/SubscriptionType.js'
import useQuipuAppRootUrl from 'hooks/quipu/useQuipuAppRootUrl.js'
import { useCallback, useMemo } from 'react'

export default () => {
  const quipuAppRootUrl = useQuipuAppRootUrl()
  const quipuSubscriptionUrl = useMemo(() => {
    return quipuAppRootUrl ? `${quipuAppRootUrl}/new_subscription` : null
  }, [quipuAppRootUrl])

  const redirectToSubscriptionPage = useCallback(({ subscriptionType }) => {
    if (subscriptionType === SubscriptionType.QUIPU) {
      window.location.replace(quipuSubscriptionUrl)
    }
  }, [quipuSubscriptionUrl])

  return { quipuSubscriptionUrl, redirectToSubscriptionPage }
}
