import { DatePicker, Drawer, Form, Input } from 'antd'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { trim, pick, get } from 'lodash'
import numeral from 'numeral'

import Button from 'design/Button'

import { XMarkIcon } from '@heroicons/react/20/solid'
import Title from 'design/Title'
import useHandleFormValidationErrors from 'hooks/useHandleFormValidationErrors'
import moment from 'moment'
import { Trans, useTranslation } from 'react-i18next'
import AccountingDocumentEditExpectedTransaction from '../accounting-documents/AccountingDocumentEditExpectedTransaction'

QuoteEditDrawer.propTypes = {
  mode: PropTypes.oneOf(['create', 'update']),
  quote: PropTypes.object,
  type: PropTypes.string,
  loading: PropTypes.bool,
  visible: PropTypes.bool,
  onFinish: PropTypes.func,
  onClose: PropTypes.func,
  defaultDueDate: PropTypes.object,
  defaultCategoryId: PropTypes.string,
  graphQLErrors: PropTypes.object
}

function QuoteEditDrawer ({ mode, quote, visible, type, onFinish, onClose, defaultDueDate, defaultCategoryId, loading, graphQLErrors }) {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [quoteParams, setQuoteParams] = useState(undefined)
  const handleValidationErrors = useHandleFormValidationErrors({ form })

  const handleDueDateChange = useCallback((newDueDate) => {
    const expectedDatePath = ['expectedTransactions', 0, 'expectedDate']

    const todayStart = moment().startOf('day')
    const newExpectedDate = todayStart.isAfter(newDueDate) ? todayStart : newDueDate
    form.setFieldValue(expectedDatePath, newExpectedDate)
  }, [form])

  const handleIssueDateChange = useCallback((newIssueDate) => {
    const dueDate = form.getFieldValue('dueDate')

    if (!dueDate) {
      const newDueDate = moment(newIssueDate).add(1, 'month')
      form.setFieldValue('dueDate', newDueDate)
      handleDueDateChange(newDueDate)
    }
  }, [form, handleDueDateChange])

  useEffect(() => {
    if (quote) {
      const newQuoteParams = {
        ...pick(quote, ['thirdPartyName', 'quoteNumber', 'issueDate', 'dueDate']),
        expectedTransactions: quote.expectedTransactions.map((expTr) => ({
          ...pick(expTr, ['expectedDate', 'description']),
          expectedTransactionDetails: expTr.expectedTransactionDetails.map((expTrDtl) => pick(expTrDtl, ['amount', 'withVat', 'vatRate', 'categoryId']))
        }))
      }
      setQuoteParams(newQuoteParams)
    } else {
      setQuoteParams({ expectedTransactions: [{ expectedTransactionDetails: [{}] }] })
    }
  }, [quote])

  useEffect(() => {
    if (!visible) {
      form.resetFields()
    }
  }, [form, visible])

  useEffect(() => {
    form.resetFields()
  }, [form, quoteParams])

  useEffect(() => {
    if (!graphQLErrors) {
      return
    }

    const errorCode = get(graphQLErrors, '0.extensions.code')
    handleValidationErrors({ graphQLErrors })
    if (errorCode === 'QUOTE_ALREADY_EXISTS') {
      const fields = form.getFieldsValue()
      const allFieldsWithErrors = Object.keys(fields).map((fieldName) => {
        const fieldValue = fields[fieldName]
        if (fieldName === 'quoteNumber') {
          const message = t('component.quoteEditDrawer.quoteAlreadyExistsMsg')
          return { name: fieldName, value: fieldValue, errors: [message] }
        }

        const errors = form.getFieldError(fieldName)
        return { name: fieldName, value: fieldValue, errors }
      })
      form.setFields(allFieldsWithErrors)
    }
  }, [form, graphQLErrors, handleValidationErrors, t])

  const onFinishCallback = useCallback((formValues) => {
    onFinish({
      quoteParams: {
        thirdPartyName: trim(formValues.thirdPartyName),
        quoteNumber: trim(formValues.quoteNumber),
        issueDate: moment(formValues.issueDate).format('YYYY-MM-DD'),
        dueDate: moment(formValues.dueDate).format('YYYY-MM-DD'),
        expectedTransactions: formValues.expectedTransactions.map((expTxnParams) => {
          return {
            ...expTxnParams,
            description: trim(expTxnParams.description),
            expectedDate: moment(expTxnParams.expectedDate).format('YYYY-MM-DD'),
            expectedTransactionDetails: expTxnParams.expectedTransactionDetails.map((expTxnDetailParams) => {
              return {
                ...expTxnDetailParams,
                categoryId: expTxnDetailParams.categoryId || null,
                amount: computeAmount(expTxnDetailParams),
                withVat: undefined
              }
            })
          }
        })
      }
    })
  }, [onFinish])

  return (
    <>
      <Drawer
        title={(
          <Title
            label={(
              <div>
                <Trans
                  i18nKey={mode === 'create' ? 'component.quoteEditDrawer.title.createExpectedTransaction' : 'component.quoteEditDrawer.title.updateExpectedTransaction'}
                  components={{ Span: <span className={cx({ 'rc-highlight-text-cashin': (type === 'cashin'), 'rc-highlight-text-cashout': (type === 'cashout') })} /> }}
                  context={type}
                />

              </div>
            )}
          />
        )}
        closeIcon={<XMarkIcon className='w-5 h-5' />}
        open={visible}
        destroyOnClose
        onClose={onClose}
        width={780}
      >
        <Form form={form} layout='vertical' onFinish={onFinishCallback} className='p-2' requiredMark='optional'>
          <div className='w-full flex flex-row space-x-8'>
            <Form.Item name='quoteNumber' initialValue={quoteParams?.quoteNumber} label={t('shared.description')} rules={[{ required: true, message: t('component.quoteEditDrawer.descriptionRequired') }, { max: 256 }]} className='w-1/2'>
              <Input size='large' placeholder={t('component.quoteEditDrawer.descriptionPlaceholder')} onKeyDown={e => e.stopPropagation()} />
            </Form.Item>

            <Form.Item name='thirdPartyName' initialValue={quoteParams?.thirdPartyName} label={t('component.quoteEditDrawer.client')} rules={[{ required: true, message: t('component.quoteEditDrawer.nameRequired') }, { max: 256 }]} className='w-1/2'>
              <Input size='large' placeholder={t('component.quoteEditDrawer.nameOfClient')} onKeyDown={e => e.stopPropagation()} />
            </Form.Item>
          </div>

          <div className='w-full flex flex-row space-x-8'>
            <Form.Item name='issueDate' label={t('component.quoteEditDrawer.issueDate')} initialValue={quoteParams?.issueDate} getValueProps={(i) => ({ value: i ? moment(i) : undefined })} rules={[{ required: true, message: t('component.quoteEditDrawer.issueDateRequired') }]} className='w-1/2'>
              <DatePicker
                format='DD/MM/YYYY'
                allowClear={false}
                size='large'
                className='w-full'
                onChange={handleIssueDateChange}
              />
            </Form.Item>

            <Form.Item name='dueDate' label={t('component.quoteEditDrawer.dueDate')} initialValue={quoteParams?.dueDate || defaultDueDate} getValueProps={(i) => ({ value: i ? moment(i) : undefined })} rules={[{ required: true, message: t('component.quoteEditDrawer.dueDateRequired') }]} className='w-1/2'>
              <DatePicker
                format='DD/MM/YYYY'
                allowClear={false}
                size='large'
                className='w-full'
                onChange={handleDueDateChange}
              />
            </Form.Item>
          </div>

          <AccountingDocumentEditExpectedTransaction expectedTransactions={quoteParams?.expectedTransactions} defaultCategoryId={defaultCategoryId} defaultDueDate={defaultDueDate} type={type} form={form} />

          <Form.Item>
            <Button primary disabled={loading} loading={loading} type='submit' label={mode === 'create' ? t('component.quoteEditDrawer.createExpected') : t('component.quoteEditDrawer.updateExpected')} className='mt-4' />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  )
}

function computeAmount ({ amount, withVat, vatRate }) {
  if (withVat === 'true') return amount

  const onePlusVatRate = numeral(1).add(vatRate).value()
  return numeral(amount).multiply(onePlusVatRate).value()
}

export default QuoteEditDrawer
