import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Tooltip, Dropdown } from 'antd'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'

import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'

import { IGNORE_TRANSACTION, UNIGNORE_TRANSACTION, VALIDATE_TRANSACTION } from 'graphql/transactions'

TransactionPartActions.propTypes = {
  transaction: PropTypes.object,
  withValidation: PropTypes.bool,
  onValidate: PropTypes.func
}

export default function TransactionPartActions ({ transaction, withValidation, onValidate }) {
  const { t } = useTranslation()

  const [ignoreTransactionMutation, { loading: ignoreTransactionLoading }] = useMutation(IGNORE_TRANSACTION)
  const [unignoreTransactionMutation, { loading: unignoreTransactionLoading }] = useMutation(UNIGNORE_TRANSACTION)
  const [validateTransactionMutation, { loading: validateTransactionLoading }] = useMutation(VALIDATE_TRANSACTION)

  const loading = useMemo(() => {
    return unignoreTransactionLoading || ignoreTransactionLoading || validateTransactionLoading
  }, [ignoreTransactionLoading, unignoreTransactionLoading, validateTransactionLoading])

  const swapIgnoreTransaction = useCallback(() => {
    if (transaction.ignored) {
      unignoreTransactionMutation({ variables: { id: transaction.id } })
    } else {
      ignoreTransactionMutation({ variables: { id: transaction.id, ignoredReason: null } })
    }
  }, [ignoreTransactionMutation, unignoreTransactionMutation, transaction])

  const validateTransaction = useCallback(() => {
    validateTransactionMutation({ variables: { id: transaction.id } })
  }, [validateTransactionMutation, transaction])

  const handleMenuClick = useCallback(({ key }) => {
    switch (key) {
      case 'validate':
        validateTransaction()
        break
      case 'ignore':
        swapIgnoreTransaction()
        break
      default:
        console.warn(`${key} button not handled`)
    }
  }, [swapIgnoreTransaction, validateTransaction])

  return (
    <Dropdown
      disabled={loading}
      placement='bottomRight'
      menu={{
        onClick: handleMenuClick,
        items: [
          {
            key: 'ignore',
            label: (
              <Tooltip
                title={t('component.transactionPartActions.ignoreTooltip')}
                placement='topRight'
                className='flex items-center justify-center'
              >
                {(transaction.ignored && <EyeIcon className='w-4 h-4 mr-2 inline' />) || <EyeSlashIcon className='w-4 h-4 mr-2 inline' />}
                {transaction.ignored ? t('shared.unIgnore') : t('shared.ignore')}
              </Tooltip>
            )
          }
        ]
      }}
    >
      <EllipsisVerticalIcon className='w-6 h-6 text-gray-400 hover:opacity-75 cursor-pointer' />
    </Dropdown>
  )
}
