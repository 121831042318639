import React, { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import Badge from 'design/Badge'
import TextEdit from 'design/TextEdit'
import { SET_EXPECTED_TRANSACTION_DESCRIPTION } from 'graphql/expected-transactions'
import { useTranslation } from 'react-i18next'

const ExpectedTransactionDescription = ({ expectedTransaction }) => {
  const { t } = useTranslation()
  const [setDescription, { loading }] = useMutation(SET_EXPECTED_TRANSACTION_DESCRIPTION)

  const handleTextChanged = useCallback((description) => {
    setDescription({
      variables: {
        id: expectedTransaction.id,
        description
      }
    })
  }, [expectedTransaction.id, setDescription])

  return (
    <div className='flex flex-row items-center space-x-2'>
      {expectedTransaction.transactionId && (<Badge type='primary' label={t('component.expectedTransactionDescription.reconciled')} />)}

      <TextEdit text={expectedTransaction.description} onTextChanged={handleTextChanged} loading={loading} labelEditButton={t('component.expectedTransactionDescription.renameExpectedTransaction')} className='truncate' />
    </div>
  )
}

ExpectedTransactionDescription.propTypes = {
  expectedTransaction: PropTypes.object
}

export default ExpectedTransactionDescription
