import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Tree } from 'antd'
import { useSelector } from 'react-redux'
import { getCategoriesInTreeSelector } from 'selectors/categories'
import { get } from 'lodash'
import CategoryTag from 'components/categories/CategoryTag'

const generateCategorySelectTreeDataFromTreeData = (categoryTree) => {
  return categoryTree.map((category) => {
    const newData = {
      title: <CategoryTag category={category} />,
      value: get(category, 'id'),
      key: get(category, 'id')
    }

    if (get(category, 'children.length', 0) > 0) {
      newData.children = generateCategorySelectTreeDataFromTreeData(category.children)
    }

    return newData
  })
}

const CategoryTreeCheck = ({ type, ...rest }) => {
  const categoryTree = useSelector(state => getCategoriesInTreeSelector(state, type))
  const selectTreeData = useMemo(() => {
    return generateCategorySelectTreeDataFromTreeData(categoryTree)
  }, [categoryTree])

  // if (get(categoryTree, 'length', 0) === 0) return null
  // defaultExpandAll

  return (
    <Tree
      checkable
      checkStrictly
      treeData={selectTreeData}
      {...rest}
    />
  )
}

CategoryTreeCheck.propTypes = {
  type: PropTypes.string
}

export default CategoryTreeCheck
