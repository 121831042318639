import { LeftOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Col, Input, Row, Typography, Form } from 'antd'
import { get } from 'lodash'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import authActions from 'actions/AuthActions'

const { Title, Paragraph } = Typography

const ForgotPasswordScreen = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isSuccess, setIsSuccess] = useState(false)
  const isSendingInstructions = useSelector(state => get(state, 'auth.isSendingInstructions'))

  const onSuccess = useCallback(() => {
    setIsSuccess(true)
  }, [setIsSuccess])

  const onFinish = values => {
    dispatch(authActions.sendResetInstructions({ email: values.email }, onSuccess))
  }

  return (
    <div className='rc-full-page-box-container'>
      <div className='rc-signin-logo-container mb-12'>
        <img src='/logo_sellsy_tresorerie.svg' alt='Sellsy Trésorerie logo' className='rc-signin-logo' />
      </div>

      <div className='max-w-md p-4'>
        <Row>
          <Col span={2}>
            <Link to='/signin'><Button shape='circle' icon={<LeftOutlined />} /></Link>
          </Col>
          <Col span={20} align='center'>
            <Title level={4}>{t('forgotPassword.title')}</Title>
          </Col>
        </Row>

        <br />

        {(isSuccess && (
          <Paragraph>
            {t('forgotPassword.instructionsSent')}
          </Paragraph>
        )) || (
          <>
            <Paragraph>
              {t('forgotPassword.enterYourAccountEmail')}
            </Paragraph>
            <Paragraph>
              {t('forgotPassword.securityNotice')}
            </Paragraph>

            <Form layout='vertical' onFinish={onFinish} className='p-0' requiredMark={false}>
              <Form.Item
                label={t('forgotPassword.yourEmail')} name='email'
                rules={[
                  { type: 'email', message: t('forgotPassword.emailNotValid') },
                  { required: true, message: t('forgotPassword.enterYourEmail') }
                ]}
              >
                <Input
                  size='large'
                  prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder='email@example.net'
                />

              </Form.Item>
              <Form.Item>
                <Button size='large' loading={isSendingInstructions} block type='primary' htmlType='submit'>
                  {t('forgotPassword.receiveInstructions')}
                </Button>
              </Form.Item>
            </Form>
          </>
        )}
      </div>
    </div>
  )
}

export default ForgotPasswordScreen
