import { useQuery } from '@apollo/client'
import cx from 'classnames'
import featureConfig from 'config/features.js'
import { AMOUNTS_INVOICES } from 'graphql/invoices'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { backgroundTextColors } from 'utils/colors.js'
import { c } from 'utils/currencies'

const InvoiceAmounts = function ({ className, type, search, stateFilter }) {
  const { t } = useTranslation()
  const { loading, data } = useQuery(AMOUNTS_INVOICES, {
    variables: {
      type,
      search,
      stateFilter
    }
  })

  const totalAmountsRemaining = useMemo(() => {
    return c(data?.amountsInvoices?.totalAmountsRemaining)
  }, [data?.amountsInvoices?.totalAmountsRemaining])

  const totalAmountsRemainingExcludingVAT = useMemo(() => {
    return c(data?.amountsInvoices?.totalAmountsRemainingExcludingVAT)
  }, [data?.amountsInvoices?.totalAmountsRemainingExcludingVAT])

  const colorsStyle = type === 'cashin' ? backgroundTextColors.cashin : backgroundTextColors.cashout

  if (stateFilter === 'PAID' || loading ||
      (data?.amountsInvoices?.totalAmountsRemaining === 0 && data?.amountsInvoices?.totalAmountsRemainingExcludingVAT === 0)) {
    return (<div className={`${className}`} />)
  }

  return (
    <div className={`${className} flex justify-end items-center space-x-1`}>
      <span className='m-1'>{t('component.invoiceAmounts.totalRemaining')}</span>
      <div className={cx('flex flex-row items-center px-2.5 py-1.5 rounded-md', colorsStyle)}>
        <span>{totalAmountsRemaining}</span>
        {featureConfig.vatDetailsEnabled && (
          <span className='text-xs ml-2'>{t('shared.inclTax')}</span>
        )}
      </div>
      {featureConfig.vatDetailsEnabled && (
        <div className={cx('flex flex-row items-center px-2.5 py-1.5 rounded-md', colorsStyle)}>
          <span>{totalAmountsRemainingExcludingVAT}</span>
          <span className='text-xs ml-2'>{t('shared.exclTax')}</span>
        </div>
      )}

    </div>
  )
}

InvoiceAmounts.propTypes = {
  type: PropTypes.oneOf(['cashin', 'cashout']),
  search: PropTypes.string,
  className: PropTypes.string,
  stateFilter: PropTypes.oneOf(['ALL', 'ONGOING', 'LATE', 'PAID', 'DRAFT'])
}

export default InvoiceAmounts
