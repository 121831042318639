
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Rectangle } from 'recharts'
import { get } from 'lodash'

const MainChartCursor = ({ dataLength, x, y, width, height, left, points, ...rest }) => {
  const computedWidth = useMemo(() => {
    if (!dataLength) return 0
    return width / dataLength
  }, [width, dataLength])

  const computedX = useMemo(() => get(points, '0.x', 0) - (computedWidth / 2), [computedWidth, points])
  const computedY = useMemo(() => get(points, '0.y', 0), [points])

  return (
    <Rectangle
      fill='#f1f1f1'
      x={computedX}
      y={computedY}
      width={computedWidth}
      height={height}
    />
  )
}

MainChartCursor.propTypes = {
  dataLength: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  left: PropTypes.number,
  points: PropTypes.arrayOf(PropTypes.object)
}

export default MainChartCursor
