import { useQuery } from '@apollo/client'
import { ME } from 'graphql/users'
import { get } from 'lodash'
import { useSelector } from 'react-redux'

export default function useAuthenticatedContext () {
  const isAuthenticated = useSelector(state => get(state, 'app.isAuthenticated'))

  const { data, loading } = useQuery(ME, {
    fetchPolicy: 'cache-first',
    skip: !isAuthenticated
  })

  if (!isAuthenticated || loading) return {}

  const company = get(data, 'me.company', {})
  const user = get(data, 'me', {})
  const subscription = get(data, 'me.company.subscription', {})

  return { company, user, subscription }
}
