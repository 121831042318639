import { useMutation } from '@apollo/client'
import { Modal } from 'antd'
import SplitTransactionForm from 'components/transactions/SplitTransactionForm'
import { SPLIT_TRANSACTION } from 'graphql/transactions'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

export default ({ transaction, onComplete }) => {
  // LES DEUX DOIVENT ETRE FALSE
  // const [isModalRendered, setIsModalRendered] = useState(transaction.description === 'Today')
  const [isModalVisible, setIsModalVisible] = useState(false)

  const openSplitTransactionPopup = useCallback(() => {
    // if (!isModalRendered) setIsModalRendered(true)
    setIsModalVisible(true)
  }, [setIsModalVisible])

  const closeSplitTransactionPopup = useCallback(() => {
    setIsModalVisible(false)
  }, [setIsModalVisible])

  const [splitTransaction, { loading: splitting }] = useMutation(SPLIT_TRANSACTION, {
    onCompleted: () => {
      if (onComplete) onComplete()
      closeSplitTransactionPopup()
    },
    refetchQueries: ['listAllTransactions']
  })

  const onSubmit = useCallback((parts) => {
    splitTransaction({ variables: { input: { id: transaction.id, parts } } })
  }, [splitTransaction, transaction.id])

  const SplitTransactionModal = useSplitTransactionModal({
    closeSplitTransactionPopup,
    isModalVisible,
    transaction,
    onSubmit,
    splitting
  })

  return {
    openSplitTransactionPopup,
    SplitTransactionModal
  }
}

const useSplitTransactionModal = ({ closeSplitTransactionPopup, isModalVisible, transaction, onSubmit, splitting }) => {
  const { t } = useTranslation()

  return useCallback(() => {
    // if (!isModalRendered) return null
    return (
      <Modal
        width={840}
        title={t('hook.useSplitTransaction.splitTransaction')}
        open={isModalVisible}
        onClose={closeSplitTransactionPopup}
        onCancel={closeSplitTransactionPopup}
        footer={null}
      >
        <SplitTransactionForm transaction={transaction} onSubmit={onSubmit} loading={splitting} />
      </Modal>
    )
  }, [isModalVisible, closeSplitTransactionPopup, transaction, onSubmit, splitting, t])
}
