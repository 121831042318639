import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

ShortcutContainer.propTypes = {
  bordered: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  groupName: PropTypes.string
}

ShortcutContainer.defaultProps = {
  bordered: true,
  groupName: ''
}

// You need to add "group/shortcut" class to the parent div
// that you want to be the trigger of the hovering
export default function ShortcutContainer ({ bordered, className, children, groupName, ...rest }) {
  const hasContent = useMemo(() => {
    return React.Children.toArray(children).some(child => child)
  }, [children])

  if (!hasContent) return null

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={classNames(
        'min-h-full flex-row items-center hidden group-hover/shortcut:flex group-hover/shortcut:bg-white rounded-md py-0.5 px-1 space-x-1 group-hover/shortcut:bg-opacity-40 group-hover/shortcut:backdrop-blur-sm',
        { 'group-hover/shortcut:border': bordered },
        className
      )}
      {...rest}
    >
      {children}
    </div>
  )
}
