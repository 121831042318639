import React from 'react'
import PropTypes from 'prop-types'
import { Layout, Button } from 'antd'

import PageLoader from 'design/PageLoader'
import IntegrationCard from './IntegrationCard'
import { useHistory } from 'react-router'
import { openLiveChat } from 'utils/contact'
import { useTranslation, Trans } from 'react-i18next'
// import Button from 'design/Button'

const ListIntegrations = ({ loading, connectionsEntries }) => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Layout className='rc-content flex flex-col items-center pt-48'>
      {/* <div className='w-full h-full p-4 flex flex-col items-center justify-center bg-white'> */}
      <div className='w-full xl:w-2/3 2xl:w-1/2'>
        <div className='pb-5 border-b border-gray-200 mb-5'>
          <h3 className='text-lg leading-6 font-medium text-gray-900'>
            {t('component.listIntegrations.integrations')}
          </h3>
          <div className='mt-2 max-w-4xl text-sm text-gray-500'>{t('component.listIntegrations.connectYourErp')}</div>
        </div>

        {(loading && (
          <div className='w-full flex justify-center pt-4'>
            <PageLoader />
          </div>
        )) || (
          <div className='w-full grid grid-cols-1 gap-4 sm:grid-cols-2'>
            {connectionsEntries.map(([source, connection]) => (
              <IntegrationCard
                key={source}
                source={source}
                connection={connection}
                onClick={() => { history.push(`/integrations/${source.toLowerCase()}`) }}
              />
            ))}
          </div>
        )}

        <div className='mt-4 max-w-4xl text-sm text-gray-500'>
          <Trans
            i18nKey='component.listIntegrations.cryMessage'
            components={{ Span: <span role='img' aria-label='cry' /> }}
          />
        </div>
        <Button type='link' onClick={openLiveChat} className='p-0 h-auto'>{t('component.listIntegrations.letUsKnow')}</Button>
      </div>
    </Layout>
  )
}

ListIntegrations.propTypes = {
  loading: PropTypes.bool,
  connectionsEntries: PropTypes.arrayOf(PropTypes.array)
}

export default ListIntegrations
