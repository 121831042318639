import React from 'react'
import PropTypes from 'prop-types'
import { InputNumber } from 'antd'
import cx from 'classnames'
import Spinner from 'design/Spinner.js'

const PercentInput = React.forwardRef(({ loading, className, grow, ...rest }, ref) => {
  return (
    <div className={cx('rc-unit-input relative flex flex-row items-center', { 'w-full': grow }, className)}>
      <InputNumber
        ref={ref}
        min={0}
        step={10}
        decimalSeparator=','
        className={cx({ 'flex-grow': grow }, className)}
        controls={false}
        {...rest}
      />

      {loading && (<Spinner className='absolute left-2 w-4 h-4 text-primary' />)}

      <div className='absolute right-1.5 text-xs'>%</div>
    </div>
  )
})

PercentInput.defaultProps = {
  loading: false,
  grow: true
}

PercentInput.propTypes = {
  loading: PropTypes.bool,
  grow: PropTypes.bool,
  className: PropTypes.string
}

export default PercentInput
