import { useMemo } from 'react'
import useConnections from 'hooks/connections/useConnections.js'
import featureConfig from 'config/features.js'

export default function useBankConnectionReady () {
  const { powensConnection } = useConnections()

  const bankConnectionReady = useMemo(() => {
    if (featureConfig.powensConnectionEnabled) {
      return powensConnection?.firstSyncingEndAt
    }

    return true
  }, [powensConnection])

  return bankConnectionReady
}
