import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './styles/page-loader.less'

PageLoader.propTypes = {
  className: PropTypes.string
}

export default function PageLoader ({ className, ...rest }) {
  return (
    <div className={classNames('rc-page-loader', className)} {...rest}>
      <div className='dot' />
      <div className='dot' />
      <div className='dot' />
    </div>
  )
}
