import { useMutation } from '@apollo/client'
import { Dropdown, Tooltip } from 'antd'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'

import { BanknotesIcon as BanknotesIconOutline } from '@heroicons/react/24/outline'
import { BanknotesIcon, EyeIcon, EyeSlashIcon, TrashIcon, PencilSquareIcon } from '@heroicons/react/24/solid'
import confirm from 'antd/lib/modal/confirm'
import classNames from 'classnames'
import ActionsIcon from 'design/ActionsIcon'
import { MARK_EXPECTED_TRANSACTIONS_AS_PAID } from 'graphql/expected-transactions'
import { DELETE_INVOICE_FROM_IDS, IGNORE_INVOICES } from 'graphql/invoices'
import { useTranslation } from 'react-i18next'
import AccountingDocumentPreviewButton from 'components/accounting-documents/AccountingDocumentPreviewButton.js'

InvoiceActions.propTypes = {
  invoice: PropTypes.object,
  refetchInvoices: PropTypes.func,
  editInvoice: PropTypes.func
}

export default function InvoiceActions ({ invoice, refetchInvoices, editInvoice }) {
  const { t } = useTranslation()
  const allExpectedTransactionsArePaid = useMemo(() => {
    const nonPaidExpTxn = invoice.expectedTransactions.find((expTxn) => !expTxn.paymentDate)
    return !nonPaidExpTxn
  }, [invoice])

  const expectedTransactionIds = useMemo(() => invoice.expectedTransactions.map((expTxn) => expTxn.id), [invoice])

  const [ignoreInvoices, { loading: ignoreLoading }] = useMutation(IGNORE_INVOICES, {
    variables: { input: { invoiceIds: [invoice.id], cancel: invoice.ignored } }
  })

  const [markExpectedTransactionAsPaid, { loading: markAsPaidLoading }] = useMutation(MARK_EXPECTED_TRANSACTIONS_AS_PAID, {
    variables: { input: { expectedTransactionIds, cancel: allExpectedTransactionsArePaid } }
  })

  const editInvoiceCallback = useCallback(() => {
    editInvoice({ invoice })
  }, [editInvoice, invoice])

  const [deleteInvoice, { loading: deleteLoading }] = useMutation(DELETE_INVOICE_FROM_IDS, {
    variables: { invoiceIds: [invoice?.id] },
    onCompleted: refetchInvoices
  })

  const handleMenuClick = useCallback(({ key }) => {
    switch (key) {
      case 'ignore':
        ignoreInvoices()
        break
      case 'markAsPaid':
        markExpectedTransactionAsPaid()
        break
      case 'edit':
        editInvoiceCallback()
        break
      case 'delete':
        confirm({
          title: t('component.invoiceActions.doYouReallyWantToDeleteThisInvoice'),
          okType: 'danger',
          okText: t('shared.delete'),
          cancelText: t('shared.no'),
          maskClosable: true,
          onOk () {
            deleteInvoice()
          }
        })
        break
      default:
        console.warn(`${key} button not handled`)
    }
  }, [ignoreInvoices, markExpectedTransactionAsPaid, t, deleteInvoice, editInvoiceCallback])

  const loading = useMemo(() => (ignoreLoading || markAsPaidLoading || deleteLoading), [ignoreLoading, markAsPaidLoading, deleteLoading])
  const canBeEdited = useMemo(() => (['upload', 'manual'].includes(invoice.externalSource)), [invoice.externalSource])

  return (
    <div className='flex flex-row items-center justify-end w-full space-x-1'>
      <AccountingDocumentPreviewButton accountingDocument={invoice} />
      <Dropdown
        disabled={loading}
        placement='bottomRight'
        menu={{
          onClick: handleMenuClick,
          items: [
            {
              key: 'ignore',
              label: (
                <Tooltip
                  title={t('component.invoiceActions.tooltip.ignoredBill')}
                  placement='left'
                  className='flex items-center'
                >
                  {(invoice.ignored && <EyeIcon className='w-4 h-4 mr-2 inline' />) || <EyeSlashIcon className='w-4 h-4 mr-2 inline' />}
                  {invoice.ignored ? t('shared.unIgnore') : t('shared.ignore')}
                </Tooltip>
              )
            }, {
              key: 'markAsPaid',
              label: (
                <Tooltip
                  title={t('component.invoiceActions.paidExpectedTransactionNotTakenIntoAccountAnymore')}
                  placement='left'
                  className='flex items-center'
                >
                  {allExpectedTransactionsArePaid ? <BanknotesIconOutline className='w-4 h-4 mr-2 inline' /> : <BanknotesIcon className='w-4 h-4 mr-2 inline' />}
                  {allExpectedTransactionsArePaid ? t('component.invoiceActions.unmarkAsPaid') : t('component.invoiceActions.markAsPaid')}
                </Tooltip>
              )
            }, {
              key: 'edit',
              disabled: !canBeEdited,
              label: (
                <Tooltip
                  title={canBeEdited ? null : t('component.invoiceActions.tooltip.cannotBeUpdated')}
                  placement='left'
                  className='flex items-center'
                >
                  <PencilSquareIcon className={classNames('w-4 h-4 mr-2 inline', { 'text-gray-200': !canBeEdited })} /> {t('shared.edit')}
                </Tooltip>
              )
            }, {
              key: 'delete',
              disabled: !canBeEdited,
              label: (
                <Tooltip
                  title={canBeEdited ? null : t('component.invoiceActions.tooltip.cannotBeDeleted')}
                  placement='left'
                  className='flex items-center'
                >
                  <TrashIcon className={classNames('w-4 h-4 mr-2 inline', { 'text-error': canBeEdited, 'text-gray-200': !canBeEdited })} /> {t('shared.delete')}
                </Tooltip>
              )
            }
          ]
        }}
      >
        <ActionsIcon loading={loading} />
      </Dropdown>
    </div>
  )
}
