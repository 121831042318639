import React from 'react'
import PropTypes from 'prop-types'
import { isNumber } from 'lodash'
// import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'

const CustomizedDot = ({ stroke, cx, cy, value }) => {
  if (!isNumber(value) || !cy || !cx) return null

  if (value < 0) {
    return (
      <svg x={cx - 16} y={cy - 16} width={32} height={32}>
        <rect x='3' y='3' width={26} height={26} stroke={stroke} strokeWidth='3px' fill='white' rx={13} />
        {/* <g>
          <ExclamationTriangleIcon className='h-1 w-1 text-yellow-400' />
        </g> */}
        <text x='50%' y='50%' dominantBaseline='middle' textAnchor='middle' fontSize='12'>⚠️</text>
      </svg>
    )
  }

  return (
    <svg x={cx - 7} y={cy - 7} width={14} height={14}>
      <rect x='2' y='2' width={10} height={10} stroke={stroke} strokeWidth='3px' fill='white' rx={5} />
    </svg>
  )
}

CustomizedDot.propTypes = {
  stroke: PropTypes.string,
  cx: PropTypes.number,
  cy: PropTypes.number,
  value: PropTypes.number
}

export default CustomizedDot
