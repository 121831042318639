import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import moment from 'moment'

const ConnectionEvent = ({ icon, iconType, children, eventAt }) => {
  const Icon = icon

  return (
    <div className='relative pb-8'>
      <span className='absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200' aria-hidden='true' />
      <div className='relative flex space-x-3'>
        <div>
          <span
            className={cx(
              'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white',
              {
                'bg-green-500': iconType === 'success',
                'bg-blue-500': iconType === 'info',
                'bg-yellow-500': iconType === 'warning',
                'bg-gray-400': iconType === 'default'
              }
            )}
          >
            <Icon className='h-5 w-5 text-white' />
          </span>
        </div>
        <div className='min-w-0 flex-1 pt-1.5 flex justify-between space-x-4'>
          <div>
            <p className='text-sm text-gray-500'>{children}</p>
          </div>
          {eventAt && (
            <div className='text-right text-sm whitespace-nowrap text-gray-500'>
              {moment(eventAt).format('D MMM - HH:mm')}
            </div>
          )}
        </div>
      </div>
    </div>

  )
}

ConnectionEvent.defaultProps = {
  iconType: 'default'
}

ConnectionEvent.propTypes = {
  eventAt: PropTypes.string,
  icon: PropTypes.elementType.isRequired,
  iconType: PropTypes.oneOf(['success', 'info', 'default', 'warning']).isRequired,
  children: PropTypes.node
}

export default ConnectionEvent
