import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { c } from 'utils/currencies'

const VATTooltip = ({ payload }) => {
  const { t } = useTranslation()

  if (!payload) return null

  return (
    <div className='rounded-md border border-gray-200 px-2 py-1 bg-white'>
      <div className='font-bold'>{payload.title}</div>
      <div className='text-sm'>{t('component.vatTooltip.vatIn')} <span className='text-success'>{c(payload.vatIn)}</span></div>
      <div className='text-sm'>{t('component.vatTooltip.vatOut')} <span className='text-error'>{c(payload.vatOut)}</span></div>
    </div>
  )
}

VATTooltip.propTypes = {
  payload: PropTypes.object
}

export default VATTooltip
