import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'
import i18next from 'i18next'

import ExpectedTransactionDetailCategorySelect from 'components/expected-transaction-details/ExpectedTransactionDetailCategorySelect'
import ExpectedTransactionDetailVatRateSelect from 'components/expected-transaction-details/ExpectedTransactionDetailVatRateSelect'
import ExpectedTransactionDetailAmountInput from 'components/expected-transaction-details/ExpectedTransactionDetailAmountInput'
import AddDetailToExpectedTransactionButton from 'components/expected-transactions/AddDetailToExpectedTransactionButton'
import DeleteExpectedTransactionDetail from './DeleteExpectedTransactionDetail'

const getColumns = ({ expectedTransaction }) => {
  const { t } = i18next

  return [
    {
      title: t('shared.category'),
      key: 'categoryId',
      render: (expTxnDetail) => (
        <ExpectedTransactionDetailCategorySelect expectedTransactionDetail={expTxnDetail} />
      )
    },
    {
      title: t('shared.VAT'),
      key: 'vatRate',
      width: '160px',
      render: (expTxnDetail) => (
        <ExpectedTransactionDetailVatRateSelect expectedTransactionDetail={expTxnDetail} />
      )
    },
    {
      title: t('shared.amountInclTax'),
      key: 'amount',
      width: '152px',
      render: (expTxnDetail) => (
        <ExpectedTransactionDetailAmountInput expectedTransactionDetail={expTxnDetail} />
      )
    },
    {
      key: 'deleteButton',
      width: '40px',
      className: 'pl-0 pr-0',
      render: (expTxnDetail) => <DeleteExpectedTransactionDetail expectedTransactionDetail={expTxnDetail} expectedTransaction={expectedTransaction} />
    }
  ]
}

const ExpectedTransactionDetailTable = ({ expectedTransaction, ...rest }) => {
  const columns = useMemo(() => getColumns({ expectedTransaction }), [expectedTransaction])

  return (
    <Table
      size='small'
      columns={columns}
      rowKey={expTxnDetail => expTxnDetail.id}
      pagination={false}
      footer={() => (
        <AddDetailToExpectedTransactionButton expectedTransaction={expectedTransaction} />
      )}
      dataSource={expectedTransaction.expectedTransactionDetails}
      {...rest}
    />
  )
}

ExpectedTransactionDetailTable.propTypes = {
  expectedTransaction: PropTypes.object
}

export default ExpectedTransactionDetailTable
