import React, { useMemo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'
import { get } from 'lodash'
import { useDebouncedCallback } from 'use-debounce'

import CategorizeTransaction from './CategorizeTransaction'
import TransactionVatSelect from './TransactionVatSelect'
import MultipleTransactionUpdate from './MultipleTransactionUpdate'
import TransactionActions from './TransactionActions'
import TransactionPartTableActions from './TransactionPartTableActions'
import ShiftTransactionDateInput from './ShiftTransactionDateInput'
import TransactionDescription from './TransactionDescription'
import TransactionBankLogo from './TransactionBankLogo'
import TransactionAmount from './TransactionAmount'
import useBankInfosByAccountId from 'hooks/useBankInfosByAccountId.js'
import i18next from 'i18next'
import featureConfig from 'config/features.js'

const generateColumns = (type, orderable, orderBy, order, withValidation, onValidate, lean, bankInfosByAccountId) => {
  const { t } = i18next
  const withValidateStatus = !withValidation

  const columns = [
    {
      title: '',
      dataIndex: '',
      key: 'logo',
      width: '12px',
      className: 'p-0',
      render: transaction => <TransactionBankLogo transaction={transaction} bankInfosByAccountId={bankInfosByAccountId} className='rc-show-on-table-row-hover' />
    },
    {
      title: t('component.transactionTable.description'),
      dataIndex: '',
      key: 'description',
      render: transaction => <TransactionDescription transaction={transaction} />
    },
    {
      title: t('component.transactionTable.date'),
      dataIndex: '',
      key: 'date',
      sorter: orderable,
      sortOrder: orderBy === 'date' && order,
      width: '140px',
      render: txn => <ShiftTransactionDateInput transaction={txn} />
    },
    {
      title: t('component.transactionTable.amount'),
      dataIndex: '',
      key: 'amount',
      sorter: orderable,
      sortOrder: orderBy === 'amount' && order,
      ellipsis: true,
      width: '144px',
      render: transaction => <TransactionAmount transaction={transaction} />
    }
  ]

  if (!lean) {
    columns.push({
      // pl-3 and pl-1 are here to align selects with header
      title: <div className='pl-3'>{t('component.transactionTable.vat')}</div>,
      className: 'pl-1',
      dataIndex: '',
      key: 'vat',
      width: '140px',
      render: (transaction) => <TransactionVatSelect transaction={transaction} />
    })
  }

  columns.push({
    // pl-3 and pl-1 are here to align selects with header
    title: <div className='pl-3'>{t('component.transactionTable.category')}</div>,
    className: 'pl-1',
    dataIndex: '',
    key: 'validation',
    width: '20%',
    render: (transaction) => (
      <CategorizeTransaction
        type={type}
        transaction={transaction}
        lean={lean}
      />
    )
  })

  if (!lean) {
    columns.push({
      title: '',
      dataIndex: '',
      key: 'more',
      width: computeActionsWidth({ withValidateStatus }),
      render: (transaction) => {
        if (transaction.originalTransactionId) {
          return (
            <TransactionPartTableActions
              transaction={transaction}
              withValidation={withValidation}
              onValidate={onValidate}
            />
          )
        }

        return (
          <TransactionActions
            transaction={transaction}
            withValidation={withValidation}
            onValidate={onValidate}
          />
        )
      }
    })
  }

  return columns
}

const TransactionTable = ({
  type,
  setOrder,
  orderBy,
  order,
  onValidate,
  lean,
  ...rest
}) => {
  const [selectedTransactionIds, setSelectedTransactionIds] = useState([])

  const orderable = useMemo(() => {
    return !!setOrder
  }, [setOrder])

  const sortCallback = useCallback((sorter) => {
    if (orderable) {
      setOrder({ order: sorter.order, orderBy: sorter.columnKey })
    }
  }, [orderable, setOrder])

  const [debouncedOnValidate] = useDebouncedCallback(onValidate, 300)
  const withValidation = !!onValidate

  const bankInfosByAccountId = useBankInfosByAccountId()

  const columns = useMemo(
    () => generateColumns(type, orderable, orderBy, order, withValidation, debouncedOnValidate, lean, bankInfosByAccountId),
    [type, orderable, orderBy, order, withValidation, debouncedOnValidate, lean, bankInfosByAccountId]
  )

  const onUpdateMultipleSuccess = useCallback(() => {
    setSelectedTransactionIds([])
    if (withValidation) onValidate()
  }, [setSelectedTransactionIds, withValidation, onValidate])

  const rowSelection = {
    selectedRowKeys: selectedTransactionIds,
    onChange: (transactionIds) => setSelectedTransactionIds(transactionIds)
  }

  const getRowClassName = useCallback((transaction) => {
    const classNames = ['rc-absolute-row']

    if (onValidate && get(transaction, 'validated')) {
      classNames.push('rc-hidden-row')
    }

    if (get(transaction, 'ignored') || (get(transaction, 'amount', 0) === 0)) {
      classNames.push('rc-table-row-ignored')
    }

    return classNames.join(' ')
  }, [onValidate])

  return (
    <>
      {!lean && (
        <MultipleTransactionUpdate
          type={type}
          transactionIds={selectedTransactionIds}
          onSuccess={onUpdateMultipleSuccess}
          withValidation={withValidation}
        />
      )}

      <div>
        <Table
          rowSelection={lean ? undefined : rowSelection}
          columns={columns}
          rowKey={transaction => transaction.id}
          onChange={(_, __, sorter) => sortCallback(sorter)}
          showSorterTooltip={false}
          rowClassName={getRowClassName}
          className='rc-transaction-table'
          {...rest}
        />
      </div>
    </>
  )
}

function computeActionsWidth ({ withValidateStatus }) {
  if (featureConfig.invoicesEnabled) {
    return withValidateStatus ? '136px' : '200px'
  }

  return withValidateStatus ? '112px' : '176px'
}

TransactionTable.defaultProps = {
  lean: false
}

TransactionTable.propTypes = {
  type: PropTypes.oneOf(['cashin', 'cashout']),
  setOrderBy: PropTypes.func,
  setOrder: PropTypes.func,
  orderBy: PropTypes.string,
  order: PropTypes.string,
  onValidate: PropTypes.func,
  lean: PropTypes.bool
}

export default TransactionTable
