import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Redirect } from 'react-router-dom'

import AuthActions from 'actions/AuthActions'
import { message } from 'antd'
import Spinner from 'design/Spinner'
import useSearchParams from 'hooks/useSearchParams'
import { get } from 'lodash'

export default () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { webtoken } = useSearchParams()
  const history = useHistory()

  const isAuthenticated = useSelector(state => get(state, 'app.isAuthenticated'))
  const [firstAuthenticated] = useState(isAuthenticated)

  useEffect(() => {
    if (firstAuthenticated && webtoken) dispatch(AuthActions.signOut())
  }, [dispatch, firstAuthenticated, webtoken])

  useEffect(() => {
    if (webtoken && !isAuthenticated) {
      dispatch(AuthActions.authenticateSellsyUserOrRedirect({
        webToken: webtoken,
        onError: (e) => {
          const errorCode = e?.response?.data?.code

          switch (errorCode) {
            case 'SELLSY_SSO_SIGN_UP_NOT_PERMITTED':
              message.warning({ content: t('sellsySsoScreen.error.SELLSY_SSO_SIGN_UP_NOT_PERMITTED'), duration: 15 })
              break
            case 'SELLSY_SSO_SIGN_IN_NOT_PERMITTED':
              message.warning({ content: t('sellsySsoScreen.error.SELLSY_SSO_SIGN_IN_NOT_PERMITTED'), duration: 15 })
              break
            case 'SELLSY_CASHFLOW_MODULE_IS_INACTIVE':
              message.warning({ content: t('sellsySsoScreen.error.SELLSY_CASHFLOW_MODULE_IS_INACTIVE'), duration: 20 })
              break
            case 'INCLUDED_USERS_QUANTITY_REACHED':
              message.warning({ content: t('sellsySsoScreen.error.INCLUDED_USERS_QUANTITY_REACHED'), duration: 20 })
              break
            case 'EMAIL_ALREADY_USED':
              message.warning({ content: t('sellsySsoScreen.error.EMAIL_ALREADY_USED'), duration: 20 })
              break
            default:
              message.error({ content: t('sellsySsoScreen.error.default'), duration: 15 })
              break
          }

          history.replace('/signin')
        },
        onComplete: () => {
          history.push('/')
        }
      }))
    }
  }, [dispatch, history, webtoken, isAuthenticated, t])

  if (isAuthenticated && !webtoken) {
    return <Redirect to='/' />
  }

  return (
    <div className='flex flex-col w-full h-full items-center justify-center'>
      <div className='mb-8 -mt-14 w-full flex flex-row justify-center animate-fade-in'>
        <img src='/logo_sellsy_tresorerie.svg' alt='Sellsy Trésorerie logo' className='rc-signin-logo' />
      </div>

      <Spinner className='w-6 h-6 text-primary' />
    </div>
  )
}
