import { DatePicker, Popover, Select, Tooltip } from 'antd'
import confirm from 'antd/lib/modal/confirm'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
// import { get } from 'lodash'
// import accounting from 'accounting'
import React, { useCallback, useContext, useMemo, useState } from 'react'

import { useMutation } from '@apollo/client'
import { CalculatorIcon, CalendarIcon, ChevronLeftIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import Button from 'design/Button'
import ButtonLink from 'design/ButtonLink'
import { BATCH_FORECAST_AMOUNTS } from 'graphql/scenario-forecasts'
import moment from 'moment'

import useSaveForecastFromRealised from 'hooks/useSaveForecastFromRealised.js'

import CurrencyInput from 'components/CurrencyInput'
import GrowthVariation from 'components/forecast/GrowthVariation.js'
import CashflowSheetContext from 'contexts/CashflowSheetContext'
import ForecastAssistantService from 'services/ForecastAssistantService.js'

ForecastAssistant.propTypes = {
  scenarioId: PropTypes.string,
  categoryId: PropTypes.string,
  defaultToDate: PropTypes.string,
  sheetDate: PropTypes.object,
  amount: PropTypes.number,
  onVisibleChange: PropTypes.func,
  children: PropTypes.node,
  hideAssistant: PropTypes.func,
  onCompleted: PropTypes.func
}

const ForecastAssistantMode = {
  NotSet: 0,
  Realised: 1,
  Recurrence: 2
}

function ForecastAssistant ({
  scenarioId,
  categoryId,
  defaultToDate,
  sheetDate,
  amount,
  onVisibleChange,
  children,
  hideAssistant,
  onCompleted,
  ...rest
}) {
  const { t } = useTranslation()
  const { refetchCashflowSheet, sheet } = useContext(CashflowSheetContext)
  const [editMode, setEditMode] = useState(false)

  const [mode, setMode] = useState(ForecastAssistantMode.NotSet)
  const [forecastBaseAmount, setForecastBaseAmount] = useState(amount)

  const defaultToDateMoment = useMemo(() => moment(defaultToDate), [defaultToDate])

  const [params, setParams] = useState({
    growthType: 'PERCENTAGE',
    growthOperation: 'ADD',
    growthValue: 0,
    toDate: defaultToDateMoment,
    frequency: 'MONTHLY',
    behaviour: 'AVERAGE_LAST_THREE_MONTHS'
  })

  const setParameter = useCallback((newParams) => setParams({ ...params, ...newParams }), [params, setParams])

  const [batchForecastAmounts, { loadingRecurrence }] = useMutation(BATCH_FORECAST_AMOUNTS, {
    onCompleted: () => {
      refetchCashflowSheet()
      hideAssistant()
      onCompleted()
    }
  })

  const onSubmitRecurrence = useCallback(() => {
    const assistantService = new ForecastAssistantService({ sheetDate, amount: forecastBaseAmount })
    const forecasts = assistantService.computeGrowth(params)

    if (forecasts.length > 0) {
      batchForecastAmounts({ variables: { input: { scenarioId, categoryId, forecasts } } })
    } else {
      hideAssistant()
    }
  }, [sheetDate, forecastBaseAmount, params, batchForecastAmounts, scenarioId, categoryId, hideAssistant])

  const onSubmitReset = useCallback(() => {
    const assistantService = new ForecastAssistantService({ sheetDate, amount: 0 })
    const toDate = moment(sheetDate.momentDate).add(35, 'months')
    const forecasts = assistantService.computeGrowth({
      growthType: 'PERCENTAGE',
      growthOperation: 'ADD',
      growthValue: 0,
      toDate: toDate,
      frequency: 'MONTHLY'
    })

    if (forecasts.length > 0) {
      if (assistantService.hasAlreadyBudgetedOnPeriod({ categoryId, toDate: toDate, sheet })) {
        confirm({
          title: t('dashboard.forecastAssistant.youAreAboutToOverwriteFutureBudgets'),
          okType: 'danger',
          okText: t('shared.confirm'),
          cancelText: t('shared.cancel'),
          maskClosable: true,
          zIndex: 1500,
          onOk () {
            batchForecastAmounts({ variables: { input: { scenarioId, categoryId, forecasts } } })
          }
        })
      } else batchForecastAmounts({ variables: { input: { scenarioId, categoryId, forecasts } } })
    } else hideAssistant()
  }, [sheetDate, hideAssistant, categoryId, sheet, batchForecastAmounts, scenarioId, t])

  const { onSubmitRealised, loading } = useSaveForecastFromRealised({
    scenarioId,
    categoryId,
    sheetDate,
    onCompleted: () => {
      refetchCashflowSheet()
      hideAssistant()
      onCompleted()
    },
    params,
    amount,
    sheet
  })

  const handleToDateChange = useCallback((toDateMoment) => {
    const toDate = moment(toDateMoment).endOf('month')
    setParameter({ toDate })
  }, [setParameter])

  const pickerFooter = usePickerFooter({ handleToDateChange, sheetDate })

  const menu = (
    <>
      <Button
        onClick={() => setMode(ForecastAssistantMode.Realised)}
        label={t('dashboard.forecastAssistant.useRealized')}
        className='overflow-visible'
      />

      <Button
        onClick={() => setMode(ForecastAssistantMode.Recurrence)}
        label={t('dashboard.forecastAssistant.applyRecurring')}
        className='overflow-visible'
      />

      <Button
        onClick={onSubmitReset}
        disabled={loadingRecurrence}
        loading={loadingRecurrence}
        label={t('dashboard.forecastAssistant.resetLine')}
        className='overflow-visible'
      />
    </>
  )
  if (!editMode) {
    return (
      <Popover
        placement='top'
        trigger='click'
        overlayClassName='rc-forecast-assistant rc-popover-no-padding'
        onOpenChange={onVisibleChange}
        {...rest}
        content={(
          <ButtonLink
            onClick={() => setEditMode(true)}
            className='p-2'
            label={(
              <CalculatorIcon className='w-6 h-6 ' />
            )}
          />
        )}
      >
        {children}
      </Popover>
    )
  }

  return (
    <Popover
      placement='top'
      trigger='click'
      overlayClassName='rc-forecast-assistant rc-popover-no-padding'
      onOpenChange={onVisibleChange}
      {...rest}
      content={
        <div className='px-2 py-2 flex flex-col space-y-2'>
          <div className='w-full flex flex-row items-center justify-between font-bold mb-2'>

            {mode !== 0 && (
              <ButtonLink onClick={() => setMode(ForecastAssistantMode.NotSet)} label={<ChevronLeftIcon className='w-4 h-4' />} className='text-gray-400' />
            )}

            {mode === ForecastAssistantMode.NotSet && (<span>{t('dashboard.forecastAssistant.forecastAssistant')}</span>)}
            {mode === ForecastAssistantMode.Realised && (<span>{t('dashboard.forecastAssistant.useRealized')}</span>)}
            {mode === ForecastAssistantMode.Recurrence &&
             (<span>{t('dashboard.forecastAssistant.applyRecurring')}</span>)}

            <ButtonLink onClick={hideAssistant} label={<XMarkIcon className='w-4 h-4' />} className='text-gray-400' />
          </div>

          {mode === ForecastAssistantMode.NotSet && menu}

          {mode === ForecastAssistantMode.Realised && (
            <>

              <div className='flex flex-col space-y-1'>
                <div className='flex flex-row text-xs font-bold w-20'>
                  {t('dashboard.forecastAssistant.reference')}
                  <Tooltip
                    placement='top'
                    title={t('dashboard.forecastAssistant.forecastedFromRealizedTooltip')}
                  >
                    <InformationCircleIcon className='float-right ml-1 min-w-4 w-4 h-4 text-gray-200' />
                  </Tooltip>
                </div>

                <Select
                  value={params.behaviour}
                  onChange={(behaviour) => setParameter({ behaviour })}
                  className='w-64'
                >
                  <Select.Option value='PREVIOUS_MONTH'>{t('dashboard.forecastAssistant.behaviourSelect.PREVIOUS_MONTH')}</Select.Option>
                  <Select.Option value='SAME_MONTH_PREVIOUS_YEAR'>{t('dashboard.forecastAssistant.behaviourSelect.SAME_MONTH_PREVIOUS_YEAR')}</Select.Option>
                  <Select.Option value='AVERAGE_LAST_THREE_MONTHS'>{t('dashboard.forecastAssistant.behaviourSelect.AVERAGE_LAST_THREE_MONTHS')}</Select.Option>
                  <Select.Option value='AVERAGE_LAST_TWELVE_MONTHS'>{t('dashboard.forecastAssistant.behaviourSelect.AVERAGE_LAST_TWELVE_MONTHS')}</Select.Option>
                </Select>
              </div>

              <div className='flex flex-col space-y-1'>
                <div className='text-xs font-bold w-20'>{t('dashboard.forecastAssistant.growth')}</div>

                <div className='flex flex-row space-x-2'>
                  <GrowthVariation
                    onChange={setParameter}
                    growthOperation={params.growthOperation}
                    growthType={params.growthType}
                    growthValue={params.growthValue}
                  />
                </div>
              </div>

              <div className='flex flex-col space-y-1'>
                <div className='text-xs font-bold w-20'>{t('dashboard.forecastAssistant.until')}</div>

                <DatePicker
                  picker='month'
                  format='MMMM YYYY'
                  allowClear={false}
                  disabledDate={(current) => disabledToDate(current, sheetDate)}
                  value={params.toDate}
                  onChange={handleToDateChange}
                  className='w-64'
                  renderExtraFooter={pickerFooter}
                  suffixIcon={<CalendarIcon className='w-4 h-4 text-gray-200' />}
                />
              </div>

              <div className='w-full'>
                <Button
                  primary
                  disabled={loading}
                  loading={loading}
                  label={t('dashboard.forecastAssistant.computeAndSave')}
                  onClick={onSubmitRealised}
                  className='w-full mt-2'
                />
              </div>
            </>)}

          {mode === ForecastAssistantMode.Recurrence && (
            <>
              <div className='flex flex-row space-x-2 w-64'>
                <div className='flex flex-col space-y-1 w-1/2'>
                  <div className='flex flex-row items-center text-xs font-bold'>
                    {t('dashboard.forecastAssistant.Amount')}

                    <Tooltip
                      placement='top'
                      title={t('dashboard.forecastAssistant.AmountTooltip')}
                    >
                      <InformationCircleIcon className='inline-block w-4 h-4 text-gray-200 ml-2' />
                    </Tooltip>
                  </div>

                  <CurrencyInput
                    min={0}
                    value={forecastBaseAmount}
                    onChange={(newAmount) => setForecastBaseAmount(newAmount)}
                    grow
                  />
                </div>

                <div className='flex flex-col space-y-1 w-1/2'>
                  <div className='text-xs font-bold'>{t('dashboard.forecastAssistant.frequency')}</div>

                  <Select
                    value={params.frequency}
                    onChange={(frequency) => setParameter({ frequency })}
                    className='w-32'
                  >
                    <Select.Option value='MONTHLY'>{t('shared.monthly')}</Select.Option>
                    <Select.Option value='QUARTERLY'>{t('shared.quarterly')}</Select.Option>
                    <Select.Option value='HALF-YEARLY'>{t('shared.halfYearly')}</Select.Option>
                    <Select.Option value='YEARLY'>{t('shared.yearly')}</Select.Option>
                  </Select>
                </div>
              </div>

              <div className='flex flex-col space-y-1'>
                <div className='text-xs font-bold'>{t('dashboard.forecastAssistant.growth')}</div>

                <div className='flex flex-row space-x-2'>
                  <GrowthVariation
                    onChange={setParameter}
                    growthOperation={params.growthOperation}
                    growthType={params.growthType}
                    growthValue={params.growthValue}
                  />
                </div>
              </div>

              <div className='flex flex-col space-y-1'>
                <div className='text-xs font-bold'>{t('dashboard.forecastAssistant.until')}</div>

                <DatePicker
                  picker='month'
                  format='MMMM YYYY'
                  allowClear={false}
                  disabledDate={(current) => disabledToDate(current, sheetDate)}
                  value={params.toDate}
                  onChange={handleToDateChange}
                  className='w-64'
                  renderExtraFooter={pickerFooter}
                  suffixIcon={<CalendarIcon className='w-4 h-4 text-gray-200' />}
                />
              </div>

              <div className='w-full'>
                <Button
                  primary
                  disabled={loadingRecurrence}
                  loading={loadingRecurrence}
                  label={t('dashboard.forecastAssistant.computeAndSave')}
                  onClick={onSubmitRecurrence} className='w-full mt-2'
                />
              </div>
            </>)}
        </div>
      }
    >
      {children}
    </Popover>
  )
}

const usePickerFooter = ({ handleToDateChange, sheetDate }) => {
  const { t } = useTranslation()
  return useCallback(() => {
    const twelveMonthFromSheetDate = moment(sheetDate.momentDate).add(12, 'month')
    const endOfSheetDateYear = moment(sheetDate.momentDate).endOf('year')

    return (
      <div className='flex flex-row justify-around'>
        <ButtonLink
          onClick={() => handleToDateChange(twelveMonthFromSheetDate)}
          label={t('dashboard.forecastAssistant.next12Months')}
        />

        <ButtonLink
          type='link'
          onClick={() => handleToDateChange(endOfSheetDateYear)}
          label={t('dashboard.forecastAssistant.endOfTheYear')}
        />
      </div>
    )
  }, [handleToDateChange, sheetDate.momentDate, t])
}

function disabledToDate (current, sheetDate) {
  const inFiveYears = moment().add(5, 'years').endOf('month')
  return moment(sheetDate.momentDate).endOf('month').isAfter(current) || moment(current).endOf('month').isAfter(inFiveYears)
}

export default ForecastAssistant
