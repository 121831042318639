import { Tab } from '@headlessui/react'
import { Tooltip } from 'antd'
import classNames from 'classnames'
import { get, max } from 'lodash'
import numeral from 'numeral'
import PropTypes from 'prop-types'
import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import CashInfo from 'components/CashInfo'
import Currency from 'components/Currency'
import Dot from 'components/Dot'
import ExpectedBar from 'components/charts/shapes/ExpectedBar'
import ForecastBar from 'components/charts/shapes/ForecastBar'
import RealisedBar from 'components/charts/shapes/RealisedBar'
import CashflowSheetContext from 'contexts/CashflowSheetContext'
import Badge from 'design/Badge'
import { useSelector } from 'react-redux'
import colors from 'utils/colors'
import { c } from 'utils/currencies'
import BudgetedCell from './budgeted/BudgetedCell'
import ScenarioCommentInput from './budgeted/ScenarioCommentInput'
import featureConfig from 'config/features.js'

CashflowSheetDrawerRecap.propTypes = {
  rowType: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  sheetDate: PropTypes.object.isRequired,
  scenarioId: PropTypes.string
}

export default function CashflowSheetDrawerRecap ({ rowType, value, sheetDate, scenarioId }) {
  const { t } = useTranslation()
  const realisedVsForecasted = useSelector(state => get(state, 'app.dashboard.realisedVsForecasted', false))
  const { loadingCashflowSheet } = useContext(CashflowSheetContext)

  const {
    realised,
    expected,
    outstandingExpected,
    budgeted,
    budgetedEditable,
    forecasted,
    categoryId,
    comment
  } = useValue({ rowType, value })
  const realisedLabel = useMemo(() => c(realised), [realised])
  const expectedLabel = useMemo(() => c(expected), [expected])

  const realisedPercentLabel = useMemo(() => {
    if (!realised || !forecasted) return null

    const percent = (realised / forecasted) * 100
    const rounded = Math.round(percent)
    return `${rounded}%`
  }, [realised, forecasted])

  const expectedPercentLabel = useMemo(() => {
    if (!expected || !budgeted) return null

    const percent = (expected / budgeted) * 100
    const rounded = Math.round(percent)
    return `${rounded}%`
  }, [expected, budgeted])

  const computedExpected = useMemo(() => {
    return outstandingExpected ? numeral(realised || 0).add(outstandingExpected || 0).value() : expected
  }, [realised, expected, outstandingExpected])

  const maxAmount = useMemo(() => {
    return max([realised, computedExpected, forecasted])
  }, [realised, computedExpected, forecasted])

  const realisedWidth = useMemo(() => {
    const percent = ((realised || 0) / (maxAmount || 1)) * 100
    return `${percent}%`
  }, [realised, maxAmount])

  const expectedWidth = useMemo(() => {
    const percent = ((computedExpected || 0) / (maxAmount || 1)) * 100
    return `${percent}%`
  }, [computedExpected, maxAmount])

  const budgetedNode = useMemo(() => {
    if (!categoryId) {
      return (
        <Tooltip
          title={t('dashboard.cashflowSheetDrawerRecap.cannotEditBudget')}
        >
          <Currency amount={budgeted} style={{ color: budgeted ? colors.black : colors.grey }} className='cursor-not-allowed grayscale border-primary rounded group-hover:border-2 group-hover:py-1 group-hover:px-2' />
        </Tooltip>
      )
    }

    return (
      <BudgetedCell
        budgetedEditable={budgetedEditable}
        budgeted={budgeted}
        comment={comment}
        scenarioId={scenarioId}
        categoryId={categoryId}
        sheetDate={sheetDate}
        className='min-w-full w-full'
      />
    )
  }, [categoryId, budgetedEditable, budgeted, comment, scenarioId, sheetDate, t])

  return (
    <div className='relative w-full flex flex-col justify-start space-y-4'>
      <div className='w-full grid grid-cols-3 gap-8 divide-x'>
        <Tab className='font-bold text-left group'>
          {({ selected }) => (
            <CashInfo
              title={(
                <span className='flex flex-row items-center space-x-2'>
                  <Dot className={classNames('bg-primary')} />
                  <span>{t('dashboard.cashflowSheetDrawerRecap.realized')}</span>
                </span>
              )}
              text={realisedLabel}
              textContainerClassname='h-8'
              color={realised ? 'black' : 'grey'}
              noBackground
              subtextInfoLabel={t('dashboard.cashflowSheetDrawerRecap.percentageOfExpected')}
              subtext={realisedPercentLabel && (<Badge label={realisedPercentLabel} type='disabled' />)}
              className={classNames('group-hover:opacity-100', { 'opacity-100': selected, 'opacity-60': !selected })}
            />
          )}
        </Tab>

        {featureConfig.invoicesEnabled && !realisedVsForecasted && (
          <Tab className='-ml-4 pl-4 font-bold text-left group'>
            {({ selected }) => (
              <CashInfo
                title={(
                  <span className='flex flex-row items-center space-x-2'>
                    <Dot className={classNames('rc-dashed-example-dot-primary')} />
                    <span>{t('dashboard.cashflowSheetDrawerRecap.expected')}</span>
                    {!sheetDate.isFutureMonth && (<span className='text-gray-400 text-xs font-normal'>({sheetDate.isPastMonth ? t('dashboard.cashflowSheetDrawerRecap.paid') : t('dashboard.cashflowSheetDrawerRecap.total')})</span>)}
                  </span>
                )}
                text={expectedLabel}
                textContainerClassname='h-8'
                color={expected ? 'black' : 'grey'}
                noBackground
                subtextInfoLabel={t('dashboard.cashflowSheetDrawerRecap.percentageOfBudget')}
                subtext={expectedPercentLabel && (<Badge label={expectedPercentLabel} type='disabled' />)}
                className={classNames('group-hover:opacity-100', { 'opacity-100': selected, 'opacity-60': !selected })}
              />
            )}
          </Tab>
        )}

        {/* <Tab className='-ml-4 pl-4 font-bold text-left group'> */}
        <div className='-ml-4 pl-4 font-bold text-left group'>
          {/* {({ selected }) => ( */}
          <CashInfo
            title={(
              <span className='flex flex-row items-center space-x-2'>
                <Dot className={classNames('bg-light-primary')} />
                <span>{t('dashboard.cashflowSheetDrawerRecap.budget')}</span>
                <ScenarioCommentInput
                  sheetDate={sheetDate}
                  scenarioId={scenarioId}
                  categoryId={categoryId}
                  comment={comment}
                />
              </span>
            )}
            text={budgetedNode}
            textContainerClassname='h-8 w-full'
            color={budgeted ? 'black' : 'grey'}
            noBackground
            className={classNames('group-hover:opacity-100')}
          />
          {/* )} */}
        </div>
      </div>

      <div className='group cursor-pointer'>
        <svg width='100%' height={10} className={classNames('bg-white rounded-sm', { 'animate-pulse': loadingCashflowSheet })}>
          <ForecastBar fill={colors.primary} x={0} y={0} width='100%' height={10} value={1} noTopBar />
          <ExpectedBar fill={colors.primary} x={0} y={0} width={expectedWidth} height={10} value={1} />
          <RealisedBar fill={colors.primary} x={0} y={0} width={realisedWidth} height={10} value={1} />
        </svg>

        <div className='opacity-60 group-hover:opacity-100 text-black absolute flex flex-row items-center text-xs  space-x-2 -bottom-6 right-0'>
          {/* <span>Valeur prévisionnelle :</span> */}
          <Currency amount={maxAmount} className='font-bold' />
          <span>↲</span>
        </div>
      </div>
    </div>

  )
}

function useValue ({ rowType, value }) {
  return useMemo(() => {
    switch (rowType) {
      case 'CASH_IN':
        return {
          realised: value.realisedCashin,
          expected: value.expectedCashin,
          outstandingExpected: value.outstandingExpectedCashin,
          budgeted: value.budgetedCashin,
          budgetedEditable: null,
          forecasted: value.forecastedCashin,
          categoryId: null,
          comment: null
        }
      case 'CASH_OUT':
        return {
          realised: value.realisedCashout,
          expected: value.expectedCashout,
          outstandingExpected: value.outstandingExpectedCashout,
          budgeted: value.budgetedCashout,
          budgetedEditable: null,
          forecasted: value.forecastedCashout,
          categoryId: null,
          comment: null
        }
      case 'CASHIN_CATEGORY':
      case 'CASHOUT_CATEGORY':
        return {
          realised: value.realised,
          expected: value.expected,
          outstandingExpected: value.outstandingExpected,
          budgeted: value.budgeted,
          budgetedEditable: value.budgetedEditable,
          forecasted: value.forecasted,
          categoryId: value.categoryId,
          comment: value.comment
        }
      default:
        return {}
    }
  }, [value, rowType])
}
