import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Drawer } from 'antd'
import { get, isNumber } from 'lodash'
import CashflowSheetDrawerRecap from './CashflowSheetDrawerRecap'
import CashflowSheetDrawerTitle from './CashflowSheetDrawerTitle'
import { Tab } from '@headlessui/react'
import CashflowSheetDrawerDetails from './CashflowSheetDrawerDetails'
import { XMarkIcon } from '@heroicons/react/20/solid'
import cx from 'classnames'

CashflowSheetDrawer.propTypes = {
  sheet: PropTypes.array,
  sheetDates: PropTypes.array,
  drawerParams: PropTypes.shape({
    visible: PropTypes.bool,
    rowAbsolutePath: PropTypes.string,
    valueIndex: PropTypes.number,
    tabIndex: PropTypes.number
  }),
  setDrawerParams: PropTypes.func,
  scenarioId: PropTypes.string
}

export default function CashflowSheetDrawer ({ sheet, sheetDates, drawerParams, setDrawerParams, scenarioId }) {
  const row = useMemo(() => {
    return get(sheet, drawerParams.rowAbsolutePath, {})
  }, [sheet, drawerParams.rowAbsolutePath])
  const rowType = row.type

  const value = useMemo(() => {
    return get(row, `sheet.${drawerParams.valueIndex}.value`, {})
  }, [row, drawerParams.valueIndex])

  const sheetDate = useMemo(() => {
    return get(sheetDates, drawerParams.valueIndex, {})
  }, [sheetDates, drawerParams.valueIndex])

  const defaultTabIndex = useMemo(() => {
    if (isNumber(drawerParams.tabIndex)) return drawerParams.tabIndex
    const isFutureMonth = get(sheetDate, 'isFutureMonth', false)
    return isFutureMonth ? 1 : 0
  }, [sheetDate, drawerParams.tabIndex])

  if (!rowType || !value) return null

  return (
    <Drawer
      title={(
        <CashflowSheetDrawerTitle
          rowType={rowType}
          value={value}
          sheetDate={sheetDate}
          sheetDates={sheetDates}
          drawerParams={drawerParams}
          setDrawerParams={setDrawerParams}
        />
      )}
      closeIcon={<XMarkIcon className='w-5 h-5' />}
      destroyOnClose
      width={690}
      placement='right'
      onClose={() => setDrawerParams({ ...drawerParams, visible: false })}
      open={drawerParams.visible}
    >
      <div className={cx('h-full overflow-hidden w-full space-y-10 flex flex-col', (sheetDate?.isCurrentMonth ? 'min-h-[1000px]' : ''))}>
        <Tab.Group defaultIndex={defaultTabIndex}>
          <Tab.List className='mt-2 flex-grow-0'>
            <CashflowSheetDrawerRecap
              rowType={rowType}
              value={value}
              sheetDate={sheetDate}
              scenarioId={scenarioId}
            />
          </Tab.List>

          <Tab.Panels className='flex-grow flex flex-col overflow-hidden'>
            <CashflowSheetDrawerDetails
              rowType={rowType}
              value={value}
              sheetDate={sheetDate}
              sheetDates={sheetDates}
              row={row}
              drawerParams={drawerParams}
            />
          </Tab.Panels>
        </Tab.Group>
      </div>
    </Drawer>
  )
}
