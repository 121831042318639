import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { ComposedChart, Bar, Area, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts'
import { flatMap, floor, get, isNumber, max, min, range, round, sumBy } from 'lodash'
import moment from 'moment'

import DashboardMainChartTooltip from './DashboardMainChartTooltip'
import ForecastBar from './shapes/ForecastBar'
import RealisedBar from './shapes/RealisedBar'
import useCurrencyFormatter from 'hooks/useCurrencyFormatter'
import MainChartCursor from './shapes/MainChartCursor'
import ExpectedBar from './shapes/ExpectedBar'
import CustomizedDot from './shapes/CustomizedDot'
import { getUnixEndOfMonth, getUnixMiddleOfMonth, startOfThisMonth } from 'utils/dates'
import { useSelector } from 'react-redux'
import theme from 'theme'

const AxisIds = {
  Cashflow: 'CashflowAxis',
  Balance: 'BalanceAxis'
}

DashboardMainChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  scenarioName: PropTypes.string,
  hideChartLines: PropTypes.bool,
  hideChartBars: PropTypes.bool,
  chartScale: PropTypes.string
}

const margin = { top: 8, left: 8, right: 8, bottom: 8 }

export default function DashboardMainChart ({ data, scenarioName, hideChartLines, hideChartBars, chartScale }) {
  const realisedVsForecasted = useSelector(state => get(state, 'app.dashboard.realisedVsForecasted', false))

  // Data
  const dataWithUnixDates = useDataWithUnixChartDates({ data, realisedVsForecasted })
  const dataWithPreMonth = useDataWithPreMonth({ data: dataWithUnixDates })

  // Domains
  const accountBalanceAxisDomain = useBalanceAxisDomain({ data: dataWithPreMonth })
  const cashflowAxisDomain = useCashflowAxisDomain({ data: dataWithPreMonth, accountBalanceAxisDomain })
  const xAxisDomain = useXAxisDomain({ data: dataWithUnixDates })

  // Ticks
  const accountBalanceTicks = useBalanceTicks({ accountBalanceAxisDomain })
  const cashflowTicks = useCashflowTicks({ cashflowAxisDomain })
  const dateTicks = useDateTicks({ data: dataWithUnixDates })
  const indexByMiddleOfMonth = useIndexByMiddleOfMonth({ data: dataWithUnixDates })

  // Other
  const currencyFormatter = useCurrencyFormatter({ options: { precision: 0 } })

  const scale = useMemo(() => chartScale || 'auto', [chartScale])

  return (
    <ResponsiveContainer className='rc-cashflow-chart'>
      <ComposedChart data={dataWithUnixDates} maxBarSize={30} barGap={5} barCategoryGap={15} margin={margin}>
        <XAxis xAxisId='middleOfMonth' type='number' domain={xAxisDomain} dataKey='unixMiddleOfMonth' ticks={dateTicks} hide />
        <XAxis xAxisId='middleOfMonth2' type='number' domain={xAxisDomain} dataKey='unixMiddleOfMonth' ticks={dateTicks} hide />
        <XAxis xAxisId='middleOfMonth3' type='number' domain={xAxisDomain} dataKey='unixMiddleOfMonth' ticks={dateTicks} hide />
        <XAxis xAxisId='endOfMonth' type='number' domain={xAxisDomain} dataKey='unixEndOfMonth' tickLine={false} ticks={dateTicks} tickFormatter={formatDate} axisLine={false} allowDuplicatedCategory={false} />
        <XAxis xAxisId='presentOrEndOfMonth' type='number' domain={xAxisDomain} dataKey='unixPresentOrEndOfMonth' ticks={dateTicks} hide />
        <XAxis xAxisId='presentForPastOrEndOfMonth' type='number' domain={xAxisDomain} dataKey='unixPresentForPastOrEndOfMonth' ticks={dateTicks} hide />

        <YAxis
          scale={scale}
          yAxisId={AxisIds.Balance}
          domain={['dataMin', 'dataMax']}
          axisLine={false}
          tickLine={false}
          orientation='right'
          stroke={theme.colors.cashflow}
          ticks={accountBalanceTicks}
          tickFormatter={currencyFormatter}
          width={90}
          hide={hideChartLines}
        />
        <YAxis
          scale={scale}
          yAxisId={AxisIds.Cashflow}
          domain={cashflowAxisDomain}
          axisLine={false}
          ticks={cashflowTicks}
          tickLine={false}
          tickFormatter={currencyFormatter}
          width={90}
          hide={hideChartBars}
        />

        <ReferenceLine xAxisId='endOfMonth' yAxisId={AxisIds.Balance} y={0} strokeDasharray='3 3' />

        <defs>
          <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
            <stop offset='0%' stopColor={theme.colors.cashflow} stopOpacity={0.1} />
            <stop offset='100%' stopColor={theme.colors.cashflow} stopOpacity={0} />
          </linearGradient>
        </defs>

        <Tooltip
          cursor={<MainChartCursor dataLength={dataWithUnixDates?.length} />}
          content={({ label }) => {
            const index = indexByMiddleOfMonth[label]

            return (
              <DashboardMainChartTooltip
                dataIndex={index}
                data={dataWithUnixDates}
                scenarioName={scenarioName}
              />
            )
          }}
        />

        <Area
          data={dataWithPreMonth}
          xAxisId='presentOrEndOfMonth'
          yAxisId={AxisIds.Balance}
          dataKey='value.realisedEndingBalance'
          type='monotone'
          fill='url(#colorUv)'
          strokeWidth={0}
          hide={hideChartLines}
        />

        <Bar
          stackId='cashout'
          xAxisId='middleOfMonth'
          yAxisId={AxisIds.Cashflow}
          dataKey='value.realisedCashout'
          name='Expense'
          type='monotone'
          shape={<RealisedBar />}
          fill={theme.colors.cashout}
          hide={hideChartBars}
        />
        <Bar
          stackId='cashin'
          xAxisId='middleOfMonth'
          yAxisId={AxisIds.Cashflow}
          dataKey='value.realisedCashin'
          name='Revenue'
          type='monotone'
          shape={<RealisedBar />}
          fill={theme.colors.cashin}
          hide={hideChartBars}
        />

        <Bar
          stackId='cashout'
          xAxisId='middleOfMonth'
          yAxisId={AxisIds.Cashflow}
          dataKey='value.outstandingExpectedCashout'
          name='Revenue'
          type='monotone'
          shape={<ExpectedBar />}
          fill={theme.colors.cashout}
          hide={hideChartBars}
        />
        <Bar
          stackId='cashin'
          xAxisId='middleOfMonth'
          yAxisId={AxisIds.Cashflow}
          dataKey='value.outstandingExpectedCashin'
          name='Expense'
          type='monotone'
          shape={<ExpectedBar />}
          fill={theme.colors.cashin}
          hide={hideChartBars}
        />

        <Bar
          xAxisId='middleOfMonth2'
          yAxisId={AxisIds.Cashflow}
          dataKey='value.forecastedCashout'
          type='monotone'
          shape={<ForecastBar />}
          fill={theme.colors.cashout}
          hide={hideChartBars}
        />
        <Bar
          xAxisId='middleOfMonth2'
          yAxisId={AxisIds.Cashflow}
          dataKey='value.forecastedCashin'
          type='monotone'
          shape={<ForecastBar />}
          fill={theme.colors.cashin}
          hide={hideChartBars}
        />

        <Line
          data={dataWithPreMonth}
          dataKey='value.forecastedEndingBalance'
          xAxisId='endOfMonth'
          yAxisId={AxisIds.Balance}
          type='monotone'
          stroke={theme.colors.lightCashflow}
          strokeWidth={4}
          dot={<CustomizedDot />}
          activeDot={null}
          hide={hideChartLines}
          // stroke={theme.colors.lightCashflow}
          // strokeWidth={4}
          // strokeDasharray='2 8'
          // strokeLinecap='round'
          // animation='dash 5s linear forwards'
        />

        <Line
          data={dataWithPreMonth}
          dataKey='value.realisedEndingBalance'
          xAxisId='presentOrEndOfMonth'
          yAxisId={AxisIds.Balance}
          type='monotone'
          stroke={theme.colors.cashflow}
          strokeWidth={4}
          dot={{ strokeWidth: 3, r: 5 }}
          activeDot={null}
          hide={hideChartLines}
        />
      </ComposedChart>
    </ResponsiveContainer>
  )
}

function formatDate (unixDate) {
  if (!unixDate || !isNumber(unixDate)) return ''
  return moment.unix(unixDate).endOf('month').format('MMM')
}

// From https://github.com/recharts/recharts/blob/ca4ca8262bde5a1fe0d021bfd514fbe5b318d147/src/util/ChartUtils.ts#L469
function getAxisDomain (axisDomains) {
  return axisDomains
    .reduce((result, entry) => [Math.min(result[0], entry[0], 0), Math.max(result[1], entry[1])], [
      Infinity,
      -Infinity
    ])
}

// From https://github.com/recharts/recharts/blob/ca4ca8262bde5a1fe0d021bfd514fbe5b318d147/src/util/ChartUtils.ts#L43
function getDomainOfDataByKeys ({ data, keys }) {
  const flattenData = flatMap(data, (entry) => sumBy(keys, (key) => get(entry, key)))
  const domain = flattenData.filter(entry => isNumber(entry) || parseFloat(entry))
  return domain.length ? [min(domain), max(domain)] : [Infinity, -Infinity]
}

function computeCashflowAxisDomain ({ accountBalanceAxisDomain, cashflowAxisDomain }) {
  const minBalance = accountBalanceAxisDomain[0]
  const maxBalance = accountBalanceAxisDomain[1]
  const maxCashflow = cashflowAxisDomain[1]

  const minCashflow = minBalance / (maxBalance / maxCashflow)

  return [minCashflow, cashflowAxisDomain[1]]
}

const getRoundPrecision = (max) => {
  if (max >= 1000000) return -4
  if (max >= 100000) return -3
  if (max >= 10000) return -2
  if (max >= 1000) return -1
  return 0
}

function getScale ({ min, max, numberOfTicks }) {
  const roundPrecision = getRoundPrecision(max)
  const step = floor(max / numberOfTicks)
  const upperZeroRange = range(numberOfTicks).map((i) => {
    return round(step * (i + 1), roundPrecision)
  })

  return [min, 0, ...upperZeroRange]
}

function useBalanceTicks ({ accountBalanceAxisDomain }) {
  return useMemo(() => {
    return getScale({
      min: accountBalanceAxisDomain[0],
      max: accountBalanceAxisDomain[1],
      numberOfTicks: 4
    })
  }, [accountBalanceAxisDomain])
}

function useCashflowAxisDomain ({ data, accountBalanceAxisDomain }) {
  return useMemo(() => {
    const cashflowDomains = [
      ['value.realisedCashin', 'value.expectedCashin'],
      ['value.realisedCashout', 'value.expectedCashout'],
      ['value.forecastedCashin'],
      ['value.forecastedCashout']
    ].map((keys) => getDomainOfDataByKeys({ data, keys }))

    const cashflowAxisDomain = getAxisDomain(cashflowDomains)

    return computeCashflowAxisDomain({ accountBalanceAxisDomain, cashflowAxisDomain })
  }, [data, accountBalanceAxisDomain])
}

function useBalanceAxisDomain ({ data }) {
  return useMemo(() => {
    const keys = [['value.realisedEndingBalance'], ['value.expectedEndingBalance'], ['value.forecastedEndingBalance']]
    const accountBalanceDomains = keys.map((keys) => getDomainOfDataByKeys({ data, keys }))
    return getAxisDomain(accountBalanceDomains)
  }, [data])
}

function useCashflowTicks ({ cashflowAxisDomain }) {
  return useMemo(() => {
    return getScale({
      min: cashflowAxisDomain[0],
      max: cashflowAxisDomain[1],
      numberOfTicks: 4
    })
  }, [cashflowAxisDomain])
}

// X AXIS
// Remember that for 1 month there is always 2 months : preMonth and Month
function useXAxisDomain ({ data }) {
  if (get(data, 'length', 0) === 0) return ['auto', 'auto']

  const firstMonth = data[0]
  const lastMonth = data[data.length - 1]
  const unixFirstMonthStart = moment.unix(firstMonth.unixMiddleOfMonth).startOf('month').unix()
  const unixLastMonthEnd = lastMonth.unixEndOfMonth

  return [unixFirstMonthStart, unixLastMonthEnd]
}

function useDateTicks ({ data }) {
  return useMemo(() => {
    if (get(data, 'length', 0) === 0) return []

    const midMonths = data.map((monthData) => monthData.unixMiddleOfMonth)

    return midMonths
  }, [data])
}

function useIndexByMiddleOfMonth ({ data }) {
  return useMemo(() => {
    if (get(data, 'length', 0) === 0) return {}
    const midMonths = data.reduce((acc, { unixMiddleOfMonth }, index) => ({ ...acc, [unixMiddleOfMonth]: index }), {})
    return midMonths
  }, [data])
}

function useDataWithUnixChartDates ({ data, realisedVsForecasted }) {
  return useMemo(() => {
    return (data || []).map(({ date, value }) => {
      const isCurrentOrFuture = moment(date, 'YYYY-MM').isSameOrAfter(startOfThisMonth)
      return {
        date,
        value: {
          ...value,
          forecastedCashin: (realisedVsForecasted || isCurrentOrFuture) ? value.forecastedCashin : null,
          forecastedCashout: (realisedVsForecasted || isCurrentOrFuture) ? value.forecastedCashout : null
        },
        ...getMonthDates(date)
      }
    })
  }, [data, realisedVsForecasted])
}

function useDataWithPreMonth ({ data }) {
  return useMemo(() => {
    if (!data || data.length === 0) return data

    const firstMonth = data[0]
    const date = moment(firstMonth.date, 'YYYY-MM').subtract(1, 'month').format('YYYY-MM')
    const preMonth = {
      date,
      value: {
        realisedEndingBalance: firstMonth.value.realisedStartingBalance
      },
      ...getMonthDates(date)
    }

    const newData = [preMonth, ...data]
    const firstMonthWithForecastedEndingBalanceIndex = newData.findIndex((month) => !!month.value.forecastedStartingBalance)

    if (firstMonthWithForecastedEndingBalanceIndex > 0) {
      const beforeMonthData = newData[firstMonthWithForecastedEndingBalanceIndex - 1]
      const monthData = newData[firstMonthWithForecastedEndingBalanceIndex]
      newData[firstMonthWithForecastedEndingBalanceIndex - 1] = {
        ...beforeMonthData,
        value: {
          ...beforeMonthData.value,
          forecastedEndingBalance: monthData.value.forecastedStartingBalance
        }
      }
    }

    return newData
  }, [data])
}
const getMonthDates = (date) => {
  const unixStartOfMonth = moment(date, 'YYYY-MM').unix()

  return {
    unixStartOfMonth,
    unixMiddleOfMonth: getUnixMiddleOfMonth(unixStartOfMonth),
    unixEndOfMonth: getUnixEndOfMonth(unixStartOfMonth),
    unixPresentOrEndOfMonth: getUnixEndOfMonth(unixStartOfMonth, { keepPresentForCurrentMonth: true }),
    unixPresentForPastOrEndOfMonth: getUnixEndOfMonth(unixStartOfMonth, { keepPresentForPastMonths: true })
  }
}
