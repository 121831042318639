import { gql } from '@apollo/client'

export const CREATE_CHECKOUT_SESSION = gql`
  mutation createCheckoutSession(
    $plan: Plan!
    $recurrence: SubscriptionRecurrence!
  ) {
    createCheckoutSession(
      plan: $plan
      recurrence: $recurrence
    )
  }
`

export const UPGRADE_SUBSCRIPTION = gql`
  mutation upgradeSubscription(
    $newPlan: Plan!
    $recurrence: SubscriptionRecurrence!
    $promotionCode: String
  ) {
    upgradeSubscription(
      newPlan: $newPlan
      recurrence: $recurrence
      promotionCode: $promotionCode
    ) {
      id
      plan
      includedBanksQuantity
      includedUsersQuantity
      hasAccessToVatDetails
      hasAccessToTransactionsExport
      hasAccessToBatchReceiptsDownload
      hasAccessToScenarioConfiguration
      hasAccessToManualTriggerSyncAccount
    }
  }
`
