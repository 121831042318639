import { gql } from '@apollo/client'

export const GET_SECURED_QUIPU_INVOICE_DOCUMENT_URL = gql`
  query getSecuredQuipuInvoiceDocumentUrl($invoiceId: ID!) {
    getSecuredQuipuInvoiceDocumentUrl(invoiceId: $invoiceId)
  }
`
export const GET_SECURED_QUIPU_QUOTE_DOCUMENT_URL = gql`
  query getSecuredQuipuQuoteDocumentUrl($quoteId: ID!) {
    getSecuredQuipuQuoteDocumentUrl(quoteId: $quoteId)
  }
`
