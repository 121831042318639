import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Badge from 'design/Badge'
import { useTranslation } from 'react-i18next'
// import Button from 'design/Button'

UserRoleBadge.propTypes = {
  role: PropTypes.string
}

function UserRoleBadge ({ role }) {
  const { t } = useTranslation()

  const { type, label } = useMemo(() => {
    switch (role) {
      case 'owner':
        return { type: 'warning', label: t('users.userRoleBadge.owner') }
      case 'admin':
        return { type: 'primary', label: t('users.userRoleBadge.admin') }
      case 'collaborator':
        return { type: 'success', label: t('users.userRoleBadge.collaborator') }
      case 'cfo':
        return { type: 'info', label: t('users.userRoleBadge.cfo') }
      case 'accountant':
        return { type: 'info', label: t('users.userRoleBadge.accountant') }
      case 'investor':
        return { type: 'info', label: t('users.userRoleBadge.investor') }
      case 'banker':
        return { type: 'info', label: t('users.userRoleBadge.banker') }
      default:
        return { type: 'info', label: role }
    }
  }, [role, t])

  return (
    <Badge
      type={type}
      label={label}
    />
  )
}

export default UserRoleBadge
