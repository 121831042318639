import { get } from 'lodash'

export const getTotalMonthInfo = (month, key, transactionsTotal) => {
  const monthTotal = get(transactionsTotal, 'monthlyTotal', []).find((monthTotal) => {
    return monthTotal.month === month
  })

  return get(monthTotal, key)
}

export const getTypeTotalMonthInfo = (month, key, typeTotal) => {
  const monthTotal = get(typeTotal, 'totalPerMonth', []).find((monthTotal) => {
    return monthTotal.month === month
  })

  return get(monthTotal, key)
}
