import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Modal, Tooltip, Dropdown, message } from 'antd'

import { TrashIcon } from '@heroicons/react/24/solid'
import ActionsIcon from 'design/ActionsIcon'
import { useMutation } from '@apollo/client'
import { DELETE_USER } from 'graphql/users'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'

const { confirm } = Modal

UserActions.propTypes = {
  user: PropTypes.object,
  ownerId: PropTypes.string,
  onDelete: PropTypes.func
}

export default function UserActions ({ user, ownerId, onDelete }) {
  const { t } = useTranslation()
  const [deleteUser, { loading }] = useMutation(DELETE_USER, {
    variables: { userIdToDelete: user?.id },
    onCompleted: onDelete,
    onError: (e) => {
      const errorCode = get(e, 'graphQLErrors.0.extensions.code')

      switch (errorCode) {
        case 'ONLY_ADMIN_CAN_DELETE_USER':
          message.warning(t('users.userActions.onlyAdminCanDelete'))
          break
        case 'USER_CANNOT_SELF_DELETE':
          message.warning(t('users.userActions.cannotDeleteOwnAccount'))
          break
        default:
          message.error(t('shared.anErrorHasOccured'))
          break
      }
    }
  })

  const handleMenuClick = useCallback(({ key }) => {
    switch (key) {
      case 'delete':
        confirm({
          title: t('users.userActions.askForDeleteConfirmation', { firstName: user?.firstName, lastName: user?.lastName }),
          content: t('users.userActions.loginDataWillBeDeleted'),
          okType: 'danger',
          okText: t('shared.delete'),
          cancelText: t('shared.cancel'),
          width: '512px',
          maskClosable: true,
          onOk: deleteUser
        })
        break
      default:
        console.warn(`${key} button not handled`)
    }
  }, [deleteUser, t, user?.firstName, user?.lastName])

  if (user.id === ownerId) return null

  return (
    <div className='flex flex-row items-center justify-between'>
      <Dropdown
        disabled={loading}
        menu={{
          onClick: handleMenuClick,
          items: [
            {
              key: 'delete',
              className: 'text-error',
              label: (
                <Tooltip
                  className='flex items-center'
                >
                  <TrashIcon className='w-4 h-4 mr-1' /> {t('shared.delete')}
                </Tooltip>
              )
            }
          ]
        }}
      >
        <ActionsIcon loading={loading} />
      </Dropdown>
    </div>
  )
}
