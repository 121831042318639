import React, { useCallback, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import Spinner from 'design/Spinner'
import PopoverTextAreaInput from 'components/PopoverTextAreaInput'
import { useMutation } from '@apollo/client'
import { COMMENT_SCENARIO_FORECAST } from 'graphql/scenario-forecasts'
import CashflowSheetContext from 'contexts/CashflowSheetContext'
import classNames from 'classnames'
import { isUncategorized } from 'utils/categories.js'

ScenarioCommentInput.propTypes = {
  sheetDate: PropTypes.object,
  scenarioId: PropTypes.string,
  categoryId: PropTypes.string,
  comment: PropTypes.string,
  maskEmpty: PropTypes.bool,
  className: PropTypes.string
}

export default function ScenarioCommentInput ({ sheetDate, scenarioId, categoryId, comment, maskEmpty, className }) {
  const { t } = useTranslation()
  const [popoverVisible, setPopoverVisible] = useState(false)
  const { refetchCashflowSheet } = useContext(CashflowSheetContext)

  const [commentScenarioForecast, { loading }] = useMutation(COMMENT_SCENARIO_FORECAST)

  const handleOnSave = useCallback(({ value, successCallback }) => {
    commentScenarioForecast({
      variables: {
        input: {
          date: sheetDate.momentDate.format('YYYY-MM-DD'),
          scenarioId,
          categoryId,
          comment: value
        }
      },
      onCompleted: () => {
        refetchCashflowSheet()
        successCallback()
      }
    })
  }, [commentScenarioForecast, sheetDate, scenarioId, categoryId, refetchCashflowSheet])

  if (isUncategorized(categoryId)) return null

  return (
    <PopoverTextAreaInput
      value={comment}
      placeholder={t('dashboard.scenarioCommentInput.comment')}
      onSave={handleOnSave}
      onVisibleChange={setPopoverVisible}
      className={classNames('group-hover:opacity-100', { 'opacity-0': (maskEmpty && !comment && !popoverVisible) }, className)}
      // className='rc-forecast-comment'
    >
      {(loading && (
        <div className='w-5 h-5 flex items-center justify-center'><Spinner className='m-0.5 text-primary' /></div>
      )) || (
        <ChatBubbleBottomCenterTextIcon
          onClick={(e) => { e.stopPropagation() }}
          className='w-5 h-5 hover:text-primary cursor-pointer'
        />
      )}
    </PopoverTextAreaInput>
  )
}
