import { Modal, Typography } from 'antd'
import Button from 'design/Button'
import ButtonLink from 'design/ButtonLink'
import SubscriptionType from 'enums/SubscriptionType.js'
import useFreeTrialEnd from 'hooks/subscriptions/useFreeTrialEnd.js'
import useSubscriptionRedirect from 'hooks/subscriptions/useSubscriptionRedirect.js'
import useAuthenticatedContext from 'hooks/useAuthenticatedContext'
import { get } from 'lodash'
import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { openLiveChat } from 'utils/contact'

export default () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { company, user } = useAuthenticatedContext()
  const { isOverFreeTrial, endGracePeriodDate } = useFreeTrialEnd()
  const firstName = get(user, 'firstName')
  const subscriptionType = get(company, 'subscriptionType')
  const { quipuSubscriptionUrl } = useSubscriptionRedirect()

  const shouldDisplayModal = useMemo(() => {
    if (history.location.pathname === '/subscribe' ||
    history.location.pathname === '/users') return false

    return isOverFreeTrial
  }, [isOverFreeTrial, history.location.pathname])

  if (subscriptionType === SubscriptionType.QUIPU) {
    return (
      <Modal
        width={620}
        open={shouldDisplayModal}
        closable={false}
        centered
        footer={null}
      >
        <div className='flex flex-col p-7'>
          <Typography.Title level={4} align='center'>
            {t('component.freeTrialEndPopup.titleQuipu')}
          </Typography.Title>

          <div className='mt-4'>
            {t('component.freeTrialEndPopup.descriptionQuipu')}
            <br />
            <br />
            <div className='w-full flex flex-col items-center mt-4'>
              <Button onClick={() => window.location.assign(quipuSubscriptionUrl)} primary label={t('component.freeTrialEndPopup.displayPlanQuipu')} />
            </div>

          </div>
        </div>
      </Modal>
    )
  }

  return (
    <Modal
      open={shouldDisplayModal}
      closable={false}
      centered
      footer={null}
    >
      <Typography.Title level={4} align='center'>
        {t('component.freeTrialEndPopup.trialFinished', { firstName })}
      </Typography.Title>

      {(user?.role !== 'admin' && (
        <div>
          <Trans
            i18nKey='component.freeTrialEndPopup.pleaseSubscribeBy'
            components={{
              Span: <span className='font-bold' />
            }}
            values={{ endDateStr: endGracePeriodDate }}
          />
          <div>{t('component.freeTrialEndPopup.pleaseContactAnAdmin')}</div>
        </div>
      )) || (
        <div>
          <Trans
            i18nKey='component.freeTrialEndPopup.pleaseSubscribeBy'
            components={{
              Span: <span className='font-bold' />
            }}
            values={{ endDateStr: endGracePeriodDate }}
          />
          {(subscriptionType === SubscriptionType.DIRECT && (
            <div className='w-full flex flex-col items-center mt-4'>
              <Button onClick={() => history.push('/subscribe')} primary label={t('component.freeTrialEndPopup.chooseAPlan')} />
            </div>
          )) || (
            <span>
              {' '}{t('component.freeTrialEndPopup.pleaseContactSellsy')}
              <br />
              <br />
              <Trans
                i18nKey='component.freeTrialEndPopup.liveChatMessage'
                components={{ ButtonLink: <ButtonLink onClick={openLiveChat} label={t('component.freeTrialEndPopup.contactUs')} /> }}
              />
            </span>
          )}

          <br />
          <br />
          <Link to='/users'>{t('component.freeTrialEndPopup.manageUsers')}</Link>
        </div>
      )}
    </Modal>
  )
}
