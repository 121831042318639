import { message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { Redirect } from 'react-router-dom'

import AuthActions from 'actions/AuthActions'
import Spinner from 'design/Spinner'
import useSearchParams from 'hooks/useSearchParams'
import { get } from 'lodash'

export default () => {
  const { t } = useTranslation()
  const isAuthenticated = useSelector(state => get(state, 'app.isAuthenticated'))
  const [wasAlreadyAuthenticated] = useState(isAuthenticated)
  const { search } = useLocation()
  const history = useHistory()
  const { code } = useSearchParams()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isAuthenticated && code) {
      dispatch(AuthActions.authenticateSellsyUserOrSignUp({
        authorizationCode: code,
        onError: (e) => {
          const errorCode = e?.response?.data?.code || get(e, 'response.data.details.0.path', []).join('.')

          switch (errorCode) {
            case 'SELLSY_SSO_SIGN_UP_NOT_PERMITTED':
              message.warning({ content: t('sellsyOAuthScreen.error.SELLSY_SSO_SIGN_UP_NOT_PERMITTED'), duration: 20 })
              break
            case 'SELLSY_SSO_SIGN_IN_NOT_PERMITTED':
              message.warning({ content: t('sellsyOAuthScreen.error.SELLSY_SSO_SIGN_IN_NOT_PERMITTED'), duration: 20 })
              break
            case 'SELLSY_CASHFLOW_MODULE_IS_INACTIVE':
              message.warning({ content: t('sellsyOAuthScreen.error.SELLSY_CASHFLOW_MODULE_IS_INACTIVE'), duration: 20 })
              break
            case 'INCLUDED_USERS_QUANTITY_REACHED':
              message.warning({ content: t('sellsyOAuthScreen.error.INCLUDED_USERS_QUANTITY_REACHED'), duration: 20 })
              break
            case 'EMAIL_ALREADY_USED':
              message.warning({ content: t('sellsyOAuthScreen.error.EMAIL_ALREADY_USED'), duration: 20 })
              break
            case 'country':
              message.warning({ content: t('sellsyOAuthScreen.error.country'), duration: 20 })
              break
            default:
              message.error({ content: t('sellsyOAuthScreen.error.default'), duration: 20 })
              break
          }

          history.replace('/signin')
        }
      }))
    }
  }, [isAuthenticated, dispatch, code, history, t])

  if (isAuthenticated && !wasAlreadyAuthenticated) return (<Redirect to='/' />)

  if (isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: '/integrations/sellsy',
          search
        }}
      />
    )
  }

  return (
    <div className='flex flex-col w-full h-full items-center justify-center'>
      <div className='mb-8 -mt-14 w-full flex flex-row justify-center animate-fade-in'>
        <img src='/logo_sellsy_tresorerie.svg' alt='Sellsy Trésorerie logo' className='rc-signin-logo' />
      </div>

      <Spinner className='w-6 h-6 text-primary' />
    </div>
  )
}
