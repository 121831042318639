import { LIST_INVOICE_EXISTING_EXTERNAL_IDS, IMPORT_INVOICES_FROM_UPLOAD } from 'graphql/invoices.js'
import client from 'utils/apollo-client.js'
import useAuthenticatedContext from 'hooks/useAuthenticatedContext.js'
import { createImport } from 'utils/import.js'
import { convertStringDateFrToIso } from 'utils/dates.js'
import { validateDate, validateAmount, validateAmountExcludingVat, validateString, validateId } from 'utils/import-validations.js'
import { parseNumber } from 'utils/number.js'
import i18next from 'i18next'
const { t } = i18next

const fieldsKeys = {
  invoiceNumber: 'invoiceNumber',
  thirdPartyName: 'thirdPartyName',
  amount: 'amount',
  amountExcludingVat: 'amountExcludingVat',
  issueDate: 'issueDate',
  dueDate: 'dueDate',
  expectedDate: 'expectedDate'
}

const sheet = () => {
  return {
    name: t('import.sheets.invoices.name'),
    fields: [
      {
        key: fieldsKeys.invoiceNumber,
        type: 'string',
        label: t('import.sheets.invoices.fields.invoiceNumber')
      },
      {
        key: fieldsKeys.thirdPartyName,
        type: 'string',
        label: t('import.sheets.invoices.fields.thirdPartyName')
      },
      {
        key: fieldsKeys.amount,
        type: 'number',
        label: t('import.sheets.invoices.fields.amount')
      },
      {
        key: fieldsKeys.amountExcludingVat,
        type: 'number',
        label: t('import.sheets.invoices.fields.amountExcludingVat')
      },
      {
        key: fieldsKeys.issueDate,
        type: 'string',
        label: t('import.sheets.invoices.fields.issueDate')
      },
      {
        key: fieldsKeys.dueDate,
        type: 'string',
        label: t('import.sheets.invoices.fields.dueDate')
      },
      {
        key: fieldsKeys.expectedDate,
        type: 'string',
        label: t('import.sheets.invoices.fields.expectedDate')
      }
    ]
  }
}

async function fetchExistingsExternalIds (externalIds) {
  const result = await client.query({
    query: LIST_INVOICE_EXISTING_EXTERNAL_IDS,
    variables: { type: 'cashin', externalIds },
    fetchPolicy: 'no-cache'
  })

  return result.data.listInvoiceExistingExternalIds.existingsExternalIds
}

async function onValidateRecords ({ contextParams, records }) {
  const invoiceNumbers = records.map(record => record.get(fieldsKeys.invoiceNumber))
  const existingsIds = await fetchExistingsExternalIds(invoiceNumbers)

  return records.map((record) => {
    const params = { record, contextParams }
    validateId({ ...params, fieldName: fieldsKeys.invoiceNumber, existingsIds })
    validateString({ ...params, fieldName: fieldsKeys.thirdPartyName })
    validateAmount({ ...params })
    validateAmountExcludingVat({ ...params })
    validateDate({ ...params, fieldName: fieldsKeys.issueDate })
    validateDate({ ...params, fieldName: fieldsKeys.dueDate })
    validateDate({ ...params, fieldName: fieldsKeys.expectedDate })

    return record
  })
}

async function onImportRecords ({ records, contextParams, spaceId }) {
  const invoicesParams = records.map(record => {
    const element = record.values
    const amount = parseNumber(element.amount.value)
    const amountExcludingVat = parseNumber(element.amountExcludingVat.value)

    return {
      externalImportId: spaceId,
      type: contextParams.type,
      invoiceNumber: element.invoiceNumber.value,
      thirdPartyName: element.thirdPartyName.value,
      amount,
      amountExcludingVat,
      issueDate: convertStringDateFrToIso(element.issueDate.value),
      dueDate: convertStringDateFrToIso(element.dueDate.value),
      expectedDate: convertStringDateFrToIso(element.expectedDate.value)
    }
  })
  await client.mutate({
    mutation: IMPORT_INVOICES_FROM_UPLOAD,
    variables: {
      invoicesParams
    }
  })
}

export default function useInvoiceImporter ({ type, refetchInvoices }) {
  const { user } = useAuthenticatedContext()

  const createImportOption = {
    sheet: sheet(),
    onValidateRecords,
    onImportRecords,
    onClose: refetchInvoices,
    contextParams: {
      type
    },
    user
  }

  const triggerImport = async () => {
    await createImport(createImportOption)
  }

  return { triggerImport }
}
