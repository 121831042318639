// import React from 'react'
// import banksInfos from 'utils/banks-infos.json'

// const banks = Object.values(banksInfos)

const TestScreen = () => {
  // return (
  //   <div className='grid grid-flow-row auto-rows-max grid-cols-12 grid-rows-3 gap-4'>
  //     {banks.map((bank, i) => (
  //       <img key={i} src={bank.faviconUrl} />
  //     ))}
  //   </div>
  // )

  return null
}

export default TestScreen
