// This file is used to export the theme module as default export.
// So this file will be used to import theme in the app.
import defaultTheme from './default.json'
import quipuTheme from './quipu.json'

const selectedTheme = selectTheme(process.env.REACT_APP_TENANT)

const theme = {
  ...defaultTheme,
  ...selectedTheme,
  colors: {
    ...defaultTheme.colors,
    ...selectedTheme.colors
  }
}

function selectTheme (tenant) {
  switch (tenant) {
    case 'QUIPU':
      return quipuTheme
  }

  return { colors: {} }
}

export default theme
