import React, { useState, useCallback } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { CUSTOMIZE_ACCOUNT_NAME } from 'graphql/accounts'
import { LIST_BANK_CONNECTIONS_WITH_ACCOUNTS } from 'graphql/bank-connections.js'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import TextEdit from 'design/TextEdit'
import featureConfig from 'config/features.js'

AccountCustomNameInput.propTypes = {
  account: PropTypes.object
}

function AccountCustomNameInput ({ account }) {
  const { t } = useTranslation()
  const [value, setValue] = useState(account?.customName)
  const [refreshBankConnections, { loading: queryLoading }] = useLazyQuery(LIST_BANK_CONNECTIONS_WITH_ACCOUNTS)

  const [customizeAccountName, { loading: mutationLoading }] = useMutation(CUSTOMIZE_ACCOUNT_NAME, {
    variables: { id: account.id, customName: value },
    onCompleted: refreshBankConnections
  })

  const handleTextChanged = useCallback((customName) => {
    setValue(customName)
    customizeAccountName({ variables: { id: account.id, customName } })
  }, [account, customizeAccountName])

  const originalName = get(account, 'originalName') || get(account, 'accountNumber')
  const name = get(account, 'customName') || get(account, 'originalName') || get(account, 'accountNumber')

  if (featureConfig.accountEditNameEnabled) {
    return (
      <TextEdit
        text={name}
        resetText={originalName}
        onTextChanged={handleTextChanged}
        loading={queryLoading || mutationLoading}
        labelEditButton={t('component.accountCustomNameInput.modifyName')}
        labelResetButton={t('component.accountCustomNameInput.restoreOriginalName')}
        className='font-bold'
      />
    )
  }

  return (
    <div className='font-bold break-all'>
      {name}
    </div>
  )
}

export default AccountCustomNameInput
