import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import RecurringPaymentExpectedTransactionsTable from './RecurringPaymentExpectedTransactionsTable.js'

const RecurringPaymentExpectedTransactionsList = ({ recurringPayment }) => {
  const { t } = useTranslation()
  return (
    <div className='w-full max-w-4xl'>
      <div className='text-lg mb-2'>
        <Typography.Text className='text-lg' strong>{t('shared.expectedTransactions')}</Typography.Text>
      </div>
      <RecurringPaymentExpectedTransactionsTable recurringPayment={recurringPayment} />
    </div>
  )
}

RecurringPaymentExpectedTransactionsList.propTypes = {
  recurringPayment: PropTypes.object
}

export default RecurringPaymentExpectedTransactionsList
