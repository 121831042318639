
import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Drawer, Tooltip } from 'antd'
import { DocumentTextIcon } from '@heroicons/react/24/solid'
import { get } from 'lodash'
import cx from 'classnames'

import ReconciliationDrawer from 'components/reconciliation/ReconciliationDrawer'
import Title from 'design/Title'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { useTranslation } from 'react-i18next'
import featureConfig from 'config/features.js'

ReconciliationDrawerContainer.defaultProps = {
  lean: false
}

ReconciliationDrawerContainer.propTypes = {
  transaction: PropTypes.object
}

export default function ReconciliationDrawerContainer ({ transaction }) {
  const { t } = useTranslation()
  const [drawerVisible, setDrawerVisible] = useState(false)

  const isReconciliated = useMemo(() => get(transaction, 'isReconciliated', false), [transaction])

  if (!featureConfig.invoicesEnabled) return null

  return (
    <>
      <Drawer
        title={<Title label={t('component.reconciliationDrawerContainer.reconciliateInvoices')} />}
        closeIcon={<XMarkIcon className='w-5 h-5' />}
        width={540}
        placement='right'
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
      >
        <ReconciliationDrawer transaction={transaction} />
      </Drawer>

      <Tooltip mouseEnterDelay={0.4} title={t('component.reconciliationDrawerContainer.reconciliateInvoices')}>
        <DocumentTextIcon
          onClick={() => setDrawerVisible(true)}
          className={cx('w-6 h-6 cursor-pointer hover:opacity-50', {
            'text-primary': isReconciliated,
            'text-gray-300': !isReconciliated
          })}
        />
      </Tooltip>
    </>
  )
}
