import { useMutation } from '@apollo/client'
import { Dropdown, Tooltip } from 'antd'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'
import { BanknotesIcon as BanknotesIconOutline } from '@heroicons/react/24/outline'
import { BanknotesIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'
import ActionsIcon from 'design/ActionsIcon'
import { MARK_EXPECTED_TRANSACTIONS_AS_INVOICED } from 'graphql/expected-transactions'
import { useTranslation } from 'react-i18next'
import { IGNORE_ORDERS } from 'graphql/orders.js'
import AccountingDocumentPreviewButton from 'components/accounting-documents/AccountingDocumentPreviewButton.js'

OrderActions.propTypes = {
  order: PropTypes.object
}

export default function OrderActions ({ order }) {
  const { t } = useTranslation()
  const allExpectedTransactionsAreInvoiced = useMemo(() => {
    const nonInvoicedExpTxn = order.expectedTransactions.find((expTxn) => !expTxn.paymentDate)
    return !nonInvoicedExpTxn
  }, [order])

  const expectedTransactionIds = useMemo(() => order.expectedTransactions.map((expTxn) => expTxn.id), [order])

  const [ignoreOrders, { loading: ignoreLoading }] = useMutation(IGNORE_ORDERS, {
    variables: { input: { orderIds: [order.id], cancel: order.ignored } }
  })

  const [markExpectedTransactionAsInvoiced, { loading: markAsInvoicedLoading }] = useMutation(MARK_EXPECTED_TRANSACTIONS_AS_INVOICED, {
    variables: { input: { expectedTransactionIds, cancel: allExpectedTransactionsAreInvoiced } }
  })

  const handleMenuClick = useCallback(({ key }) => {
    switch (key) {
      case 'markAsInvoiced':
        markExpectedTransactionAsInvoiced()
        break
      case 'ignore':
        ignoreOrders()
        break
      default:
        console.warn(`${key} button not handled`)
    }
  }, [ignoreOrders, markExpectedTransactionAsInvoiced])

  const loading = useMemo(() => (ignoreLoading || markAsInvoicedLoading), [ignoreLoading, markAsInvoicedLoading])

  return (
    <div className='flex flex-row items-center justify-end w-full space-x-1'>
      <AccountingDocumentPreviewButton accountingDocument={order} />
      <Dropdown
        disabled={loading}
        placement='bottomRight'
        menu={{
          onClick: handleMenuClick,
          items: [
            {
              key: 'ignore',
              label: (
                <Tooltip
                  title={t('component.orderActions.tooltip.ignoredOrder')}
                  placement='left'
                  className='flex items-center'
                >
                  {(order.ignored && <EyeIcon className='w-4 h-4 mr-2 inline' />) || <EyeSlashIcon className='w-4 h-4 mr-2 inline' />}
                  {order.ignored ? t('shared.unIgnore') : t('shared.ignore')}
                </Tooltip>
              )
            },
            {
              key: 'markAsInvoiced',
              label: (
                <Tooltip
                  title={t('component.quoteActions.invoicedExpectedTransactionNotTakenIntoAccountAnymore')}
                  placement='left'
                  className='flex items-center'
                >
                  {allExpectedTransactionsAreInvoiced ? <BanknotesIconOutline className='w-4 h-4 mr-2 inline' /> : <BanknotesIcon className='w-4 h-4 mr-2 inline' />}
                  {allExpectedTransactionsAreInvoiced ? t('component.quoteActions.unmarkAsInvoiced') : t('component.quoteActions.markAsInvoiced')}
                </Tooltip>
              )
            }
          ]
        }}
      >
        <ActionsIcon loading={loading} />
      </Dropdown>
    </div>
  )
}
