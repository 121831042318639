import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal, Typography } from 'antd'
import { get } from 'lodash'
import moment from 'moment-timezone'

import Button from 'design/Button'
import { LIST_BANK_CONNECTIONS_WITH_ACCOUNTS } from 'graphql/bank-connections.js'
import { useQuery } from '@apollo/client'
import useManageBankConnections from 'hooks/useManageBankConnections.js'
import { useTranslation, Trans } from 'react-i18next'

export default function FailedBankConnectionPopup () {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const { data } = useQuery(LIST_BANK_CONNECTIONS_WITH_ACCOUNTS, { fetchPolicy: 'cache-only' })

  const hasFailedConnections = useMemo(() => {
    const bankConnections = data?.listBankConnectionsWithAccounts || []
    return bankConnections.filter((bankCo) => bankCo?.status === 'ACTION_REQUIRED').length > 0
  }, [data])

  const lastAccountManagementAt = useSelector((state) => get(state, 'app.lastAccountManagementAt'))
  const isAuthenticated = useSelector(state => get(state, 'app.isAuthenticated'))

  useEffect(() => {
    if (!hasFailedConnections) return

    if (lastAccountManagementAt) {
      const anHourAgo = moment().subtract(1, 'hour')
      const isMoreThanHourAgo = moment(lastAccountManagementAt).isBefore(anHourAgo)
      if (isMoreThanHourAgo) setVisible(true)
    } else {
      setVisible(true)
    }
  }, [hasFailedConnections, lastAccountManagementAt, setVisible])

  const { redirectToManageAccountsUrl, redirectingToManageAccountsUrl } = useManageBankConnections()

  if (!isAuthenticated) return null

  return (
    <Modal
      open={visible}
      onCancel={() => setVisible(false)}
      maskClosable={false}
      centered
      footer={null}
    >
      <Typography.Title level={4}>
        {t('component.failedBankConnectionPopup.title01')}
        <br />
        {t('component.failedBankConnectionPopup.title02')}
      </Typography.Title>

      <Typography.Paragraph>
        {/* {t('component.failedBankConnectionPopup.paragraph')} */}
        <Trans
          i18nKey='component.failedBankConnectionPopup.paragraph' components={{
            Br: <br />
          }}
        />
      </Typography.Paragraph>

      <div className='w-full flex flex-col items-center'>
        <Button loading={redirectingToManageAccountsUrl} onClick={redirectToManageAccountsUrl} primary label={t('component.failedBankConnectionPopup.reconnectMyBank')} />
        <Typography.Text style={{ marginTop: 10 }}>{t('component.failedBankConnectionPopup.noPanic')}</Typography.Text>
      </div>
    </Modal>
  )
}
