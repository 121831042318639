import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Table, Typography } from 'antd'
import { get } from 'lodash'

import { c } from 'utils/currencies'
import CategorizeTransaction from './CategorizeTransaction'
import TransactionVatSelect from './TransactionVatSelect'
import RowCategoryBorder from 'components/categories/RowCategoryBorder'
import TransactionPartActions from './TransactionPartActions'
import i18next from 'i18next'

const { t } = i18next

const { Text } = Typography

const generateColumns = (type) => {
  const columns = [
    {
      title: t('shared.amountInclTax'),
      dataIndex: 'amount',
      key: 'amount',
      ellipsis: true,
      width: '140px',
      render: amount => <Text>{c(amount)}</Text>
    },
    {
      title: t('shared.VAT'),
      dataIndex: '',
      key: 'vat',
      width: '172px',
      render: (transaction) => (
        <TransactionVatSelect transaction={transaction} />
      )
    },
    {
      title: t('shared.category'),
      dataIndex: '',
      key: 'validation',
      // width: '140px',
      render: (transaction) => (
        <div>
          <RowCategoryBorder categoryId={transaction.categoryId} />

          <CategorizeTransaction
            type={type}
            transaction={transaction}
            lean
          />
        </div>
      )
    },
    {
      title: '',
      dataIndex: '',
      key: 'more',
      width: '56px',
      render: (transaction) => {
        return <TransactionPartActions transaction={transaction} />
      }
    }
  ]

  return columns
}

const TransactionPartTable = ({ type, ...rest }) => {
  const columns = useMemo(() => generateColumns(type), [type])

  return (
    <Table
      columns={columns}
      rowKey={transaction => transaction.id}
      rowClassName={getRowClassName}
      className='rc-transaction-table'
      pagination={false}
      {...rest}
    />
  )
}

function getRowClassName (transaction) {
  const classNames = ['rc-absolute-row']

  if (get(transaction, 'ignored')) {
    classNames.push('rc-ignored-transaction')
  }

  return classNames.join(' ')
}

TransactionPartTable.propTypes = {
  type: PropTypes.oneOf(['cashin', 'cashout'])
}

export default TransactionPartTable
