import React, { useCallback, useState, useContext } from 'react'
import { Popover, Radio, Tooltip } from 'antd'
import PropTypes from 'prop-types'

import ButtonLink from 'design/ButtonLink.js'
import { BoltIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import Button from 'design/Button.js'
import { CHECK_EXISTS_SCENARIO_FORECAST, INITIALIZE_FORECAST_FROM_REALISED } from 'graphql/scenario-forecasts.js'
import { useMutation, useQuery } from '@apollo/client'
import confirm from 'antd/lib/modal/confirm.js'
import CashflowSheetContext from 'contexts/CashflowSheetContext'
import { Trans, useTranslation } from 'react-i18next'

InitializeScenarioForecastButton.propTypes = {
  scenarioId: PropTypes.string
}

export default function InitializeScenarioForecastButton ({ scenarioId }) {
  const { t } = useTranslation()
  const { refetchCashflowSheet } = useContext(CashflowSheetContext)

  const forecastExistsRequest = useQuery(CHECK_EXISTS_SCENARIO_FORECAST, {
    variables: {
      request: {
        scenarioId: scenarioId
      }
    },
    onCompleted: (data) => { setHasForecast(data.checkExistsScenarioForecast) }
  })

  const [initializeForecastFromRealised, { loading }] = useMutation(INITIALIZE_FORECAST_FROM_REALISED, {
    onCompleted: () => {
      forecastExistsRequest.refetch()
      refetchCashflowSheet()
    }
  })

  const [hasForecast, setHasForecast] = useState(null)
  const [open, setOpen] = useState(false)
  const handleOnOpenChange = open => setOpen(open)

  const [forecastBehaviour, setForecastBehaviour] = useState('AVERAGE_LAST_THREE_MONTHS')

  const saveForecastFromRealised = useCallback(() => {
    initializeForecastFromRealised({
      variables: {
        input: {
          scenarioId,
          forecastBehaviour
        }
      }
    })
  }, [initializeForecastFromRealised, scenarioId, forecastBehaviour])

  const onSubmit = useCallback(() => {
    if (hasForecast) {
      confirm({
        title: t('dashboard.initializeScenarioForecastButton.warning'),
        okType: 'danger',
        okText: t('shared.confirm'),
        cancelText: t('shared.cancel'),
        maskClosable: true,
        zIndex: 1500,
        onOk () {
          saveForecastFromRealised()
        }

      })
    } else {
      setOpen(false)
      saveForecastFromRealised()
    }
  }, [hasForecast, t, saveForecastFromRealised])

  const choiceForecastBehaviour = (
    <div className='px-2 py-2 flex flex-col space-y-2'>
      <p className='w-full flex flex-row text-center justify-between font-bold mb-2'>
        <Trans
          i18nKey='dashboard.initializeScenarioForecastButton.selectDesc'
        />
      </p>
      <Radio.Group
        className='px-2 py-2 flex flex-col space-y-2'
        value={forecastBehaviour} onChange={(e) => setForecastBehaviour(e.target.value)}
      >
        <Tooltip
          placement='left'
          title={t('dashboard.initializeScenarioForecastButton.averageLastThreeMonthDesc')}
        >
          <Radio value='AVERAGE_LAST_THREE_MONTHS'>{t('dashboard.initializeScenarioForecastButton.averageLastThreeMonthLabel')}</Radio>
        </Tooltip>
        <Tooltip
          placement='left'
          title={t('dashboard.initializeScenarioForecastButton.exactLastTwelveMonthDesc')}
        >
          <Radio value='SAME_MONTH_PREVIOUS_YEAR'>{t('dashboard.initializeScenarioForecastButton.exactLastTwelveMonthLabel')}</Radio>
        </Tooltip>
      </Radio.Group>

      <Button
        primary
        disabled={loading}
        loading={loading}
        label={t('dashboard.forecastAssistant.computeAndSave')}
        onClick={onSubmit}
      />
    </div>
  )

  return (
    <div>
      <Popover
        overlayClassName='rc-popup-forecast '
        open={open}
        onOpenChange={handleOnOpenChange}
        content={choiceForecastBehaviour} placement='bottom' trigger='click'
      >
        <Tooltip
          placement='left'
          title={t('dashboard.initializeScenarioForecastButton.tooltipTitle')}
        >
          <ButtonLink
            className='w-full flex justify-center items-center px-2'
            label={<BoltIcon className={classNames('w-5 h-5 cursor-pointer', { 'animate-pulse': !hasForecast })} />}
            disabled={loading || forecastExistsRequest.loading}
            loading={loading || forecastExistsRequest.loading}
            displaySpinner={loading || forecastExistsRequest.loading}
          />
        </Tooltip>
      </Popover>
    </div>

  )
}
