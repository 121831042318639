import React from 'react'
import { Layout, Menu, Typography } from 'antd'
import { useLocation, useHistory, Redirect, Route, Switch } from 'react-router'
import { useTranslation } from 'react-i18next'

import ManageCategories from 'components/categories/ManageCategories'

export default () => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()

  return (
    <Layout>
      <Layout.Header className='rc-header'>
        <Typography.Title level={4} className='rc-header-title'>{t('categoriesScreen.categories')}</Typography.Title>

        <Menu
          onSelect={({ key }) => { history.push(key) }}
          selectedKeys={[location.pathname]}
          mode='horizontal' className='rc-header-menu'
          items={[
            { key: '/categories/cash-in', label: t('categoriesScreen.cashInCategories') },
            { key: '/categories/cash-out', label: t('categoriesScreen.cashOutCategories') }
          ]}
        />
      </Layout.Header>

      <Layout.Content className='rc-content overflow-x-visible'>
        <Switch>
          <Route exact path='/categories/cash-in'>
            <ManageCategories type='cashin' />
          </Route>
          <Route exact path='/categories/cash-out'>
            <ManageCategories type='cashout' />
          </Route>
          <Redirect to='/categories/cash-in' />
        </Switch>
      </Layout.Content>
    </Layout>
  )
}
