import { takeEvery } from 'redux-saga/effects'
import { actionTypes } from 'redux-resource'
import { generateCategoryTreeSaga, createCategorySaga, readCategorySaga, updateCategorySaga, moveCategorySaga, deleteCategorySaga } from './resources/category-sagas'
import { createReceiptSaga } from './resources/receipt-sagas'
import { createScenarioSaga, updateScenarioSaga } from './resources/scenario-sagas'

export default [
  takeEvery(actionTypes.CREATE_RESOURCES_PENDING, createResourcesSaga),
  takeEvery(actionTypes.READ_RESOURCES_PENDING, readResourcesSaga),
  takeEvery(actionTypes.UPDATE_RESOURCES_PENDING, updateResourcesSaga),
  takeEvery(actionTypes.DELETE_RESOURCES_PENDING, deleteResourcesSaga)
]

function * createResourcesSaga (action) {
  switch (action.resourceType) {
    case 'categories':
      if (action.requestKey === 'generateCategoryTree-cashin' || action.requestKey === 'generateCategoryTree-cashout') {
        yield generateCategoryTreeSaga(action)
      } else {
        yield createCategorySaga(action)
      }
      break
    case 'receipts':
      yield createReceiptSaga(action)
      break
    case 'scenarios':
      yield createScenarioSaga(action)
      break
    default:
      console.warn('No create saga matches with resourceType')
  }
}

function * readResourcesSaga (action) {
  switch (action.resourceType) {
    case 'categories':
      yield readCategorySaga(action)
      break
    default:
      console.warn('No read saga matches with resourceType')
  }
}

function * updateResourcesSaga (action) {
  switch (action.resourceType) {
    case 'categories':
      if (action.requestKey === 'moveCategory') {
        yield moveCategorySaga(action)
      } else {
        yield updateCategorySaga(action)
      }
      break
    case 'scenarios':
      yield updateScenarioSaga(action)
      break
    default:
      console.warn('No update saga matches with resourceType')
  }
}

function * deleteResourcesSaga (action) {
  switch (action.resourceType) {
    case 'categories':
      yield deleteCategorySaga(action)
      break
    default:
      console.warn('No delete saga matches with resourceType')
  }
}
