import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Currency from 'components/Currency'
import { isNumber } from 'lodash'
import CashflowSheetDefaultCell from './components/CashflowSheetCurrencyCell'

CashflowSheetStartingBalanceCell.propTypes = {
  sheetDate: PropTypes.object,
  value: PropTypes.object,
  realisedVsForecasted: PropTypes.bool

}

export default function CashflowSheetStartingBalanceCell ({ sheetDate, value, realisedVsForecasted }) {
  const justify = useMemo(() => {
    if (sheetDate.isCurrentMonth) return 'center'
    if (sheetDate.isPastMonth && realisedVsForecasted) return 'start'

    return 'end'
  }, [sheetDate, realisedVsForecasted])

  if (isNumber(value.realisedStartingBalance)) {
    return (
      <CashflowSheetDefaultCell justify={justify}>
        <Currency amount={value.realisedStartingBalance} />
      </CashflowSheetDefaultCell>
    )
  }

  if (isNumber(value.forecastedStartingBalance)) {
    return (
      <CashflowSheetDefaultCell justify={justify}>
        <Currency amount={value.forecastedStartingBalance} />
      </CashflowSheetDefaultCell>
    )
  }

  return null
}
