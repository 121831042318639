import { LoadingOutlined } from '@ant-design/icons'
import { TreeSelect } from 'antd'
import CategoryTag from 'components/categories/CategoryTag'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getCategoriesInTreeSelector } from 'selectors/categories'
import CategorySelectRenderer from './CategorySelectRenderer'

const loadingIcon = <LoadingOutlined spin />

const generateCategorySelectTreeDataFromTreeData = (categoryTree) => {
  return categoryTree.map((category) => {
    const newData = {
      title: (
        <div className='rc-category-select-tag-container'>
          <CategoryTag category={category} />
        </div>
      ),
      value: get(category, 'id'),
      name: get(category, 'name')
    }

    if (get(category, 'children.length', 0) > 0) {
      newData.children = generateCategorySelectTreeDataFromTreeData(category.children)
    }

    return newData
  })
}

const CategorySelect = React.forwardRef(({ type, loading, withEmpty, emptyLabel, withCreateButton, withSettingsButton, onChange, isEditable, ...rest }, ref) => {
  const { t } = useTranslation()
  const categoryTree = useSelector(state => getCategoriesInTreeSelector(state, type))
  const selectTreeData = useMemo(() => {
    const data = []

    if (withEmpty) {
      data.push({
        title: (
          <div className='rc-category-select-tag-container'>
            <CategoryTag category={{ color: '#999999', name: emptyLabel || t('component.categorySelect.notCategorized') }} />
          </div>
        ),
        value: 'null',
        name: t('component.categorySelect.notCategorized')
      })
    }

    return [...data, ...generateCategorySelectTreeDataFromTreeData(categoryTree)]
  }, [withEmpty, categoryTree, emptyLabel, t])

  return (
    <TreeSelect
      ref={ref}
      allowClear
      treeData={selectTreeData}
      placeholder={t('shared.category')}
      // treeDefaultExpandAll
      suffixIcon={loading ? loadingIcon : undefined}
      dropdownMatchSelectWidth={false}
      showSearch
      treeNodeFilterProp='name'
      onChange={onChange}
      dropdownRender={(menu) => (
        <CategorySelectRenderer
          menu={menu}
          type={type}
          withCreateButton={withCreateButton}
          withSettingsButton={withSettingsButton}
          onCreateSuccess={onChange}
        />
      )}
      {...rest}
    />

  )
})

CategorySelect.defaultProps = {
  withEmpty: false
}

CategorySelect.propTypes = {
  type: PropTypes.oneOf(['cashin', 'cashout']),
  loading: PropTypes.bool,
  withEmpty: PropTypes.bool,
  emptyLabel: PropTypes.string,
  withCreateButton: PropTypes.bool,
  withSettingsButton: PropTypes.bool,
  onChange: PropTypes.func,
  isEditable: PropTypes.bool
}

export default CategorySelect
