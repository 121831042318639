
import AppActions from 'actions/AppActions'
import { get } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const queryFilters = { withIgnored: true }

export default function useRecurringPaymentsQueryFilters () {
  const dispatch = useDispatch()

  const memorizedQueryFilters = useSelector(state => get(state, 'app.recurringPayments.queryFilters', queryFilters))
  const [recurringPaymentsQueryFilters, setRecurringPaymentsQueryFilters] = useState(memorizedQueryFilters)

  const mergeRecurringPaymentsQueryFilters = useCallback((filters) => {
    setRecurringPaymentsQueryFilters({ ...recurringPaymentsQueryFilters, ...filters })
  }, [recurringPaymentsQueryFilters, setRecurringPaymentsQueryFilters])

  // REHYDRATE QUERY FILTERS
  useEffect(() => {
    if (memorizedQueryFilters !== recurringPaymentsQueryFilters) {
      dispatch(AppActions.setRecurringPaymentsQueryFilters(recurringPaymentsQueryFilters))
    }
  }, [dispatch, recurringPaymentsQueryFilters, memorizedQueryFilters])

  return {
    recurringPaymentsQueryFilters,
    mergeRecurringPaymentsQueryFilters
  }
}
