import { BoltIcon } from '@heroicons/react/24/outline'
import { Tooltip } from 'antd'
import ButtonLink from 'design/ButtonLink'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import CashflowSheetContext from 'contexts/CashflowSheetContext'
import useSaveForecastFromRealised from 'hooks/useSaveForecastFromRealised.js'

ForecastShortCut.propTypes = {
  scenarioId: PropTypes.string,
  categoryId: PropTypes.string,
  sheetDate: PropTypes.object,
  onCompleted: PropTypes.func,
  promote: PropTypes.bool
}

export default function ForecastShortCut ({
  scenarioId, categoryId, sheetDate, onCompleted, promote
}) {
  const { t } = useTranslation()
  const { refetchCashflowSheet, sheet } = useContext(CashflowSheetContext)

  const onCompletedFinal = () => {
    refetchCashflowSheet()
    if (onCompleted) { onCompleted() }
  }

  const { onSubmitRealised, loading } = useSaveForecastFromRealised({
    scenarioId,
    categoryId,
    sheetDate,
    onCompleted: onCompletedFinal,
    params: {
      growthType: 'PERCENTAGE',
      growthOperation: 'ADD',
      growthValue: 0,
      toDate: moment(sheetDate.momentDate).add(11, 'months'),
      frequency: 'MONTHLY',
      behaviour: 'AVERAGE_LAST_THREE_MONTHS'
    },
    amount: 0,
    sheet
  })

  return (
    <div className='opacity-0 group-hover:opacity-100'>
      <Tooltip
        placement='top'
        title={t('dashboard.forecastShortCut.fillBudgetOf12NextMonths')}
      >
        <ButtonLink
          label={<BoltIcon className={classNames('w-5 h-5 text-gray-400 cursor-pointer hover:text-primary hover:animate-pulse', { 'animate-pulse': promote })} />} onClick={() => onSubmitRealised()}
          loading={loading}
        />
      </Tooltip>
    </div>
  )
}
