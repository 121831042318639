import React from 'react'
import PropTypes from 'prop-types'
// import { isNumber } from 'lodash'

const ForecastBar = ({ fill, x, y, width, height, value, noTopBar }) => {
  // if (!isNumber(value)) return null
  if (!value) return null

  const realY = y || 0

  return (
    <g fill={fill}>
      <rect x={x || 0} y={realY} width={width || 0} height={height || 0} fillOpacity='0.3' />
      {!noTopBar && (<rect x={x - 4} y={realY - 1} width={width + 8} height={4} stroke='white' strokeWidth={2} strokeOpacity={1} rx='2' />)}
      {/* <rect x={x} y={(y || 0) + 1} width={width - 2} height={dotLineHeight >= 0 ? dotLineHeight : 0} rx='0' fill='none' /> */}
    </g>
  )
}

ForecastBar.defaultProps = {
  fill: '#000000',
  x: 0,
  y: 0,
  width: 0,
  height: 0
}

ForecastBar.propTypes = {
  fill: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.number,
  noTopBar: PropTypes.bool
}

export default ForecastBar
