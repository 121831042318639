import React, { useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'
import { Cog6ToothIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import VATOverviewChart from 'components/charts/VATOverviewChart'
import CashInfo from 'components/CashInfo'
import { c } from 'utils/currencies'
import { actions as vatActions } from 'reducers/vat-reducer'
import useAuthenticatedContext from 'hooks/useAuthenticatedContext'

const DashboardVatCard = () => {
  const { company } = useAuthenticatedContext()
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const currentPeriod = t(`labels.vat.currentPeriod.${company?.vatPeriod}`)
  const currentVatBalance = useSelector(state => get(state, 'vat.currentVatBalance'))
  const vatData = useMemo(() => ([
    {
      vatIn: get(currentVatBalance, 'totalVatCollectedForPeriod'),
      vatOut: get(currentVatBalance, 'totalVatDeductibleForPeriod')
    }
  ]), [currentVatBalance])

  const vatBalance = get(currentVatBalance, 'vatBalance')

  useEffect(() => {
    dispatch(vatActions.requestCurrentVatBalance())
  }, [dispatch])

  return (
    <div className='relative h-full bg-white rounded-md p-4'>
      <div className='flex justify-between'>
        <CashInfo
          loading={vatBalance === undefined}
          title={t('dashboard.vatCard.cashInfo.title', { currentPeriod })}
          text={c(vatBalance, null, { sign: true })}
          linkTo='/dashboard/vat'
        />

        <Link to='/dashboard/vat'>
          <Cog6ToothIcon className='w-5 h-5' />
        </Link>
      </div>
      <div className='rc-full-card-graph pl-4'>
        <VATOverviewChart data={vatData} />
      </div>
    </div>
  )
}

export default DashboardVatCard
