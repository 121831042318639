import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { Button, Tooltip } from 'antd'
import { CSVLink } from 'react-csv'

import { CheckIcon, ArrowDownTrayIcon } from '@heroicons/react/24/solid'

import { useTranslation } from 'react-i18next'

export default ({ sheet, sheetDates, ExportService, ...rest }) => {
  const { t } = useTranslation()
  const [exported, setExported] = useState(false)
  const [params, setParams] = useState({ filename: '', headers: [], data: [] })

  const exportService = useMemo(() => {
    return new ExportService({ sheet, sheetDates, ...rest })
  }, [sheet, sheetDates, ExportService, rest])

  useEffect(() => {
    if (exported) {
      const timeout = setTimeout(() => setExported(false), 3000)
      return () => clearTimeout(timeout)
    }
  }, [exported, setExported])

  const handleOnClick = useCallback((event, done) => {
    setParams({
      filename: exportService.getFilename(),
      headers: exportService.getCSVHeader(),
      data: exportService.getCSVData()
    })
    done()
    setExported(true)
  }, [setParams, exportService])

  const exportButton = useMemo(() => {
    return (
      <Tooltip title={t('hook.useExportTable.title')}>
        <Button type='link' className='w-full flex justify-center items-center'>
          <CSVLink
            filename={params.filename}
            headers={params.headers}
            data={params.data}
            asyncOnClick
            separator=';'
            onClick={handleOnClick}
            className='flex flex-row items-center'
          >
            {(exported && (
              <CheckIcon className='w-5 h-5' />
            )) || (
              <ArrowDownTrayIcon className='w-5 h-5' />
            )}
          </CSVLink>
        </Button>
      </Tooltip>
    )
  }, [t, params.filename, params.headers, params.data, handleOnClick, exported])

  return {
    exportButton
  }
}
