import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import { Typography } from 'antd'
import OrderStatusBadge from './OrderStatusBadge.js'
import AccountingDocumentAmountPreview from 'components/accounting-documents/AccoutingDocumentAmountPreview.js'
import ExpectedTransactionExpectedDatePickerShortcut from 'components/expected-transactions/ExpectedTransactionExpectedDatePickerShortcut.js'
import AccountingDocumentTableCategorySelectShortcut from 'components/accounting-documents/AccountingDocumentTableCategorySelectShortcut.js'
import moment from 'moment'
import AccountingDocumentTable from 'components/accounting-documents/AccountingDocumentTable.js'
import CashType from 'enums/CashType.js'
import OrderActions from './OrderActions.js'
import MultipleOrderUpdate from './MultipleOrderUpdate.js'
import OrderNoSyncPanel from './OrderNoSyncPanel.js'

const { t } = i18next

const { Text } = Typography

const getColumns = ({
  toggleRowExpand
}) => [
  {
    title: t('component.orderTable.column.status'),
    key: 'status',
    width: '1px',
    onCell: (order) => ({ onClick: () => { toggleRowExpand(order.id) } }),
    className: 'cursor-pointer min-w-max',
    render: order => <OrderStatusBadge order={order} />
  },
  {
    title: t('component.orderTable.column.orderNumber'),
    dataIndex: 'orderNumber',
    key: 'orderNumber',
    onCell: (order) => ({ onClick: () => { toggleRowExpand(order.id) } }),
    className: 'cursor-pointer min-w-48',
    render: text => <Text>{text}</Text>
  },
  {
    title: t('component.orderTable.column.client'),
    dataIndex: 'thirdPartyName',
    key: 'thirdPartyName',
    width: '160px',
    onCell: (order) => ({ onClick: () => { toggleRowExpand(order.id) } }),
    className: 'cursor-pointer',
    render: text => <Text>{text}</Text>
  },
  {
    title: t('component.orderTable.column.dueDate'),
    dataIndex: 'dueDate',
    key: 'dueDate',
    width: '120px',
    onCell: (order) => ({ onClick: () => { toggleRowExpand(order.id) } }),
    className: 'cursor-pointer',
    render: date => date && (<Text>{moment(date).format('DD/MM/YYYY')}</Text>)
  },
  {
    title: t('component.orderTable.column.expectedDate'),
    key: 'expectedDate',
    width: '170px',
    editable: true,
    render: order => <ExpectedTransactionExpectedDatePickerShortcut accountingDocument={order} onExpand={() => { toggleRowExpand(order.id) }} />
  },
  {
    title: t('shared.category'),
    key: 'category',
    width: '192px',
    className: 'max-w-[192px]',
    render: order => <AccountingDocumentTableCategorySelectShortcut accountingDocument={order} onExpand={() => { toggleRowExpand(order.id) }} />
  },
  {
    title: t('shared.amountInclTax'),
    key: 'amount',
    width: '180px',
    onCell: (order) => ({ onClick: () => { toggleRowExpand(order.id) } }),
    className: 'cursor-pointer text-right',
    render: order => <AccountingDocumentAmountPreview accountingDocument={order} />
  },
  {
    title: '',
    dataIndex: '',
    key: 'more',
    width: '32px',
    className: 'pl-0',
    render: (order) => (<OrderActions order={order} />)
  }
]

OrderTable.propTypes = {
  refetchOrders: PropTypes.func,
  hasSyncedOrderOnce: PropTypes.bool
}

export default function OrderTable ({ refetchOrders, hasSyncedOrderOnce, ...rest }) {
  const [selectedOrderIds, setSelectedOrderIds] = useState([])
  const [selectedOrders, setSelectedOrders] = useState([])

  const handleRowSelect = useCallback((selectedOrderIds, selectedOrders) => {
    setSelectedOrderIds(selectedOrderIds)
    setSelectedOrders(selectedOrders)
  }, [setSelectedOrderIds, setSelectedOrders])

  const onMultipleUpdateSuccess = useCallback(() => {
    setSelectedOrderIds([])
    setSelectedOrders([])
  }, [setSelectedOrderIds, setSelectedOrders])

  if (!hasSyncedOrderOnce) {
    return (<OrderNoSyncPanel />)
  }

  return (
    <>
      <MultipleOrderUpdate
        type={CashType.CASHIN}
        orderIds={selectedOrderIds}
        orders={selectedOrders}
        onSuccess={onMultipleUpdateSuccess}
        refetchOrders={refetchOrders}
      />

      <AccountingDocumentTable
        rowSelection={{
          selectedRowKeys: selectedOrderIds,
          onChange: handleRowSelect
        }}
        type={CashType.CASHIN}
        getColumns={getColumns}
        {...rest}
      />
    </>
  )
}

OrderTable.propTypes = {
  hasSyncedOrderOnce: PropTypes.bool
}
