import { get, isArray } from 'lodash'

export const adaptError = ({ errors }) => {
  const adaptedErrors = []
  if (!errors) return adaptedErrors
  errors.forEach((errorDetail) => {
    const path = get(errorDetail, 'path', [''])
    const joinedPath = isArray(path) ? path?.join('.') : path
    const key = get(errorDetail, 'key', joinedPath)
    const message = get(errorDetail, 'message', 'Unknown error')

    adaptedErrors.push({
      field: key,
      messages: [message]
    })
  })

  return adaptedErrors
}

export const setFieldsError = (errors, setFields, getFieldValue) => {
  (errors || []).forEach((errorDetail) => {
    const path = get(errorDetail, 'path', [''])
    const joinedPath = isArray(path) ? path?.join('.') : path
    const key = get(errorDetail, 'key', joinedPath)
    const message = get(errorDetail, 'message', 'Unknown error')

    setFields({
      [key]: {
        value: getFieldValue(key),
        errors: [{ message }]
      }
    })
  })
}
