import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export default function useSearchParams () {
  const location = useLocation()

  return useMemo(() => {
    const urlSearchParams = new URLSearchParams(location.search)
    return Object.fromEntries(urlSearchParams)
  }, [location])
}
