import React, { useEffect, useContext, useState } from 'react'
import { Modal } from 'antd'

import OnboardingContext from 'components/onboarding/OnboardingContext'
import OnboardingHeader from 'components/onboarding/OnboardingHeader'
import { getBookDemoLink, autoSignInDemoAccountLink } from 'utils/contact'
import Button from 'design/Button'
import useIsConnectingOrConnected from 'hooks/useIsConnectingOrConnected'
import useAuthenticatedContext from 'hooks/useAuthenticatedContext'
import { useLazyQuery } from '@apollo/client'
import { GET_POWENS_CONNECT_URL } from 'graphql/powens.js'
import useConnections from 'hooks/connections/useConnections.js'
import { useTranslation } from 'react-i18next'
import featureConfig from 'config/features.js'

const handleOnAskForTour = () => {
  window.open(getBookDemoLink(), '_blank')
}

const handleOnGoToDemoAccount = () => {
  /* eslint-disable no-undef */
  if (_cio) _cio.track('Demo Account Visited')

  window.open(autoSignInDemoAccountLink, '_self')
}

export default function SetupScreen () {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)

  const { company: { signUpSource } } = useAuthenticatedContext()
  const { powensConnection } = useConnections()

  const isConnectingOrConnected = useIsConnectingOrConnected()
  const context = useContext(OnboardingContext)

  useEffect(() => {
    if (isConnectingOrConnected) context.moveToNextStep()
  }, [isConnectingOrConnected, context])

  const [getPowensConnectUrl, { loading: gettingPowensConnectUrl }] = useLazyQuery(GET_POWENS_CONNECT_URL, {
    onCompleted: (data) => {
      if (data.getPowensConnectUrl) {
        window.location.replace(data.getPowensConnectUrl)
      }
    }
  })

  return (
    <div className='rc-onboarding-screen-container mt-20'>
      <OnboardingHeader title={t('onboarding.onboardingBankSetupScreen.syncAFirstBank')} />

      <div className='text-lg'>
        {t('onboarding.onboardingBankSetupScreen.description')}
      </div>

      <Button
        className='mt-4'
        primary
        size='large'
        loading={!!powensConnection?.firstSyncingStartAt || gettingPowensConnectUrl}
        onClick={getPowensConnectUrl}
        label={t('onboarding.onboardingBankSetupScreen.connectMyBank')}
      />

      <Button
        label={t('onboarding.onboardingBankSetupScreen.iDontWantToConnectMyBank')}
        className='mt-4 ml-4'
        size='large'
        onClick={() => setVisible(true)}
      />

      <Modal
        title={t('onboarding.onboardingBankSetupScreen.youDontWantToConnectYourBank')}
        open={visible}
        onClose={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        // onOk={}
        cancelText={t('shared.back')}
        okText={t('onboarding.onboardingBankSetupScreen.askForAProductTour')}
        footer={(
          <div className='space-x-2'>
            {featureConfig.demoLinkEnabled && (
              <Button onClick={handleOnAskForTour} label={t('onboarding.onboardingBankSetupScreen.askForAProductTour')} />
            )}
            <Button primary onClick={handleOnGoToDemoAccount} label={t('onboarding.onboardingBankSetupScreen.goToTheDemoAccount')} />
          </div>
        )}
      >
        {t('onboarding.onboardingBankSetupScreen.connectingYourBankAccountIsEssential')}
        <br />
        {
          (
            signUpSource === 'SELLSY' &&
            t('onboarding.onboardingBankSetupScreen.howeverYouCanTalkToSellsy')
          ) ||
          t('onboarding.onboardingBankSetupScreen.howeverYouCanUseTheDemoAccount')
        }
      </Modal>
    </div>
  )
}
