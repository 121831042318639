import { ApolloClient, from, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { message } from 'antd'
import { createUploadLink } from 'apollo-upload-client'
import i18next from 'i18next'

const { t } = i18next

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message: errMessage, extensions }) => {
      console.log(`[GraphQL error]: Message: ${errMessage}, Extensions:`, extensions)

      if (extensions?.code === 'INTERNAL_SERVER_ERROR') {
        message.error({ content: t('utils.apolloClient.networkErrorPleaseContactSupport'), duration: 10, key: 'internalServerError' })
      }
    })
  }

  if (networkError) {
    if (networkError) console.log(`[Network error]: ${networkError}`)
    message.error({ content: t('utils.api.error.networkError'), key: 'networkError' })
  }
})

const uploadLink = createUploadLink({
  uri: `${process.env.REACT_APP_API_BASE_URL}/graphql`,
  credentials: 'include',
  headers: {
    'Apollo-Require-Preflight': 'true'
  }
})

const client = new ApolloClient({
  link: from([errorLink, uploadLink]),
  cache: new InMemoryCache()
})

// https://github.com/apollographql/react-apollo/issues/3750
client.defaultOptions = {
  // query: {
  //   fetchPolicy: 'network-only'
  // },
  watchQuery: {
    fetchPolicy: 'network-only'
  }
}

export default client
