import { useQuery } from '@apollo/client'
import { LIST_BANK_CONNECTIONS_WITH_ACCOUNTS } from 'graphql/bank-connections.js'
import { useMemo } from 'react'
import { getBankInfosByAccountId } from 'selectors/bank-connections.js'

export default function useBankInfosByAccountId () {
  const { data } = useQuery(LIST_BANK_CONNECTIONS_WITH_ACCOUNTS, { fetchPolicy: 'cache-first' })
  const bankInfosByAccountId = useMemo(() => getBankInfosByAccountId(data), [data])

  return bankInfosByAccountId
}
