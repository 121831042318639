import { createActions } from 'redux-actions'

export default createActions({
  REQUEST_SIGN_IN: (email, password, firstSignIn = false) => ({ email, password }),
  REQUEST_SIGN_IN_SUCCESS: (firstSignIn = false) => ({ firstSignIn }),
  REQUEST_SIGN_IN_ERROR: (error) => ({ error }),

  AUTHENTICATE_SELLSY_USER_OR_REDIRECT: ({ webToken, onError, onComplete }) => ({ webToken, onError, onComplete }),
  AUTHENTICATE_SELLSY_USER_OR_SIGN_UP: ({ authorizationCode, onError }) => ({ authorizationCode, onError }),

  AUTHENTICATE_FROM_TEMPORARY_USER_TOKEN: ({ temporaryUserToken, onError, onComplete, ensureLogoutBefore }) => ({ temporaryUserToken, onError, onComplete, ensureLogoutBefore }),

  REQUEST_SIGN_UP: ({ params, onError }) => ({ params, onError }),
  REQUEST_SIGN_UP_SUCCESS: ({ params }) => ({ params }),
  REQUEST_SIGN_UP_ERROR: undefined,

  SEND_RESET_INSTRUCTIONS: (params, onSuccess, onError) => ({ params, onSuccess, onError }),
  SEND_RESET_INSTRUCTIONS_SUCCESS: undefined,
  SEND_RESET_INSTRUCTIONS_ERROR: (error) => ({ error }),

  RESET_PASSWORD: (params, onSuccess, onError) => ({ params, onSuccess, onError }),
  RESET_PASSWORD_SUCCESS: undefined,
  RESET_PASSWORD_ERROR: (errors) => ({ errors }),

  SET_COMPANY_ID: (companyId) => ({ companyId }),

  HAS_FETCH_USER: undefined,

  SIGN_OUT: undefined
})
