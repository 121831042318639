import { Card, Table } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useContext, useMemo } from 'react'

import Currency from 'components/Currency'
// import CashIndicator from 'components/CashIndicator'
import Dot from 'components/Dot'
import CashflowSheetContext from 'contexts/CashflowSheetContext'
import i18next from 'i18next'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import theme from 'theme'

DashboardMainChartTooltip.propTypes = {
  dataIndex: PropTypes.number,
  data: PropTypes.array,
  scenarioName: PropTypes.string
}

export default function DashboardMainChartTooltip ({ dataIndex, data, scenarioName }) {
  const payload = useMemo(() => data[dataIndex], [dataIndex, data])
  const { sheetDates } = useContext(CashflowSheetContext)
  const sheetDate = useMemo(() => sheetDates[dataIndex], [dataIndex, sheetDates])
  const realisedVsForecasted = useSelector(state => get(state, 'app.dashboard.realisedVsForecasted', false))
  const { t } = useTranslation()

  const columns = useMemo(() => {
    return generateColumns({ payload, scenarioName, realisedVsForecasted, sheetDate })
  }, [payload, scenarioName, realisedVsForecasted, sheetDate])

  const tableData = useMemo(() => {
    return [
      {
        key: 'cashin',
        name: t('shared.cashinPlural'),
        color: theme.colors.cashin,
        realised: get(payload, 'value.realisedCashin'),
        expected: get(payload, 'value.outstandingExpectedCashin', 0),
        // expected: sheetDate?.isPastMonth ? get(payload, 'value.expectedCashin', 0) : get(payload, 'value.outstandingExpectedCashin', 0),
        forecasted: get(payload, 'value.forecastedCashin')
      },
      {
        key: 'cashout',
        name: t('shared.cashout'),
        color: theme.colors.cashout,
        realised: get(payload, 'value.realisedCashout'),
        expected: get(payload, 'value.outstandingExpectedCashout', 0),
        // expected: sheetDate?.isPastMonth ? get(payload, 'value.expectedCashout', 0) : get(payload, 'value.outstandingExpectedCashout', 0),
        forecasted: get(payload, 'value.forecastedCashout')
      },
      {
        key: 'cashflow',
        name: t('component.dashboardMainChartTooltip.variation'),
        realised: get(payload, 'value.realisedCashflow'),
        // expected: sheetDate.isPastMonth ? get(payload, 'value.expectedCashflow', 0) : get(payload, 'value.outstandingExpectedCashflow', 0),
        forecasted: get(payload, 'value.forecastedCashflow')
      },
      {
        key: 'balance',
        name: (
          <Trans
            i18nKey='component.dashboardMainChartTooltip.balance'
            components={{ Span: <span className='text-gray-400 text-xs font-normal ml-1' /> }}
          />
        ),
        color: theme.colors.cashflow,
        realised: get(payload, 'value.realisedEndingBalance'),
        expected: get(payload, 'value.expectedEndingBalance'),
        forecasted: get(payload, 'value.forecastedEndingBalance')
      }
    ]
  }, [payload, t])

  if (!payload) return null

  return (
    <Card className='bg-white'>
      <Table
        rowKey='key'
        size='small'
        dataSource={tableData}
        columns={columns}
        pagination={false}
        className='rc-min-table'
      />
    </Card>
  )
}

const unixEndOfMonth = moment().endOf('month').unix()
const { t } = i18next

const computeTitle = (unixEndOfDateMonth) => {
  const fullDate = moment.unix(unixEndOfDateMonth).format('MMM YYYY')
  if (unixEndOfDateMonth === unixEndOfMonth) return `${fullDate} - ${t('component.dashboardMainChartTooltip.currentMonth')}`

  return fullDate
}

const generateColumns = ({ payload, scenarioName, realisedVsForecasted, sheetDate }) => {
  const title = computeTitle(get(payload, 'unixEndOfMonth'))

  const columns = [
    {
      title,
      key: 'name',
      render: (row) => <div className='font-bold'>{row.name}</div>
    }
  ]

  if (sheetDate?.isPastMonth || sheetDate?.isCurrentMonth) {
    columns.push({
      title: <div className='flex flex-row items-center font-bold'><Dot className='bg-light-cashflow mr-2' /> {t('component.dashboardMainChartTooltip.realised')}</div>,
      key: 'realised',
      render: (row) => (
        <Currency
          amount={row.realised}
          color={row.color}
          options={row.key === 'cashflow' ? { sign: true } : undefined}
        />
      )
    })
  }

  if (sheetDate?.isCurrentMonth || sheetDate?.isFutureMonth) {
    columns.push({
      title: <div className='flex flex-row items-center font-bold'><Dot className='rc-dashed-example-dot mr-2' /> {t('component.dashboardMainChartTooltip.expected')}</div>,
      key: 'expected',
      render: (row) => {
        if (['balance', 'cashflow'].includes(row.key)) return null

        return (
          <Currency
            amount={row.expected}
            color={row.color}
          />
        )
      }
    })
  }

  if (scenarioName && (!sheetDate?.isPastMonth || realisedVsForecasted)) {
    columns.push({
      title: <div className='flex flex-row items-center font-bold'><Dot className='bg-ultra-light-cashflow mr-2' /> {scenarioName}</div>,
      key: 'forecasted',
      render: (row) => {
        if (row.key === 'balance' && sheetDate?.isPastMonth) return null

        return (
          <Currency
            amount={row.forecasted}
            color={row.color}
            options={row.key === 'cashflow' ? { sign: true } : undefined}
          />
        )
      }
    })
  }

  return columns
}
