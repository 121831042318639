import moment from 'moment'
import i18next from 'i18next'
import { parseNumber } from 'utils/number.js'

const { t } = i18next

const fieldsKeys = {
  amount: 'amount',
  amountExcludingVat: 'amountExcludingVat'
}

export function validateString ({ record, fieldName }) {
  const value = record.get(fieldName)
  if (value === null || value.length === 0) {
    record.addError(
      fieldName,
      t('import.validations.emptyField')
    )
  }
}

export function validateAmount ({ record }) {
  const amountInput = record.get(fieldsKeys.amount)
  const amount = parseNumber(amountInput)

  if (isNaN(amount)) {
    record.addError(
      fieldsKeys.amount,
      t('import.validations.notNumber')
    )
    return
  }

  if (amount <= 0) {
    record.addError(
      fieldsKeys.amount,
      t('import.validations.upperThanZero')
    )
    return
  }

  const amountWithComma = amountInput.replace('.', ',')
  record.set(fieldsKeys.amount, amountWithComma)
}

export function validateAmountExcludingVat ({ record }) {
  const amountExcludingVatInput = record.get(fieldsKeys.amountExcludingVat)
  const amountExcludingVat = parseNumber(amountExcludingVatInput)

  if (isNaN(amountExcludingVat)) {
    record.addError(
      fieldsKeys.amountExcludingVat,
      t('import.validations.notNumber')
    )
    return
  }

  if (amountExcludingVat < 0) {
    record.addError(
      fieldsKeys.amountExcludingVat,
      t('import.validations.upperThanZero')
    )
    return
  }

  const amountInput = record.get(fieldsKeys.amount)
  const amount = parseNumber(amountInput)
  if (!isNaN(amount)) {
    if (amountExcludingVat > amount) {
      record.addError(
        fieldsKeys.amountExcludingVat,
        t('import.validations.aitIsLowerThanAbt')
      )
      return
    }

    const vatRatio = (amount - amountExcludingVat) / amountExcludingVat
    if (vatRatio >= 1) {
      record.addError(
        fieldsKeys.amountExcludingVat,
        t('import.validations.wrongVatRatio')
      )
      return
    }
  }

  const amountExcludingVatWithComma = amountExcludingVatInput.replace('.', ',')
  record.set(fieldsKeys.amountExcludingVat, amountExcludingVatWithComma)
}

export function validateDate ({ record, fieldName }) {
  const dateValue = record.get(fieldName)
  const date = moment(dateValue, 'DD/MM/YYYY', true)
  if (!date.isValid()) {
    record.addError(
      fieldName,
      t('import.validations.invalidDate')
    )
  }
}

export function validateCashType ({ record, fieldName }) {
  const type = record.get(fieldName)
  if (!['cashin', 'cashout'].includes(type)) {
    record.addError(
      fieldName,
      t('import.validations.invalidType')
    )
  }
}

export function validateId ({ record, fieldName, existingsIds }) {
  const id = record.get(fieldName)
  if (existingsIds.includes(id)) {
    record.addError(
      fieldName,
      t('import.validations.idAlreadyExists')
    )
  }
}
