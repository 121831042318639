import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

Logo.propTypes = {
  className: PropTypes.string
}

function Logo ({ className }) {
  if (process.env.REACT_APP_TENANT === 'QUIPU') {
    return (
      <img src='/logo_quipu.svg' alt='Quipu logo' className={classNames('h-16', className)} />
    )
  }

  return (
    <img src='/logo_sellsy_tresorerie.svg' alt='Sellsy Trésorerie logo' className={classNames('h-16', className)} />
  )
}

export default Logo
