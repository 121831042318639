import Currency from 'components/Currency'
import { isNumber } from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import CashflowSheetDefaultCell from './components/CashflowSheetCurrencyCell'

CashflowSheetEndingBalanceCell.propTypes = {
  sheetDate: PropTypes.object,
  value: PropTypes.object,
  realisedVsForecasted: PropTypes.bool
}

export default function CashflowSheetEndingBalanceCell ({ sheetDate, value, realisedVsForecasted }) {
  const justify = useMemo(() => {
    if (sheetDate.isCurrentMonth) return 'center'
    if (sheetDate.isPastMonth && realisedVsForecasted) return 'start'

    return 'end'
  }, [sheetDate, realisedVsForecasted])

  if (sheetDate.isCurrentMonth && isNumber(value.forecastedEndingBalance)) {
    return (
      <CashflowSheetDefaultCell justify={justify}>
        <Currency amount={value.forecastedEndingBalance} />
      </CashflowSheetDefaultCell>
    )
  }

  if (isNumber(value.forecastedEndingBalance)) {
    return (
      <CashflowSheetDefaultCell justify={justify}>
        <Currency amount={value.forecastedEndingBalance} />
      </CashflowSheetDefaultCell>
    )
  }

  if (isNumber(value.realisedEndingBalance)) {
    return (
      <CashflowSheetDefaultCell justify={justify}>
        <Currency amount={value.realisedEndingBalance} />
      </CashflowSheetDefaultCell>
    )
  }

  return null
}
