import { handleActions } from 'redux-actions'

const initialState = {
  isSigningIn: false,
  signInError: null,
  isSigningUp: false,
  isSendingInstructions: false,
  sendResetInstructionErrors: null,
  isResettingPassword: false,
  resetPasswordErrors: null,
  hasFetchUser: false,
  companyId: null
}

const reducer = handleActions(
  {
    REQUEST_SIGN_IN: (state) => ({
      ...state,
      isSigningIn: true,
      isSigningUp: false
    }),

    REQUEST_SIGN_IN_SUCCESS: (state, action) => ({
      ...state,
      isSigningIn: false,
      isSigningUp: false,
      signInError: null
    }),

    REQUEST_SIGN_IN_ERROR: (state, { payload }) => ({
      ...state,
      isSigningIn: false,
      isSigningUp: false,
      signInError: payload.error
    }),

    REQUEST_SIGN_UP: (state) => ({
      ...state,
      isSigningIn: false,
      isSigningUp: true
    }),

    REQUEST_SIGN_UP_SUCCESS: (state, action) => ({
      ...state,
      // Will be set to false while signing in
      isSigningIn: true,
      isSigningUp: false
    }),

    REQUEST_SIGN_UP_ERROR: (state) => ({
      ...state,
      isSigningIn: false,
      isSigningUp: false
    }),

    SEND_RESET_INSTRUCTIONS: (state) => ({
      ...state,
      isSendingInstructions: true
    }),

    SEND_RESET_INSTRUCTIONS_SUCCESS: (state, action) => ({
      ...state,
      isSendingInstructions: false,
      sendResetInstructionErrors: null
    }),

    SEND_RESET_INSTRUCTIONS_ERROR: (state, { payload }) => ({
      ...state,
      isSendingInstructions: false,
      sendResetInstructionErrors: payload.errors
    }),

    RESET_PASSWORD: (state) => ({
      ...state,
      isResettingPassword: true
    }),

    RESET_PASSWORD_SUCCESS: (state) => ({
      ...state,
      isResettingPassword: false,
      resetPasswordErrors: null
    }),

    RESET_PASSWORD_ERROR: (state, { payload }) => ({
      ...state,
      isResettingPassword: false,
      resetPasswordErrors: payload.errors
    }),

    SET_COMPANY_ID: (state, { payload: { companyId } }) => ({
      ...state,
      companyId
    }),

    HAS_FETCH_USER: (state) => ({
      ...state,
      hasFetchUser: true
    })
  },
  { ...initialState }
)

export default reducer
