import { LIST_EXISTING_EXTERNAL_IDS, IMPORT_TRANSACTIONS_FROM_UPLOAD } from 'graphql/transactions.js'
import client from 'utils/apollo-client.js'
import useAuthenticatedContext from 'hooks/useAuthenticatedContext.js'
import { convertStringDateFrToIso } from 'utils/dates.js'
import { createImport } from 'utils/import.js'
import { parseNumber } from 'utils/number.js'
import { validateDate, validateAmount, validateString, validateCashType, validateId } from 'utils/import-validations.js'
import i18next from 'i18next'
const { t } = i18next

const fieldsKeys = {
  externalId: 'externalId',
  date: 'date',
  amount: 'amount',
  type: 'type',
  description: 'description'
}

const sheet = () => {
  return {
    name: t('import.sheets.transactions.name'),
    fields: [
      {
        key: fieldsKeys.externalId,
        type: 'string',
        label: t('import.sheets.transactions.fields.id')
      },
      {
        key: fieldsKeys.date,
        type: 'string',
        label: t('import.sheets.transactions.fields.date')
      },
      {
        key: fieldsKeys.amount,
        type: 'number',
        label: t('import.sheets.transactions.fields.amount')
      },
      {
        key: fieldsKeys.type,
        label: t('import.sheets.transactions.fields.type.label'),
        type: 'enum',
        config: {
          options: [
            {
              value: 'cashin',
              label: t('import.sheets.transactions.fields.type.cashin')
            },
            {
              value: 'cashout',
              label: t('import.sheets.transactions.fields.type.cashout')
            }
          ]
        }
      },
      {
        key: fieldsKeys.description,
        type: 'string',
        label: t('import.sheets.transactions.fields.description')
      }
    ]
  }
}

async function fetchExistingsTransactionsIds (externalIds, accountId) {
  const result = await client.query({
    query: LIST_EXISTING_EXTERNAL_IDS,
    variables: { accountId: accountId, externalIds },
    fetchPolicy: 'no-cache'
  })

  return result.data.listExistingExternalIds.existingsExternalIds
}

async function onValidateRecords ({ contextParams, records }) {
  const externalIds = records.map(record => record.get(fieldsKeys.externalId))
  const existingsIds = await fetchExistingsTransactionsIds(externalIds, contextParams.accountId)

  return records.map((record) => {
    const params = { record }
    validateAmount({ ...params })
    validateId({ ...params, fieldName: fieldsKeys.externalId, existingsIds })
    validateCashType({ ...params, fieldName: fieldsKeys.type })
    validateDate({ ...params, fieldName: fieldsKeys.date })
    validateString({ ...params, fieldName: fieldsKeys.description })

    return record
  })
}

async function onImportRecords ({ records, contextParams, spaceId }) {
  const transactionsParams = records.map(record => {
    const element = record.values
    const amount = parseNumber(element.amount.value)
    return {
      externalImportId: spaceId,
      recordId: record.id,
      amount,
      externalId: element.externalId.value,
      type: element.type.value,
      date: convertStringDateFrToIso(element.date.value),
      description: element.description.value
    }
  })
  await client.mutate({
    mutation: IMPORT_TRANSACTIONS_FROM_UPLOAD,
    variables: {
      accountId: contextParams.accountId,
      transactionsParams
    }
  })
}

export default function useTransactionsImporter ({ onImportComplete, accountId }) {
  const { user } = useAuthenticatedContext()

  const createImportOption = {
    sheet: sheet(),
    onValidateRecords,
    onImportRecords,
    onClose: onImportComplete,
    contextParams: {
      accountId
    },
    user
  }

  const triggerImport = async () => {
    await createImport(createImportOption)
  }

  return { triggerImport }
}
