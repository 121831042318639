import React, { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useSubscribeToPlan from 'hooks/useSubscribeToPlan'
import { Typography, Button } from 'antd'
import cx from 'classnames'
import { get } from 'lodash'
import { CheckCircleIcon, PlusCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'

import { openLiveChat } from 'utils/contact'
import Badge from 'design/Badge'
import useAuthenticatedContext from 'hooks/useAuthenticatedContext'
import PageLoader from 'design/PageLoader'
import { useQuery } from '@apollo/client'
import { LIST_USERS } from 'graphql/users'
import { LIST_BANK_CONNECTIONS_WITH_ACCOUNTS } from 'graphql/bank-connections.js'
import { ScenariosContext } from 'contexts/ScenariosContext.js'

const { Title } = Typography

export default () => {
  const { t } = useTranslation()
  const [yearly, setYearly] = useState(true)

  const { data: scenariosData, loading: scenariosLoading } = useContext(ScenariosContext)

  const { data: bankConnectionsData, loading: bankConnectionsLoading } = useQuery(LIST_BANK_CONNECTIONS_WITH_ACCOUNTS, { fetchPolicy: 'cache-first' })

  const { subscribeToPlan, upgradePopup, loading: subscribeLoading } = useSubscribeToPlan()
  const { subscription } = useAuthenticatedContext()

  const plan = subscription?.plan

  const banksCount = useMemo(() => {
    const bankConnections = bankConnectionsData?.listBankConnectionsWithAccounts || []
    const withoutDeleted = bankConnections.filter((a) => a.status !== 'DELETED')
    return withoutDeleted.length
  }, [bankConnectionsData])

  const { loading: usersLoading, data: usersData } = useQuery(LIST_USERS, {
    // Will set loading to true while refetching
    notifyOnNetworkStatusChange: true
  })

  const usersCount = useMemo(() => {
    return get(usersData, 'listUsers', []).length
  }, [usersData])

  const scenariosCount = useMemo(() => {
    return get(scenariosData, 'listScenarios', []).length
  }, [scenariosData])

  const loading = useMemo(() => {
    return usersLoading || bankConnectionsLoading || scenariosLoading
  }, [usersLoading, bankConnectionsLoading, scenariosLoading])

  const hasMultipleUsers = usersCount > 1
  const hasMultipleBanks = banksCount > 1
  const hasMultipleScenarios = scenariosCount > 2
  const hasTooMuchScenarios = scenariosCount > 5
  const hasTooMuchBanks = banksCount > 3

  return (
    <div className='flex flex-col items-center'>
      <Title level={3}>{t('subscribe.planSelection.chooseThePlanForYourActivity')}</Title>

      <div className='border border-gray-300 rounded-lg flex flex-row items-center space-x-1 p-0.5 mt-4'>
        <button
          onClick={() => setYearly(true)} className={cx(
            'flex flex-col items-center p-2 rounded-lg w-48 outline-none border transition-colors duration-300', {
              'border-primary bg-ultra-light-primary bg-opacity-20': yearly,
              'hover:border-ultra-light-primary bg-ultra-white border-transparent': !yearly
            }
          )}
        >
          <span className='font-bold'>{t('subscribe.planSelection.yearlyPayment')}</span>
          <Badge type='success' label={t('subscribe.planSelection.twoFreeMonths')} />
        </button>

        <button
          onClick={() => setYearly(false)} className={cx(
            'flex flex-col items-center p-2 rounded-lg w-48 outline-none border border-transparent transition-colors duration-300', {
              'border-primary bg-ultra-light-primary bg-opacity-20': !yearly,
              'hover:border-ultra-light-primary bg-ultra-white border-transparent': yearly
            }
          )}
        >
          <span className='font-bold'>{t('subscribe.planSelection.monthlyPayment')}</span>
          <span className='text-xs py-0.5'>{t('subscribe.planSelection.commitment')}</span>
        </button>
      </div>

      {(loading && (
        <PageLoader className='mt-8' />
      )) || (
        <div className='flex flex-col xl:flex-row items-center justify-center px-16 space-x-0 xl:space-x-4 2xl:space-x-16 space-y-8 xl:space-y-0 mt-8 2xl:mt-16'>
          {!['PRO', 'BUSINESS'].includes(plan) && (
            <div className={cx(
              'rc-subscription-card w-full max-w-sm h-full relative', {
                'filter grayscale': hasMultipleUsers || hasMultipleBanks || hasMultipleScenarios
              }
            )}
            >
              <Title level={4}>{t('subscribe.planSelection.starterPlan.title')}</Title>

              <div className='flex flex-row items-center'><CheckCircleIcon className='h-4 w-4 text-primary mr-2' /> {t('subscribe.planSelection.starterPlan.includedBanks')}</div>
              <div className='flex flex-row items-center'><CheckCircleIcon className='h-4 w-4 text-primary mr-2' /> {t('subscribe.planSelection.starterPlan.includedUsers')}</div>
              <div className='flex flex-row items-center'><CheckCircleIcon className='h-4 w-4 text-primary mr-2' />{t('subscribe.planSelection.features.starterPlan.includedScenarios')}</div>

              <div className='flex flex-row items-baseline mt-4'>
                <span className='text-2xl font-bold mr-1'>{yearly ? t('subscribe.planSelection.starterPlan.yearlyMonthPrice') : t('subscribe.planSelection.starterPlan.monthPrice')}</span>
                {t('subscribe.planSelection.byMonthExclTax')}
              </div>
              {yearly && (<div>{t('subscribe.planSelection.starterPlan.yearlyPaymentPrice')}</div>)}
              <Button
                loading={subscribeLoading}
                disabled={loading || hasMultipleUsers || hasMultipleBanks || hasMultipleScenarios || plan === 'STARTER'}
                onClick={() => subscribeToPlan({ plan: 'STARTER', recurrence: yearly ? 'YEARLY' : 'MONTHLY' })}
                type='primary'
                size='large'
                className='mt-2 mb-8'
              >
                {plan === 'STARTER' ? t('subscribe.planSelection.currentPlan') : t('subscribe.planSelection.chooseThisPlan')}
              </Button>

              <div className='flex flex-row items-center'>
                <CheckCircleIcon className='h-4 w-4 text-primary mr-2' />
                {t('subscribe.planSelection.features.bankSync')}
              </div>

              <div className='flex flex-row items-center'>
                <CheckCircleIcon className='h-4 w-4 text-primary mr-2' />
                {t('subscribe.planSelection.features.customCategories')}
              </div>

              <div className='flex flex-row items-center'>
                <CheckCircleIcon className='h-4 w-4 text-primary mr-2' />
                {t('subscribe.planSelection.features.automaticCategorization')}
              </div>

              <div className='flex flex-row items-center'>
                <CheckCircleIcon className='h-4 w-4 text-primary mr-2' />
                {t('subscribe.planSelection.features.cashflowAnalytics')}
              </div>

              <div className='flex flex-row items-center'>
                <CheckCircleIcon className='h-4 w-4 text-primary mr-2' />
                {t('subscribe.planSelection.features.expectedAndBudget')}
              </div>

              <div className='flex flex-row items-center'>
                <CheckCircleIcon className='h-4 w-4 text-primary mr-2' />
                {t('subscribe.planSelection.features.comparisonOfExpenseVsExpected')}
              </div>

              <div className='flex flex-row items-center'>
                <CheckCircleIcon className='h-4 w-4 text-primary mr-2' />
                {t('subscribe.planSelection.features.sellsyIntegration')}
              </div>

              <div className='flex flex-row items-center'>
                <CheckCircleIcon className='h-4 w-4 text-primary mr-2' />
                {t('subscribe.planSelection.features.mainScenario')}
              </div>

              <div className='flex flex-row items-center text-gray-400'>
                <XCircleIcon className='h-4 w-4 text-gray-400 mr-2' />
                {t('subscribe.planSelection.features.multiScenarios')}
              </div>

              <div className='flex flex-row items-center text-gray-400'>
                <XCircleIcon className='h-4 w-4 text-gray-400 mr-2' />
                {t('subscribe.planSelection.features.configureScenarios')}
              </div>

              <div className='flex flex-row items-center text-gray-400'>
                <XCircleIcon className='h-4 w-4 text-gray-400 mr-2' />
                {t('subscribe.planSelection.features.vatForecast')}
              </div>

              <div className='flex flex-row items-center text-gray-400'>
                <XCircleIcon className='h-4 w-4 text-gray-400 mr-2' />
                {t('subscribe.planSelection.features.customExports')}
              </div>

              <div className='flex flex-row items-center text-gray-400'>
                <XCircleIcon className='h-4 w-4 text-gray-400 mr-2' />
                {t('subscribe.planSelection.features.exportReceipts')}
              </div>

              <div className='flex flex-row items-center text-gray-400'>
                <XCircleIcon className='h-4 w-4 text-gray-400 mr-2' />
                {t('subscribe.planSelection.features.triggerBankAccountSync')}
              </div>

              {((hasMultipleUsers || hasMultipleBanks || hasMultipleScenarios) && (plan !== 'STARTER')) && (
                <div className='transition-opacity duration-300 opacity-0 hover:opacity-100 absolute top-0 bottom-0 left-0 right-0 rounded-lg bg-white bg-opacity-90 text-shadow flex flex-row items-center justify-center p-8'>
                  {t('subscribe.planSelection.youCannotChooseThisPlanBecause')}
                </div>
              )}
            </div>
          )}

          {!['BUSINESS'].includes(plan) && (
            <div className={cx(
              'rc-subscription-card w-full max-w-sm h-full relative', {
                'filter grayscale': hasTooMuchBanks || hasTooMuchScenarios
              }
            )}
            >
              <Title level={4}>{t('subscribe.planSelection.proPlan.title')}</Title>

              <div className='flex flex-row items-center'><CheckCircleIcon className='h-4 w-4 text-primary mr-2' /> {t('subscribe.planSelection.proPlan.includedBanks')}</div>
              <div className='flex flex-row items-center'><CheckCircleIcon className='h-4 w-4 text-primary mr-2' /> {t('subscribe.planSelection.proPlan.includedUsers')}</div>
              <div className='flex flex-row items-center'><CheckCircleIcon className='h-4 w-4 text-primary mr-2' />{t('subscribe.planSelection.features.proPlan.includedScenarios')}</div>

              <div className='flex flex-row items-baseline mt-4'>
                <span className='text-2xl font-bold mr-1'>{yearly ? t('subscribe.planSelection.proPlan.yearlyMonthPrice') : t('subscribe.planSelection.proPlan.monthPrice')}</span>
                {t('subscribe.planSelection.byMonthExclTax')}
              </div>
              {yearly && (<div>{t('subscribe.planSelection.proPlan.yearlyPaymentPrice')}</div>)}
              <Button
                loading={subscribeLoading}
                disabled={loading || hasTooMuchBanks || hasTooMuchScenarios || plan === 'PRO'}
                onClick={() => subscribeToPlan({ plan: 'PRO', recurrence: yearly ? 'YEARLY' : 'MONTHLY' })}
                type='primary'
                size='large'
                className='mt-2 mb-8'
              >
                {plan === 'PRO' ? t('subscribe.planSelection.currentPlan') : t('subscribe.planSelection.chooseThisPlan')}
              </Button>

              <div className='flex flex-row items-center'>
                <CheckCircleIcon className='h-4 w-4 text-primary mr-2' />
                {t('subscribe.planSelection.features.bankSync')}
              </div>

              <div className='flex flex-row items-center'>
                <CheckCircleIcon className='h-4 w-4 text-primary mr-2' />
                {t('subscribe.planSelection.features.customCategories')}
              </div>

              <div className='flex flex-row items-center'>
                <CheckCircleIcon className='h-4 w-4 text-primary mr-2' />
                {t('subscribe.planSelection.features.automaticCategorization')}
              </div>

              <div className='flex flex-row items-center'>
                <CheckCircleIcon className='h-4 w-4 text-primary mr-2' />
                {t('subscribe.planSelection.features.cashflowAnalytics')}
              </div>

              <div className='flex flex-row items-center'>
                <CheckCircleIcon className='h-4 w-4 text-primary mr-2' />
                {t('subscribe.planSelection.features.expectedAndBudget')}
              </div>

              <div className='flex flex-row items-center'>
                <CheckCircleIcon className='h-4 w-4 text-primary mr-2' />
                {t('subscribe.planSelection.features.comparisonOfExpenseVsExpected')}
              </div>

              <div className='flex flex-row items-center'>
                <CheckCircleIcon className='h-4 w-4 text-primary mr-2' />
                {t('subscribe.planSelection.features.sellsyIntegration')}
              </div>

              <div className='flex flex-row items-center'>
                <CheckCircleIcon className='h-4 w-4 text-primary mr-2' />
                {t('subscribe.planSelection.features.mainScenario')}
              </div>

              <div className='flex flex-row items-center'>
                <CheckCircleIcon className='h-4 w-4 text-primary mr-2' />
                {t('subscribe.planSelection.features.multiScenarios')}
              </div>

              <div className='flex flex-row items-center'>
                <XCircleIcon className='h-4 w-4 text-primary mr-2' />
                {t('subscribe.planSelection.features.configureScenarios')}
              </div>

              <div className='flex flex-row items-center'>
                <CheckCircleIcon className='h-4 w-4 text-primary mr-2' />
                {t('subscribe.planSelection.features.vatForecast')}
              </div>

              <div className='flex flex-row items-center'>
                <CheckCircleIcon className='h-4 w-4 text-primary mr-2' />
                {t('subscribe.planSelection.features.customExports')}
              </div>

              <div className='flex flex-row items-center'>
                <CheckCircleIcon className='h-4 w-4 text-primary mr-2' />
                {t('subscribe.planSelection.features.exportReceipts')}
              </div>

              <div className='flex flex-row items-center'>
                <XCircleIcon className='h-4 w-4 text-primary mr-2' />
                {t('subscribe.planSelection.features.triggerBankAccountSync')}
              </div>

              {(hasTooMuchBanks || hasTooMuchScenarios) && (plan !== 'PRO') && (
                <div className='transition-opacity duration-300 opacity-0 hover:opacity-100 absolute top-0 bottom-0 left-0 right-0 rounded-lg bg-white bg-opacity-90 text-shadow flex flex-row items-center justify-center p-8'>
                  {t('subscribe.planSelection.youCannotChooseThisPlanBecauseYouHaveMoreThan3Banks')}
                </div>
              )}
            </div>
          )}

          <div className='rc-subscription-card w-full max-w-sm h-full'>
            <Title level={4}>{t('subscribe.planSelection.businessPlan.title')}</Title>

            <div className='flex flex-row items-center'><CheckCircleIcon className='h-4 w-4 text-primary mr-2' /> {t('subscribe.planSelection.businessPlan.includedBanks')}</div>
            <div className='flex flex-row items-center'><CheckCircleIcon className='h-4 w-4 text-primary mr-2' /> {t('subscribe.planSelection.businessPlan.includedUsers')}</div>
            <div className='flex flex-row items-center'><CheckCircleIcon className='h-4 w-4 text-primary mr-2' />{t('subscribe.planSelection.features.businessPlan.includedScenarios')}</div>

            <div className='flex flex-row items-baseline mt-4'>
              <span className='text-2xl font-bold mr-1'>{yearly ? t('subscribe.planSelection.businessPlan.yearlyMonthPrice') : t('subscribe.planSelection.businessPlan.monthPrice')}</span>
              {t('subscribe.planSelection.byMonthExclTax')}
            </div>
            {yearly && (<div>{t('subscribe.planSelection.businessPlan.yearlyPaymentPrice')}</div>)}
            <Button
              loading={subscribeLoading}
              disabled={loading || plan === 'BUSINESS'}
              onClick={() => openLiveChat(t('subscribe.planSelection.liveChatPrompt'))}
              type='primary'
              size='large'
              className='mt-2 mb-8'
            >
              {plan === 'BUSINESS' ? t('subscribe.planSelection.currentPlan') : t('subscribe.planSelection.contactUs')}
            </Button>

            <div className='flex flex-row items-center'>
              <CheckCircleIcon className='h-4 w-4 text-primary mr-2' />
              {t('subscribe.planSelection.features.bankSync')}
            </div>

            <div className='flex flex-row items-center'>
              <CheckCircleIcon className='h-4 w-4 text-primary mr-2' />
              {t('subscribe.planSelection.features.customCategories')}
            </div>

            <div className='flex flex-row items-center'>
              <CheckCircleIcon className='h-4 w-4 text-primary mr-2' />
              {t('subscribe.planSelection.features.automaticCategorization')}
            </div>

            <div className='flex flex-row items-center'>
              <CheckCircleIcon className='h-4 w-4 text-primary mr-2' />
              {t('subscribe.planSelection.features.cashflowAnalytics')}
            </div>

            <div className='flex flex-row items-center'>
              <CheckCircleIcon className='h-4 w-4 text-primary mr-2' />
              {t('subscribe.planSelection.features.expectedAndBudget')}
            </div>

            <div className='flex flex-row items-center'>
              <CheckCircleIcon className='h-4 w-4 text-primary mr-2' />
              {t('subscribe.planSelection.features.comparisonOfExpenseVsExpected')}
            </div>

            <div className='flex flex-row items-center'>
              <CheckCircleIcon className='h-4 w-4 text-primary mr-2' />
              {t('subscribe.planSelection.features.sellsyIntegration')}
            </div>

            <div className='flex flex-row items-center'>
              <CheckCircleIcon className='h-4 w-4 text-primary mr-2' />
              {t('subscribe.planSelection.features.mainScenario')}
            </div>

            <div className='flex flex-row items-center'>
              <CheckCircleIcon className='h-4 w-4 text-primary mr-2' />
              {t('subscribe.planSelection.features.multiScenarios')}
            </div>

            <div className='flex flex-row items-center'>
              <XCircleIcon className='h-4 w-4 text-primary mr-2' />
              {t('subscribe.planSelection.features.configureScenarios')}
            </div>

            <div className='flex flex-row items-center'>
              <CheckCircleIcon className='h-4 w-4 text-primary mr-2' />
              {t('subscribe.planSelection.features.vatForecast')}
            </div>

            <div className='flex flex-row items-center'>
              <CheckCircleIcon className='h-4 w-4 text-primary mr-2' />
              {t('subscribe.planSelection.features.customExports')}
            </div>

            <div className='flex flex-row items-center'>
              <CheckCircleIcon className='h-4 w-4 text-primary mr-2' />
              {t('subscribe.planSelection.features.exportReceipts')}
            </div>

            <div className='flex flex-row items-center '>
              <XCircleIcon className='h-4 w-4 text-primary mr-2' />
              {t('subscribe.planSelection.features.triggerBankAccountSync')}
            </div>

            <div className='flex flex-row items-centerabsolute'>
              <PlusCircleIcon className='h-4 w-4 text-primary mr-2' />
              {yearly ? t('subscribe.planSelection.businessPlan.yearlyAdditionalBankPrice') : t('subscribe.planSelection.businessPlan.additionalBankPrice')}
            </div>
          </div>
        </div>
      )}

      {upgradePopup}
    </div>
  )
}
