import { message } from 'antd'
import axios from 'axios'
import FormData from 'form-data'
import i18next from 'i18next'
import { get } from 'lodash'

import authActions from 'actions/AuthActions'

const DEFAULT_TIMEOUT = 30000
const STREAMING_TIMEOUT = 300000

const { t } = i18next

const passThroughNetworkErrorUrls = ['/receipts/transactions/archive']

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: DEFAULT_TIMEOUT,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
})

export const setUnauthorizedInterceptor = (store) => {
  instance.interceptors.response.use(undefined, error => {
    if (get(error, 'response.status') === 401) {
      store.dispatch(authActions.signOut())
    } else if (get(error, 'request.status') === 0 && !passThroughNetworkErrorUrls.includes(error.config.url)) {
      console.error(error)
      message.error({ content: t('utils.api.error.networkError'), key: 'networkError' })
    } else {
      return Promise.reject(error)
    }
  })
}

// AUTH
export const authenticate = ({ email, password }) => {
  return instance.post('/auth', { email, password })
}

export const logout = () => {
  return instance.delete('/auth')
}

export const authenticateSellsyUserOrRedirect = ({ webToken }) => {
  return instance.post('/sellsy/authenticateSellsyUserOrRedirect', { webToken })
}

export const authenticateSellsyUserOrSignUp = ({ authorizationCode }) => {
  return instance.post('/sellsy/authenticateSellsyUserOrSignUp', { authorizationCode })
}

export const authenticateFromTemporaryUserToken = ({ temporaryUserToken }) => {
  return instance.post('/auth/fromTemporaryUserToken', { temporaryUserToken })
}

export const forgotPassword = ({ email }) => {
  return instance.post('/password/forgot', { email })
}

export const resetPassword = ({ token, newPassword, newPasswordAgain }) => {
  return instance.post('/password/reset', { token, newPassword, newPasswordAgain })
}

// USERS
export const signUp = (params) => {
  return instance.post('/company', params)
}

export const updateUser = (params) => {
  return instance.put('/user', params)
}

// COMPANY
export const endOnboarding = () => {
  return instance.post('/company/endOnboarding')
}

// VAT
export const getVatBalance = (params) => {
  return instance.get('/vatBalance', { params })
}

// BI
export const getBIWebviewUrl = () => {
  return instance.post('/powensAuth')
}

export const getBIWebviewUrlForExistingUser = () => {
  return instance.put('/powensAuth')
}

export const notifyBIAuth = (connectionId, temporaryToken) => {
  return instance.get('/powensAuth', { params: { connectionId, temporaryToken } })
}

// TRANSACTIONS
export const getTransactionsTotal = (year) => {
  return instance.get('/transactionsTotal', { params: { year } })
}

// EXPENSES
export const getExpensesTotal = (year) => {
  const params = { year }
  return instance.get('/expensesTotal', { params })
}

// REVENUES
export const getRevenueTotal = (year) => {
  const params = { year }
  return instance.get('/revenueTotal', { params })
}

// CATEGORIES
export const generateCategoryTree = (params) => {
  return instance.post('/categories/generateTree', params)
}

export const fetchCategories = (type) => {
  return instance.get('/categories', { params: { type } })
}

export const createCategory = (params) => {
  return instance.post('/categories', params)
}

export const updateCategory = (params) => {
  return instance.put('/categories', params)
}

export const moveCategory = (params) => {
  return instance.put('/categories/move', params)
}

export const deleteCategory = (params) => {
  return instance.delete('/categories', { data: params })
}

// SCENARIOS
export const createScenario = (params) => {
  return instance.post('/scenario', params)
}

export const updateScenario = (params) => {
  return instance.put('/scenario', params)
}

export const joinYearsForScenario = (params) => {
  return instance.put('/scenario/joinYears', params)
}

// RECEIPTS
export const createReceipt = ({ params, documentFile }) => {
  const formData = new FormData()

  formData.append('documentFile', documentFile)

  Object.entries(params).map(([key, value]) => {
    return formData.append(key, value)
  })

  return instance.post(
    '/receipts',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}

export const downloadReceiptsArchive = (params) => {
  return instance.post('/receipts/transactions/archive', params,
    {
      responseType: 'blob', // response blob for stream
      timeout: STREAMING_TIMEOUT // set timetout to 5 minutes (streaming can take time)
    })
}
