import { initializeFlatfile, FlatfileListener } from '@flatfile/javascript'
import { bulkRecordHook } from '@flatfile/plugin-record-hook'
import api from '@flatfile/api'
import i18next from 'i18next'

const { t } = i18next

const IMPORT_ITEMS_PER_PAGE = 100
const VALIDATION_ITEMS_PER_PAGE = 100
const VALIDATION_PARALLELIZATION = 2
const SHEET_SLUG = 'rc-import'

function createWorkbook ({ sheet: { name, fields } }) {
  return {
    name: t('import.workbookName'),
    sheets: [
      {
        name,
        slug: SHEET_SLUG,
        allowAdditionalFields: false,
        access: ['import', 'edit', 'delete'],
        fields
      }
    ],
    actions: [
      {
        operation: 'submitActionRC',
        mode: 'foreground',
        label: t('import.submit'),
        primary: true,
        constraints: [{ type: 'hasData' }, { type: 'hasAllValid' }]
      }
    ],
    settings: {
      trackChanges: true
    }
  }
}

async function onImportReady (onImportRecords, contextParams, event) {
  const { context } = event
  const { jobId, workbookId, spaceId } = context

  try {
    const {
      data: { sheets }
    } = await api.workbooks.get(workbookId)

    const sheet = sheets[0]

    const {
      data: {
        counts: { total: totalRecords }
      }
    } = await api.sheets.getRecordCounts(sheet.id, { filter: 'valid' })

    if (!totalRecords) {
      await api.jobs.fail(jobId, {
        outcome: {
          message: t('import.noRecords'),
          acknowledge: true
        }
      })

      return
    }

    const totalPages = Math.ceil(totalRecords / IMPORT_ITEMS_PER_PAGE)

    for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
      const {
        data: { records }
      } = await api.records.get(sheet.id, { IMPORT_ITEMS_PER_PAGE, pageNumber, filter: 'valid' })
      onImportRecords({ records, contextParams, spaceId })
      await updateEgressProgressBar(jobId, pageNumber, totalPages)
    }

    await api.jobs.complete(jobId, {
      outcome: {
        acknowledge: true,
        message: t('import.success')
      }
    })
  } catch (error) {
    console.error('Error:', error.stack)

    await api.jobs.fail(jobId, {
      outcome: {
        message: t('import.error')
      }
    })
  }
}

async function validateRecordsCallback (onValidateRecords, contextParams, records) {
  try {
    await onValidateRecords({ contextParams, records })
  } catch (error) {
    const message = t('import.error')
    for (const record of records) {
      for (const column of Object.keys(record.value)) {
        if (column !== 'undefined') {
          record.addError(column, message)
        }
      }
    }
  }
}

function createListener ({ onValidateRecords, onImportRecords, contextParams }) {
  return FlatfileListener.create((listener) => {
    const validationOptions = { chunkSize: VALIDATION_ITEMS_PER_PAGE, parallel: VALIDATION_PARALLELIZATION }
    listener.use(bulkRecordHook(SHEET_SLUG, validateRecordsCallback.bind(null, onValidateRecords, contextParams), validationOptions))
    listener.on('job:ready', { job: 'workbook:submitActionRC' }, onImportReady.bind(null, onImportRecords, contextParams))
  })
}

export async function createImport ({ sheet, onValidateRecords, onImportRecords, onClose, contextParams, user }) {
  const currentLocale = i18next.language

  const flatfileOptions = {
    name: `${sheet.name} ${user.company.name}`,
    publishableKey: process.env.REACT_APP_FLATFILE_API_PUBLIC_KEY,
    workbook: createWorkbook({ sheet }),
    listener: createListener({ onValidateRecords, onImportRecords, contextParams }),
    sidebarConfig: {
      showSidebar: false
    },
    apiUrl: process.env.REACT_APP_FLATFILE_API_URL,
    closeSpace: {
      operation: 'submitActionRC',
      onClose
    },
    userInfo: {
      companyId: user.company.id,
      companyName: user.company.name,
      userId: user.id,
      email: user.email,
      name: user.lastName
    },
    languageOverride: currentLocale
  }

  await initializeFlatfile(flatfileOptions)
}

export const updateEgressProgressBar = async (jobId, pageNumber, totalPages) => {
  await api.jobs.update(jobId, {
    info: t('import.transfertInProgress'),
    progress: Math.min(Math.floor((pageNumber / totalPages) * 100), 100)
  })
}
