import React, { useMemo } from 'react'
import { Route, Switch } from 'react-router'

import ListIntegrations from './integrations/ListIntegrations'
import ManageIntegration from './integrations/ManageIntegration'
import useListAndPollConnections from 'hooks/useListAndPollConnections'

const sources = ['SELLSY']

export default () => {
  const { loading, connections, startPolling } = useListAndPollConnections()

  const connectionsEntries = useMemo(() => {
    return sources.map((source) => {
      const connection = connections.find((connection) => connection.externalSource === source)
      return [source, connection]
    })
  }, [connections])

  return (
    <Switch>
      <Route exact path='/integrations'>
        <ListIntegrations loading={loading} connectionsEntries={connectionsEntries} />
      </Route>

      {connectionsEntries.map(([source, connection]) => (
        <Route key={source} exact path={`/integrations/${source.toLowerCase()}`}>
          <ManageIntegration source={source} connection={connection} startPolling={startPolling} />
        </Route>
      ))}
    </Switch>
  )
}
