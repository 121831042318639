import Button from 'design/Button'
import PropTypes from 'prop-types'
import React, { useCallback, useContext } from 'react'

import { Trans, useTranslation } from 'react-i18next'
import { getBookDemoLink } from 'utils/contact'
import OnboardingContext from './OnboardingContext'
import featureConfig from 'config/features.js'

const OnboardingFooter = ({ prev, next, nextLabel, nextDisabled }) => {
  const { t } = useTranslation()
  const context = useContext(OnboardingContext)
  const onPrev = useCallback(() => {
    context.moveToPrevStep()
  }, [context])

  const onNext = useCallback(() => {
    context.moveToNextStep()
  }, [context])

  return (
    <div>
      <div className='flex justify-end mt-4'>
        {prev && (
          <Button
            onClick={onPrev}
            // type='text'
            className={next ? 'mr-2' : ''}
            label={t('shared.back')}
          />
        )}
        {next && (
          <Button
            primary
            onClick={onNext}
            disabled={nextDisabled}
            label={nextLabel || t('component.onboardingFooter.nextStep')}
          />
        )}
      </div>

      {/* {(signUpSource === 'SELLSY' && (
        <div className='w-full mt-2 text-right'>Besoin d’aide pour prendre en main l’outil ? Contactez votre interlocuteur chez Sellsy pour avoir une démo.</div>
      )) || ( */}
      {featureConfig.demoLinkEnabled && (
        <div className='w-full mt-2 text-right'>
          <Trans
            i18nKey='component.onboardingFooter.demoLinkMessage'
            components={{ Link: <a href={getBookDemoLink()} target='_blank' rel='noopener noreferrer' /> }}
          />
        </div>
      )}

      {/* )} */}
    </div>
  )
}

OnboardingFooter.defaultProps = {
  prev: false,
  next: false,
  nextDisabled: false,
  nextLabel: null
}

OnboardingFooter.propTypes = {
  prev: PropTypes.bool,
  next: PropTypes.bool,
  nextDisabled: PropTypes.bool,
  nextLabel: PropTypes.string
}

export default OnboardingFooter
