import { put, call } from 'redux-saga/effects'
import { get } from 'lodash'
import { actionTypes } from 'redux-resource'
import categoryTrees from 'actions/CategoryTreesActions'

import { generateCategoryTree, createCategory, fetchCategories, updateCategory, moveCategory, deleteCategory } from 'utils/api'

export function * generateCategoryTreeSaga ({ resourceType, requestKey, requestProperties, list }) {
  try {
    const response = yield call(generateCategoryTree, requestProperties.params)
    const type = requestProperties.params.type
    const categories = get(response, 'data.categories', [])
    const tree = get(response, 'data.tree', [])

    yield put({
      type: actionTypes.CREATE_RESOURCES_SUCCEEDED,
      resourceType,
      requestKey,
      list: type,
      resources: categories,
      requestProperties: {
        status: response.status,
        errors: null
      }
    })

    yield put(categoryTrees.UPDATE_CATEGORY_TREE(type, tree))
    yield call(get(requestProperties, 'successCallback', () => true), requestProperties.id)
  } catch (error) {
    yield put({
      type: actionTypes.CREATE_RESOURCES_FAILED,
      resourceType,
      requestKey,
      requestProperties: {
        status: get(error, 'response.status', 0),
        errors: get(error, 'response.data.validation')
      }
    })
  }
}

export function * createCategorySaga ({ resourceType, requestKey, requestProperties }) {
  try {
    const response = yield call(createCategory, requestProperties.params)
    const type = requestProperties.params.type
    const categories = get(response, 'data.categories', [])
    const newCategoryId = response?.data?.newCategories?.[0]?.id

    const tree = get(response, 'data.tree', [])

    yield put({
      type: actionTypes.CREATE_RESOURCES_SUCCEEDED,
      resourceType,
      requestKey,
      list: type,
      resources: categories,
      requestProperties: {
        status: response.status,
        errors: null
      }
    })

    yield put(categoryTrees.UPDATE_CATEGORY_TREE(type, tree))
    yield call(get(requestProperties, 'successCallback', () => true), newCategoryId)
  } catch (error) {
    yield put({
      type: actionTypes.CREATE_RESOURCES_FAILED,
      resourceType,
      requestKey,
      requestProperties: {
        status: get(error, 'response.status', 0),
        errors: get(error, 'response.data.details')
      }
    })
  }
}

export function * readCategorySaga ({ resourceType, requestKey, requestProperties, list }) {
  try {
    const type = requestProperties.type
    const response = yield call(fetchCategories, type)

    const categories = get(response, 'data.categories', [])
    const tree = get(response, 'data.tree', [])

    yield put({
      type: actionTypes.READ_RESOURCES_SUCCEEDED,
      resourceType,
      requestKey,
      list,
      resources: categories,
      requestProperties: {
        status: response.status
      }
    })

    yield put(categoryTrees.UPDATE_CATEGORY_TREE(type, tree))
  } catch (error) {
    yield put({
      type: actionTypes.READ_RESOURCES_FAILED,
      resourceType,
      requestKey,
      requestProperties: {
        status: get(error, 'response.status', 0),
        errors: get(error, 'response.data.details')
      }
    })
  }
}

export function * updateCategorySaga ({ resources, resourceType, requestKey, requestProperties }) {
  try {
    const response = yield call(updateCategory, requestProperties.params)
    const updatedCategory = get(response, 'data.category', {})

    yield put({
      type: actionTypes.UPDATE_RESOURCES_SUCCEEDED,
      resourceType,
      requestKey,
      resources: [updatedCategory],
      requestProperties: {
        status: response.status
      }
    })

    yield call(get(requestProperties, 'successCallback', () => true))
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_RESOURCES_FAILED,
      resourceType,
      requestKey,
      resources,
      requestProperties: {
        status: get(error, 'response.status', 0),
        errors: get(error, 'response.data.details')
      }
    })
  }
}

export function * moveCategorySaga ({ resources, resourceType, requestKey, requestProperties, list }) {
  try {
    const response = yield call(moveCategory, requestProperties.params)
    const categories = get(response, 'data.categories')
    const type = requestProperties.type
    const tree = get(response, 'data.tree')

    yield put({
      type: actionTypes.UPDATE_RESOURCES_SUCCEEDED,
      resourceType,
      requestKey,
      list,
      resources: categories,
      mergeListIds: false,
      requestProperties: {
        status: response.status
      }
    })

    yield put(categoryTrees.UPDATE_CATEGORY_TREE(type, tree))
    yield call(get(requestProperties, 'successCallback', () => true))
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_RESOURCES_FAILED,
      resourceType,
      requestKey,
      resources,
      requestProperties: {
        status: get(error, 'response.status', 0),
        errors: get(error, 'response.data.details')
      }
    })
  }
}

export function * deleteCategorySaga ({ resourceType, requestKey, resources, requestProperties }) {
  try {
    const response = yield call(deleteCategory, requestProperties)
    const type = requestProperties.type
    const categories = get(response, 'data.categories', [])
    const tree = get(response, 'data.tree', [])

    // IF BUG COMPARE MISSING IDS BEFORE AND AFTER
    // const deletedCategoriesIds = get(response, 'data.categories')
    // yield put({
    //   type: actionTypes.DELETE_RESOURCES_SUCCEEDED,
    //   resourceType,
    //   requestKey,
    //   resources: deletedCategoriesIds,
    //   list: type,
    //   mergeListIds: false,
    //   requestProperties: {
    //     status: response.status
    //   }
    // })

    yield put({
      type: actionTypes.READ_RESOURCES_SUCCEEDED,
      resourceType,
      requestKey,
      resources: categories,
      list: type,
      mergeListIds: false,
      requestProperties: {
        status: response.status
      }
    })

    yield put(categoryTrees.UPDATE_CATEGORY_TREE(type, tree))
    yield call(get(requestProperties, 'successCallback', () => true))
  } catch (error) {
    yield put({
      type: actionTypes.DELETE_RESOURCES_FAILED,
      resourceType,
      resources,
      requestKey,
      requestProperties: {
        status: get(error, 'response.status', 0),
        errors: get(error, 'response.data.details')
      }
    })
  }
}
