import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Divider, Select, Tooltip } from 'antd'
import { get } from 'lodash'
import { Link } from 'react-router-dom'
import Button from 'design/Button'
import { useTranslation } from 'react-i18next'
import useSubscriptionScenarioLimit from 'hooks/subscriptions/useSubscriptionScenarioLimit.js'
import classNames from 'classnames'
import { ScenariosContext } from 'contexts/ScenariosContext.js'

const { Option } = Select

const ScenarioSelect = React.forwardRef(({ value, onCreate, ...rest }, ref) => {
  const { t } = useTranslation()
  const { data: scenariosData } = useContext(ScenariosContext)
  const selectedScenarioId = useMemo(() => {
    return scenariosData?.listScenarios.some((scenario) => scenario?.id === value) ? value : undefined
  }, [value, scenariosData])

  const { hasScenarioLimitAttempted, tooltipTitle } = useSubscriptionScenarioLimit()

  return (
    <Select
      ref={ref}
      placeholder={t('component.scenarioSelect.placeholder')}
      notFoundContent={<Link to='/dashboard?action=newScenario'>{t('component.scenarioSelect.notFoundContent')}</Link>}
      value={selectedScenarioId}
      dropdownRender={(menu) => {
        if (!onCreate) return menu

        return (
          <div className='flex flex-col items-stretch'>
            {menu}

            <Divider className='mb-2 mt-0' />
            <Tooltip
              title={hasScenarioLimitAttempted ? tooltipTitle : null}
              placement='bottom'
              className={classNames('flex flex-row py-1', { grayscale: hasScenarioLimitAttempted })}
            >
              <Button disabled={hasScenarioLimitAttempted} onClick={onCreate} label={t('component.scenarioSelect.btn.newScenario')} className='self-center mb-1' />
            </Tooltip>
          </div>
        )
      }}
      {...rest}
    >
      {scenariosData?.listScenarios.map((scenario) => (
        <Option key={scenario.id} value={scenario.id}>
          {get(scenario, 'name')}
        </Option>
      ))}
    </Select>
  )
})

ScenarioSelect.propTypes = {
  value: PropTypes.string,
  onCreate: PropTypes.func
}

export default ScenarioSelect
