import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Badge from 'design/Badge.js'
import numeral from 'numeral'
import CashType from 'enums/CashType.js'
import { useTranslation } from 'react-i18next'

CashflowSheetForecastedCompletionRateOverlay.propTypes = {
  type: PropTypes.oneOf([CashType.CASHIN, CashType.CASHOUT, CashType.CASHFLOW]).isRequired,
  outlineBadge: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
  realisedAmount: PropTypes.number.isRequired,
  forecastedAmount: PropTypes.number.isRequired,
  children: PropTypes.node,
  showPercentage: PropTypes.bool
}

export default function CashflowSheetForecastedCompletionRateOverlay ({ type, outlineBadge, visible, realisedAmount, forecastedAmount, children, showPercentage }) {
  const { t } = useTranslation()

  const rate = useMemo(() => {
    if (!visible) return 0

    if (!forecastedAmount) return realisedAmount ? 1 : 0

    return numeral(realisedAmount || 0).divide(forecastedAmount || 0).value()
  }, [visible, realisedAmount, forecastedAmount])

  const rateLabel = useMemo(() => {
    if (!visible) return null

    if (!forecastedAmount) return t('dashboard.cashflowSheetForecastedCompletionRateOverlay.notBudgeted')

    return numeral(rate).format('0 %')
  }, [visible, rate, forecastedAmount, t])

  const badgeType = useMemo(() => {
    if (rate > 1) {
      switch (type) {
        case CashType.CASHIN:
          return 'success'
        case CashType.CASHOUT:
          return 'error'
        default:
          return 'primary'
      }
    }

    return 'disabled'
  }, [type, rate])

  if (!visible) return children

  const opacityPercentage = showPercentage ? 'opacity-100 group-hover:opacity-0' : 'opacity-0'
  const opacityAmount = showPercentage ? 'opacity-0 group-hover:opacity-100' : 'opacity-100'

  return (
    <div className='relative group h-full w-full'>
      <div className={`h-full ${opacityAmount} transition-opacity`}>
        {children}
      </div>

      <div className={`pointer-events-none z-10 absolute top-0 left-0 bottom-0 right-0 bg-inherit h-full ${opacityPercentage} transition-opacity flex flex-row items-center justify-center`}>
        {((realisedAmount || forecastedAmount) && (
          <Badge label={rateLabel} type={badgeType} outline={outlineBadge} />
        )) || (
          null
        )}
      </div>
    </div>
  )
}
