import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'

import { SET_EXPECTED_TRANSACTION_DETAIL_AMOUNT } from 'graphql/expected-transaction-details'
import CurrencyInput from 'components/CurrencyInput'

const ExpectedTransactionDetailAmountInput = ({ expectedTransactionDetail }) => {
  const type = expectedTransactionDetail.type
  const amount = expectedTransactionDetail.amount
  const [changedAmount, setChangedAmount] = useState(amount)
  const [setExpTxnDetailAmount, { loading }] = useMutation(SET_EXPECTED_TRANSACTION_DETAIL_AMOUNT)

  const handleOnChange = useCallback((changedAmount) => setChangedAmount(changedAmount), [setChangedAmount])

  const handleOnBlur = useCallback(() => {
    if (changedAmount !== amount) {
      setExpTxnDetailAmount({
        variables: {
          id: expectedTransactionDetail.id,
          amount: changedAmount
        }
      })
    }
  }, [setExpTxnDetailAmount, expectedTransactionDetail.id, amount, changedAmount])

  useEffect(() => {
    setChangedAmount(amount)
  }, [setChangedAmount, amount])

  return (
    <CurrencyInput
      disabled={loading}
      loading={loading}
      type={type}
      value={changedAmount}
      onChange={handleOnChange}
      onPressEnter={(event) => { event.target.blur() }}
      onBlur={handleOnBlur}
      options={{ precision: 2 }}
      className='w-full'
    />
  )
}

ExpectedTransactionDetailAmountInput.propTypes = {
  expectedTransactionDetail: PropTypes.object
}

export default ExpectedTransactionDetailAmountInput
