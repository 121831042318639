
import AppActions from 'actions/AppActions'
import { get } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const queryFilters = { withIgnored: true }

export default function useQuoteQueryFilters () {
  const dispatch = useDispatch()

  const memorizedQueryFilters = useSelector(state => get(state, 'app.quotes.queryFilters', queryFilters))
  const [quotesQueryFilters, setQuotesQueryFilters] = useState(memorizedQueryFilters)

  const mergeQuotesQueryFilters = useCallback((filters) => {
    setQuotesQueryFilters({ ...quotesQueryFilters, ...filters })
  }, [quotesQueryFilters, setQuotesQueryFilters])

  // REHYDRATE QUERY FILTERS
  useEffect(() => {
    if (memorizedQueryFilters !== quotesQueryFilters) {
      dispatch(AppActions.setQuotesQueryFilters(quotesQueryFilters))
    }
  }, [dispatch, quotesQueryFilters, memorizedQueryFilters])

  return {
    quotesQueryFilters,
    mergeQuotesQueryFilters
  }
}
