import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { sourceNames, sourceLogos } from 'utils/connections'
import ConnectionBadge from './ConnectionBadge'
import { Cog6ToothIcon } from '@heroicons/react/24/outline'
import { Tooltip } from 'antd'

const IntegrationCard = ({ source, connection, onClick }) => {
  const { t } = useTranslation()

  return (
    <div onClick={onClick} className='relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 cursor-pointer'>
      <div className='flex-shrink-0'>
        <img className='h-auto w-10 rounded-sm' src={sourceLogos[source]} alt={t('integrations.integrationCard.connectorLogoAlt')} />
      </div>
      <div className='flex-1 min-w-0'>
        <span className='focus:outline-none'>
          <div className='w-full flex flex-row justify-between'>
            <span className='text-sm font-medium text-gray-900 grow'>
              {sourceNames[source]}
            </span>
            <Tooltip
              placement='top'
              title={(
                <div className='flex flex-col space-y-4'>
                  {t('integrations.integrationCard.integrationSettings')}
                </div>
              )}
            >

              <Cog6ToothIcon className='w-5 h-5 text-primary mr-2' onClick={onClick} />
            </Tooltip>
            <ConnectionBadge connection={connection} />
          </div>
          <span className='text-sm text-gray-500 truncate'>
            {t('integrations.integrationCard.billingSoftware')}
          </span>
        </span>
      </div>
    </div>
  )
}

IntegrationCard.propTypes = {
  source: PropTypes.string.isRequired,
  connection: PropTypes.object,
  onClick: PropTypes.func
}

export default IntegrationCard
