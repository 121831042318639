import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import PictoLogo from './PictoLogo.js'
import useQuipuAppRootUrl from 'hooks/quipu/useQuipuAppRootUrl.js'

LogoContainer.propTypes = {
  withoutNav: PropTypes.bool
}

function LogoContainer ({ withoutNav }) {
  const quipuAppRootUrl = useQuipuAppRootUrl()

  if (quipuAppRootUrl) {
    return (
      <a href={quipuAppRootUrl} className='rc-sider-element-container rc-logo-container'>
        <PictoLogo />
      </a>
    )
  }

  if (withoutNav) {
    return (
      <div className='rc-sider-element-container rc-logo-container'>
        <PictoLogo />
      </div>
    )
  }

  return (
    <Link to='/dashboard' className='rc-sider-element-container rc-logo-container'>
      <PictoLogo />
    </Link>
  )
}

export default LogoContainer
