import PropTypes from 'prop-types'
import React from 'react'

import useLoadBankconnections from 'hooks/useLoadBankConnections.js'
import LogoutButton from './LogoutButton'
import NavBar from './NavBar'
import SettingsButton from './SettingsButton'
import WispButton from './WispButton'
import LogoContainer from './LogoContainer.js'
import featureConfig from 'config/features.js'

const AppBar = ({ withoutNav }) => {
  useLoadBankconnections()

  return (
    <div className='rc-appbar'>
      <div>
        <LogoContainer withoutNav={withoutNav} />

        {!withoutNav && <NavBar />}
      </div>

      {(withoutNav && (
        <div className='fixed left-0 bottom-4'>
          {featureConfig.logoutEnabled && (<LogoutButton />)}
        </div>
      )) || (
        <div className='fixed left-0 bottom-2'>
          <WispButton />
          <SettingsButton />
        </div>
      )}
    </div>
  )
}

AppBar.defaultProps = {
  withoutNav: false
}

AppBar.propTypes = {
  withoutNav: PropTypes.bool
}

export default AppBar
