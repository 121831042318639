
import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Collapse, Drawer, Tooltip } from 'antd'
import { PaperClipIcon } from '@heroicons/react/24/solid'
import { get } from 'lodash'
import cx from 'classnames'

import ReceiptList from 'components/receipts/ReceiptList'
import TransactionPreview from './TransactionPreview'
import Title from 'design/Title'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { useTranslation } from 'react-i18next'

DocumentDrawerContainer.propTypes = {
  transaction: PropTypes.object,
  tooltipPlacement: PropTypes.string
}

export default function DocumentDrawerContainer ({ transaction, tooltipPlacement }) {
  const { t } = useTranslation()
  const [uploadDrawerVisible, setUploadDrawerVisible] = useState(false)
  const receiptCount = useMemo(() => get(transaction, 'receiptCount', 0), [transaction])
  const hasDocument = useMemo(() => receiptCount > 0, [receiptCount])

  return (
    <>
      <Drawer
        title={<Title label={t('component.documentDrawerContainer.addDocuments')} />}
        closeIcon={<XMarkIcon className='w-5 h-5' />}
        width={540}
        placement='right'
        onClose={() => setUploadDrawerVisible(false)}
        open={uploadDrawerVisible}
      >
        <div className='h-full'>
          <Collapse ghost defaultActiveKey={['1', '2']}>
            <Collapse.Panel header={t('shared.transaction')} key='1'>
              <TransactionPreview transaction={transaction} />
            </Collapse.Panel>

            <Collapse.Panel header={t('component.documentDrawerContainer.documentsCount', { count: receiptCount })} key='2'>
              <ReceiptList transaction={transaction} />
            </Collapse.Panel>
          </Collapse>
        </div>
      </Drawer>

      <Tooltip placement={tooltipPlacement || 'topRight'} mouseEnterDelay={0.4} title={t('component.documentDrawerContainer.addDocuments')}>
        <PaperClipIcon
          onClick={() => setUploadDrawerVisible(true)}
          className={cx('w-6 h-6 cursor-pointer hover:opacity-50', {
            'text-primary': hasDocument,
            'text-gray-300': !hasDocument
          })}
        />
      </Tooltip>
    </>
  )
}
