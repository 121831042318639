import React from 'react'
import PropTypes from 'prop-types'

export default function ExpectedPattern ({ id, stroke }) {
  return (
    <defs>
      <pattern id={id} patternTransform='rotate(-45)' patternUnits='userSpaceOnUse' width='6' height='5'>
        <line x1='1' y1='0' x2='1' y2='5' stroke={stroke} strokeWidth='1.5' />
      </pattern>
    </defs>
  )
}

ExpectedPattern.propTypes = {
  id: PropTypes.string,
  stroke: PropTypes.string
}
