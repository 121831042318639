import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import Button from 'design/Button'

import { CREATE_INVOICE_MANUALLY } from 'graphql/invoices'
import { trim } from 'lodash'
import { useTranslation } from 'react-i18next'
import InvoiceEditDrawer from './InvoiceEditDrawer'

OpenCreateInvoiceManuallyDrawerButton.propTypes = {
  type: PropTypes.oneOf(['cashin', 'cashout']),
  defaultCategoryId: PropTypes.string,
  defaultDueDate: PropTypes.object,
  onSuccess: PropTypes.func
}

function OpenCreateInvoiceManuallyDrawerButton ({ type, defaultCategoryId, defaultDueDate, onSuccess }) {
  const { t } = useTranslation()
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [graphQLErrors, setGraphQLErrors] = useState(null)

  const onError = useCallback(({ graphQLErrors }) => {
    setGraphQLErrors(graphQLErrors)
  }, [setGraphQLErrors])

  const onCompleted = useCallback(() => {
    if (onSuccess) onSuccess()
    setDrawerVisible(false)
  }, [onSuccess, setDrawerVisible])

  const [createInvoiceManually, { loading }] = useMutation(CREATE_INVOICE_MANUALLY, {
    onError,
    onCompleted
  })

  const onClose = useCallback(() => {
    setDrawerVisible(false)
  }, [setDrawerVisible])

  const onFinishCallback = useCallback(({ invoiceParams }) => {
    createInvoiceManually({
      variables: {
        input: {
          thirdPartyName: trim(invoiceParams.thirdPartyName),
          invoiceNumber: trim(invoiceParams.invoiceNumber),
          type,
          issueDate: invoiceParams.issueDate,
          dueDate: invoiceParams.dueDate,
          expectedTransactions: invoiceParams.expectedTransactions.map((expTxnParams) => {
            return {
              ...expTxnParams,
              description: trim(expTxnParams.description),
              expectedDate: expTxnParams.expectedDate,
              expectedTransactionDetails: expTxnParams.expectedTransactionDetails.map((expTxnDetailParams) => {
                return {
                  ...expTxnDetailParams,
                  withVat: undefined
                }
              })
            }
          })
        }
      }
    })
  }, [createInvoiceManually, type])

  return (
    <>
      <Button label={t('component.openCreateInvoiceManuallyDrawerButton.addManually')} onClick={() => setDrawerVisible(true)} />
      <InvoiceEditDrawer mode='create' type={type} loading={loading} visible={drawerVisible} onFinish={onFinishCallback} defaultCategoryId={defaultCategoryId} defaultDueDate={defaultDueDate} onClose={onClose} graphQLErrors={graphQLErrors} />
    </>
  )
}

export default OpenCreateInvoiceManuallyDrawerButton
