import { gql } from '@apollo/client'

export const CATEGORIZE_EXPECTED_TRANSACTION_DETAIL = gql`
  mutation categorizeExpectedTransactionDetail(
    $id: ID!,
    $categoryId: ID
  ) {
    categorizeExpectedTransactionDetail(
      id: $id
      categoryId: $categoryId
    ) {
      id
      categoryId
    }
  }
`

export const CATEGORIZE_MULTIPLE_EXPECTED_TRANSACTION_DETAILS = gql`
  mutation categorizeMultipleExpectedTransactionDetails(
    $ids: [ID!]!,
    $categoryId: ID
  ) {
    categorizeMultipleExpectedTransactionDetails(
      ids: $ids
      categoryId: $categoryId
    ) {
      id
      categoryId
    }
  }
`

export const SET_EXPECTED_TRANSACTION_DETAIL_VAT_RATE = gql`
  mutation setExpectedTransactionDetailVatRate(
    $id: ID!,
    $vatRate: Float!
  ) {
    setExpectedTransactionDetailVatRate(
      id: $id
      vatRate: $vatRate
    ) {
      id
      vatRate
      vatAmount
    }
  }
`

export const SET_EXPECTED_TRANSACTION_DETAIL_AMOUNT = gql`
  mutation setExpectedTransactionDetailAmount(
    $id: ID!,
    $amount: Float!
  ) {
    setExpectedTransactionDetailAmount(
      id: $id
      amount: $amount
    ) {
      id
      amount
      vatAmount
    }
  }
`

export const DELETE_EXPECTED_TRANSACTION_DETAIL = gql`
  mutation deleteExpectedTransactionDetail(
    $id: ID!
  ) {
    deleteExpectedTransactionDetail(id: $id) {
      id
    }
  }
`
