import { Typography } from 'antd'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Badge from 'design/Badge'
import ButtonLink from 'design/ButtonLink'
import { openLiveChat } from 'utils/contact'
import useFreeTrialEnd from 'hooks/subscriptions/useFreeTrialEnd.js'
import useAuthenticatedContext from 'hooks/useAuthenticatedContext.js'

const { Title } = Typography

export default () => {
  const { t } = useTranslation()
  const { subscription } = useAuthenticatedContext()
  const { formattedFreeTrialEndAt, isOverFreeTrial } = useFreeTrialEnd()

  const plan = subscription?.plan

  return (
    <div className='flex flex-col items-center'>
      {(subscription?.id && (
        <>
          <Title level={3} className='flex flex-row items-center'>
            <Trans
              i18nKey='subscribe.sellsySubscriptionMessage.youAreOnPlanX'
              components={{ Badge: <Badge type='info' label={t(`utils.plans.planLocales.${plan}`)} className='text-lg ml-2' /> }}
            />

          </Title>

          <div>
            {t('subscribe.sellsySubscriptionMessage.contactSellsy')}
          </div>

          <br />

          <div>
            <Trans
              i18nKey='subscribe.sellsySubscriptionMessage.contactUsForAnyQuestion'
              components={{ ButtonLink: <ButtonLink onClick={openLiveChat} label={t('subscribe.sellsySubscriptionMessage.contactUs')} /> }}
            />

          </div>
        </>
      )) || (
        <>
          <div className='text-6xl mb-4'><span role='img' aria-label='rocket'>🚀</span></div>
          <Title level={3}>{t('subscribe.sellsySubscriptionMessage.subscribeToRocketChart')}</Title>

          <div className='w-full max-w-2xl mt-8 space-y-4 flex flex-col items-start'>
            <div>
              {t('subscribe.sellsySubscriptionMessage.rocketChartIsAComplementaryModuleToSellsy')}
              {' '}
              {(isOverFreeTrial && (
                <div>
                  {t('subscribe.sellsySubscriptionMessage.yourTrialPeriodIsFinished')}
                </div>
              )) || (
                <div>
                  <Trans
                    i18nKey='subscribe.sellsySubscriptionMessage.yourTrialPeriodFinishesOn'
                    components={{ Span: <span className='font-bold' /> }}
                    values={{ formattedFreeTrialEndAt }}
                  />

                </div>
              )}
            </div>

            {(isOverFreeTrial && (
              <div>
                <Trans
                  i18nKey='subscribe.sellsySubscriptionMessage.reinsuranceMsg.trialEnded'
                  components={{ Span: <span className='font-bold' /> }}
                />
              </div>
            )) || (
              <div>
                <Trans
                  i18nKey='subscribe.sellsySubscriptionMessage.reinsuranceMsg.trialNotEnded'
                  components={{ Span: <span className='font-bold' /> }}
                />
              </div>
            )}

            <div>
              {t('subscribe.sellsySubscriptionMessage.doYouHaveAContactAtSellsy')}
            </div>

            <div>
              <Trans
                i18nKey='subscribe.sellsySubscriptionMessage.forAllQuestionsPleaseContactUs'
                components={{ ButtonLink: <ButtonLink onClick={openLiveChat} label={t('subscribe.sellsySubscriptionMessage.contactUs')} /> }}
              />

            </div>
          </div>
        </>
      )}

    </div>
  )
}
