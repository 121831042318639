import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

const Dot = ({ bordered, color, size, style, ...rest }) => {
  const pixelSize = useMemo(() => {
    switch (size) {
      case 'x-small':
        return 10
      case 'small':
        return 14
      case 'normal':
        return 16
      case 'medium':
        return 24
      case 'large':
        return 28
      default:
        console.warn(`Wrong size for dot: ${size}`)
    }
  }, [size])

  return (
    <div
      style={{
        display: 'inline-block',
        width: pixelSize,
        height: pixelSize,
        borderRadius: pixelSize / 2,
        backgroundColor: color,
        border: bordered ? '2px solid white' : null,
        ...style
      }}
      {...rest}
    />
  )
}

Dot.defaultProps = {
  bordered: false,
  color: '#ccc',
  size: 'normal',
  style: {}
}

Dot.propTypes = {
  bordered: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.oneOf(['small', 'normal', 'medium', 'large']),
  style: PropTypes.object
}

export default Dot
