import { useMutation } from '@apollo/client'
import { Dropdown, Tooltip } from 'antd'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'
import classNames from 'classnames'
import confirm from 'antd/lib/modal/confirm'
import { BanknotesIcon as BanknotesIconOutline } from '@heroicons/react/24/outline'
import { BanknotesIcon, EyeIcon, EyeSlashIcon, TrashIcon, PencilSquareIcon } from '@heroicons/react/24/solid'
import ActionsIcon from 'design/ActionsIcon'
import { MARK_EXPECTED_TRANSACTIONS_AS_INVOICED } from 'graphql/expected-transactions'
import { useTranslation } from 'react-i18next'
import { DELETE_QUOTE_FROM_IDS, IGNORE_QUOTES } from 'graphql/quotes.js'
import AccountingDocumentPreviewButton from 'components/accounting-documents/AccountingDocumentPreviewButton.js'

QuoteActions.propTypes = {
  quote: PropTypes.object,
  editQuote: PropTypes.func,
  refetchQuotes: PropTypes.func
}

export default function QuoteActions ({ quote, editQuote, refetchQuotes }) {
  const { t } = useTranslation()
  const allExpectedTransactionsAreInvoiced = useMemo(() => {
    const nonInvoicedExpTxn = quote.expectedTransactions.find((expTxn) => !expTxn.paymentDate)
    return !nonInvoicedExpTxn
  }, [quote])

  const expectedTransactionIds = useMemo(() => quote.expectedTransactions.map((expTxn) => expTxn.id), [quote])

  const [ignoreQuotes, { loading: ignoreLoading }] = useMutation(IGNORE_QUOTES, {
    variables: { input: { quoteIds: [quote.id], cancel: quote.ignored } }
  })

  const [markExpectedTransactionAsInvoiced, { loading: markAsInvoicedLoading }] = useMutation(MARK_EXPECTED_TRANSACTIONS_AS_INVOICED, {
    variables: { input: { expectedTransactionIds, cancel: allExpectedTransactionsAreInvoiced } }
  })

  const editQuoteCallback = useCallback(() => {
    editQuote({ quote })
  }, [editQuote, quote])

  const [deleteQuote, { loading: deleteLoading }] = useMutation(DELETE_QUOTE_FROM_IDS, {
    variables: { quoteIds: [quote?.id] },
    onCompleted: refetchQuotes
  })

  const handleMenuClick = useCallback(({ key }) => {
    switch (key) {
      case 'ignore':
        ignoreQuotes()
        break
      case 'markAsInvoiced':
        markExpectedTransactionAsInvoiced()
        break
      case 'edit':
        editQuoteCallback()
        break
      case 'delete':
        confirm({
          title: t('component.quoteActions.doYouReallyWantToDeleteThisInvoice'),
          okType: 'danger',
          okText: t('shared.delete'),
          cancelText: t('shared.no'),
          maskClosable: true,
          onOk () {
            deleteQuote()
          }
        })
        break
      default:
        console.warn(`${key} button not handled`)
    }
  }, [ignoreQuotes, markExpectedTransactionAsInvoiced, editQuoteCallback, deleteQuote, t])

  const loading = useMemo(() => (ignoreLoading || markAsInvoicedLoading || deleteLoading), [ignoreLoading, markAsInvoicedLoading, deleteLoading])
  const canBeEdited = useMemo(() => (['upload', 'manual'].includes(quote.externalSource)), [quote.externalSource])

  return (
    <div className='flex flex-row items-center justify-end w-full space-x-1'>
      <AccountingDocumentPreviewButton accountingDocument={quote} />
      <Dropdown
        disabled={loading}
        placement='bottomRight'
        menu={{
          onClick: handleMenuClick,
          items: [
            {
              key: 'ignore',
              label: (
                <Tooltip
                  title={t('component.quoteActions.tooltip.ignoredQuote')}
                  placement='left'
                  className='flex items-center'
                >
                  {(quote.ignored && <EyeIcon className='w-4 h-4 mr-2 inline' />) || <EyeSlashIcon className='w-4 h-4 mr-2 inline' />}
                  {quote.ignored ? t('shared.unIgnore') : t('shared.ignore')}
                </Tooltip>
              )
            }, {
              key: 'markAsInvoiced',
              label: (
                <Tooltip
                  title={t('component.quoteActions.invoicedExpectedTransactionNotTakenIntoAccountAnymore')}
                  placement='left'
                  className='flex items-center'
                >
                  {allExpectedTransactionsAreInvoiced ? <BanknotesIconOutline className='w-4 h-4 mr-2 inline' /> : <BanknotesIcon className='w-4 h-4 mr-2 inline' />}
                  {allExpectedTransactionsAreInvoiced ? t('component.quoteActions.unmarkAsInvoiced') : t('component.quoteActions.markAsInvoiced')}
                </Tooltip>
              )
            }, {
              key: 'edit',
              disabled: !canBeEdited,
              label: (
                <Tooltip
                  title={canBeEdited ? null : t('component.quoteActions.tooltip.cannotBeUpdated')}
                  placement='left'
                  className='flex items-center'
                >
                  <PencilSquareIcon className={classNames('w-4 h-4 mr-2 inline', { 'text-gray-200': !canBeEdited })} /> {t('shared.edit')}
                </Tooltip>
              )
            }, {
              key: 'delete',
              disabled: !canBeEdited,
              label: (
                <Tooltip
                  title={canBeEdited ? null : t('component.quoteActions.tooltip.cannotBeDeleted')}
                  placement='left'
                  className='flex items-center'
                >
                  <TrashIcon className={classNames('w-4 h-4 mr-2 inline', { 'text-error': canBeEdited, 'text-gray-200': !canBeEdited })} /> {t('shared.delete')}
                </Tooltip>
              )
            }
          ]
        }}
      >
        <ActionsIcon loading={loading} />
      </Dropdown>
    </div>
  )
}
