import React, { useEffect, useState } from 'react'
import { Layout } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { get } from 'lodash'
import Spinner from 'design/Spinner'
import AuthActions from 'actions/AuthActions'
import useSearchParams from 'hooks/useSearchParams'
import { Redirect } from 'react-router'

const AutoSignInScreen = () => {
  const dispatch = useDispatch()

  const { e, p } = useSearchParams()

  const isAuthenticated = useSelector(state => get(state, 'app.isAuthenticated'))
  const [ensureHasLogOut, setEnsureHasLogout] = useState(!isAuthenticated)

  useEffect(() => {
    if (!ensureHasLogOut && isAuthenticated) {
      dispatch(AuthActions.signOut())
    } else if (!ensureHasLogOut && !isAuthenticated) {
      setEnsureHasLogout(true)
    }
  }, [dispatch, ensureHasLogOut, isAuthenticated, setEnsureHasLogout])

  useEffect(() => {
    if (!isAuthenticated && e && p) {
      dispatch(AuthActions.requestSignIn(e, p))
    }
  }, [isAuthenticated, dispatch, e, p])

  if (ensureHasLogOut && isAuthenticated) {
    return <Redirect to='/' />
  }

  return (
    <Layout className='flex items-center justify-center'>
      <Spinner className='w-6 h-6 text-primary' />
    </Layout>
  )
}

export default AutoSignInScreen
