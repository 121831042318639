import React from 'react'
import PropTypes from 'prop-types'
import { Tag } from 'antd'
import { get } from 'lodash'
import { getAccessibleTextColor } from 'utils/colors'

const getTagStyle = (category, checkable, checked) => {
  const color = get(category, 'color')

  if (color && checkable && checked) {
    // const textColor = getAccessibleTextColor(color)
    return { backgroundColor: color }
  }

  return null
}

const CategoryTag = ({ category, checkable, style, ...rest }) => {
  const TagComponent = checkable ? Tag.CheckableTag : Tag
  const color = get(category, 'color')
  const textColor = getAccessibleTextColor(color)

  return (
    <TagComponent
      color={color}
      {...rest}
      style={{ ...getTagStyle(category, checkable, get(rest, 'checked')), ...style }}
    >
      <span style={{ color: textColor }}>{get(category, 'name')}</span>
    </TagComponent>
  )
}

CategoryTag.propTypes = {
  category: PropTypes.object,
  checkable: PropTypes.bool,
  style: PropTypes.object
}

export default CategoryTag
