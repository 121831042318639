import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import Spinner from 'design/Spinner'

export default function DocumentViewerModal ({ title, url, open, onClose }) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    setIsModalOpen(open)
  }, [open])

  const handleOk = () => {
    setIsModalOpen(false)
    onClose()
  }

  return (
    <Modal
      title={title}
      open={isModalOpen}
      onCancel={handleOk}
      footer={null}
      className='w-4/5 h-4/5 pdf-viewer-modal z-20'
      destroyOnClose
    >
      <div className='w-full h-full flex items-center justify-center'>
        <Spinner className='absolute w-5 h-5 text-primary z-0' />
        <embed src={url} className='h-full w-full z-20' />
      </div>
    </Modal>
  )
}

DocumentViewerModal.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func
}
