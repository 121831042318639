import { useQuery } from '@apollo/client'
import { useDispatch } from 'react-redux'

import { LIST_ALL_TRANSACTION } from 'graphql/transactions'
import AggregationsActions from 'actions/AggregationsActions'

export default ({ type }) => {
  const dispatch = useDispatch()

  return useQuery(LIST_ALL_TRANSACTION, {
    variables: {
      type,
      toValidateOnly: true,
      offset: 0,
      limit: 1
    },
    onCompleted: (data) => {
      const total = data?.allTransactions?.total
      dispatch(AggregationsActions.requestToValidateTotalSuccess(type, total))
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  })
}
