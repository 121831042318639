import { gql } from '@apollo/client'

export const GET_SELLSY_OAUTH_REDIRECTION_URL = gql`
  query getSellsyOauthRedirectionUrl {
    getSellsyOauthRedirectionUrl
  }
`

export const GET_SELLSY_INVOICE_DOCUMENT_URL = gql`
  query getSellsyInvoiceDocumentUrl($invoiceId: String!) {
    getSellsyInvoiceDocumentUrl(invoiceId: $invoiceId)
  }
`

export const GET_SELLSY_QUOTE_DOCUMENT_URL = gql`
  query getSellsyQuoteDocumentUrl($quoteId: String!) {
    getSellsyQuoteDocumentUrl(quoteId: $quoteId)
  }
`

export const GET_SELLSY_ORDER_DOCUMENT_URL = gql`
  query getSellsyOrderDocumentUrl($orderId: String!) {
    getSellsyOrderDocumentUrl(orderId: $orderId)
  }
`

export const CONNECT_OR_RECONNECT_SELLSY = gql`
  mutation connectOrReconnectSellsy($authorizationCode: String!) {
    connectOrReconnectSellsy(authorizationCode: $authorizationCode) {
      id
      externalSource
      status
      firstSyncingStartAt
      firstSyncingEndAt
      lastSyncingAt
    }
  }
`

export const ACTIVATE_SELLSY_QUOTES = gql`
  mutation activateSellsyQuotes($connectionId: ID!, $state: Boolean!) {
    activateSellsyQuotes(input: {id: $connectionId, state: $state}) {
      id
      externalSource
      status
      firstSyncingStartAt
      firstSyncingEndAt
      lastSyncingAt
      hasSyncedQuoteOnce
      config {
        userId
        hasSyncDraftInvoice
        hasSyncQuote
        hasSyncRecurringPayment
        hasSyncOrder
      }
    }
  }
`

export const ACTIVATE_SELLSY_RECURRING_PAYMENTS = gql`
  mutation activateSellsyRecurringPayments($connectionId: ID!, $state: Boolean!) {
    activateSellsyRecurringPayments(input: {id: $connectionId, state: $state}) {
      id
      externalSource
      status
      firstSyncingStartAt
      firstSyncingEndAt
      lastSyncingAt
      hasSyncedQuoteOnce
      config {
        userId
        hasSyncDraftInvoice
        hasSyncQuote
        hasSyncRecurringPayment
        hasSyncOrder
      }
    }
  }
`

export const ACTIVATE_SELLSY_ORDERS = gql`
  mutation activateSellsyOrders($connectionId: ID!, $state: Boolean!) {
    activateSellsyOrders(input: {id: $connectionId, state: $state}) {
      id
      externalSource
      status
      firstSyncingStartAt
      firstSyncingEndAt
      lastSyncingAt
      hasSyncedQuoteOnce
      config {
        userId
        hasSyncDraftInvoice
        hasSyncQuote
        hasSyncRecurringPayment
        hasSyncOrder
      }
    }
  }
`
