import { LeftOutlined, LockOutlined } from '@ant-design/icons'
import { Button, Card, Col, Input, Row, Typography, message, Form } from 'antd'
import { get } from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'

import authActions from 'actions/AuthActions'
import useSearchParams from 'hooks/useSearchParams'
import { setFieldsError } from 'utils/legacy-forms'

const { Title, Text } = Typography

const ResetPasswordScreen = () => {
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const { token } = useSearchParams()
  const isResettingPassword = useSelector(state => get(state, 'auth.isResettingPassword'))
  const resetErrors = useSelector(state => get(state, 'auth.resetPasswordErrors'))
  const { setFields, getFieldValue } = form

  const onSuccess = useCallback(() => {
    message.success(t('resetPassword.passwordUpdated'))
    history.push('signin')
  }, [history, t])

  useEffect(() => {
    setFieldsError(resetErrors, setFields, getFieldValue)
  }, [history, resetErrors, setFields, getFieldValue])

  const handleSubmit = (values) => {
    dispatch(authActions.resetPassword(
      { token, ...values },
      onSuccess,
      () => message.error(t('resetPassword.requestExpired'))
    ))
  }

  const validatePasswordMatch = useCallback((rule, value, callback) => {
    if (value && value !== form.getFieldValue('newPassword')) {
      callback(new Error(t('resetPassword.passwordsNotIdentical')))
    } else {
      callback()
    }
  }, [form, t])

  return (
    <div className='rc-full-page-box-container'>
      <div className='absolute top-2 right-2'>
        <Text>{t('resetPassword.stillNoAccount')}</Text>
        <br />
        <Link to='/signup'>{t('resetPassword.freeTrialPeriod')}</Link>
      </div>

      <div className='rc-signin-logo-container'>
        <img src='/logo_sellsy_tresorerie.svg' alt='Sellsy Trésorerie logo' className='rc-signin-logo' />
      </div>

      <Card bordered={false} className='rc-box-container'>
        <Row>
          <Col span={2}>
            <Link to='/signin'><Button shape='circle' icon={<LeftOutlined />} /></Link>
          </Col>
          <Col span={20} align='center'>
            <Title level={4}>{t('resetPassword.newPassword')}</Title>
          </Col>
        </Row>

        <br />

        <Form form={form} layout='vertical' requiredMark={false} onFinish={handleSubmit}>
          <Form.Item
            name='newPassword'
            validateTrigger='onBlur'
            rules={[
              { required: true, message: t('resetPassword.pleaseEnterANewPassword') },
              { min: 8, message: t('resetPassword.minCharacters') }
            ]}
            label={t('resetPassword.password')}
          >
            <Input.Password
              size='large'
              prefix={<LockOutlined style={{ color: 'rgba(0, 0, 0, .25)' }} />}
              placeholder={t('resetPassword.password')}
            />
          </Form.Item>

          <Form.Item
            name='newPasswordAgain'
            validateTrigger='onBlur'
            rules={[
              { required: true, message: t('resetPassword.pleaseConfirmPassword') },
              { validator: validatePasswordMatch }
            ]}
            label={t('resetPassword.confirmPassword')}
          >
            <Input.Password
              size='large'
              prefix={<LockOutlined style={{ color: 'rgba(0, 0, 0, .25)' }} />}
              placeholder={t('resetPassword.password')}
            />
          </Form.Item>

          <Form.Item>
            <Button size='large' loading={isResettingPassword} block type='primary' htmlType='submit'>
              {t('resetPassword.button.saveNewPassword')}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  )
}

export default ResetPasswordScreen
