import React, { useContext, useMemo } from 'react'

import SimpleChart from 'components/charts/SimpleChart'
import CashInfo from 'components/CashInfo'
import { c } from 'utils/currencies'
import CashflowSheetContext from 'contexts/CashflowSheetContext'
import { useTranslation } from 'react-i18next'

function DashboardCashinCard () {
  const { t } = useTranslation()
  const { currentMonthRealised, sheet } = useContext(CashflowSheetContext)
  const dataUntilNow = useMemo(() => {
    return (sheet || []).filter(({ value }) => value?.realisedCashin !== null)
  }, [sheet])

  const realisedCashin = useMemo(() => {
    return c(currentMonthRealised?.value?.realisedCashin)
  }, [currentMonthRealised])

  return (
    <div className='relative h-full bg-white rounded-md p-4'>
      <CashInfo
        title={t('dashboard.cashinCard.cashInfo.title')}
        text={realisedCashin}
        color='cashin'
        linkTo='/cash-in'
      />

      <div className='rc-full-card-graph'>
        <SimpleChart
          data={dataUntilNow}
          color='cashin'
          dataKey='value.realisedCashin'
        />
      </div>
    </div>
  )
}

export default DashboardCashinCard
