export default {
  DEFAULT:
    {
      DRAFT: 'DRAFT',
      SENT: 'SENT',
      READ: 'READ',
      ACCEPTED: 'ACCEPTED',
      PARTIAL_INVOICED: 'PARTIAL_INVOICED',
      DEPOSIT: 'DEPOSIT',
      INVOICED: 'INVOICED',
      EXPIRED: 'EXPIRED',
      CANCELED: 'CANCELED'
    }
}
