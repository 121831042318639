import React, { useContext, useMemo } from 'react'

import SimpleChart from 'components/charts/SimpleChart'
import CashInfo from 'components/CashInfo'
import { c } from 'utils/currencies'
import CashflowSheetContext from 'contexts/CashflowSheetContext'
import { useTranslation } from 'react-i18next'

function DashboardCashoutCard () {
  const { currentMonthRealised, sheet } = useContext(CashflowSheetContext)
  const { t } = useTranslation()

  const dataUntilNow = useMemo(() => {
    return (sheet || []).filter(({ value }) => value?.realisedCashout !== null)
  }, [sheet])

  const realisedCashout = useMemo(() => {
    return c(currentMonthRealised?.value?.realisedCashout)
  }, [currentMonthRealised])

  return (
    <div className='relative h-full bg-white rounded-md p-4'>
      <CashInfo
        title={t('dashboard.cashoutCard.cashInfo.title')}
        text={realisedCashout}
        color='cashout'
        linkTo='/cash-out'
      />

      <div className='rc-full-card-graph'>
        <SimpleChart
          data={dataUntilNow}
          color='cashout'
          dataKey='value.realisedCashout'
        />
      </div>
    </div>
  )
}

export default DashboardCashoutCard
