import resourcesActions from 'actions/ResourcesActions'
import { Modal } from 'antd'
import CategoryTag from 'components/categories/CategoryTag'
import React, { useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getStatus } from 'redux-resource'

export default ({ category }) => {
  const { t } = useTranslation()
  const deleteStatus = useSelector(state => getStatus(state, `categories.requests.deleteCategory-${category.id}.status`))

  const dispatch = useDispatch()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const openDeleteCategoryPopup = useCallback(() => {
    setIsModalVisible(true)
  }, [setIsModalVisible])

  const closeDeleteCategoryPopup = useCallback(() => {
    setIsModalVisible(false)
  }, [setIsModalVisible])

  const deleteCategory = useCallback(() => {
    dispatch(resourcesActions.DELETE_CATEGORY(category.type, category.id, closeDeleteCategoryPopup))
  }, [dispatch, category.type, category.id, closeDeleteCategoryPopup])

  const deleteCategoryModal = (
    <Modal
      width={620}
      destroyOnClose
      confirmLoading={deleteStatus.pending}
      title={t('hook.useDeleteCategory.deleteCategory')}
      open={isModalVisible}
      onClose={closeDeleteCategoryPopup}
      onCancel={closeDeleteCategoryPopup}
      onOk={deleteCategory}
      okText={t('shared.delete')}
      okType='danger'
      cancelText={t('shared.cancel')}
    >
      <div className='flex flex-col'>
        <span className='font-bold mb-2'>
          <Trans
            i18nKey='hook.useDeleteCategory.doYouReallyWantToDeleteCategoryX'
            components={{ CategoryTag: <CategoryTag category={category} className='mx-1' /> }}
          />

        </span>
        <span>{t('hook.useDeleteCategory.allSubCategoriesWillBeDeleted')}</span>
        <span>{t('hook.useDeleteCategory.allAssignedCategoriesWillBecomeUnassigned')}</span>
      </div>
    </Modal>
  )

  return {
    openDeleteCategoryPopup,
    deleteCategoryModal
  }
}
