import React from 'react'
import { Spin } from 'antd'

const ReloadScreen = () => {
  return (
    <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
      <Spin size='large' />
    </div>
  )
}

export default ReloadScreen
