export const businessTypes = [
  'restoration',
  'industry',
  'merchant',
  'software',
  'agencies/medias',
  'consulting',
  'enterprise_services',
  'construction',
  'real_estate',
  'leisure/tourism',
  'health',
  'hotel',
  'education',
  'wholesaling',
  'public',
  'sport/wellness',
  'other'
]
