import React from 'react'
import PropTypes from 'prop-types'

// const topRadius = 5

const RealisedBar = ({ fill, x, y, width, height }) => {
  // const dotLineHeight = (height || 0) - 2
  // const id = useMemo(() => `${x}-${y}-top-round-corner`, [x, y])

  if (!height) return null

  return (
    <g fill={fill}>
      {/* <defs>
        <clipPath id={id}>
          <rect x={x} y={y} width={width} height={height + topRadius} rx={topRadius} ry={topRadius} />
        </clipPath>
      </defs> */}

      <rect
        x={x || 0}
        y={y || 0}
        width={width || 0}
        height={height || 0}
        // clipPath={`url(#${id})`}
      />
    </g>
  )
}

RealisedBar.defaultProps = {
  fill: '#000000',
  x: 0,
  y: 0,
  width: 0,
  height: 0
}

RealisedBar.propTypes = {
  fill: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default RealisedBar
