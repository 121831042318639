import React from 'react'
import PropTypes from 'prop-types'
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from 'recharts'
import { get } from 'lodash'

import CategoryPieChartTooltip from './CategoryPieChartTooltip'
import PercentLabel from './shapes/PercentLabel'

export default function CategoryPieChart ({ isFetching, mainCategory, dataKey }) {
  const children = get(mainCategory, 'children', [])
  const mainCatColor = get(mainCategory, 'color')

  return (
    <ResponsiveContainer>
      <PieChart>
        <Pie
          isAnimationActive={!isFetching}
          data={children}
          dataKey={dataKey}
          label={PercentLabel}
          labelLine={false}
          outerRadius='80%'
        >
          {children.map((entry, index) => (
            <Cell key={index} fill={get(entry, 'color')} fillOpacity={0.8} stroke={get(entry, 'color')} strokeWidth={2} />
          ))}
        </Pie>

        <Pie
          isAnimationActive={!isFetching}
          data={[mainCategory]}
          dataKey={dataKey}
          innerRadius='85%'
          outerRadius='100%'
        >
          <Cell fill={mainCatColor} fillOpacity={0.8} stroke={mainCatColor} strokeWidth={2} />
        </Pie>

        <Tooltip
          content={({ payload }) => (
            <CategoryPieChartTooltip params={get(payload, '0')} dataKey={dataKey} />
          )}
        />
      </PieChart>
    </ResponsiveContainer>
  )
}

CategoryPieChart.propTypes = {
  isFetching: PropTypes.bool,
  mainCategory: PropTypes.object,
  dataKey: PropTypes.string
}
