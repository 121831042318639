import { Tooltip } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router'

import Button from 'design/Button'
import useSyncConnection from 'hooks/useSyncConnection'
import { useTranslation } from 'react-i18next'
import { sourceLogos, sourceNames } from 'utils/connections'

const ExpectedDocumentsConnectorPreview = ({ connection, startPolling }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const { syncConnection, syncing } = useSyncConnection({ connection, startPolling })

  const lastSyncing = useMemo(() => {
    if (!connection?.lastSyncingAt) return
    const formattedDate = moment(connection.lastSyncingAt).format('D MMM - HH:mm')
    const connectionStatusLabel = connection?.status === 'DISCONNECTED' ? `, ${t('component.invoiceConnectorPreview.loginNeeded')}` : ''
    return t('component.invoiceConnectorPreview.lastSyncOn', { formattedDate, connectionStatusLabel })
  }, [connection.lastSyncingAt, connection?.status, t])

  return (
    <div className='w-max flex flex-row items-center ml-4 pl-4 border-l border-gray-200'>
      <Tooltip title={sourceNames[connection?.externalSource]}>
        <img alt='connector logo' src={sourceLogos[connection?.externalSource]} className='h-auto w-8 mr-4' />
      </Tooltip>
      <Tooltip mouseEnterDelay={0.4} title={lastSyncing}>
        {(connection?.status === 'DISCONNECTED' && (
          <Button
            onClick={() => { history.push(`/integrations/${connection?.externalSource.toLowerCase()}`) }}
            warning
            label={t('component.invoiceConnectorPreview.reconnect')}
          />
        )) || (
          <Button
            loading={syncing}
            onClick={syncConnection}
            label={syncing ? t('component.invoiceConnectorPreview.syncing') : t('component.invoiceConnectorPreview.sync')}
          />
        )}

      </Tooltip>
    </div>
  )
}

ExpectedDocumentsConnectorPreview.propTypes = {
  connection: PropTypes.object,
  startPolling: PropTypes.func
}

export default ExpectedDocumentsConnectorPreview
