import { useMutation, useQuery } from '@apollo/client'
import { Upload, message } from 'antd'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { CloudArrowUpIcon } from '@heroicons/react/24/solid'
import Spinner from 'design/Spinner'
import { CREATE_RECEIPT, DELETE_RECEIPT, LIST_TRANSACTION_RECEIPTS } from 'graphql/receipts'
import { useTranslation } from 'react-i18next'

const receiptToFile = (receipt) => {
  return {
    uid: receipt?.id,
    name: receipt?.document?.filename,
    status: 'done',
    // %23 escaping #
    url: receipt?.document?.url
  }
}

const ReceiptList = ({ transaction }) => {
  const { t } = useTranslation()
  const [fileList, setFileList] = useState([])

  const { loading } = useQuery(LIST_TRANSACTION_RECEIPTS, {
    variables: { transactionId: transaction.id },
    onCompleted: (data) => {
      const files = get(data, 'receipts', []).map(receiptToFile)
      setFileList([...fileList, ...files])
    }
  })

  const [createReceiptMutation, { loading: creating }] = useMutation(CREATE_RECEIPT, {
    onCompleted: (data) => {
      setFileList([...fileList, receiptToFile(data?.createReceipt)])
    },
    onError: () => {
      message.error(t('component.receiptList.errorWhileUploadingDocument'))
    }
  })

  const [deleteReceiptMutation] = useMutation(DELETE_RECEIPT, {
    onCompleted: (data) => {
      const idToDelete = data?.deleteReceipt?.id
      const filteredFileList = fileList.filter((file) => file.uid !== idToDelete)
      setFileList(filteredFileList)
    },
    onError: () => {
      message.error(t('component.receiptList.errorWhileDeletingDocument'))
    }
  })

  const handleUpload = useCallback((file) => {
    const isValid = file.size / 1024 / 1024 < 16

    if (isValid) {
      createReceiptMutation({ variables: { transactionId: transaction.id, documentFile: file } })
    } else {
      message.error(t('component.receiptList.sizeLimitExceeded'))
    }

    return false
  }, [createReceiptMutation, t, transaction.id])

  const handleRemove = useCallback((file) => {
    const receiptId = file.uid
    deleteReceiptMutation({ variables: { id: receiptId } })
    return false
  }, [deleteReceiptMutation])

  return (
    <Upload.Dragger
      beforeUpload={handleUpload}
      fileList={fileList}
      onRemove={handleRemove}
      disabled={loading || creating}
      multiple
      listType='picture'
      height={140}
    >
      <div className='flex flex-col justify-center items-center'>
        <div className='flex flex-row justify-center items-center'>
          <CloudArrowUpIcon className='w-6 h-6 mr-4' />
          {t('component.receiptList.clickOrDragAndDrop')}
        </div>

        {(loading || creating) && (<Spinner className='w-6 h-6 mt-4 text-primary' />)}
      </div>
    </Upload.Dragger>
  )
}

ReceiptList.propTypes = {
  transaction: PropTypes.object
}

export default ReceiptList
