import { get } from 'lodash'
import { createSelector } from 'reselect'

export const getCountry = (state) => get(state, 'app.country')

export const getUserOptions = createSelector(
  () => {},
  (user) => get(user, 'options', {})
)

// FIXME move this into back
export const getVatRates = createSelector(
  getCountry,
  (country) => {
    switch (country) {
      case 'FR':
        return [0, 0.021, 0.055, 0.085, 0.1, 0.2]
      case 'BE':
        return [0, 0.06, 0.12, 0.21]
      case 'LU':
        return [0, 0.03, 0.08, 0.14, 0.17]
      case 'DE':
        return [0, 0.07, 0.19]
      case 'ES':
        return [0, 0.03, 0.04, 0.05, 0.07, 0.1, 0.21]
      default:
        return []
    }
  }
)
