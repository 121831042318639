import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ButtonLink from 'design/ButtonLink'
import { upperFirst } from 'lodash'
import Button from 'design/Button'
import { Modal, Input } from 'antd'
import { useTranslation, Trans } from 'react-i18next'

UpgradePopup.propTypes = {
  params: PropTypes.shape({
    visible: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    plan: PropTypes.string,
    recurrence: PropTypes.string
  }).isRequired,
  setVisible: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
}

export default function UpgradePopup ({ params, setVisible, onConfirm }) {
  const { t } = useTranslation()
  const [promoCodeVisible, setPromoCodeVisible] = useState(false)
  const [promoCodeInput, setPromoCodeInput] = useState(null)
  const [inUsePromoCode, setInUsePromoCode] = useState(null)

  return (
    <Modal
      open={params.visible}
      closable={false}
      onCancel={() => setVisible(false)}
      centered
      footer={null}
    >
      <div className='flex flex-col items-start justify-start space-y-8'>
        <div className='flex flex-col items-start justify-start'>
          <div>
            <Trans
              i18nKey='component.upgradePopup.aboutToUpgradeToPlan'
              components={{ Span: <span className='font-bold' /> }}
              values={{ planName: upperFirst(params.plan?.toLowerCase()) }}
            />
          </div>

          <ButtonLink label={t('component.upgradePopup.doYouHaveAPromoCode')} onClick={() => setPromoCodeVisible(!promoCodeVisible)} />

          {promoCodeVisible && (
            <div className='flex flex-row items-center justify-start space-x-2 mt-2'>
              <Input size='large' placeholder={t('component.upgradePopup.promoCode')} value={promoCodeInput} onChange={(event) => setPromoCodeInput(event.target.value)} className='max-w-sm' />
              <Button disabled={!promoCodeInput} primary label={t('shared.add')} onClick={() => setInUsePromoCode(promoCodeInput)} />
            </div>
          )}
        </div>

        <div className='w-full flex flex-col items-end space-y-2'>
          {inUsePromoCode && (<div>Code : <span className='font-bold'>{inUsePromoCode}</span> - <ButtonLink label={t('shared.delete')} onClick={() => setInUsePromoCode(null)} /></div>)}
          <div className='flex-row space-x-2'>
            <Button
              disabled={params.loading}
              label={t('shared.cancel')}
              onClick={() => setVisible(false)}
            />
            <Button
              loading={params.loading}
              label={t('shared.confirm')}
              primary
              onClick={() => onConfirm({
                plan: params.plan,
                recurrence: params.recurrence,
                promotionCode: inUsePromoCode
              })}
            />
          </div>

        </div>
      </div>
    </Modal>
  )
}
