import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux'

import CategorySelect from 'components/categories/CategorySelect'
import { CATEGORIZE_TRANSACTION, UNCATEGORIZE_TRANSACTION } from 'graphql/transactions'
import AggregationsActions from 'actions/AggregationsActions'
import CategorizationHelperContext from 'contexts/CategorizationHelperContext'
import classNames from 'classnames'

const CategorizeTransaction = ({ type, transaction, lean, className, onCategorize }) => {
  const dispatch = useDispatch()
  const [newCategoryIdValue, setNewCategoryIdValue] = useState(null)
  const categorizationHelper = useContext(CategorizationHelperContext)
  const transactionDescription = transaction?.description
  const triggerHelper = categorizationHelper?.triggerHelper

  const onCompleted = useCallback((data) => {
    const categoryId = data?.categorizeTransaction?.transaction?.categoryId
    setNewCategoryIdValue(categoryId)

    if (onCategorize) onCategorize()

    const actionsLeft = data?.categorizeTransaction?.actionsLeft
    if (actionsLeft) dispatch(AggregationsActions.updateActionsLeft(actionsLeft))
  }, [dispatch, setNewCategoryIdValue, onCategorize])

  const startTriggerHelper = useCallback(() => {
    if (triggerHelper && newCategoryIdValue) {
      triggerHelper({
        categorizationType: 'category',
        categorizationTypeId: newCategoryIdValue,
        description: transactionDescription
      })
    }
  }, [triggerHelper, newCategoryIdValue, transactionDescription])

  useEffect(startTriggerHelper, [startTriggerHelper])

  const [categorizeTransactionMutation, { loading: loadingCategorize }] = useMutation(CATEGORIZE_TRANSACTION, { onCompleted })
  const [uncategorizeTransactionMutation, { loading: loadingUncategorize }] = useMutation(UNCATEGORIZE_TRANSACTION, { onCompleted })

  const transactionCategory = useMemo(() => get(transaction, 'categoryId'), [transaction])
  const loading = useMemo(() => loadingCategorize || loadingUncategorize, [loadingCategorize, loadingUncategorize])

  const changeCategory = useCallback((categoryId) => {
    if (categoryId) {
      categorizeTransactionMutation({ variables: { id: transaction.id, categoryId } })
    } else {
      uncategorizeTransactionMutation({ variables: { id: transaction.id } })
    }
  }, [categorizeTransactionMutation, uncategorizeTransactionMutation, transaction])

  return (
    <div className={classNames('flex flex-col', className)}>
      <div style={styles.inline}>
        <CategorySelect
          type={type}
          value={transactionCategory}
          onChange={changeCategory}
          loading={loading}
          disabled={loading}
          withCreateButton
          withSettingsButton={!lean}
          bordered={false}
          style={{ width: '100%' }}
        />
      </div>
    </div>
  )
}

CategorizeTransaction.defaultProps = {
  lean: false
}

CategorizeTransaction.propTypes = {
  type: PropTypes.oneOf(['cashin', 'cashout']),
  transaction: PropTypes.object,
  lean: PropTypes.bool,
  className: PropTypes.string,
  onCategorize: PropTypes.func
}

const styles = {
  inline: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}

export default CategorizeTransaction
