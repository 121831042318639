import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { c } from 'utils/currencies'
import classNames from 'classnames'

const Currency = ({ amount, currency, color, style, options, maskZero, maskZeroLabel, onClick, strong, className, ...rest }) => {
  const amountLabel = useMemo(() => {
    if (maskZero && !amount) return <span className='text-gray-400'>{maskZeroLabel}</span>
    return c(amount, currency, options)
  }, [maskZero, amount, maskZeroLabel, currency, options])
  return (
    <div
      onClick={onClick}
      style={{ color: color, ...style }}
      className={classNames(className, { 'font-bold': strong, 'hover:underline cursor-pointer': !!onClick })}
      {...rest}
    >
      {amountLabel}
    </div>
  )
}

Currency.defaultProps = {
  maskZero: false
}

Currency.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currency: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.object,
  options: PropTypes.object,
  maskZero: PropTypes.bool,
  maskZeroLabel: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  strong: PropTypes.bool
}

export default Currency
