import React, { useCallback, useMemo } from 'react'

import { Select } from 'antd'
import { useTranslation } from 'react-i18next'
import useLocale from 'hooks/useLocale.js'
import { CHANGE_LOCALE } from 'graphql/users.js'
import { useMutation } from '@apollo/client'
import Locale from 'enums/Locale.js'

const flagsByLocale = {
  [Locale.fr_FR]: '🇫🇷',
  [Locale.en_US]: '🇺🇸',
  [Locale.es_ES]: '🇪🇸',
  [Locale.ca_ES]: '🇪🇸'
}

const ChangeLocaleSelect = (props) => {
  const { t } = useTranslation()
  const locale = useLocale()

  const [changeLocale, { loading }] = useMutation(CHANGE_LOCALE)

  const onChange = useCallback((selectedLocale) => {
    changeLocale({ variables: { locale: selectedLocale } })
  }, [changeLocale])

  const locales = useMemo(() => {
    return Object.values(Locale).filter((locale) => locale !== Locale.ca_ES)
  }, [])

  return (
    <Select {...props} value={locale} loading={loading} onChange={onChange}>
      <Select.OptGroup label={t('shared.language')}>
        {locales.map((locale) => (
          <Select.Option key={locale} value={locale}>
            <span className='mr-2'>{flagsByLocale[locale]}</span>
            <span>{t(`locales.${locale}`)}</span>
          </Select.Option>
        ))}
      </Select.OptGroup>
    </Select>
  )
}

export default ChangeLocaleSelect
