import React, { useState, useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'
import confetti from 'canvas-confetti'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import AppActions from 'actions/AppActions'
import resourcesActions from 'actions/ResourcesActions'
import AggregationsActions from 'actions/AggregationsActions'
import TransactionTable from './TransactionTable'
import { LIST_ALL_TRANSACTION } from 'graphql/transactions'
import ValidateAllButton from './ValidateAllButton'
import SearchInput from 'design/SearchInput.js'

function r (min, max) {
  return Math.random() * (max - min) + min
}

const launchConfetti = () => confetti({
  angle: r(85, 95),
  spread: r(100, 200),
  particleCount: r(50, 120),
  origin: {
    y: 1
  }
})

const TransactionsToValidateList = ({ type }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [hasValidated, setHasValidated] = useState(false)
  const pageSize = useSelector(state => get(state, 'app.pageSize', 20))
  const [search, setSearch] = useState(null)

  const { loading, data, refetch } = useQuery(LIST_ALL_TRANSACTION, {
    variables: {
      type,
      toValidateOnly: true,
      offset: (page - 1) * pageSize,
      search,
      limit: pageSize
    }
  })

  useEffect(() => {
    const total = data?.allTransactions?.total
    if (total !== undefined) {
      dispatch(AggregationsActions.requestToValidateTotalSuccess(type, total))
    }
  }, [dispatch, type, data])

  const pagination = {
    current: page,
    onChange: (page) => {
      setPage(page)
    },
    onShowSizeChange: (page, pageSize) => {
      setPage(page)
      dispatch(AppActions.setPageSize(pageSize))
    },
    pageSize,
    showSizeChanger: true,
    total: data?.allTransactions.total
  }

  useEffect(() => {
    dispatch(resourcesActions.READ_CATEGORIES(type))
  }, [dispatch, type])

  useEffect(() => {
    if (data?.allTransactions?.total === 0 && hasValidated) {
      launchConfetti()
      setHasValidated(false)
    }
  }, [data, hasValidated, setHasValidated])

  const onValidate = useCallback(() => {
    setHasValidated(true)
    refetch()
  }, [setHasValidated, refetch])

  const onValidateAllSuccess = useCallback(() => {
    if (page !== 1) setPage(1)
    onValidate()
  }, [page, setPage, onValidate])

  const onSearchChanged = useCallback((text) => {
    setSearch(text)
    setPage(1)
  }, [setSearch])

  const transactionIds = useMemo(() => data?.allTransactions?.transactions.map((txn) => txn.id), [data])

  return (
    <div>
      <div className='flex flex-row items-center mb-4'>
        <div className='flex-grow flex flex-row items-center'>
          <SearchInput onSearchChanged={onSearchChanged} />
        </div>
        <ValidateAllButton className='m-auto' transactionIds={transactionIds} onSuccess={onValidateAllSuccess} />
      </div>

      <TransactionTable
        type={type}
        dataSource={data?.allTransactions?.transactions}
        pagination={pagination}
        onValidate={onValidate}
        locale={{ emptyText: t('component.transactionsToValidate.noOtherTransactionToValidate') }}
        loading={loading}
      />
    </div>
  )
}

TransactionsToValidateList.propTypes = {
  type: PropTypes.oneOf(['cashin', 'cashout'])
}

export default TransactionsToValidateList
