import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import moment from 'moment'

import { PencilSquareIcon } from '@heroicons/react/24/outline'
import { useMutation } from '@apollo/client'
import { SHIFT_TRANSACTION_DATE } from 'graphql/transactions'
import { startEdgeDate, endEdgeDate } from 'utils/dates'
import { DatePicker, Tooltip } from 'antd'
import ButtonLink from 'design/ButtonLink'
import Spinner from 'design/Spinner'
import { useTranslation } from 'react-i18next'

ShiftTransactionDateInput.propTypes = {
  transaction: PropTypes.object
}

function ShiftTransactionDateInput ({ transaction }) {
  const { t } = useTranslation()
  const [shiftTransactionDate, { loading }] = useMutation(SHIFT_TRANSACTION_DATE)

  const shiftDate = useCallback((date) => {
    const formattedDate = date.format('YYYY-MM-DD')

    shiftTransactionDate({
      variables: {
        id: transaction.id,
        date: formattedDate
      }
    })
  }, [shiftTransactionDate, transaction])

  const momentDate = useMemo(() => {
    return moment.utc(transaction.date)
  }, [transaction.date])

  const originalDateMoment = useMemo(() => {
    return moment.utc(transaction.originalDate)
  }, [transaction.originalDate])

  const originalDateLabel = useMemo(() => {
    return (
      <span>
        {t('component.shiftTransactionDateInput.originalDateLabel')}
        <span className='font-bold ml-1'>{originalDateMoment.format('DD/MM/YYYY')}</span>
      </span>
    )
  }, [originalDateMoment, t])

  const hasDateChanged = useMemo(() => {
    return !originalDateMoment.isSame(momentDate)
  }, [originalDateMoment, momentDate])

  const renderFromDatePickerFooter = useCallback(() => {
    const startOfNextMonth = moment.utc(momentDate).add(1, 'month').startOf('month')
    const endOfPreviousMonth = moment.utc(momentDate).subtract(1, 'month').endOf('month').startOf('day')

    return (
      <div className='flex flex-col justify-center'>
        <Tooltip placement='right' title={startOfNextMonth.format('DD/MM/YYYY')}>
          <ButtonLink
            onClick={() => shiftDate(startOfNextMonth)}
            label={t('component.shiftTransactionDateInput.startOfNextMonth')}
            className='px-0'
          />
        </Tooltip>

        <Tooltip placement='right' title={endOfPreviousMonth.format('DD/MM/YYYY')}>
          <ButtonLink
            onClick={() => shiftDate(endOfPreviousMonth)}
            label={t('component.shiftTransactionDateInput.endOfPreviousMonth')}
            className='px-0 border-t border-b'
          />
        </Tooltip>

        <Tooltip placement='right' title={originalDateMoment.format('DD/MM/YYYY')}>
          <ButtonLink
            onClick={() => shiftDate(originalDateMoment)}
            label={t('component.shiftTransactionDateInput.originalDate')}
            className='px-0'
          />
        </Tooltip>
      </div>
    )
  }, [momentDate, t, originalDateMoment, shiftDate])

  const picker = useMemo(() => (
    <DatePicker
      disabledDate={disabledDate}
      format='DD/MM/YYYY'
      value={momentDate}
      onChange={shiftDate}
      bordered={false}
      allowClear={false}
      showToday={false}
      renderExtraFooter={renderFromDatePickerFooter}
      className='px-0 cursor-pointer'
      suffixIcon={(loading && (<Spinner className='w-4 h-4 text-primary' />)) || (
        <PencilSquareIcon
          className={
            cx('w-4 h-4 rc-show-on-table-row-hover', {
              visible: hasDateChanged,
              'text-primary': hasDateChanged,
              'text-gray-400': !hasDateChanged
            })
          }
        />
      )}
    />
  ), [momentDate, shiftDate, loading, renderFromDatePickerFooter, hasDateChanged])

  if (hasDateChanged) {
    return (
      <Tooltip placement='right' title={originalDateLabel}>
        {picker}
      </Tooltip>
    )
  }

  return picker
}

function disabledDate (current) {
  return moment(current).isBefore(startEdgeDate) || moment(current).isAfter(endEdgeDate)
}

export default ShiftTransactionDateInput
