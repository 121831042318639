import React, { useCallback, useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import Button from 'design/Button'

import CategorySelect from 'components/categories/CategorySelect'
import { useTranslation, Trans } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { CATEGORIZE_RECURRING_PAYMENTS, IGNORE_RECURRING_PAYMENTS } from 'graphql/recurring-payments.js'
import CategoryTagFromId from 'components/categories/CategoryTagFromId'
import CashType from 'enums/CashType.js'

const MultipleRecurringPaymentUpdate = ({ recurringPaymentIds, recurringPayments, onSuccess }) => {
  const { t } = useTranslation()
  const [selectionHasBeenIgnored, setSelectionHasBeenIgnored] = useState(false)
  const [categorizeModalVisible, setCategorizeModalVisible] = useState(false)
  const [selectedCategoryId, setSelectedCategoryId] = useState(undefined)

  const hasNotIgnored = useMemo(() => {
    return selectionHasBeenIgnored ? !recurringPayments.every((recPay) => recPay.ignored) : recurringPayments.some((recPay) => !recPay.ignored)
  }, [recurringPayments, selectionHasBeenIgnored])

  useEffect(() => {
    setSelectionHasBeenIgnored(false)
  }, [recurringPaymentIds, setSelectionHasBeenIgnored])

  const [ignoreRecurringPayments, { loading: ignoreLoading }] = useMutation(IGNORE_RECURRING_PAYMENTS, {
    variables: { input: { ids: recurringPaymentIds } },
    onCompleted: () => {
      setSelectionHasBeenIgnored(!selectionHasBeenIgnored)
      onSuccess()
    }
  })

  const cancelIgnoreRecurringPayments = useCallback(() => {
    ignoreRecurringPayments({ variables: { input: { ids: recurringPaymentIds, cancel: true } } })
  }, [ignoreRecurringPayments, recurringPaymentIds])

  const [categorizeRecurringPayments, { loading: categorizeLoading }] = useMutation(CATEGORIZE_RECURRING_PAYMENTS, {
    onCompleted: () => {
      setCategorizeModalVisible(false)
      setSelectedCategoryId(undefined)
      onSuccess()
    }
  })

  if (recurringPaymentIds.length === 0) return null

  return (
    <div className='rc-multiple-update flex flex-row items-center space-x-2'>

      <CategorySelect
        type={CashType.CASHIN}
        className='w-64'
        size='large'
        withEmpty
        emptyLabel={t('shared.uncategorize')}
        loading={categorizeLoading}
        disabled={categorizeLoading}
        value={selectedCategoryId}
        onChange={(categoryId) => {
          setSelectedCategoryId(categoryId)
          setCategorizeModalVisible(true)
        }}
      />

      <Modal
        centered
        open={categorizeModalVisible}
        onCancel={() => setCategorizeModalVisible(false)}
        footer={null}
      >
        <div className='mb-4 font-bold'>
          {
            selectedCategoryId !== 'null' ? (
              <Trans
                i18nKey='component.multipleRecurringPaymentUpdate.warningAssignCategoryToAllSelected'
                components={
                  { CategoryTag: <CategoryTagFromId id={selectedCategoryId} /> }
                }
              />
            ) : (
              <Trans
                i18nKey='component.multipleRecurringPaymentUpdate.warningUnCategorizeAllSelected'
              />
            )
          }
        </div>
        <div className='w-full flex flex-row justify-end space-x-2'>
          <Button
            label={t('shared.cancel')}
            onClick={() => {
              setSelectedCategoryId(undefined)
              setCategorizeModalVisible(false)
            }}
          />

          <Button
            primary
            label={selectedCategoryId !== 'null' ? t('shared.categorizeSelection') : t('shared.uncategorizeSelection')}
            loading={categorizeLoading}
            onClick={() => {
              categorizeRecurringPayments({ variables: { input: { ids: recurringPaymentIds, categoryId: selectedCategoryId === 'null' ? null : selectedCategoryId } } })
            }}
          />
        </div>
      </Modal>

      {(hasNotIgnored && (
        <Button
          label={t('shared.ignore')}
          onClick={ignoreRecurringPayments}
          loading={ignoreLoading}
        />
      )) || (
        <Button
          label={t('shared.unIgnore')}
          onClick={cancelIgnoreRecurringPayments}
          loading={ignoreLoading}
        />
      )}

    </div>
  )
}

MultipleRecurringPaymentUpdate.propTypes = {
  recurringPayments: PropTypes.arrayOf(PropTypes.object),
  recurringPaymentIds: PropTypes.arrayOf(PropTypes.string),
  onSuccess: PropTypes.func
}

export default MultipleRecurringPaymentUpdate
