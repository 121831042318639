
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

Title.propTypes = {
  label: PropTypes.node,
  className: PropTypes.string
}

export default function Title ({ label, className, ...rest }) {
  return (
    <div className={classNames('text-lg font-bold', className)} {...rest}>
      {label}
    </div>
  )
}
