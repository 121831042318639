import { message } from 'antd'
import confetti from 'canvas-confetti'
import i18next from 'i18next'
import { get } from 'lodash'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import aggregationsActions from 'actions/AggregationsActions'
import { getExpensesTotal, getRevenueTotal, getTransactionsTotal } from 'utils/api'

export default [
  takeLatest(aggregationsActions.requestTransactionsTotal, requestTransactionsTotalSaga),
  takeEvery(aggregationsActions.requestCashoutTotal, requestCashoutTotalSaga),
  takeEvery(aggregationsActions.requestCashinTotal, requestCashinTotalSaga),
  takeLatest(aggregationsActions.updateActionsLeft, updateActionsLeft)
]

function * requestTransactionsTotalSaga ({ payload: { year } }) {
  try {
    const response = yield call(getTransactionsTotal, year)
    yield put(aggregationsActions.requestTransactionsTotalSuccess(get(response, 'data')))
  } catch (error) {
    yield put(aggregationsActions.requestTransactionsTotalError())
  }
}

function * requestCashoutTotalSaga ({ payload: { year, categories } }) {
  try {
    const response = yield call(getExpensesTotal, year, categories)
    yield put(aggregationsActions.requestCashoutTotalSuccess(year, get(response, 'data')))
  } catch (error) {
    yield put(aggregationsActions.requestCashoutTotalError())
  }
}

function * requestCashinTotalSaga ({ payload: { year, categories } }) {
  try {
    const response = yield call(getRevenueTotal, year, categories)
    yield put(aggregationsActions.requestCashinTotalSuccess(year, get(response, 'data')))
  } catch (error) {
    yield put(aggregationsActions.requestCashinTotalError())
  }
}

function * updateActionsLeft ({ payload: { actionsLeft } }) {
  const { t } = i18next
  const prevCategorizationLeft = yield select((state) => get(state, 'aggregations.previousActionsLeft.category', 0))
  const newCategorizationLeft = get(actionsLeft, 'category', 0)

  if (prevCategorizationLeft > 0 && newCategorizationLeft <= 0) {
    const msgStr = t('sagas.aggregationsSaga.autoCategorizationEnabled')
    message.success(msgStr, 5)

    confetti({
      startVelocity: 35,
      angle: -90,
      spread: 240,
      particleCount: 80,
      gravity: 0.7,
      decay: 0.92,
      origin: {
        y: 0
      }
    })
  }
}
