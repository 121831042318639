
import { useQuery } from '@apollo/client'
import { LIST_BANK_CONNECTIONS_WITH_ACCOUNTS } from 'graphql/bank-connections.js'
import { useEffect } from 'react'

export default function useLoadBankconnections () {
  const { data } = useQuery(LIST_BANK_CONNECTIONS_WITH_ACCOUNTS, { fetchPolicy: 'cache-and-network' })

  useEffect(() => {
    const bankConnections = data?.listBankConnectionsWithAccounts || []

    if (window.Intercom && bankConnections.length > 0) {
      try {
        if (bankConnections.length > 0) {
          const connectedBanks = []

          bankConnections.forEach((bankConnection) => {
          /* eslint-disable no-useless-escape */
          // const bankName = bankConnection.bankName.split(' ').join('-').replaceAll(/[@\`\'()!.\[\]\"]/g, '-').normalize('NFD').replaceAll(/[\u0300-\u036f]/g, '')
          // const key = `RC - Budget-Insight-${bankName}-Connection-Id`
            const bankName = bankConnection.bankName
            const value = bankConnection.externalId
            connectedBanks.push(`${bankName} (${value})`)
          })

          window.Intercom('update', { 'RC - Connected Banks': connectedBanks.join(', ') })
        }
      } catch (error) {
        console.error(error)
      }
    }
  }, [data])
}
