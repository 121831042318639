import { put, call } from 'redux-saga/effects'
import { actionTypes } from 'redux-resource'
import { get } from 'lodash'

import { createScenario, updateScenario } from 'utils/api'
import {
  getResourcesFromResponse,
  getPropertiesFromResponse,
  getErrorsFromResponse
} from 'utils/transform'

export function * createScenarioSaga ({ resourceType, requestKey, requestProperties }) {
  try {
    const response = yield call(createScenario, requestProperties.params)

    yield put({
      type: actionTypes.CREATE_RESOURCES_SUCCEEDED,
      resourceType,
      requestKey,
      resources: getResourcesFromResponse(response, 'data.details'),
      requestProperties: getPropertiesFromResponse(response)
    })

    const data = get(response, 'data.details', {})

    yield call(get(requestProperties, 'successCallback', () => true), data._id)
  } catch (error) {
    yield put({
      type: actionTypes.CREATE_RESOURCES_FAILED,
      resourceType,
      requestKey,
      requestProperties: getErrorsFromResponse(error.response)
    })
  }
}

export function * updateScenarioSaga ({ resources, resourceType, requestKey, requestProperties }) {
  try {
    const response = yield call(updateScenario, requestProperties.params)

    yield put({
      type: actionTypes.UPDATE_RESOURCES_SUCCEEDED,
      resourceType,
      requestKey,
      resources: getResourcesFromResponse(response, 'data.details'),
      requestProperties: getPropertiesFromResponse(response)
    })

    yield call(get(requestProperties, 'successCallback', () => true))
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_RESOURCES_FAILED,
      resourceType,
      requestKey,
      resources,
      requestProperties: getErrorsFromResponse(error.response)
    })
  }
}
