import { blue } from '@ant-design/colors'
import theme from 'theme'

const primary = theme.colors.primary
const lightPrimary = theme.colors.lightPrimary
const success = theme.colors.success
const lightSuccess = theme.colors.lightSuccess
const transparentSuccess = `${theme.colors.success}80`
const error = theme.colors.error
const lightError = theme.colors.lightError
const white = '#FFFFFF'
const grey = '#BFBFBF'
const black = '#000000'
const warning = theme.colors.warning
const textColor = theme.colors.baseColor

export const categoryColors = [
  '#D94A87',
  '#C84AD9',
  '#874AD9',
  '#5B4AD9',
  '#4A86D9',
  '#4ABDD9',
  '#4AD992',
  '#60D94A',
  '#D9D94A',
  '#D9A84A',
  '#D94A4A',
  '#000000',
  '#969696'
]

export default {
  primary,
  lightPrimary,

  white,
  grey,
  black,
  success,
  lightSuccess,
  transparentSuccess,
  neutral: blue[6],
  error,
  lightError,
  warning,
  textColor,

  danger: error,
  cashflow: primary,
  cashin: success,
  cashout: error,
  revenue: success,
  expense: error
}

const hexToR = (h) => parseInt((cutHex(h)).substring(0, 2), 16)
const hexToG = (h) => parseInt((cutHex(h)).substring(2, 4), 16)
const hexToB = (h) => parseInt((cutHex(h)).substring(4, 6), 16)
const cutHex = (h) => (h.charAt(0) === '#') ? h.substring(1, 7) : h

// From this W3C document: http://www.webmasterworld.com/r.cgi?f=88&d=9769&url=http://www.w3.org/TR/AERT#color-contrast
// and https://codepen.io/davidhalford/pen/ywEva
export const getAccessibleTextColor = (hex) => {
  const threshold = 180
  const hRed = hexToR(hex)
  const hGreen = hexToG(hex)
  const hBlue = hexToB(hex)

  const cBrightness = ((hRed * 299) + (hGreen * 587) + (hBlue * 114)) / 1000

  if (cBrightness > threshold) return '#000000'
  return '#ffffff'
}

// const backgroundTextSuccess = 'text-green-800 bg-green-100'
const backgroundTextSuccess = 'text-success bg-ultra-light-success'
const backgroundTextError = 'text-error bg-ultra-light-error'

export const backgroundTextColors =
{
  cashin: backgroundTextSuccess,
  cashout: backgroundTextError,

  success: backgroundTextSuccess,
  error: backgroundTextError,
  warning: 'text-yellow-800 bg-yellow-100',
  danger: backgroundTextError,
  primary: 'text-primary bg-ultra-light-primary',
  info: 'text-blue-800 bg-blue-100',
  disabled: 'text-gray-500 bg-gray-100'
}

const outlineBackgroundTextSuccess = 'border-success text-success'
const outlineBackgroundTextError = 'border-error text-error '

export const outlineBackgroundTextColors =
{
  cashin: outlineBackgroundTextSuccess,
  cashout: outlineBackgroundTextError,

  success: outlineBackgroundTextSuccess,
  error: outlineBackgroundTextError,
  warning: 'border-yellow-800 text-yellow-800',
  danger: outlineBackgroundTextError,
  primary: 'border-primary text-primary',
  info: 'border-blue-800 text-blue-800',
  disabled: 'border-gray-500 text-gray-500'
}
