import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import cx from 'classnames'
import { Tooltip } from 'antd'
import { BuildingLibraryIcon } from '@heroicons/react/24/solid'

const TransactionBankLogo = ({ transaction, bankInfosByAccountId, className }) => {
  const bankInfos = useMemo(() => bankInfosByAccountId[transaction.accountId], [transaction.accountId, bankInfosByAccountId])

  return (
    <Tooltip
      title={(
        <div className='flex flex-col items-center'>
          <span className='font-bold'>{bankInfos?.bankName}</span>
          <span>{bankInfos?.accountName || bankInfos?.accountNumber}</span>
        </div>
      )}
      className={cx('min-w-5 cursor-help', className)}
    >
      {(bankInfos?.faviconUrl && (
        <img
          alt='bank-logo'
          src={bankInfos?.faviconUrl}
          className='w-5 h-5'
        />
      )) || (
        <BuildingLibraryIcon className='w-5 h-5 text-light-primary' />
      )}
    </Tooltip>
  )
}

TransactionBankLogo.propTypes = {
  transaction: PropTypes.shape({
    categoryId: PropTypes.string,
    accountId: PropTypes.string
  }),
  bankInfosByAccountId: PropTypes.object,
  className: PropTypes.string
}

export default TransactionBankLogo
