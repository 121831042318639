import { get } from 'lodash'
import accounting from 'accounting'

const defaultCurrency = 'EUR'

export const currencyToSymbol = {
  EUR: '€',
  GBP: '£',
  USD: '$'
}

export const supportedCurrencies = Object.keys(currencyToSymbol)
// export const currencySymbols = Object.keys(currencyToSymbol)

// const getIntlLocale = (currency) => {
//   switch (currency) {
//     case 'EUR':
//       return 'fr-FR'
//     default:
//       return 'en-US'
//   }
// }

// French format
const defaultOptions = { decimal: ',', thousand: ' ', format: '%v %s' }

export const c = (amount, currency, options = {}) => {
  const symbol = get(currencyToSymbol, currency || defaultCurrency, currency)

  if (options.precision === 'auto') {
    options.precision = (amount % 1 === 0) ? 0 : 2
  }

  if (get(options, 'sign')) {
    const sign = amount >= 0 ? '+' : '-'
    const absAmount = Math.abs(amount)
    const formattedMoney = accounting.formatMoney(absAmount, { ...defaultOptions, symbol: symbol, ...options })
    return `${sign}${formattedMoney}`
  } else {
    return accounting.formatMoney(amount, { ...defaultOptions, symbol: symbol, ...options })
  }
}
