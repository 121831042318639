import { useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import usePushLocationWithJsonQueryParams from 'hooks/usePushLocationWithJsonQueryParams'

export default function useJsonQueryParams (initParams, paramsKey = 'f') {
  const location = useLocation()

  const jsonQueryParams = useMemo(() => {
    const urlSearchParams = new URLSearchParams(location.search)
    const rawParams = Object.fromEntries(urlSearchParams)[paramsKey]
    const defaultValue = initParams || {}

    return rawParams ? JSON.parse(b64ToUtf8(rawParams)) : defaultValue
  }, [location, initParams, paramsKey])

  const setPushWithJsonQueryParams = usePushLocationWithJsonQueryParams(paramsKey)

  const setJsonQueryParams = useCallback((params) => {
    return setPushWithJsonQueryParams(location.pathname, params)
  }, [setPushWithJsonQueryParams, location.pathname])

  return [jsonQueryParams, setJsonQueryParams]
}

function b64ToUtf8 (str) {
  return decodeURIComponent(escape(window.atob(str)))
}
