import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { Col, Row, Switch, Tooltip } from 'antd'
import Currency from 'components/Currency'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import AccountCustomNameInput from './AccountCustomNameInput'
import Badge from 'design/Badge.js'

const AccountSettings = ({ loading, account, showedAccountsById, updateHiddenAccounts }) => {
  const { t } = useTranslation()
  const id = useMemo(() => get(account, 'id'), [account])
  const showed = useMemo(() => {
    return get(showedAccountsById, id, false)
  }, [showedAccountsById, id])

  const balance = useMemo(() => {
    const balance = get(account, 'balance')
    const ignoreBalance = get(account, 'ignoreBalance')
    return ignoreBalance ? 0 : balance
  }, [account])

  const switchLinkedAccounts = useCallback((checked) => {
    const newShowedaccountsById = { ...showedAccountsById, [id]: checked }
    updateHiddenAccounts(newShowedaccountsById)
  }, [id, updateHiddenAccounts, showedAccountsById])

  const moreInfoLabel = useMemo(() => {
    return (
      <div>
        {t('component.accountSettings.toCome')}
        <br />
        <Currency amount={account?.comingTransactionsAmount} className='text-white font-bold' />
      </div>
    )
  }, [account?.comingTransactionsAmount, t])

  return (
    <div style={{ marginLeft: 30, marginTop: 10 }}>
      <Row>
        <Col xs={3}>
          <Switch
            disabled={loading}
            loading={loading}
            checked={showed}
            onChange={switchLinkedAccounts}
          />
        </Col>

        <Col xs={21} className='flex flex-row items-center justify-between space-x-2'>
          <div className='flex flex-grow flex-row items-center space-x-2'>
            <AccountCustomNameInput account={account} />

            {account.archived && (
              <div className='flex flex-grow items-center font-bold text-left'>
                <Badge type='disabled' label={t('component.bankConnectionSettings.archived')} />
              </div>
            )}
          </div>

          <Tooltip title={moreInfoLabel} placement='right' className='flex flex-row items-center justify-end'>
            <InformationCircleIcon className='w-4 h-4 text-gray-300 mr-1' />
            <Currency amount={balance} currency={get(account, 'currency')} className='min-w-max' />
          </Tooltip>
        </Col>
      </Row>
    </div>
  )
}

AccountSettings.propTypes = {
  loading: PropTypes.bool,
  account: PropTypes.object,
  showedAccountsById: PropTypes.object,
  updateHiddenAccounts: PropTypes.func
}

export default AccountSettings
