import React from 'react'
import PropTypes from 'prop-types'

// import ReconciliationDrawerContainer from './ReconciliationDrawerContainer'
import TransactionValidate from './TransactionValidate'
import SplittedTransactionModal from './SplittedTransactionModal'

TransactionPartTableActions.propTypes = {
  transaction: PropTypes.object,
  withValidation: PropTypes.bool,
  onValidate: PropTypes.func
}

export default function TransactionPartTableActions ({ transaction, withValidation, onValidate }) {
  return (
    <div className='w-full flex justify-center'>
      <div className='flex flex-col items-center justify-between max-w-24'>
        {(withValidation && (
          <TransactionValidate transaction={transaction} onValidate={onValidate} />
        ))}
        <SplittedTransactionModal originalTransactionId={transaction.originalTransactionId} />
      </div>
    </div>
  )
}
