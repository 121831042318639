import React, { useState, useEffect, useCallback, useMemo } from 'react'

import PropTypes from 'prop-types'
import { DatePicker, Checkbox, Form, message, TreeSelect, Divider } from 'antd'
import { useDispatch } from 'react-redux'
import { useLazyQuery } from '@apollo/client'
import moment from 'moment'
import { get, values } from 'lodash'
import { ExclamationTriangleIcon, CheckCircleIcon } from '@heroicons/react/24/outline'

import { EXPORT_TRANSACTIONS } from 'graphql/transactions'
import CategorySelect from 'components/categories/CategorySelect.js'
import Button from 'design/Button'
import resourcesActions from 'actions/ResourcesActions'
import { useTranslation, Trans } from 'react-i18next'
import { downloadReceiptsArchive } from 'utils/api.js'
import { AxiosError } from 'axios'

const { RangePicker } = DatePicker
const { SHOW_ALL } = TreeSelect

ExportForm.propTypes = {
  year: PropTypes.number,
  month: PropTypes.number,
  type: PropTypes.string,
  categoryIds: PropTypes.arrayOf(PropTypes.string),
  mode: PropTypes.oneOf(['transaction', 'document'])

}

export default function ExportForm ({ year, month, categoryIds, type, mode }) {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [cashInChecked, setCashInChecked] = useState(type === 'cashin')
  const [cashOutChecked, setCashOutChecked] = useState(type === 'cashout')
  const [receiptDownloadDateTypeChecked, setReceiptDownloadDateTypeChecked] = useState(false)
  const [loadingDownloadArchive, setLoadingDownloadArchive] = useState(false)
  const [successDownloadArchive, setSuccessDownloadArchive] = useState(false)

  const [dateRange, setDateRange] = useState()
  const [disabledExport, setDisabledExport] = useState(true)
  const [cashinCategoryIds, setCashinCategoryIds] = useState([])
  const [cashoutCategoryIds, setCashoutCategoryIds] = useState([])
  const dispatch = useDispatch()

  const updateDisabledExport = useCallback(() => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length)
    setDisabledExport(hasErrors)
  }, [form, setDisabledExport])

  useEffect(() => {
    const fromDate = moment([year, month ? (month - 1) : 0, 1])
    const toDate = moment([year, month ? (month - 1) : 11, 1]).endOf('month')
    setDateRange({ fromDate, toDate })

    form.setFieldsValue({
      dateRange: values({ fromDate, toDate })
    })

    updateDisabledExport()
  }, [year, month, form, updateDisabledExport])

  const onCashInChange = useCallback(() => {
    setCashInChecked(!cashInChecked)
  }, [setCashInChecked, cashInChecked])

  const onCashOutChange = useCallback(() => {
    setCashOutChecked(!cashOutChecked)
  }, [setCashOutChecked, cashOutChecked])

  const onReceiptDownloadDateTypeChange = useCallback(() => {
    setReceiptDownloadDateTypeChecked(!receiptDownloadDateTypeChecked)
  }, [setReceiptDownloadDateTypeChecked, receiptDownloadDateTypeChecked])

  useEffect(() => {
    dispatch(resourcesActions.READ_CATEGORIES('cashin'))
    dispatch(resourcesActions.READ_CATEGORIES('cashout'))
  }, [dispatch])

  const onDateRangeChange = useCallback((range) => {
    if (range === null) {
      setDateRange(null)
      return
    }
    const fromDate = moment(range[0].format('YYYY-MM-DD'))
    const toDate = moment(range[1].format('YYYY-MM-DD'))

    setDateRange({ fromDate, toDate })
  }, [setDateRange])

  // #region export transactions

  const onExportError = useCallback(async (data) => {
    const errorCode = get(data, 'graphQLErrors.0.extensions.code')

    switch (errorCode) {
      case 'TRANSACTIONS_EXPORT_TOO_LARGE':
        message.warning({ content: t('component.exportForm.tooManyTransactions'), duration: 15 })
        break
      default:
        message.error({ content: t('component.exportForm.anErrorOccurendWhileExporting'), duration: 15 })
        break
    }
  }, [t])

  const onExportLoaded = useCallback(async (data) => {
    const { content, fileName, mimeType, stringFormat, transactionsCount } = data.exportTransactions

    if (transactionsCount === 0) {
      message.info(t('component.exportForm.noTransactionToExport'))
      return
    }

    const blobParts = []
    if (stringFormat === 'utf8') {
      blobParts.push(new Uint8Array([0xEF, 0xBB, 0xBF]))
    }
    blobParts.push(content)

    const blob = new Blob(blobParts, { type: mimeType + ';charset=' + stringFormat })

    const a = document.createElement('a')
    a.href = window.URL.createObjectURL(blob)
    a.download = fileName
    a.click()
  }, [t])

  const [getExportTransactions, { loadingExportTransactions }] = useLazyQuery(EXPORT_TRANSACTIONS,
    {
      fetchPolicy: 'no-cache',
      onCompleted: onExportLoaded,
      onError: onExportError
    })
  // #endregion

  // #region download archive

  const ondownloadArchiveError = useCallback(async ({ response, error }) => {
    const errorCode = response
      ? get(response, 'code') || get(response, 'statusCode')
      : error instanceof AxiosError ? error.response?.data?.code || error.code || error.response?.status : null

    switch (errorCode) {
      case 'CANNOT_ACCESS_BATCH_RECEIPTS_DOWNLOAD':
        message.error({ content: t('component.exportForm.message.error.noAccessToExportDocument'), duration: 15 })
        break

      case 'TOO_MANY_DOCUMENTS':
        message.error({ content: t('component.exportForm.message.error.tooManyDocumentToExport'), duration: 15 })
        break

      case 'ECONNABORTED':
        message.error({ content: t('component.exportForm.message.error.timeout'), duration: 15 })
        break
      case 404:
        message.info({ content: t('component.exportForm.message.info.noDocumentToExport'), duration: 15 })
        break
      default:
        message.error({ content: t('component.exportForm.anErrorOccurendWhileExporting'), duration: 15 })
        break
    }
  }, [t])

  const ondownloadArchiveLoaded = useCallback(async ({ response }) => {
    if (response?.status === 200) {
      const blob = new Blob([response.data], { type: 'application/zip' })

      const filename = response.headers['content-disposition'].split('filename=')[1]

      const a = document.createElement('a')
      a.href = window.URL.createObjectURL(blob)
      a.download = filename
      a.click()
      setSuccessDownloadArchive(true)
    } else {
      ondownloadArchiveError({ response })
    }
  }, [ondownloadArchiveError, setSuccessDownloadArchive])

  const parseJsonResponse = function (response) {
    try {
      return JSON.parse(response)
    } catch (e) {
      return null
    }
  }

  const downloadArchive = useCallback(async ({ params }) => {
    const { fromDate, toDate, cashinCategoryIds, cashoutCategoryIds, type, receiptDownloadDateType } = params
    setLoadingDownloadArchive(true)
    setSuccessDownloadArchive(false)
    downloadReceiptsArchive({
      fromDate,
      toDate,
      cashinCategoryIds,
      cashoutCategoryIds,
      cashType: type,
      receiptDownloadDateType
    }).then((response) => {
      setLoadingDownloadArchive(false)
      ondownloadArchiveLoaded({ response })
    }).catch(async (error) => {
      setLoadingDownloadArchive(false)
      const response = await error.response?.data.text()
      const jsonResponse = parseJsonResponse(response)
      ondownloadArchiveError({ error, response: jsonResponse })
    })
  }, [ondownloadArchiveError, ondownloadArchiveLoaded, setLoadingDownloadArchive])

  // #endregion

  const loading = useMemo(() => loadingExportTransactions || loadingDownloadArchive, [loadingExportTransactions, loadingDownloadArchive])

  const onFinish = useCallback(async (values) => {
    // init parameters
    const type =
      cashInChecked && !cashOutChecked ? 'cashin'
        : cashOutChecked && !cashInChecked ? 'cashout' : ''
    const fromDate = dateRange.fromDate.format('YYYY-MM-DD')
    const toDate = dateRange.toDate.format('YYYY-MM-DD')

    const params = {
      type,
      fromDate,
      toDate,
      cashinCategoryIds: cashinCategoryIds?.length > 0 ? cashinCategoryIds : undefined,
      cashoutCategoryIds: cashoutCategoryIds?.length > 0 ? cashoutCategoryIds : undefined
    }

    if (mode === 'document') {
      if (type === '') delete params.type
      params.receiptDownloadDateType = receiptDownloadDateTypeChecked ? 'DOCUMENT_CREATION_DATE' : 'TRANSACTION_VALUE_DATE'
      downloadArchive({ params })
    }

    if (mode === 'transaction') {
      params.format = 'csv'
      getExportTransactions({ variables: params })
    }
  }, [mode, dateRange, cashInChecked, cashOutChecked, cashinCategoryIds, cashoutCategoryIds, receiptDownloadDateTypeChecked, getExportTransactions, downloadArchive])

  const categoriesValidator = useCallback(async () => {
    if (!cashInChecked && !cashOutChecked) {
      throw new Error(t('component.exportForm.pleaseSelectATransactionType'))
    }
  }, [cashInChecked, cashOutChecked, t])

  const periodRules = [{ required: true, message: t('component.exportForm.exportPeriodRequired') }]

  if (mode === 'document') {
    periodRules.push(() => ({
      validator (_, value) {
        if (!value || !value[0] || !value[1]) return Promise.resolve()

        const fromDate = moment(value[0], 'YYYY-MM-DD')
        const toDate = moment(value[1], 'YYYY-MM-DD')

        const diff = toDate.diff(fromDate, 'months')

        if (diff <= 12) {
          return Promise.resolve()
        }
        return Promise.reject(new Error(t('component.exportForm.exportPeriodNotGreaterThanOneYear')))
      }
    }))
  }

  return (
    <Form form={form} layout='vertical' onFieldsChange={updateDisabledExport} onFinish={onFinish} className='p-2' requiredMark='optional'>
      <Form.Item
        name='dateRange' placeholder={t('component.exportForm.periodToExport')} label={t('component.exportForm.periodToExport')} rules={periodRules}
      >
        <RangePicker
          format='DD/MM/YYYY'
          onChange={onDateRangeChange}
          ranges={{
            [t('component.exportForm.today')]: [moment(), moment()],
            [t('component.exportForm.thisMonth')]: [moment().startOf('month'), moment().endOf('month')]
          }}
        />
      </Form.Item>

      <Form.Item
        name='categories'
        label={t('component.exportForm.includeBankFlows')} className='mt-8'
        rules={[{ required: true, validator: categoriesValidator }]}
      >

        <div className='flex flex-row space-x-4'>
          <div className='w-1/2'>
            <Checkbox name='cashinCheckbox' checked={cashInChecked} onChange={onCashInChange} className='mb-2'>
              {t('component.exportForm.cashin')}
            </Checkbox>

            {cashInChecked && (
              <Form.Item name='cashinCategoryIds'>
                <CategorySelect
                  treeCheckable
                  withEmpty
                  type='cashin'
                  placeholder={t('component.exportForm.allCategories')}
                  className='w-full rc-multi-category-select'
                  showCheckedStrategy={SHOW_ALL}
                  onChange={(values) => { setCashinCategoryIds(values) }}
                />
              </Form.Item>
            )}
          </div>

          <div className='w-1/2'>
            <Checkbox name='cashoutCheckbox' checked={cashOutChecked} onChange={onCashOutChange} className='mb-2'>
              {t('component.exportForm.cashout')}
            </Checkbox>

            {cashOutChecked && (
              <Form.Item name='cashoutCategoryIds'>
                <CategorySelect
                  treeCheckable
                  withEmpty
                  type='cashout'
                  placeholder={t('component.exportForm.allCategories')}
                  className='w-full rc-multi-category-select'
                  showCheckedStrategy={SHOW_ALL}
                  onChange={(values) => { setCashoutCategoryIds(values) }}
                />
              </Form.Item>
            )}
          </div>

        </div>
      </Form.Item>
      {mode === 'document' && (
        <Form.Item>
          <div className='flex flex-row space-x-4'>
            <Checkbox name='receiptDownloadDateTypeCheckbox' checked={receiptDownloadDateTypeChecked} onChange={onReceiptDownloadDateTypeChange} className='mb-2'>
              {t('component.exportForm.optiontoExportFromCreatedDocumentDate')}
            </Checkbox>
          </div>
        </Form.Item>)}

      <Form.Item>
        <Button
          primary
          label={t('component.exportForm.export')}
          loading={loading}
          disabled={disabledExport || loading}
          type='submit'
        />
      </Form.Item>

      <Divider className='mb-3 mt-2' />

      {loadingDownloadArchive && (
        <span className='flex items-center'>
          <ExclamationTriangleIcon className='w-10 h-10 mr-4  text-orange-500' />
          <span>
            <Trans
              i18nKey='component.exportForm.message.warning.notCloseTab' components={{ Br: <br /> }}
            />
          </span>
        </span>
      )}

      {successDownloadArchive && (
        <span className='flex items-center'>
          <CheckCircleIcon className='w-10 h-10 mr-4 text-success' />
          <span>
            <Trans
              i18nKey='component.exportForm.message.success' components={{ Br: <br /> }}
            />
          </span>
        </span>)}

    </Form>
  )
}
