import { gql } from '@apollo/client'

export const CUSTOMIZE_ACCOUNT_NAME = gql`
  mutation customizeAccountName($id: ID!, $customName: String) {
    customizeAccountName(id: $id, customName: $customName) {
      id
      customName
    }
  }
`
