import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import Button from 'design/Button'
import { useMutation } from '@apollo/client'
import { flatMap } from 'lodash'
import { MARK_EXPECTED_TRANSACTIONS_AS_INVOICED } from 'graphql/expected-transactions'
import CategorySelect from 'components/categories/CategorySelect'
import { CATEGORIZE_MULTIPLE_EXPECTED_TRANSACTION_DETAILS } from 'graphql/expected-transaction-details'
import CategoryTagFromId from 'components/categories/CategoryTagFromId'
import { Modal, notification } from 'antd'
import MultipleExpectedTransactionExpectedDatePicker from 'components/expected-transactions/MultipleExpectedTransactionExpectedDatePicker'
import { useTranslation, Trans } from 'react-i18next'
import { IGNORE_QUOTES, DELETE_QUOTE_FROM_IDS } from 'graphql/quotes.js'
import confirm from 'antd/lib/modal/confirm'

MultipleQuoteUpdate.propTypes = {
  type: PropTypes.oneOf(['cashin']),
  quoteIds: PropTypes.arrayOf(PropTypes.string),
  quotes: PropTypes.arrayOf(PropTypes.object),
  onSuccess: PropTypes.func,
  refetchQuotes: PropTypes.func
}

function MultipleQuoteUpdate ({ type, quoteIds, quotes, onSuccess, refetchQuotes }) {
  const { t } = useTranslation()
  const [selectionHasBeenIgnored, setSelectionHasBeenIgnored] = useState(false)
  const [selectionHasBeenInvoiced, setSelectionHasBeenInvoiced] = useState(false)
  const [categorizeModalVisible, setCategorizeModalVisible] = useState(false)
  const [selectedCategoryId, setSelectedCategoryId] = useState(undefined)

  const expectedTransactions = useMemo(() => {
    const expectedTransactions = flatMap(quotes, (quote) => quote.expectedTransactions)
    return expectedTransactions
  }, [quotes])
  const expectedTransactionIds = useMemo(() => expectedTransactions.map((expTxn) => expTxn.id), [expectedTransactions])

  const hasOnlyPaidExpectedTransactions = useMemo(() => {
    // all expected transactions have a payment date
    return expectedTransactions.every((expTxn) => expTxn.paymentDate)
  }, [expectedTransactions])

  const hasNotPaid = useMemo(() => {
    return selectionHasBeenInvoiced ? hasOnlyPaidExpectedTransactions : !hasOnlyPaidExpectedTransactions
  }, [selectionHasBeenInvoiced, hasOnlyPaidExpectedTransactions])

  const hasNotIgnored = useMemo(() => {
    return selectionHasBeenIgnored ? !quotes.every((quote) => quote.ignored) : quotes.some((quote) => !quote.ignored)
  }, [quotes, selectionHasBeenIgnored])

  useEffect(() => {
    setSelectionHasBeenIgnored(false)
    setSelectionHasBeenInvoiced(false)
  }, [quoteIds, setSelectionHasBeenIgnored])

  const [ignoreQuotes, { loading: ignoreLoading }] = useMutation(IGNORE_QUOTES, {
    variables: { input: { quoteIds } },
    onCompleted: () => {
      setSelectionHasBeenIgnored(!selectionHasBeenIgnored)
      onSuccess()
    }
  })

  const cancelIgnoreQuotes = useCallback(() => {
    ignoreQuotes({ variables: { input: { quoteIds, cancel: true } } })
  }, [ignoreQuotes, quoteIds])

  const [markExpectedTransactionAsInvoiced, { loading: markAsInvoicedLoading }] = useMutation(MARK_EXPECTED_TRANSACTIONS_AS_INVOICED, {
    variables: { input: { expectedTransactionIds, cancel: !hasNotPaid } },
    onCompleted: () => {
      setSelectionHasBeenInvoiced(!selectionHasBeenInvoiced)
      onSuccess()
    }
  })

  const [categorizeMultipleExpectedTransactionDetails, { loading: categorizeExpTxnDetailsLoading }] = useMutation(CATEGORIZE_MULTIPLE_EXPECTED_TRANSACTION_DETAILS, {
    onCompleted: () => {
      setCategorizeModalVisible(false)
      setSelectedCategoryId(undefined)
      onSuccess()
    }
  })

  const [deleteQuotesFromIds, { loading: deleteQuotesFromIdsLoading }] = useMutation(DELETE_QUOTE_FROM_IDS, {
    variables: { input: { quoteIds } }
  })

  const triggerDeleteQuotesFromIds = useCallback(() => {
    const onlyQuoteFromUpload = quotes.filter((quote) => ['upload', 'manual'].includes(quote.externalSource))
    const onlyQuoteFromUploadIds = onlyQuoteFromUpload.map((inv) => inv.id)
    const totalCount = quotes.length
    const deletedCount = onlyQuoteFromUploadIds.length
    const notDeletedQuotesCount = totalCount - deletedCount

    const triggerNotification = () => {
      if (deletedCount > 0 && notDeletedQuotesCount > 0) {
        notification.warn({
          duration: 20,
          message: t('component.multipleQuoteUpdate.deletedNotification.warningMessage', { deletedCount }),
          description: t('component.multipleQuoteUpdate.deletedNotification.warningDescription', { notDeletedQuotesCount })
        })
      } else if (deletedCount > 0 && notDeletedQuotesCount === 0) {
        notification.info({
          message: t('component.multipleQuoteUpdate.deletedNotification.message', { deletedCount })
        })
      }
    }

    if (deletedCount === 0 && notDeletedQuotesCount > 0) {
      notification.warn({
        duration: 20,
        message: t('component.multipleQuoteUpdate.noQuoteDeletedMessage'),
        description: t('component.multipleQuoteUpdate.noQuoteDeletedDescription', { notDeletedQuotesCount })
      })
    } else {
      confirm({
        title: t('component.multipleQuoteUpdate.askForDeleteConfirmation'),
        okType: 'danger',
        okText: t('shared.delete'),
        cancelText: t('shared.no'),
        maskClosable: true,
        onOk () {
          deleteQuotesFromIds({
            variables: { quoteIds: onlyQuoteFromUploadIds },
            onCompleted: () => {
              triggerNotification()
              onSuccess()
              refetchQuotes()
            }
          })
        }
      })
    }
  }, [deleteQuotesFromIds, quotes, onSuccess, refetchQuotes, t])

  const expectedTransactionDetailIds = useMemo(() => {
    const expectedTransactionDetails = flatMap(expectedTransactions, (expectedTransaction) => expectedTransaction.expectedTransactionDetails)
    const expectedTransactionDetailIds = expectedTransactionDetails.map((expTxnDetail) => expTxnDetail.id)
    return expectedTransactionDetailIds
  }, [expectedTransactions])

  if (quoteIds.length === 0) return null

  return (
    <div className='rc-multiple-update flex flex-row items-center space-x-2'>
      <MultipleExpectedTransactionExpectedDatePicker
        expectedTransactions={expectedTransactions}
        onCompleted={onSuccess}
      />

      <CategorySelect
        type={type}
        className='w-64'
        size='large'
        withEmpty
        emptyLabel={t('shared.uncategorize')}
        loading={categorizeExpTxnDetailsLoading}
        disabled={categorizeExpTxnDetailsLoading}
        value={selectedCategoryId}
        onChange={(categoryId) => {
          setSelectedCategoryId(categoryId)
          setCategorizeModalVisible(true)
        }}
      />

      <Modal
        centered
        open={categorizeModalVisible}
        onCancel={() => setCategorizeModalVisible(false)}
        footer={null}
      >
        <div className='mb-4 font-bold'>
          {
            selectedCategoryId !== 'null' ? (
              <Trans
                i18nKey='component.multipleQuoteUpdate.warningAssignCategoryToAllSelected'
                components={
                  { CategoryTag: <CategoryTagFromId id={selectedCategoryId} /> }
                }
              />
            ) : (
              <Trans
                i18nKey='component.multipleQuoteUpdate.warningUnCategorizeAllSelected'
              />
            )
          }
        </div>
        <div className='w-full flex flex-row justify-end space-x-2'>
          <Button
            label={t('shared.cancel')}
            onClick={() => {
              setSelectedCategoryId(undefined)
              setCategorizeModalVisible(false)
            }}
          />

          <Button
            primary
            label={selectedCategoryId !== 'null' ? t('shared.categorizeSelection') : t('shared.uncategorizeSelection')}
            loading={categorizeExpTxnDetailsLoading}
            onClick={() => {
              categorizeMultipleExpectedTransactionDetails({ variables: { ids: expectedTransactionDetailIds, categoryId: selectedCategoryId === 'null' ? null : selectedCategoryId } })
            }}
          />
        </div>
      </Modal>

      {(hasNotIgnored && (
        <Button
          label={t('shared.ignore')}
          onClick={ignoreQuotes}
          loading={ignoreLoading}
        />
      )) || (
        <Button
          label={t('shared.unIgnore')}
          onClick={cancelIgnoreQuotes}
          loading={ignoreLoading}
        />
      )}

      {(hasNotPaid && (
        <Button
          label={t('component.multipleQuoteUpdate.markAsInvoiced')}
          onClick={markExpectedTransactionAsInvoiced}
          loading={markAsInvoicedLoading}
        />
      )) || (
        <Button
          label={t('component.multipleQuoteUpdate.unMarkAsInvoiced')}
          onClick={markExpectedTransactionAsInvoiced}
          loading={markAsInvoicedLoading}
        />
      )}

      <Button
        warning
        label={t('shared.delete')}
        onClick={triggerDeleteQuotesFromIds}
        loading={deleteQuotesFromIdsLoading}
      />
    </div>
  )
}

export default MultipleQuoteUpdate
