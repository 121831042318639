import { isArray, isString, isNumber, get, indexOf } from 'lodash'
import { pipe, set } from 'lodash/fp'

class CategorySortService {
  constructor ({ categoryTree }) {
    if (!isArray(categoryTree)) throw new Error('categoryTree must be an array')

    this.categoriesByParentId = this.getCategoriesByParentIdFromTree(categoryTree)
    this.globalIndex = this.getGlobalIndexFromTree(categoryTree)
    this.parentIdsByCategoryId = this.getParentIdsByCategoryIdFromTree(categoryTree)
  }

  moveCategory ({ id, parentId, currentParentId, index }) {
    if (!isString(id)) throw new Error('id must be a string')
    if (!isString(parentId) && parentId !== undefined) throw new Error('parentId must be a string')
    if (!isNumber(index) || index < 0) throw new Error('index must be a positive number')

    if (!this.globalIndex.includes(currentParentId) && currentParentId !== undefined) throw new Error(`currentParentId ${currentParentId} does not exist in globalIndex`)
    if (!this.globalIndex.includes(parentId) && parentId !== undefined) throw new Error(`parentId ${parentId} does not exist in globalIndex`)
    if (!this.globalIndex.includes(id)) throw new Error(`id ${id} does not exist in globalIndex`)
    if (!Object.keys(this.categoriesByParentId).includes(`${currentParentId}`)) throw new Error('currentParentId must be one of categoriesByParentId keys')

    const currentList = get(this.categoriesByParentId, currentParentId, [])
    const currentCatIndex = indexOf(currentList, id)
    if (currentCatIndex === -1) throw new Error(`can't find category ${id} under category ${currentParentId}`)

    const newCurrentList = [
      ...currentList.slice(0, currentCatIndex),
      ...currentList.slice(currentCatIndex + 1, currentList.length)
    ]

    const parentList = parentId === currentParentId ? newCurrentList : get(this.categoriesByParentId, parentId, [])
    if (parentList.length < index) throw new Error(`can't move category ${id} to index ${index}`)

    const newParentList = [
      ...parentList.slice(0, index),
      id,
      ...parentList.slice(index, parentList.length)
    ]

    const categoriesByParentId = pipe(
      set(currentParentId, newCurrentList),
      set(parentId, newParentList)
    )(this.categoriesByParentId)

    // GLOBAL INDEX UPDATE
    const indexesToMove = this.getIdWithNestedChildrenIds(id, categoriesByParentId)
    const filteredGlobalIndex = this.globalIndex.filter((id) => {
      return !indexesToMove.includes(id)
    })
    const childrenToGet = categoriesByParentId[parentId].slice(0, index)
    const idsToSkip = childrenToGet.reduce((acc, childId) => {
      return [...acc, ...this.getIdWithNestedChildrenIds(childId, categoriesByParentId)]
    }, [])
    const parentIndex = parentId ? indexOf(filteredGlobalIndex, parentId) : 0
    const insertAt = parentIndex + idsToSkip.length + (parentId ? 1 : 0)

    const globalIndex = [
      ...filteredGlobalIndex.slice(0, insertAt),
      ...indexesToMove,
      ...filteredGlobalIndex.slice(insertAt, filteredGlobalIndex.length)
    ]

    // PARENT IDS BY CATEGORY ID
    const parentIds = parentId ? this.parentIdsByCategoryId[parentId].concat(parentId) : []
    const parentIdsByCategoryId = this.setNewParentIds(id, parentIds, this.parentIdsByCategoryId)

    // REFERENCES UPDATE
    this.categoriesByParentId = categoriesByParentId
    this.globalIndex = globalIndex
    this.parentIdsByCategoryId = parentIdsByCategoryId
  }

  // PRIVATES
  getCategoriesByParentIdFromTree (categoryTree, catsByParentId = {}, parentId = undefined) {
    return categoryTree.reduce((acc, cat) => {
      const oldValue = acc[parentId] || []
      acc[parentId] = [...oldValue, cat.id]

      const children = get(cat, 'children') || []
      this.getCategoriesByParentIdFromTree(children, acc, cat.id)

      return acc
    }, catsByParentId)
  }

  getGlobalIndexFromTree (categoryTree, globalIndex = []) {
    return categoryTree.reduce((acc, cat) => {
      const newGlobalIndex = [...acc, cat.id]

      const children = get(cat, 'children') || []
      return this.getGlobalIndexFromTree(children, newGlobalIndex)
    }, globalIndex)
  }

  getParentIdsByCategoryIdFromTree (categoryTree, parentIdsByCatId = {}, parentIds = []) {
    return categoryTree.reduce((acc, cat) => {
      const newAcc = { ...acc, [cat.id]: parentIds }
      const children = get(cat, 'children') || []
      const childrenPath = [...parentIds, cat.id]
      return this.getParentIdsByCategoryIdFromTree(children, newAcc, childrenPath)
    }, parentIdsByCatId)
  }

  getIdWithNestedChildrenIds (id, categoriesByParentId, nestedChildrenIds = []) {
    const newNestedChildrenIds = [...nestedChildrenIds, id]
    const children = categoriesByParentId[id] || []

    return children.reduce((acc, childId) => {
      return this.getIdWithNestedChildrenIds(childId, categoriesByParentId, acc)
    }, newNestedChildrenIds)
  }

  setNewParentIds (id, parentIds, parentIdsByCategoryId) {
    const newParentIdsByCategoryId = set(id, parentIds, parentIdsByCategoryId)
    const children = this.categoriesByParentId[id] || []
    const childrenParentIds = [...parentIds, id]

    return children.reduce((acc, childId) => {
      return this.setNewParentIds(childId, childrenParentIds, acc)
    }, newParentIdsByCategoryId)
  }
}

export default CategorySortService
