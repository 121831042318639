import React from 'react'
import { Radio } from 'antd'
import { useTranslation } from 'react-i18next'

const VatToggle = React.forwardRef((props, ref) => {
  const { t } = useTranslation()

  return (
    <Radio.Group
      ref={ref}
      defaultValue='true'
      buttonStyle='solid'
      className='rc-custom-radio'
      size='small'
      {...props}
    >
      <Radio.Button value='true'>{t('shared.inclTax')}</Radio.Button>
      <Radio.Button value='false'>{t('shared.exclTax')}</Radio.Button>
    </Radio.Group>
  )
})

export default VatToggle
