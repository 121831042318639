import React, { useEffect } from 'react'
import { getResources } from 'redux-resource'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'

import resourcesActions from 'actions/ResourcesActions'
import OnboardingHeader from 'components/onboarding/OnboardingHeader'
import OnboardingFooter from 'components/onboarding/OnboardingFooter'
import { Spin } from 'antd'
import ManageCategories from 'components/categories/ManageCategories'

export default () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const categories = useSelector(state => getResources(state.categories, 'cashin'))
  const hasNoCategories = categories.length === 0

  useEffect(() => {
    if (hasNoCategories) {
      dispatch(resourcesActions.GENERATE_CATEGORY_TREE('cashin'))
    }
  }, [hasNoCategories, dispatch])

  return (
    <div className='mt-20 flex flex-col items-center w-full'>
      <div className='rc-onboarding-screen-container'>
        <OnboardingHeader
          title={(
            <div>
              <Trans
                i18nKey='onboarding.onboardingCashinCategoriesScreen.createYourCashinCategories'
                components={{
                  Span: <span className='rc-highlight-text-cashin' />
                }}
              />

            </div>
          )}
        />

        <ul className='list-disc list-inside'>
          <li className='text-lg'>
            {t('onboarding.onboardingCashinCategoriesScreen.bullet01')}
          </li>
          <li className='text-lg'>
            {t('onboarding.onboardingCashinCategoriesScreen.bullet02')}
          </li>
          <li className='text-lg'>
            {t('onboarding.onboardingCashinCategoriesScreen.bullet03')}
          </li>
          <li className='text-lg font-bold'>
            <Trans
              i18nKey='onboarding.onboardingCashinCategoriesScreen.bullet04'
              components={{ ErrorStyle: <span className='text-error' /> }}
            />

          </li>
        </ul>
      </div>

      <div className='w-full mt-4 px-2 bg-gray-100 p-6 pl-4'>
        {(hasNoCategories && (
          <Spin />
        )) || (
          <ManageCategories type='cashin' />
        )}
      </div>

      <div className='rc-onboarding-screen-container mb-2'>
        <OnboardingFooter prev next nextDisabled={hasNoCategories} />
      </div>
    </div>
  )
}
