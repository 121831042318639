import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { get } from 'lodash'
import cx from 'classnames'

import Spinner from 'design/Spinner'
import { Tooltip } from 'antd'
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import theme from 'theme'

const CashInfoContent = ({ title, loading, color, text, textContainerClassname, subtext, infoLabel, subtextInfoLabel, size, inline, noBackground }) => {
  const textColor = get(theme.colors, color, 'black')

  return (
    <div
      className={cx('flex', {
        'flex-col': !inline,
        'flex-row': inline
      })}
    >
      {(infoLabel && (
        <Tooltip title={infoLabel} placement='right'>
          <div className='flex flex-row items-center max-w-max'>
            <span className='text-base-color'>{title}</span>
            <InformationCircleIcon className='w-4 h-4 text-gray-300 ml-1' />
          </div>
        </Tooltip>
      )) || (
        <span className='text-base-color'>{title}</span>
      )}

      <div className={cx('flex flex-row items-center', { 'mt-1': !inline, 'ml-2': inline }, textContainerClassname)}>
        <div className='inline-block px-2 py-0 rounded-full' style={{ background: noBackground ? 'none' : textColor + '2F' }}>
          {(size === 'small' && (
            <div className='font-bold' style={{ color: textColor }}>{text}</div>
          )) || (
            <div
              className='rc-no-margin font-bold'
              style={{ color: textColor }}
            >
              {text}
            </div>
          )}
        </div>

        {(!!loading && (
          <Spinner className='w-4 h-4 inline-block text-primary' />)
        )}

        {!!subtext && (
          <Tooltip title={subtextInfoLabel} placement='right'>
            <span className='font-bold ml-2'>{subtext}</span>
          </Tooltip>
        )}
      </div>
    </div>
  )
}

CashInfoContent.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.node,
  text: PropTypes.node,
  textContainerClassname: PropTypes.string,
  subtext: PropTypes.node,
  infoLabel: PropTypes.string,
  subtextInfoLabel: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  inline: PropTypes.bool,
  noBackground: PropTypes.bool
}

export default function CashInfo ({ loading, title, text, textContainerClassname, subtext, color, linkTo, infoLabel, subtextInfoLabel, size, inline, noBackground, ...rest }) {
  if (linkTo) {
    return (
      <Link to={linkTo} {...rest}>
        <CashInfoContent
          title={title}
          loading={loading}
          color={color}
          text={text}
          textContainerClassname={textContainerClassname}
          subtext={subtext}
          infoLabel={infoLabel}
          subtextInfoLabel={subtextInfoLabel}
          size={size}
          inline={inline}
          noBackground={noBackground}
        />
      </Link>
    )
  }

  return (
    <div {...rest}>
      <CashInfoContent
        title={title}
        loading={loading}
        color={color}
        text={text}
        textContainerClassname={textContainerClassname}
        subtext={subtext}
        infoLabel={infoLabel}
        subtextInfoLabel={subtextInfoLabel}
        size={size}
        inline={inline}
        noBackground={noBackground}
      />
    </div>
  )
}

CashInfo.defaultProps = {
  size: 'medium',
  inline: false
}

CashInfo.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.node,
  text: PropTypes.node,
  textContainerClassname: PropTypes.string,
  subtext: PropTypes.node,
  color: PropTypes.string,
  linkTo: PropTypes.string,
  infoLabel: PropTypes.string,
  subtextInfoLabel: PropTypes.string,
  size: PropTypes.string,
  inline: PropTypes.bool,
  noBackground: PropTypes.bool
}
