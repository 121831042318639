import { Tooltip } from 'antd'
import Button from 'design/Button'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router'

import { Menu } from '@headlessui/react'
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import ManageInvoiceImportsButton from 'components/invoices/ManageInvoiceImportsButton'
import OpenCreateInvoiceManuallyDrawerButton from 'components/invoices/OpenCreateInvoiceManuallyDrawerButton'
import useInvoiceImporter from 'hooks/invoices/useInvoiceImporter'
import useListAndPollConnections from 'hooks/useListAndPollConnections'
import { useTranslation } from 'react-i18next'
import ExpectedDocumentsConnectorPreview from './ExpectedDocumentsConnectorPreview'
import featureConfig from 'config/features.js'

ExpectedDocumentsConnectorList.propTypes = {
  type: PropTypes.oneOf(['cashin', 'cashout']),
  refetchInvoices: PropTypes.func,
  enableManualImports: PropTypes.bool
}

const expectedDocumentsConnector = ['SELLSY']

function ExpectedDocumentsConnectorList ({ type, refetchInvoices, enableManualImports }) {
  const { t } = useTranslation()
  const history = useHistory()
  const { connections, loading, startPolling } = useListAndPollConnections({ onEndPolling: refetchInvoices })

  const expDocConnections = useMemo(() => connections.filter((co) => expectedDocumentsConnector.includes(co.externalSource)), [connections])

  const { triggerImport } = useInvoiceImporter({ type, refetchInvoices })

  return (
    <div className='h-full flex flex-row justify-end'>
      {expDocConnections.map((connection) => (
        <ExpectedDocumentsConnectorPreview
          key={connection.externalSource}
          connection={connection}
          loading={loading}
          startPolling={startPolling}
        />
      ))}

      {/*
      {(expDocConnections.length === 0 && !loading && (
        <Button
          primary
          label='Configurer mes intégrations'
          onClick={() => { history.push('/integrations') }}
        />
      ))} */}

      <div className='h-full w-[1px] bg-gray-200 mx-2' />

      <Menu>
        {enableManualImports && (
          <Menu.Button as='div'>
            <Button
              primary={expDocConnections.length === 0 && !loading}
              label={t('component.invoiceConnectorList.addExpectedType',
                {
                  type: type === 'cashin'
                    ? t('shared.cashinPlural')
                    : t('shared.cashoutPlural')
                }
              )}
            />
          </Menu.Button>
        )}

        <Menu.Items className='relative' unmount={false}>
          <div className='flex flex-col space-y-2 bg-white p-2 rounded-md absolute top-12 right-0 z-10 shadow-md min-w-max'>
            {(featureConfig.manageIntegrationsEnabled && expDocConnections.length === 0 && !loading && (
              <Menu.Item as='div'>
                <Button
                  primary
                  label={t('component.invoiceConnectorList.configureMyIntegrations')}
                  onClick={() => { history.push('/integrations') }}
                />
              </Menu.Item>
            ))}

            <Menu.Item as='div'>
              <OpenCreateInvoiceManuallyDrawerButton type={type} onSuccess={refetchInvoices} />
            </Menu.Item>

            {featureConfig.importInvoicesEnabled && (
              <Menu.Item as='div' className='inline-flex items-center'>
                <Button
                  label={t('component.invoiceConnectorList.importAFile')}
                  onClick={triggerImport}
                />
                <Tooltip title={t('component.invoiceConnectorList.importCSVTooltip')} placement='right'>
                  <InformationCircleIcon className='w-6 h-6 text-gray-300 ml-1' />
                </Tooltip>
              </Menu.Item>
            )}

            {featureConfig.importInvoicesEnabled && (
              <Menu.Item>
                <ManageInvoiceImportsButton type={type} refetchInvoices={refetchInvoices} />
              </Menu.Item>
            )}
          </div>
        </Menu.Items>
      </Menu>
    </div>
  )
}

export default ExpectedDocumentsConnectorList
