
import React, { useCallback, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Dropdown, Modal, Tooltip, Popover, message } from 'antd'

import EditScenarioModal from './EditScenarioModal'
import NewScenarioModal from './NewScenarioModal'
import { EllipsisVerticalIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { Square2StackIcon, PencilIcon, TrashIcon, DocumentIcon, ReceiptRefundIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import useSubscriptionScenarioLimit from 'hooks/subscriptions/useSubscriptionScenarioLimit.js'
import { ScenariosContext } from 'contexts/ScenariosContext.js'
import ScenarioIncludeQuotes from './ScenarioIncludeQuotes.js'
import useAuthenticatedContext from 'hooks/useAuthenticatedContext.js'
import CashflowSheetContext from 'contexts/CashflowSheetContext.js'
import { DELETE_SCENARIO } from 'graphql/scenarios.js'
import { useMutation } from '@apollo/client'
import { get, remove } from 'lodash'
import ScenarioIncludeRecurringPayments from './ScenarioIncludeRecurringPayments.js'
import ScenarioIncludeOrders from './ScenarioIncludeOrders.js'
import useConnections from 'hooks/connections/useConnections.js'

const { confirm } = Modal

ScenarioActions.propTypes = {
  scenarioId: PropTypes.string,
  year: PropTypes.number,
  onDelete: PropTypes.func,
  onDuplicateSuccess: PropTypes.func
}

function ScenarioActions ({ scenarioId, year, onDelete, onDuplicateSuccess }) {
  const { subscription } = useAuthenticatedContext()
  const { refetchCashflowSheet } = useContext(CashflowSheetContext)
  const { sellsyConnection } = useConnections()

  const hasAccessToScenarioConfiguration = process.env.REACT_APP_TENANT === 'QUIPU' ||
  (subscription?.hasAccessToScenarioConfiguration ?? false)

  const [isIncludeQuotesOpen, setIsIncludeQuotesOpen] = useState(false)
  const [isIncludeOrdersOpen, setIsIncludeOrdersOpen] = useState(false)
  const [isMenuOpen, setMenuOpen] = useState(false)
  const [isSettingsUpdated, setSettingsUpdated] = useState(false)

  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [isDuplicateVisible, setIsDuplicateVisible] = useState(false)
  const { data: scenariosData, refetch: scenariosRefetch } = useContext(ScenariosContext)
  const onEditComplete = useCallback(() => {
    setIsEditModalVisible(false)
    scenariosRefetch()
  }, [scenariosRefetch, setIsEditModalVisible])
  const lastScenario = useMemo(() => scenariosData?.listScenarios.length === 1, [scenariosData])
  const scenario = useMemo(() => scenariosData?.listScenarios.find((scenario) => scenario.id === scenarioId), [scenariosData, scenarioId])

  const [deleteScenario] = useMutation(DELETE_SCENARIO, {
    variables: { scenarioId },
    onCompleted: onDelete,
    onError: (e) => {
      const errorCode = get(e, 'graphQLErrors.0.extensions.code')

      switch (errorCode) {
        case 'CANNOT_DELETE_LAST_SCENARIO':
          message.warning(t('component.scenarioActions.dropdown.lastScenarioWarning'))
          break
        default:
          message.error(t('shared.anErrorHasOccured'))
          break
      }
    }
  })

  const { t } = useTranslation()

  const showDeleteConfirm = useCallback(() => {
    confirm({
      title: t('component.scenarioActions.deleteConfirmation.title'),
      okType: 'danger',
      okText: t('component.scenarioActions.deleteConfirmation.okText'),
      cancelText: t('component.scenarioActions.deleteConfirmation.cancelText'),
      maskClosable: true,
      onOk: deleteScenario
    })
  }, [t, deleteScenario])

  const handleMenuClick = useCallback(({ key }) => {
    switch (key) {
      case 'rename':
        setIsEditModalVisible(true)
        setMenuOpen(false)
        break
      case 'duplicate':
        setIsDuplicateVisible(true)
        setMenuOpen(false)
        break
      case 'delete':
        showDeleteConfirm()
        setMenuOpen(false)
        break
      case 'includeQuotes':
      case 'includeOrders':
      case 'includeRecurringPayments':
        break

      default:
        console.warn(`${key} button not handled`)
    }
  }, [showDeleteConfirm, setIsEditModalVisible, setIsDuplicateVisible])

  const handleOpenIncludeQuotesMenu = (visible) => {
    // Close the Tooltip when the Popover is closed
    setIsIncludeQuotesOpen(!visible)
    if (!visible && isSettingsUpdated) {
      refetchCashflowSheet()
      setSettingsUpdated(false)
    }
  }

  const handleOpenIncludeOrdersMenu = (visible) => {
    // Close the Tooltip when the Popover is closed
    setIsIncludeOrdersOpen(!visible)
    if (!visible && isSettingsUpdated) {
      refetchCashflowSheet()
      setSettingsUpdated(false)
    }
  }

  const handleOpenIncludeRecurringPaymentsMenu = (visible) => {
    // Close the Tooltip when the Popover is closed
    if (!visible && isSettingsUpdated) {
      refetchCashflowSheet()
      setSettingsUpdated(false)
    }
  }

  const handleOpenChangeMenu = (nextOpen, info) => {
    if (info?.source === 'trigger' || nextOpen) {
      setMenuOpen(nextOpen)
    }
  }

  const { hasScenarioLimitAttempted, tooltipTitle } = useSubscriptionScenarioLimit()

  const items = [
    {
      key: 'title',
      label: (<div className='cursor-default text-sm font-semibold text-base-color flex'>{t('component.scenarioActions.title')}</div>),
      disabled: true
    },
    {
      key: 'includeQuotes',
      label: (
        <Popover
          placement='left'
          onOpenChange={handleOpenIncludeQuotesMenu}
          trigger='hover'
          content={
            <ScenarioIncludeQuotes
              isReadOnly={!hasAccessToScenarioConfiguration}
              scenarioId={scenarioId}
              scenarioExpectedQuoteStatuses={scenario?.expectedQuoteStatuses}
              isSettingsOpen={isIncludeQuotesOpen}
              onUpdated={() => setSettingsUpdated(true)}
              includeQuotes={scenario?.includeQuotes}
            />
          }
        >
          <div className='flex flex-row items-center py-1'>
            <DocumentIcon className='w-4 h-4 mr-2' />
            <div>{t('component.scenarioActions.dropdown.includeQuotes')}</div>
            <ChevronRightIcon className='w-4 h-4 ml-2' />
          </div>
        </Popover>
      )

    },
    {
      key: 'includeOrders',
      label: (
        <Popover
          placement='left'
          onOpenChange={handleOpenIncludeOrdersMenu}
          trigger='hover'
          content={
            <ScenarioIncludeOrders
              isReadOnly={!hasAccessToScenarioConfiguration}
              scenarioId={scenarioId}
              scenarioExpectedOrderStatuses={scenario?.expectedOrderStatuses}
              isSettingsOpen={isIncludeOrdersOpen}
              onUpdated={() => setSettingsUpdated(true)}
              includeOrders={scenario?.includeOrders}
            />
          }
        >
          <div className='flex flex-row items-center py-1'>
            <DocumentIcon className='w-4 h-4 mr-2' />
            <div>{t('component.scenarioActions.dropdown.includeOrder')}</div>
            <ChevronRightIcon className='w-4 h-4 ml-2' />
          </div>
        </Popover>
      )
    },
    {
      key: 'includeRecurringPayments',
      label: (
        <Popover
          placement='left'
          onOpenChange={handleOpenIncludeRecurringPaymentsMenu}
          trigger='hover'
          content={
            <ScenarioIncludeRecurringPayments
              scenarioId={scenarioId}
              includeRecurringPayments={scenario?.includeRecurringPayments}
              onUpdated={() => setSettingsUpdated(true)}
            />
          }
        >
          <div className='flex flex-row items-center py-1'>
            <ReceiptRefundIcon className='w-4 h-4 mr-2' />
            <div>{t('component.scenarioActions.dropdown.includeRecurringPayments')}</div>
            <ChevronRightIcon className='w-4 h-4 ml-2' />
          </div>
        </Popover>
      )

    },
    {
      key: 'rename',
      label: (
        <div className='flex flex-row items-center py-1'>
          <PencilIcon className='w-4 h-4 mr-2' />
          <div>{t('component.scenarioActions.dropdown.rename')}</div>
        </div>
      )
    },
    {
      key: 'duplicate',
      disabled: hasScenarioLimitAttempted,
      label: (
        <Tooltip
          title={hasScenarioLimitAttempted ? tooltipTitle : null}
          placement='left'
          className={classNames('flex flex-row py-1', { grayscale: hasScenarioLimitAttempted })}
        >
          <Square2StackIcon className='w-4 h-4 mr-2' />
          <div>{t('component.scenarioActions.dropdown.duplicate')}</div>
        </Tooltip>
      )
    },
    {
      key: 'delete',
      disabled: lastScenario,
      label: (
        <Tooltip
          title={lastScenario ? t('component.scenarioActions.dropdown.lastScenarioWarning') : null}
          className={classNames('flex flex-row items-center py-1 text-error', { grayscale: lastScenario })}
        >
          <TrashIcon className='w-4 h-4 mr-2' />
          <div>{t('component.scenarioActions.dropdown.delete')}</div>
        </Tooltip>
      )
    }
  ]

  if (!sellsyConnection) {
    remove(items, (item) => ['includeOrders', 'includeRecurringPayments'].includes(item.key))
  }

  return (
    <>
      <Dropdown
        disabled={!scenarioId}
        onOpenChange={handleOpenChangeMenu}
        open={isMenuOpen}
        menu={{
          onClick: handleMenuClick,
          onMouseLeave: () => setMenuOpen(false),
          items
        }}
      >
        <EllipsisVerticalIcon className='w-6 h-6 text-primary cursor-pointer' />
      </Dropdown>

      <EditScenarioModal
        scenarioId={scenarioId}
        year={year}
        visible={isEditModalVisible}
        setVisible={setIsEditModalVisible}
        onComplete={onEditComplete}
      />

      <NewScenarioModal
        duplicateTab
        visible={isDuplicateVisible}
        setVisible={setIsDuplicateVisible}
        onSuccess={onDuplicateSuccess}
        duplicateScenarioId={scenarioId}
      />
    </>
  )
}

export default ScenarioActions
