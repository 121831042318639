import { useCallback } from 'react'

import { c } from 'utils/currencies'

export default ({ maskZero, currency, options } = {}) => {
  const formatter = useCallback((amount) => {
    if (maskZero && amount === 0) return '-'
    return c(amount, currency, options)
  }, [currency, options, maskZero])

  return formatter
}
