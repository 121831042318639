import { get } from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import colors from 'utils/colors'

import { ArrowsPointingOutIcon } from '@heroicons/react/24/outline'
import Currency from 'components/Currency'
import ButtonLink from 'design/ButtonLink.js'
import ShortcutContainer from 'design/ShortcutContainer.js'
import moment from 'moment'
import BudgetedCell from '../budgeted/BudgetedCell'
import ForecastShortCut from '../budgeted/ForecastShortCut.js'
import ScenarioCommentIndicator from '../budgeted/ScenarioCommentIndicator.js'
import ScenarioCommentInput from '../budgeted/ScenarioCommentInput'
import CashflowSheetComparisonCell from './components/CashflowSheetComparisonCell'
import CashflowSheetDefaultCell from './components/CashflowSheetCurrencyCell'
import { Tooltip } from 'antd'
import { t } from 'i18next'
import CashflowSheetForecastedCompletionRateOverlay from '../CashflowSheetForecastedCompletionRateOverlay.js'
import CashType from 'enums/CashType.js'

CashflowSheetCategoryCell.propTypes = {
  row: PropTypes.object,
  sheetDate: PropTypes.object,
  valueIndex: PropTypes.number,
  value: PropTypes.object,
  queryFilters: PropTypes.object,
  setDrawerParams: PropTypes.func
}

export default function CashflowSheetCategoryCell ({ row, sheetDate, valueIndex, value, queryFilters, setDrawerParams }) {
  const onClickLeft = useCallback(() => {
    setDrawerParams({ visible: true, rowAbsolutePath: row.absolutePath, valueIndex, tabIndex: 0 })
  }, [setDrawerParams, row.absolutePath, valueIndex])

  const onClickRight = useCallback(() => {
    setDrawerParams({ visible: true, rowAbsolutePath: row.absolutePath, valueIndex, tabIndex: 1 })
  }, [setDrawerParams, row.absolutePath, valueIndex])

  const realisedVsForecasted = useSelector(state => get(state, 'app.dashboard.realisedVsForecasted', false))
  const showPercentage = useSelector(state => get(state, 'app.dashboard.showPercentage', true))

  const type = useMemo(() => row.type, [row])
  const cashType = useMemo(() => type === 'CASHIN_CATEGORY' ? CashType.CASHIN : CashType.CASHOUT, [type])
  const strong = useMemo(() => !row.parentCategoryId, [row])
  const nextMonth = moment().add(1, 'month')

  const hasForecastShortCut = useMemo(() => value.budgetedEditable &&
  (sheetDate.isCurrentMonth || (sheetDate.isFutureMonth && sheetDate.momentDate.isBefore(nextMonth))),
  [sheetDate, value.budgetedEditable, nextMonth])

  const forecastedValueIsBudgeted = useMemo(() => {
    return (value.forecasted === value.budgeted)
  }, [value.forecasted, value.budgeted])

  const comparison = useMemo(() => {
    return (!sheetDate.isFutureMonth && (sheetDate.isCurrentMonth || realisedVsForecasted))
  }, [sheetDate, realisedVsForecasted])

  const scenarioCommentNode = useMemo(() => {
    return (
      <ScenarioCommentInput
        sheetDate={sheetDate}
        scenarioId={queryFilters.scenarioId}
        categoryId={value.categoryId}
        comment={value.comment}
        maskEmpty
      />
    )
  }, [sheetDate, queryFilters.scenarioId, value.categoryId, value.comment])

  const budgetedCellNode = useMemo(() => {
    return (
      <BudgetedCell
        sheetDate={sheetDate}
        budgeted={value.budgeted}
        budgetedEditable={value.budgetedEditable}
        categoryId={value.categoryId}
        scenarioId={queryFilters.scenarioId}
        toDate={queryFilters.toDate}
        className='text-xs text-right'
        withAssistant
        maskZero
      />
    )
  }, [sheetDate, queryFilters.scenarioId, queryFilters.toDate, value.budgeted, value.budgetedEditable, value.categoryId])

  const openDrawerNode = useMemo(() => {
    if (!onClickRight) return null

    return (
      <Tooltip title={t('app.dashboard.realisedVsForecasted.openButtonTooltip')}>
        <ButtonLink
          onClick={(e) => {
            onClickRight()
            e.stopPropagation()
          }}
          className='ml-1'
          label={(
            <ArrowsPointingOutIcon
              className='h-5 w-5  text-gray-400 hover:text-primary'
            />
          )}
        />
      </Tooltip>
    )
  }, [onClickRight])

  if (comparison) {
    return (
      <CashflowSheetForecastedCompletionRateOverlay
        type={cashType}
        visible={realisedVsForecasted}
        realisedAmount={value.realised}
        forecastedAmount={value.forecasted}
        showPercentage={showPercentage}
      >
        <CashflowSheetComparisonCell
          onClickLeft={onClickLeft}
          onClickRight={onClickRight}
          fill={type === 'CASHIN_CATEGORY' ? colors.success : colors.error}
          realisedAmount={value.realised}
          expectedAmount={value.expected}
          outstandingExpectedAmount={value.outstandingExpected}
          forecastedAmount={value.forecasted}
          noBar={!value.forecasted}
          strong={strong}
          comment={value.comment}
          scenarioCommentNode={scenarioCommentNode}
          budgetedCellNode={budgetedCellNode}
          forecastedValueIsBudgeted={forecastedValueIsBudgeted}
          forecastShortCutNode={hasForecastShortCut &&
            <ForecastShortCut
              scenarioId={queryFilters.scenarioId}
              categoryId={value.categoryId}
              sheetDate={sheetDate}
              promote={!value.forecasted}
            />}
          openDrawerNode={openDrawerNode}
          className='w-full min-w-max'
        />
      </CashflowSheetForecastedCompletionRateOverlay>
    )
  }

  if (!sheetDate.isFutureMonth) {
    return (
      <CashflowSheetDefaultCell
        onClick={onClickLeft}
        justify={realisedVsForecasted ? 'start' : 'end'}
      >
        <Currency
          amount={value.realised}
          strong={strong}
          maskZero
        />
      </CashflowSheetDefaultCell>
    )
  }

  if (forecastedValueIsBudgeted) {
    return (
      <CashflowSheetDefaultCell
        justify='between'
        className='group/shortcut'
      >
        <ScenarioCommentIndicator comment={value.comment} className='mr-1' />

        <div className='w-full relative'>
          <ShortcutContainer className='absolute right-full mr-1'>
            {openDrawerNode}

            {hasForecastShortCut &&
              <ForecastShortCut
                scenarioId={queryFilters.scenarioId}
                categoryId={value.categoryId}
                sheetDate={sheetDate}
                promote={!value.budgeted}
              />}

            {scenarioCommentNode}
          </ShortcutContainer>

          {budgetedCellNode}
        </div>
      </CashflowSheetDefaultCell>
    )
  }

  return (
    <CashflowSheetDefaultCell
      onClick={onClickRight}
      justify='between'
      className='relative group/shortcut'
    >
      <ScenarioCommentIndicator comment={value.comment} className='mr-1' />

      <ShortcutContainer className='absolute left-1 max-h-6' bordered={false}>
        {scenarioCommentNode}

        {hasForecastShortCut &&
          <ForecastShortCut
            scenarioId={queryFilters.scenarioId}
            categoryId={value.categoryId}
            sheetDate={sheetDate}
          />}
      </ShortcutContainer>

      <Currency
        amount={value.forecasted}
        maskZero
        className='flex-grow text-xs text-right'
      />

    </CashflowSheetDefaultCell>
  )
}
