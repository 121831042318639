import { put, call } from 'redux-saga/effects'
import { actionTypes } from 'redux-resource'
import { get } from 'lodash'

import { createReceipt } from 'utils/api'
import {
  getPropertiesFromResponse,
  getErrorsFromResponse
} from 'utils/transform'

export function * createReceiptSaga ({ resourceType, requestKey, requestProperties }) {
  try {
    const response = yield call(createReceipt, { params: requestProperties.params, documentFile: requestProperties.documentFile })
    const transactionId = get(requestProperties, 'params.transactionId')
    const list = transactionId && `transaction/${transactionId}`

    yield put({
      type: actionTypes.CREATE_RESOURCES_SUCCEEDED,
      resourceType,
      requestKey,
      list,
      resources: [get(response, 'data.receipt')],
      requestProperties: getPropertiesFromResponse(response)
    })

    const data = get(response, 'data.invocice', {})

    yield call(get(requestProperties, 'successCallback', () => true), data._id)
  } catch (error) {
    yield put({
      type: actionTypes.CREATE_RESOURCES_FAILED,
      resourceType,
      requestKey,
      requestProperties: getErrorsFromResponse(error.response)
    })
  }
}
