import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Input, Form } from 'antd'
import { useTranslation } from 'react-i18next'

import { isSafari } from 'utils/browser'
import ColorSelect from 'components/colors/ColorSelect'
import VatSelect from 'components/vat/VatSelect'

import { CheckOutlined } from '@ant-design/icons'

const CategoryForm = ({ edit, submit, submitting, initialValues, colorPalette, errors, ...rest }) => {
  const { t } = useTranslation()

  const [form] = Form.useForm()

  const [changes, setChanges] = useState(false)

  const disabled = !changes || submitting

  useEffect(() => {
    if (errors && errors.length > 0) {
      errors.forEach((error) => {
        form.setFields([{ name: error.field, value: form.getFieldValue(error.field), errors: error.messages }])
      })
    }
  })
  // Handlers
  const handleChange = useCallback(() => {
    setChanges(true)
  }, [])

  const handleReset = useCallback(() => {
    form.resetFields()
    setChanges(false)
  }, [form])

  const handleFinish = useCallback((values) => {
    submit(values, handleReset)
  }, [handleReset, submit])

  return (
    <Form
      form={form}
      onFinish={handleFinish}
      onChange={handleChange}
      layout='inline'
      initialValues={initialValues}
      {...rest}
    >
      <Form.Item name='name' rules={[{ required: true, message: t('component.categoryForm.error.nameCategory') }]}>
        <Input
          type='text'
          placeholder={t('component.categoryForm.namePlacehold')}
          onDragStart={(event) => {
            event.stopPropagation()
            event.preventDefault()
          }}
          draggable={!isSafari}
          className='w-40'
        />
      </Form.Item>

      <Form.Item align='center' className='rc-no-shadow' name='color' rules={[{ required: true, message: t('component.categoryForm.error.chooseColor') }]}>
        <ColorSelect onChange={handleChange} palette={colorPalette} />
      </Form.Item>

      <Form.Item align='center' className='rc-no-shadow' name='vatRate'>
        <VatSelect onChange={handleChange} allowClear placeholder={t('component.categoryForm.vatPercentage')} className='w-40' />
      </Form.Item>

      <Form.Item>
        <Button
          htmlType='submit'
          type='primary'
          loading={submitting}
          disabled={disabled}
          icon={<CheckOutlined />}
        >
          {edit ? t('component.categoryForm.save') : t('component.categoryForm.createCategory')}
        </Button>
      </Form.Item>
    </Form>
  )
}

CategoryForm.propTypes = {
  edit: PropTypes.bool,
  submitting: PropTypes.bool,
  submit: PropTypes.func,
  initialValues: PropTypes.object,
  colorPalette: PropTypes.arrayOf(PropTypes.string),
  errors: PropTypes.array
}

export default CategoryForm
