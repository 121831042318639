import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { DatePicker } from 'antd'

import { SET_EXPECTED_TRANSACTION_EXPECTED_DATE } from 'graphql/expected-transactions'
import moment from 'moment'
import ButtonLink from 'design/ButtonLink'
import confirm from 'antd/lib/modal/confirm'
import { useTranslation } from 'react-i18next'

const startOfCurrentMonth = moment().startOf('month')

MultipleExpectedTransactionExpectedDatePicker.propTypes = {
  expectedTransactions: PropTypes.arrayOf(PropTypes.object),
  onCompleted: PropTypes.func
  // className: PropTypes.string
}

function MultipleExpectedTransactionExpectedDatePicker ({ expectedTransactions, onCompleted }) {
  const { t } = useTranslation()
  const [customLoading, setCustomLoading] = useState(false)
  const [setExpDate, { loading }] = useMutation(SET_EXPECTED_TRANSACTION_EXPECTED_DATE)

  const changeMultipleExpDate = useCallback(async (newExpectedDate) => {
    setCustomLoading(true)

    for (let index = 0; index < expectedTransactions.length; index++) {
      const expectedTransaction = expectedTransactions[index]
      await setExpDate({
        variables: {
          id: expectedTransaction.id,
          expectedDate: newExpectedDate.format('YYYY-MM-DD')
        }
      })
    }
    document.activeElement.blur()
    setCustomLoading(false)
    onCompleted()
  }, [setExpDate, expectedTransactions, setCustomLoading, onCompleted])

  const handleOnChange = useCallback(async (newExpectedDate) => {
    confirm({
      title: t('component.multipleExpectedTransactionExpectedDatePicker.newExpectedDateConfirmation', { newExpectedDate: newExpectedDate.format('DD/MM/YYYY') }),
      // okType: 'danger',
      okText: t('component.multipleExpectedTransactionExpectedDatePicker.update'),
      cancelText: t('shared.no'),
      maskClosable: true,
      onOk () {
        changeMultipleExpDate(newExpectedDate)
      }
    })
  }, [changeMultipleExpDate, t])

  const renderDatePickerFooter = useCallback(() => {
    const startOfToday = moment().startOf('day')
    const currentMidMonth = moment(startOfCurrentMonth).add(14, 'days')
    const currentMidMonthIsFuture = moment(currentMidMonth).isSameOrAfter(startOfToday)
    const currentEndMonth = moment(startOfCurrentMonth).endOf('month').startOf('day')
    const nextMidMonth = moment(startOfCurrentMonth).add(1, 'month').add(14, 'days')
    const nextEndMonth = moment(startOfCurrentMonth).add(1, 'month').endOf('month').startOf('day')

    return (
      <div className='flex flex-col divide-y divide-dashed divide-gray-200'>
        {currentMidMonthIsFuture && (
          <ButtonLink
            onClick={() => handleOnChange(currentMidMonth)}
            label={t('component.multipleExpectedTransactionExpectedDatePicker.15thOfCurrentMonth')}
            className='text-xs py-3'
          />
        )}
        <ButtonLink
          onClick={() => handleOnChange(currentEndMonth)}
          label={t('component.multipleExpectedTransactionExpectedDatePicker.nDayOfCurrentMonth', { dayNumber: currentEndMonth.format('D') })}
          className='text-xs py-3'
        />

        <ButtonLink
          onClick={() => handleOnChange(nextMidMonth)}
          label={t('component.multipleExpectedTransactionExpectedDatePicker.15thOfNextMonth')}
          className='text-xs py-3'
        />

        <ButtonLink
          onClick={() => handleOnChange(nextEndMonth)}
          label={t('component.multipleExpectedTransactionExpectedDatePicker.nDayOfCurrentMonth', { dayNumber: nextEndMonth.format('D') })}
          className='text-xs py-3'
        />
      </div>
    )
  }, [handleOnChange, t])

  return (
    <DatePicker
      // value={expectedDateMoment}
      placeholder={t('shared.expectedPayment')}
      loading={loading || customLoading}
      disabled={loading || customLoading}
      disabledDate={disabledDate}
      format='DD/MM/YYYY'
      onChange={handleOnChange}
      allowClear={false}
      renderExtraFooter={renderDatePickerFooter}
      showToday={false}
      size='large'
      className='w-64'
    />
  )
}

function disabledDate (current) {
  return moment().startOf('day').isAfter(current)
}

export default MultipleExpectedTransactionExpectedDatePicker
