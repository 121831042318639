import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { flatten, get } from 'lodash'
import { Tab } from '@headlessui/react'
import { useSelector } from 'react-redux'
import CashflowSheetDrawerOutstandingExpectedTransactionsPanel from './CashflowSheetDrawerOutstandingExpectedTransactionsPanel'
import CashflowSheetDrawerPaidExpectedTransactionsPanel from './CashflowSheetDrawerPaidExpectedTransactionsPanel'
import CashflowSheetDrawerTransactionsPanel from './CashflowSheetDrawerTransactionsPanel'
import { isUncategorized } from 'utils/categories.js'

CashflowSheetDrawerDetails.propTypes = {
  // sheet: PropTypes.array,
  rowType: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  sheetDate: PropTypes.object,
  sheetDates: PropTypes.array,
  row: PropTypes.shape({
    categoryId: PropTypes.string
  }),
  drawerParams: PropTypes.object
}

export default function CashflowSheetDrawerDetails ({ rowType, value, sheetDate, sheetDates, row, drawerParams }) {
  const realisedVsForecasted = useSelector(state => get(state, 'app.dashboard.realisedVsForecasted', false))

  const categoryIds = useMemo(() => {
    if (!row?.categoryId) return
    if (isUncategorized(row?.categoryId)) return ['null']
    return getCategoryIdsWithChildrenFromRow(row)
  }, [row])

  const { outstandingExpected, paidExpected } = useValues({ rowType, value })

  return (
    <div className='h-full flex flex-col'>
      <Tab.Panel className='h-full flex flex-col  overflow-hidden flex-grow'>
        <CashflowSheetDrawerTransactionsPanel
          rowType={rowType}
          sheetDate={sheetDate}
          categoryIds={categoryIds}
          drawerParams={drawerParams}
        />
      </Tab.Panel>

      {!realisedVsForecasted && (
        <Tab.Panel className='flex-grow overflow-hidden flex flex-col'>
          <CashflowSheetDrawerOutstandingExpectedTransactionsPanel
            outstandingExpected={outstandingExpected}
            rowType={rowType}
            rowCategoryId={row?.categoryId}
            sheetDate={sheetDate}
            categoryIds={categoryIds}
          />

          <CashflowSheetDrawerPaidExpectedTransactionsPanel
            paidExpected={paidExpected}
            rowType={rowType}
            sheetDate={sheetDate}
            categoryIds={categoryIds}
          />
        </Tab.Panel>
      )}
    </div>
  )
}

// const redirectToList = () => {
//   const categoryIds = getCategoryIdsWithChildrenFromRow(row)
//   pushWithQueryParams(pathname, { year, month, categoryIds })
// }

function getCategoryIdsWithChildrenFromRow (row) {
  const childrenIds = row?.children?.map(getCategoryIdsWithChildrenFromRow) || []
  return flatten([row.categoryId, ...childrenIds])
}

function useValues ({ rowType, value }) {
  return useMemo(() => {
    switch (rowType) {
      case 'CASH_IN':
        return {
          paidExpected: value.paidExpectedCashin,
          outstandingExpected: value.outstandingExpectedCashin
          // expected: value.expectedCashin,
          // realised: value.realisedCashin
        }
      case 'CASH_OUT':
        return {
          paidExpected: value.paidExpectedCashout,
          outstandingExpected: value.outstandingExpectedCashout
          // expected: value.expectedCashout,
          // realised: value.realisedCashout
        }
      case 'CASHIN_CATEGORY':
      case 'CASHOUT_CATEGORY':
        return {
          paidExpected: value.paidExpected,
          outstandingExpected: value.outstandingExpected
          // expected: value.expected,
          // realised: value.realised
        }
      default:
        return {
          paidExpected: 0,
          outstandingExpected: 0
          // expected: 0,
          // realised: 0
        }
    }
  }, [rowType, value])
}
