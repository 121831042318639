
import AppActions from 'actions/AppActions'
import { get } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { getResources } from 'redux-resource'

const queryFilters = { withIgnored: true }

export default function useInvoicesQueryFilters () {
  const dispatch = useDispatch()

  const memorizedQueryFilters = useSelector(state => get(state, 'app.invoices.queryFilters', queryFilters))
  const [invoicesQueryFilters, setInvoicesQueryFilters] = useState(memorizedQueryFilters)

  const mergeInvoicesQueryFilters = useCallback((filters) => {
    setInvoicesQueryFilters({ ...invoicesQueryFilters, ...filters })
  }, [invoicesQueryFilters, setInvoicesQueryFilters])

  // REHYDRATE QUERY FILTERS
  useEffect(() => {
    if (memorizedQueryFilters !== invoicesQueryFilters) {
      dispatch(AppActions.setInvoicesQueryFilters(invoicesQueryFilters))
    }
  }, [dispatch, invoicesQueryFilters, memorizedQueryFilters])

  return {
    invoicesQueryFilters,
    setInvoicesQueryFilters,
    mergeInvoicesQueryFilters
  }
}
