import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { getStatus } from 'redux-resource'
import { useDispatch, useSelector } from 'react-redux'

import StandaloneCategoryForm from './StandaloneCategoryForm'
import resourcesActions from 'actions/ResourcesActions'

const StandaloneCategoryFormContainer = ({ type, onSuccess }) => {
  const dispatch = useDispatch()

  const createCategoryStatus = useSelector(state => getStatus(state, 'categories.requests.createCategory-standalone.status'))

  const successCallback = useCallback((id, resetChanges) => {
    if (onSuccess) onSuccess(id)
    resetChanges()
  }, [onSuccess])

  const createCategory = useCallback((values, resetChanges) => {
    dispatch(resourcesActions.CREATE_CATEGORY(
      { type, ...values },
      (id) => successCallback(id, resetChanges),
      'standalone'
    ))
  }, [dispatch, successCallback, type])

  return (
    <StandaloneCategoryForm
      onSubmit={createCategory}
      submitting={createCategoryStatus.pending}
      type={type}
    />
  )
}

StandaloneCategoryFormContainer.propTypes = {
  type: PropTypes.oneOf(['cashin', 'cashout']),
  onSuccess: PropTypes.func
}

export default StandaloneCategoryFormContainer
