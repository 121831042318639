
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Spinner from './Spinner'

Button.propTypes = {
  label: PropTypes.node,
  primary: PropTypes.bool,
  warning: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  rounded: PropTypes.bool,
  className: PropTypes.string
}

export default function Button ({ label, primary, warning, disabled, loading, onClick, rounded, className, ...rest }) {
  const handleOnClick = useCallback(() => {
    if (disabled || loading) return
    if (onClick) onClick()
  }, [onClick, disabled, loading])

  return (
    <button
      type='button'
      onClick={handleOnClick}
      className={classNames(
        'inline-flex items-center justify-center py-2 border border-transparent text-sm font-medium shadow-sm focus:outline-none focus:ring-offset-2 min-w-max', {
          'px-5': !loading && !rounded,
          'px-2': loading || rounded,
          'focus:ring-2 focus:ring-dark-primary': !disabled,
          'bg-primary hover:bg-dark-primary text-white': !disabled && primary,
          'bg-yellow-200 hover:bg-yellow-300 text-yellow-800': !disabled && warning,
          'bg-ultra-light-primary hover:brightness-105 hover:saturate-50 text-dark-primary': !disabled && !primary && !warning,
          'bg-gray-200 text-gray-400': disabled && primary,
          'bg-gray-100 text-gray-400': disabled && !primary,
          'cursor-not-allowed': disabled || loading,
          'rounded-full': rounded,
          'rounded-md': !rounded
        },
        className
      )}
      {...rest}
    >
      {loading && (<Spinner className='w-4 mr-2' />)}

      <div>
        {label}
      </div>
    </button>
  )
}
