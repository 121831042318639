import { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'

import { GET_RECURRING_PAYMENTS_COUNT } from 'graphql/recurring-payments.js'

export default () => {
  const [recurringPaymentsCount, setRecurringPaymentsCount] = useState(undefined)
  const [refetchRecurringPaymentsCount] = useLazyQuery(GET_RECURRING_PAYMENTS_COUNT, {
    fetchPolicy: 'no-cache',
    onCompleted: (result) => {
      setRecurringPaymentsCount(result.getRecurringPaymentsCount)
    }
  }, [setRecurringPaymentsCount])

  useEffect(() => {
    refetchRecurringPaymentsCount()
  }, [refetchRecurringPaymentsCount])

  return {
    refetchRecurringPaymentsCount,
    recurringPaymentsCount
  }
}
