import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Badge from 'design/Badge'

ConnectionBadge.propTypes = {
  connection: PropTypes.object
}

export default function ConnectionBadge ({ connection, ...rest }) {
  const { t } = useTranslation()

  if (!connection) return null

  if (connection?.status === 'DISCONNECTED') return <Badge label={t('integrations.connectionBadge.reconnectionNeeded')} type='warning' {...rest} />
  if (connection?.status === 'ERROR') return <Badge label={t('integrations.connectionBadge.syncError')} type='error' {...rest} />

  return (
    <Badge label={t('integrations.connectionBadge.connected')} {...rest} />
  )
}
