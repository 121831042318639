import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Input, Tooltip } from 'antd'
import Spinner from './Spinner.js'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { PencilIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

TextEdit.propTypes = {
  text: PropTypes.string,
  resetText: PropTypes.string,
  className: PropTypes.string,
  loading: PropTypes.bool,
  onTextChanged: PropTypes.func,

  labelEditButton: PropTypes.string,
  labelResetButton: PropTypes.string
}

export default function TextEdit ({ text, resetText, loading, onTextChanged, className, labelEditButton, labelResetButton, ...rest }) {
  const { t } = useTranslation()
  const [showEditButton, setShowEditButton] = useState(false)
  const [editing, setEditing] = useState(false)
  const [currentText, setCurrentText] = useState(text)

  useEffect(() => {
    setCurrentText(text)
  }, [setCurrentText, text])

  const handleEditButtonClick = useCallback((e) => {
    e.stopPropagation()
    setEditing(true)
    setShowEditButton(false)
  }, [setShowEditButton])

  const handleResetButtonClick = useCallback((e) => {
    e.stopPropagation()
    setCurrentText(resetText)
    onTextChanged(resetText)
  }, [resetText, setCurrentText, onTextChanged])

  const handleTextMouseEnter = useCallback((e) => {
    setShowEditButton(true)
  }, [setShowEditButton])

  const handleTextMouseLeave = useCallback((e) => {
    setShowEditButton(false)
  }, [setShowEditButton])

  const handleTextMouseMove = useCallback((e) => {
    setShowEditButton(true)
  }, [setShowEditButton])

  const handleInputChange = useCallback((e) => {
    setCurrentText(e.target.value)
  }, [setCurrentText])

  const handleInputKeyDown = useCallback((e) => {
    if (e.key === 'Enter') {
      if (currentText.trim() === '') {
        return
      }
      onTextChanged(currentText.trim())
      setEditing(false)
      setShowEditButton(true)
    }
    if (e.key === 'Escape') {
      setCurrentText(text)
      setEditing(false)
    }
  }, [setEditing, setCurrentText, setShowEditButton, onTextChanged, currentText, text])

  const handleCancelButton = useCallback((e) => {
    e.stopPropagation()
    setCurrentText(text)
    setEditing(false)
    setShowEditButton(true)
  }, [setEditing, setCurrentText, setShowEditButton, text])

  const handleValidButton = useCallback((e) => {
    e.stopPropagation()
    if (currentText.trim() === '') {
      return
    }
    onTextChanged(currentText.trim())
    setEditing(false)
  }, [currentText, onTextChanged])

  if (editing) {
    return (
      <div className={classNames('w-full flex flex-row items-center')} {...rest} onClick={(e) => { e.stopPropagation() }}>
        <Input
          value={currentText}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          autoFocus
          className='flex-grow'
        />
        <CheckIcon className='h-6 w-6 text-gray-400 ml-2 stroke-2' onClick={handleValidButton} />
        <XMarkIcon className='h-6 w-6 text-gray-400 ml-2 stroke-3' onClick={handleCancelButton} />
      </div>
    )
  } else {
    return (
      <div className={classNames('w-full flex flex-row items-center')} {...rest} onMouseEnter={handleTextMouseEnter} onMouseLeave={handleTextMouseLeave} onMouseMove={handleTextMouseMove}>
        <span className={classNames(className)}>{currentText}</span>
        {(showEditButton && !loading) && (
          <>
            <Tooltip title={labelEditButton ?? t('shared.edit')}>
              <PencilIcon className='h-4 w-4 min-h-4 min-w-4 text-primary ml-2 cursor-pointer' onClick={handleEditButtonClick} />
            </Tooltip>
            {(resetText && resetText !== currentText) && (
              <Tooltip title={`${labelResetButton ?? t('shared.reset')} (${resetText})`}>
                <XCircleIcon className='h-4 w-4 min-h-4 min-w-4 text-primary ml-2 cursor-pointer' onClick={handleResetButtonClick} />
              </Tooltip>
            )}
          </>
        )}
        {loading && (<Spinner className='w-4 h-4 text-primary ml-2' />)}
      </div>
    )
  }
}
